var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "h-100 w-100 pb-10 overflow" },
    [
      _vm.repository != null
        ? _c(
            "div",
            {
              attrs: {
                "data-role": "charms",
                id: "reposelected",
                "data-position": "left",
                "data-cls-charms": "bg-white fg-dark border-right bd-gray ",
              },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.closeCharm()
                },
              },
            },
            [
              _vm.repository
                ? _c("div", { staticClass: "info-box" }, [
                    _c("span", {
                      staticClass: "button square closer",
                      on: {
                        click: function ($event) {
                          return _vm.closeCharm()
                        },
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "info-box-content" },
                      [
                        _c("SARepoView", {
                          attrs: {
                            repository: _vm.repository,
                            serviceAccount: _vm.serviceAccount,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("Navigation", { attrs: { pageName: "repositories" } }),
      _c(
        "ul",
        {
          staticClass: "p-8",
          attrs: {
            "data-role": "listview",
            "data-view": "icons",
            "data-select-node": "true",
            "data-selectable": "false",
          },
        },
        _vm._l(_vm.$root.getRepositoriesTypes(), function (t) {
          return t == _vm.$root.PlatformsTypesEnum.Cloud
            ? _c("li", {
                attrs: {
                  "data-icon": "<span class='mif-cloud fg-black'>",
                  "data-caption": "Cloud",
                  id: "cloud_toggle",
                },
              })
            : t == _vm.$root.PlatformsTypesEnum.Microsoft
            ? _c("li", {
                attrs: {
                  "data-icon": _vm.$root.platformsTypes[t].icon,
                  "data-caption": _vm.$root.platformsTypes[t].name,
                  id: "microsoft_toggle",
                },
              })
            : t == _vm.$root.PlatformsTypesEnum.Email
            ? _c("li", {
                attrs: {
                  "data-icon": _vm.$root.platformsTypes[t].icon,
                  "data-caption": _vm.$root.platformsTypes[t].name,
                  id: "email_toggle",
                },
              })
            : _c("li", {
                attrs: {
                  disabled:
                    _vm.$root.platformsTypes[t].needSocketConnection &&
                    !_vm.$session.get("ONEISCONNECTED"),
                  "data-icon": _vm.$root.platformsTypes[t].icon,
                  "data-caption": _vm.$root.platformsTypes[t].name,
                },
                on: {
                  click: function ($event) {
                    return _vm.$root.$emit("OPENREPOSITORYDIALOG", t)
                  },
                },
              })
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "pl-8",
          attrs: {
            "data-role": "dropdown",
            "data-toggle-element": "#cloud_toggle",
          },
        },
        [
          _c(
            "ul",
            { attrs: { "data-role": "listview", "data-view": "tiles" } },
            _vm._l(_vm.$root.getCloudTypes(), function (t) {
              return _c("li", {
                key: t,
                attrs: {
                  "data-icon": _vm.platformsTypes[t].icon,
                  "data-caption": _vm.platformsTypes[t].name,
                },
                on: {
                  click: function ($event) {
                    return _vm.$root.$emit("OPENREPOSITORYDIALOG", t)
                  },
                },
              })
            }),
            0
          ),
        ]
      ),
      _vm.repositories == null
        ? _c("div", { staticClass: "d-flex flex-justify-center" }, [
            _c("span", {
              staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
            }),
          ])
        : _c(
            "div",
            { staticClass: "pl-4 pr-4 w-100 pb-10" },
            [
              _vm.repositories.length == 0
                ? _c("NoDataAvailable", {
                    attrs: { message: _vm.$t("No repositories available") },
                  })
                : _c("div", { staticClass: "p-2" }, [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table border small striped bg-white m-0 compact",
                        attrs: {
                          "data-check": false,
                          "data-check-name": "chkBox",
                          "data-table-search-title": _vm.$t(
                            "Search Repositories"
                          ),
                          "data-cls-search": "w-100-sm w-25-xl w-25-lg w-50-md",
                          "data-cell-wrapper": "false",
                          "data-role": "table",
                          "data-rows": "20",
                          "data-show-rows-steps": "false",
                          "data-show-pagination": "true",
                          "data-horizontal-scroll": "true",
                        },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              { staticClass: "sortable-column text-left" },
                              [_vm._v(_vm._s(_vm.$t("Name")))]
                            ),
                            _c("th", { staticClass: "sortable-column" }, [
                              _vm._v(_vm._s(_vm.$t("Type"))),
                            ]),
                            _c("th", { staticClass: "w-10" }),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.repositories, function (repo, index) {
                            return _c("tr", { key: repo.id }, [
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button flat-button text-bold",
                                    attrs: { href: "#" + repo.id },
                                  },
                                  [_vm._v(_vm._s(repo.name))]
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge border inline mt-2 p-1 text-bold",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.platformsTypes[
                                          repo.type
                                        ].name.toUpperCase()
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "td",
                                { staticClass: "d-flex flex-justify-end" },
                                [
                                  _c("ul", { staticClass: "listview" }, [
                                    _c("li", { staticClass: "mr-2" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "button bg-transparent",
                                          attrs: { href: "#" + repo.id },
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "mif-pencil",
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "button mr-2 bg-transparent",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteRepository(
                                                repo,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_c("span", { staticClass: "mif-bin" })]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }