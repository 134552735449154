<template>
        <!--------------------FINESTRA AGGIUNTA / MODIFICA UTENTE------------------------------------------------>
        <div class="dialog w-100-sm w-100-md w-75-lg  w-50-xl overflow" id="user_dialog"  v-if="selecteduser">
            <div class="dialog-title">
                {{ selecteduser.id == null ? $t('Create New User') : selecteduser.username }}
                <span class="button square closer" @click="$root.$emit('CLOSEUSERVIEWDIALOG')" ></span>
            </div>
            <div class="dialog-content ">
                <!-- <ul data-role="tabs" data-tabs-type="group" data-expand="true" class=""
                    style="z-index: 1;">
                    <li class=""><a href="#profile"><span class="mif mif-profile mif-2x"></span>{{ $t("Profile") }}</a></li>
                     <li class="m-1"><a href="#permissions">{{ $t("Folder Permissions") }}</a></li> 
                     <li class="" ><a href="#security"><span class="mif mif-security mif-2x "></span>{{ $t("Security") }}</a></li> 
                </ul> -->

                <!--------------------FINESTRA AGGIUNTA / MODIFICA UTENTE / PROFILE------------------------------------------------>
                <div id="profile" class=""> 
                    <div class="form-group">
                        <label><b>{{ $t("Username") }}</b></label>
                        <input data-role="input" v-model="selecteduser.username" type="text" required
                            data-prepend="<span class='mif-user'></span>" />
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                    <div class="form-group row">
                        <div class="col-6">
                            <label><b>{{ $t("Firstname") }}</b></label>
                            <input data-role="input" v-model="selecteduser.firstname" type="text" required
                                data-prepend="<span class='mif-user'></span>" />
                            <small class="text-muted">{{ $t("Required") }}</small>

                        </div>
                        <div class="col-6">
                            <label><b>{{ $t("Lastname") }}</b></label>
                            <input data-role="input" v-model="selecteduser.lastname" type="text" required
                                data-prepend="<span class='mif-user'></span>" />
                            <small class="text-muted">{{ $t("Required") }}</small>

                        </div>
                    </div>
                    <div class="form-group row pb-1">
                        <div class="col-6">
                            <label>{{ $t("Email") }}</label>
                            <input data-role="input" v-model="selecteduser.email" type="email" required
                                data-prepend="<span class='mif-mail'></span>" />
                            <small class="text-muted">{{ $t("Required") }}</small>
                        </div>
                        <div class="col-6">
                            <label>{{ $t("User Role") }}</label>
                            <select class="select" v-model="selecteduser.role" :disabled="selecteduser.id==$session.get('AUTH')?.id_user">
                                <option v-for="role in this.$session.get('ROLES')" :value="role.name" :key="role.id"> {{ role.name }} </option>
                            </select>
                        </div>

                    </div>

                    <div class="form-group row">
                    <label>{{ $t("Allowed Groups") }}</label>
                        <select :data-on-item-deselect="confirm" data-role="select" 	data-filter="false"  multiple id="groupselect" name="ids_groups">
                            <option :selected="selecteduser.groups.find(elem => elem.id == group.id_group)"
                                v-for="group in $session.get('GROUPS')" :value="group.id_group" :key="group.id_group"> {{ group.name_group }} </option>
                        </select>
                    </div>

                    <div class="form-group row" v-if="selecteduser.id != null">
                        <div class="col-12">

                            <label>{{ $t("Password") }}</label>
                            <input data-role="input" v-model="selecteduser.oldPassword" type="password" required
                            data-prepend="<span class='mif-lock'></span>" />
                        </div>

                    </div>
                    <div class="form-group row">
                        <div class="col-6">
                            <label>{{ $t("New Password") }}</label>
                            <input data-role="input" v-model="selecteduser.password" type="password" required
                                data-prepend="<span class='mif-lock'></span>" />
                            <small class="text-muted">{{ $t("Required") }}</small>

                        </div>
                        <div class="col-6">
                            <label>{{ $t("Retype Password") }}</label>
                            <input data-role="input" v-model="selecteduser.confirmPassword" type="password" required
                                data-prepend="<span class='mif-lock'></span>" />
                            <small class="text-muted">{{ $t("Required") }}</small>

                        </div>
                    </div>
                    <div class="form-group row">

                        <input class="" v-if="selecteduser.id == null" v-model="selecteduser.sendResetPasswordEmail" type="checkbox"
                            data-role="checkbox" :data-caption="$t('Send Reset Password email to user')">
                    </div>

                    <div class="form-group row" >
                        
                            <div  class="p-2">
                                <h6 class="text-bold">{{ $t("Set Two Factor Authentication") }}</h6>
                                <input data-class="ml-3":disabled="this.$root.checkAuth().id_user != selecteduser.id" data-on="on" data-off="off" @change="toggleTFA()" v-model="startEnabling" type="checkbox"
                                data-role="switch" data-caption-position="right" :data-caption="$t('TFA on this account')">
                            </div>
                            <div v-if="checkTFA.step1!=null && checkTFA.step2==null">
                                <div class="row">
            
                                <div class="cell-md-5 cell-sm-12 text-center">
                                    <div class="p-6">
                                    <Qrcode :value="checkTFA.step1" />
                                    </div>
                                    
                                </div>
                                <div class="cell-md-7">

                                    <div class="mb-3 pt-4 text-left">
                                        <label class="h6">{{$t("Scan the QR code on the page using an app such as Google Authenticator, 2 FAS, Microsoft Authenticator, Twilio Authy.")}}</label>                
                                    </div>
                                    <div class="inline-form">
                                        <input maxlength="6" minlength="6" class="input rounded w-50 p-1" v-model="selecteduser.TFAcode" type="text"
                                        :placeholder="$t('Insert Code from Authenticator')" required/>
                                        <button class="button text-upper primary" :disabled="selecteduser.TFAcode==null || selecteduser.TFAcode.length<6" @click="enableTFA">{{ $t("Enable TFA") }}</button>
                                    </div>
                                </div>
                            </div>
        
                            </div>
                    </div>
                  



                </div>
                <!--------------------FINESTRA AGGIUNTA / MODIFICA UTENTE / SICUREZZA------------------------------------------------>
               
                <!--------------------FINESTRA AGGIUNTA / MODIFICA UTENTE / PERMESSI------------------------------------------------>
               <!--  <div id="permissions" class="p-4" style="width:500px;">
                    <div class="">

                        <h6 class="text-center">{{ $t("Select User allowed directories") }}</h6>
                        <span v-html="treeViewHtml"></span>

                    </div>
                </div> -->
            </div>
            <div class="dialog-actions d-flex dialog-actions flex-justify-end">
                <button class="button primary" @click="saveUser">{{ $t("Save") }}</button>
                <button v-if="selecteduser.id != null" class="button error ml-2" @click="deleteUser">{{ $t("Delete") }}</button>
                <button class="button ml-2" @click="$root.$emit('CLOSEUSERVIEWDIALOG')">{{ $t("Cancel") }}</button>
            </div>
        </div>

</template>
<script>
import { TFA_MESSAGES } from '../../../../public/assets/js/messages';
import Qrcode from '../../Qrcode.vue';

export default {
    components: {
        Qrcode
    },
    name: "UserDialog",
    data() { return { 
       startEnabling:false
     } },
    props: {
        selecteduser: {
            type: Object,
            required: true
        },
        checkTFA:{
            type:Object,
            required:true
        }
    },
      mounted: function() {
        this.startEnabling = this.selecteduser.mfa_enabled;
        this.checkTFA.step1=null;
    },
    methods:{
            confirm(option){
                console.log(option);
            },

            async deleteUser(user) {

                if (!this.$root.openDialogActions()) return;

                let self = this;
                var userLite = {
                    id: user.id,
                    username: user.username
                }
                var deleted = await this.$root.api("DELETEUSER", userLite);

                if (deleted) {
                    self.users.splice(self.selectedIndex, 1);
                     self.$root.$emit('CLOSEUSERVIEWDIALOG');
                }
            },

            async saveUser() {
            let self = this;
          /*   this.selecteduser.directories = [];
            jQuery('#treeview input[type=checkbox]:checked').each(function() {
                this.selecteduser.directories.push(jQuery(this).val().toString());
            }); */


            //TODO: DA MODIFICARE
            if (!this.selecteduser.password.split("").some(c => 65 <= c.charCodeAt(0) && c.charCodeAt(0) <= 90)) {
                self.$root.toast(this.$t("No uppercase characters in password."), 5000, "alert");
                return;
            }

            var isNew = this.selecteduser.id == null;
            var method = isNew ? "CREATEUSER" : "EDITUSER";
            var user = await this.$root.api(method, this.selecteduser);
            var ok = user != null;

            if (ok) {
                self.$root.$emit('CLOSEUSERVIEWDIALOG');
                if (isNew) {
                    self.users.push(user);
                } else {
                    self.users[self.selectedIndex] = user;
                }
            }
        },

         /*****************************USERS - TFA *************************************************/
        async toggleTFA() {
            var self = this;
            
            //lo abilito
            if (self.startEnabling){

                var uri = await this.$root.api("toggleTFA", self.$root.checkAuth().access_token);
                        

                const secretRegex = /secret=([^&]+)/;
                const match = uri.match(secretRegex);

                if (match) {
                    const secretValue = match[1];
                    self.checkTFA.secret= secretValue;
                    self.checkTFA.step1 = uri;
                } else {
                     this.$root.toast(this.$t(TFA_MESSAGES.NOT_ENABLED));
                }

            
            }else {
                if(self.selecteduser.mfa_enabled){
                    if(confirm("Are you sure?")==true){
                        var tfaDisabled = await this.$root.api("disableTFA", self.$root.checkAuth().access_token);
                        if (tfaDisabled) {
                            self.checkTFA.step1 = null;
                            self.selecteduser.mfa_enabled = false;
                            this.$root.toast(this.$t(TFA_MESSAGES.DISABLED));
                        } else{
                            this.$root.toast(this.$t(TFA_MESSAGES.DISABLED));
                        }
                    }else{
                        self.startEnabling = true;
                    }

                }else{
                    self.checkTFA.step1 = null;
                }

                

            }
        },
        async enableTFA() {
            var self = this;
            var esito = await this.$root.api("enableTFA",self.$root.checkAuth().access_token, self.selecteduser.TFAcode );
            if(esito==false){
                 this.$root.toast(this.$t("TFA_NOT_ENABLED"));
            }else{
                self.checkTFA.step2 = true;
                self.selecteduser.mfa_enabled = true;
                self.startEnabling = true;

            }
        },
    }
}
</script>