var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog overflow w-100-sm w-100-md w-75-lg w-50-xl",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSERESTOREDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("Configure Exchange Restore"),
          defaultTitle: _vm.$t("Configure Exchange restore"),
          subTitle: _vm.$t("Exchange Backups"),
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSERESTOREDIALOG")
          },
        },
      }),
      _c("div", { staticClass: "dialog-content p-4" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.steps[0],
                expression: "steps[0]",
              },
            ],
            staticClass: "row",
          },
          [
            _vm.wait
              ? _c("div", {
                  attrs: {
                    "data-role": "activity",
                    "data-type": "metro",
                    "data-style": "dark",
                  },
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "pb-4 w-100",
                staticStyle: { overflow: "scroll" },
                attrs: { id: "esxilogslist" },
              },
              [
                !_vm.wait
                  ? _c(
                      "table",
                      {
                        staticClass: "table striped text-small",
                        attrs: {
                          "data-role": "sorter",
                          "data-sort-dir": "desc",
                          "data-show-search": "true",
                          "data-show-rows-steps": "false",
                          "data-search-min-length": "3",
                          "data-search-threshold": "300",
                        },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "td",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("Sel")]
                            ),
                            _c(
                              "td",
                              {
                                attrs: {
                                  "data-name": "dt_start_utc",
                                  "data-sortable": "true",
                                  "data-format": "int",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Start Date")) + " ")]
                            ),
                            _c(
                              "td",
                              {
                                attrs: {
                                  "data-name": "dt_end_utc",
                                  "data-sortable": "true",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("End Date")))]
                            ),
                            _c(
                              "td",
                              {
                                attrs: {
                                  "data-name": "backup_size",
                                  "data-sortable": "true",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Backup Size")))]
                            ),
                            _c(
                              "td",
                              {
                                attrs: {
                                  "data-name": "copied_files",
                                  "data-sortable": "true",
                                  "data-sort-dir": "desc",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Copied files")))]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.logs, function (log, index) {
                            return _c(
                              "tr",
                              { key: log.id, attrs: { "track-by": "log.id" } },
                              [
                                _c("td", [
                                  _c("input", {
                                    staticClass: "ml-5",
                                    attrs: {
                                      name: "r1",
                                      type: "radio",
                                      "data-role": "radio",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getJobConfiguration(
                                          log,
                                          index
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.convertDate(log.dt_start_utc))
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.convertDate(log.dt_end_utc))
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("prettyBytes")(log.backup_size)
                                    )
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(log.copied_files))]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.steps[1],
                expression: "steps[1]",
              },
            ],
            staticClass: "row",
          },
          [
            _vm.waitvm
              ? _c("div", {
                  attrs: {
                    "data-role": "activity",
                    "data-type": "metro",
                    "data-style": "dark",
                  },
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "pb-4 w-100",
                staticStyle: { overflow: "scroll" },
                attrs: { id: "esxivmslist" },
              },
              [
                !_vm.waitvm
                  ? _c(
                      "table",
                      {
                        staticClass: "table striped text-small",
                        attrs: {
                          "data-role": "sorter",
                          "data-sort-dir": "desc",
                          "data-show-search": "true",
                          "data-show-rows-steps": "false",
                          "data-search-min-length": "3",
                          "data-search-threshold": "300",
                        },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("td"),
                            _c(
                              "td",
                              {
                                attrs: {
                                  "data-name": "name",
                                  "data-sortable": "true",
                                  "data-format": "int",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("VM Name")))]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.mailboxesinbackup, function (mb) {
                            return _c(
                              "tr",
                              { key: mb.Id, attrs: { "track-by": "mb.Id" } },
                              [
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: mb.selectedtorestore,
                                        expression: "mb.selectedtorestore",
                                      },
                                    ],
                                    staticClass: "ml-5",
                                    attrs: {
                                      name: "r2",
                                      type: "checkbox",
                                      "data-role": "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        mb.selectedtorestore
                                      )
                                        ? _vm._i(mb.selectedtorestore, null) >
                                          -1
                                        : mb.selectedtorestore,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = mb.selectedtorestore,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                mb,
                                                "selectedtorestore",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                mb,
                                                "selectedtorestore",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(mb, "selectedtorestore", $$c)
                                        }
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", [_vm._v(_vm._s(mb.Name))]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _vm.steps[2] && _vm.restore
          ? _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row mt-4 ml-2" }, [
                [4, 5].includes(_vm.restore.type)
                  ? _c("div", { staticClass: "cell-8" }, [
                      _c("label", [_vm._v(_vm._s(_vm.restore.vmname))]),
                      _c("br"),
                      _c(
                        "span",
                        {
                          staticClass:
                            "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                        },
                        [_vm._v(_vm._s(_vm.restore.type_name))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                        },
                        [_vm._v(_vm._s(_vm.restore.datacenter))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                        },
                        [_vm._v("n. " + _vm._s(_vm.restore.backupnumber))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "badge inline bg-cobalt fg-white p-1 mt-2 mr-1",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.restore.datastore + "-" + _vm.restore.host
                            )
                          ),
                        ]
                      ),
                    ])
                  : _c("div", { staticClass: "cell-8" }, [
                      _c("label", [_vm._v(_vm._s(_vm.restore.restorename))]),
                    ]),
                _c("div", { staticClass: "cell-8" }, [
                  _vm.restore.dt_start_utc != "" &&
                  _vm.restore.dt_start_utc != null
                    ? _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(
                              _vm._f("utcAsLocal")(
                                _vm.restore.dt_start_utc.substr(0, 8) +
                                  "T" +
                                  _vm.restore.dt_start_utc.substr(8)
                              ),
                              "MM/DD/YY hh:mm A"
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "cell-8" },
                  [
                    _c("RestoreLastResult", {
                      attrs: { lastresult: _vm.restore.lastresult },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "cell-6" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.restore.progress != null &&
                            _vm.restore.progress != 0,
                          expression:
                            "restore.progress != null && restore.progress != 0",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "clear" }, [
                        _vm.restore.current_operation.length <= 50
                          ? _c(
                              "small",
                              {
                                staticClass: "place-left",
                                attrs: {
                                  "data-role": "hint",
                                  "data-hint-position": "top",
                                  "data-hint-text":
                                    _vm.restore.current_operation,
                                },
                              },
                              [_vm._v(_vm._s(_vm.restore.current_operation))]
                            )
                          : _vm.restore.current_operation <= 100
                          ? _c(
                              "small",
                              {
                                staticClass: "place-left",
                                attrs: {
                                  "data-role": "hint",
                                  "data-hint-position": "top",
                                  "data-hint-text":
                                    _vm.restore.current_operation,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.restore.current_operation.substr(
                                      0,
                                      50
                                    ) + "..."
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "small",
                              {
                                staticClass: "place-left",
                                attrs: {
                                  "data-role": "hint",
                                  "data-hint-position": "top",
                                  "data-hint-text":
                                    _vm.restore.current_operation,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.restore.current_operation.substr(
                                      0,
                                      50
                                    ) +
                                      "..." +
                                      _vm.restore.current_operation.substr(
                                        -50,
                                        50
                                      )
                                  )
                                ),
                              ]
                            ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "progress small",
                          attrs: {
                            "data-role": "progress",
                            "data-type": "line",
                            "data-value": _vm.restore.progress,
                            "data-small": "true",
                            "data-cls-bar": "bg-cobalt",
                            "data-role-progress": "true",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "bar ribbed-cobalt",
                            style: { width: _vm.restore.progress + "%" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "cell-2" }, [
                  _vm.restore.isRunning
                    ? _c(
                        "small",
                        { staticClass: "place-right text-bold enlarge-1" },
                        [_vm._v(_vm._s(_vm.restore.progress) + "%")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "dialog-actions" }, [
        _c("div", { staticClass: "ml-auto" }, [
          _c(
            "button",
            {
              staticClass: "button error float-right ml-2",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSERESTOREDIALOG")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Cancel")))]
          ),
          _c(
            "button",
            {
              staticClass: "button ml-2",
              class: { disabled: _vm.steps[0] },
              on: { click: _vm.back },
            },
            [
              _c("span", { staticClass: "mif-arrow-left" }),
              _vm._v(_vm._s(_vm.$t("Prev"))),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "button ml-2",
              class: { disabled: _vm.steps[1] || _vm.steps[2] },
              on: { click: _vm.next },
            },
            [
              _vm._v(_vm._s(_vm.$t("Next"))),
              _c("span", { staticClass: "mif-arrow-right" }),
            ]
          ),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.steps[1] &&
                    _vm.mailboxesinbackup.find(
                      (o) => o.selectedtorestore == true
                    ),
                  expression:
                    "steps[1] && (mailboxesinbackup.find(o => o.selectedtorestore == true))",
                },
              ],
              staticClass: "button primary ml-2 float-right",
              on: { click: _vm.beginRestore },
            },
            [
              _c("span", { staticClass: "mif-checkmark pr-2" }),
              _vm._v(_vm._s(_vm.$t("Restore"))),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }