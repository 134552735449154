<template>
        <div v-if="source.hvSource != null" v-on:keyup.esc="$root.$emit('CLOSESOURCEDIALOG')"
            class="dialog overflow w-100-sm w-100-md w-75-lg  w-50-xl">

            <DialogTitle :title="$t('Configure Source')" :defaultTitle="$t('Configure Source')" :subTitle="source.type_name"
                @closeDialog="$root.$emit('CLOSESOURCEDIALOG')" />

            <div v-if="source.hvSource == ''" class="dialog-content">
                <div class="h-100 d-flex flex-align-center flex-column flex-justify-center py-4">
                    <span class="mif-info mif-6x fg-gray" />
                    <h5 class="text-center">{{ $t('No virtual machine available') }}</h5>
                </div>
            </div>
            <div v-else class="dialog-content" style="overflow: hidden;">

                <div class="info-box-content h-100">
                    <div class="bd-default no-border-top h-100">

                        <div class="p-1 h-100" v-show="steps[0]">
                            <h6 class="pl-5 mb-3">{{ $t("Select Virtual Machines you want to back up") }}</h6>

                            <div class="w-100 d-flex flex-align-left">

                                    <div class="p-3 w-75"> 
                                        <!--ul data-role="treeview" v-if="source.hvSource.length != 0">
                                            <li class="p-1" :title="pc.note">
                                                <input class="mt-3-minus" :value="true" type="checkbox"
                                                    data-role="checkbox" :data-caption="pc.name + ' [' + pc.os_version + ']'">
                                                <ul>
                                                    <li :class="{ 'bg-light': hvselected == hvsource }" class=""
                                                        v-for="hvsource in source.hvSource" :key="hvsource.guid"
                                                        data-collapsed="true">
                                                        <input class="" type='checkbox'
                                                            v-model="hvsource.selected" data-role='checkbox'><label
                                                            style="cursor: pointer;" @click="hvselected = hvsource">{{
                                                                hvsource.name
                                                            }}</label>
                                                        <ul v-if="hvsource.virtualHardDisks">
                                                            <li class="p-1" :title="virtualHardDisk.path" data-collapsed="true"
                                                                v-for="virtualHardDisk in hvsource.virtualHardDisks"
                                                                :key="virtualHardDisk.id"
                                                                data-icon="<span class='mif-drive2 pt-1'></span>">
                                                                <input class="ml-5"  
                                                                    v-model="virtualHardDisk.selected"
                                                                    :value="virtualHardDisk.name" type="checkbox"
                                                                    data-role="checkbox"
                                                                    :data-caption="virtualHardDisk.name + ' [' + prettyBytes(virtualHardDisk.size) + ']'">
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>

                                        </ul-->
                                        <ul data-role="treeview" v-if="source.hvSource.length">
                                            <TreeItem v-for="hvsource in source.hvSource"
                                            :key="hvsource.guid"
                                            :item="hvsource"
                                            :hvselected.sync="hvselected" :selectedCount.sync="selectedCount" />
                                        </ul>
                                    </div>
                            

                                <div class="p-3" v-if="hvselected">
                                    <h5 class="w-100"> <span class='mif-computer'></span> {{ hvselected.name }}</h5>
                                    <div data-role="buttongroup">
                                        <button :title="machine_status[2]" class="button rounded m-1 small"
                                            @click="changeVmStatusHyperV(hvselected, 2)"
                                            :class="{ disabled: hvselected.status == 2 }"><span
                                                class='mif-play'></span></button>
                                        <button :title="machine_status[3]" class="button rounded m-1 small"
                                            @click="changeVmStatusHyperV(hvselected, 3)"
                                            :class="{ disabled: hvselected.status == 3 }"><span
                                                class='mif-stop'></span></button>
                                        <button :title="machine_status[9]" class="button rounded m-1 small"
                                            @click="changeVmStatusHyperV(hvselected, 9)"
                                            :class="{ disabled: hvselected.status == 9 }"><span
                                                class='mif-pause'></span></button>
                                        <button :title="machine_status[6]" class="button rounded m-1 small"
                                            @click="changeVmStatusHyperV(hvselected, 6)"
                                            :class="{ disabled: hvselected.status == 6 }"><span
                                                class='mif-bookmarks'></span></button>
                                        <button :title="machine_status[4]" class="button rounded m-1 small"
                                            @click="changeVmStatusHyperV(hvselected, 4)"
                                            :class="{ disabled: hvselected.status == 4 }"><span
                                                class='mif-switch'></span></button>
                                    </div>
                                    <hr>
                                    <table class="w-100 text-secondary">
                                        <tr>
                                            <td>
                                                {{ $t("Status") }}
                                            </td>
                                            <td class="text-right">
                                                <strong>{{ machine_status[hvselected.status] }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ $t("Version") }}
                                            </td>
                                            <td class="text-right">
                                                <strong>{{ hvselected.version }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                RAM
                                            </td>
                                            <td class="text-right">
                                                <strong>{{ hvselected.status == 2 ? hvselected.ram : "-" }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ $t("Integrated Services") }}
                                            </td>
                                            <td class="text-right">
                                                <strong>{{ hvselected.vss ? "Enabled" : "Disabled" }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ $t("Number of Snapshots") }}
                                            </td>
                                            <td class="text-right">
                                                <strong>{{ hvselected.nofSnapshots }}</strong>
                                            </td>
                                        </tr>
                                    </table>
                                    <hr>
                                    <div>
                                        <h6 class="mb-3">{{ $t("Backup Options") }}</h6>
                                        <label class="text-secondary"> <input v-model="hvselected.backupOptions.changeVMstatus" type="checkbox"
                                                data-role="checkbox" :data-caption="$t('Change VM status before the backup')">
                                        </label>
                                        <select
                                            :disabled="hvselected.backupOptions.changeVMstatus == false || hvselected.backupOptions.changeVMstatus == null"
                                            class="select w-100" v-model="hvselected.backupOptions.changeVMstatus">
                                            <option value="3">{{ $t("Turn off the VM") }}</option>
                                            <option value="6">{{ $t("Put in Saved State") }}</option>
                                            <option value="9">{{ $t("Quiesce the VM") }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="p-2 h-100" v-show="false">

                            <h6 class="pl-5">{{ $t("Select the type of Hyper-V backup you want to configure") }}:</h6>

                            <div class="form-group p-4">
                                <input type="radio" data-role="radio"
                                    :data-caption="$t('Backup RCT (Resilient Change Tracking)')"
                                    v-model="source.options.rct" :value="1"><br>
                                <small class="">{{ $t(messages[0]) }}.</small><br>
                                <small class="">{{ $t(messages[1]) }}</small>
                            </div>
                            <div class="form-group p-4">
                                <input type="radio" data-role="radio"
                                    :data-caption="$t('Direct Copy of single disks and config. files')"
                                    v-model="source.options.rct" :value="2"><br>
                                <small class="">{{ $t(messages[2]) }}</small><br>
                                <small class="">{{ $t(messages[3]) }}</small>
                            </div>


                        </div>
                        <div class="p-4 h-100" v-show="steps[1]">
                            <h6>{{ $t("Configure backup options") }}:</h6>
                            <div class="form-group  row">
                                <div class="cell-8">
                                    <label>{{ $t("Backup Type") }}</label>
                                    <select class="select w-100" :disabled="source.options.rct == 2"
                                        v-model="source.options.backupType">
                                        <option value="F">{{ $t("Full backup Only") }}</option>
                                        <option value="I">{{ $t("One full Backup + Incremental Backups") }}</option>
                                        <option value="D">{{ $t("One full Backup + Differential Backups") }}</option>
                                    </select>
                                </div>
                                <div class="cell-4">
                                    <label>{{ $t("Copies") }}</label>
                                    <input type="text" data-role="spinner" id="nCopiesSpinner" data-default-value="1" data-min-value="1" data-max-value="99" />
                                </div>
                            </div>
                            <div class="form-group">
                                <input data-role="checkbox" type="checkbox"
                                    :data-caption="$t('Add automatically every new virtual machine to the backup')"
                                    data-validate="required" v-model="source.options.autoAddNewItems" required />
                            </div>
                            <div class="form-group" v-show="false">
                                <input v-model="source.enableCompression" type="checkbox" data-role="checkbox"
                                    :data-caption="$t('Enable Compression')">
                            </div>
                            <div class="form-group w-50" v-show="false">
                                <p>{{ $t("Archive File Custom Name") }} </p>
                                <input :disabled="!source.enableCompression" v-model.lazy="source.options.archiveFileCustomName"
                                    type="text" data-role="input" class="">
                                <label class="text-small text-muted">{{ $t("If blank, default name will be used") }}</label>
                            </div>
                            <div class="form-group" v-show="false">
                                <input :disabled="!source.enableCompression" v-model="source.options.useArchivePassword"
                                    type="checkbox" data-role="checkbox" :data-caption="$t('Protect zip with Password')">
                            </div>
                            <div v-if="source.enableCompression && source.options.useArchivePassword" v-show="false">
                                <div class="form-group w-50">
                                    <p>{{ $t("Archive Password") }}</p>
                                    <input data-role="input" class="metro-input" type="password"
                                        v-model="source.options.archivePassword" :placeholder="$t('Enter Password')"
                                        data-prepend="<span class='mif-lock'></span>" />
                                    <small class="text-muted">{{ $t("Required") }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog-actions">
                <div class="ml-auto">
                    <button class="button alert" @click="$root.$emit('CLOSESOURCEDIALOG')">{{ $t("Cancel") }}</button>
                    <button class="button ml-2" :class="{ 'disabled': source.hvSource == '' || steps[0] }" @click="back"><span class="mif-arrow-left"></span>{{ $t("Prev") }}</button>
                    <button class="button ml-2" :class="{ 'disabled': source.hvSource == '' || steps[1] || selectedCount <= 0 }" @click="next">{{ $t("Next")}}<span class="mif-arrow-right"> </span> </button>
                    
                        <button class="button primary ml-2" v-if="steps[1]" @click="saveSource">{{ $t("Save") }}</button>
                    
                </div>
            </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import { isValid, prettyBytes, syncronizeArray, waitSeconds } from "../../../../public/assets/js/utilitiesmodule";
import TreeItem from '../../utils/TreeItem.vue';



export default {
    name: "HyperVSource",
    props: {
        source: {
            type: Object,
            required: true,
        }
    },
    components: {
        "DialogTitle": DialogTitle,
        TreeItem,
    },
    data() {
        return {
            steps: [1, 0, 0],
            visible: false,

            hvselected: null,
            machine_status: ["", "", "On", "Off", "Shut Down", "", "Saved State", "Test", "Defer", "Quiesce", "Reboot", "Reset"],

            pc: null,
            messages: [
                "The software will create an incremental or differential backup based on the modified blocks",
                "(Available only if the software is installed on a Hyper-V 2016, 2019, Windows 10 or higher)",
                "The software will copy directly in the backup job destinations the disk files and the configuration files.",
                "(Allows you to back up virtual machines even from Hyper-V servers on the network, without agents)"
            ],
            selectedCount: 0,

        }
    },
    created: async function () {
        let self = this;
        self.pc = self.$session.get('ROOMPC');

        var newVM = await this.$root.socket("GetVirtualMachinesHyperV");
        //Caso 1: Si è verificato un errore
        if (newVM.length == 0) {
            this.source.hvSource = "";
            return;
        }

        //Caso 2 : Si sta creando la sorgente
        if (!isValid(self.source.id))
            self.source.hvSource = newVM;
        else {//Caso 3 : Si sta modificando la sorgente
            var oldVM = self.source.hvSource;

            //test
            newVM.splice(0, 1);
            oldVM.splice(1, 1);
            newVM[0].virtualHardDisks.splice(0, 1);
            oldVM[1].virtualHardDisks.splice(1, 1);
            var syncronizationResult = syncronizeArray(3, oldVM, newVM);
            self.source.hvSource = syncronizationResult.finalArray;

            syncronizationResult.messages.forEach(async messageObj => {
                self.$root.formattedToast(this.$t(messageObj.message), messageObj.args, 3000, "warning");
                await waitSeconds(3);
            });
        }
        //self.hvselected = self.source.hvSource[0]

        setTimeout(function () {
            var nCopiesSpinner = window.Metro.get$el("#nCopiesSpinner").data("spinner");
            nCopiesSpinner.val(self.source.options.nCopies);
            nCopiesSpinner = nCopiesSpinner.options;
            nCopiesSpinner.onButtonClick = (x, y, val) => {
                self.source.options.nCopies = y;
            };
            nCopiesSpinner.onArrowClick = (x, y, val) => {
                self.source.options.nCopies = y;
                //self.onChangeNCopies(val);
            };
        }, 500);
    },
    methods: {
         
        /*handleSelectionChanged(count) {
            this.selectedCount = count;
        },*/ 

        async changeVmStatusHyperV(hyperv, newState) {
            this.hvselected = hyperv;
            var changedState = await this.$root.socket("ChangeVmStatusHyperV", hyperv.guid, newState);

            if (changedState)
                this.hvselected.status = newState;
        },
        next() {
            var next = true;

            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index + 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
        back() {
            var next = true;
            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index - 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
        saveSource() {
            this.$root.$emit("saveBackupSource", this.source);
        },
        prettyBytes: function (bytes, precision) {
            return prettyBytes(bytes, precision);
        },

    }
}
</script>
<style scoped>
    ul[data-role="treeview"] {
    list-style: none;
    padding: 0;
    margin: 0;
    }
</style>