<template>
    <div class="dialog w-100-sm w-100-md w-75-lg  w-50-xl overflow" id="group_dialog" v-if="group">
        <div class="dialog-title">
            {{ group.id == null ? $t('Create New Group') : group.name }}
            <span class="button square closer" @click="$root.$emit('CLOSEGROUPVIEWDIALOG')"></span>
        </div>
        <div class="dialog-content">
            <div id="profile">
                <div class="form-group">
                    <label>{{ $t("Name") }}*</label>
                    <input data-role="input" class="input" required v-model="group.name" type="text"
                        data-prepend="<span class='mif-folder'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                <div class="form-group ">
                    <div class="">
                        <label>{{ $t("Note") }}*</label>
                        <input data-role="input" class="input small" required v-model="group.notes" type="text" />
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>

                </div>

                <div class="form-group">
                    <label>{{ $t("Users") }}</label>
                    <select data-role="select" data-filter="false" class="input" multiple id="userselect"
                        name="ids_users">
                        <option
                            :selected="group.users.find(elem => elem.id == user.id || elem.id_user == user.id) || user.id == $session.get('AUTH')?.id_user"
                            v-for="user in users" :value="user.id" :key="user.id"
                            data-template="<span class='mif-user icon'></span> $1"> {{ user.username }} </option>
                    </select>
                    <small>{{ $t("Users that can view this group") }}</small>
                </div>

                <div class="form-group">
                    <label>{{ $t("Devices") }}</label>
                    <select v-if="computers" class=" input" data-role="select" data-filter="false" multiple
                        id="computerselect" name="ids_computers">
                        <option :selected="group.computers.find(elem => elem.id == pc.id)" v-for="pc in computers"
                            :value="pc.id" :key="pc.id" data-template="<span class='mif-laptop icon'></span> $1"> {{
        pc.name }} </option>
                    </select>
                    <small>{{ $t("Computers in this group") }}</small>
                </div>

                <div class="form-group ">
                    <div class="">
                        <label>{{ $t("Email") }}</label>
                        <input data-role="input" class=" input" v-model="group.email" type="email"
                            data-prepend="<span class='mif-mail'></span>" />
                    </div>
                </div>
                <div class="form-group ">
                    <div class="">
                        <label>{{ $t("Phone Number") }}</label>
                        <input data-role="input" class=" input" v-model="group.phone_number" type="number"
                            data-prepend="<span class='mif-phone'></span>" />
                    </div>
                </div>
                <div class="form-group ">
                    <div class="">
                        <label>{{ $t("Address") }}</label>
                        <input data-role="input" class=" input" v-model="group.address" type="text"
                            data-prepend="<span class='mif-home'></span>" />
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog-actions d-flex dialog-actions flex-justify-end">
            <button class="button primary" @click="save">{{ group.id == "" ? $t('Create') : $t("Save") }}</button>
            <button v-if="group.id != null" class="button error ml-2" @click="deleteGroup">{{ $t("Delete") }}</button>
            <button class="button ml-2" @click="$root.$emit('CLOSEGROUPVIEWDIALOG')">{{ $t("Cancel") }}</button>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import { GROUP_MESSAGES } from '../../../../public/assets/js/messages';
export default {

    name: "GroupDialog",
    data() {
        return {

        }
    },
    props: {

        group: {
            type: Object,
            required: false
        },
        computers: {
            type: Array,
            required: true
        },
        users: {
            type: Array,
            required: true
        },

    },
    methods: {

        async save() {
            let self = this;

            self.group.ids_computers = window.Metro.getPlugin($("#computerselect"), 'select').val();
            self.group.ids_users = window.Metro.getPlugin($('#userselect'), 'select').val();

            var isNew = self.group.id == "";
            var method = isNew ? "CREATEGROUP" : "EDITGROUP";
            var newGroup = await self.$root.api(method, self.group);


            //Se il salvataggio è andato a buon fine, devo aggiungere o modificare la sua versione lite in session
            var groupLite = {
                "id_group": newGroup.id,
                "name_group": newGroup.name,
                "num_computers": newGroup.computers.length,
                "num_users": newGroup.users.length
            };

            var groupsLite = this.$session.get("GROUPS");
            groupsLite.push(groupLite);
            this.$session.set("GROUPS", groupsLite);
            this.$root.$emit("refreshGroups");
            this.$root.$emit('CLOSEGROUPVIEWDIALOG');

        },

        async deleteGroup() {
            var groupID = this.group.id;
            var groupName = this.group.name;
            //Personalizzo la dialog da mostrare
            var dialogTitle = "Delete '" + groupName + "'?";
            var dialogContent = "Are you sure you want to delete the '" + groupName + "' group?";
            var okText = "Yes";
            var okClass = "alert";
            var okResult = true;

            var cancelText = "No";
            var cancelClass = "";
            var cancelResult = false;

            let self = this;
            this.$root.$on(dialogContent, async ok => {

                //Se l'utente non conferma la cancellazione, riporto l'URL a /groups
                if (!ok) {
                    self.$router.replace({ path: '/groups' });
                    self.$root.$off(dialogContent);
                    return;
                }
                
                ok = await self.$root.api("DELETEGROUP", groupID);

                //Se la cancellazione del gruppo non va a buon fine, riporto l'URL a /groups
                if (!ok) {
                    self.$root.toast(GROUP_MESSAGES.NOT_DELETED);
                    self.$router.replace({ path: '/groups' });
                    self.$root.$off(dialogContent);
                    return;
                }

                self.$root.toast(GROUP_MESSAGES.DELETED);

                //Se la cancellazione del gruppo va a buon fine, cancello il gruppo in session
                var groups = this.$session.get("GROUPS");
                groups.splice(groups.findIndex(g => g.id_group == groupID), 1);
                self.$session.set("GROUPS", groups);

                self.$root.$off(dialogContent);
                self.$root.$emit("refreshGroups");

                self.$root.$emit('CLOSEGROUPVIEWDIALOG');
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openDialogActions(dialogTitle, dialogContent, okText, okClass, okResult, cancelText, cancelClass, cancelResult)
        }
    }
}
</script>