<template>
    <div class="w-100 overflow" v-if="group != null" v-cloak>
        <Navigation pageName="group" :data="{ 'group': group }" v-if="group != null" />
       
       
        <div v-if="group == null" class="d-flex flex-justify-center  p-10">
            <span class="mif-spinner2 fg-primary ani-spin mif-3x" />
        </div>

        <div class="row p-5" v-show="fullStats" >
                    
            <div class="cell-lg-3 cell-md-3 col-sm-6 mt-2">
                <div class="more-info-box card rounded p-2" >
                    <div class="content">
                        <h2 class="text-bold mb-0">{{fullStats?.nOfWorkstations}}/{{fullStats?.nOfServers}}</h2>
                        <div>{{$t("Workstations")}}/{{$t("Servers")}}</div>
                        
                    </div>
                    <div class="icon">
                        <span class="mif-server"></span>
                    </div>
                
                </div>
            </div>
         
            
            <div class="cell-lg-3 cell-md-3 col-sm-6 mt-2">
                <div class="more-info-box card rounded p-2" :class="(fullStats?.nPcDiskAlert!=0 || fullStats?.nPcRamAlert!=0)?'bg-red':''">
                    <div class="content">
                        <h2 class="text-bold mb-0">{{fullStats?.nPcDiskAlert}}/{{fullStats?.nPcRamAlert}}</h2>
                        <div>{{ $t("Devices with Disk/Ram Alert") }}</div>
                    </div>
                    <div class="icon">
                        <span class="mif-cogs"></span>
                    </div>
                
                </div>
            </div>
            <div class="cell-lg-3 cell-md-3 col-sm-6 mt-2">
                <div class="more-info-box card rounded p-2" :class="(fullStats?.nPcAvAlert!=0 )?'bg-red':''">
                    <div class="content">
                        <h2 class="text-bold mb-0">{{fullStats?.nPcAvAlert}}</h2>
                        <div>{{ $t("Devices with Antivirus Alert") }}</div>
                    </div>
                    <div class="icon">
                        <span class="mif-security"></span>
                    </div>
                    
                </div>
            </div>
        </div> 

        <NoDataAvailable v-if="group.computers==null || group.computers.length == 0" :message="$t('No computers available')" />
        <div v-if="group.computers.length!=0" class="p-10">

             <h3 class="p-2">{{ $t("Devices") }}</h3>
            <div class="row">
                <table class="table border compact striped d-table-lg bg-white m-0 " data-role="">
                    <thead>
                        <tr>
                            
                            <th class="text-left"></th>
                            <th>{{$t("Description")}}</th>
                            <th>{{$t("Organization")}}</th>
                            <th>{{$t("OS Version")}}</th>
                            <th>{{$t("Private IP")}}</th>
                            <th>{{$t("Public IP")}}</th>
                            <th class="text-center">{{$t("Remote Connection")}}</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="pc in group.computers" v-bind:key="pc.id" class="p-4 " >
                              
                                <td class="text-left">
                                    <PCLink :pc="pc" />
                                </td>
                                <td>
                                <label v-if="pc.description" class="text-small text-center p-0 m-0">
                                    {{ pc.description }}</label>
                                </td>
                                <td>
                                    <label v-if="pc.description" class="text-small text-center p-0 m-0">
                                        {{ pc.organization }}</label>
                                    </td>
                                <td>
                                    <label v-if="pc.description" class="text-small text-center p-0 m-0">
                                        {{ pc.os_version_full }}</label>
                                </td>
                                <td>
                                    <label class="text-small text-center p-0 m-0"> {{ pc.ipaddress_lan }}</label>
                                </td>
                                <td>
                                    <label class="text-small text-center p-0 m-0"> {{ pc.ipaddress_public }}</label>
                                </td>
                                <td>
                                    <label class="text-small text-center p-0 m-0"> <PCRemoteLink :pc="pc"/></label>
                                </td>
                                
                                
                                
                            </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <NoDataAvailable v-if="group.users.length==0" :message="$t('No users available')" />

        <div v-if="group!=null && group.users.length!=0" class="p-10" >
                <h3 class="p-2">{{ $t("Users") }}</h3>
                <table class="table border striped  bg-white m-0 compact">
                    <thead>
                        <tr>

                            <th >{{$t("Username")}}</th>
                            <th>{{$t("Lastname")}}</th>
                            <th>{{$t("Firstname")}}</th>
                            <th>{{$t("Role")}}</th>
                            <th class="text-center">{{$t("2FA")}}</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="user in group.users" class="mx-8 p-4  rounded m-6"  v-bind:key="user.id" >
                            <td>
                                <a   class="text-bold text-left m-0 flat-button"> {{user.username}} </a>

                            </td>    
                            <td>
                                {{user.lastname}} 
                            </td>    
                            <td>
                                {{user.firstname}} 
                            </td>    
                            <td class=""  > 
                                <label class="text-bold text-left m-0">{{user.role}}</label>
                            </td>
                            <td class=" text-center"> 
                                <span class=" text-center mif-2x " :class="user.mfa_enabled?'mif-security':''"></span>
                            </td>
                          
                          
                                
                        </tr>
                    </tbody>
                </table>
    </div>
    </div>
</template>
<script>

import Navigation from './utils/Navigation';
import PCRemoteLink from './utils/PCRemoteLink.vue';
import PCLink from './utils/PCLink.vue';
import NoDataAvailable from "./utils/NoDataAvailable.vue";

export default {
    name: "Group",
    props: {
        idGroup: String
    },
    components: {
        Navigation,
        PCRemoteLink,
        PCLink,
        NoDataAvailable
    },
    data() {
        return {
            group: null,
            fullStats:null
        }
    },

    async created() {
        var self = this;
        this.group = await this.$root.api("GETGROUP", this.idGroup);

        this.$root.$on("socketEventConnected", () => {
            self.$root.socketConnected = true;
            self.$root.checkConnectionTimerLight();
        });
        //"socketEventDisconnected" viene emesso se la connessione socket non è attiva
        this.$root.$on("socketEventDisconnected", () => {
            self.$root.socketConnected = false;
        });
      
        this.$root.checkSocket();
    },

      beforeDestroy: function() {
            this.$root.$off("socketEventConnected");
            this.$root.$off("socketEventDisconnected");
        },

    mounted(){
        var self = this;
        this.$root.api("GETSTATSFROMLICENSELIGHT",this.idGroup).then( stats=>{
                self.fullStats= stats;
                //self.statsOS();
            });
    }
}
</script>