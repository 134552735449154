import { isValid } from "../../public/assets/js/utilitiesmodule";
import { selectValues } from "../../public/assets/js/selectvalues";


export class OptionsConverter {

    /*
    Dato l'oggetto passato ne modifica alcuni valori, cambiandone il tipo.
    Il parametro options è una mappa <string, string>, ma alcuni valori devono essere trasformati in bool, int o array.
    */
    from(options) {

        //COMPRESSIONE ZIP
        if (isValid(options.useArchiveCompression)) options.useArchiveCompression = options.useArchiveCompression.toString().toLowerCase() == "true";
        if (isValid(options.useArchiveFileCustomName) && typeof(options.useArchiveFileCustomName) != "boolean") options.useArchiveFileCustomName = options.useArchiveFileCustomName.toLowerCase() == "true";
        if (isValid(options.useArchivePassword) && typeof(options.useArchiveFileCustomName) != "boolean") options.useArchivePassword = options.useArchivePassword.toString().toLowerCase() == "true";

        if (isValid(options.protectionType)) options.protectionType = parseInt(options.protectionType);
        if (isValid(options.pathsInArchive)) options.pathsInArchive = parseInt(options.pathsInArchive);

        //TIPO BACKUP
        if (isValid(options.nCopies)) options.nCopies = parseInt(options.nCopies);
        if (isValid(options.syncronize) && typeof(options.useArchiveFileCustomName) != "boolean") options.syncronize = options.syncronize.toLowerCase() == "true";

        //FILTRI
        if (isValid(options.extensionsFilterAction)) options.extensionsFilterAction = parseInt(options.extensionsFilterAction);

        if (isValid(options.useFileSizeFilter) && typeof(options.useArchiveFileCustomName) != "boolean") options.useFileSizeFilter = options.useFileSizeFilter.toLowerCase() == "true";
        if (isValid(options.fileSizeFilterAction)) options.fileSizeFilterAction = parseInt(options.fileSizeFilterAction);
        if (isValid(options.fileSizeFilterCompare)) options.fileSizeFilterCompare = parseInt(options.fileSizeFilterCompare);
        if (isValid(options.fileSize)) options.fileSize = parseInt(options.fileSize);

        //ESCLUSIONI -> path?type|path?type|...
        if (isValid(options.excludedPaths)) options.excludedPaths = options.excludedPaths.split("|").map(str => {
            var path_type = str.split("?");
            return {
                id: null,
                path: path_type[0],
                type: path_type[1]
            };
        });

        if (isValid(options.excludeSubFolders) && typeof(options.useArchiveFileCustomName) != "boolean") options.excludeSubFolders = options.excludeSubFolders.toLowerCase() == "true";


        //VARIE
        if (isValid(options.copySecurityPermissions) && typeof(options.useArchiveFileCustomName) != "boolean") options.copySecurityPermissions = options.copySecurityPermissions.toLowerCase() == "true";
        if (isValid(options.recreateCompletePath) && typeof(options.useArchiveFileCustomName) != "boolean") options.recreateCompletePath = options.recreateCompletePath.toLowerCase() == "true";
        return options;

    }

    /*
    Divide options in sezioni (oggetti di tipo {section:"nomesezione", keys:[array di nomi di valori], values : [array di valori]}) e converte i valori di options in stringhe rappresentative.
    details è un array di input, quindi bisogna mantenere il riferimento a tale oggetto (usare details.push)
    */
    fromOptionsToDetails(options, details) {

        var sections = [
            this.buildArchiveSection(options),
            this.buildBackupSection(options),
            this.buildFiltersSection(options),
            this.buildExclusionsSection(options),
            this.buildOtherSection(options)
        ];

        sections.forEach(section => {
            if (section != null) details.push(section);
        });
    }

    buildArchiveSection(options) {

        var archive = { section: 'ARCHIVE', keys: [], values: [] };

        if (isValid(options.useArchiveCompression)) {
            archive.keys.push('USE ARCHIVE COMPRESSION');
            archive.values.push(options.useArchiveCompression ? 'Yes' : 'No');

            if (options.useArchiveCompression) {
                if (isValid(options.useArchiveFileCustomName)) {
                    archive.keys.push('USE ARCHIVE FILE CUSTOM NAME');
                    archive.values.push(options.useArchiveFileCustomName ? 'Yes' : 'No');
                }
                if (isValid(options.archiveFileCustomName)) {
                    archive.keys.push('ARCHIVE FILE CUSTOM NAME');
                    archive.values.push(options.archiveFileCustomName);
                }
                if (isValid(options.useArchivePassword)) {
                    archive.keys.push('USE ARCHIVE PASSWORD');
                    archive.values.push(options.archivePassword ? 'Yes' : 'No');
                }
                if (isValid(options.archivePassword)) {
                    archive.keys.push('PASSWORD');
                    archive.values.push(options.archivePassword);
                }

                if (isValid(options.protectionType)) {
                    archive.keys.push("PROTECTION TYPE");
                    archive.values.push(selectValues.protectionTypes[options.protectionType]);
                }

                if (isValid(options.pathsInArchive)) {
                    archive.keys.push("PATHS IN ARCHIVE");
                    archive.values.push(selectValues.pathsInArchive[options.pathsInArchive]);
                }
            }
        }

        if (archive.keys.length != 0) return archive;
        else return null;
    }

    buildBackupSection(options) {

        var backup = { section: 'BACKUP', keys: [], values: [] };

        if (isValid(options.backupMode)) {
            backup.keys.push('MODE');

            backup.values.push(options.backupMode);
        }
        if (isValid(options.backupType)) {
            backup.keys.push('TYPE');
            backup.values.push(options.backupType.toLowerCase().startsWith('i') ? 'Incremental' :
                options.backupType.toLowerCase().startsWith('d') ? 'Differential' :
                options.backupType.toLowerCase().startsWith('f') ? 'Full' :
                'Update full');
        }
        if (isValid(options.nCopies)) {
            backup.keys.push('COPIES');
            backup.values.push(options.nCopies);
        }
        if (isValid(options.nOfCopies)) {
            backup.keys.push('COPIES');
            backup.values.push(options.nOfCopies);
        }

        if (isValid(options.verifyBackup)) {
            backup.keys.push('VERIFY BACKUP');
            backup.values.push(options.verifyBackup ? 'Yes' : 'No');
        }

        if (backup.keys.length != 0) return backup;
        else return null;

    }

    buildFiltersSection(options) {

        var filters = { section: 'FILTERS', keys: [], values: [] };

        if (isValid(options.specifiedExtensions)) {

            filters.keys.push(selectValues.extensionsFilterAction[options.extensionsFilterAction]);
            filters.values.push(options.specifiedExtensions);

        }

        if (isValid(options.useFileSizeFilter)) {
            if (!options.useFileSizeFilter) {
                filters.keys.push("USE FILE SIZE FILTER");
                filters.values.push("No");
            } else {
                filters.keys.push(selectValues.fileSizeFilterAction[options.fileSizeFilterAction]);
                filters.values.push(selectValues.fileSizeFilterCompare[options.fileSizeFilterCompare] + " " + options.fileSize + " MB");
            }
        }

        if (filters.keys.length != 0) return filters;
        else return null;

    }

    buildExclusionsSection(options) {

        var exclusions = { section: 'EXCLUSIONS', keys: [], values: [] };

        if (isValid(options.excludedPaths)) {

            exclusions.keys.push("EXCLUDED PATHS");
            exclusions.values.push(options.excludedPaths.map(r => r.path).join("\n"));
        }

        if (isValid(options.excludeSubFolders)) {
            exclusions.keys.push("EXCLUDE SUB FOLDERS");
            exclusions.values.push(options.excludeSubFolders ? "Yes" : "No");
        }

        if (exclusions.keys.length != 0) return exclusions;
        else return null;

    }

    buildOtherSection(options) {
        var other = { section: 'OTHER', keys: [], values: [] };
        if (isValid(options.disableDasdIo)) {
            other.keys.push("ENABLE I/O BOUNDARY CHECKS BY THE FILE SYSTEM");
            other.values.push(options.disableDasdIo ? 'Yes' : 'No');
        }

        if (isValid(options.badItemsLimit)) {
            other.keys.push("BAD ITEMS LIMIT");
            other.values.push(options.badItemsLimit);
        }
        if (isValid(options.outputFormat)) {
            other.keys.push("OUTPUT FORMAT");
            other.values.push(options.outputFormat);
        }
        if (isValid(options.rtc)) {
            other.keys.push("RTC");
            other.values.push(options.rtc);
        }
        if (isValid(options.server)) {
            other.keys.push("SERVER");
            other.values.push(options.server);
        }
        if (isValid(options.autoAddNewItems)) {
            other.keys.push("ADD NEW ITEMS AUTOMATICALLY");
            other.values.push(options.autoAddNewItems ? 'Yes' : 'No');
        }
        if (isValid(options.useParallelMode)) {
            other.keys.push("USE PARALLEL MODE");
            other.values.push(options.useParallelMode ? 'Yes' : 'No');
        }
        if (isValid(options.useNativeComp)) {
            other.keys.push("USE NATIVE COMPRESSION");
            other.values.push(options.useNativeComp ? 'Yes' : 'No');
        }
        if (isValid(options.replicaName)) {
            other.keys.push("REPLICA NAME");
            other.values.push(options.replicaName);
        }

        if (isValid(options.cbtPort)) {
            other.keys.push("CBT PORT");
            other.values.push(options.cbtPort);
        }

        if (isValid(options.includeArchiveMailbox)) {
            other.keys.push("Include archive mailbox");
            other.values.push(options.includeArchiveMailbox ? 'Yes' : 'No');
        }

        if (isValid(options.includePublicFolders)) {
            other.keys.push("Include public folders");
            other.values.push(options.includePublicFolders ? 'Yes' : 'No');
        }

        if (isValid(options.truncateExchangeLogs)) {
            other.keys.push("Truncate Exchange logs");
            other.values.push(options.truncateExchangeLogs ? 'Yes' : 'No');
        }

        if (isValid(options.useCopyOnly)) {
            other.keys.push("Use copy only");
            other.values.push(options.useCopyOnly ? 'Yes' : 'No');
        }

        if (isValid(options.enableAppend)) {
            other.keys.push("Append text and/or dynamic variables to the backup file name");
            other.values.push(options.enableAppend ? 'Yes' : 'No');
        }

        if (isValid(options.appendTextBkp)) {
            other.keys.push("Append text backup");
            other.values.push(options.appendTextBkp ? 'Yes' : 'No');
        }

        if (isValid(options.singleComp)) {
            other.keys.push("Create .zip file for each single database");
            other.values.push(options.singleComp ? 'Yes' : 'No');
        }

        if (isValid(options.backupLog)) {
            other.keys.push("Backup the transaction log");
            other.values.push(options.backupLog ? 'Yes' : 'No');
        }

        if (isValid(options.shrinkLog)) {
            other.keys.push("Shrink the transaction log after the backup");
            other.values.push(options.shrinkLog ? 'Yes' : 'No');
        }

        if (isValid(options.alterDbRecModel)) {
            other.keys.push("Alter the database recovery mode to SIMPLE if necessary");
            other.values.push(options.alterDbRecModel ? 'Yes' : 'No');
        }

        if (isValid(options.appName)) {
            other.keys.push("Application name");
            other.values.push(options.appName);
        }
        if (isValid(options.tenant_id)) {
            other.keys.push("Tenant ID");
            other.values.push(options.tenant_id);
        }
        if (isValid(options.useModernAuth)) {
            other.keys.push("Use modern authentication");
            other.values.push(options.useModernAuth ? this.$t("Yes") : this.$t("No"));
        }


        if (isValid(options.useWinAuth)) {
            other.keys.push("Use Windows authentication");
            other.values.push(options.useWinAuth ? this.$t("Yes") : this.$t("No"));
        }

        if (isValid(options.useTcpIpNetLib)) {
            other.keys.push("Use 'TCP/IP Net Library'");
            other.values.push(options.useTcpIpNetLib ? this.$t("Yes") : this.$t("No"));
        }
        if (isValid(options.usePersistSecInfo)) {
            other.keys.push("Use 'Persist Security Info'");
            other.values.push(options.usePersistSecInfo ? this.$t("Yes") : this.$t("No"));
        }
        if (isValid(options.useIntSecSSPI)) {
            other.keys.push("Use 'Integrated Security SSPI'");
            other.values.push(options.useIntSecSSPI ? this.$t("Yes") : this.$t("No"));
        }
        if (isValid(options.useTrustConn)) {
            other.keys.push("Use Trusted Connection");
            other.values.push(options.useTrustConn ? this.$t("Yes") : this.$t("No"));
        }
        if (isValid(options.useEncConn)) {
            other.keys.push("Use Encrypted Connection");
            other.values.push(options.useEncConn ? this.$t("Yes") : this.$t("No"));
        }
        if (isValid(options.senderAddress)) {
            other.keys.push("Sender address");
            other.values.push(options.senderAddress);
        }
        if (isValid(options.usePop3)) {
            other.keys.push("Use POP3");
            other.values.push(options.usePop3 ? this.$t("Yes") : this.$t("No"));
        }
        if (isValid(options.popUsername) && options.usePop3) {
            other.keys.push("POP3 Username");
            other.values.push(options.popUsername);
        }
        if (isValid(options.popPassword) && options.usePop3) {
            other.keys.push("POP3 Password");
            other.values.push(options.popPassword);
        }
        if (isValid(options.popServer) && options.usePop3) {
            other.keys.push("POP3 Server");
            other.values.push(options.popServer);
        }
        if (isValid(options.popPort) && options.usePop3) {
            other.keys.push("POP3 Port");
            other.values.push(options.popPort);
        }

        if (other.keys.length != 0) return other;
        console.log(options);
        return null;

    }
}