var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog overflow w-100-sm w-100-md w-75-lg w-50-xl" },
    [
      _c("DialogTitle", {
        attrs: {
          title:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.serviceAccount.name,
          defaultTitle:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.$t("Edit/Test Service Account"),
          subTitle: _vm.serviceAccount.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
          },
        },
      }),
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Name")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.serviceAccount.name,
                expression: "serviceAccount.name",
              },
            ],
            attrs: {
              "data-role": "input",
              type: "text",
              maxlength: "50",
              placeholder: _vm.$t("Enter friendly name"),
              "data-prepend": "<span class='mif-pencil'></span>",
            },
            domProps: { value: _vm.serviceAccount.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.serviceAccount, "name", $event.target.value)
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Sender")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.serviceAccount.options.senderAddress,
                expression: "serviceAccount.options.senderAddress",
              },
            ],
            attrs: {
              "data-role": "input",
              type: "text",
              placeholder: _vm.$t("Enter a sender email"),
              "data-prepend": "<span class='mif-mail'></span>",
            },
            domProps: { value: _vm.serviceAccount.options.senderAddress },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.serviceAccount.options,
                  "senderAddress",
                  $event.target.value
                )
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "cell-6" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Username")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.serviceAccount.username,
                    expression: "serviceAccount.username",
                  },
                ],
                staticClass: "metro-input",
                attrs: {
                  "data-role": "input",
                  type: "text",
                  placeholder: _vm.$t("Enter Username"),
                  "data-prepend": "<span class='mif-user'></span>",
                },
                domProps: { value: _vm.serviceAccount.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount,
                      "username",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]),
            _c("div", { staticClass: "cell-6" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Password")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.serviceAccount.password,
                    expression: "serviceAccount.password",
                  },
                ],
                staticClass: "metro-input",
                attrs: {
                  "data-role": "input",
                  type: "password",
                  placeholder: _vm.$t("Enter Password"),
                  "data-prepend": "<span class='mif-lock'></span>",
                },
                domProps: { value: _vm.serviceAccount.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount,
                      "password",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Recipient")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.serviceAccount.options.recipient,
                expression: "serviceAccount.options.recipient",
              },
            ],
            attrs: {
              "data-role": "input",
              type: "text",
              placeholder: _vm.$t("Enter recipient email"),
              "data-prepend": "<span class='mif-mail'></span>",
            },
            domProps: { value: _vm.serviceAccount.options.recipient },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.serviceAccount.options,
                  "recipient",
                  $event.target.value
                )
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "cell-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.serviceAccount.use_ssl,
                    expression: "serviceAccount.use_ssl",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  type: "checkbox",
                  "data-caption": _vm.$t("Use secure connection (SSL/TLS)"),
                  required: "",
                  "data-prepend": "<span class='mif-lock'></span>",
                  disabled: "",
                },
                domProps: {
                  checked: Array.isArray(_vm.serviceAccount.use_ssl)
                    ? _vm._i(_vm.serviceAccount.use_ssl, null) > -1
                    : _vm.serviceAccount.use_ssl,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.serviceAccount.use_ssl,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.serviceAccount,
                            "use_ssl",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.serviceAccount,
                            "use_ssl",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.serviceAccount, "use_ssl", $$c)
                    }
                  },
                },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "cell-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.serviceAccount.options.usePop3,
                    expression: "serviceAccount.options.usePop3",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  type: "checkbox",
                  "data-caption": _vm.$t("Use POP3"),
                  required: "",
                  "data-prepend": "<span class='mif-lock'></span>",
                },
                domProps: {
                  checked: Array.isArray(_vm.serviceAccount.options.usePop3)
                    ? _vm._i(_vm.serviceAccount.options.usePop3, null) > -1
                    : _vm.serviceAccount.options.usePop3,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.serviceAccount.options.usePop3,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.serviceAccount.options,
                            "usePop3",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.serviceAccount.options,
                            "usePop3",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.serviceAccount.options, "usePop3", $$c)
                    }
                  },
                },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "cell-6" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("POP Username")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.serviceAccount.options.popUsername,
                    expression: "serviceAccount.options.popUsername",
                  },
                ],
                staticClass: "metro-input",
                attrs: {
                  "data-role": "input",
                  type: "text",
                  placeholder: _vm.$t("Enter Username"),
                  "data-prepend": "<span class='mif-user'></span>",
                },
                domProps: { value: _vm.serviceAccount.options.popUsername },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount.options,
                      "popUsername",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]),
            _c("div", { staticClass: "cell-6" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Password")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.serviceAccount.options.popPassword,
                    expression: "serviceAccount.options.popPassword",
                  },
                ],
                staticClass: "metro-input",
                attrs: {
                  "data-role": "input",
                  type: "password",
                  placeholder: _vm.$t("Enter Password"),
                  "data-prepend": "<span class='mif-lock'></span>",
                },
                domProps: { value: _vm.serviceAccount.options.popPassword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount.options,
                      "popPassword",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]),
          ]),
        ]),
        _vm.serviceAccount.type == "7"
          ? _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "cell-8" }, [
                  _c("label", [_vm._v("POP Server")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.serviceAccount.options.popServer,
                        expression: "serviceAccount.options.popServer",
                      },
                    ],
                    staticClass: "metro-input",
                    attrs: {
                      "data-role": "input",
                      type: "text",
                      placeholder: _vm.$t("Enter POP Server"),
                      "data-prepend": "<span class='mif-cloud'></span>",
                    },
                    domProps: { value: _vm.serviceAccount.options.popServer },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.serviceAccount.options,
                          "popServer",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("Required"))),
                  ]),
                ]),
                _c("div", { staticClass: "cell-4" }, [
                  _c("label", { staticClass: "text-upper" }, [
                    _vm._v(_vm._s(_vm.$t("POP Port"))),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.serviceAccount.options.popPort,
                        expression: "serviceAccount.options.popPort",
                      },
                    ],
                    staticClass: "metro-input",
                    attrs: {
                      "data-role": "input",
                      type: "text",
                      placeholder: _vm.$t("Enter POP Port"),
                      "data-prepend": "<span class='mif-cloud'></span>",
                    },
                    domProps: { value: _vm.serviceAccount.options.popPort },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.serviceAccount.options,
                          "popPort",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("Required"))),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.$session.get("ROOMPC") == null
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("Select PC to Test Service Account"))),
                ]),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.testPC,
                      expression: "testPC",
                    },
                  ],
                  staticClass: "select cell-9",
                  attrs: {
                    disabled: _vm.$session.get("COMPUTERS").length == 0,
                  },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.testPC = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.$session.get("COMPUTERS"), function (pc) {
                  return _c(
                    "option",
                    {
                      key: pc.id,
                      attrs: {
                        "data-append": pc.note,
                        disabled: !_vm.$session.get("PCSCONNECTIONS")[pc.id],
                      },
                      domProps: { value: pc },
                    },
                    [_vm._v(" " + _vm._s(pc.name) + " ")]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "dialog-actions d-flex flex-justify-end" }, [
        _vm.tested == _vm.TEST_WAIT
          ? _c("div", [_c("span", { staticClass: "mif-spinner2 ani-spin" })])
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "button alert mr-2",
            on: {
              click: function ($event) {
                return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Cancel")))]
        ),
        _c(
          "button",
          {
            staticClass: "button primary mr-2",
            attrs: { disabled: !_vm.enableTest },
            on: { click: _vm.test },
          },
          [
            _c("span", { staticClass: "mif-checkmark pr-2" }),
            _vm._v(" " + _vm._s(_vm.$t("Test account")) + " "),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "button primary",
            attrs: { disabled: !_vm.enableSave },
            on: {
              click: function ($event) {
                return _vm.$root.saveServiceAccount(_vm.serviceAccount)
              },
            },
          },
          [
            _c("span", { staticClass: "mif-checkmark pr-2" }),
            _vm._v(" " + _vm._s(_vm.$t("Save")) + " "),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }