<template>
    <div class="w-100 overflow">
        <Navigation pageName="sessions" />
            <!-- PANNELLO SESSIONI-->
            <div id="all_sessions" class="p-5 w-100 " >
                
                <div class="d-flex flex-justify-start ">
                    <form @submit.prevent="getSessions()" class="inline-form">

                                <select class=" pl-4 select  text-small" v-model="filters.id_group">
                                    <option value="">{{ $t("All Groups") }}</option>
                                    <option v-for="group in groups" :value="group.id_group" v-bind:key="group.id_group">{{ group.name_group }}
                                    </option>
                                    
                                </select>
                            <select class="pl-4 select text-small" v-model="filters.id_user">
                                    <option value="">{{ $t("All Users") }}</option>
                                    <option v-for="user in users" :value="user.id" v-bind:key="user.id">{{ user.username  }}  </option>   
                                </select>
                            <input type="date" class="date select  p-4  text-small text-center" v-model="filters.dt_inf" min="2018-01-01" :max="Date.now() | moment('YYYY-MM-DD')" >
                            <input type="date" class="date select   p-4  text-small text-center" v-model="filters.dt_sup" min="2018-01-01" :max="Date.now() | moment('YYYY-MM-DD')" >
                        
                            <button @click="getSessions()" class="button primary">{{$t("Filter")}} </button>
                        
                    </form>
                </div>
                <div>
                    <div v-show="loading" class="h-25 m-0 p-0 w-100 z-2">
                        <span class="ani-spin mif-5x mif-spinner2 mt-13-minus pos-center"></span>
                    </div>
                    <table v-if="sessions" id="session-list" class="panel compact table w-100 mt-4">
                        <thead class="mb-0">
                            <tr class="mb-3">
                                <th style="width:100"></th>
                                <th class="text-left" style="vertical-align: left;">{{ $t("From Device") }}</th>
                                <th class="text-left" style="vertical-align: middle;white-space:nowrap;">{{ $t("From Device ID") }}</th>
                                <th class="text-left" style="vertical-align: middle;">{{ $t("To Device") }}</th>
                                <th class="text-left" style="vertical-align: middle;white-space:nowrap;">{{ $t("To Device ID") }}</th>
                                <th class="text-center" style="vertical-align: middle;">{{ $t("Organization") }}</th>
                                <th class="text-right" style="vertical-align: middle;">{{ $t("Started at") }}</th>
                                <th class="text-right" style="vertical-align: middle;">{{ $t("Ended at") }}</th>
                                <th class="text-center" style="vertical-align: middle;">{{ $t("Duration") }}</th>
                                <th class="text-center" style="vertical-align: middle;">{{ $t("Notes") }}</th>
                                <th class="text-center" style="vertical-align: middle;"></th>
                            </tr>
                        </thead>

                                <tbody>
                                    
                                    <tr class="pt-10" v-if="!sessions">
                                        <td class="p-6" colspan="7" data-role="activity" data-type="metro" data-style="dark"></td>
                                    </tr>
                                    <!--inizio codice vero-->
                                    <template   v-for="(session,index) in sessions">
                                        <tr v-if="shouldBeAddedAfter(session) && index!=0"><td colspan="11"> <div class="text-bold bg-light" >{{$t("Sessions")}} : {{ sessionrow }}</div></td></tr>
                                        <tr v-if="shouldBeAddedBefore(session)" class="text-bold bg-light"><td colspan="11"> {{$t("Remote Device")}}: {{ session.name_computer_host }} ({{ session.custom_name }})</td></tr>        
                                        <tr>
                                            <td></td>
                                            <td class="no-wrap" >
                                                {{session.firstname + " "+ session.lastname+ " (" + session.name_computer_control +")"}}
                                            </td>
                                            <td>{{session.id_remote_control}}</td>
                                            <!-- <td class="text-left c-pointer" @click="openBackupView(session.id)">{{
                                                    session.firstname + " "+ session.lastname+ " (" + session.name_computer_control +")"}}
                                                </td> -->
                                                <td class="text-center">
                                                    {{ session.name_computer_host }}
                                                </td>
                                                <td class="text-center">
                                                    {{ session.id_remote_host }}
                                                </td>
                                            
                                                <td class="text-center">
                                                    {{ session.organization_name }}
                                                </td>
                                                <td class="text-right no-wrap"><label v-if="session.dt_start != '' && session.dt_start != null">{{formatDate(session.dt_start) }}</label></td>
                                                <td class="text-right no-wrap"><label v-if="session.dt_end != '' && session.dt_end != null">{{  formatDate(session.dt_end)  }}</label></td>
                                                <td class="text-right"><label class="text-bold" v-if="session.dt_end != '' && session.dt_end != null">{{ diffDate(session.dt_start, session.dt_end) }}</label></td>
                                            
                                                <td class="text-center">
                                                    <input type="text" data-role="input" v-model="session.notes[0]" >

                                                </td>
                                                <td>
                                                    <button class="button small">{{ $t("Save")}}</button>
                                                </td>

                                        </tr>
                                        
                                           </template>
                                         <tr><td colspan="9"> <div class="text-bold" >{{$t("Sessions")}} : {{ sessionrow }}</div></td></tr>
                                
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- FINE PANNELLO SESSIONI -->
    </div>
    
</template>
<script>

import Navigation from './utils/Navigation.vue';
import NoDataAvailable from "./utils/NoDataAvailable.vue";
 import {  formatDate, diffDate, fromNow } from '../../public/assets/js/utilitiesmodule';
export default {
    name: "Sessions",
    components:{
        Navigation,
        NoDataAvailable
    },
    
    data() { 
    return { 
        groups: null,
        filteredgroups:null,     
        filters: {
            firstline: true,
            lastline: false,
            sessionrow: 0,
            first_dt_start: 0,
            total_session_inarow: "",
            headergroupname: "",
            numrows: 25,
            offset: 0,
            lastresult: '',
            dt_inf:  this.$moment().subtract(10, 'days').format('YYYY-MM-DD'),
            dt_sup:  this.$moment().format('YYYY-MM-DD'),
            id_group: '',
            id_host: '',
            id_user: '',
            include_stats:"0",
        },
        sessions: null,
        users:null,
        groups:null,
        loading:false,
            } 
    },
    async created() {
        //this.$store.commit('SET_LAYOUT', 'nav-layout')
        this.groups = this.$session.get("GROUPS");
        this.users = this.$session.get("USERS");
       /*  this.$root.$on("finish", message => {
            var mss = message.split("|");
            self.$root.toast(this.$t(mss[0]), 3000, mss[1]);
        }); */
    }, 
    mounted: function () {
        this.headergroupname = "";
        this.sessionrow = 0;
        this.getSessions() 
    },
    methods:{

         //metodo per controllare se devo aggiungere o meno la riga iniziale...
        shouldBeAddedBefore(session) {
            if (this.headergroupname == "" || session.name_computer_control != this.headergroupname) {
                this.headergroupname = session.name_computer_control;
                this.sessionrow = 1;
                this.total_session_inarow = session.total_time;
                return true;
            }
            if (session.name_computer_control == this.headergroupname) {
                this.total_session_inarow += session.total_time;
                this.sessionrow += 1;
                return false;
            }

        },
        shouldBeAddedAfter(session) {
            
            if ( this.headergroupname != "" && session.name_computer_control != this.headergroupname ) {

                return true;
            }
            return false;

        },
        formatDate(date) {
            if(date==null || date =="")return ""
            return formatDate(date, this.$moment);
        },
        fromNow(date) {
                if(date==null || date =="")return ""
                return fromNow(date, this.$moment);
            },
        diffDate(datestart, dateend) {
            return diffDate(datestart, dateend, this.$moment);
        },
         getSessions() {
                let self = this;
                self.loading = true;
                this.$root.api("GETSESSIONS", self.filters).then(res => {
                    self.sessions = res;
                    self.loading = false;
                });
            },
               

     
        
    
        
    
        
    }
}



</script>
