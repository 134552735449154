var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 overflow" },
    [
      _c("Navigation", { attrs: { pageName: "groups" } }),
      _vm.filteredgroups == null
        ? _c("div", { staticClass: "d-flex flex-justify-center p-10" }, [
            _c("span", {
              staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
            }),
          ])
        : _vm.filteredgroups.length == 0
        ? _c("NoDataAvailable", {
            attrs: { message: _vm.$t("No groups available") },
          })
        : _c("div", { staticClass: "p-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-border compact striped bg-white m-0",
                attrs: {
                  "data-check": false,
                  "data-check-name": "chkBox",
                  "data-table-search-title": _vm.$t("Search Groups"),
                  "data-cls-search": "w-100-sm w-25-xl w-25-lg w-50-md",
                  "data-show-activity": "true",
                  "data-cell-wrapper": "false",
                  "data-role": "table",
                  "data-rows": "20",
                  "data-show-rows-steps": "false",
                  "data-show-pagination": "true",
                  "data-horizontal-scroll": "true",
                },
              },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "sortable-column" }, [
                      _vm._v(_vm._s(_vm.$t("Name"))),
                    ]),
                    _c("th", { staticClass: "sortable-column" }, [
                      _vm._v(_vm._s(_vm.$t("Description"))),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "sortable-column",
                        attrs: { "data-cls-column": "text-center  no-wrap" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Email")))]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center no-wrap",
                        attrs: { "data-cls-column": "text-center no-wrap" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Phone Number")) + " ")]
                    ),
                    _c("th", [_vm._v(_vm._s(_vm.$t("Address")))]),
                    _c(
                      "th",
                      {
                        staticClass: "text-center no-wrap",
                        attrs: { "data-cls-column": "text-center  no-wrap" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Devices")))]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center no-wrap",
                        attrs: { "data-cls-column": "text-center  no-wrap" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Users")))]
                    ),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.groups, function (group) {
                    return _c(
                      "tr",
                      {
                        key: group.id_group,
                        staticClass: "mx-8 p-4 rounded m-6",
                      },
                      [
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-bold text-left m-0 button flat-button",
                              attrs: { href: "#" + group.id_group },
                            },
                            [_vm._v(" " + _vm._s(group.name_group) + " ")]
                          ),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(group.notes_group) + " "),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(group.email_group) + " "),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(group.phone_number) + " "),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(group.address_group) + " "),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(group.num_computers) + " "),
                        ]),
                        _c("td", [_vm._v(" " + _vm._s(group.num_users) + " ")]),
                        _c("td", [
                          _c("ul", { staticClass: "listview" }, [
                            _c("li", { staticClass: "mr-2" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "button bg-transparent",
                                  attrs: { href: "#" + group.id_group },
                                },
                                [_c("span", { staticClass: "mif-pencil" })]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "button mr-2 bg-transparent",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteGroup(group)
                                    },
                                  },
                                },
                                [_c("span", { staticClass: "mif-bin" })]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }