<template>
    <li class="p-1 ml-4" :title="disk.path" data-collapsed="true">
      <span class="mif-drive1 mif-lg"></span>
      <!--input class="ml-5" type="checkbox" v-model="disk.selected"-->      
      <span class="ml-3">{{ disk.name }} [{{ prettyBytes(disk.size) }}]</span>
    </li>
  </template>
  
  <script>
  export default {
    props: {
      disk: Object,
    },
    methods: {
      prettyBytes(size) {
        // Funzione per formattare la dimensione in byte
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];
        let i = 0;
        while (size >= 1024 && i < units.length - 1) {
          size /= 1024;
          i++;
        }
        return `${size.toFixed(2)} ${units[i]}`;
      },
   
    },
  };
  </script>
  
  <style scoped>
  .ml-5 {
    margin-left: 5px;
  }
  </style>
  