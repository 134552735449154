import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VOffline } from 'v-offline';
import VueContext from 'vue-context'
import i18n from '@/plugins/i18n';
import FlagIcon from 'vue-country-flag'
import moment from 'moment';
import { EventBus } from './event-bus';
import VueSessionStorage from 'vue-session';
import { WebSocketClient } from './models/WebSocketClient';
import { SignalRClient } from './models/SignalRClient';
import { PlatformsTypesEnum, isValid, platformsTypes, testServiceAccount, ResultValue, ExchangeAccountType, FileFolderEntityType } from '../public/assets/js/utilitiesmodule';
import { loginAPI, serviceAccountAPI, repositoryAPI, backupAPI, userAPI, pcAPI, getRoles, getTypes, getPricing, getEmailProviders, agentAPI, getLicenses } from './router/apiRequests';
import { BACKUP_MESSAGES, EMAILPROVIDERS_NOT_RETRIEVED, GROUPS_NOT_RETRIEVED, LOGIN_FAILED, LOGOUT_FAILED, PC_MESSAGES, PRICING_NOT_RETRIEVED, REGIONS_NOT_RETRIEVED, ROLES_NOT_RETRIEVED, SERVICEACCOUNT_MESSAGES, STATS_NOT_RETRIEVED, USER_MESSAGES, REPOSITORY_MESSAGES, GROUP_MESSAGES, SESSIONS_MESSAGES, TFA_MESSAGES, CONTROLS_NOT_RETRIEVED, COCOD_ERROR_CRYPT, STRING_ERROR_CRYPT, LIC_EXT_INFO_ERR, GET_BACKUP_STATS_ERR } from '../public/assets/js/messages';
import { WebSocketInfo } from './models/WebSocketInfo';
import VueMoment from 'vue-moment';
import CountryFlag from 'vue-country-flag'
import { selectValues } from '../public/assets/js/selectvalues';
import VueApexCharts from 'vue-apexcharts';


Vue.use(VueSessionStorage)
Vue.use(require('vue-cookies'))
Vue.use(VueMoment, { moment })
Vue.component('apexchart', VueApexCharts);
Vue.component('country-flag', CountryFlag)
Vue.config.productionTip = false

//Vue.$cookies.config('7d', '', '', true)
//
/*Nota per i dati in sessione : rimangono memorizzati anche refreshando le pagine
        {
            AUTH : {},
            INIT : "",
            ROOMPC : {},
            JOBS : [],
            SERVICEACCOUNTS : [],
            REPOSITORIES : [],
            PCSCONNECTIONS : {idPC : true/false (stato della connessione)},
            USERCONNECTED : true/false (stato della connessione)
            ONEISCONNECTED : Se ROOMPC è valorizzato, indica se tale pc è connesso o no. Se ROOMPC non è valorizzato, 
            COMPUTERS
        }*/

window.app = new Vue({
    router,
    store,
    i18n,
    VOffline,
    FlagIcon,
    EventBus,
    Metro,
    data() {
        return {
            signalr: null,
            websocket: null,
            checkConnectionTimer: null,
            connectionsStates: [],
            connectionsStatesRemote: [],
            socketConnected: false,

            //Mappe utili
            PlatformsTypesEnum: null,
            platformsTypes: null,
            ResultValue: null,
            ExchangeAccountType: null,
            selectValues: null,
            FileFolderEntityType: null

            //pc: null
        }
    },

    methods: {
        createhintGroups(groupsList, isString = false) {
            var hint = "";
            groupsList.forEach(group => {
                hint += "• " + (isString ? group.toUpperCase() : group.name.toUpperCase()) + "<br> ";
            });
            return hint.substring(0, hint.length - 2);
        },

        format(time) {

            // Hours, minutes and seconds
            var hrs = ~~(time / 3600);
            var mins = ~~((time % 3600) / 60);
            var secs = ~~time % 60;

            // Output like "1:01" or "4:03:59" or "123:03:59"
            var ret = "";
            if (hrs > 0) {
                ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
            }
            ret += "" + mins + ":" + (secs < 10 ? "0" : "");
            ret += "" + secs;

            return ret;
        },

        //Veronica - 08/08/2024
        openDialogActions(
            title = this.$t("Are you sure?"),
            content = "",
            okBtn_text = this.$t("OK,delete it"),
            okBtn_class = "alert",
            okBtn_result = true,

            cancelBtn_text = "Cancel",
            cancelBtn_class = "",
            cancelBtn_result = false
        ) {

            return confirm(this.$t(title) +"\n"+ this.$t(content));
            
            /* let self = this;
            var okFunction = () => self.$root.$emit(content, okBtn_result);
            var cancelFunction = () => self.$root.$emit(content, cancelBtn_result);

            return Metro.dialog.create({
                title: this.$t(title),
                content: this.$t(content),
                clsDialog: "dialog pos-center h-vh-20",
                clsTitle: "h-25",
                clsContent: "minh-none h-50",
                clsAction: "h-25",
                actions: [{
                        caption: this.$t(okBtn_text),
                        cls: "js-dialog-close " + okBtn_class,
                        onclick: okFunction
                    },
                    {
                        caption: this.$t(cancelBtn_text),
                        cls: "js-dialog-close " + cancelBtn_class,
                        onclick: cancelFunction
                    }
                ],
                onClose: function(el) {
                    console.log(el);
                    //this.$root.$emit(content, cancelBtn_result);

                    //return cancelBtn_result;
                }
            }); */

        },

        goBack() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.replace('/')
        },

        //se non ci sta nel local storage lo prendo dalla session
        checkAuth() {
            this.auth = JSON.parse(localStorage.getItem("AUTH"));
            if (this.auth == null) {
                this.auth = this.$session.has("AUTH") ? this.$session.get("AUTH") : null;
            }
            return this.auth;
        },

        // Funzione per generare colori varianti dello stesso colore base
        generateBlueShades(baseColor, numberOfShades) {
            const colors = [];
            for (let i = 1; i <= numberOfShades; i++) {
                const lightness = Math.round(i * (100 / numberOfShades)); // Calcola la luminosità
                const color = 'hsl(' + baseColor + ',68%,' + lightness + '%)'; // Imposta la tonalità del blu a 210 (blu) e varia solo la luminosità
                colors.push(color);
            }
            console.log(colors);
            return colors;
        },

        /*****************************MESSAGGI*****************************************************************************************************/
        //Il messaggio deve essere già tradotto
        //Secondo la documentazione ufficiale di Metro UI (https://docs.metroui.org.ua/toast.html), clsToast deve essere uno dei seguenti valori : 
        // - primary (blu), 
        // - secondary (grigio)
        // - success (verde)
        // - alert (rosso)
        // - warning (arancione)
        // - yellow (giallo)
        // - info (celeste)
        // - light (grigio chiarissimo, quasi trasparente)
        toast(message, timeoutMs, level) {

            var options = {
                showTop: true,
                distance: 120,
                timeout: timeoutMs,
                clsToast: level ? level.toLowerCase() : "info"
            };
            window.Metro.toast.create(message, null, null, null, options);
        },

        //Il messaggio contiene la stessa formattazione utilizzata in C# "blablabla {0} blabla {1} blablablablabla {2}"
        //params è un'array di parametri
        //ad ogni {x} corrispondera il vue-country-flagparametro in posizione x
        formattedToast(message, params, timeoutMs, messageType) {
            for (var i = 0; i < params.length; i++)
                message = message.replace("{" + i + "}", params[i]);

            this.toast(message, timeoutMs, messageType);
        },

        /*****************************API*****************************************************************************************************/

        /**
         * Implementazione:
         * 1- Inserire il case interessato nello switch 
         * 2- Implementare in src/router/apiRequests.js il metodo in questo modo:
         *     2.1 - Se la richiesta va a buon fine, restituisce res.data
         *     2.2 - Se la richiesta non va a buon fine, restituire:
         *         2.2.1 - un array vuoto se ci si aspettava un array
         *         2.2.2 - null se ci si aspettava un oggetto
         *         2.2.3 - false se ci si aspettava un booleano
         * 3- Inserire in public/js/messages.js i messaggi da mostrare nel caso la richiesta del punto 2 non va a buon fine
         * 4- Utilizzare this.toast o this.formattedToast per mostrarli
         * Utilizzo: chiamare this.$root.api(nome_metodo, argomento_1, argomento_2 ecc...)
         */
        async api(method, arg1, arg2, arg3, arg4) {

            var response = null;
            var accessToken = null;
            method = method.toUpperCase();
            var bkpLite;

            if (!method.startsWith("LOGIN") && !method.startsWith("LOGOUT")) {
                var auth = this.checkAuth();
                if (auth != null)
                    accessToken = auth.access_token;
            }

            switch (method) {

                //-------------------------LOGIN--------------------------------------
                case "LOGIN":
                    response = await loginAPI.login(arg1, arg2);
                    if (response == null)
                        this.toast(this.$t(LOGIN_FAILED), 3000, "alert");
                    break;

                case "LOGOUT":
                    response = await loginAPI.logout(arg1);
                    if (!response)
                        this.toast(this.$t(LOGOUT_FAILED), 3000, "alert");
                    break;

                case "GETSESSIONS":
                    response = await loginAPI.getSessions(arg1, accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(SESSIONS_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;
                case "GETSESSIONSLIGHT":
                    response = await loginAPI.getSessionsLight(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(SESSIONS_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;
                case "GETEVENTS":
                    response = await loginAPI.getEvents(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(SESSIONS_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                    //-------------------------TFA--------------------------------------
                case "TOGGLETFA":
                    response = await loginAPI.toggleTFA(arg1);
                    if (response == null)
                        this.toast(this.$t(TFA_MESSAGES.NOT_ENABLED), 3000, "alert");
                    break;
                case "ENABLETFA":
                    response = await loginAPI.enableTFA(arg1, arg2);
                    if (response == null)
                        this.toast(this.$t(TFA_MESSAGES.NOT_ENABLED), 3000, "alert");
                    break;
                case "DISABLETFA":
                    response = await loginAPI.disableTFA(arg1);
                    if (response == null)
                        this.toast(this.$t(TFA_MESSAGES.NOT_DISABLED), 3000, "alert");
                    break;

                case "LOGINTFA":
                    response = await loginAPI.loginTFA(arg1, arg2);
                    if (response == null)
                        this.toast(this.$t(LOGIN_FAILED), 3000, "alert");
                    break;


                case "LOGINTFARECOVERY":
                    response = await loginAPI.loginTFARecovery(arg1);
                    if (response == null)
                        this.toast(this.$t(LOGIN_FAILED), 3000, "alert");
                    break;

                    //-------------------------SERVICEACCOUNT--------------------------------------
                case "GETSERVICEACCOUNTS":
                    response = await serviceAccountAPI.getServiceAccounts(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(SERVICEACCOUNT_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "CREATESERVICEACCOUNT":
                    response = await serviceAccountAPI.createServiceAccount(arg1, accessToken);
                    if (response == null)
                        this.formattedToast(this.$t(SERVICEACCOUNT_MESSAGES.NOT_CREATED), [arg1.name], 3000, "alert");
                    else
                        this.formattedToast(this.$t(SERVICEACCOUNT_MESSAGES.CREATED), [arg1.name], 3000, "success");
                    break;

                case "EDITSERVICEACCOUNT":
                    response = await serviceAccountAPI.editServiceAccount(arg1, accessToken);
                    if (response == null)
                        this.formattedToast(this.$t(SERVICEACCOUNT_MESSAGES.NOT_MODIFIED), [arg1.name], 3000, "alert");
                    else
                        console.log(this.$t(SERVICEACCOUNT_MESSAGES.MODIFIED), [arg1.name], 3000, "success");
                    break;

                case "DELETESERVICEACCOUNT":
                    response = await serviceAccountAPI.deleteServiceAccount(arg1.id, accessToken);
                    if (response)
                        this.formattedToast(this.$t(SERVICEACCOUNT_MESSAGES.DELETED), [arg1.name], 3000, "success");
                    else
                        this.formattedToast(this.$t(SERVICEACCOUNT_MESSAGES.NOT_DELETED), [arg1.name], 3000, "alert");
                    break;

                    //-------------------------REPOSITORY--------------------------------------
                case "GETREPOSITORIES":
                    response = await repositoryAPI.getRepositories(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(REPOSITORY_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "CREATEREPOSITORY":
                    response = await repositoryAPI.createRepository(arg1, accessToken);
                    if (response == null)
                        this.formattedToast(this.$t(REPOSITORY_MESSAGES.NOT_CREATED), [arg1.name], 3000, "alert");
                    else
                        this.formattedToast(this.$t(REPOSITORY_MESSAGES.CREATED), [arg1.name], 3000, "success");
                    break;

                case "EDITREPOSITORY":
                    response = await repositoryAPI.editRepository(arg1, accessToken);
                    if (response == null)
                        this.formattedToast(this.$t(REPOSITORY_MESSAGES.NOT_MODIFIED), [arg1.name], 3000, "alert");
                    else
                        this.formattedToast(this.$t(REPOSITORY_MESSAGES.MODIFIED), [arg1.name], 3000, "success");
                    break;

                case "DELETEREPOSITORY":
                    var repoLite = arg1; // {id:..., name: ...}
                    response = await repositoryAPI.deleteRepository(repoLite.id, accessToken);
                    if (response)
                        this.formattedToast(this.$t(REPOSITORY_MESSAGES.DELETED), [repoLite.name], 3000, "success");
                    else
                        this.formattedToast(this.$t(REPOSITORY_MESSAGES.NOT_DELETED), [repoLite.name], 3000, "alert");
                    break;

                case "GETAMZREGIONS":
                    response = await repositoryAPI.getAmzRegions(accessToken, arg1);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(REGIONS_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                    //--------------------PC-------------------------------------
                case "GETPCLIST":
                    response = await pcAPI.getPCList(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break
                case "GETPCREMOTECONNECTEDLIST":
                    response = await pcAPI.getPCRemoteConnected(arg1, accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "REMFROMLICENSE":
                    response = await pcAPI.removeFromLicense(arg1, accessToken);
                    if (response == null) {
                        response = false;
                        this.toast(this.$t(PC_MESSAGES.NO_RESPONSE), 3000, "alert");
                    }
                
                    break;
                case "ADDTOLICENSE":
                    response = await pcAPI.addToLicense(arg1, accessToken);
                    if (response == null) {
                        response = false;
                        this.toast(this.$t(PC_MESSAGES.NO_RESPONSE), 3000, "alert");
                    }
                    break;

                case "GETPCLISTBYGROUPID":
                    response = await pcAPI.getPcListByGroupID(arg1, accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETOTP":
                    response = await pcAPI.getOTP(arg1, accessToken);

                    break;

                case "GETPCLISTLITE":
                    response = await pcAPI.getPCListLite(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETPCLISTWITHSUBDIRECTORIES":
                    response = await pcAPI.getPCListWithSubdirectories(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(PC_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETPC":
                    response = await pcAPI.getPC(arg1, accessToken);
                    if (response == null) {
                        this.toast(this.$t(PC_MESSAGES.NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "EDITPC":
                    response = await pcAPI.editPC(arg1, accessToken);
                    if (response == null) {
                        this.toast(this.$t(PC_MESSAGES.NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETSTATSFROMPC":
                    response = await pcAPI.getStatsFromPC(arg1, accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(STATS_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETSTATSFROMLICENSE":
                    response = await pcAPI.getStatsFromLicense(accessToken, arg1, arg2);
                    if (response == null)
                        this.toast(this.$t(STATS_NOT_RETRIEVED), 3000, "alert");
                    break;

                case "GETSTATSFROMLICENSELIGHT":
                    response = await pcAPI.getStatsFromLicenseLight(arg1, accessToken);
                    if (response == null)
                        this.toast(this.$t(STATS_NOT_RETRIEVED), 3000, "alert");
                    break;

                    //-------------------------BACKUP---------------------------
                case "CREATEBACKUP":
                    response = await backupAPI.createBackup(arg1, accessToken);
                    if (response == null)
                        this.toast(this.$t(BACKUP_MESSAGES.NOT_CREATED), 3000, "alert");
                    //Il messaggio di buona riuscita sarà mostrato dopo la chiamata socket associata

                    break;

                case "EDITBACKUP":
                    response = await backupAPI.editBackup(arg1, accessToken);
                    if (response == null)
                        this.toast(this.$t(BACKUP_MESSAGES.NOT_MODIFIED), 3000, "alert");
                    //Il messaggio di buona riuscita sarà mostrato dopo la chiamata socket associata

                    break;

                case "DELETEBACKUP":
                    bkpLite = arg1; // {id:..., name :...}
                    response = await backupAPI.deleteBackup(bkpLite.id, accessToken);
                    if (!response)
                        this.formattedToast(this.$t(BACKUP_MESSAGES.NOT_DELETED), [arg1.name], 3000, "alert");
                    //Il messaggio di buona riuscita sarà mostrato dopo la chiamata socket associata

                    break;

                case "RENAMEBACKUP":
                    bkpLite = arg1; // {id:..., oldName:..., newName:...}
                    response = await backupAPI.renameBackup(bkpLite.id, bkpLite.newName, accessToken);
                    if (!response)
                        this.formattedToast(this.$t(BACKUP_MESSAGES.NOT_RENAMED), [bkpLite.oldName, bkpLite.newName], 3000, "alert");
                    //Il messaggio di buona riuscita sarà mostrato dopo la chiamata socket associata
                    break;
                case "GETBACKUPWITHID":
                    response = await backupAPI.getBackupWithID(arg1, accessToken);
                    if (response == null) {
                        this.toast(this.$t(BACKUP_MESSAGES.NOT_RETRIEVED), 3000, "alert");
                    }
                    break;
                case "GETBACKUPLOGS":
                    response = await backupAPI.getBackupLogs(arg1, accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(BACKUP_MESSAGES.LOGS_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETBACKUPLOGCONFIGURATION":
                    response = await backupAPI.getBackupLogConfiguration(arg1, accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(BACKUP_MESSAGES.LOGS_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETBACKUPS":
                    response = await backupAPI.getBackups(arg1, arg2, arg3, arg4, accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(BACKUP_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETIDLEBACKUPS":
                    response = await backupAPI.getIdleBackups(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(BACKUP_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                    //----------------------USER--------------------------------------
                case "GETUSERS":
                    response = await userAPI.getUsers(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(USER_MESSAGES.LIST_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "CREATEUSER":
                    response = await userAPI.createUser(arg1, accessToken);
                    if (response == null)
                        this.toast(this.$t(USER_MESSAGES.NOT_CREATED), 3000, "alert");
                    else
                        this.toast(this.$t(USER_MESSAGES.CREATED), 3000, "success");
                    break;

                case "EDITUSER":
                    response = await userAPI.editUser(arg1, accessToken);
                    if (response == null)
                        this.toast(this.$t(USER_MESSAGES.NOT_MODIFIED), 3000, "alert");
                    else
                        this.toast(this.$t(USER_MESSAGES.MODIFIED), 3000, "success");
                    break;

                case "DELETEUSER":
                    response = await userAPI.deleteUser(arg1.id, accessToken);
                    if (response)
                        this.toast(this.$t(USER_MESSAGES.DELETED), [arg1.username], 3000, "success");
                    else
                        this.toast(this.$t(USER_MESSAGES.NOT_DELETED), [arg1.username], 3000, "alert");
                    break;


                    //------------ALTRO-------------------------------------------
                case "GETGROUPS":
                    response = await pcAPI.getGroups(accessToken);
                    if (response == null) {
                        this.toast(this.$t(GROUPS_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;
                case "GETCONTROLS":
                    response = await pcAPI.getControls(accessToken);
                    if (response == null) {
                        this.toast(this.$t(CONTROLS_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "CREATEGROUP":
                    response = await pcAPI.createGroup(arg1, accessToken);
                    if (response == null)
                        this.toast(this.$t(GROUP_MESSAGES.NOT_CREATED), 3000, "alert");
                    else
                        this.toast(this.$t(GROUP_MESSAGES.CREATED), 3000, "success");
                    break;

                case "EDITGROUP":
                    response = await pcAPI.editGroup(arg1, accessToken);
                    if (response == null)
                        this.toast(this.$t(GROUP_MESSAGES.NOT_MODIFIED), 3000, "alert");
                    else
                        this.toast(this.$t(GROUP_MESSAGES.MODIFIED), 3000, "success");
                    break;

                case "GETGROUP":
                    response = await pcAPI.getGroup(arg1, accessToken);
                    break;

                case "GETROLES":
                    response = await getRoles(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(ROLES_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETTYPES":
                    response = await getTypes(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(TYPES_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETPRICING":
                    response = await getPricing(arg1, accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(PRICING_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETLICENSES":
                    response = await getLicenses(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(LICENSES_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETEMAILPROVIDERS":
                    response = await getEmailProviders(accessToken);
                    if (response == null) {
                        response = [];
                        this.toast(this.$t(EMAILPROVIDERS_NOT_RETRIEVED), 3000, "alert");
                    }
                    break;

                case "GETCOCOD":
                    response = await agentAPI.getCoCod(arg1, accessToken);
                    if (response == null) {
                        this.toast(this.$t(COCOD_ERROR_CRYPT), 3000, "alert");
                    }
                    break;
                case "CRYPTSTR":
                    response = await agentAPI.getCrio(arg1, accessToken);
                    if (response == null) {
                        this.toast(this.$t(STRING_ERROR_CRYPT), 3000, "alert");
                    }
                    break;
                case "LICINFOEXT":
                    response = await agentAPI.getLicenseExtendedInfo(arg1, accessToken);
                    if (response == null) {
                        this.toast(this.$t(LIC_EXT_INFO_ERR), 3000, "alert");
                    }
                    break;
                case "GETBACKUPSTATS":
                    response = await loginAPI.getBackupstats(arg1, accessToken);
                    if (response == null) {
                        this.toast(this.$t(GET_BACKUP_STATS_ERR), 3000, "alert");
                    }
                    break;

                case "GETLOCALPUBLICIP":
                    response = await loginAPI.getLocalPublicIP();
                    break;
                case "DELETEGROUP":
                    response = await pcAPI.deleteGroup(arg1, accessToken);
                    if (!response)
                        this.toast(this.$t(GROUP_MESSAGES.NOT_DELETED), 3000, "alert");
                    break;
            }

            return response;
        },
        /****************************INIZIO CHIAMATE SOCKET*********************************************************************************************************/
        /**
         * Implementazione
         * 1- In src/models/WebSocketInfo:
         *     1.2 - Aggiungere nel metodo buildInfo il case adeguato, in cui si implementa
         *         1.2.1 - Il caso in cui si chiama il metodo prima di effettuare la richiesta (forRequest=true + eventuali argomenti in arg1, arg2...)
         *         1.2.2 - Il caso in cui la risposta è andata a buon fine (forRequest=false + okResponse=true + arg1 eventuale dato ritornato dalla risposta).
         *                 Se la risposta è stata ricevuta con un oggetto in cui msg_type==data (vedere i msg_type in src/models/WebSocketClient.js -> onMessgeCallback), arg1 è il dato ricevuto.
         *                 Altrimenti okResponse è l'esito dela chiamata, quindi aggiungere in public/js/messages.js l'eventuale messaggio relativo alla buona riuscita della chiamata
         *         1.2.3 - Il caso in cui la risposta non è andata a buon fine (forRequest=false + okResponse=false). 
         *                 In questo caso verrà generato un toast per comunicare l'errore, quindi aggiungere in public/js/messages.js il relativo messaggio
         * Utilizzo: chiamare this.$root.socket("medoto_socket", array_di_argomenti_da_passare_al_messaggio_da_visualizzare, argomento_1, argomento_2 ecc).
         */
        async socket(message, arg1, arg2, arg3) {
            let self = this;

            //Step 1 : Controllo la connessione. Se fallisce
            if (!this.socketConnected) {
                //Step 1.1 : si visualizza un toast per comunicare all'utente che non è connesso
                //this.connectionMessage(false);
                // Step 1.2 : si ritorna il risultato di default
                var errorResult = WebSocketInfo.buildInfo(message.toUpperCase(), false, false, { text: PC_MESSAGES.NOT_CONNECTED, msgType: "ERROR", msgArgs: null });
                return errorResult.result;
            }

            if (message.toUpperCase() == "SETPC") {
                this.websocket.idPC = arg1 == null ?
                    null :
                    typeof(arg1) == "string" ?
                    arg1 :
                    arg1.id; // messageargs è l'id o il pc da impostare
                return;
            }

            //Step 2 : Si tenta la chiamata.
            var info = await self.websocket.call(message, arg1, arg2, arg3);
            // Step 3 : Se l'oggetto ritornato ha un messaggio che deve essere visualizzato, ne creo un toast
            if (isValid(info.message)) {
                // Caso 3.1 : Se il messaggio comunica che il computer non è connesso e sono in un pc
                if (info.message.text == PC_MESSAGES.NOT_CONNECTED && isValid(this.$session.get("ROOMPC"))) {
                    info.message.msgArgs = [this.$session.get("ROOMPC").pc_name];
                } else if (info.message.text == PC_MESSAGES.NOT_CONNECTED && isValid(this.$session.get("COMPUTERS")) && this.$session.get("COMPUTERS").length > 0) {
                    // Caso 3.2 : Se il messaggio comunica che il computer non è connesso ed è stato impostato un pc per la connesisone socket
                    info.message.msgArgs = [this.$session.get("COMPUTERS").find(pc => pc.id == this.websocket.idPC).pc_name];
                }
                // Caso 3.3 : Se il messaggio da visualizzare non ha argomenti, creo un toast normale
                if (info.message.msgArgs == null)
                    this.toast(this.$t(info.message.text), 3000, info.message.msgType);
                else // Caso 3.4 : Se il messaggio da visualizzare ha argomenti, creo un toast formattato
                    this.formattedToast(this.$t(info.message.text), info.message.msgArgs, 3000, info.message.msgType);
            }

            //Step 4 : Restituisco il risultato
            return info.result;
        },

        checkSocket() {
            let self = this;
            //Caso 1 : Non è stata mai instanziata la connessione: creo la connessione
            if (self.websocket == null) {
                console.log("websocket è null!!!!");
                self.websocket = new WebSocketClient(this.$session.get("AUTH"), this.emitFunction, this.translateFunction);
            }
            self.websocket.checkConnection();
        },


        async closeSocket() {
            if (this.websocket != null && this.websocket.websocket != null) {
                await this.websocket.websocket.close();
            }
            this.websocket = null;
        },

        async checkSignalR(idPC) {
            let self = this;

            if (self.signalr == null) self.signalr = await (new SignalRClient(process.env.VUE_APP_SIGNALR_BASE_URL + "iperiusHub?client=C_" + idPC, idPC, self.$root.$emit, this.$t)).connect();

            return self.signalr != null;
        },


        /*****************************SESSION*****************************************************************************************************/

        emitFunction(message, data) {
            if (message == "realtimeMessages") {
                if (data.msgArgs != null)
                    this.formattedToast(this.$t(data.text), data.msgArgs, 3000, data.msgType);
                else
                    this.toast(this.$t(data.text), 3000, data.msgType);

            } else {

                this.$root.$emit(message, data);
                //this.$emit(message, data);
            }
        },

        translateFunction(message) {
            return this.$t(message);
        },



        //MA: aggiornamento checkConnectionTimerAction con questo semplificato
        async checkConnectionTimerLight() {
            let self = this;

            if (self.checkConnectionTimer != null || !isValid(self.$session.get("COMPUTERS"))) {
                return;
            }
            var roomPC;
            self.checkConnectionTimer = true;
            if (self.socketConnected) {
                self.connectionsStates = await self.socket("is_online", self.$session.get("COMPUTERS").map(pc => pc.id));
                self.$session.set("PCSCONNECTIONS", self.connectionsStates);

                roomPC = self.$session.get("ROOMPC");
                if (isValid(roomPC)) {
                    self.$session.set("ONEISCONNECTED", self.connectionsStates[roomPC.id]);
                    return;
                }
                //Se non sono in un pc, controllo la risposta di "is_online", che sarà "true" se c'è almeno un valore idPC: true
                self.$session.set("ONEISCONNECTED", Object.values(self.connectionsStates).some(isConnectedValue => isConnectedValue));
            }
            self.checkConnectionTimer = setInterval(async() => {
                //Ritorna un oggetto in cui le chiavi sono gli id dei computer e i valori sono booleani che indicano se quel pc è connesso o no
                self.connectionsStates = await self.socket("is_online", self.$session.get("COMPUTERS").map(pc => pc.id));
                //Alcune operazioni sono disattivate se tutti i computer sono disconnessi.
                //Quindi è utile memorizzare una variabile che indica se c'è almeno un pc connesso ("ONEISCONNECTED")
                //Se sono in un pc, mi interessa sapere se questo è connesso
                self.$session.set("PCSCONNECTIONS", self.connectionsStates);

                roomPC = self.$session.get("ROOMPC");
                if (isValid(roomPC)) {
                    self.$session.set("ONEISCONNECTED", self.connectionsStates[roomPC.id]);
                    return;
                }
                //Se non sono in un pc, controllo la risposta di "is_online", che sarà "true" se c'è almeno un valore idPC: true
                self.$session.set("ONEISCONNECTED", Object.values(self.connectionsStates).some(isConnectedValue => isConnectedValue));
            }, 30000);

        },


        closeConnectionTimerLight() {
            this.$root.$off("is_online");
            clearInterval(this.checkConnectionTimer);
        },

        async getOTP(idPC) {
            var Key_OTP = await this.api("GETOTP", { pcId: idPC });
            console.log(Key_OTP);

            var win = window.open('iperiusremote://' + Key_OTP + '/', '_blank');
            win.getValue = function() {
                return Key_OTP;
            };
        },

        async testServiceAccount(serviceAccount, pc) {
            var response = {
                ok: false,
                okNumber: 0,
                serviceAccount: serviceAccount,
                list: null,
                client: null
            };

            //Cloud
            if (this.isCloud(serviceAccount.type) || this.isMicrosoft365(serviceAccount.type)) {
                response = await testServiceAccount(serviceAccount);
                if (!isValid(response.serviceAccount))
                    response.serviceAccount = serviceAccount;
                if (!response.ok) {
                    return response;
                }
                if (isValid(response.serviceAccount.id))
                    this.api("EDITSERVICEACCOUNT", response.serviceAccount);
                return response;
            }


            //Raggruppo tutti i service account i cui test necessitano di una chiamata socket, quindi di un pc
            //Questo pc sarà:
            // - this.$session.get('ROOMPC') se sono dentro un computer
            // - pc passato se + stato passato un computer con cui eseguire il test
            // - serviceAccount.options.lastTestPC (è un id di un computer) se è stato salvato nel service account ed è attualmente connesso
            if (isValid(this.$session.get('ROOMPC')))
                pc = this.$session.get('ROOMPC');
            else if (isValid(pc))
                await this.socket("SETPC", pc);
            else if (isValid(serviceAccount.options.lastTestPC) && this.$session.get("PCSCONNECTIONS")[serviceAccount.options.lastTestPC]) {
                pc = this.$session.get("COMPUTERS").find(c => c.id == serviceAccount.options.lastTestPC);
                await this.socket("SETPC", pc);
            } else {
                this.toast(this.$t(SERVICEACCOUNT_MESSAGES.TEST_FAILED), 5000, "alert");
                return response;
            }

            //EMAIL
            if (this.isEmail(serviceAccount.type)) {
                response.ok = await this.socket("SendEmailTest", serviceAccount, serviceAccount.options.recipient);
                response.okNumber = response.ok ? 1 : 0;

            } else

            //EXCHANGE ON PREMISES
            if (this.isExchangeOnPremises(serviceAccount.type)) {
                response.ok = await this.socket("testserviceaccount", serviceAccount);
                response.okNumber = response.ok ? 1 : 0;

                if (response.ok && isValid(serviceAccount.id)) {
                    response.list = await this.socket("GetExchangeMailboxes", serviceAccount.id);
                    response.ok = response.list != null;
                    response.okNumber = response.ok ? 1 : 0;
                }

            } else

            //DATABASE
            if ([PlatformsTypesEnum.Database_SQLServer, PlatformsTypesEnum.Database_MySQL].includes(serviceAccount.type)) {
                response.ok = await this.socket("testserviceaccount", serviceAccount);
                response.okNumber = response.ok ? 1 : 0;

                if (response.ok && isValid(serviceAccount.id)) {
                    response.list = await this.socket("GetDatabaseList", serviceAccount.id);
                    response.ok = response.list != null;
                    response.okNumber = response.ok ? 1 : 0;
                }


            } else {
                var requestData;
                var fsOptions = { // recupero il meno possibile perchè è un test
                    includeFiles: false,
                    includeHiddenFiles: false,
                    includeInaccessibleFiles: false,
                    includeSystemFiles: false,
                    includeDetails: false
                };
                switch (serviceAccount.type) {
                    case PlatformsTypesEnum.FTP:
                        var accountSessionId_FTP = await this.socket("connectftp", serviceAccount);

                        if (accountSessionId_FTP == "") {
                            response.ok = false;
                            response.okNumber = 0;
                            break;
                        }
                        response.ok = true;
                        response.okNumber = 1;
                        serviceAccount.options.accountSessionId = accountSessionId_FTP;
                        response.serviceAccount = serviceAccount;
                        requestData = {
                            accountSession: accountSessionId_FTP,
                            path: serviceAccount.options.path,
                            options: fsOptions,
                            nodeID: "node"
                        };
                        response.list = await this.socket("exploreftp", requestData);
                        if (response.list == null) {
                            response.ok = false;
                            response.okNumber = 0;
                            break;
                        }
                        response.ok = true;
                        response.okNumber = 1;
                        response.client = { "serviceAccount": serviceAccount };
                        break;
                    case PlatformsTypesEnum.ESXi:
                        response.ok = await this.socket("testserviceaccount", serviceAccount);
                        if (response.ok)
                            response.okNumber = 1;
                        //non richiedo la lista poiché la chiamata è molto lenta
                        break;

                    case PlatformsTypesEnum.Network:
                        var accountSessionId_NETWORK = await this.socket("connectnetwork", serviceAccount);

                        if (accountSessionId_NETWORK == "") {
                            response.ok = false;
                            response.okNumber = 0;
                            break;
                        }
                        response.ok = true;
                        response.okNumber = 1;
                        serviceAccount.options.accountSessionId = accountSessionId_NETWORK;
                        response.serviceAccount = serviceAccount;

                        requestData = {
                            accountSession: accountSessionId_NETWORK,
                            path: serviceAccount.options.path,
                            options: fsOptions,
                            nodeID: "node"
                        };
                        //ritorna la lista di ClientTreeNode
                        response.list = await this.socket("getexplorenetworkfs", requestData);
                        if (response.list == null) {
                            response.ok = false;
                            response.okNumber = 0;
                            break;
                        }
                        response.ok = true;
                        response.okNumber = 1;
                        response.client = { "serviceAccount": serviceAccount };
                        break;
                }
            }

            if (this.$session.get('ROOMPC') == null)
                await this.socket("SETPC", null);

            if (response.ok) {
                response.serviceAccount.options.lastTestPC = pc.id;
                if (isValid(response.serviceAccount.id))
                    this.api("EDITSERVICEACCOUNT", response.serviceAccount);
                return response;
            }
            return response;
        },

        async saveServiceAccount(serviceAccount) {
            var method = isValid(serviceAccount.id) ? "EDITSERVICEACCOUNT" : "CREATESERVICEACCOUNT";
            var newServiceAccount = await this.api(method, serviceAccount, this.checkAuth().access_token);

            if (!isValid(newServiceAccount))
                return;

            this.$session.set("SERVICEACCOUNTS", await this.api("GETSERVICEACCOUNTS"));
            this.$root.$emit("REFRESHSERVICEACCOUNTS");
            this.$root.$emit('CLOSESERVICEACCOUNTDIALOG');
        },

        async saveRepository(repository) {
            var method = isValid(repository.id) ? "EDITREPOSITORY" : "CREATEREPOSITORY";
            var newRepository = await this.api(method, repository, this.checkAuth().access_token);

            if (!isValid(newRepository))
                return;

            this.$session.set("REPOSITORIES", await this.api("GETREPOSITORIES"));
            this.$root.$emit("REFRESHREPOSITORIES");
            this.$root.$emit('CLOSEREPOSITORYDIALOG');
        },

        isCloud(type) {
            return platformsTypes[type].mainType == 1;
        },

        isMicrosoft(type) {
            return platformsTypes[type].mainType == PlatformsTypesEnum.Microsoft;
        },

        isMicrosoft365(type) {
            return this.isMicrosoft(type) && !this.isExchangeOnPremises(type);
        },

        getMicrosoftTypes() {
            return Object.keys(platformsTypes).filter(t => platformsTypes[t].mainType == PlatformsTypesEnum.Microsoft && platformsTypes[t].enabled).map(t => parseInt(t));
        },
        getCloudTypes() {
            return Object.keys(platformsTypes).filter(t => platformsTypes[t].mainType == PlatformsTypesEnum.Cloud && platformsTypes[t].enabled).map(t => parseInt(t));
        },
        getEmailTypes() {
            return Object.keys(platformsTypes).filter(t => platformsTypes[t].mainType == PlatformsTypesEnum.Email && platformsTypes[t].enabled).map(t => parseInt(t));
        },

        getServiceAccountsTypes() {
            return Object.keys(platformsTypes).filter(t => platformsTypes[t].forServiceAccount && platformsTypes[t].enabled && platformsTypes[t].mainType == -1).map(t => parseInt(t)).concat([60]);
        },
        getRepositoriesTypes() {
            return Object.keys(platformsTypes).filter(t => platformsTypes[t].forRepository && platformsTypes[t].enabled && platformsTypes[t].mainType == -1).map(t => parseInt(t));
        },
        getBackupSourcesTypes() {
            return Object.keys(platformsTypes).filter(t => platformsTypes[t].forBackupSource && platformsTypes[t].enabled && platformsTypes[t].mainType == -1).map(t => parseInt(t));
        },
        getRestoreFileDestinationTypes() {
            return [
                this.PlatformsTypesEnum.FileFolder,
                this.PlatformsTypesEnum.Network,
                this.PlatformsTypesEnum.FTP,
                this.PlatformsTypesEnum.Cloud_AmazonS3Storage,
                this.PlatformsTypesEnum.Cloud_AzureBlob,
                this.PlatformsTypesEnum.Cloud_AzureFile,
                this.PlatformsTypesEnum.Cloud_Dropbox,
                this.PlatformsTypesEnum.Cloud_GoogleDrive,
                this.PlatformsTypesEnum.Cloud_IperiusS3Storage,
                this.PlatformsTypesEnum.Cloud_OneDrive,
                this.PlatformsTypesEnum.Cloud_S3CompatibleStorage,
                this.PlatformsTypesEnum.Microsoft_OneDriveForBusiness,
                this.PlatformsTypesEnum.Microsoft_SharePoint,
                this.PlatformsTypesEnum.Microsoft_Teams
            ]
        },

        isExchangeOnPremises(type) {
            return type == PlatformsTypesEnum.Microsoft_ExchangeOnPremises || type == PlatformsTypesEnum.Microsoft_ExchangeOnPremisesEWS;
        },

        isExchange(type) {
            return [
                PlatformsTypesEnum.Microsoft_ExchangeOnPremises,
                PlatformsTypesEnum.Microsoft_ExchangeOnPremisesEWS,
                PlatformsTypesEnum.Microsoft_Exchange365
            ].includes(type);
        },
        isEmail(type) {
            return platformsTypes[type].mainType == PlatformsTypesEnum.Email;
        },
        isDatabase(type) {
            return platformsTypes[type].mainType == PlatformsTypesEnum.Database
        },
        isSimpleFileNFolderType(type) {
            return this.isCloud(type) || //10, 110, 111, 112, 12, 13, 140, 141
                (this.isMicrosoft365(type) && !this.isExchange(type)) || //53, 54, 55
                [PlatformsTypesEnum.FTP, PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network].includes(type)
        },
        isRestoreFile(restoreDestinationType) {
            switch (restoreDestinationType) {
                case PlatformsTypesEnum.FileFolder:
                case PlatformsTypesEnum.Network:
                case PlatformsTypesEnum.Microsoft_SharePoint:
                case PlatformsTypesEnum.Microsoft_Teams:
                case PlatformsTypesEnum.Microsoft_OneDriveForBusiness:
                    return true;
            }
            return false;
        },


        getNameFromType(type) {
            return platformsTypes[type].name;
        }

        // syncronize(idPC, socket_backups) {
        //     var list, method;
        //     //Se è stao passato un pc non valido, controllo se in sessione sono stati salvati service account o repositories
        //     if (!isValid(idPC)) {
        //         list = this.$session.get("SERVICEACCOUNTS");

        //         if (list != null) {
        //             list.forEach(sa => {
        //                 method = isValid(sa.id) ? "EDITSERVICEACCOUNT" : "CREATESERVICEACCOUNT";
        //                 this.api(method, sa);
        //                 this.session("SERVICEACCOUNTS", "DELETE");
        //             });
        //         }

        //         list = this.session("REPOSITORIES", "GET");
        //         if (list != null) {
        //             list.forEach(repo => {
        //                 method = isValid(repo.id) ? "EDITREPOSITORY" : "CREATEREPOSITORY";
        //                 this.api(method, repo);
        //                 this.session("REPOSITORIES", "DELETE");
        //             });
        //         }

        //         list = this.session("JOBS", "GET");
        //         if (list != null) {
        //             list.forEach(job => {
        //                 method = isValid(job.id) ? "EDITBACKUP" : "CREATEBACKUP";
        //                 this.api(method, job);
        //                 this.session("JOBS", "DELETE");
        //             });
        //         }
        //         return;
        //     }

        //     let self = this;
        //     var complete_api_job;
        //     //Se è stato passato un idPC valido, extradata corrisponde alla lista dei backup recuperati tramite socket (in realtime)
        //     this.api("GETBACKUPS", idPC, 99, 25, 0).then(api_backups => {
        //         if (api_backups == null)
        //             return;

        //         api_backups = api_backups.filter(job => job.id_computer == idPC);
        //         if (api_backups.length == 0)
        //             return;

        //         //Per sincronizzare i dati si da priorità al database.
        //         //Ciò che è presente lì è stato creato
        //         //Ciò che non è presente lì, è stato eliminato
        //         api_backups.forEach(async api_job => {
        //             //Se la lista socket non contiene un job presente nella lista api
        //             //eseguo la chiamata socket per creare il job
        //             if (!socket_backups.some(socket_job => socket_job.id == api_job.id)) {
        //                 complete_api_job = await self.api("GETBACKUPWITHID", api_job.id);
        //                 if (complete_api_job != null)
        //                     await self.socket("CreateJob", [api_job.name], complete_api_job);
        //             }
        //         });
        //         var deleted;
        //         socket_backups.forEach(async socket_job => {
        //             //Se nella lista socket trovo un backup non presente nella lista api,
        //             //eseguo la chiamata per eliminare il job
        //             if (!api_backups.some(api_job => api_job.id == socket_job.id)) {
        //                 deleted = await self.socket("DeleteJob", [socket_job.name], socket_job.id);
        //                 console.log("Il job " + socket_job.name + " è presente sul pc ma non nel db, quindi ho provato a eliminarlo. Risultato = " + (deleted))
        //                     //alert("Il job " + socket_job.name + " è presente sul pc ma non nel db, quindi ho provato a eliminarlo. Risultato = " + (deleted));
        //             }

        //         });
        //     })


        // },
        //https://codepen.io/m5dev/pen/oLamKE
        // Disable key press
        /*disableKeyPressing(e) {

          // keycodes table https://css-tricks.com/snippets/javascript/javascript-keycodes/
          var conditions = [
              // Diable F5
              (e.which || e.keyCode) == 116,
              // Diable Ctrl+R
              e.ctrlKey && (e.which === 82)
          ]

          if ($.each(conditions, function(key, val) { val + ' || ' })) {
              e.preventDefault();
          }
        }*/
    },
    components: {
        VueContext,
    },
    created: function() {
        var self = this;
        this.platformsTypes = platformsTypes;
        this.PlatformsTypesEnum = PlatformsTypesEnum;
        this.ResultValue = ResultValue;
        this.ExchangeAccountType = ExchangeAccountType;
        this.selectValues = selectValues;
        this.FileFolderEntityType = FileFolderEntityType;
        //require("dotenv").config();
        window.addEventListener('beforeunload', async function(event) {
            await self.closeSocket();
        });

        if(this.$session.get("AUTH")){
            this.checkSocket();
        }

        //Metro.init;
        /*let self = this;
        $(document).on('keydown', function(e) {
            // F5 is pressed
            if (e.key == "F5") {
                self.disableKeyPressing(e);
                console.log('F5 is diabled now');
            }

            // Ctrl+R
            if (e.ctrlKey && (e.which === 82)) {
                self.disableKeyPressing(e);
                console.log('Ctrl+R is pressed and refresh is diabled now');
            }
        }); */

    },
    beforeDestroy: async function() {
        this.$off("socketEventConnected");
        this.$off("socketEventDisconnected");
        await this.closeSocket();
    },

    /* mounted: function() {
        Metro.init;
    }, */
    render: h => h(App),
});


app.$mount('#app');

Vue.filter('utcAsLocal', function(value) {
    return moment.utc(value).local();
});