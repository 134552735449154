/*
Metodi di autorizzazione  : https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-requests-to-azure-storage
https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-with-shared-key#blob-queue-and-file-services-shared-key-authorization
*/
import api from "./api";
import { createHmac } from "crypto";
import { ClientTreeNodeMethods, ClientTreeNode } from "../models/TreeNode";
import { FileFolderEntityType, PlatformsTypesEnum, applyBucketsRules } from "../../public/assets/js/utilitiesmodule";

const version = "2014-02-14";
export class AzureBlobClient {
    constructor(accountName, accessKey) {
        this.accountName = accountName;
        this.accessKey = accessKey;
        this.nodes = [];
    }

    /*
    Documentazione : 
    https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-with-shared-key#blob-queue-and-file-services-shared-key-authorization
    https://techcommunity.microsoft.com/t5/azure-paas-blog/the-mac-signature-found-in-the-http-request-xxxx-is-not-the-same/bc-p/3721224#M480
    */
    //Signature=Base64(HMAC-SHA256(UTF8(StringToSign), Base64.decode(<your_azure_storage_account_shared_key>)))
    async getRootNodes() {

        if (this.nodes.length != 0 && this.nodes.some(r => r.nParts == 1))
            return this.nodes.filter(r => r.nParts == 1);

        var urlToRequestContainers = "https://" + this.accountName + ".blob.core.windows.net/?comp=list";
        var canonicalizedResource = "/" + this.accountName + "/\ncomp:list";

        var now = new Date().toUTCString();

        var hash = buildHash(
            this.accessKey, [
                "x-ms-date:" + now,
                "x-ms-version:" + version
            ],
            "GET",
            canonicalizedResource
        );

        var headers = {
            "Authorization": "SharedKey " + this.accountName + ":" + hash,
            "x-ms-date": now,
            "x-ms-version": version
        };

        //Step 5 : Effettuo la richiesta
        var containers = await api.get(urlToRequestContainers, {
                headers: headers
            })
            .then(res => {
                //console.log(res);
                //res.data è un html. I nomi dei containers sono inclusi tra i tags <Name></Name>
                var containersList = res.data.split("</Name>").filter(html => html.indexOf("<Name>") != -1).map(html => {
                    return new ClientTreeNode(
                        null,
                        null,
                        html.split("<Name>")[1],
                        FileFolderEntityType.Cloud_Bucket
                    );
                });

                return containersList;
            })
            .catch(e => {
                console.log(e);
                return null;
            });

        this.nodes = this.nodes.concat(containers);
        //console.log(containers);
        return containers;

    }

    async getNodesFrom(resource, searchInResources = true) {
        resource.path = applyBucketsRules(resource.path);

        //Step 1 : Controllo se sono già state salvare in locale le risorse richieste
        if (this.nodes.length != 0) {

            var children = this.nodes.filter(r => r.nParts == resource.nParts + 1);
            children = children.filter(r => r.path.startsWith(resource.path + "/"));
            //Step 2 : Se non sono state salvate o non le devo richiedere al cloud, ritorno le risorse trovate
            if (children.length != 0 || !searchInResources) return children;
        }

        //Step 3 : Altrimenti, devo richiederle al cloud

        //Step 3.1 : Preparo i dati
        var pathParts = extractData(resource.path);

        var urlToGetBlobs = "https://" + this.accountName + ".blob.core.windows.net/" + pathParts.rootFolder + "?restype=container&comp=list&prefix=" + encodeURIComponent(pathParts.subPath);

        var canonicalizedResource = "/" + this.accountName + "/" + pathParts.rootFolder + "\ncomp:list\nprefix:" + pathParts.subPath + "\nrestype:container";

        var now = new Date().toUTCString();

        var hash = buildHash(
            this.accessKey, [
                "x-ms-date:" + now,
                "x-ms-version:" + version
            ],
            "GET",
            canonicalizedResource
        );

        var headers = {
            "Authorization": "SharedKey " + this.accountName + ":" + hash,
            "x-ms-date": now,
            "x-ms-version": version
        };

        //Step 3.2 : Effettuo la richiesta
        var blobs = await api.get(
                urlToGetBlobs, {
                    headers: headers
                })
            .then(res => {
                //console.log(res);
                //res.data è un html. I nomi dei containers sono inclusi tra i tags <Name></Name>
                var newPaths = res.data.split("</Name>")
                    .filter(html => html.indexOf("<Name>") != -1)
                    .map(html => new ClientTreeNode(null, null, pathParts.rootFolder + "/" + html.split("<Name>")[1], FileFolderEntityType.Cloud_File)); //azure blob non accetta cartelle vuote

                return newPaths;
            }).catch(e => {
                console.log(e);
                return null;
            });

        if (blobs == null) return null;

        blobs.forEach(newNode => {
            this.nodes.push(newNode);
            this.nodes = new ClientTreeNodeMethods().insertParentsInto(newNode, this.nodes, PlatformsTypesEnum.Cloud_AzureBlob);
        });

        //console.log(blobs);
        return await this.getNodesFrom(resource, false);
    }
}

export class AzureFileClient {
    constructor(accountName, accessKey) {
        this.accountName = accountName;
        this.accessKey = accessKey;
        this.nodes = [];
    }

    /*
Documentazione : 
    https://learn.microsoft.com/en-us/rest/api/storageservices/authorize-with-shared-key#blob-queue-and-file-services-shared-key-authorization
    https://techcommunity.microsoft.com/t5/azure-paas-blog/the-mac-signature-found-in-the-http-request-xxxx-is-not-the-same/bc-p/3721224#M480
*/
    //Signature=Base64(HMAC-SHA256(UTF8(StringToSign), Base64.decode(<your_azure_storage_account_shared_key>)))
    async getRootNodes() {

        if (this.nodes.length != 0 && this.nodes.some(r => r.nParts == 1))
            return this.nodes.filter(r => r.nParts == 1);

        var urlToRequestContainers = "https://" + this.accountName + ".file.core.windows.net/?comp=list";
        var now = new Date().toUTCString();
        var canonicalizedResource = "/" + this.accountName + "/\ncomp:list";


        var hash = buildHash(
            this.accessKey, [
                "x-ms-date:" + now,
                "x-ms-version:" + version
            ],
            "GET",
            canonicalizedResource
        );

        var headers = {
            "Authorization": "SharedKey " + this.accountName + ":" + hash,
            "x-ms-date": now,
            "x-ms-version": version
        };

        //Step 5 : Effettuo la richiesta
        var containers = await api.get(urlToRequestContainers, {
                headers: headers
            })
            .then(res => {
                //console.log(res);
                //res.data è un html. I nomi dei containers sono inclusi tra i tags <Name></Name>
                var containersList = res.data.split("</Name>")
                    .filter(html => html.indexOf("<Name>") != -1)
                    .map(html => new ClientTreeNode(
                        null,
                        null,
                        html.split("<Name>")[1],
                        FileFolderEntityType.Cloud_Bucket
                    ));

                return containersList;
            })
            .catch(e => {
                console.log(e);
                return null;
            });

        this.nodes = this.nodes.concat(containers);

        //console.log(containers);
        return containers;

    }

    //https://learn.microsoft.com/en-us/rest/api/storageservices/list-directories-and-files
    async getNodesFrom(resource, searchInResources = true) {

        resource.path = applyBucketsRules(resource.path);
        //Step 1 : Controllo se sono già state salvare in locale le risorse richieste
        if (this.nodes) {
            var children = this.nodes.filter(r => r.path.startsWith(resource.path + "/") && r.nParts == resource.nParts + 1);
            //Step 2 : Se non sono state salvate o non le devo richiedere al cloud, ritorno le risorse trovate
            if (children.length != 0 || !searchInResources) return children;
        }


        //Step 3 : Altrimenti, devo richiederle al cloud

        //Step 3.1 : Preparo i dati

        var urlToGetResources = "https://" + this.accountName + ".file.core.windows.net/" + resource.path + "?restype=directory&comp=list";
        while (urlToGetResources.indexOf(" ") != -1)
            urlToGetResources = urlToGetResources.replace(" ", "%20");

        var canonicalizedResource = "/" + this.accountName + "/" + resource.path + "\ncomp:list\nrestype:directory";
        while (canonicalizedResource.indexOf(" ") != -1)
            canonicalizedResource = canonicalizedResource.replace(" ", "%20");

        var now = new Date().toUTCString();

        var hash = buildHash(
            this.accessKey, [
                "x-ms-date:" + now,
                "x-ms-version:" + version
            ],
            "GET",
            canonicalizedResource
        );

        var headers = {
            "Authorization": "SharedKey " + this.accountName + ":" + hash,
            "x-ms-date": now,
            "x-ms-version": version
        };

        //Step 5 : Effettuo la richiesta
        var resources = await api.get(
                urlToGetResources, {
                    headers: headers
                })
            .then(res => {
                console.log(res);
                //res.data è un html. I nomi di file e cartelle sono inclusi nei tag <Entries><File><Name>... o <Entries><Directory><Name>
                var entries = res.data.split("</Name>")
                    .filter(html => html.indexOf("<Name>") != -1)
                    .map(html => new ClientTreeNode(
                        null,
                        null,
                        resource.path + "/" + html.split("<Name>")[1],
                        html.indexOf("<Directory><Name>") == -1 ?
                        FileFolderEntityType.Cloud_File :
                        FileFolderEntityType.Cloud_Folder
                    ));

                return entries;
            })
            .catch(e => {
                console.log(e);
                return null;
            });

        if (resources == null) return null;


        resources.forEach(newNode => {
            this.nodes.push(newNode);
            this.nodes = new ClientTreeNodeMethods().insertParentsInto(newNode, this.nodes, PlatformsTypesEnum.Cloud_AzureFile);
        });

        return await this.getNodesFrom(resource, false);
    }
}

function buildHash(accessKey, headers, httpVerb, canonicalizedResource) {

    //Step 1 : Costruisco la stringa
    var VERB = httpVerb;
    var ContentEncoding = "";
    var ContentLanguage = "";
    var ContentLength = "";
    var ContentMD5 = "";
    var ContentType = "";
    var date = "";
    var IfModifiedSince = "";
    var IfMatch = "";
    var IfNoneMatch = "";
    var IfUnmodifiedSince = "";
    var Range = "";
    var CanonicalizedHeaders = headers.join("\n");
    var CanonicalizedResource = canonicalizedResource;


    var StringToSign = VERB + "\n" +
        ContentEncoding + "\n" +
        ContentLanguage + "\n" +
        ContentLength + "\n" +
        ContentMD5 + "\n" +
        ContentType + "\n" +
        date + "\n" +
        IfModifiedSince + "\n" +
        IfMatch + "\n" +
        IfNoneMatch + "\n" +
        IfUnmodifiedSince + "\n" +
        Range + "\n" +
        CanonicalizedHeaders + "\n" +
        CanonicalizedResource;

    //console.log(StringToSign);
    //Step 2 : Codifico la stringa in UTF-8
    var encodedStringToSign = Buffer.from(StringToSign).toString('utf-8');

    //Step 3 : Decodifico l'access key Decode the Base64 storage key.
    var decodedAccessKey = Buffer.from(accessKey, "base64");

    //Step 4 : Cripto encodedString utilizzando l'algoritmo HMAC-SHA256 e la chiave decodedAccessKey. Il tutto viene codificato in base 64
    var hash = createHmac('sha256', decodedAccessKey).update(encodedStringToSign).digest("base64");

    //console.log(hash);

    return hash;
}

//https://learn.microsoft.com/en-us/rest/api/storageservices/enumerating-blob-resources


function extractData(path) {
    var slashIndex = path.indexOf("/");

    if (slashIndex == -1)
        return {
            rootFolder: path,
            subPath: ""
        };

    var pathArray = path.split("/");
    var f = pathArray[0];

    pathArray.splice(0, 1);
    var sp = pathArray.join("/");

    return {
        rootFolder: f,
        subPath: sp
    };
}