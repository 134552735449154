/*******************************************************************************************************************************************************
Usare la sidebar:
1 - Il componente che ne richiede l'utilizzo chiama this.$root.$emit("toggleFileSystemSidebar", selectedNodes, config), dove :
    - selectedNodes : è l'array di nodi che verrà modificato con la selezione e de-selezione delle checkbox. Può essere vuoto o contenere degli oggetti;
    - config : è un oggetto che contiene e seguenti campi :
        - type : il tipo (numero intero) del service account/piattaforma a cui faranno riferimento le risorse;
        - includeFiles: boolean che specifica se si vuole caricare anche i file o meno.
        E' false, ad esempio, durante la scelta delle destinationi o del path per un repository;
        - multipleChoice: boolean che specifica se si da la possibilità di sleezionare più nodi o meno.
        E' false, ad esempio, durante la scelta del path per un repository;
        - client: oggetto, che può essere null in determinate situazioni, che consente di richiedere le risorse.
        Nel caso sia null, verranno utilizzate delle chiamate socket per richiedere le risorse.
        - rootPath : il percorso da cui l'albero del filesystem deve partire.
        Nel caso sia null, verranno richieste le root

    Dopo la chiamata this.$root.$emit("toggleFileSystemSidebar", selectedNodes, config),
    - la sidebar, inizialmente chiusa, verrà aperta
    - se necessario, verrà popolata la selezione roots per scegliere la cartella di partenza per richiedere file e cartelle,
    altrimenti verranno richiesti file e cartelle della root della piattaforma corrispondente a type;
    - verrà resettato l'intero treeview.
2 - Per aprire o chiudere la sidebar, un componente può utilizzare le chiamate :
    - this.$root.$emit("toggleFileSystemSidebar", selectedNodes, config) per aprire o chiudere la sidebar;
    - this.$root.$emit("closeFileSystemSidebar") per chiudere la sidebar.
3 - Al click di una checkbox, il nodo corrispondente a tale checkbox verrà aggiunto/rimosso (d)all'array selectedNodes.
4 - Al click di una cartella, verranno richieste le risorse figlie di questa cartella, utilizzando i dati specificati in config
*******************************************************************************************************************************************************/

import { ERROR } from '../../public/assets/js/messages';


import { isValid, trimEnd, getNodeType, getRootType, FileFolderEntityType, platformsTypes, assignProperties, getSeparator } from '../../public/assets/js/utilitiesmodule';
import { ClientTreeNodeMethods, ClientTreeNode } from '../models/TreeNode';
import $ from 'jquery'

export default {
    name: "FileSystemSidebar",
    data: function() {
        return {

            //FILESYSTEM
            //roots: [], //la lista di nodi che rappresentano le cartelle principali

            tree: new ClientTreeNode("node", "", "", FileFolderEntityType.FN_Folder), //null, // { path: "" },
            clickedNode: null,

            loading: false,

            selectedNodes: null,
            config: {
                type: 0,
                includeFiles: true,
                multipleChoice: true,
                client: null,
                //rootFolder: ""
            },

            filters: {
                pathToSearch: "",
                extensions: "",
                includeSpecifiedExtensions: true
            },

            //Si riferisce alla classe ExploreFSOptions dell'agent
            exploreFSOptions: {
                includeFiles: true,
                includeHiddenFiles: false,
                includeInaccessibleFiles: false,
                includeSystemFiles: false,
                includeDetails: false
            },

            nodeMethods: null
        }
    },
    beforeDestroy() {
        this.$root.$off("toggleFileSystemSidebar");
        this.$root.$off("closeFileSystemSidebar");
        this.$root.$off("uncheckNode");
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        //In caso di ftp o network, eseguo la disconnessione
        if (this.config.client != null && [this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.config.type)) {
            var method = this.config.type == this.$root.PlatformsTypesEnum.FTP ? "disconnectftp" : "disconnectnetwork";
            this.$root.socket(method, this.config.client.serviceAccount.options.accountSessionId);
        }
    },
    mounted() {
        let self = this;
        this.setTreeviewCallbacks();




        this.$root.$on("toggleFileSystemSidebar", (selectedNodes, config) => this.toggleFileSystemSidebar(selectedNodes, config));
        this.$root.$on("closeFileSystemSidebar", () => this.closeFileSystemSidebar());

        this.$root.$on('uncheckNode', (node) => this.setNode(node, false));
        this.nodeMethods = new ClientTreeNodeMethods();

    },
    computed: {
        isOpen: function() { return Metro.charms.isOpen("#fileSystemSidebar"); }
    },

    methods: {
        /***********************************APERTURA/CHIUSURA DELLA SIDEBAR*********************************************************************/
        toggleFileSystemSidebar(selectedNodes, config) {
            this.setTreeviewCallbacks();
            if (window.Metro.charms.isOpen("#fileSystemSidebar") && isValid(this.config) && config.type == this.config.type)
                return;

            if (window.Metro.charms.isOpen("#fileSystemSidebar"))
                this.closeFileSystemSidebar();
            else
                this.openFileSystemSidebar(selectedNodes, config);
        },

        openFileSystemSidebar(selectedNodes, config) {

            if (window.Metro.charms.isOpen("#fileSystemSidebar")) return;

            // $(".shifted-content").css({ "transform": "translateX(-12%)", "transition-duration": ".3s" });
            window.Metro.charms.open("#fileSystemSidebar");

            this.setSidebar(selectedNodes, config);

        },

        closeFileSystemSidebar() {

            if (!window.Metro.charms.isOpen("#fileSystemSidebar")) return;

            window.Metro.charms.close("#fileSystemSidebar");
            //$(".shifted-content").css({ "transform": "initial", "transition-duration": ".3s" });
            this.loading = false;
        },

        /*
            Questo metodo viene chiamato :
            - Quando viene aperta la sidebar (isValid(config)),
            - Quando si cambia la root
        */
        async setSidebar(selectedNodes, config) {
            if (this.loading) return;

            this.loading = true;

            //3 - Si svuota la treeview
            var treeview = window.Metro.getPlugin("#treeview", "treeview");
            if (isValid($('#node')) && isValid(treeview)) {
                treeview.clean($('#node'));
                assignProperties(new ClientTreeNode("node", "", platformsTypes[config.type].name, getRootType(config.type)), this.tree);
            }


            if (!isValid(config)) {
                alert("config è null!!!");
                await this.getNSetChildrenOfPath(this.tree.path, false);
                return;
            }
            // 1 - Si memorizzano i dati passati

            this._setConfig(config);
            this.selectedNodes = selectedNodes;

            // 2 - Si cambia la root
            if (isValid(this.config.tree)) {
                this.tree.nodeID = this.config.tree.nodeID;
                this.tree.resID = this.config.tree.resID;
                this.tree.path = this.config.tree.path;
                this.tree.type = this.config.tree.type; // FileFolderEntityType: ...\iperius-enterprise-agent\IperiusEWP\BackupSources\FileFolder\Core\Utils\Enums.cs
                this.tree.nParts = this.config.tree.nParts;
                this.tree.children.splice(0, this.tree.children.length);
                this.tree.children.push(...this.config.tree.children);
                this.tree.visible = true;

                this.setChildrenToViewNode("node", this.tree.children, true);
                this.loading = false;
                return;
            }

            if (this.config.rootPath != null && typeof this.config.rootPath == "string") {
                this.tree.nodeID = "node";
                this.tree.resID = null;
                this.tree.path = this.config.rootPath;
                this.tree.type = getNodeType(this.config.type, false); // FileFolderEntityType: ...\iperius-enterprise-agent\IperiusEWP\BackupSources\FileFolder\Core\Utils\Enums.cs
                var slash = getSeparator(this.tree.path);
                this.tree.nParts = this.tree.path.split(slash).length;
                this.tree.children.splice(0, this.tree.children.length);
                this.tree.visible = true;
            }

            if (this.config.rootPath != null && typeof this.config.rootPath != "string") {
                this.tree.nodeID = "node";
                this.tree.resID = null;
                this.tree.path = this.config.rootPath.path;
                this.tree.type = this.config.rootPath.type; // FileFolderEntityType: ...\iperius-enterprise-agent\IperiusEWP\BackupSources\FileFolder\Core\Utils\Enums.cs
                var slash = getSeparator(this.tree.path);
                this.tree.nParts = this.tree.path.split(slash).length;
                this.tree.children.splice(0, this.tree.children.length);
                this.tree.visible = true;
            }


            //     this.tree = null;
            // else
            //     this.tree = new ClientTreeNode(
            //         "node",
            //         null,
            //         this.config.rootPath,
            //         getNodeType(this.config.type, false)
            //     );

            // 3- Si richiedono i dati per popolare la sidebar
            await this.getNSetChildrenOfPath(this.config.rootPath, false);

        },
        /******************************************************************CHILDREN****************************************************************************/

        async getNSetChildrenOfPath(path, searchInTree) {

            this.loading = true;

            var getFirstChildren = !isValid(path);

            //Step 1 : Impostazione del nodo selezionato e del tree (se non precedentemente impostato).
            this._setTreeAndClickedNode(path);

            //Step 2 : Se è stato specificato, controllo nell'albero se sono già stati richiesti i children di path.
            //In caso affermativo, termino la ricerca
            if (isValid(path) && searchInTree && this.clickedNode.children.length > 0) {
                this.loading = false;
                return;
            }

            var requestData;
            var children;

            try {

                // Step 3 : A seconda del tipo della piattaforma da cui devo prendere file e cartelle, eseguo una chiamata differente
                switch (this.config.type) {
                    // Caso 1 : CLOUD
                    case this.$root.PlatformsTypesEnum.Cloud_S3CompatibleStorage:
                    case this.$root.PlatformsTypesEnum.Cloud_IperiusS3Storage:
                    case this.$root.PlatformsTypesEnum.Cloud_AmazonS3Storage:
                    case this.$root.PlatformsTypesEnum.Cloud_AzureBlob:
                    case this.$root.PlatformsTypesEnum.Cloud_AzureFile:
                    case this.$root.PlatformsTypesEnum.Cloud_Dropbox:
                    case this.$root.PlatformsTypesEnum.Cloud_GoogleDrive:
                        if (getFirstChildren)
                            children = await this.config.client.getRootNodes();
                        else
                            children = await this.config.client.getNodesFrom(this.clickedNode);
                        break;
                        //Caso 1.1 : OneDrive    
                    case this.$root.PlatformsTypesEnum.Cloud_OneDrive:
                        if (getFirstChildren)
                            children = await this.config.client.getOneDriveRootNodes(this.config.client.serviceAccount.options.userID, this.config.client.serviceAccount.options.driveID);
                        else
                            children = await this.config.client.getOneDriveNodesFrom(this.config.client.serviceAccount.options.userID, this.config.client.serviceAccount.options.driveID, this.clickedNode);
                        break;

                        // Caso 2 : FTP
                    case this.$root.PlatformsTypesEnum.FTP:
                        if (path.startsWith("ftp://") || path.startsWith("ftps://")) {
                            path = path.split("/").slice(3).join("/"); // [ftp:]/[]/[]/[prima cartella]/...
                        }

                        requestData = {
                            accountSession: this.config.client.serviceAccount.options.accountSessionId,
                            nodeID: this.clickedNode.nodeID,
                            path: path, //percorso da esplorare,
                            options: this.exploreFSOptions //vedi ExploreFSOptions,
                        };

                        children = await this.$root.socket("exploreftp", requestData);
                        break;
                        // Caso 3 : FileFolder
                    case this.$root.PlatformsTypesEnum.FileFolder:
                        requestData = {
                            nodeID: this.clickedNode.nodeID,
                            path: path, //percorso da esplorare,
                            options: this.exploreFSOptions //vedi ExploreFSOptions,
                        };
                        var fs = await this.$root.socket("GetExploreFs", requestData);
                        if (fs == null) {
                            children = null;
                            break
                        }

                        if (getFirstChildren)
                            children = fs.drives.map((d) => new ClientTreeNode(
                                null,
                                null,
                                d,
                                FileFolderEntityType.FN_Folder
                            ));
                        else
                            children = fs.items;

                        break;
                    case this.$root.PlatformsTypesEnum.Network: //Network
                        requestData = {
                            accountSession: this.config.client.serviceAccount.options.accountSessionId,
                            nodeID: this.clickedNode.nodeID,
                            path: path, //percorso da esplorare,
                            options: this.exploreFSOptions //vedi ExploreFSOptions,
                        };
                        //Ritorna la lista di ClientTreeNode
                        children = await this.$root.socket("getexplorenetworkfs", requestData);
                        break;

                    case this.$root.PlatformsTypesEnum.Microsoft_OneDriveForBusiness:
                    case this.$root.PlatformsTypesEnum.Microsoft_SharePoint:
                        children = await this.$root.socket("GetMicrosoft365List", this.config.client.serviceAccount, this.config.type, null);
                        if (children == null) {
                            this.loading = false;
                            return;
                        }
                        children = children.map(n => new ClientTreeNode(n.nodeID, n.resID, n.path, n.type));
                        this.setChildrenToLogicNode("node", children);
                        this.setChildrenToViewNode("node", children, false);
                        this.loading = false;
                        return;
                        //children = await this.config.client.getSites();

                    case this.$root.PlatformsTypesEnum.Microsoft_Teams: //Teams

                        if (!getFirstChildren) {
                            children = await this.$root.socket("GetMicrosoft365List", this.config.client.serviceAccount, this.config.type, this.clickedNode.resID);
                            if (children != null)
                                children = children.map(n => new ClientTreeNode(n.nodeID, n.resID, n.path, n.type));
                            //children = await this.config.client.getChannelsByTeamID(this.clickedNode.resID, this.clickedNode.path);
                            break;
                        }

                        var mTeamsObject = await this.$root.socket("GetMicrosoft365List", this.config.client.serviceAccount, this.config.type, null);

                        if (mTeamsObject == null) {
                            this.loading = false;
                            return;
                        }

                        mTeamsObject.teams = mTeamsObject.teams.map(n => new ClientTreeNode(n.nodeID, n.resID, n.path, n.type));
                        mTeamsObject.users = mTeamsObject.users.map(n => new ClientTreeNode(n.nodeID, n.resID, n.path, n.type));
                        //Creo 2 sottonodi. uno ospiterà i team e l'altro gli utenti
                        var teamsNode = new ClientTreeNode("node-0", null, "Teams", FileFolderEntityType.MT_Root);
                        var usersNode = new ClientTreeNode("node-1", null, "Users", FileFolderEntityType.Account);
                        //Creo i due nodi sotto la root
                        this.setChildrenToLogicNode("node", [teamsNode, usersNode]);
                        this.setChildrenToViewNode("node", [teamsNode, usersNode], false);


                        this.setChildrenToLogicNode("node-0", mTeamsObject.teams);
                        this.setChildrenToViewNode("node-0", mTeamsObject.teams, false);
                        this.setChildrenToLogicNode("node-1", mTeamsObject.users);
                        this.setChildrenToViewNode("node-1", mTeamsObject.users, false);
                        this.loading = false;
                        return;

                }

                if (children == null) {
                    this.loading = false;
                    return;
                }

                //Step 4 : Assegno l'id ai nodi
                children = children.map((c, i) => {
                    c.nodeID = this.clickedNode.nodeID + "-" + i;
                    return c;
                });

                //Step 5 : Assegno i children al nodo selezionato
                this.setChildrenToLogicNode(this.clickedNode.nodeID, children);

                this.setChildrenToViewNode(this.clickedNode.nodeID, children, false);

                this.loading = false;

            } catch (e) {
                this.$root.toast(this.$t(ERROR), 3000, "alert");
                console.log(e);
                this.loading = false;
            }
        },

        setChildrenToLogicNode(id, children) {
            var currentNode = this.nodeMethods.findNodeWithId(this.tree, id);
            currentNode.children = children;
            return currentNode;
        },

        setChildrenToViewNode(id, children, recursive) {

            var treeview = window.Metro.getPlugin("#treeview", "treeview");

            //Step 1a : Toglie l'eventuale nodo finto aggiunto nello step 2.3
            $("#" + id + " ul").remove();

            this.sleep(1000);

            //Step 2 : Per ogni nuovo child da aggiungere
            children.forEach(child => {

                //Step 2.1 : Aggiungo una checkbox all'interno del nodo
                //Questo comando crea, dentro il nodo, la struttura 
                //ul(solo alla prima chiamata di addTo) -> li -> template
                treeview.addTo(
                    id == 'treeview' ?
                    null :
                    $("#" + id), {
                        html: this.nodeMethods.getTemplate(child, this.selectedNodes.findIndex(n => this.nodeMethods.getParent(child).path == n.path || child.path == n.path) != -1)
                    }
                );

                //Step 2.2 : Aggiungo gli attributi al li
                $("#" + id + " li").last().attr({
                    "id": child.nodeID,
                    "path": child.path,
                    "class": this.nodeMethods.isFile(child) || this.config.oneLevel ? '' : 'tree-node expanded'
                });

                /*
                <li id="nodeID" ...>
                <a>
                <label>
                */
                var aTag_in_li = $("#" + child.nodeID + " a");
                if (aTag_in_li) {
                    aTag_in_li = aTag_in_li[0];
                    var labelTag_in_a = aTag_in_li.firstChild;

                    if (labelTag_in_a) {
                        // Move cElement to the parent of bElement (which is a)
                        aTag_in_li.parentNode.insertBefore(labelTag_in_a, aTag_in_li);

                        // Remove bElement
                        aTag_in_li.remove();
                    }

                }
                /**/

                //Step 2.3 : In seguenti elementi possono contentere figli sull'interfaccia
                switch (child.type) {
                    case FileFolderEntityType.FN_Folder:
                    case FileFolderEntityType.Cloud_Root:
                    case FileFolderEntityType.Cloud_Bucket:
                    case FileFolderEntityType.Cloud_Folder:
                    case FileFolderEntityType.SP_Root:
                    case FileFolderEntityType.MT_Root:
                    case FileFolderEntityType.MT_Team:
                    case FileFolderEntityType.FTP_Folder:
                        //Step 2.3.1: Aggiungo un finto elemento all'interno della cartella, per far apparire la freccetta per il toggle.
                        //Quando il nodo verrà riempito, chiamando "treeview.clean(htmlNode)", verrà cancellato il finto elemento
                        treeview.addTo(
                            $("#" + child.nodeID), {
                                html: "<ul><li></li></ul>"
                            }
                        )

                        //Step 2.3.2 : Chiudo il nodo
                        /*if (child.visible)*/
                        treeview.toggleNode($("#" + child.nodeID));
                }

                if (recursive && isValid(child.children) && child.children.length > 0)
                    this.setChildrenToViewNode(child.nodeID, child.children, true);
            });
        },

        /*************************************FUNZIONI PER LE CHECKBOX***********************************************/
        setNode(node, checked) {

            if (!checked)
                window.Metro.get$el("#treeview #checkbox-" + node.nodeID)[0].checked = false; //OK


            var allInput = $("#treeview input[type=checkbox]");
            var currentID;

            if (checked && !this.config.multipleChoice && this.selectedNodes.length == 0) {

                this.selectedNodes.push(node);
                return;
            }

            if (checked && !this.config.multipleChoice && this.selectedNodes.length != 0) {
                console.log(window.Metro.get$el("#treeview #checkbox-node"));
                console.log(window.Metro.get$el("#treeview #checkbox-node")[0]);
                console.log(window.Metro.get$el("#treeview #checkbox-node")[0].checked);
                window.Metro.get$el("#treeview #checkbox-node")[0].click();
                window.Metro.get$el("#treeview #checkbox-node")[0].click();

                //window.Metro.get$el("#treeview #checkbox-node")[0].checked = true; //OK
                //window.Metro.get$el("#treeview #checkbox-node")[0].checked = false; //OK
                window.Metro.get$el("#treeview #checkbox-" + node.nodeID)[0].checked = true; //OK
                this.selectedNodes.splice(0, 1);
                this.selectedNodes.push(node);
                return;
            }

            this.selectedNodes.splice(0, this.selectedNodes.length);

            for (var i = 0; i < allInput.length; i++) {
                currentID = allInput[i].id.replace("checkbox-", "");
                if (!allInput[i].checked || allInput[i].dataset.indeterminate == "true" || this.selectedNodes.some(n => currentID.startsWith(n.nodeID + "-")))
                    continue;
                this.selectedNodes.push(this.nodeMethods.findNodeWithId(this.tree, currentID));
            }

        },


        /************************UTILS******************************************************/
        sleep(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        },
        setTreeviewCallbacks() {
            let self = this;
            var treeview = window.Metro.get$el("#treeview").data("treeview");
            if (treeview == undefined)
                return;
            treeview = treeview.options;
            treeview.onExpandNode = (htmlNode, tree) => self.onExpandNode(htmlNode, tree);
            treeview.onCollapseNode = (htmlNode, tree) => self.onCollapseNode(htmlNode, tree);
            treeview.onCheckClick = (state, x, htmlNode, y) => self.onCheckClick(state, htmlNode); // check e tree non mi servono ma se non le uso il compilatore si lamenta
        },

        /**********************CALLBACK*********************************/
        onExpandNode(htmlNode) {
            var nodePath = htmlNode.attributes.path.nodeValue;
            this.getNSetChildrenOfPath(nodePath, true);
        },
        onCollapseNode(htmlNode) {
            var node = this.nodeMethods.findNodeWithId(this.tree, htmlNode.id);
            if (node) node.visible = false;
        },
        onCheckClick(state, htmlNode) { // state ==true se è stato checkato, false viceversa
            this.setNode(this.nodeMethods.findNodeWithId(this.tree, htmlNode.id), state);
        },

        onClearSearch() {
            this.config.rootPath = null;
            this.setSidebar(this.selectedNodes, this.config);
        },

        //Imposta tree e clickedNode
        _setTreeAndClickedNode(path) {
            //Caso 1 : Se è stato specificato un path e il tree non è ancora stato impostato
            //l'albero parte dal path passato e path sarà il nodo selezionato
            if (isValid(path) && this.tree.path == "") {
                assignProperties(new ClientTreeNode("node", "", path, getRootType(this.config.type)), this.tree);
                this.clickedNode = this.tree;
                return;
            }


            // Caso 2 : Se è stato specificato un path e il tree è stato costruito, path diventa il nodo selezionato
            if (isValid(path) && this.tree.path != "") {
                this.clickedNode = this.nodeMethods.findNodeWithPath(this.tree, path);
                return;
            }

            // Caso 3 : Se non è stato passato alcun path e bisogna costruire l'albero,
            //la root diventa il nome della piattaforma corrispondente a type e il nodo selezionato
            if (!isValid(path)) {
                this.tree = new ClientTreeNode("node", "", platformsTypes[this.config.type].name, getRootType(this.config.type));
                this.clickedNode = this.tree;
                return;
            }

        },

        _setConfig(config) {
            this.config.type = isValid(config.type) ? config.type : 0;
            this.config.multipleChoice = isValid(config.multipleChoice) ? config.multipleChoice : true;
            this.config.client = config.client;

            this.config.rootPath = isValid(config.rootPath) ? config.rootPath : null;

            this.config.oneLevel = isValid(config.oneLevel) ? config.oneLevel : [this.$root.PlatformsTypesEnum.Microsoft_SharePoint, this.$root.PlatformsTypesEnum.Microsoft_OneDriveForBusiness].includes(this.config.type);
            this.config.tree = isValid(config.tree) ? config.tree : null;

            this.config.includeFiles = isValid(config.includeFiles) ? config.includeFiles : true;
            this.exploreFSOptions.includeFiles = this.config.includeFiles;
        },

        //---------------------------------TAG INPUT------------------------------------------------------------------

        search() {
            if (this.clickedNode == null)
                return;

            //Step 1 : Pulisco il percorso
            while (this.filters.pathToSearch.includes('"'))
                this.filters.pathToSearch = this.filters.pathToSearch.replace('"', "");
            this.filters.pathToSearch = trimEnd(trimEnd(this.filters.pathToSearch, '\\'), '/');

            //Step 2 : Se è stato specificato un percorso ma corrisponde alla root di tree, non faccio nulla
            if (this.filters.pathToSearch != "" && this.filters.pathToSearch == this.config.rootPath)
                return;

            //Step 3: Se è stato cancellato il percorso da cercare ed era stato cercato un percorso
            // Si ricostruisce il tree di default
            if (this.filters.pathToSearch == "" && isValid(this.config.rootPath)) {
                this.config.rootPath = "";
                this.setSidebar(this.selectedNodes, this.config);
                return;
            }

            //Step 4: Se è stato cancellato un percorso ed era stata specificata una stringa da cercare
            // Si mostrano gli elementi nascosti
            if (this.filters.pathToSearch == "" && this.clickedNode != null) {
                this.clickedNode.children.forEach(node => $("#" + node.nodeID).removeClass("d-none"));
                return;
            }

            //Step 5: Se non è stato specificato un percorso, mostro solo i nodi in clickedNode che corrispondono a pathToSearch
            if (!this.filters.pathToSearch.includes("/") && !this.filters.pathToSearch.includes("\\") && this.clickedNode != null) {
                this.clickedNode.children.forEach(node => {
                    if (node.path.toLowerCase().split(getSeparator(node.path)).at(-1).startsWith(this.filters.pathToSearch.toLowerCase()))
                        $("#" + node.nodeID).removeClass("d-none");
                    else
                        $("#" + node.nodeID).addClass("d-none");
                });

                return;
            }

            //Step 6 : Se è stato specificato un percorso, imposto il tree partendo dal percorso cercato
            this.config.rootPath = this.filters.pathToSearch;
            this.setSidebar(this.selectedNodes, this.config);
        },
    }
}