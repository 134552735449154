<template>
    <div v-on:keyup.esc="$root.$emit('CLOSESOURCEDIALOG')"
        class="dialog w-100-sm w-100-md w-75-lg  w-50-xl" v-cloak>

        <DialogTitle :title="$t('Configure Source')" :defaultTitle="$t('Configure Source')" :subTitle="source.type_name"
            @closeDialog="$root.$emit('CLOSESOURCEDIALOG')" />

        <div v-if="step == 0" class="dialog-content">
            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            
            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <SARepoSelect :type=source.type :idSA=source.id_service_account :idRepo=null
                @sendSATestResult="sendSATestResult" />
        </div>
        <div v-if="step == 1" class="dialog-content">

            <div class="form-group">
                <h6 class="text-bold">{{ $t("Select the Virtual Machines to backup") }}</h6>
            </div>
            <div v-if="wait" class="form-group text-center">
                <span class="mif-spinner2 fg-primary ani-spin"></span>
            </div>
            <div v-else data-role="splitter" class="h-100 w-100" data-split-sizes="70, 30">
                <div class="p-2" style="max-height:600px;overflow-y: auto;overflow-x:hidden;min-width:500px">
                    <ul data-role="treeview" v-if="source.esxiSource.dataCenters.length != 0">
                        <li class="p-1" :title="source.esxiSource.url">
                            <input class="mt-3-minus" :value="true" type="checkbox" data-role="checkbox"
                                :data-caption="source.esxiSource.url + ' (' + source.esxiSource.hostVersion + ')'">
                            <ul>
                                <li v-for="datacenter in source.esxiSource.dataCenters" :key="datacenter.name"
                                    data-collapsed="true" data-icon="<span class='mif-location-city pt-1'></span>">
                                    <input class="ml-5" type='checkbox' v-model="datacenter.selected"
                                        data-role='checkbox' :data-caption="datacenter.name">
                                    <ul v-if="datacenter.computerResourceList">
                                        <li class="p-1" :title="computerResource.name" data-collapsed="true"
                                            v-for="computerResource in datacenter.computerResourceList"
                                            :key="computerResource.name" data-icon="<span class='mif-cabinet pt-1'></span>">
                                            <input :disabled="computerResource.datastores.length == 0" class="ml-5"
                                                v-model="computerResource.selected" type="checkbox"
                                                data-role="checkbox" :data-caption="computerResource.name">
                                            <ul v-if="datacenter.computerResourceList">
                                                <li class="p-1" :title="datastore.name" data-collapsed="true"
                                                    v-for="(datastore) in computerResource.datastores" :key="datastore.name"
                                                    data-icon="<span class='mif-database pt-1'></span>">
                                                    <input class="ml-5" :disabled="datastore.virtualMachines.length == 0"
                                                        v-model="datastore.selected" type="checkbox"
                                                        data-role="checkbox"
                                                        :data-caption="datastore.name + ' [' + prettyBytes(datastore.freeSpace) + '/' + prettyBytes(datastore.capacity) + ']'">
                                                    <ul v-if="datastore.virtualMachines">
                                                        <li class="p-1" data-collapsed="true"
                                                            v-for="(virtualmachine) in datastore.virtualMachines"
                                                            :key="virtualmachine.name"
                                                            data-icon="<span class='mif-stack pt-1'></span>">
                                                            <input class="ml-5"  
                                                                v-model="virtualmachine.selected" type="checkbox"
                                                                data-role="checkbox"><label
                                                                :class="{ 'active': esxiselected == virtualmachine }"
                                                                @click="esxiselected = virtualmachine"
                                                                style="cursor: pointer;top: -4px;position: relative;"
                                                                class="text-bold">{{ virtualmachine.name }}
                                                                <span class="mif-info"></span> </label>
                                                            <ul v-if="virtualmachine.virtualDisks">
                                                                <li class="p-1"
                                                                    :title="virtualDisk.diskFilename.split('/')[1]"
                                                                    data-collapsed="true"
                                                                    v-for="(virtualDisk) in virtualmachine.virtualDisks"
                                                                    :key="virtualDisk.deviceKey"
                                                                    data-icon="<span class='mif-drive2 pt-1'></span>">
                                                                    <input class="ml-5"  
                                                                        v-model="virtualDisk.selected" type="checkbox"
                                                                        data-role="checkbox"
                                                                        :data-caption="virtualDisk.diskFilename.split('/')[1] + ' [' + prettyBytes(virtualDisk.size * 1024) + ']'">
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="pl-2 p-0 m-0">
                    <div v-if="esxiselected" class="h-100">
                        <h6 class="w-100 mb-0 text-bold"> {{ esxiselected.name }}</h6>
                        <p class="mute mt-0">{{ esxiselected.os }}</p>

                        <div data-role="buttongroup">
                            <button :title="ESXiVmPowerState[1]" class="button bg-primary fg-white rounded m-1 small"
                                @click="changeVMStatusEsxi(esxiselected, 1)"
                                :class="{ disabled: esxiselected.powerState == 1 }"><span class='mif-play'></span></button>
                                <button :title="ESXiVmPowerState[2]" class="button bg-primary rounded m-1 small"
                                    @click="changeVMStatusEsxi(esxiselected, 2)"
                                    :class="{ disabled: esxiselected.powerState == 2 }"><span class='mif-pause'></span></button>
                            <button :title="ESXiVmPowerState[0]" class="button bg-primary fg-white rounded m-1 small"
                                @click="changeVMStatusEsxi(esxiselected, 0)"
                                :class="{ disabled: esxiselected.powerState == 0 }"><span class='mif-stop'></span></button>
                        </div>

                        <div class="form-group">
                            <input data-role="checkbox" type="checkbox"
                                :data-caption="$t('Power off virtual machines before the backup')"  
                                data-validate="required" v-model="esxiselected.backupOptions.turnOffBefore" required
                                data-prepend="<span class='mif-server'></span>" />
                        </div>
                        <div class="form-group">
                            <input data-role="checkbox" type="checkbox"
                                :data-caption="$t('Power on virtual machines after the backup')"  
                                data-validate="required" v-model="esxiselected.backupOptions.turnOnAfter" required
                                data-prepend="<span class='mif-server'></span>" />
                        </div>
                        <div class="form-group">
                            <input data-role="checkbox" type="checkbox"
                                :data-caption="$t('Quiesce the file system before the backup (recommended)')"  
                                data-validate="required" v-model="esxiselected.backupOptions.quiesceBefore" required
                                data-prepend="<span class='mif-server'></span>" />
                        </div>

                        <div class="form-group pt-4 pb-2">
                            <p class="text-bold">Windows Os: </p>
                            <input data-role="radio" class="form-check-input mb-1" type="radio"
                                v-model="esxiselected.backupOptions.vssMode" value="COPY"
                                :data-caption="$t('VSS copy mode (default)')"  ><br>
                            <input data-role="radio" class="form-check-input mb-1" type="radio"
                                v-model="esxiselected.backupOptions.vssMode" value="FULL"
                                :data-caption="$t('VSS full mode (logs of supported applications will be truncated)')"
                                 ><br>
                        </div>

                        <div class="form-group pt-4 pb-2 ">
                            <p class="text-bold">Linux Os: </p>
                            <input data-role="checkbox" type="checkbox"
                                :data-caption="$t('Run pre-freeze and post-thaw script with the following credentials (Linex machines only)')"
                                v-model="esxiselected.backupOptions.run_script" required
                                data-prepend="<span class='mif-server'></span>" />
                        </div>

                        <div class="form-group">

                            <div class="cell-12">
                                <label>{{ $t("Username") }}</label>
                                <input data-role="input" v-model="esxiselected.backupOptions.userScript" type="text"
                                    required data-prepend="<span class='mif-user'></span>" />
                                <small class="text-muted">{{ $t("Required") }}</small>
                            </div>
                            <div class="cell-12">
                                <label>{{ $t("Password") }}</label>
                                <input data-role="input" class="metro-input" type="password"
                                    v-model="esxiselected.backupOptions.pwScript" :placeholder="$t('Enter Password')"
                                    data-prepend="<span class='mif-lock'></span>" />
                                <small class="text-muted">{{ $t("Required") }}</small>

                            </div>
                            <small class="text-muted ml-2">{{ $t("Freezing is recommended on Linux to get an 'application consistent' backup") }} </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="step == 2" class="dialog-content">

            <div class="form-group pb-4 ">
                <p class="text-bold">{{ $t("Backup mode") }}</p>
                <input data-role="radio" class="form-check-input mb-1" type="radio" v-model="source.options.backupMode"
                    value="STD" :data-caption="$t('Standard (copy of individual virtual machine files)')"
                     ><br>
                <label data-role="hint" :data-hint-text="$t('*Virtual machines must have the CBT option enabled')"
                    data-hint-position="right"> <input data-role="radio" class="form-check-input mb-1" type="radio"
                        v-model="source.options.backupMode" value="CBT"
                        :data-caption="$t('CBT / VDDK (full/incremental/differential)')"  ></label><br>

                <input data-role="radio" class="form-check-input mb-1" type="radio" v-model="source.options.backupMode"
                    value="2DS" :data-caption="$t('Full Backup to Datastore/Host (requires a vCenter connection)')"
                     ><br>
                <label data-role="hint" :data-hint-text="$t('*Virtual machines must have the CBT option enabled')"
                    data-hint-position="right"><input data-role="radio" class="form-check-input mb-1" type="radio"
                        v-model="source.options.backupMode" value="REP"
                        :data-caption="$t('Incremental replication from host to host')"  ></label><br>

            </div>
            <div class="form-group pb-4 " v-show="source.options.backupMode != 'REP'">
                <p class="text-bold">{{ $t("Backup type") }}</p>
                <input data-role="radio" class="form-check-input mb-1" type="radio" v-model="source.options.backupType"
                    value="F" :data-caption="$t('Full Backups only')"  ><br>
                <input data-role="radio" :disabled="source.options.backupMode != 'CBT'" class="form-check-input mb-1"
                    type="radio" v-model="source.options.backupType" value="I"
                    :data-caption="$t('One Full backup + Incremental backups')"  ><br>
                <input data-role="radio" :disabled="source.options.backupMode != 'CBT'" class="form-check-input mb-1"
                    type="radio" v-model="source.options.backupType" value="D"
                    :data-caption="$t('One Full backup + Differential backups')"  ><br>
            </div>
            <div class="form-group">
                <p>{{ $t("CBT Port") }}:</p>
                <input class="input w-25 p-2" type="number" min="0" v-model.lazy="source.options.cbtPort">
                <label>{{ $t("Leave blank if you don't use it") }}</label>
            </div>

            <div class="form-group pt-4" v-show="source.options.backupMode == 'CBT'">
                <p>{{ $t("Number of copies") }}:</p>
                <input class="w-25" type="text" v-model.lazy="source.options.nOfCopies" data-role="spinner"
                    data-min-value="0" data-max-value="99">
            </div>
        </div>
        <div v-show="step == 3" class="dialog-content">
            <h6 class="text-bold">{{ $t("Configure backup options") }}:</h6>
            <div class="form-group">
                <input data-role="checkbox" type="checkbox"
                    :data-caption="$t('Add automatically every new virtual machine to the backup')"  
                    data-validate="required" v-model="source.options.autoAddNewItems" required />
            </div>
            <div class="form-group">
                <input :disabled="source.options.backupMode == 'REP' || source.options.backupMode == '2DS'"
                    v-model="source.enableCompression" type="checkbox" data-role="checkbox"
                    :data-caption="$t('Enable Compression')">
            </div>
            <div class="form-group w-50">
                <p>{{ $t("Archive File Custom Name") }} </p>
                <input :disabled="!source.enableCompression" v-model.lazy="source.options.archiveFileCustomName" type="text"
                    data-role="input" class="">
                <label class="text-small text-muted">{{ $t("If blank, default name will be used") }}</label>
            </div>
            <div class="form-group">
                <input :disabled="!source.enableCompression" v-model="source.options.useArchivePassword" type="checkbox"
                    data-role="checkbox" :data-caption="$t('Protect zip with Password')">
            </div>
            <div v-if="source.enableCompression && source.options.useArchivePassword">
                <div class="form-group w-50">
                    <p>{{ $t("Archive Password") }}</p>
                    <input data-role="input" class="metro-input" type="password" v-model="source.options.archivePassword"
                        :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
            </div>
        </div>
        <div v-if="step == 4 && ['2DS', 'REP'].includes(source.options.backupMode)" class="dialog-content">
            <div class="form-group pb-6">
                <label>
                    <span class='mif-cabinet mr-1' />
                    <span> {{ $t("Vmware ESXi / VCenter Account") }} </span>
                </label>
                <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
                
                <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
                <SARepoSelect :type=destination.type :idSA=destination.id_service_account :idRepo=null
                    @sendSATestResult="sendSATestResult" />
            </div>
            <div class="form-group">
                <label> {{ $t("Target Datastore") }}: </label>
                <!-------------SELEZIONE DEL DATASTORE---------------------------------------->

                <!--Se sto richiedendo i datastore della destinazione, mostro un progress-->
                <div class="m-4" v-if="destination.id_service_account != -2 && destination.datastoreList == null"
                    data-role="progress" data-cls-back="bg-light" data-cls-bar="bg-light" data-cls-buffer="bg-cobalt"
                    data-type="line" data-small="true" />

                <!--Se ho ricevuto i datastore della destinazione, mostro la select-->
                <select v-else-if="destination.datastoreList" class="select w-100" v-model="destinationData.datastore"
                    @change="getFoldersFromDatastore()">
                    <option v-for="(datastore, index) in destination.datastoreList"
                        v-bind:key="datastore.datastore.name + '_' + index" :value="datastore">
                        [{{ datastore.datacentername }}]/[{{ datastore.resourcename }}] {{
                            datastore.datastore.name
                        }}
                        [{{ datastore.datastore.freeSpace | prettyBytes }}/{{ datastore.datastore.capacity |
                            prettyBytes
                        }}]
                    </option>
                </select>

            </div>

            <!------BACKUPMODE = 2DS - SELEZIONE DELLA CARTELLA DEL DATASTORE SELEZIONATO-------------------------------------------->
            <div class="form-group" v-if="destinationData.datastore != null && source.options.backupMode == '2DS'">
                <label> {{ $t("Destination folder on the Datastore") }}: </label>
                <!--Se sto richiedendo le cartelle del datastore selezionato, mostro un progress-->
                <div v-if="destinationData.datastore.datastore.folders == null" class="m-4" data-role="progress"
                    data-cls-back="bg-light" data-cls-bar="bg-light" data-cls-buffer="bg-cobalt" data-type="line"
                    data-small="true" />
                <!--Se ho ricevuto le cartelle del datastore selezionato, mostro la select-->
                <select v-else class="select w-100" v-model="destinationData.datastoreFolder">
                    <option v-for="folder in destinationData.datastore.datastore.folders" :key="folder.name"
                        :value="folder">
                        {{ folder.name }} ({{ folder.path }})</option>
                </select>
            </div>

            <!------BACKUPMODE = REP - INSERIMENTO SUFFISSO-------------------------------------------->
            <div v-if="source.options.backupMode == 'REP'">
                <p>{{ $t("Suffix to add to the name of the target virtual machine") }}</p>
                <input v-model.lazy="destination.options.replicaName" type="text" data-role="input" class="">
            </div>
        </div>
        <div class="dialog-actions">
            <div class="ml-auto">
                <button class="button error float-right ml-2" @click="$root.$emit('CLOSESOURCEDIALOG')">{{  $t("Close") }}</button>
                <button class="button ml-2" :class="{ 'disabled': step == 0 }" @click="step -= 1"><span class="mif-arrow-left"></span> {{ $t("Prev") }}</button>
                <button class="button ml-2"  :class="{ 'disabled': source.esxiSource == null || step == 4 || (step == 3 && (source.options.backupMode != '2DS' && source.options.backupMode != 'REP')) }" @click="step += 1">{{ $t("Next") }} <span class="mif-arrow-right"> </span> </button>
                <button class="button primary ml-2 float-right" v-show="step == 4 || (step == 3 && (source.options.backupMode != '2DS' && source.options.backupMode != 'REP'))" @click="saveSource"><span class="mif-checkmark pr-2"></span> {{ $t("Save") }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import SARepoSelect from "../../utils/SARepoSelect";
import { isValid, prettyBytes, waitSeconds, syncronizeArray, platformsTypes} from "../../../../public/assets/js/utilitiesmodule";

export default {
    name: "ESXiSourceDestination",
    props: {
        source: {
            type: Object,
            required: true,
        },
    },
    components: {
        "DialogTitle": DialogTitle,
        SARepoSelect
    },
    data() {
        return {
            step: 0,
            wait: false,

            destination: {
                id: null,
                type: this.$root.PlatformsTypesEnum.ESXi,
                type_name: platformsTypes[this.$root.PlatformsTypesEnum.ESXi].name,
                port: 21,
                id_service_account: -2,
                id_repository: -2,
                region: "",
                options: {
                    signatureversion: 2,
                    connectionType: 'ftps'
                },
                icon: "assets/img/icons/disk.png",
                path: "",
            },

            ESXiVmPowerState: [
                "OFF", "ON", "SUSPENDED"
            ],

            destinationData: {
                datastoreList: null,
                datastore: null,
                datastoreFolder: null
            },

            esxiselected: null,

        }
    },
    created: async function () {
        let self = this;
        if (!isValid(this.source.id))
            return;

        this.setESXiSource();
    },
    methods: {
        async sendSATestResult(testResult) {
            let self = this;
            if (self.step == 0) {
                self.source.id_service_account = testResult.serviceAccount.id;
                self.setESXiSource();
                return;
            }
            self.destination.id_service_account = testResult.serviceAccount.id;
            self.setESXiDestination();
        },
        async setESXiSource() {
            let self = this;
            self.wait = true;
            self.step = 1;
            var subSource = await this.$root.socket("GetVirtualMachinesESXi", this.source.id_service_account);
            self.source.driveImgSource = null;
            self.source.exchangeSource = null;
            self.source.dbSource = null;

            //Se la richiesta non è andata a buon fine, ritorno alla prima pagina
            if (subSource == null || subSource.dataCenters == null) {
                self.step = 0;
                self.source.id_service_account = -2;
                self.wait = false;
                return;
            }

            if (this.source.id == null) {
                this.source.esxiSource = subSource;
                self.source.enableCompression = false;
                self.source.options.backupType = "F";
                self.source.options.backupMode = "STD";
                self.source.options.replicaName = "-Replica";
                self.source.options.nCopies = 1;

                //carico su dataStoreList la lista parsata dei data store in modo da usarla quando mi serve 
                //self.dataStoreList = self.extractDatastoreList(subSource);

                self.wait = false;
                return;
            }

            var newDatacenters = subSource.dataCenters;
            var oldDatacenters = this.source.esxiSource.dataCenters;

            var syncronizationResult = syncronizeArray(4, oldDatacenters, newDatacenters);

            this.source.esxiSource.dataCenters = syncronizationResult.finalArray;
            self.wait = false;

            syncronizationResult.messages.forEach(async messageObj => {
                self.$root.formattedToast(this.$t(messageObj.message), messageObj.args, 3000, "warning");
                await waitSeconds(3);
            });
        },

        async setESXiDestination() {
            var destination_vm = await this.$root.socket("GetVirtualMachinesESXi", this.destination.id_service_account);
            if (destination_vm == null || destination_vm.dataCenters == null) {
                this.destination.id_service_account = -2;
                return;
            }

            this.destinationData.datastoreList = this.extractDatastoreList(destination_vm);
            this.destinationData.datastore = null;
            this.destinationData.datastoreFolder = null;
        },

        /*********************METODI***********************************************/
        async getFoldersFromDatastore() {
            if (this.source.options.backupMode == 'REP')
                return;

            //backupmode = 2DS
            this.destinationData.datastore.datastore.folders = await this.$root.socket(
                "GetDatastoreFoldersESXi",
                this.destination.id_service_account,
                {
                    "Datacenter": this.destinationData.datastore.datacentername,
                    "Datastore": this.destinationData.datastore.datastore.name
                }
            );

        },

        changeVMStatusEsxi() {
            alert("DA IMPLEMENTARE");
            //TODO Da implementare
        },


        saveSource() {

            this.$root.$emit("saveBackupSource", this.source);
            //in questo caso vado a salvare anche la destinazione di esxi che rimane unica e inmodificabile
            /* 
              name = folder.name oppure ReplicaName
              type = 40 per 2DS, 41 per REP
              type_name = 2DS, REP
              id_service_account = id service account di ESXI selezionato
              path = PATH della cartella del datastore
              options = da vedere se serve o no metterci dentro qualcosa 
            */
            if (this.source.options.backupMode == '2DS') {
                this.destination.name = this.destinationData.datastoreFolder.name;
                this.destination.path = this.destinationData.datastoreFolder.path;
                this.destination.type = 40;
                this.destination.type_name = "Datastore";
                this.destination.id_service_account = this.source.id_service_account;
                //salva la destinazione nel job e chiude la dialog
                this.$root.$emit("saveBackupDestination", this.destination);
            }
            if (this.source.options.backupMode == 'REP') {
                this.destination.name = this.destination.options.replicaName;
                this.destination.path = "";
                this.destination.type = 41;
                this.destination.type_name = "Replica";
                //salva la destinazione nel job e chiude la dialog
                this.$root.$emit("saveBackupDestination", this.destination);
            }
        },

        /*********************************UTILS**************************************/

        prettyBytes: function (bytes, precision) {
            return prettyBytes(bytes, precision);
        },

        extractDatastoreList(esxiObj) {
            var dataStoreListArray = [];

            esxiObj.dataCenters.forEach(datacenter => {
                //dcName = datacenter.name;
                datacenter.computerResourceList.forEach(resource => {
                    //rName = resource.name;
                    resource.datastores.forEach(datastore => {
                        var temp = {
                            "datacentername": datacenter.name,
                            "resourcename": resource.name,
                            "datastore": datastore
                        }
                        dataStoreListArray.push(temp);

                    })
                })
            });
            return dataStoreListArray;
        },

    }
}
</script>