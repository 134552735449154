import { BACKUP_MESSAGES, PROCESS_MESSAGES, ITEM_RENAMED, ITEMINFO_NOT_RETRIEVED, DATABASE_MESSAGES, DATACENTER_MESSAGES, DISK_MESSAGES, FILESYSTEM_NOT_RETRIEVED, FOLDER_CREATED, FOLDER_NOT_CREATED, ITEM_DELETED, ITEM_NOT_DELETED, ITEM_NOT_RENAMED, MAILBOXES_MESSAGES, PC_MESSAGES, SERVICEACCOUNT_MESSAGES, VIRTUAL_MACHINES_MESSAGES, EVENTLOGS_NOT_RETRIEVED, NETWORK_MESSAGES, FTP_MESSAGES, RESTOREFILE_MESSAGES, RESTORE_MESSAGES, ITEMS_NOT_RETRIEVED } from "../../public/assets/js/messages";


export const WebSocketInfo = {

    //command è uppercase
    // Se si chiama questo metodo prima di effettuare una richiesta con il messaggio command
    //  - forRequest è true;
    //  - okResponse è false
    //  - arg1 e arg2 sono gli eventuali argomenti da passare nel campo data dell'oggetto ritornato da questo metodo
    // Restituisce un oggetto tipo:
    //{
    //    waitResponse // true per default
    //    data // opzionale   
    //}
    // Se si chiama questo metodo dopo una risposta, timeout o disconnessione:
    //  - forRequest è false
    //  - okResponse è il valore indicativo della risposta che si vuole dare
    //  - arg1 contiene l'eventuale dato ritornato dalla risposta 
    // Restituisce, a seconda dei casi, un oggetto di tipo :
    //{
    //    "result": è il risultato della chiamata. se forRequest == true, result è il risultato di default
    //    "message": è il messaggio da visualizzare,
    //}
    buildInfo(command, forRequest, okResponse, msgObj, arg1, arg2, arg3) {
        if (command == undefined) {
            console.debug("command == undefined");
        }
        command = command.toUpperCase(); // per sicurezza
        var result; // è il risultato di default
        var data = null;

        switch (command) {

            // 0 PARAMETRI NO RISPOSTA -----------------------------------------------------------------------------
            //TODO DA IMPLEMENTARE PARTE AGENT
            /*case "UPDATE":
            case "UPDATEAGENT":
                waitResponse = false;
                result = null;
                message = null;
                messageType = null;
                break;*/

            // RICHIESTA -> 0 PARAMETRI-------------------------------------------------------------------
            // RISPOSTA REQUEST_STATUS (okResponse corrisponde al valore di command_status, trasformato in booleano)-----------------------------------------------------------------------------
            //attendo un {command: 'stop', msg_type: 'command_status', result: 'true'}

            case "SUBSCRIBE_PC": //configuro solo la risposta, poichè è stata fatta una richiesta personalizzata
                if (forRequest)
                    return this.request_nodata();

                // la risposta viene gestita in WebSocketClient -> onMessageCallback_CommandStatus
                if (okResponse)
                    return this.response_no_toast(true);

                msgObj = {
                    text: PC_MESSAGES.NOT_CONNECTED,
                    msgType: "ERROR",
                    msgArgs: null
                };
                return this.response_with_toast(msgObj, false);
            case "UNSUBSCRIBE_PC":
                if (forRequest)
                    return this.request_nodata();

                // la risposta viene gestita in WebSocketClient -> onMessageCallback_CommandStatus
                if (okResponse)
                    return this.response_no_toast(true);
                msgObj = {
                    text: PC_MESSAGES.NOT_CONNECTED,
                    msgType: "ERROR",
                    msgArgs: null
                };
                return this.response_with_toast(msgObj, false);
                // RICHIESTA -> 0 PARAMETRI-------------------------------------------------------------------
                // RISPOSTA COMMAND_STATUS (okResponse corrisponde al valore di command_status, trasformato in booleano)-----------------------------------------------------------------------------
                //attendo un {command: 'start', msg_type: 'command_status', result: 'true'}
            case "START":
            case "STARTSENDINFO":
                if (forRequest)
                    return this.request_nodata();

                if (okResponse)
                    return this.response_no_toast(true);
                msgObj = {
                    text: PC_MESSAGES.NOT_CONNECTED,
                    msgType: "ERROR",
                    msgArgs: null
                };
                return this.response_with_toast(msgObj, false);


                // la risposta viene gestita in WebSocketClient -> onMessageCallback_CommandStatus
                // RISPOSTA COMMAND_STATUS (okResponse corrisponde al valore di command_status, trasformato in booleano)-----------------------------------------------------------------------------
                //attendo un {command: 'stop', msg_type: 'command_status', result: 'true'}
            case "STOP":
            case "STOPSENDINFO":
                if (forRequest)
                    return this.request_nodata();

                // la risposta viene gestita in WebSocketClient -> onMessageCallback_CommandStatus
                if (okResponse)
                    return this.response_no_toast(true);

                msgObj = {
                    text: PC_MESSAGES.NOT_CONNECTED,
                    msgType: "ERROR",
                    msgArgs: null
                };
                return this.response_with_toast(msgObj, false);
            case "IS_ONLINE":
                if (forRequest)
                    return this.request_nodata();
                //TODO, ANDREA DEVE SISTEMARE LA RISPOSTA. ADESSO RITORNA UN ARRAY DEL TIPO [{idpc:true/false}], 
                //quindi al momento lo trasformo in un oggetto {idpc:true/false}
                if (okResponse) {
                    var obj = {};
                    var idPC;
                    arg1.forEach(x => {
                        idPC = Object.keys(x)[0]
                        obj[idPC] = x[idPC];
                    });
                    return this.response_no_toast(obj);
                }

                return this.response_no_toast(false);

                // RICHIESTA -> 0 PARAMETRI-------------------------------------------------------------------
                // RISPOSTA DATA (arg1 corrisponde al valore data ritornato dalla risposta)-----------------------------------------------------------------------------
            case "GETVMHYPERV":
            case "GETVIRTUALMACHINESHYPERV":
                if (forRequest)
                    return this.request_nodata();

                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, []);

            case "GETDISKINFO":
                if (forRequest) {
                    data = {
                        includeRemovableDrives: arg1,
                        includeVirtulDisks: arg2,
                        includeCBTComponentsStatus: arg3
                    };
                    return this.request_withdata(data);
                }



                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, []);

            case "GETWINUPDATES":
                if (forRequest) {
                    data = {

                    };
                    return this.request_withdata(data);
                }



                if (okResponse) {
                    return this.response_no_toast(arg1);
                }

                return this.response_with_toast(msgObj, []);

                // RICHIESTA -> 1 PARAMETRO 
                // RISPOSTA MESSAGE (okResponse è il risultato del comando inviato) -----------------------------------------------------------------------------
            case "CREATEJOB":
                if (forRequest) {
                    data = arg1;
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);



            case "SAVEJOB":
                if (forRequest) {
                    data = arg1;
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "DELETEJOB":
                if (forRequest) {
                    data = {
                        jobId: arg1,
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

                /*case "EditJob":
                    waitResponse = false;
                    result = false;
                    message = "";
                     messageType = "alert";
                    break;*/
            case "RUNJOB":
                if (forRequest) {
                    data = {
                        jobId: arg1,
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "TESTESXICONNECTION":
                if (forRequest) {
                    data = {
                        id_service_account: arg1,
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "RUNPROCESS":
                if (forRequest) {
                    data = {
                        path: arg1,
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "KILLPROCESS":
                if (forRequest) {
                    data = {
                        pid: arg1,
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "TESTSERVICEACCOUNT":
                if (forRequest) {
                    data = arg1;
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "STOPJOB":
                if (forRequest) {
                    data = {
                        jobId: arg1,
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "CREATEFOLDER":
                if (forRequest) {
                    data = {
                        path: arg1,
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "DELETEITEM":
                if (forRequest) {
                    data = {
                        path: arg1,
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

                // RICHIESTA -> 1 PARAMETRO 
                // RISPOSTA DATA (arg1 è il campo data ritornato dalla risposta) -----------------------------------------------------------------------------
            case "RETRIEVELOG":
            case "GETLOG":
                if (forRequest) {
                    data = {
                        logPath: arg1
                    };
                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, null);

            case "LISTDATABASES":
            case "GETDATABASELIST":
                if (forRequest) {
                    data = {
                        id_service_account: arg1
                    };
                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, null);

            case "GETINSTANCESLIST":
                    if (forRequest) {                       
                        return this.request_nodata();
                    }
    
                    if (okResponse)
                        return this.response_no_toast(arg1);
    
                    return this.response_with_toast(msgObj, null);                

            case "GETVMESXI":
            case "GETVIRTUALMACHINESESXI":
                if (forRequest) {
                    data = {
                        id_service_account: arg1
                    };
                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, null);

                // controlla che la directory indicata abbia un backup esxi corretto all'interno
            case "ESXI_CHECKPATHANDGETBACKUPDETAILS":
                if (forRequest) {
                    data = {
                        EsxiBackupDetails: arg1
                    };
                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, null);

                // controlla che la directory indicata abbia un backup esxi corretto all'interno
            case "HYPERV_CHECKPATHANDGETBACKUPDETAILS":
                if (forRequest) {
                    data = {
                        HyperVBackupDetails: arg1
                    };
                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, null);

                // controlla che la directory indicata abbia un backup esxi corretto all'interno
            case "HYPERV_COMPATIBILITYTEST":
                if (forRequest) {
                    data = {
                        HyperVTestVMCompatibilityModel: arg1
                    };
                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, null);
            case "HYPERV_RUNRESTORE":
                if (forRequest) {
                    data = {
                        HyperVRestoreModel: arg1
                    };
                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, null);
                //case "GETVMESXI":
            case "RESTOREVIRTUALMACHINE":
                if (forRequest) {
                    data = {
                        ESXiRestoreModel: arg1,
                        id_service_account: arg2
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "GETEXCHANGEMAILBOXES":
                if (forRequest) {
                    data = {
                        id_service_account: arg1
                    };
                    return this.request_withdata(data);
                }
                if (okResponse)
                    return this.response_no_toast(arg1)

                return this.response_with_toast(msgObj, []);

            case "RUNEXCHANGERESTORE":
                if (forRequest) {
                    data = {
                        ExchangeRestoreModel: arg1
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "RUNDATABASERESTORE":
                if (forRequest) {
                    data = {
                        DatabaseRestoreModel: arg1
                    };
                    return this.request_withdata(data);
                }
                if (okResponse)
                    return this.response_no_toast(true)

                return this.response_with_toast(msgObj, []);

                // RICHIESTA -> 2 PARAMETRI
                // RISPOSTA MESSAGE (okResponse è il risultato del comando inviato) -----------------------------------------------------------------------------
            case "CHANGEVMSTATUSHYPERV":
                if (forRequest) {
                    data = {
                        vmId: arg1,
                        newState: arg2
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "CHANGEJOBNAME":
                if (forRequest) {
                    data = {
                        jobId: arg1,
                        newJobName: arg2
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "RENAMEITEM":

                if (forRequest) {
                    data = {
                        oldPath: arg1,
                        newPath: arg2
                    };
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "TESTMAILACCOUNT":
            case "SENDEMAILTEST":

                if (forRequest) {
                    data = {
                        service_account: arg1,
                        recipient: arg2
                    };

                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

                // RICHIESTA -> 2 PARAMETRI
                // RISPOSTA DATA (arg1 è il campo data ritornato dalla risposta) -----------------------------------------------------------------------------
            case "GETDSFOLDERSESXI":
            case "GETDATASTOREFOLDERSESXI":

                if (forRequest) {
                    data = {
                        id_service_account: arg1,
                        targetDatastore: arg2
                    };
                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, []);

            case "GETEXPLOREFS":
                if (forRequest) {
                    /*arg1 :
                    {
                        nodeID: // (opzionale) id del nodo da esplorare
                        path: //percorso da esplorare,
                        options: //vedi ExploreFSOptions,
                    }

                    */
                    return this.request_withdata(arg1);
                }

                if (okResponse) { // di 
                    //è una oggetto:
                    //{
                    //    drives : //lista di stringhe, 
                    //    items: //lista di ClientTreeNode
                    //}
                    return this.response_no_toast(arg1);
                }

                return this.response_with_toast(msgObj, null);

            case "GETEVENTLOGS":

                if (forRequest) {
                    data = {
                        logName: arg1,
                        filter: arg2
                    }

                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, []);

            case "CONNECTNETWORK":
                if (forRequest) {
                    data = {
                        serviceAccount: arg1,
                    };
                    return this.request_withdata(data);
                }

                if (okResponse && arg1) {
                    return this.response_no_toast(arg1);
                }

                //console.log(arg1.error);
                return this.response_with_toast(msgObj, "");

            case "DISCONNECTNETWORK":
                if (forRequest) {
                    data = {
                        accountSessionId: arg1
                    }
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);
            
            case "REMFROMLICENSE":
                if (forRequest) {
                    data = {
                        pcID: arg1
                    }
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "GETEXPLORENETWORKFS":
                if (forRequest) {

                    /*arg1 :
                    {
                        accountSession : // id della sessione della connessione network
                        nodeID: // (opzionale) id del nodo da esplorare
                        path: //percorso da esplorare,
                        options: //vedi ExploreFSOptions,
                    }

                    */
                    return this.request_withdata(arg1);
                }

                if (okResponse) {
                    return this.response_no_toast(arg1); // è una lista di ClientTreeNode
                }

                return this.response_with_toast(msgObj, null)

            case "CONNECTFTP":
                if (forRequest) {
                    data = {
                        serviceAccount: arg1,
                        path: arg1.options.path
                    };
                    return this.request_withdata(data);
                }

                if (okResponse && arg1) {
                    return this.response_no_toast(arg1);
                }

                //console.log(arg1.error);
                return this.response_with_toast(msgObj, "");

            case "STARTCMD":
                if (forRequest) {
                    data = {
                        u: arg1,
                        p: arg2
                    };
                    return this.request_withdata(data);
                }

                if (okResponse) {
                    return this.response_no_toast(arg1);
                }

            case "RUNCMD":
                if (forRequest) {
                    data = {
                        command: arg1,
                    };
                    return this.request_withdata(data,false);
                }


            case "INSTALLWINUPDATE":
                if (forRequest) {
                    data = {
                        updateId: arg1,
                    };
                    return this.request_withdata(data,false);
                }

            case "DISCONNECTFTP":
                if (forRequest) {
                    data = {
                        accountSessionId: arg1
                    }
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "EXPLOREFTP":
                if (forRequest) {
                    /*arg1 :
                    {
                        accountSession : // id della sessione della connessione ftp
                        nodeID: // (opzionale) id del nodo da esplorare
                        path: //percorso da esplorare,
                        options: //vedi ExploreFSOptions,
                    }
                    */
                    return this.request_withdata(arg1);
                }

                if (okResponse) {
                    return this.response_no_toast(arg1); // è una lista di ClientTreeNode
                }

                return this.response_with_toast(msgObj, null);

            case "GETCONFIGFILEFORRESTOREFILE":
                if (forRequest) {
                    data = {
                        svcID: arg1,
                        iterationFolderPath: arg2
                    }

                    return this.request_withdata(data);
                }

                if (okResponse) {
                    console.log(arg1);
                    //if (arg1 == "")
                    //    return this.response_with_toast(RESTOREFILE_MESSAGES.NOT_BACKUP_FOLDER, "");
                    return this.response_no_toast(arg1);
                }

                return this.response_with_toast(msgObj, "");

            case "RESTOREFILE":
                if (forRequest) {
                    data = arg1; // restorefile -> restorefilemodel
                    return this.request_withdata(data);
                }

                return this.response_with_toast(msgObj, okResponse);

            case "GETITEMINFO": // ?????????????????????????????????????????
                if (forRequest) {
                    data = {
                        path: arg1,
                        type: arg2
                    };
                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1);

                return this.response_with_toast(msgObj, null);

            case "GETMICROSOFT365LIST":
                if (forRequest) {
                    data = {
                        serviceAccount: arg1,
                        type: arg2,
                        parentID: arg3
                    };
                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1); // array di resources che dovranno essere trasformati in ClientTreeNode

                return this.response_with_toast(msgObj, null);

            case "GETTREEFROMCONFIGFILE":
                if (forRequest) {
                    data = {
                        iterationFolderPath: arg1,
                        type: arg2
                    };
                    return this.request_withdata(data);
                }

                if (okResponse)
                    return this.response_no_toast(arg1); // nodo che contiene tutto l'albero

                return this.response_with_toast(msgObj, null);

            default:
                console.debug("METHOD " + command + " NOT MAPPED");
        }

        return null;
    },


    request_nodata() {
        return {
            waitResponse: true,
        }
    },

    request_withdata(data,waitResponse=true) {
        return {
            data: data,
            waitResponse: waitResponse
        }
    },

    response_with_toast(msgObj, result) {
        return {
            message: msgObj,
            result: result
        };
    },

    response_no_toast(result) {
        return {
            result: result
        };
    }
}