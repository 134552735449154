
export default {
    name: "PageNotFound",
    data() {
        return {

        };
    },
    props: {
        msg: String
    },
    created: function () {
        this.$store.commit('SET_LAYOUT', 'nav-layout')


    },
}