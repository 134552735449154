import Navigation from "../components/utils/Navigation";
export default {
    components: {
       
        Navigation,
       
    },
    name: "Settings",
    data() {
        return {

        };
    },
    props: {
        msg: String
    },
    created: function() {
        //this.$store.commit('SET_LAYOUT', 'nav-layout')

    },
}