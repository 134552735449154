import { FileFolderEntityType, isValid, GetAllPaths, GetFoldersNames, PlatformsTypesEnum } from "../../public/assets/js/utilitiesmodule";
import api from "./api";
import { ClientTreeNode } from "../models/TreeNode";

export class GraphClient {
    constructor(serviceAccount) {
        this.serviceAccount = serviceAccount;
        this.accessToken = serviceAccount.token;
    }

    async getUserByEmail(email) {
        var url = "https://graph.microsoft.com/v1.0/users?$filter=mail eq '" + email + "'";
        var user = await _Get(url, this.accessToken);
        if (user == null)
            return null;
        user = user[0];
        return new ClientTreeNode(null, user.id, user.mail, FileFolderEntityType.Account);
    }

    async getUsers(type) {
        var url = "https://graph.microsoft.com/v1.0/users?$select=id,displayName,mail,assignedPlans"
        var users = await _Get(url, this.accessToken);
        if (users == null)
            return null;
        var drive;
        var size = users.length;
        for (var i = 0; i < size; i++) {
            if (users[i].mail == null) {
                users.splice(i, 1);
                size--;
                i--;
                continue;
            }

            //Se ho richiesto gli utenti per onedrive o teams, ho bisogno del loro drive
            switch (type) {
                case PlatformsTypesEnum.Cloud_OneDrive:
                case PlatformsTypesEnum.Microsoft_OneDriveForBusiness:
                case PlatformsTypesEnum.Microsoft_Teams:
                    drive = await _GetDrive(users[i].id, this.accessToken);
                    if (drive == null) {
                        users.splice(i, 1);
                        size--;
                        i--;
                        continue;
                    }
            }


            users[i] = new ClientTreeNode(null, users[i].id, users[i].mail, FileFolderEntityType.Account)
        }

        return users;
    }



    async getDriveByUserID(userID) {
        var url = "https://graph.microsoft.com/v1.0/users/" + userID + "/drive";
        var drive = await _Get(url, this.accessToken);
        if (drive != null && drive.name == "OneDrive" && drive.driveType == "business")
            return drive;
        return null;
    }

    async getOneDriveRootNodes(userID, driveID) {
        var url = "https://graph.microsoft.com/v1.0/users/" + userID + "/drives/" + driveID + "/root/children";
        //var url = "https://graph.microsoft.com/v1.0/users/" + this.userID + "/drive/root/children";

        return await createRequest(url, this.accessToken);
    }

    async getOneDriveNodesFrom(userID, driveID, resource) {
        if (resource.resID == null)
            resource.resID = await _getIDFromPath(resource.path, this.accessToken);
        var url = "https://graph.microsoft.com/v1.0/users/" + userID + "/drives/" + driveID + "/items/" + resource.resID + "/children";

        var resources = await createRequest(url, this.accessToken);

        if (resources == null) return null;

        resources = resources.map(r => {
            r.path = resource.path + "/" + r.path;
            return r;
        });

        return resources;
    }

}


async function _GetDrive(userID, accessToken) {
    var url = "https://graph.microsoft.com/v1.0/users/" + userID + "/drive";
    var drive = await _Get(url, accessToken);
    if (drive != null && drive.name == "OneDrive" && drive.driveType == "business")
        return drive;
    return null;
}
async function _Get(url, accessToken) {
    try {
        var result = await api.get(url, {
            headers: {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json"
            }
        }).then(res => {
            console.log(res)
            return res;
        }).catch(e => {
            console.log(e);
            return null;
        });

        if (result == null || result.data == null)
            return null;
        return isValid(result.data.value) ? result.data.value : result.data;
    } catch (ex) {
        return null;
    }
}

async function createRequest(url, accessToken) {
    var response = await api.get(
            url, {
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    //"Accept-Encoding": "gzip, deflate"
                }
            }
        )
        .then(res => {
            var resources = res.data.value.map(r =>
                new ClientTreeNode(
                    null,
                    r.id,
                    r.name,
                    r.folder == undefined ?
                    FileFolderEntityType.Cloud_File :
                    FileFolderEntityType.Cloud_Folder
                )
            );

            return resources;
        })
        .catch(e => {
            console.log(e);
            return null;
        });

    console.log(response);
    return response;
}

async function _getIDFromPath(path, accessToken) {
    var folders = GetAllPaths(path);
    var foldersNames = GetFoldersNames(path);
    var parentID = "root";
    var children = [];
    var url;
    for (var i = 0; i < foldersNames.length; i++) {
        url = "https://graph.microsoft.com/v1.0/me/drive/items/" + parentID + "/children";

        children = await createRequest(url, accessToken);
        if (children == null)
            return null;
        children.map(c => {
            c.path = (parentID == "root" ? "" : folders[i - 1] + "/") + c.path;
            return c;
        });
        parentID = children.find(r => r.path == folders[i]).resID;
    }

    return parentID;
}