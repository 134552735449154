import Navigation from "../components/utils/Navigation";
import SARepoView from '../components/dialogs/view/SARepoView';
import NoDataAvailable from '../components/utils/NoDataAvailable';
import { platformsTypes } from "../../public/assets/js/utilitiesmodule";

export default {
    name: "Repositories",
    data() {
        return {
            repositories: null,
            serviceAccount: null,
            repository: null,
            selectedIndex: -1,

            platformsTypes: [],
            cloudTypes: [],
            microsoftTypes: [],
        };
    },
    props: {
        msg: String,
    },
    watch: {
        '$route': {
            handler: function(to) {
                if (to.hash != "") {
                    var idrepo = to.hash.replace("#", "");
                    this.$root.$emit('OPENREPOSITORYDIALOG', this.$session.get("REPOSITORIES").find((repo) => repo.id == idrepo))
                }

            },
            deep: true,
            immediate: true
        }
    },
    beforeDestroy: async function() {
        this.repositories = null;
        this.$root.$off("REFRESHREPOSITORIES");
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        //alert("destroy repositories.JS");
        //this.$root.checkConnectionTimerAction("stop");
        //this.$root.newPage("repositories", "destroy");
    },
    created: function() {
        //alert("CREATED COMPUTERS.JS");
        //this.$root.newPage("repositories", "created");
        this.repositories = this.$session.get("REPOSITORIES");
        let self = this;
        this.$root.$on("REFRESHREPOSITORIES", () => self.repositories = self.$session.get("REPOSITORIES"));

        this.platformsTypes = platformsTypes;
        this.cloudTypes = Object.keys(platformsTypes).filter(t => platformsTypes[t].mainType == 1);
        this.microsoftTypes = Object.keys(platformsTypes).filter(t => platformsTypes[t].mainType == 5);
        this.$root.$on("socketEventConnected", () => {
            self.$root.socketConnected = true;
            self.$root.checkConnectionTimerLight();
        });
        //"socketEventDisconnected" viene emesso se la connessione socket non è attiva
        this.$root.$on("socketEventDisconnected", () => {
            self.$root.socketConnected = false;
        });
        this.$root.checkSocket();

    },

    components: {
        NoDataAvailable,
        Navigation,
        SARepoView
    },
    methods: {
        async deleteRepository(repo, index) {
            if (!this.$root.openDialogActions()) return;

            var repoLite = {
                id: repo.id,
                name: repo.name
            }
            var deleted = await this.$root.api("DELETEREPOSITORY", repoLite)
            if (!deleted) return;

            this.repositories.splice(index, 1);
            this.$session.set("REPOSITORIES", this.repositories);
            this.$forceUpdate();
        },

        see(repo) {
            //apre un charm nella pagina
            this.serviceAccount = this.$session.get('SERVICEACCOUNTS').find(sa => sa.id == repo.id_service_account);
            this.repository = repo;
            window.Metro.charms.toggle("#reposelected");
        },


        closeCharm() {
            window.Metro.charms.toggle("#reposelected");
        }
    }
}