<template>
    <div class="dialog center text-small rounded  overflow w-100-sm w-100-md w-75-lg  w-50-xl" v-cloak>

        <DialogTitle :title="serviceAccount.id == null ? $t('Add Service Account') : serviceAccount.name"
            :defaultTitle="serviceAccount.id == null ? $t('Add Service Account') : $t('Edit/Test Service Account')"
            :subTitle="serviceAccount.type_name"
            @closeDialog="$root.$emit('CLOSESERVICEACCOUNTDIALOG')" />


        <div class="dialog-content">

            <!--------------------------FORM--------------------------------------------->

            <div class="form-group">
                <label>{{ $t("Name") }}</label>
                <input data-role="input" type="text" v-model="serviceAccount.name" maxlength="50"
                    :placeholder="$t('Enter friendly name')" data-prepend="<span class='mif-pencil'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <div class="form-group row">
                <div class="cell-10">
                    
                    <!-- input data-role="input" type="text" v-model="serviceAccount.url" placeholder="PC-NAME\SQLEXPRESS"
                        data-prepend="<span class='mif-database'></span>" /-->
                        <div class="d-flex align-items-center">
                        
                        <select data-role="select" data-filter="false" class="w-75" @change="onChange" v-model="serviceAccount.url">
                            <option v-if="instances.length > 1" value="-1" disabled>{{ $t("Select a local instance") }}</option>
                            <option v-for="(sa, index) in instances" 
                                class="p-0" :key="index" :value="sa.name">
                                {{ sa.name }}
                            </option>
                        </select>
                        <div v-if="searchingInstances==true" class="align-items-center"><span class="mif-spinner2 mif-3x ani-spin ml-3" /></div>
                        <div v-if="searchingInstances==true" class="align-items-center ml-3"><label class="ml-3">{{ $t("Searching for Sql instances..") }}</label></div>
                    </div>
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                <div class="cell-4" v-if="serviceAccount.type == 61">
                    <label>{{ $t("PORT") }}</label>
                    <input data-role="input" class="metro-input" type="text" v-model="serviceAccount.port"
                        :placeholder="$t('Enter Domain')" data-prepend="<span class='mif-cloud'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
            </div>
            <div class="form-group" v-if="serviceAccount.type == 60">
                <input type="radio" data-role="radio" :data-caption="$t('Use Integrate Windows Authentication')"
                    name="useWinAuth" :value=true v-model="serviceAccount.options.useWinAuth"><br>
                <input type="radio" data-role="radio" :data-caption="$t('Use SQL server Authentication')"
                    name="useWinAuth" :value=false v-model="serviceAccount.options.useWinAuth"><br>
            </div>

            <div class="form-group row">
                <div class="cell-6">
                    <label>{{ $t("Username") }}</label>
                    <input :disabled="serviceAccount.options.useWinAuth && serviceAccount.type == 60" data-role="input"
                        class="metro-input" type="text" v-model="serviceAccount.username"
                        :placeholder="$t('Enter Username')" data-prepend="<span class='mif-user'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                <div class="cell-6">
                    <label>{{ $t("Password") }}</label>
                    <input :disabled="serviceAccount.options.useWinAuth && serviceAccount.type == 60" data-role="input"
                        class="metro-input" type="password" v-model="serviceAccount.password"
                        :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>" />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
            </div>

            <div v-if="serviceAccount.type == 60" class="form-group">

                <input data-role="checkbox" type="checkbox" :data-caption="$t('Use TCP/IP Network Library')"  
                    data-validate="required" v-model="serviceAccount.options.useTcpIpNetLib" required
                    data-prepend="<span class='mif-server'></span>" /><br>
                <input data-role="checkbox" type="checkbox" :data-caption="$t('Use Persist Security Info')"
                    data-validate="required" v-model="serviceAccount.options.usePersistSecInfo" required
                    data-prepend="<span class='mif-server'></span>" /><br>
                <input data-role="checkbox" type="checkbox" :data-caption="$t('Use Integrated Security SSPI')"
                    data-validate="required" v-model="serviceAccount.options.useIntSecSSPI" required
                    data-prepend="<span class='mif-server'></span>" /><br>
                <input data-role="checkbox" type="checkbox"
                    :data-caption="$t('Use Trusted Connection (required for Windows authentication)')"  
                    data-validate="required" v-model="serviceAccount.options.useTrustConn" required
                    data-prepend="<span class='mif-server'></span>" /><br>
                <input data-role="checkbox" type="checkbox" :data-caption="$t('Use Encrypted Connection')"  
                    data-validate="required" v-model="serviceAccount.options.useEncConn" required
                    data-prepend="<span class='mif-server'></span>" /><br>
            </div>

            <!-------------------------------SCELTA PC----------------------------------------->
            <div v-if="$session.get('ROOMPC') == null" class="form-group">
                <label>
                    <span>{{ $t("Select PC to Test Service Account") }}</span>
                </label>
                <select class="select cell-9" v-model="testPC" :disabled="$session.get('COMPUTERS').length == 0">
                    <option :data-append="pc.note" v-for="pc in $session.get('COMPUTERS')" :value="pc"
                        :disabled="!$session.get('PCSCONNECTIONS')[pc.id]" v-bind:key="pc.id"> {{ pc.name }}
                    </option>
                </select>
            </div>
        </div>

        <!-------------------------------TEST ACCOUNT----------------------------------------->
        <div class="dialog-actions d-flex flex-justify-end">
            <div v-if="tested == TEST_WAIT"><span class="mif-spinner2 ani-spin" /></div>
            <button class="button alert mr-2" @click="$root.$emit('CLOSESERVICEACCOUNTDIALOG')">{{ $t("Cancel") }}</button>
            <button class="button primary mr-2" @click="test" :disabled="!enableTest">
                <span class="mif-checkmark pr-2" /> {{ $t("Test account") }}
            </button>
            <button class="button primary" :disabled="!enableSave"
                @click="$root.saveServiceAccount(serviceAccount)">
                <span class="mif-checkmark pr-2" /> {{ $t("Save") }}
            </button>
        </div>
    </div>
</template>

<script>
import DialogTitle from "../../utils/DialogTitle.vue";

export default {
    name: "DatabaseServiceAccount",
    components: {
        "DialogTitle": DialogTitle, 
    },
    props: {
        serviceAccount: Object
    },
    data() {
        return {
            testPC: null,
            // -1 : avviato
            // 0 : non avviato o fallito
            // 1 : ok
            tested: 0,
            index_SA: -1,
            TEST_NOTOK : 0,
            TEST_WAIT : -1,
            TEST_OK : 1,
            instances:[],
            searchingInstances: false,
        }
    },
    computed: {
        allInputCompleted() {
            var specificInputsCompleted;
            switch (this.serviceAccount.type) {
                case this.$root.PlatformsTypesEnum.Database_SQLServer:
                specificInputsCompleted = this.serviceAccount.options.useWinAuth ? this.serviceAccount.url != "" : this.serviceAccount.url != "" && this.serviceAccount.username != "" && this.serviceAccount.password!="";
                break;
                case this.$root.PlatformsTypesEnum.Database_MySQL:
                    specificInputsCompleted = this.serviceAccount.url!= "";
                    break;
            }
            return this.serviceAccount.name != "" && (this.$session.get('ROOMPC') != null || this.testPC != null) && specificInputsCompleted;
        },
        enableTest() {
            return this.allInputCompleted && this.tested == this.TEST_NOTOK;
        },
        enableSave() {
            return this.allInputCompleted && (this.tested == this.TEST_OK || this.tested== this.TEST_NOTOK && this.serviceAccount.id != null);
        }

    },
    created(){

       
        if(this.serviceAccount.options.lastTestPC !=null)
            this.testPC = this.$session.get("COMPUTERS").find(pc => pc.id == this.serviceAccount.options.lastTestPC);


        // se sql server deve caricare le istanze relative al computer dove èinstallato l'agent
        this.getInstances();

    },
    methods: {
        async test() {
            this.tested = (await this.$root.testServiceAccount(this.serviceAccount, this.testPC)).okNumber;
        },

    async getInstances(){

        let self = this;

        self.searchingInstances = true,

        await this.$root.socket("GETINSTANCESLIST")
                 .then(response => {
                    self.searchingInstances = false;
                    if (response.instances){
                        self.instances = response.instances;

                    if (response.instances.length==1){
                        self.serviceAccount.url = self.instances[0].name;
                    }

                    }                                          
                })
                .catch(error => {
                    // Gestisci l'errore qui
                    self.searchingInstances = false,
                    console.error(error);
                    self.$root.toast(this.$t(messageObj.message), 3000, "warning");
                });
        
    },

    onChange(event){

        // per aiutare l'utente si imposta il friendly name uguale al nome dell'istanza
        let self = this;
        const selectedIndex = event.target.selectedIndex;
        self.serviceAccount.name = event.target.options[selectedIndex].text;       
    },

    }
}

</script>