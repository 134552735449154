<template>
    <div>
        <Navigation pageName="computers" />
        <div v-if="filteredcomputers == null" class="d-flex flex-justify-center p-10">
            <span class="mif-spinner2 fg-primary ani-spin mif-3x" />
        </div>
        

        <NoDataAvailable v-else-if="filteredcomputers.length == 0" :message="$t('No computers available')" />

       
        
        <div v-else >
          
            <div class="tiles-grid d-flex flex-wrap mx-8 p-8">
                <div v-for="pc in filteredcomputers" v-bind:key="pc.id" style="max-width: 150px;"   class="mx-8  rounded" width=100>
                    <router-link :disabled="!$session.get('PCSCONNECTIONS')[pc.id]" :to="'/computer/' + pc.id" :key="pc.id" :event="$session.get('PCSCONNECTIONS')[pc.id] ? 'click' : ''"   class="bg-transparent " >
                            <p class="icon text-center p-0"><svg width="26" height="26" fill="none" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg"><path d="M6.75 22a.75.75 0 0 1-.102-1.493l.102-.007h1.749v-2.498H4.25a2.25 2.25 0 0 1-2.245-2.096L2 15.752V5.25a2.25 2.25 0 0 1 2.096-2.245L4.25 3h15.499a2.25 2.25 0 0 1 2.245 2.096l.005.154v10.502a2.25 2.25 0 0 1-2.096 2.245l-.154.005h-4.25V20.5h1.751a.75.75 0 0 1 .102 1.494L17.25 22H6.75Zm7.248-3.998h-4l.001 2.498h4l-.001-2.498Z" fill="#212121"/></svg>  <span class="badge bg-transparent"  v-bind:class="{ 'fg-green': $session.get('PCSCONNECTIONS')[pc.id], 'fg-red': !$session.get('PCSCONNECTIONS')[pc.id]  }" style="font-size: 30px;">•</span></p>
                            <p class="text-center text-bold p-0"> {{pc.name}} </p>
                        </router-link> 
                        <p v-if="pc.description" class="text-small text-center p-0 m-0"> {{pc.description}}</p> 
                        <p v-if="pc.ipaddress_public" class="text-small text-center p-0 m-0"> {{pc.ipaddress_public}}</p> 
                </div>
            </div>
        </div>
        
    </div>
    
</template>
<script>

import Navigation from './utils/Navigation';
export default {
    name: "Computers",
    components:{
        Navigation,
        //Tile
    },
    data() { return { computers: null,filteredcomputers:null,     connectionPctimeInterval:null } },
    async created() {
        //this.$store.commit('SET_LAYOUT', 'nav-layout')
        this.computers = this.$session.get("COMPUTERS");
        this.filteredcomputers = this.computers;
        this.$root.checkConnectionTimerLight();
    },
     mounted: function () {
        this.$root.$on("filteredString",(data) => this.filteredList(data));
    },
    beforeDestroy: function() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
      clearInterval(this.connectionPctimeInterval);
    },
    methods:{

        filteredList(data) {
            this.filteredcomputers = this.computers;
            this.filteredcomputers = this.computers.filter((computer) =>{
                return computer.name.toLowerCase().includes(data.toLowerCase()) || computer.description.toLowerCase().includes(data.toLowerCase())
            }); 
        },
        
    }
}
</script>
<style scoped>
a {
    text-decoration: none;
    color: black
}

.tile-medium{
    max-width: none;
}
</style>