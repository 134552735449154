var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 overflow" },
    [
      _c("Navigation", { attrs: { pageName: "activesessions" } }),
      _c(
        "div",
        { staticClass: "p-5 w-100 h-100", attrs: { id: "activesessions" } },
        [
          _vm.controls
            ? _c(
                "table",
                {
                  staticClass: "panel bg-white compact table w-100 mt-4",
                  attrs: { id: "controls-list" },
                },
                [
                  _c("thead", { staticClass: "mb-0" }, [
                    _c("tr", { staticClass: "mb-3" }, [
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Operator")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Source")))]
                      ),
                      _c("td", {
                        staticClass: "text-center",
                        staticStyle: { "vertical-align": "middle" },
                      }),
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Destination")))]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          staticStyle: { "vertical-align": "middle" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Start date")))]
                      ),
                      _c("td", {
                        staticClass: "text-left",
                        staticStyle: { "vertical-align": "middle" },
                      }),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      !_vm.controls
                        ? _c("tr", { staticClass: "pt-10" }, [
                            _c("td", {
                              staticClass: "p-6",
                              attrs: {
                                colspan: "7",
                                "data-role": "activity",
                                "data-type": "metro",
                                "data-style": "dark",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.controls, function (control, index) {
                        return _c("tr", { key: control.id + "-" + index }, [
                          _c("td", { staticClass: "text-bold text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  control.firstname + " " + control.lastname
                                ) +
                                " "
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              staticClass:
                                "d-flex flex-align-center no-wrap text-center flex-justify-center",
                            },
                            [
                              _c("PCLink", {
                                attrs: {
                                  pc: {
                                    id: control.id,
                                    name: control.computer_name,
                                    device: control.device,
                                    os_version_full: control.os_version_full,
                                  },
                                },
                              }),
                              _c("PCRemoteLink", {
                                attrs: {
                                  pc: {
                                    id_remote: control.id_remote,
                                    id: control.id,
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._m(0, true),
                          _c(
                            "td",
                            {
                              staticClass:
                                "d-flex flex-align-center no-wrap text-center flex-justify-center",
                            },
                            [
                              _c("PCLink", {
                                attrs: {
                                  pc: {
                                    id: control.target_host_guid,
                                    name: control.name_computer_host,
                                    device: "",
                                    os_version_full: "",
                                  },
                                },
                              }),
                              _c("PCRemoteLink", {
                                attrs: {
                                  pc: {
                                    id_remote: control.target_host_id,
                                    id: control.target_host_guid,
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c("td", { staticClass: "text-center" }, [
                            control.dt_start != "" && control.dt_start != null
                              ? _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.formatDate(control.dt_start))
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("td", { staticClass: "text-left" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "bg-transparent button fg-red fg-white",
                                attrs: { title: _vm.$t("Terminate") },
                              },
                              [_c("span", { staticClass: "mif mif-cross" })]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticStyle: { "vertical-align": "middle" } }, [
      _c("span", { staticClass: "mif mif-2x mif-arrow-right text-center" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }