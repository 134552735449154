<template>
    <li class="p-2 mb-2 tree-item" @mouseover="hover = true"  @click="selectItem" @mouseleave="hover = false" :class="{ 'hovered': hover, 'selected-item': item.selected  }">
      <div class="tree-item-header" :title="item.name + ' [' + item.os_version + ']'">
        <input type="checkbox" v-model="item.selected" @change="updateSelection(item)">
        <span class="mif-computer mif-lg ml-1"></span>                
        <span class="ml-2">{{ item.name }}</span>
        <!--button @click="selectAll(item)">Select All</button>
        <button @click="deselectAll(item)">Deselect All</button-->
      </div>
      <ul v-if="item.virtualHardDisks">
        <TreeDisk
          v-for="disk in item.virtualHardDisks"
          :key="disk.id"
          :disk="disk"
        />
      </ul>
    </li>
  </template>
  
  <script>
  import TreeDisk from './TreeDisk.vue';
  
  export default {
    components: { TreeDisk },
    props: {
      item: Object,
      hvselected: Object,
      selectedCount: Number,
    },
    data() {
        return {
            hover: false,
            localSelectedCount: this.selectedCount,
        };
    },
    watch: {
    selectedCount(newVal) {
      this.localSelectedCount = newVal; // Sync local property with prop
    }
  },
    methods: {
        selectItem() {
            //this.item.selected = !this.item.selected;
            this.$emit('update:hvselected', this.item);
        }, 
        updateSelection(item) {
            if (item.selected) {
                this.localSelectedCount++;
            } else {
                this.localSelectedCount--;
            }
            this.$emit('update:selectedCount', this.localSelectedCount);

        }         
    },
  };
  </script>
  
  <style scoped>
  .tree-item-header {
    
    align-items: left;
    cursor: pointer;
  }
  button {
    margin-left: auto;
    margin-right: 5px;
  }
  .tree-item {
  transition: background-color 0.3s ease;
}

.tree-item.hovered {
  background-color: #f0f0f0;
}
.tree-item.selected-item {
  background-color: #d3d3d3; /* Or any color you prefer */
}
  </style>
  