var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "" },
    [
      _c("Navigation", { attrs: { pageName: "settings" } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-2" }),
        _c(
          "div",
          {
            staticClass:
              "bg-white p-4 col-lg-8 col-md-12 border border-radius pt-9",
          },
          [
            _c("form", [
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("General Settings")))]),
                    _c("td", [_vm._v(_vm._s(_vm.$t("Email")))]),
                    _c("td", [_vm._v(_vm._s(_vm.$t("Push")) + " ")]),
                    _c("td", { staticStyle: { width: "600px" } }, [
                      _vm._v(_vm._s(_vm.$t("Email (max 2)"))),
                    ]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("Backup Notifications")))]),
                    _vm._m(0),
                    _vm._m(1),
                    _vm._m(2),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$t("Hardware Notifications"))),
                    ]),
                    _vm._m(3),
                    _vm._m(4),
                    _vm._m(5),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("System Notifications")))]),
                    _vm._m(6),
                    _vm._m(7),
                    _vm._m(8),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "p-4",
                  attrs: {
                    "data-role": "accordion",
                    "data-one-frame": "false",
                    "data-show-active": "true",
                    "data-on-frame-open":
                      "console.log('frame was opened!', arguments[0])",
                    "data-on-frame-close":
                      "console.log('frame was closed!', arguments[0])",
                  },
                },
                [
                  _c("div", { staticClass: "frame" }, [
                    _c("div", { staticClass: "heading" }, [
                      _vm._v(_vm._s(_vm.$t("Advanced Settings"))),
                    ]),
                    _c("div", { staticClass: "content" }, [
                      _c("div", { staticClass: "p-2" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$t("Advanced Settings")) + " "
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(_vm.$t("Weekly")))]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.$t("Monthly")) + " "),
                              ]),
                              _c("td", { staticStyle: { width: "600px" } }, [
                                _vm._v(_vm._s(_vm.$t("Email (max 2)"))),
                              ]),
                            ]),
                          ]),
                          _c("tbody", [
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Backup Reports")) + " "
                                ),
                              ]),
                              _vm._m(9),
                              _vm._m(10),
                              _vm._m(11),
                            ]),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$t("Idle Backup Reports")) + " "
                                ),
                              ]),
                              _vm._m(12),
                              _vm._m(13),
                              _vm._m(14),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "row pt-4" }, [
                _c("div", { staticClass: "cell d-flex flex-justify-end" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button primary",
                      attrs: { type: "submit" },
                    },
                    [_vm._v(_vm._s(_vm.$t("Save settings")))]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("div", { staticClass: "row mb-2" }, [
        _c("input", {
          attrs: {
            type: "checkbox",
            "data-caption-position": "left",
            "data-role": "switch",
            "data-on": "on",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("div", { staticClass: "row mb-2" }, [
        _c("input", {
          attrs: {
            type: "checkbox",
            "data-caption-position": "left",
            "data-role": "switch",
            "data-on": "on",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("input", {
        attrs: { type: "text", "data-role": "taginput", "data-max-tags": "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("div", { staticClass: "row mb-2" }, [
        _c("input", {
          attrs: {
            type: "checkbox",
            "data-caption-position": "left",
            "data-role": "switch",
            "data-on": "on",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("div", { staticClass: "row mb-2" }, [
        _c("input", {
          attrs: {
            type: "checkbox",
            "data-caption-position": "left",
            "data-role": "switch",
            "data-on": "on",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("input", {
        attrs: { type: "text", "data-role": "taginput", "data-max-tags": "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("div", { staticClass: "row mb-2" }, [
        _c("input", {
          attrs: {
            type: "checkbox",
            "data-caption-position": "left",
            "data-role": "switch",
            "data-on": "on",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("div", { staticClass: "row mb-2" }, [
        _c("input", {
          attrs: {
            type: "checkbox",
            "data-caption-position": "left",
            "data-role": "switch",
            "data-on": "on",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("input", {
        attrs: { type: "text", "data-role": "taginput", "data-max-tags": "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("div", { staticClass: "row mb-2" }, [
        _c("input", {
          attrs: {
            type: "checkbox",
            "data-caption-position": "left",
            "data-role": "switch",
            "data-on": "on",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("div", { staticClass: "row mb-2" }, [
        _c("input", {
          attrs: {
            type: "checkbox",
            "data-caption-position": "left",
            "data-role": "switch",
            "data-on": "on",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("input", {
        attrs: { type: "text", "data-role": "taginput", "data-max-tags": "2" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("div", { staticClass: "row mb-2" }, [
        _c("input", {
          attrs: {
            type: "checkbox",
            "data-caption-position": "left",
            "data-role": "switch",
            "data-on": "on",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("div", { staticClass: "row mb-2" }, [
        _c("input", {
          attrs: {
            type: "checkbox",
            "data-caption-position": "left",
            "data-role": "switch",
            "data-on": "on",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [
      _c("input", {
        attrs: { type: "text", "data-role": "taginput", "data-max-tags": "2" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }