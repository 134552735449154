<template>
    <div v-on:keyup.esc="$root.$emit('CLOSESOURCEDIALOG')" class="dialog overflow w-100-sm w-100-md w-75-lg  w-50-xl">

        <DialogTitle :title="$t('Configure Source')" :defaultTitle="$t('Configure Source')" :subTitle="source.type_name"
            @closeDialog="$root.$emit('CLOSESOURCEDIALOG')" />

        <!--SCELTA PERCORSI-->
        <div v-if="step == STEP_SA_AND_SOURCE" class="d-flex dialog-content flex-column">
            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->

            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <SARepoSelect :type=source.type :idSA=source.id_service_account :idRepo=null
                @sendSATestResult="sendSATestResult" />

            <SelectedResourcesLayout :buttonText="$t('Choosing files and folders')" :singleItem="false"
                :isDisabled="!testResult.ok" :list="source.networkSource.paths" :heightPercentage="30" :canCancel="true"
                @clickFunction="toggleFileSystemSidebar" />
            <div class="d-flex flex-column form-group h-20 mt-auto">

                <input type="checkbox" v-model="source.options.copySecurityPermissions" data-role="checkbox"
                    :data-caption="$t('Copy security permissions')" :disabled="!testResult.ok" />
                <input type="checkbox" v-model="source.options.recreateCompletePath" data-role="checkbox"
                    :data-caption="$t('Recreate the full path of the items')" :disabled="!testResult.ok" />
            </div>
        </div>


        <!--FILTRI-->
        <div class="dialog-content" v-show="step == STEP_FILTERS">
            <div class="form-group">
                <label class="text-bold">
                    <span class="mif-filter icon mr-1" />
                    <span>{{ $t('Filters') }}</span>
                </label>
            </div>

            <!--FILTRI - ESTENSIONI-->
            <div class="form-group">
                <div class=" d-flex flex-align-center">
                    <input type="checkbox" data-role="switch" data-material="false"
                        v-model="source.options.extensionsFilterAction" :true-value="1" :false-value="0" />
                    <label v-if="source.options.extensionsFilterAction == 0"> {{ $t('Exclude specified extensions') }}
                    </label>
                    <label v-else>{{ $t('Include only specified extensions') }}</label>
                </div>
            </div>
            <div class="form-group pb-5">
                <div class="d-flex">
                    <input id="taginput" type="text" data-role="tag-input" data-tag-separator=";"
                        class="flex-self-center">
                </div>
            </div>

            <!--FILTRI - DIMENSIONE FILE-->
            <div class="form-group">
                <div>
                    <input type="checkbox" v-model="source.options.useFileSizeFilter"
                        :data-caption="$t('File size filter')" data-role="checkbox" />
                </div>
                <div class="w-100 d-flex flex-align-center">
                    <select v-model="source.options.fileSizeFilterAction" :disabled="!source.options.useFileSizeFilter"
                        class="mr-2 input input-large ">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterAction" :value="index"
                            v-bind:key="index">{{ $t(value) }}</option>

                    </select>

                    <select v-model="source.options.fileSizeFilterCompare" :disabled="!source.options.useFileSizeFilter"
                        class="mr-2 input input-large  ">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterCompare" :value="index"
                            v-bind:key="index">{{ $t(value) }}</option>
                    </select>

                    <input type="number" v-model="source.options.fileSize" :disabled="!source.options.useFileSizeFilter"
                        class=" input " style="width:100px;" />
                    <span> MB</span>
                </div>
            </div>
        </div>

        <!--ESCLUSIONI-->
        <div class="dialog-content" v-if="step == STEP_EXCLUSIONS">
            <div class="form-group">
                <h6 class="text-bold">
                    <span class="mif-blocked icon mr-1" />
                    <span>{{ $t('Exclusions') }}</span>
                </h6>
            </div>

            <div class="form-group">{{ $t("Click on an item to select subfolders or files to exclude") }}</div>
            <!--VISUALIZZAZIONE DEGLI ELEMENTI SELEZIONATI-->
            <div class="form-group overflow h-vh-20">
                <table class="bd-black border border-radius-10 border-size-1 d-table-lg striped table text-small">
                    <tbody>
                        <tr v-for="res in source.networkSource.paths" @click="openFileSystemSidebarFrom(res)"
                            v-bind:key="res.id">
                            <td class="d-flex">
                                <span :class="getMetroIcon(res.type, res.path)" class="px-2" />
                                <span v-if="source.options.recreateCompletePath" class="flex-self-center">{{ res.path
                                    }}</span>
                                <span v-else class="flex-self-center">{{ (res.path.indexOf('/') != -1 ?
        res.path.split('/') : res.path.split('\\')).at(-1) }}</span>
                            </td>
                            <td><span class='mif-bin' @click="removeNode(res)" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-bold form-group">{{ $t("Paths to exclude selected:") }}</div>

            <NoDataAvailable v-if="source.options.excludedPaths.length == 0"
                :message="$t('No excluded path selected')" />
            <!--VISUALIZZAZIONE DEGLI ELEMENTI-->
            <div v-else class="overflow form-grouph-vh-20">
                <div class="cell">
                    <table class="bd-black border border-radius-10 border-size-1 d-table-lg striped table text-small">
                        <tbody>
                            <tr v-for="res in source.options.excludedPaths" :title="res.path" v-bind:key="res.id">
                                <td class="d-flex">
                                    <span :class="getMetroIcon(res.type, res.path)" class="px-2" />
                                    <span lass="flex-self-center">{{ cutText(res.path, 50) }}</span>
                                </td>
                                <td><span class='mif-bin' @click="removeNode(res)" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-group text-small" v-if="step == 2">
                <input type="checkbox" v-model="source.options.excludeSubFolders" />
                <label> {{ $t("Do not include any subfolders (only files in the root folder will be copied)") }}</label>
            </div>
        </div>

        <!--BACKUP-->
        <div class="dialog-content" v-show="step == STEP_BACKUPTYPE">
            <div class="form-group">
                <label class="text-bold">
                    <span class="mif-file-upload icon mr-1" />
                    <span>{{ $t('Backup') }}</span>
                </label>
            </div>
            <div class="form-group">
                <label>{{ $t("Type") }}:</label>
                <select v-model="source.options.backupType" data-role="select" data-filter="false">
                    <option v-for="key in Object.keys($root.selectValues.backupTypes)" :value="key" v-bind:key="key">{{
        $t($root.selectValues.backupTypes[key]) }}</option>
                </select>
            </div>

            <div class="form-group">
                <label>{{ $t("Number of copies") }}:</label>
                <input type="number" v-model="source.options.nCopies" max="365" data-role="input" />
            </div>
        </div>

        <!--COMPRESSIONE ZIP-->
        <div class="dialog-content p-4" v-if="step == STEP_COMPRESSION" :disabled="source.options.backupType == 'U'">

            <h6 class="text-bold">
                <span class="mif-file-zip icon mr-1" />
                <span>{{ $t('Zip compression') }}</span>
            </h6>
            <div class="form-group">
                <input type="checkbox" v-model="source.options.useArchiveCompression" data-role="checkbox"
                    :data-caption="$t('Enable zip compression')" />
                <br />
            </div>

            <div class="form-group d-flex flex-justify-between" :disabled="!source.options.useArchiveCompression">
                <div>
                    <label>{{ $t("Archive type") }}</label>
                    <select class="input" v-model="source.options.archiveType">
                        <option v-for="(value, index) in $root.selectValues.archiveTypes" :value="index"
                            v-bind:key="index">
                            {{ $t(value) }}
                        </option>
                    </select>
                </div>
                <!--
    09/09/2024 : Al momento si considera solo il percorso completo (source.options.pathsInArchive = 1) all'interno del file zip.
    Se si vorranno implementare altre modalità, basta attivare questo div
    -->
                <div class="d-none">
                    <label>{{ $t("Paths in archive") }}</label>
                    <select class="input" v-model="source.options.pathsInArchive">
                        <option v-for="(value, index) in $root.selectValues.pathsInArchive" :value="index"
                            v-bind:key="index">{{ $t(value) }}
                        </option>
                    </select>
                </div>
            </div>


            <div class="form-group" :disabled="!source.options.useArchiveCompression">
                <input type="checkbox" v-model="source.options.useArchiveFileCustomName" data-role="checkbox" />
                <label>{{ $t("Give the zip file a custom name") }}</label>
                <br />
                <input type="text" class="input" v-model="source.options.archiveFileCustomName"
                    :disabled="!source.options.useArchiveFileCustomName" required />
            </div>

            <div class="form-group" :disabled="!source.options.useArchiveCompression">
                <input type="checkbox" v-model="source.options.useArchivePassword" data-role="checkbox"
                    :data-caption="$t('Protect zip files using password')" />
            </div>
            <div :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword"
                class="form-group">
                <label>{{ $t("Password") }}:</label>
                <input data-role="input" type="password" v-model="source.options.archivePassword"
                    :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>"
                    :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword" />
                <small class="text-muted">{{ $t("Required") }}</small>
                <br />
                <label>{{ $t("Protection type") }}:</label>
                <br />
                <select class="input" v-model="source.options.protectionType"
                    :disabled="!source.options.useArchiveCompression || !source.options.useArchivePassword">
                    <option v-for="(value, index) in $root.selectValues.protectionTypes" :value="index"
                        v-bind:key="index">{{ $t(value) }}
                    </option>
                </select>

            </div>
        </div>
        <div class="dialog-actions row w-100 h-25 d-flex flex-justify-end">
            <!--div class="cell-4 text-small flex-left" v-if="step==2" style="margin-left:10%">
        <input type="checkbox" v-model="source.options.excludeSubFolders" />
        <label>  {{ $t("Do not include any subfolders (only files in the root folder will be copied)")}}</label>
    </div-->

            <input type="button" v-on:click="setStep(step - 1)" :value="$t('Prev')" class="button primary"
                :disabled="step == 0" />
            <input v-if="!showSaveButton" type="button" v-on:click="setStep(step + 1)" :value="$t('Next')"
                class="button primary" :disabled="step == 4 || source.folderSource.paths.length == 0" />
            <input type="button" v-on:click="saveSource" :value="$t('Save')" class="button primary"
                v-if="showSaveButton" />
        </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import SARepoSelect from "../../utils/SARepoSelect";
import NoDataAvailable from "../../utils/NoDataAvailable.vue";
import SelectedResourcesLayout from "../../utils/SelectedResourcesLayout.vue"
import { cutText, getMetroIcon } from "../../../../public/assets/js/utilitiesmodule";
import { EXTENSION_NOT_VALID } from "../../../../public/assets/js/messages";
import $ from 'jquery'
import { ClientTreeNodeMethods } from "../../../models/TreeNode";

export default {
    name: "NetworkSource",
    props: {
        source: Object,
    },
    components: {
        "DialogTitle": DialogTitle,
        "NoDataAvailable": NoDataAvailable,
        SARepoSelect,
        SelectedResourcesLayout
    },
    data() {
        return {
            step: 0,
            testResult: {
                ok: false,
                okNumber: 0,
                serviceAccount: { id: -2, type: this.$root.PlatformsTypesEnum.Network },
                client: null,// il client della piattaforma che effettuerà le richieste dei list
                list: null,
            },
            STEP_SA_AND_SOURCE: 0,
            STEP_FILTERS: 1,
            STEP_EXCLUSIONS: 2,
            STEP_BACKUPTYPE: 3,
            STEP_COMPRESSION: 4
        }
    },
    computed: {
        showSaveButton() {
            return (this.step == 3 && this.source.options.backupType == 'U') || this.step == 4
        }
    },

    created: function () {
        this.$root.$emit('closeFileSystemSidebar');

    },

    async beforeDestroy() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        if (this.testResult.client != null) {
            var disconnect = await this.$root.socket("disconnectnetwork", this.testResult.client.accountSessionId);
            console.log(disconnect);
        }
    },
    methods: {
        async sendSATestResult(testResult) {
            this.testResult = testResult;
            this.source.id_service_account = testResult.serviceAccount.id;
        },
        removeNode(node, inSource) {
            var index;
            if (inSource) {
                index = this.source.networkSource.paths.findIndex(res => res.path == node.path);
                this.source.networkSource.paths.splice(index, 1);
            } else {
                index = this.source.options.excludedPaths.findIndex(res => res.path == node.path);
                this.source.options.excludedPaths.splice(index, 1);
            }
            this.$root.$emit("uncheckNode", node);
        },


        setStep(nStep) {
            this.step = nStep;

            if (this.step == 2) {

                let self = this;
                setTimeout(function () {
                    var taginput = window.Metro.get$el("#taginput").data("taginput").options;
                    taginput.onBeforeTagAdd = (val, values) => self.onBeforeTagAdd('', val, values);
                    taginput.onTagAdd = (tag, val, values) => self.onTagAdd(tag, val, values)

                }, 1000);
            }

            this.$root.$emit("closeFileSystemSidebar");
        },

        async toggleFileSystemSidebar() {
            if (this.testResult.client.serviceAccount.options.accountSessionId == "") {
                this.testResult.client.serviceAccount.options.accountSessionId = await this.$root.socket("connectnetwork", this.testResult.client.serviceAccount);
            }
            var config = {
                type: this.$root.PlatformsTypesEnum.Network,
                includeFiles: true,
                multipleChoice: true,
                client: this.testResult.client,
                rootPath: this.testResult.client.serviceAccount.options.path
            };

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.source.networkSource.paths,
                config
            );
        },

        async openFileSystemSidebarFrom(res) {
            if (new ClientTreeNodeMethods().isFile(res)) return;
            if (this.testResult.client.serviceAccount.options.accountSessionId == "") {
                var method = this.restore.type == 2 ? "connectftp" : "connectnetwork";
                this.sourceData.client.serviceAccount.options.accountSessionId = await this.$root.socket(method, this.sourceData.client.serviceAccount);
            }
            var config = {
                type: this.$root.PlatformsTypesEnum.Network,
                includeFiles: true,
                multipleChoice: true,
                client: this.testResult.client,
                rootPath: res.path
            };

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.source.options.excludedPaths,
                config
            );
        },

        saveSource() {
            let self = this;
            //I paths sono nodi ma devo salvare solamente la struttura {path:'', type:''}
            self.source.networkSource.paths = self.source.networkSource.paths.map(n => {
                return {
                    path: n.path,
                    type: n.type
                }
            });

            if (self.source.options.excludedPaths.length == 0)
                self.source.options.excludedPaths = "";
            else //paths?type(FileFolderEntityType)|paths?type(FileFolderEntityType)| ....
                self.source.options.excludedPaths = self.source.options.excludedPaths.map(r => r.toString()).join("|");

            this.$root.$emit("saveBackupSource", this.source);
        },

        //---------------------------------TAG INPUT------------------------------------------------------------------
        onBeforeTagAdd(tag, val) {

            console.log("check tag " + val);

            var valToArray = val.toLowerCase().split("");

            //Caso 1 : var è composta da sole lettere
            if (valToArray.every(c => 97 <= c.charCodeAt(0) && c.charCodeAt(0) <= 122))
                return true;

            //Caso 2 : var ha come unico simbolo un punto all'inizio
            if (valToArray.filter(c => c.charCodeAt(0) < 97 || c.charCodeAt(0) > 122).length == 1 && val.startsWith("."))
                return true;

            //Caso 3 : Sono presenti simboli non ammessi
            this.$root.toast(this.$t(EXTENSION_NOT_VALID), 3000, "warning");
            return false;
        },
        onTagAdd(tag, val, values) {
            val = val.toLowerCase();
            if (val.startsWith(".")) return;
            values.pop();
            val = "." + val;
            $(".tag .title").last().html(val);
            values.push(val);
            let self = this;
            self.source.options.specifiedExtensions = values.join(";");
        },

        cutText(text, range) {
            return cutText(text, range);
        },

        getMetroIcon(type, path) {
            return getMetroIcon(type, path);
        },
        disableNextButton() {
            switch (this.step) {
                case this.STEP_SA_AND_SOURCE:
                    return !this.testResult.ok || this.source.networkSource.paths.length == 0;
                case this.STEP_COMPRESSION:
                    return true;
                default:
                    false;
            }
        }
    }
}
</script>