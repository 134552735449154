//const qs = require('querystring');
import { ButtonLoader } from "../components/utils/ButtonLoader.vue"

export default {
    name: "Login",
    data() {
        return {
            userlogin: {
                username: null,
                password: null,
                rememberme: false
            },
        };
    },
    props: {
        msg: String,
        code: String
    },
    beforeRouteLeave(to, from, next) {
       
        if (this.$root.checkAuth()) {
            next()
        } else {
            next(false)
        }
    },
    beforeDestroy: async function() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        //alert("destroy COMPUTER.JS");
        //this.$root.newPage("login", "destroy");
    },
    beforeCreate() {
        console.log(this.$route);
    },
    created: function() {
        //alert("CREATED COMPUTERS.JS");
        //this.$root.newPage("login", "created");
        this.$store.commit('SET_LAYOUT', 'simple-layout');
    },
    mounted: async function() {

        /*         if (isValid(this.$route.query.code)) {
                    cookieManager.setCookie("authCode", this.$route.query.code);
                    close();
                }

                //Caso 2 : Durante la procedura di creazione di un service account di tipo 13 (Onedrive),
                //è stata effettuata la richiesta per convalidare i permessi dell'applicazione appena registrata sulla piattaforma di Azure
                if (isValid(this.$route.query.admin_consent) && this.$route.query.admin_consent == "True") {
                    cookieManager.setCookie("admin_consent", this.$route.query.admin_consent == "True");
                    close();
                } */

        //this.$root.newPage("login", "mounted");
        this.$session.set("ROOMPC", null);

        //Se nella richiesta dell'auth code:
        //response_mode == fragment -> this.$route.hash è una stringa che contiene 'code='
        //response_mode == query -> this.$route.query.code è l'authCode


        /* if (!isValid(this.$route.query)) return;

        //Caso 1 : Durante la procedura di creazione di un service account di tipo 10 (Google Drive) o di tipo 13 (Onedrive),
        //è stata effettuata la richiesta per l'authorization code.
        if (isValid(this.$route.query.code)) {
            cookieManager.setCookie("authCode", this.$route.query.code);
            close();
        }

        //Caso 2 : Durante la procedura di creazione di un service account di tipo 13 (Onedrive),
        //è stata effettuata la richiesta per convalidare i permessi dell'applicazione appena registrata sulla piattaforma di Azure
        if (isValid(this.$route.query.admin_consent) && this.$route.query.admin_consent == "True") {
            cookieManager.setCookie("admin_consent", this.$route.query.admin_consent == "True");
            close();
        } */

        if (this.$session.exists() && this.$session.get("AUTH") != null) {
            this.$store.commit('SET_LAYOUT', 'nav-layout')
            this.$router.replace("/dashboard");
        }



    },
    components: {
        ButtonLoader
    },
    methods: {

        async login() {
            let self = this;

            var authObj = await this.$root.api("LOGIN", self.userlogin.username, self.userlogin.password);

            if (authObj == null)
                return;

            if (authObj.message == "Require2FA") {
                self.$router.replace("/login2FA");
            } else {

                //authObj.localPublicIP = await this.$root.api("GETLOCALPUBLICIP");
                if (!this.$session.exists()) {
                    this.$session.start();
                }
                if (self.userlogin.rememberme) {
                    localStorage.setItem("AUTH", JSON.stringify(authObj));
                } else {
                    self.$session.set("AUTH", authObj);
                }

                self.$session.set("licenza", { id: authObj.id_master_account, assignee: authObj.nome_licenza });
                self.$store.commit('SET_LAYOUT', 'nav-layout')
                self.$router.replace('/dashboard');

            }



        }
    }
};