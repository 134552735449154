export const PROCESS_MESSAGES = {
    PROCESS_ABORTED: "Process \"{0}\" aborted by user.",
    PROCESS_NOT_ABORTED: "Error in aborting the process.",
    PROCESS_STARTED: "Process \"{0}\" started.",
    PROCESS_NOT_STARTED: "Process \"{0}\" not started.",
}

/*************************************BACKUP**************************************************************************/
export const BACKUP_MESSAGES = {

    CREATED: "Backup \"{0}\" created successfully.",
    NOT_CREATED: "An error occurred while creating the backup \"{0}\".",
    SAVED: "Backup \"{0}\" saved successfully.",
    NOT_SAVED: "An error occurred while saving the backup \"{0}\".",
    SAVE_LATER: "\"{0}\" is offline. The backup will be saved when you are online again.",
    MODIFIED: "Backup \"{0}\" modified successfully.",
    NOT_MODIFIED: "An error occurred while editing the backup \"{0}\".",
    DELETED: "Backup \"{0}\" deleted successfully.",
    NOT_DELETED: "An error occurred while deleting the backup \"{0}\".",
    LIST_NOT_RETRIEVED: "An error occurred while retrieving the backups.",
    NOT_RETRIEVED: "An error occurred while retrieving the backup \"{0}\".",

    LOGS_NOT_RETRIEVED: "An error occurred while retrieving logs of backup \"{0}\".",

    COMPLETED: "Job {0} completed successfully.",
    NOT_COMPLETED: "Job {0} completed with errors.",
    WARNINGS: "Job {0} completed with warnings.",
    ERROR: "There were errors in running the selected job: {0}",


    ABORTED: "Job \"{0}\" aborted by user.",
    NOT_ABORTED: "Error in aborting the job \"{0}\".",
    ABORTING: "Aborting job \"{0}\"",

    NOT_FOUND: "Job \"{0}\" not found.",

    RENAMED: "Job \"{0}\" renamed successfully in \"{1}\"",
    NOT_RENAMED: "Error in renaming the job \"{0}\" in \"{1}\".",

    RUNNING: "Running job \"{0}\"",
    NOT_RUNNING: "There were errors in running the job \"{0}\"",

    CHOOSE_SOURCE: "Configure a source!",
    CHOOSE_DESTINATION: "Configure a destination!",

    LOG_DETAIL_NOT_RETRIEVED: "An error occurred while retrieving the log details \"{0}\""
}

/*************************************SERVICE ACCOUNTS**************************************************************************/

export const SERVICEACCOUNT_MESSAGES = {
    CREATED: "Service account \"{0}\" created successfully.",
    NOT_CREATED: "An error occurred while creating the service account \"{0}\".",
    MODIFIED: "Service account \"{0}\" modified successfully.",
    NOT_MODIFIED: "An error occurred while editing the service account \"{0}\".",
    DELETED: "Service account \"{0}\" deleted successfully.",
    NOT_DELETED: "An error occurred while deleting the service account \"{0}\".",
    LIST_NOT_RETRIEVED: "An error occurred while retrieving the service accounts.",
    NOT_RETRIEVED: "An error occurred while retrieving the service account.",
    TEST_FAILED: "An error occurred while testing the service account \"{0}\".",
    TEST_OK: "Service account \"{0}\" tested successfully",
    SAVE_LATER: "\"{0}\" is offline. The service account will be saved when you are online again.",
}


export const SESSIONS_MESSAGES = {
    CREATED: "Repository \"{0}\" created successfully.",
    NOT_CREATED: "An error occurred while creating the repository \"{0}\".",
    MODIFIED: "Repository \"{0}\" modified successfully.",
    NOT_MODIFIED: "An error occurred while editing the repository \"{0}\".",
    DELETED: "Repository \"{0}\" deleted successfully.",
    NOT_DELETED: "An error occurred while deleting the repository \"{0}\".",
    LIST_NOT_RETRIEVED: "An error occurred while retrieving the sessions.",
    NOT_RETRIEVED: "An error occurred while retrieving the repository.",
    SAVE_LATER: "\"{0}\" is offline. The repository will be saved when you are online again.",

}

/*************************************REPOSITORY**************************************************************************/

export const REPOSITORY_MESSAGES = {
    CREATED: "Repository \"{0}\" created successfully.",
    NOT_CREATED: "An error occurred while creating the repository \"{0}\".",
    MODIFIED: "Repository \"{0}\" modified successfully.",
    NOT_MODIFIED: "An error occurred while editing the repository \"{0}\".",
    DELETED: "Repository \"{0}\" deleted successfully.",
    NOT_DELETED: "An error occurred while deleting the repository \"{0}\".",
    LIST_NOT_RETRIEVED: "An error occurred while retrieving the repositories.",
    NOT_RETRIEVED: "An error occurred while retrieving the repository.",
    SAVE_LATER: "\"{0}\" is offline. The repository will be saved when you are online again.",

}


/*************************************PC**************************************************************************/
export const PC_MESSAGES = {
    LIST_NOT_RETRIEVED: "An error occurred while retrieving the computers.",
    NOT_RETRIEVED: "An error occurred while retrieving the computer.",
    CONNECTED: "Computer \"{0}\" connected.",
    NOT_CONNECTED: "Computer \"{0}\" is not connected.",
    NO_RESPONSE: "No response from server.",
    ERROR: "Generic Error, please retry",
    SAVED: "Computer modified successfully",
    PASSWORD_ERROR: "Password Custom non saved.",
    PASSWORD_OK: "Password Custom saved successfully.",
    PASSWORD_REMOVED: "Password Custom removed successfully.",
    LICENSE_REMOVED: "Device removed from license successfully.",
    LICENSE_ADDED: "Device linked to license successfully. Reconnect manually using howto.",
}

/*************************************TFA**************************************************************************/
export const TFA_MESSAGES = {
    DISABLED: "TFA disabled successfully",
    NOT_DISABLED: "An error occurred while disabling TFA.",
    NOT_ENABLED: "An error occurred while enabling the TFA."
}


/*************************************USER**************************************************************************/

export const USER_MESSAGES = {
    CREATED: "User created successfully.",
    NOT_CREATED: "An error occurred while creating the user.",
    MODIFIED: "User modified successfully.",
    NOT_MODIFIED: "An error occurred while editing the user.",
    DELETED: "User deleted successfully.",
    NOT_DELETED: "An error occurred while deleting the user.",
    LIST_NOT_RETRIEVED: "An error occurred while retrieving the users.",
    NOT_RETRIEVED: "An error occurred while retrieving the user.",
    CONNECTED: "You are online",
    NOT_CONNECTED: "You are offline."
}

/*************************************GROUP**************************************************************************/

export const GROUP_MESSAGES = {
    CREATED: "Group created successfully.",
    NOT_CREATED: "An error occurred while creating the Group.",
    MODIFIED: "Group modified successfully.",
    NOT_MODIFIED: "An error occurred while editing the Group.",
    DELETED: "Group deleted successfully.",
    NOT_DELETED: "An error occurred while deleting the Group.",
    LIST_NOT_RETRIEVED: "An error occurred while retrieving the Group.",
    NOT_RETRIEVED: "An error occurred while retrieving the Group.",
    CONNECTED: "You are online",
    NOT_CONNECTED: "You are offline."
}

/*************************************LOGIN**************************************************************************/

export const LOGIN_FAILED = "An error occurred while logging in.";
export const LOGOUT_FAILED = "An error occurred while logging out.";

export const TOKENS_NOT_RETRIEVED = "An error occurred while retrieving the tokens.";
export const STEP_ACQUIRE_TOKENS = "I acquire the new tokens.";

/*************************************LIST**************************************************************************/

export const REGIONS_NOT_RETRIEVED = "An error occurred while retrieving the regions.";
export const BUCKETS_NOT_RETRIEVED = "An error occurred while retrieving the buckets.";
export const GROUPS_NOT_RETRIEVED = "An error occurred while retrieving groups.";
export const CONTROLS_NOT_RETRIEVED = "An error occurred while retrieving computers connected.";
export const ROLES_NOT_RETRIEVED = "An error occurred while retrieving roles.";
export const PRICING_NOT_RETRIEVED = "An error occurred while retrieving pricing.";
export const LICENSES_NOT_RETRIEVED = "An error occurred while retrieving licenses.";
export const EMAILPROVIDERS_NOT_RETRIEVED = "An error occurred while retrieving email providers.";
export const EVENTLOGS_NOT_RETRIEVED = "An error occurred while retrieving eventlogs.";


/************************************PROCESS***********************************/

/*************************************MISCELLANEOUS**************************************************************************/

export const ERROR = "An error occurred."
export const STATS_NOT_RETRIEVED = "An error occurred while retrieving statistics.";
export const NO_UPPERCASE_CHARACTERS = "No uppercase characters in password.";
export const FILESYSTEM_NOT_RETRIEVED = "An error occurred while retrieving filesystem.";
export const EXTENSION_NOT_VALID = "Extension not valid."

export const FOLDER_CREATED = "Folder {0} created";
export const FOLDER_NOT_CREATED = "An error occurred while creating the folder {0} ";

export const ITEM_DELETED = "Item deleted";
export const ITEM_NOT_DELETED = "An error occurred while deleting the selected item";
export const ITEMINFO_NOT_RETRIEVED = "An error occurred while retrieving the selected item info";
export const ITEMS_NOT_RETRIEVED = "An error occurred while retrieving items";
export const ITEM_RENAMED = "Item renamed";
export const ITEM_NOT_RENAMED = "An error occurred while renaming the selected item";

//[TG 18/06/2024 - messaggio per criptazione stringhe su api]
export const COCOD_ERROR_CRYPT = "An error occurred while retrieving the agent build page URL (control code not valid).";
export const STRING_ERROR_CRYPT = "An error occurred while retrieving the agent build page URL (param non valid).";
export const LIC_EXT_INFO_ERR = "An error occurred while retrieving the info about current license.";

export const GET_REMOTE_STATS_ERR = "An error occurred while retrieving the info about current remote access ststistics.";
export const GET_BACKUP_STATS_ERR = "An error occurred while retrieving the info about current backup statistics.";

export const DATABASE_MESSAGES = {
    LIST_NOT_RETRIEVED: "An error occurred while retrieving databases",
    FOUND_NEW: "New database found : \"{0}\"",
    NOT_FOUND: "The database \"{0}\" was not found"
}

export const DISK_MESSAGES = {
    LIST_NOT_RETRIEVED: "An error occurred while retrieving disks",
    FOUND_NEW: "New disk found : \"{0}\"",
    NOT_FOUND: "The disk \"{0}\" was not found",
    NOT_FOUND_PARTITION_: "The partition \"{0}\" of disk \"{1}\" was not found",
    FOUND_NEW_PARTITION: "New partition \"{0}\" found on disk \"{1}\"",
    NOT_RETRIEVED: "An error occurred while retrieving disk"
}

export const VIRTUAL_MACHINES_MESSAGES = {
    LIST_NOT_RETRIEVED: "An error occurred while retrieving virtual machines",
    FOUND_NEW: "New virtual machine found : \"{0}\"",
    NOT_FOUND: "The virtual machine \"{0}\" was not found",
    NOT_FOUND_VIRTUAL_DISK: "The virtual disk \"{0}\" of virtual machine \"{1}\" was not found",
    FOUND_NEW_VIRTUAL_DISK: "New virtual disk \"{0}\" found on virtual machine \"{1}\"",
    STATE_CHANGED: "State of virtual machine \"{0}\" changed",
    STATE_NOT_CHANGED: "An error occurred while changing state of virtual machine \"{0}\""
}

export const DATACENTER_MESSAGES = {
    LIST_NOT_RETRIEVED: "An error occurred while retrieving datacenters",
    FOUND_NEW: "New datacenter found : \"{0}\"",
    NOT_FOUND: "The datacenter \"{0}\" was not found",
    NOT_FOUND_COMPUTER: "The computer \"{0}\" of datacenter \"{1}\" was not found",
    FOUND_NEW_COMPUTER: "New computer \"{0}\" found on datacenter \"{1}\"",
    NOT_FOUND_DATASTORE: "The datastore \"{0}\" of computer \"{1}\" was not found",
    FOUND_NEW_DATASTORE: "New datastore \"{0}\" found on computer \"{1}\"",
    NOT_FOUND_VIRTUAL_MACHINE: "The virtual machine \"{0}\" of datastore \"{1}\" was not found",
    FOUND_NEW_VIRTUAL_MACHINE: "New virtual machine \"{0}\" found on datastore \"{1}\""
}

export const MAILBOXES_MESSAGES = {
    LIST_NOT_RETRIEVED: "An error occurred while retrieving mailboxes",
    FOUND_NEW: "New mailbox found : \"{0}\"",
    NOT_FOUND: "The mailbox \"{0}\" was not found",
    EMAIL_SENT: "Test mail sent successfully."
}

export const RESTORE_MESSAGES = {
    STARTED: "Restore process starded successfully",
    NOT_STARTED: "Restore process not starded"
}

export const NETWORK_MESSAGES = {
    CONNECTED: "Network connection established",
    CONNECTION_FAILED: "An error occurred during network authentication.",
    DISCONNECT: "The disconnection was performed successfully.",
    NOT_DISCONNECT: "An error occurred while disconnecting to the network.",
    LIST_NOT_RETRIEVED: "An error occurred while retrieving filesystem."
}

export const FTP_MESSAGES = {
    CONNECTED: "FTP connection established",
    CONNECTION_FAILED: "An error occurred during ftp authentication.",
    DISCONNECT: "The disconnection was performed successfully.",
    NOT_DISCONNECT: "An error occurred while disconnecting to the server.",
    LIST_NOT_RETRIEVED: "An error occurred while retrieving ftp filesystem."
}

export const RESTOREFILE_MESSAGES = {
    NOT_BACKUP_FOLDER: "The selected folder does not correspond to a backup folder.",
    INFO_NOT_RETRIEVED: "An error occurred while retrieving infos about selected backup",
    RUNNING: "Running restore",
    NOT_RUNNING: "An error occurred while starting the selected restore"
}