<template>
    <div class="dialog overflow center text-small overflow w-100-sm w-100-md w-75-lg  w-50-xl" v-cloak>

        <DialogTitle :title="$t('Configure a restore')" :defaultTitle="$t('Configure a restore')"
            :subTitle="restore.destination.type_name" @closeDialog="$root.$emit('CLOSERESTOREDIALOG')" />

        <!---------------------SERVICE ACCOUNT DELLA SORGENTE------------------------------------------------->
        <div v-if="step == STEP_SELECTBACKUPCOPY" class="dialog-content">
            <div v-if="isManual" class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t("Choose source type") }}</div>
            </div>

            <div v-if="isManual" class="form-group">
                <ul data-role="listview" data-view="icons" data-select-node="true" data-selectable="false">
                    <!--Le destinazioni del backup diventano le sorgenti del restore-->
                    <li v-for="sourceType in platformsTypes[restore.type].backupDestinationTypes"
                        @click="setSourceType(sourceType)" :data-icon="platformsTypes[sourceType].icon"
                        :data-caption="platformsTypes[sourceType].name" :key="sourceType" />
                </ul>
            </div>

            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <SARepoSelect v-if="![-1, $root.PlatformsTypesEnum.FileFolder].includes(restore.source.type)"
                :type=restore.source.type :idSA='restore.source.id_service_account' :idRepo=null
                @sendSATestResult="sendSATestResult" />

            <!-------------------SCELTA PERCORSO CARTELLA COPIA BACKUP----------------------------------------------->
            <SelectedResourcesLayout v-if="isManual" :list="sourceData.selectedNodes" :singleItem="true"
                :canCancel="false" :buttonText="$t('Select the folder of a backup copy')"
                :isDisabled="restore.source.type != $root.PlatformsTypesEnum.FileFolder && sourceData.client == null"
                :heightPercentage="30" @clickFunction="toggleFileSystemSidebar('CHOOSEBACKUPCOPY')" />

        </div>

        <!--INFORMAZIONI INIZIALI SUL RESTORE-->
        <div v-if="step == STEP_SELECTRESTOREOPTIONS" class="dialog-content">

            <!-----------------------------RESTORETYPE: COSA SI VUOLE RECUPERARE?--------------------------------->
            <div class="form-group">
                <h6 class="text-bold">{{ $t("What is to be recovered?") }}:</h6>
                <select class="select w-100" v-model="restore.restoreType">
                    <option :value="RESTORETYPE_SELECTSINGLEITEMS">{{ $t("Single elements") }}</option>
                    <option :value="RESTORETYPE_BUILDFULLCOPY">{{ $t("Recreate full copy from backup") }}</option>
                    <option :value="RESTORETYPE_BUILDSPECIFICCOPY">{{ $t("Recreate a specific copy from backup") }}
                    </option>
                </select>
            </div>

            <!-------------------RESTORE DA UN BACKUP - SCELTA DELLE RISORSE----------------------------->
            <SelectedResourcesLayout v-if="restore.restoreType == RESTORETYPE_SELECTSINGLEITEMS"
                :list="restore.source.paths" :singleItem="false" :canCancel="true"
                :buttonText="$t('Select files and folders')" :isDisabled="iterationFolder == null"
                :heightPercentage="30" @clickFunction="toggleFileSystemSidebar('SELECTSINGLEITEMS')" />

            <!-------------------RESTORE DA UN BACKUP - SCELTA DELLA DATA DALLA QUALE ESEGUIRE IL RESTORE----------------------------->
            <div v-else-if="restore.restoreType > RESTORETYPE_SELECTSINGLEITEMS" class="form-group">

                <h6 class="text-bold">{{ $t("Select a copy") }}</h6>
                <div class="overflow">
                    <div v-for="iteration in fileConfig.iterations" :key="iteration.index" class="d-flex mb-4">
                        <input name="iter" type='radio' data-role='radio' class="mr-5"
                            @click="onIterationChanged(iteration)">
                        <div class="pr-4">
                            <div class="d-flex flex-justify-between w-100 pr-3">
                                <div>
                                    <span class='badge inline bg-cobalt fg-white p-1'> {{
            platformsTypes[iteration.sourceType].name }} </span>
                                    <span class="mif-arrow-right mx-1" />
                                    <span class='badge inline bg-cobalt fg-white p-1'> {{
            platformsTypes[iteration.destinationType].name }} </span>
                                </div>

                                <div class="text-bold"><label class="mr-2">{{ $t("Copy number") }}:</label>{{
            (iteration.index) + " / " + (iteration.nCopies) }}</div>
                            </div>
                            <div>
                                <small><b>{{ $t("Backup folder") }} : </b> {{ iteration.iterationFolder }}</small>
                            </div>
                            <small class="text-bold"><label class="mr-2">{{ $t("Start date") }}:</label>{{
            iteration.lastStart | utcAsLocal | moment("MM/DD/YY hh:mm A") }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--------------------------------------------FILTRI----------------------------------------------------->
        <div v-show="step == STEP_FILTERS" class="dialog-content">
            <div class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t('Filters') }}</div>
            </div>

            <!--FILTRI - ESTENSIONI-->
            <div class="form-group row pb-5 d-flex flex-align-center">
                <input type="checkbox" data-role="switch" data-material="false"
                    v-model="restore.source.options.extensionsFilterAction" :true-value="1" :false-value="0" />
                <label v-if="restore.source.options.extensionsFilterAction == 0"> {{ $t('Exclude specified extensions')
                    }}
                </label>
                <label v-else>{{ $t('Include only specified extensions') }}</label>
            </div>
            <div class="form-group d-flex">
                <input id="taginput" type="text" data-role="tag-input" data-tag-separator=";" class="flex-self-center">
            </div>

            <!--FILTRI - DIMENSIONE FILE-->
            <div class="form-group row">
                <div>
                    <input type="checkbox" v-model="restore.source.options.useFileSizeFilter"
                        :data-caption="$t('File size filter')" data-role="checkbox" />
                </div>
                <div class="w-100 d-flex flex-align-center">
                    <select v-model="restore.source.options.fileSizeFilterAction"
                        :disabled="!restore.source.options.useFileSizeFilter" class="mr-2 h-100">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterAction" :value="index"
                            v-bind:key="index">{{ $t(value) }}</option>
                    </select>

                    <select v-model="restore.source.options.fileSizeFilterCompare"
                        :disabled="!restore.source.options.useFileSizeFilter" class="mr-2 h-100">
                        <option v-for="(value, index) in $root.selectValues.fileSizeFilterCompare" :value="index"
                            v-bind:key="index">{{
            $t(value)
        }}</option>
                    </select>

                    <input type="number" v-model="restore.source.options.fileSize"
                        :disabled="!restore.source.options.useFileSizeFilter" class="mr-2" style="width: 50px;" />
                    <span> MB</span>
                </div>
            </div>
        </div>

        <!---------------------------------------------ESCLUSIONI--------------------------------------------------------------->
        <div v-if="step == STEP_EXCLUSIONS" class="dialog-content">
            <div class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t('Exclusions') }}</div>
            </div>

            <div class="form-group text-bold">{{ $t("Click on an item to select subfolders or files to exclude") }}
            </div>
            <!--VISUALIZZAZIONE DEGLI ELEMENTI SELEZIONATI-->
            <div class="form-group overflow h-vh-20 bd-black border border-radius-10 border-size-1 mx-7">
                <table class=" d-table-lg striped table text-small">
                    <tbody>
                        <tr v-for="res in restore.source.paths" @click="openFileSystemSidebarFrom(res)"
                            v-bind:key="res.id">
                            <td class="d-flex">
                                <span :class="getMetroIcon(res.type, res.path)" class="px-2" />
                                <span class="flex-self-center">{{ res.path }}</span>
                            </td>
                            <td><span class='mif-bin' @click="removeNode(res, true)" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="form-group text-bold mt-6">{{ $t("Paths to exclude selected:") }}</div>

            <NoDataAvailable v-if="restore.source.options.excludedPaths.length == 0"
                :message="$t('No excluded path selected')" />
            <!--VISUALIZZAZIONE DEGLI ELEMENTI-->
            <div v-else class="form-group overflow mt-4 h-vh-20 bd-black border border-radius-10 border-size-1">
                <table class="d-table-lg striped table text-small">
                    <tbody>
                        <tr v-for="res in restore.source.options.excludedPaths" :title="res.path" v-bind:key="res.id">
                            <td class="d-flex">
                                <span class="px-2" :class="getMetroIcon(res.type, res.path)" />
                                <span lass="flex-self-center">{{ cutText(res.path, 50) }}</span>
                            </td>
                            <td><span class='mif-bin' @click="removeNode(res, false)" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="form-group text-small flex-left">
                <input type="checkbox" v-model="restore.source.options.excludeSubFolders" />
                <label> {{ $t("Do not include any subfolders (only files in the root folder will be copied)") }}</label>
            </div>
        </div>

        <!----------------------------------------COMPRESSIONE ZIP----------------------------------------------------------->
        <div v-show="step == STEP_ZIPCOMPRESSION" class="dialog-content overflow">
            <div class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t('Zip compression') }}</div>
            </div>
            <div class="form-group row pb-5">
                <input type="checkbox" v-model="restore.source.enableCompression" data-role="checkbox"
                    :data-caption="$t('Enable zip compression')" />
            </div>
            <div class="form-group row pb-5">
                <input type="checkbox" :disabled="!restore.source.enableCompression"
                    v-model="restore.source.options.useArchivePassword" data-role="checkbox"
                    :data-caption="$t('Protect .zip files using password')" />
            </div>
            <div class="form-group row pb-5 w-100">
                <label>{{ $t("Password") }}:</label>
                <input data-role="input" class="metro-input" type="password"
                    v-model="restore.source.options.archivePassword" :placeholder="$t('Enter Password')"
                    data-prepend="<span class='mif-lock'></span>"
                    :disabled="!restore.source.enableCompression || !restore.source.options.useArchivePassword" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>
            
            <div class="form-group row pb-5 w-100">
                <select v-model="restore.source.options.protectionType"
                    :disabled="!restore.source.enableCompression || !restore.source.options.useArchivePassword"
                    class="w-100">
                    <option v-for="(value, index) in $root.selectValues.protectionTypes" :value="index"
                        v-bind:key="index">{{
            value }}
                    </option>
                </select>
            </div>
            <div class="form-group row pb-5">
                <input type="checkbox" v-model="restore.source.options.useArchiveFileCustomName" data-role="checkbox"
                    :disabled="!restore.source.enableCompression" />
                <label>{{ $t("Give the zip file a custom name") }}</label>
            </div>
            <div class="form-group row pb-5">
                <input type="text" v-model="restore.source.options.archiveFileCustomName"
                    :disabled="!restore.source.enableCompression || !restore.source.options.useArchiveFileCustomName"
                    required />
            </div>
            <div class="form-group row pb-5">
                {{ $t("Paths in archive") }}
            </div>
            <div class="form-group row pb-5">
                <select v-model="restore.source.options.pathsInArchive" :disabled="!restore.source.enableCompression">
                    <option v-for="(value, index) in $root.selectValues.pathsInArchive" :value="index"
                        v-bind:key="index">{{
            $t(value) }}
                    </option>
                </select>
            </div>
        </div>

        <!--DESTINAZIONE MICROSOFT TEAMS : service account + lista-->
        <div v-if="step == STEP_DESTINATION && restore.type == $root.PlatformsTypesEnum.Microsoft_Teams"
            class="dialog-content">
            <div class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t('Configure destination') }}</div>
            </div>

            <!------------------------------DESTINAZIONE : SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare sendSATestResult-->
            <SARepoSelect v-if="restore.type != $root.PlatformsTypesEnum.FileFolder" :type="$root.getMicrosoftTypes()""
                :idSA=restore.destination.id_service_account :idRepo=null @sendSATestResult="sendSATestResult" />

            <!------------------------------DESTINAZIONE : LISTA--------------------------------------------------------------->
            <SelectedResourcesLayout :list="destinationData.selectedNodes" :singleItem="false" :canCancel="true"
                :buttonText="destinationData.buttonText" :isDisabled="destinationData.client == null"
                :heightPercentage="30" @clickFunction="toggleFileSystemSidebar('SELECTDESTINATIONPATH')" />
        </div>

        <!--DESTINAZIONE ONEDRIVE FOR BUSINESS/SHAREPOINT: service account + restore in original position + custom folder + list-->
        <div v-if="step == STEP_DESTINATION && [$root.PlatformsTypesEnum.Microsoft_OneDriveForBusiness, $root.PlatformsTypesEnum.Microsoft_SharePoint].includes(restore.type)"
            class="dialog-content">
            <div class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t('Configure destination') }}</div>
            </div>

            <!------------------------------DESTINAZIONE : SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare sendSATestResult-->
            <SARepoSelect :type="$root.getMicrosoftTypes()" :idSA=restore.destination.id_service_account :idRepo=null
                @sendSATestResult="sendSATestResult" />

            <div v-if="restore.type != $root.PlatformsTypesEnum.Microsoft_Teams" class="form-group d-flex flex-column">
                <input type="radio" :data-caption="$t('Restores elements to their original position')"
                    @click="restore.source.options.restoreInOriginalPosition = true" data-role="radio" name="r1"
                    :disabled="destinationData.client == null" checked>
                <input type="radio" :data-caption="$t('Restores items to the following folder')"
                    @click="restore.source.options.restoreInOriginalPosition = false" data-role="radio" name="r1"
                    :disabled="destinationData.client == null">
            </div>

            <div v-if="restore.type != $root.PlatformsTypesEnum.Microsoft_Teams" class="form-group row pb-5 w-100">
                <label>{{ $t("Custom folder") }}:</label>
                <input data-role="input" class="metro-input" type="text"
                    v-model="restore.source.options.restoreCustomFolder"
                    :placeholder="$t('Enter the name of the customised folder')"
                    :disabled="restore.source.options.restoreInOriginalPosition" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!------------------------------DESTINAZIONE : LISTA--------------------------------------------------------------->
            <SelectedResourcesLayout :list="destinationData.selectedNodes" :singleItem="false" :canCancel="true"
                :buttonText="destinationData.buttonText" :isDisabled="destinationData.client == null"
                :heightPercentage="30" @clickFunction="toggleFileSystemSidebar('SELECTITEMSTORESTORE')" />
        </div>

        <div v-if="step == STEP_DESTINATION && [$root.PlatformsTypesEnum.FileFolder, $root.PlatformsTypesEnum.Network].includes(restore.type)"
            class="dialog-content">
            <div class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t('Configure destination') }}</div>
            </div>

            <!------------------------------DESTINAZIONE : SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare sendSATestResult-->
            <SARepoSelect v-if="restore.type == $root.PlatformsTypesEnum.Network" :type=restore.type
                :idSA=restore.destination.id_service_account :idRepo=null @sendSATestResult="sendSATestResult" />

            <div class="form-group d-flex flex-column">
                <input type="radio" :data-caption="$t('Restores elements to their original position')"
                    @click="restore.source.options.restoreInOriginalPosition = true" data-role="radio" name="r1"
                    :disabled="restore.type == $root.PlatformsTypesEnum.Network && destinationData.client == null"
                    checked>
                <input type="radio" :data-caption="$t('Restores items to the following folder')"
                    @click="restore.source.options.restoreInOriginalPosition = false" data-role="radio" name="r1"
                    :disabled="restore.type == $root.PlatformsTypesEnum.Network && destinationData.client == null">
            </div>

            <SelectedResourcesLayout :list="destinationData.selectedNodes" :singleItem="true" :canCancel="true"
                :buttonText="destinationData.buttonText"
                :isDisabled="restore.source.options.restoreInOriginalPosition || (restore.type == $root.PlatformsTypesEnum.Network && destinationData.client == null)"
                :heightPercentage="30" @clickFunction="toggleFileSystemSidebar('SELECTDESTINATIONPATH')" />
        </div>


        <!-------------------------------TEST ACCOUNT----------------------------------------->
        <div class="dialog-actions flex-justify-end">
            <div v-if="waiting == -1">
                <span class="mif-spinner2 fg-primary ani-spin ml-5" />
            </div>
            <input v-if="step != 1 || restore.source.type != $root.PlatformsTypesEnum.FileFolder" :disabled="step == STEP_SELECTBACKUPCOPY" type="button" v-on:click="setStep(step - 1)" :value="$t('Prev')" class="button primary ml-2" />
            <input type="button" @click="setStep(step + 1)" :value="$t('Next')" class="button primary ml-2" :disabled="!enableNextButton" />
            <input type="button" @click="startRestore" :value="$t('Start')" class="button primary ml-2" :disabled="step != STEP_DESTINATION || (!restore.source.options.restoreInOriginalPosition && destinationData.selectedNodes.length == 0)" />
        </div>
    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle.vue";
import { isValid, cutText, /*getParentOf,*/ getSeparator, getMetroIcon, /*platformsTypes,*/ FileFolderEntityType, platformsTypes, getNodeType, getParentOf } from "../../../../public/assets/js/utilitiesmodule";
import NoDataAvailable from "../../utils/NoDataAvailable.vue";
import SARepoSelect from "../../utils/SARepoSelect.vue";
import SelectedResourcesLayout from "../../utils/SelectedResourcesLayout.vue";
import { EXTENSION_NOT_VALID } from "../../../../public/assets/js/messages";
//import { DefaultObjectBuilder } from "../../../models/DefaulfObjectBuilder";
import { ClientTreeNode, ClientTreeNodeMethods, FileSystemSidebarConfig } from "../../../models/TreeNode";
import $ from 'jquery';
import { AzureClient } from "../../../router/azureClient";

export default {
    name: "RestoreFile",
    props: {
        restore: Object,
    },
    components: {
        "DialogTitle": DialogTitle,
        "NoDataAvailable": NoDataAvailable,
        SelectedResourcesLayout,
        SARepoSelect
    },
    data() {
        return {
            step: 0,
            STEP_SELECTBACKUPCOPY: 0,
            STEP_SELECTRESTOREOPTIONS: 1,
            STEP_FILTERS: 2,
            STEP_EXCLUSIONS: 3,
            STEP_ZIPCOMPRESSION: 4,
            STEP_DESTINATION: 5,



            RESTORETYPE_SELECTSINGLEITEMS: 0,
            RESTORETYPE_BUILDFULLCOPY: 1,
            RESTORETYPE_BUILDSPECIFICCOPY: 2,
            sourceData: {
                serviceAccount: null,
                client: null,
                filteredServiceAccounts: [],
                selectedNodes: [], //appoggio per la copia di backup scelta
            },

            destinationData: {
                serviceAccount: null,
                client: null,
                filteredServiceAccounts: [],
                filteredRepositories: [],
                selectedNodes: [],
                buttonText: null
            },

            waiting: 0,

            fileConfig: null,

            isManual: true,

            str1: "Choose account from credential locker or use Custom credentials",
            platformsTypes: null,
            microsoftTree: null

        }
    },
    computed: {
        iterationFolder: function () {
            if (this.sourceData.selectedNodes.length == 1)
                return this.sourceData.selectedNodes[0];
            return null;
        },
        enableNextButton: function () {

            switch (this.step) {
                case this.STEP_SELECTBACKUPCOPY:
                    //Nel primo step se il restore è manuale sono obbligato a:
                    // 1 - Selezionare il tipo della sorgente
                    // 2 - Se il tipo della sorgente necessita di un service account, selezionare un serviceaccount e testarlo
                    // 3 - selezionare una cartella di una copia
                    if (
                        this.isManual && 
                        this.restore.source.type != -1 && 
                       (this.restore.source.type == this.$root.PlatformsTypesEnum.FileFolder || this.sourceData.client != null) &&
                        this.iterationFolder != null
                    ) {
                        return true;
                    }

                    //Nel primo step, se il restore è automatico sono obbligato a selezionare un service account e a testarlo se necessario
                    if (
                        !this.isManual &&
                        (this.restore.source.type == this.$root.PlatformsTypesEnum.FileFolder || this.sourceData.client != null)
                    ) {
                        return true;
                    }
                    return false;

                case this.STEP_SELECTRESTOREOPTIONS:

                    return this.restore.restoreType != -1 &&
                        (
                            (this.restore.restoreType == this.RESTORETYPE_SELECTSINGLEITEMS && this.restore.source.paths.length>0) ||
                            (this.restore.restoreType > this.RESTORETYPE_SELECTSINGLEITEMS && this.restore.index != -1)

                        );
                    
                case this.STEP_DESTINATION:
                    return false;
            }

            return true;
        },
    },
    created: async function () {
        //Se sto creando un restore manuale, idBackup è vuoto
        this.isManual = !isValid(this.restore.idBackup);

        //Se è un restore automatico da una sorgente filefolder, non mi serve nulla del primo step perchè non ho bisogno del serviceaccount
        this.step = !this.isManual && this.restore.source.type == this.$root.PlatformsTypesEnum.FileFolder
        ? this.STEP_SELECTRESTOREOPTIONS
        : this.STEP_SELECTBACKUPCOPY;

        this.platformsTypes = platformsTypes;

        if (!this.isManual) {
            await this.buildFileConfigFromIDBackup();
        }


        if (this.restore.type == this.$root.PlatformsTypesEnum.Microsoft_OneDriveForBusiness)
            this.destinationData.buttonText = this.$t('Select the accounts to be restore');
        else if (this.restore.type == this.$root.PlatformsTypesEnum.Microsoft_Teams) {
            this.destinationData.buttonText = this.$t('Select the teams and channels to be restore');
            restore.source.options.restoreInOriginalPosition = true
        } else if (this.restore.type == this.$root.PlatformsTypesEnum.Microsoft_SharePoint)
            this.destinationData.buttonText = this.$t('Select the sites to be restore');
        else
            this.destinationData.buttonText = this.$t('Select a path');

    },

    beforeDestroy() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        var method;
        //In caso di ftp o network, eseguo la disconnessione
        if (this.sourceData.client != null && [this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.restore.source.type)) {
            method = this.restore.source.type == this.$root.PlatformsTypesEnum.FTP ? "disconnectftp" : "disconnectnetwork";
            this.$root.socket(method, this.sourceData.client.serviceAccount.accountSessionId);
        }
    },
    methods: {
        /**********STEP 1 : SORGENTE - SCELTA DEL SERVICE ACCOUNT + TEST SERVICE ACCOUNT************************************************************** */
        setSourceType(type) {
            this.restore.source.type = type;
            this.restore.source.type_name = this.platformsTypes[type].name;
            this.restore.source.id_service_account = -2;
            this.sourceData.client = null;
            this.sourceData.serviceAccount = null;
        },
        async sendSATestResult(testResult) {
            let self = this;

            if (self.step == this.STEP_SELECTBACKUPCOPY) {
                self.restore.source.id_service_account = testResult.serviceAccount.id;
                self.sourceData.serviceAccount = testResult.serviceAccount;
                self.sourceData.client = testResult.client;
                //Finito il test, resetto accountSessionId se il restore è di tipo ftp o network
                //if ([2, $root.PlatformsTypesEnum.Network].includes(self.restore.source.type))
                //    self.sourceData.client.serviceAccount.options.accountSessionId = "";
                return;
            }

            if (self.step == this.STEP_DESTINATION) {
                self.restore.destination.id_service_account = testResult.serviceAccount.id;
                self.destinationData.serviceAccount = testResult.serviceAccount;
                self.destinationData.client = testResult.client;
                //Finito il test, resetto accountSessionId se la destinazone del restore è di tipo ftp o network
                //if ([2, $root.PlatformsTypesEnum.Network].includes(self.restore.type))
                //    self.destinationData.client.serviceAccount.options.accountSessionId = "";
                //self.toggleFileSystemSidebar();
                return;
            }
        },

        async buildFileConfigFromIDBackup() {
            //Step 1 : Prendo i logs del job
            var logs = await this.$root.api("GETBACKUPLOGS", this.restore.idBackup);
            //Step 2 : Ordino i log dal più recente al meno recente
            logs.sort((a, b) => {
                var dateA = this.$moment(a.dt_start_utc.substr(0, 8) + "T" + a.dt_start_utc.substr(8), "YYYYMMDD HH:mm:ss");
                var dateB = this.$moment(b.dt_start_utc.substr(0, 8) + "T" + b.dt_start_utc.substr(8), "YYYYMMDD HH:mm:ss");
                if (dateA > dateB)
                    return -1;
                return 1;
            });
            //Step 3 : Costruisco il file di config prendendo i [nCopies] logs più recenti

            var nCopies = 1; //All'inizio non conosco il numero di copie, quidni lo imposto ad 1 per far partire il for 
            var currentConfiguration;
            var currentIterationFolder;
            var slash;
            for (var c = 0; c < nCopies; c++) {
                currentConfiguration = await this.$root.api("GETBACKUPLOGCONFIGURATION", logs[c].id);
                //Al primo log,
                if (c == 0) {
                    //inizio a costruire il fileConfig
                    this.fileConfig = {
                        id: this.restore.idBackup,
                        currentIndex: logs[0].backup_number,
                        nCopies: parseInt(currentConfiguration.sources[0].options.nCopies.toString()),
                        iterations: []
                    };
                    slash = getSeparator(currentConfiguration.destinations[0].path);
                    //imposto il numero di copie
                    nCopies = ["F", "U"].includes(currentConfiguration.sources[0].options.backupType)
                        ? this.fileConfig.nCopies
                        : this.fileConfig.nCopies + 1;
                    //popolo dei dati del restore che prima non conoscevo
                    this.restore.source.paths.push(new ClientTreeNode(null, null, currentConfiguration.destinations[0].path + slash + this.restore.backupName, getNodeType(currentConfiguration.destinations[0].type, false, false)));

                    this.sourceData.selectedNodes.push(this.restore.source.paths[0]);
                }

                if (logs[c].backup_number == 0)
                    currentIterationFolder = currentConfiguration.destinations[0].path + slash + this.restore.backupName + slash + "FULL_" + this.restore.idBackup;
                else
                    currentIterationFolder = currentConfiguration.destinations[0].path + slash + this.restore.backupName + slash + logs[c].backup_number.toString().padStart(3, "0") + "_" + this.restore.idBackup;

                this.fileConfig.iterations.push({
                    index: logs[c].backup_number,
                    name: this.restore.backupName,
                    result: logs[c].result == 1,
                    lastStart: this.$moment(logs[c].dt_start_utc.substr(0, 8) + "T" + logs[c].dt_start_utc.substr(8), "YYYYMMDD HH:mm:ss"),
                    iterationFolder: currentIterationFolder,
                    nCopies: parseInt(currentConfiguration.sources[0].options.nCopies.toString()),
                    sourceType: currentConfiguration.sources[0].type,
                    destinationType: currentConfiguration.destinations[0].type
                });
            }
        },

        /**********STEP 2 : SCELTA DI COSA SI VUOLE RECUPERARE + SCELTA PERCORSI************************************************************** */
        async toggleFileSystemSidebar(n) {

            switch (n) {
                case 'CHOOSEBACKUPCOPY':
                    this.$root.$emit("closeFileSystemSidebar");
                    this.$root.$emit(
                        "toggleFileSystemSidebar",
                        this.sourceData.selectedNodes,
                        await this._getConfig('CHOOSEBACKUPCOPY')
                    );
                    break;
                case 'SELECTSINGLEITEMS':
                    this.$root.$emit("closeFileSystemSidebar");
                    this.$root.$emit(
                        "toggleFileSystemSidebar",
                        this.restore.source.paths,
                        await this._getConfig(n)
                    );
                    break;
                case 'SELECTITEMSTORESTORE':
                case 'SELECTDESTINATIONPATH':
                    this.$root.$emit("closeFileSystemSidebar");
                    this.$root.$emit(
                        "toggleFileSystemSidebar",
                        this.destinationData.selectedNodes,
                        await this._getConfig(n)
                    );
            }

        },

        cutText(text, range) {
            return cutText(text, range);
        },

        /**
         * {
         *  id:,
         * currentIndex
         * iterations : // array di : 
         *     {
         *         index:
         *         name:
         *         result:
         *         lastStart
         *         iterationFolder
         *         nCopies
         *         sourceType
         *         destinationType
         *     }
         * }
         * 
         */
        async buildFileConfigFromPath() {
            this.$root.$emit("closeFileSystemSidebar");
            this.waiting = -1;
            var id_service_account = "";
            if (isValid(this.sourceData.serviceAccount)) id_service_account = this.sourceData.serviceAccount.id;
            try {
                this.fileConfig = await this.$root.socket("getconfigfileforrestorefile", id_service_account, this.iterationFolder.path);
                if (isValid(this.fileConfig)) {
                    if ([this.$root.PlatformsTypesEnum.Microsoft_OneDriveForBusiness, this.$root.PlatformsTypesEnum.Microsoft_SharePoint, this.$root.PlatformsTypesEnum.Microsoft_Teams].includes(this.restore.type)) {
                        this.microsoftTree = await this.$root.socket("getTreeFromConfigFile", this.iterationFolder.path, this.restore.type);

                    }
                    this.restore.idBackup = this.fileConfig.id;
                    this.restore.backupName = this.fileConfig.iterations[this.fileConfig.currentIndex].name; //assegno il nome del job al momento dell'ultima iterazione eseguita
                    this.waiting = 1;
                    return;
                }
            } catch (ex) {
                console.log(ex);
            }
            this.sourceData.selectedNodes.pop();
            this.waiting = 0;
        },

        

        onIterationChanged(iteration) {
            this.restore.index = iteration.index;
            //Se si vuole restorare solamente una copia, aggiungo come unico path della sorgente, il path dell'iterazione selezionata
            if (this.restore.restoreType == this.RESTORETYPE_BUILDSPECIFICCOPY) {
                var iterationFolderType = [this.$root.PlatformsTypesEnum.FileFolder, this.$root.PlatformsTypesEnum.Network].includes(this.restore.source.type)
                    ? FileFolderEntityType.FN_Folder
                    : this.$root.isCloud(this.restore.source.type)
                        ? FileFolderEntityType.Cloud_Folder
                        : FileFolderEntityType.FTP_Folder;
                this.restore.source.paths = [{ path: iteration.iterationFolder.slice(0, -1), type: iterationFolderType }];
            }

        },

        /********************STEP 4 : SCELTA DEI FILTRI************************************************/
        //---------------------------------TAG INPUT------------------------------------------------------------------
        onBeforeTagAdd(tag, val) {

            console.log("check tag " + val);

            var valToArray = val.toLowerCase().split("");

            //Caso 1 : var è composta da sole lettere
            if (valToArray.every(c => 97 <= c.charCodeAt(0) && c.charCodeAt(0) <= 122))
                return true;

            //Caso 2 : var ha come unico simbolo un punto all'inizio
            if (valToArray.filter(c => c.charCodeAt(0) < 97 || c.charCodeAt(0) > 122).length == 1 && val.startsWith("."))
                return true;

            //Caso 3 : Sono presenti simboli non ammessi
            this.$root.toast(this.$t(EXTENSION_NOT_VALID), 3000, "warning");
            return false;
        },
        onTagAdd(tag, val, values) {
            val = val.toLowerCase();
            if (val.startsWith(".")) return;
            values.pop();
            val = "." + val;
            $(".tag .title").last().html(val);
            values.push(val);
            let self = this;
            self.source.options.specifiedExtensions = values.join(";");
        },

        /************************STEP 5 : SCELTA DELLE ESCLUSIONI********************************************************/
        async openFileSystemSidebarFrom(res) {
            //res = new ClientTreeNode(res.nodeID, res.resID, res.path, res.type);
            if (new ClientTreeNodeMethods().isFile(res)) return;

            var config = await this._getConfig(3);
            config.rootPath = res.path;

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.restore.source.options.excludedPaths,
                config
            );

        },


        /*****************************STEP 7 : START*********************************************************/
        async startRestore() {
            this.$root.$emit("closeFileSystemSidebar");
            let self = this;
            //I paths sono nodi ma devo salvare solamente la struttura {path:'', type:''}
            /*self.restore.source.paths = self.restore.source.paths.map(n => {
                return {
                    path: n.path,
                    type: n.type
                }
            });*/

            if (self.restore.source.options.excludedPaths.length == 0)
                self.restore.source.options.excludedPaths = "";
            else //paths?type|paths?type| ....
                self.restore.source.options.excludedPaths = self.source.options.excludedPaths.map(r => r.toString()).join("|");

            //userID1|userID2|userID3
            //siteID1|siteID2|siteID3
            //teamID1?channelID1|teamID1?channelID2|teamID3 (il punto interrogativo è creato al momento del list dei channel)
            if ([this.$root.PlatformsTypesEnum.Microsoft_SharePoint, this.$root.PlatformsTypesEnum.Microsoft_Teams, this.$root.PlatformsTypesEnum.Microsoft_OneDriveForBusiness].includes(this.restore.type))
                self.restore.destination.path = this.destinationData.selectedNodes.map(r => r.resID).join("|");
            else if (this.restore.source.options.restoreInOriginalPosition)
                self.restore.destination.path = "";
            else self.restore.destination.path = this.destinationData.selectedNodes[0].path;

            //Se sto effettuando il restore di Microsoft Teams, ho bisogno del token preso tramite interazione dell'utente, per acquisire dei permessi speciali 
            //(creazione teams/channel eliminati)
            if (this.restore.type == this.$root.PlatformsTypesEnum.Microsoft_Teams) {
                var azureClient = new AzureClient(this.destinationData.serviceAccount);
                var loginWithDeviceCodeResult = await azureClient._getTokensFromAzure();
                if (!loginWithDeviceCodeResult)
                    return;
                this.restore.source.options.deviceCodeToken = azureClient.serviceAccount.token;
            }

            var restoreStarted = await self.$root.socket("restorefile", this.restore);

            if (!restoreStarted)
                return;

            this.$root.$emit('CLOSERESTOREDIALOG');

        },

        /**********************************GENERALE*********************************************** */
        /**
         * 0 : informazioni iniziali : service account + restoretype
         * 1 : selezione del percorso / percorsi + scelta data
         * 2 : filtri
         * 3 : esclusioni
         * 4 : compressione zip
         * 5 : destinazione
         */
        setStep(nStep) {
            this.$root.$emit("closeFileSystemSidebar");

            switch (nStep) {
                case this.STEP_SELECTRESTOREOPTIONS:

                    // RESTORE MANUALE + NO FILE CONFIG
                    if (this.isManual && !isValid(this.fileConfig)) {
                        this.buildFileConfigFromPath();
                        return; // lo step verrà impostato solo al momento in cui fileConfig sarà valido
                    }/**/

                    // RESTORE MANUALE + FILE CONFIG + RESTORE SINGLE FILES
                    if (this.isManual && isValid(this.fileConfig) && this.restore.restoreType == this.RESTORETYPE_SELECTSINGLEITEMS) {
                        this.step = this.STEP_SELECTRESTOREOPTIONS; // lo imposto prima, perchè nel seguente metodo verrà considetato lo step corrente
                        this.toggleFileSystemSidebar('SELECTSINGLEITEMS')
                        return;
                    }

                    // RESTORE MANUALE + FILE CONFIG + BUILD ITERATION
                    if (this.isManual && isValid(this.fileConfig) && this.restore.restoreType != this.RESTORETYPE_SELECTSINGLEITEMS) {
                        this.step = this.STEP_SELECTRESTOREOPTIONS; // lo imposto prima, perchè nel seguente metodo verrà considetato lo step corrente
                        return;
                    }

                    break;
                case this.STEP_FILTERS:
                    var self = this;

                    if (this.restore.restoreType != this.RESTORETYPE_SELECTSINGLEITEMS)
                        this.restore.source.paths = [this.iterationFolder];
                    setTimeout(function () {
                        var taginput = window.Metro.get$el("#taginput").data("taginput").options;
                        taginput.onBeforeTagAdd = (val, values) => self.onBeforeTagAdd('', val, values);
                        taginput.onTagAdd = (tag, val, values) => self.onTagAdd(tag, val, values)

                    }, 1000);
                    break;
            }

            this.step = nStep;

        },



        async _getConfig(n) {
            var method;

            switch (n) {
                case 'CHOOSEBACKUPCOPY':
                    //Si deve scegliere la cartella di backup
                    //if (this.restore.source.paths.length == 0 || [1, 2].includes(this.restore.restoreType)) {
                    if ([this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.restore.source.type) && this.sourceData.client.serviceAccount.options.accountSessionId == "") {
                        method = this.restore.source.type == 2 ? "connectftp" : "connectnetwork";
                        this.sourceData.client.serviceAccount.options.accountSessionId = await this.$root.socket(method, this.sourceData.client.serviceAccount);
                    }
                    return {
                        type: this.restore.source.type,
                        includeFiles: false,
                        multipleChoice: false,
                        client: this.sourceData.client,
                        rootPath: [this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.restore.source.type) ? this.sourceData.client.serviceAccount.options.path : ""
                    };

                //}
                case 'SELECTSINGLEITEMS':

                    //Si vogliono restorare file e cartelle
                    if ([this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.restore.source.type) && this.sourceData.client.serviceAccount.options.accountSessionId == "") {
                        method = this.restore.source.type == 2 ? "connectftp" : "connectnetwork";
                        this.sourceData.client.serviceAccount.options.accountSessionId = await this.$root.socket(method, this.sourceData.client.serviceAccount);
                    }
                    return {
                        type: this.restore.source.type,
                        includeFiles: true, // mostra i file se si vuole restorare degli elementi singoli
                        multipleChoice: true, // si possono selezionare più elementi solo se si vuole restorare degli elementi singoli
                        client: this.sourceData.client,
                        rootPath: this.restore.source.paths[0].path,//getParentOf(this.fileConfig.iterations[this.fileConfig.currentIndex].iterationFolder) // passo la cartella di backup
                    };


                case 3:
                    if ([this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.restore.source.type) && this.sourceData.client.serviceAccount.options.accountSessionId == "") {
                        method = this.restore.source.type == 2 ? "connectftp" : "connectnetwork";
                        this.sourceData.client.serviceAccount.options.accountSessionId = await this.$root.socket(method, this.sourceData.client.serviceAccount);
                    }
                    return {
                        type: this.restore.source.type,
                        includeFiles: true,
                        multipleChoice: true,
                        client: this.sourceData.client,
                    };

                case 'SELECTDESTINATIONPATH':
                case 'SELECTITEMSTORESTORE':
                    if ([this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.restore.type) && this.destinationData.client.serviceAccount.options.accountSessionId == "") {
                        method = this.restore.type == 2 ? "connectftp" : "connectnetwork";
                        this.destinationData.client.serviceAccount.options.accountSessionId = await this.$root.socket(method, this.destinationData.client.serviceAccount);
                    }
                    return new FileSystemSidebarConfig(
                        this.restore.type,
                        false,
                        this.$root.isMicrosoft365(this.restore.type),//elementi multipli
                        this.destinationData.client,
                        [this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.restore.type) ? this.destinationData.client.serviceAccount.options.path : "",
                        [this.$root.PlatformsTypesEnum.Microsoft_SharePoint, this.$root.PlatformsTypesEnum.Microsoft_OneDriveForBusiness].includes(this.restore.type),
                        this.microsoftTree
                    );
            }

            return null;
        },
        getMetroIcon(type, path) {
            return getMetroIcon(type, path);
        },
        removeNode(node, inSource) {
            var index;
            if (inSource) {
                index = this.restore.source.paths.findIndex(res => res.path == node.path);
                this.restore.source.paths.splice(index, 1);
            } else {
                index = this.restore.source.options.excludedPaths.findIndex(res => res.path == node.path);
                this.restore.source.options.excludedPaths.splice(index, 1);
            }
            this.$root.$emit("uncheckNode", node);
        },

        onSelectRestoreType(a, b, c) {
            console.log(a);
            console.log(b);
            console.log(c);
        }


    }
}
</script>