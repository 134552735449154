var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.job != null
    ? _c(
        "div",
        {
          staticClass:
            "dialog overflow w-100-sm w-100-md w-75-lg overflow w-50-xl",
          attrs: { id: "backupview" },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.$root.$emit("CLOSEBACKUPVIEW")
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-title" }, [
            _vm._v(" " + _vm._s(_vm.job.name) + " "),
            _c("span", {
              staticClass: "button square closer",
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("CLOSEBACKUPVIEW")
                },
              },
            }),
            _c("div", { staticClass: "cell" }, [
              _c("small", { staticClass: "badge inline" }, [
                _vm._v(_vm._s(_vm.$t(_vm.buildSubTitle()))),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "dialog-content w-100 overflow" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "cell-12 d-flex flex-justify-end" }, [
                _c(
                  "div",
                  {
                    staticClass: "button rounded",
                    attrs: { "data-role": "buttongroup " },
                  },
                  [
                    _vm.job.isRunning == "NR"
                      ? _c(
                          "button",
                          {
                            staticClass: "rounded button",
                            attrs: { title: _vm.$t("Run") },
                            on: { click: _vm.runJob },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  fill: "none",
                                  viewBox: "0 0 24 24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M5 5.274c0-1.707 1.826-2.792 3.325-1.977l12.362 6.726c1.566.853 1.566 3.101 0 3.953L8.325 20.702C6.826 21.518 5 20.432 5 18.726V5.274Z",
                                    fill: "#212121",
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.job.isRunning == "R"
                      ? _c(
                          "button",
                          {
                            staticClass: "rounded button",
                            attrs: { title: _vm.$t("Pause") },
                            on: { click: _vm.pauseJob },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  fill: "none",
                                  viewBox: "0 0 24 24",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M4.75 3A1.75 1.75 0 0 0 3 4.75v14.5c0 .966.784 1.75 1.75 1.75h14.5A1.75 1.75 0 0 0 21 19.25V4.75A1.75 1.75 0 0 0 19.25 3H4.75Z",
                                    fill: "#212121",
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "rounded button",
                        attrs: {
                          title: _vm.$t("Edit"),
                          disabled: _vm.job.isRunning != "NR",
                        },
                        on: { click: _vm.editJob },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "24",
                              height: "24",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M3 5.25A2.25 2.25 0 0 1 5.25 3h13.5A2.25 2.25 0 0 1 21 5.25v5.762a3.279 3.279 0 0 0-2.607.95L15.355 15H11.25a.75.75 0 1 0 0 1.5h2.605l-1.365 1.365a3.685 3.685 0 0 0-.97 1.712L11.166 21H5.25A2.25 2.25 0 0 1 3 18.75V5.25Zm4.75 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm3.5-1.75a.75.75 0 0 0 0 1.5h5.5a.75.75 0 0 0 0-1.5h-5.5Zm0 3.75a.75.75 0 1 0 0 1.5h5.5a.75.75 0 1 0 0-1.5h-5.5Zm-4.5.75a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1 4.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm11.35-4.08-5.903 5.902a2.686 2.686 0 0 0-.706 1.247l-.458 1.831a1.087 1.087 0 0 0 1.319 1.318l1.83-.457a2.685 2.685 0 0 0 1.248-.707l5.902-5.902A2.286 2.286 0 0 0 19.1 12.67Z",
                                fill: "#212121",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "rounded button",
                        attrs: {
                          title: _vm.$t("Duplicate"),
                          disabled: _vm.job.isRunning != "NR",
                        },
                        on: { click: _vm.duplicateJob },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "24",
                              height: "24",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "m4.5 6.75.003-2.123A2.25 2.25 0 0 0 3 6.75v10.504a4.75 4.75 0 0 0 4.75 4.75h5.064a6.515 6.515 0 0 1-1.08-1.5H7.75a3.25 3.25 0 0 1-3.25-3.25V6.75Z",
                                fill: "#212121",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M19 11.174a6.5 6.5 0 0 0-7.687 8.326H7.75a2.25 2.25 0 0 1-2.25-2.25v-13A2.25 2.25 0 0 1 7.75 2h9A2.25 2.25 0 0 1 19 4.25v6.924Z",
                                fill: "#212121",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M17.5 12a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm.501 8.503V18h2.496a.5.5 0 0 0 0-1H18v-2.5a.5.5 0 1 0-1 0V17h-2.504a.5.5 0 0 0 0 1H17v2.503a.5.5 0 1 0 1 0Z",
                                fill: "#212121",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "rounded button",
                        attrs: {
                          title: _vm.$t("Restore"),
                          disabled: _vm.job.isRunning != "NR",
                        },
                        on: { click: _vm.restoreJob },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "24",
                              height: "24",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M7.207 2.543a1 1 0 0 1 0 1.414L5.414 5.75h7.836a8 8 0 1 1-8 8 1 1 0 1 1 2 0 6 6 0 1 0 6-6H5.414l1.793 1.793a1 1 0 0 1-1.414 1.414l-3.5-3.5a1 1 0 0 1 0-1.414l3.5-3.5a1 1 0 0 1 1.414 0Z",
                                fill: "#212121",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "rounded button",
                        attrs: { title: _vm.$t("Show logs") },
                        on: {
                          click: function ($event) {
                            return _vm.setStep(4)
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "24",
                              height: "24",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M12 8V2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V10h-6a2 2 0 0 1-2-2Zm-5 4.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm0 3a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm0 3a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm3-6a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Zm0 3a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Zm0 3a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75ZM13.5 8V2.5l6 6H14a.5.5 0 0 1-.5-.5Z",
                                fill: "#212121",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "rounded button bg-red fg-white",
                        attrs: {
                          title: _vm.$t("Delete"),
                          disabled: _vm.job.isRunning != "NR",
                        },
                        on: { click: _vm.deleteJob },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "24",
                              height: "24",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M21.5 6a1 1 0 0 1-.883.993L20.5 7h-.845l-1.231 12.52A2.75 2.75 0 0 1 15.687 22H8.313a2.75 2.75 0 0 1-2.737-2.48L4.345 7H3.5a1 1 0 0 1 0-2h5a3.5 3.5 0 1 1 7 0h5a1 1 0 0 1 1 1Zm-7.25 3.25a.75.75 0 0 0-.743.648L13.5 10v7l.007.102a.75.75 0 0 0 1.486 0L15 17v-7l-.007-.102a.75.75 0 0 0-.743-.648Zm-4.5 0a.75.75 0 0 0-.743.648L9 10v7l.007.102a.75.75 0 0 0 1.486 0L10.5 17v-7l-.007-.102a.75.75 0 0 0-.743-.648ZM12 3.5A1.5 1.5 0 0 0 10.5 5h3A1.5 1.5 0 0 0 12 3.5Z",
                                fill: "#ffffff",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm.step == 0
              ? _c("div", { staticClass: "p-10 cell" }, [
                  _c("table", [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("Computer name")) + ":"),
                        ]),
                        _c("td", { staticClass: "p-3 text-bold" }, [
                          _vm._v(_vm._s(_vm.pcName)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.$t("Created at")) + ":")]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.job.inserted_at == null
                                ? ""
                                : _vm.convertDate(_vm.job.inserted_at)
                            )
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.$t("Updated at")))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.job.updated_at == null
                                ? ""
                                : _vm.convertDate(_vm.job.updated_at)
                            )
                          ),
                        ]),
                      ]),
                      _vm.isValid(_vm.job.last_result) &&
                      _vm.job.last_result != 90 &&
                      _vm.job.last_result != _vm.$root.ResultValue.NeverExecuted
                        ? _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.$t("Started at")))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.job.dt_start_utc == null
                                    ? ""
                                    : _vm.convertDate(_vm.job.dt_start_utc)
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.isValid(_vm.job.last_result) &&
                      _vm.job.last_result != 90 &&
                      _vm.job.last_result != _vm.$root.ResultValue.NeverExecuted
                        ? _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.$t("Ended at")))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.job.dt_end_utc == null
                                    ? ""
                                    : _vm.convertDate(_vm.job.dt_end_utc)
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.$t("Is running")))]),
                        _vm.job.isRunning == "NA"
                          ? _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Information not available"))
                              ),
                            ])
                          : _vm.job.isRunning == "R"
                          ? _c("td", [_vm._v(" " + _vm._s(_vm.$t("Yes")))])
                          : _c("td", { staticClass: "text-bold" }, [
                              _vm._v(" " + _vm._s(_vm.$t("No"))),
                            ]),
                      ]),
                      _vm.job.isRunning == "R"
                        ? _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("Current operation"))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.job.current_operation)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.job.isRunning == "R"
                        ? _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.$t("Progress")))]),
                            _c("td", [_vm._v(_vm._s(_vm.job.progress) + " %")]),
                          ])
                        : _vm._e(),
                      _vm.job.isRunning == "R"
                        ? _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("Processed data"))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.job.processed_data) + " "),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.job.last_result != 99
                        ? _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.$t("Copied files")))]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.job.last_backup_copiedfiles)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.job.last_result != 99
                        ? _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.$t("Backup size")))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.prettyBytes(_vm.job.last_backup_size)
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.isValid(_vm.job.last_result) &&
                      _vm.job.last_result != 90 &&
                      _vm.job.last_result != _vm.$root.ResultValue.NeverExecuted
                        ? _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.$t("Last result")))]),
                            _c(
                              "td",
                              [
                                _c("BackupLastResult", {
                                  attrs: { lastresult: _vm.job.last_result },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.$t("From")))]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.job.sources[0].type_name)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.$t("To")) + " ")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.job.destinations[0].type_name)),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.step == 1
              ? _c(
                  "div",
                  {
                    staticClass: "pl-10 cell",
                    attrs: { id: "sourcesdestinations" },
                  },
                  [
                    _c(
                      "table",
                      {
                        staticClass: "mb-6",
                        staticStyle: { height: "fit-content" },
                      },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-upper text-bold",
                                attrs: { colspan: "3" },
                              },
                              [_vm._v(_vm._s(_vm.$t("Source")))]
                            ),
                          ]),
                          _c("hr"),
                          _vm.source.id_service_account.toString() != "-2"
                            ? _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("Service account"))),
                                ]),
                                _vm.$session
                                  .get("SERVICEACCOUNTS")
                                  .some(
                                    (sa) =>
                                      sa.id == _vm.source.id_service_account
                                  )
                                  ? _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$session
                                              .get("SERVICEACCOUNTS")
                                              .find(
                                                (sa) =>
                                                  sa.id ==
                                                  _vm.source.id_service_account
                                              ).name
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("td", { staticClass: "fg-red" }, [
                                      _c("span", {
                                        staticClass: "mif-warning pr-2",
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("Not found"))),
                                      ]),
                                    ]),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$t("Elements Selected"))),
                            ]),
                            _c(
                              "td",
                              [
                                _c("SourceView", {
                                  attrs: { source: _vm.source },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("br"),
                          _c("tr", [
                            _c("td", { staticClass: "text-upper text-bold" }, [
                              _vm._v(_vm._s(_vm.$t("Destination"))),
                            ]),
                          ]),
                          _c("hr"),
                          _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.$t("Name")))]),
                            _c("td", [_vm._v(_vm._s(_vm.destination.name))]),
                          ]),
                          _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.$t("Type")))]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.destination.type_name)),
                            ]),
                          ]),
                          _vm.destination.id_service_account.toString() != "-2"
                            ? _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("Service account"))),
                                ]),
                                _vm.$session
                                  .get("SERVICEACCOUNTS")
                                  .some(
                                    (sa) =>
                                      sa.id ==
                                      _vm.destination.id_service_account
                                  )
                                  ? _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$session
                                              .get("SERVICEACCOUNTS")
                                              .find(
                                                (sa) =>
                                                  sa.id ==
                                                  _vm.destination
                                                    .id_service_account
                                              ).name
                                          )
                                      ),
                                    ])
                                  : _c("td", { staticClass: "fg-red" }, [
                                      _c("span", {
                                        staticClass: "mif-warning pr-2",
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("Not found"))),
                                      ]),
                                    ]),
                              ])
                            : _vm._e(),
                          _vm.destination.id_repository.toString() != "-2"
                            ? _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("Repository"))),
                                ]),
                                _vm.$session
                                  .get("REPOSITORIES")
                                  .some(
                                    (r) => r.id == _vm.destination.id_repository
                                  )
                                  ? _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$session
                                              .get("REPOSITORIES")
                                              .find(
                                                (r) =>
                                                  r.id ==
                                                  _vm.destination.id_repository
                                              ).name
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("td", { staticClass: "fg-red" }, [
                                      _c("span", {
                                        staticClass: "mif-warning pr-2",
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("Not found"))),
                                      ]),
                                    ]),
                              ])
                            : _vm._e(),
                          _vm.destination.path != null &&
                          _vm.destination.path != ""
                            ? _c("tr", [
                                _c("td", [_vm._v(_vm._s(_vm.$t("Path")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.destination.path)),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.step == 2
              ? _c(
                  "div",
                  { staticClass: "pl-10 cell" },
                  _vm._l(_vm.details, function (d) {
                    return _c(
                      "div",
                      { key: d.id, staticClass: "pb-3" },
                      [
                        _c("div", { staticClass: "text-bold" }, [
                          _vm._v(" " + _vm._s(d.section) + " "),
                        ]),
                        _vm._l(d.keys, function (k, index) {
                          return _c("div", { key: index }, [
                            _vm._v(
                              _vm._s(k.toUpperCase()) +
                                " : " +
                                _vm._s(d.values[index]) +
                                " "
                            ),
                          ])
                        }),
                      ],
                      2
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.step == 3
              ? _c(
                  "div",
                  { staticClass: "p-10 cell" },
                  [
                    _vm.job.scheduling.length == 0 ||
                    _vm.job.scheduling[0] == ""
                      ? _c("NoDataAvailable", {
                          attrs: { message: _vm.$t("No scheduling available") },
                        })
                      : _c(
                          "ul",
                          { staticClass: "items-list" },
                          _vm._l(_vm.job.scheduling, function (sched, index) {
                            return _c("li", { key: index }, [
                              _c("span", { staticClass: "mif-alarm label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.cronstrue.toString(sched, {
                                        locale: "en",
                                        dayOfWeekStartIndexZero: false,
                                      })
                                    )
                                ),
                              ]),
                            ])
                          }),
                          0
                        ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.step == 4
              ? _c(
                  "div",
                  { staticClass: "p-5 cell", staticStyle: { height: "500px" } },
                  [
                    _vm.logs == null
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "h-100 d-flex flex-justify-center flex-align-center",
                          },
                          [
                            _c("span", {
                              staticClass: "mif-spinner2 fg-primary ani-spin",
                            }),
                          ]
                        )
                      : _vm.logs.length == 0
                      ? _c("NoDataAvailable", {
                          staticClass: "w-100",
                          attrs: { message: _vm.$t("No logs available") },
                        })
                      : _c(
                          "table",
                          {
                            staticClass: "table text-center text-small cell-12",
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("td", { staticClass: "text-right w-25" }, [
                                  _vm._v(_vm._s(_vm.$t("Start / End"))),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(_vm._s(_vm.$t("Duration"))),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(_vm._s(_vm.$t("Size"))),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(_vm._s(_vm.$t("Num. Files"))),
                                ]),
                                _c("td", { staticClass: "text-right" }),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.logs, function (log, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.convertDate(log.dt_start_utc)
                                      ) + " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.convertDate(log.dt_end_utc))
                                    ),
                                  ]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.diffDate(
                                          log.dt_start_utc,
                                          log.dt_end_utc
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(_vm.prettyBytes(log.backup_size))
                                    ),
                                  ]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(_vm._s(log.copied_files)),
                                  ]),
                                  _c("td", [
                                    log.partial_log != null &&
                                    log.partial_log == "wait"
                                      ? _c("span", {
                                          staticClass:
                                            "mif-spinner2 fg-primary ani-spin",
                                        })
                                      : _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getPartialLog(index)
                                              },
                                            },
                                          },
                                          [
                                            _vm.focusLog
                                              ? _c("span", {
                                                  attrs: {
                                                    id: "logPopover",
                                                    "data-role": "popover",
                                                    "data-popover-text":
                                                      _vm.focusLog,
                                                    "data-popover-position":
                                                      "left",
                                                    "data-popover-hide": "0",
                                                  },
                                                })
                                              : _vm._e(),
                                            _c("span", {
                                              staticClass: "mif-eye",
                                            }),
                                          ]
                                        ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "dialog-actions" }, [
            _c(
              "button",
              {
                staticClass: "button primary",
                attrs: { disabled: _vm.step == 0 },
                on: {
                  click: function ($event) {
                    return _vm.setStep(_vm.step - 1)
                  },
                },
              },
              [
                _c("span", { staticClass: "mif-arrow-left pr-2" }),
                _vm._v(_vm._s(_vm.$t("Prev")) + " "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "button primary",
                attrs: { disabled: _vm.step == 4 },
                on: {
                  click: function ($event) {
                    return _vm.setStep(_vm.step + 1)
                  },
                },
              },
              [
                _vm._v(_vm._s(_vm.$t("Next"))),
                _c("span", { staticClass: "mif-arrow-right pl-2" }),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }