<template>
    <div class="d-flex flex-align-center flex-column flex-justify-center py-4"> 
        <span class="mif-info mif-6x fg-dark p-3" />
        <h5 class="text-center">{{message}}</h5>
    </div>   
</template>
<script>
export default {
    name : "NoDataAvailable",
    props : {
        message : {
            type : String,
            required : true
        }
    }
}
</script>