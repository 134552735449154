import { FileFolderEntityType, platformsTypes } from "./utilitiesmodule.js";
const services = [
    { type: 110, id: "64382b16-3fce-4af2-9ab5-a07d60b4905c" }, // modificato
    { type: 111, id: "aa7f1489-be60-4be5-8563-f8342b7010df" }, // modificato
    { type: 112, id: "40bffb15-f303-4a12-9348-39aea1b3097d" }, // modificato
    { type: 140, id: "bdaae368-1ce5-4edf-bb1b-fc0ab69b3c88" },
    { type: 141, id: "cf5d5f58-a51f-47fa-9b78-372ea806680f" },
    { type: 12, id: "02f4a35f-e7e8-45f1-becb-a9a5f83dd3a4" },
    { type: 10, id: "ad159ecd-7276-44c5-adf6-0da8b7861136" },
    { type: 13, id: "2ca8e0b7-73af-4c84-885c-f03682e6977b" },
    { type: 81, id: "0d09930f-eec8-4ba5-bfa6-b653979135f4" }, // modificato
    { type: 2, id: "7e64c815-f1ad-4a36-a76f-5813c891454d" }
];

function getbackup() {
    return {
        id: null,
        id_computer: "b69449bd-2c55-42af-8e96-46533a7749a9",
        name: "Provadatest",
        sources: [],
        destinations: [],
        scheduling: [],
        options: {},
    }
}

function ff_source() {
    return {
        id_service_account: -2,
        type: 8,
        type_name: "FileFolder",
        //icon: "",
        //name: "",
        enableCompression: true,
        folderSource: {
            paths: [{
                path: "C:\\Users\\Veronica\\Desktop\\FileFolderBackupSource",
                type: 1,
            }],
        },
        options: sourceOptions
    }
}

var ff_destination = {
    id: null,
    name: 'FileFolderBackupDestination',
    type: 8,
    type_name: "FileFolder",
    id_service_account: -2,
    id_repository: -2,
    path: "E:\\Test Iperius One \\FileFolderBackupDestination",
    options: {},
}

function net_source() {
    return {
        id_service_account: services.find(sa => sa.type == 81).id,
        type: 81,
        type_name: "Network",
        //icon: "",
        //name: "",
        enableCompression: true,
        networkSource: {
            paths: [{
                path: "\\\\PC-VERONICA\\Test PC-Veronica\\NetworkBackupSource",
                type: 1,
            }],
        },

        options: sourceOptions,
    }
}

function sharepoint_source() {
    return {
        id_service_account: services.find(sa => sa.type == 13).id,
        type: 53,
        type_name: "Microsoft_SharePoint",
        //icon: "",
        //name: "",
        enableCompression: true,
        folderSource: {
            paths: [{
                path: "",
                type: FileFolderEntityType.SP_Root,
            }],
        },

        options: sourceOptions,
    }
}

function teams_source() {
    return {
        id_service_account: services.find(sa => sa.type == 13).id,
        type: 54,
        type_name: "Microsoft_Teams",
        //icon: "",
        //name: "",
        enableCompression: true,
        folderSource: {
            paths: [{
                path: "",
                type: FileFolderEntityType.MT_Root,
            }],
        },

        options: sourceOptions,
    }
}

function onedrive_source() {
    return {
        id_service_account: services.find(sa => sa.type == 13).id,
        type: 55,
        type_name: "Microsoft_OneDrive",
        //icon: "",
        //name: "",
        enableCompression: true,
        folderSource: {
            paths: [{
                path: "",
                type: FileFolderEntityType.Cloud_Root,
            }],
        },

        options: sourceOptions,
    }
}

function exchange365_source() {
    return {
        "id": null,
        id_service_account: services.find(sa => sa.type == 13).id,
        "type": 52,
        "type_name": "Exchange OnLINE",
        "enableCompression": false,
        "options": {
            "archiveFileCustomName": "",
            "useArchivePassword": false,
            "archivePassword": "",
            "outputFormat": "PST",
            "includeArchiveMailbox": false,
            "useParallelMode": false,
            "nThreads": 6,
            "nDownloadThreads": 6,
            "includePublicFolders": false,
            "truncateExchangeLogs": false,
            "badItemsLimit": 50
        },
        "exchangeSource": {
            "name": "Exchange 365 Server",
            "id": "00000000-0000-0000-0000-000000000000",
            "version": "15.20.4087.50",
            "isExchangeOnline": true,
            "mailboxes": [{
                    "name": "a.alberti@iperius.onmicrosoft.com?u",
                    "id": "4f64e04e-9292-416c-9cb9-4fe7ea2f1fb4",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "a.foresi@iperius.onmicrosoft.com?u",
                    "id": "032fedcd-57e1-4216-8b17-d1fc6ed5c4b9",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "aaabbbccc@iperius.onmicrosoft.com?g",
                    "id": "9f7c08e3-b9eb-412a-a4ad-63f592d828e9",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "AdeleV@iperius.onmicrosoft.com?u",
                    "id": "661df988-1cb8-4ed3-a06e-9270aa0c2763",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "admin@iperius.onmicrosoft.com?u",
                    "id": "8d7ec195-0f46-4fe3-a032-bad2574365be",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "AlexW@iperius.onmicrosoft.com?u",
                    "id": "f94936a6-b2d5-42ca-8e9d-51a6cb616f34",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "AllCompany.6322585601.qsdkyogv@iperius.onmicrosoft.com?g",
                    "id": "daed26e9-3110-488e-bccc-cabac4299949",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "DiegoS@iperius.onmicrosoft.com?u",
                    "id": "5706cc54-17cc-4112-855a-0bedaac913e6",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "GradyA@iperius.onmicrosoft.com?u",
                    "id": "d152c458-6d43-4b8d-8de9-75a3083667ed",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "HenriettaM@iperius.onmicrosoft.com?u",
                    "id": "4c1fcf1b-3d46-47d1-ab99-a9bd84b842fa",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "iperius@iperius.onmicrosoft.com?g",
                    "id": "8301e70b-4d42-4aa3-9541-c2ef5f250424",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "JohannaL@iperius.onmicrosoft.com?u",
                    "id": "4cc879a4-7568-4dab-b8bc-500a43a2b59f",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "JoniS@iperius.onmicrosoft.com?u",
                    "id": "cba92155-e1df-4c09-b832-e4ef2486063c",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "LeeG@iperius.onmicrosoft.com?u",
                    "id": "4f7f08a4-0d57-455c-a354-a0a33a2fd562",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "LidiaH@iperius.onmicrosoft.com?u",
                    "id": "2f5969bb-6e13-4281-87f2-34b74534b263",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "LynneR@iperius.onmicrosoft.com?u",
                    "id": "5359f834-76f9-49fa-852d-37f90c6603d3",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "MeganB@iperius.onmicrosoft.com?u",
                    "id": "b4a8d56d-b9cd-4939-a0de-16f16179b3eb",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "NestorW@iperius.onmicrosoft.com?u",
                    "id": "da476514-021d-4204-b5f3-458590ae1b78",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "PattiF@iperius.onmicrosoft.com?u",
                    "id": "25275221-d97b-4bd6-a777-80a2a0c7569e",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "PradeepG@iperius.onmicrosoft.com?u",
                    "id": "8dac772a-142d-4f21-b08b-5843fc138ee7",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "sitoandrea@iperius.onmicrosoft.com?g",
                    "id": "d332e13a-06a3-472a-9ce0-88f0e208ed45",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "sitocondiviso1@iperius.onmicrosoft.com?g",
                    "id": "a8149ddc-cbb1-404a-a601-af63c6301b89",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "sitocondiviso@iperius.onmicrosoft.com?g",
                    "id": "e5aea883-7dbc-4579-a1f5-75ee883d20b5",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "sitodelmercoledi@iperius.onmicrosoft.com?g",
                    "id": "37cba39f-c00c-48ec-9b70-f2b96ffcd27b",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "sitotest@iperius.onmicrosoft.com?g",
                    "id": "80b16980-0472-4e40-9838-54ed99d3046e",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "testiperiussharedmailbox@iperius.onmicrosoft.com?u",
                    "id": "de83bd70-0dc0-4e92-9e1a-2d3e5730773f",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "testteam@iperius.onmicrosoft.com?g",
                    "id": "170c622d-ec74-4dcd-a3fe-73eb1720d87a",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "testteamdelvenerd@iperius.onmicrosoft.com?g",
                    "id": "9a02c86a-fe52-417e-9c46-4f1680fe83f8",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "User_528931836996810@iperius.onmicrosoft.com?u",
                    "id": "46414859-0bdf-48d5-9f08-04c51e71b2f7",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "User_a52893572153836@iperius.onmicrosoft.com?u",
                    "id": "26a63a25-575a-4e4e-9f4b-2f4ba8afed3d",
                    "size": null,
                    "selected": true
                },
                {
                    "name": "v.bachetti@iperius.onmicrosoft.com?u",
                    "id": "3649efcb-d6e7-416a-b847-fb80748a607a",
                    "size": null,
                    "selected": true
                }
            ],
            "databases": null
        }
    }

}

var net_destination = {
    id: null,
    name: 'NetworkBackupDestination',
    type: 81,
    type_name: "Network",
    id_service_account: services.find(sa => sa.type == 81).id,
    id_repository: -2,
    path: "\\\\PC-VERONICA\\Test PC-Veronica\\NetworkBackupDestination",
    options: {},
}


var ftp_destination = {
    id: null,
    name: 'FTPBackupDestination',
    type: 2,
    type_name: "FTP",
    id_service_account: services.find(sa => sa.type == 2).id,
    id_repository: -2,
    path: "ftp://192.168.0.205:21/Volume_1/TestVeronica/FTPBackupDestination",
    //path: "ftp://192.168.0.89:21/Backup/TestVeronica/FTPBackupDestination",
    options: {},
}

var sourceOptions = {

    //GENERALE
    copySecurityPermissions: false,
    recreateCompletePath: true,

    //FILTRI
    specifiedExtensions: "",
    extensionsFilterAction: 0,

    useFileSizeFilter: false,
    fileSizeFilterAction: 0,
    fileSizeFilterCompare: 0,
    fileSize: 0, // in MB

    //ESCLUSIONI
    excludedPaths: "",
    excludeSubFolders: false,

    //COMPRESSIONE
    useArchiveCompression: false,

    useArchiveFileCustomName: false,
    archiveFileCustomName: "",

    useArchivePassword: false,
    archivePassword: "",

    protectionType: 0,
    pathsInArchive: 1, // percorso completo nello zip di default

    //BACKUP
    backupType: "D",
    nCopies: 1,
    syncronize: false // non utilizzato
};



export function createBackups() {
    var destinations = [ff_destination, /**/ net_destination, ftp_destination /**/ ];

    /**/
    services.forEach(s => {

        destinations.push({
            id: null,
            name: platformsTypes[s.type].name + "BackupDestination",
            type: s.type,
            type_name: platformsTypes[s.type].name,
            id_service_account: s.id,
            id_repository: -2,
            path: platformsTypes[s.type].name + "BackupDestination",
            options: {}
        });
    }); /**/

    var backupTypes = ["F" /*, "U", "D", "I"*/ ];

    var backups = [];
    var types = {
        8: [10, 110, 111, 112, 12, 13, 140, 141, 8, 81, 2],
        81: [10, 110, 111, 112, 12, 13, 140, 141, 8, 81, 2],
        52: [8, 81],
        53: [8, 81],
        54: [8, 81],
        55: [8, 81]
    }

    backupTypes.forEach(t => {
        var sources = [ff_source(), /**/ net_source(), sharepoint_source(), teams_source(), onedrive_source(), exchange365_source()];
        var date = new Date();
        sources.forEach(s => {
            destinations.forEach(d => {

                if (types[s.type].includes(d.type)) {
                    //Modifico la sorgente
                    s.options.backupType = t;

                    var backup = getbackup();
                    backup.type = s.type.toString();
                    backup.sources = [s];
                    backup.destinations = [d];
                    backup.name = t + " - " + s.type_name + " - " + d.type_name + " - " + date.getDate() + " " + (date.getMonth() + 1) + " " + date.getFullYear() + " " + date.getHours() + " " + date.getMinutes() + " " + date.getSeconds();

                    while (backup.name.includes(":"))
                        backup.name = backup.name.replace(":", "-");

                    backups.push(backup);
                }
            });
        });
    });

    return backups;
}

/*export async function createAndTestBackups(root) {
    var backups = createBackups();
    var b;
    for (var i = 0; i < backups.length; i++) {
        b = backups[i];

        console.log(b.sources[0].type + " " + b.destinations[0].type);
        //Step 1 : Invio la richiesta all'API di salvare il job
        var api_response = await root.api("CREATEBACKUP", b);
        var socket_response;
        // Step 2 : Se il salvataggio è andato a buon fine, invio la richiesta al socket di salvare il job
        if (api_response) {
            socket_response = await root.socket("CreateJob", [b.name], api_response);
            console.log(socket_response);
        }

    }
}*/