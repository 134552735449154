<template>
    <div class="shifted-content">

        <Dialogs />
        <Appbar :user=true v-cloak />

         <div data-role="charms"  id="agentbuilding" data-position="right" data-cls-charms="bd-gray bg-white border border-top-none border-bottom-none border-right-none" v-cloak>
                <div class="row p-3 mb-4">
                    <h3 class="cell-8 text-left fg-black">{{ $t("Agent building and download") }}</h3>
                    <div class="cell-4 d-flex flex-justify-end">
                        
                        <!--button onclick="$('#notifications').data('charms').toggle()" class="button bg-white"><span class="icon mif mif-close mif-bin"> </span></button-->
                        <button @click="toggleAgent" class="button bg-white"><span class="icon mif mif-close mif-cross"> </span></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div>
                            <iframe allowfullscreen sandbox="allow-scripts allow-modals allow-forms allow-downloads"
                            :src="parentIframeSrc" width="500" height="600" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            
            </div>
  

    <aside class="sidebar z-2 pt-20"
       data-role="sidebar"
       data-toggle="#menu-toggle"
       id="sb4"
       data-shift=".docs-content"
       data-static-shift=".docs-content"
       data-static="lg">

         <!-- data-role="html-container" data-html-source="sidenav.html" data-insert-mode="content" data-role-htmlcontainer="true"-->
         <ul class="sidebar-menu ">
     
                <li class="">
                    <router-link to="/dashboard">
                        <span class="icon p-0 rounded"><svg style="width: 26;height: 26;" width="26" height="26"
                                fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.499 9.5 11.5 21H6.25a3.25 3.25 0 0 1-3.245-3.065L3 17.752V9.499h8.499Zm1.5 5.999H21.5v2.253a3.25 3.25 0 0 1-3.25 3.25L13 21l-.001-5.502Zm5.252-13a3.25 3.25 0 0 1 3.245 3.065l.005.184-.001 8.251h-8.501L13 2.498h5.251ZM11.5 2.497 11.499 8H3V5.748a3.25 3.25 0 0 1 3.25-3.25h5.25Z"
                                    fill="#212121" />
                            </svg></span>
                        <span class="">{{ $t("Dashboard") }}</span>
                    </router-link>
                </li>
              
                <li class="">
                    <router-link to="/groups">
                        <span class="icon p-0 rounded"><svg style="width: 26;height: 26;" width="24" height="24"
                                fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="m3.001 11.996 3 .004a1 1 0 0 1 .114 1.993L6 14 4 13.996v5.003L6 19a1 1 0 0 1 .993.883L7 20a1 1 0 0 1-.883.993L6 21H3a1 1 0 0 1-.993-.883L2 20v-7.004a1 1 0 0 1 .885-.994L3 11.996ZM21 17a1 1 0 0 1 .117 1.993L21 19H9a1 1 0 0 1-.117-1.993L9 17h12Zm0-3a1 1 0 0 1 .117 1.993L21 16H9a1 1 0 0 1-.117-1.993L9 14h12ZM6 2a1 1 0 0 1 .117 1.993L6 4H4v4.995h2a1 1 0 0 1 .993.883L7 9.995a1 1 0 0 1-.883.993L6 10.995H3a1 1 0 0 1-.993-.883L2 9.995V3a1 1 0 0 1 .883-.993L3 2h3Zm15 5a1 1 0 0 1 .117 1.993L21 9H9a1 1 0 0 1-.117-1.993L9 7h12Zm0-3a1 1 0 0 1 .117 1.993L21 6H9a1 1 0 0 1-.117-1.993L9 4h12Z"
                                    fill="#212121" />
                            </svg></span>
                        <span class="">{{ $t("Groups") }}</span>
                    </router-link>
                </li>

                <li class="">
                    <router-link to="/users">
                        <span class="icon p-0 rounded"><svg style="width: 26;height: 26;" width="24" height="24"
                                fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.75 15c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297-2.91 0-4.567-1.09-4.567-3.259v-1c0-.966.784-1.75 1.75-1.75h5.5Zm-11-5h4.376a4.007 4.007 0 0 0 1.067 3.85l.162.151L9.25 14a2.75 2.75 0 0 0-2.649 2.008l-.034.001C3.657 16.009 2 14.919 2 12.75v-1c0-.966.784-1.75 1.75-1.75Zm16.5 0c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297l-.169-.002a2.756 2.756 0 0 0-2.451-2L14.75 14l-.105.001a3.99 3.99 0 0 0 1.229-4L20.25 10ZM12 8a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM6.5 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm11 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
                                    fill="#212121" />
                            </svg></span>
                        <span class="">{{ $t("Users") }}</span>
                    </router-link>
                </li>
                <li>
                    <a class="dropdown-toggle" href="#" @click="subMenuOpen= (subMenuOpen !='backup'?'backup':'')" @blur="subMenuOpen =''">
                        <span class="mif-server icon p-0 "></span>
                        <span class="">{{ $t("Backup") }}</span>
                        <span :class="{'arrow': true, 'open': subMenuOpen == 'backup'}"></span>
                    </a>
                    <ul class="d-menu" data-role="dropdown">                        
                        <li >
                            <router-link to="/backupstats">
                            <span class=" icon p-0 pl-5">
                                <svg style="width: 26;height: 26;" width="24" height="24"
                                    fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.25 4.25A.75.75 0 0 1 11 5v8h8a.75.75 0 0 1 .743.648l.007.102c0 4.97-4.03 8.5-9 8.5a9 9 0 0 1-9-9c0-4.97 3.53-9 8.5-9Zm3-2.5a9 9 0 0 1 9 9 .75.75 0 0 1-.75.75h-8.25a.75.75 0 0 1-.75-.75V2.5a.75.75 0 0 1 .75-.75Z" fill="#212121"/>
                                </svg>
                            </span>
                            <span class="pl-5">{{ $t("Statistics") }}</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/accounts">
                            <span class=" icon p-0  pl-5">
                                <svg style="width: 26;height: 26;" width="24" height="24"
                                    fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 7.25A3.25 3.25 0 0 1 5.25 4h13.5A3.25 3.25 0 0 1 22 7.25v.25H2v-.25ZM2 9v5.75A3.25 3.25 0 0 0 5.25 18H11v-1.5a2.5 2.5 0 0 1 2-2.45V14a2.75 2.75 0 0 1 2.75-2.75h3.5A2.75 2.75 0 0 1 22 13.988V9H2Zm12 6h-.5a1.5 1.5 0 0 0-1.5 1.5V18h2.5v-.25a.75.75 0 0 1 1.5 0V18h3v-.25a.75.75 0 0 1 1.5 0V18H23v-1.5a1.5 1.5 0 0 0-1.5-1.5H21v-1a1.75 1.75 0 0 0-1.75-1.75h-3.5A1.75 1.75 0 0 0 14 14v1Zm1.5-1a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v1h-4v-1ZM12 21.5v-2h2.5v.75a.75.75 0 0 0 1.5 0v-.75h3v.75a.75.75 0 0 0 1.5 0v-.75H23v2a1.5 1.5 0 0 1-1.5 1.5h-8a1.5 1.5 0 0 1-1.5-1.5Z" fill="#212121"/>
                                </svg>
                            </span>
                            <span class="pl-5">{{ $t("Credentials") }}</span>
                            </router-link>
                        </li>
                                       
                        <li><router-link to="/repo">
                            <span class=" icon p-0  pl-5">
                                <svg style="width: 26;height: 26;" width="24" height="24"
                                fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.821 6.5h5.929a2.25 2.25 0 0 1 2.229 1.938l.016.158.005.154v9a2.25 2.25 0 0 1-2.096 2.245L19.75 20H4.25a2.25 2.25 0 0 1-2.245-2.096L2 17.75v-7.251l6.207.001.196-.009a2.25 2.25 0 0 0 1.088-.393l.156-.12L13.821 6.5ZM8.207 4c.46 0 .908.141 1.284.402l.156.12 2.103 1.751-3.063 2.553-.085.061a.75.75 0 0 1-.29.106L8.206 9 2 8.999V6.25a2.25 2.25 0 0 1 2.096-2.245L4.25 4h3.957Z" fill="#212121"/>
                            </svg></span>
                            <span class="pl-5">{{ $t("Repositories") }}</span>
                            </router-link>
                        </li>                     
                        <li >
                            <router-link to="/scheduling">
                            <span class=" icon p-0  pl-5">
                                <svg style="width: 26;height: 26;" width="24" height="24"
                                    fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.25 3A2.25 2.25 0 0 0 3 5.25v13.5A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V5.25A2.25 2.25 0 0 0 18.75 3H5.25ZM6.5 14.75a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75Zm.75-6.25h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1 0-1.5Zm10.53-.78a.75.75 0 0 1 0 1.06l-2 2a.75.75 0 0 1-1.06 0l-1-1a.75.75 0 0 1 1.06-1.06l.47.47 1.47-1.47a.75.75 0 0 1 1.06 0Zm0 6.56-2 2a.75.75 0 0 1-1.06 0l-1-1a.75.75 0 1 1 1.06-1.06l.47.47 1.47-1.47a.75.75 0 1 1 1.06 1.06Z" fill="#212121"/>
                                </svg>
                            </span>
                            <span class="pl-5">{{ $t("Scheduling") }}</span>
                            </router-link>
                        </li>                      
                        <li>
                            <router-link to="/sharedbackups">
                                <span class=" icon p-0  pl-5">
                                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M17 3.002a2.998 2.998 0 1 1-2.148 5.09l-5.457 3.12a3.002 3.002 0 0 1 0 1.577l5.458 3.119a2.998 2.998 0 1 1-.746 1.304l-5.457-3.12a2.998 2.998 0 1 1 0-4.184l5.457-3.12A2.998 2.998 0 0 1 17 3.002Z" fill="#212121"/></svg>
                                </span>   
                                <span  class="pl-5">{{ $t("Shared Backups") }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="dropdown-toggle" href="#" @click="subMenuOpen= (subMenuOpen!='remote'?'remote':'')" @blur="subMenuOpen =''">
                        <span class="mif-display icon p-0 "></span>
                        <span>{{ $t("Remote Access") }}</span>
                        <span class="arrow" :class="{'open': subMenuOpen == 'remote'}"></span>
                    </a>
                    <ul class="d-menu" data-role="dropdown">
                        <li >
                            <router-link to="/remotestats">
                            <span class=" icon p-0 pl-5"><svg style="width: 26;height: 26;" width="24" height="24"
                                fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.25 4.25A.75.75 0 0 1 11 5v8h8a.75.75 0 0 1 .743.648l.007.102c0 4.97-4.03 8.5-9 8.5a9 9 0 0 1-9-9c0-4.97 3.53-9 8.5-9Zm3-2.5a9 9 0 0 1 9 9 .75.75 0 0 1-.75.75h-8.25a.75.75 0 0 1-.75-.75V2.5a.75.75 0 0 1 .75-.75Z" fill="#212121"/>
                            </svg></span>
                            <span class="pl-5">{{ $t("Statistics") }}</span>
                            </router-link>
                        </li>
                        <li >
                            <router-link to="/sessions" active-class="active">
                            <span class=" icon p-0 pl-5">
                                <svg style="width: 26;height: 26;" width="24" height="24"
                                    fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.25 4A2.25 2.25 0 0 0 2 6.25v2.5A2.25 2.25 0 0 0 4.25 11h2.5A2.25 2.25 0 0 0 9 8.75v-2.5A2.25 2.25 0 0 0 6.75 4h-2.5Zm7 1a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5h-10Zm0 3a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7Zm-7 5A2.25 2.25 0 0 0 2 15.25v2.5A2.25 2.25 0 0 0 4.25 20h2.5A2.25 2.25 0 0 0 9 17.75v-2.5A2.25 2.25 0 0 0 6.75 13h-2.5Zm7 1a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5h-10Zm0 3a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5h-7Z" fill="#212121"/>
                                </svg>
                            </span>
                            <span class="pl-5">{{ $t("sessions") }}</span></router-link>
                        </li>
                   
                        <li >
                            <router-link to="/activesessions">
                            <span class=" icon p-0 pl-5">
                                <svg style="width: 26;height: 26;" width="24" height="24"
                                    fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m15.207 2.292 4 3.995a1 1 0 0 1 .084 1.32l-.083.094-4 4.006a1 1 0 0 1-1.498-1.32l.083-.094L16.083 8H5.5a1 1 0 0 1-.994-.883L4.5 7a1 1 0 0 1 .883-.993L5.5 6h10.59l-2.296-2.293a1 1 0 0 1-.084-1.32l.083-.095a1 1 0 0 1 1.32-.084l.094.084 4 3.995-4-3.995Zm4.283 14.591.007.117a1 1 0 0 1-.883.993l-.117.007H7.913l2.294 2.293a1 1 0 0 1 .084 1.32l-.083.094a1 1 0 0 1-1.32.084l-.095-.084-4-3.996a1 1 0 0 1-.083-1.32l.083-.094 4-4.004a1 1 0 0 1 1.498 1.32l-.083.094L7.918 16h10.579a1 1 0 0 1 .993.883l.007.117-.007-.117Z" fill="#212121"/>
                                </svg>
                            </span>
                            <span class="pl-5">{{ $t("Active sessions") }}</span>
                            </router-link>
                        </li>
                  
                        <li>
                            <router-link to="/sharedsettings">
                                <span class=" icon p-0 pl-5 ">
                                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M17.934 3.005 17.75 3H6.25l-.184.005A3.25 3.25 0 0 0 3 6.25v11.5l.005.184A3.25 3.25 0 0 0 6.25 21h5.772a6.463 6.463 0 0 1-.848-2H6.25l-.128-.006A1.25 1.25 0 0 1 5 17.75L5.001 8H19v3.174c.721.17 1.395.46 2.001.848V6.25l-.005-.184a3.25 3.25 0 0 0-3.062-3.06Zm-6.94 7.143a.75.75 0 0 0-.744-.648h-3.5l-.102.007A.75.75 0 0 0 6 10.25v7l.007.102A.75.75 0 0 0 6.75 18h3.5l.102-.007A.75.75 0 0 0 11 17.25v-7l-.007-.102ZM7.5 11h2v5.5h-2V11Zm10.5-.75a.75.75 0 0 0-.75-.75h-4.496l-.101.007A.75.75 0 0 0 12.754 11h4.496l.102-.007A.75.75 0 0 0 18 10.25Zm-3.721 3.725a2 2 0 0 1-1.441 2.497l-.585.144a5.729 5.729 0 0 0 .007 1.807l.54.13a2 2 0 0 1 1.45 2.51l-.187.632c.439.386.94.699 1.484.921l.493-.518a2 2 0 0 1 2.9 0l.498.525a5.28 5.28 0 0 0 1.483-.913l-.198-.686a2 2 0 0 1 1.441-2.496l.584-.144a5.729 5.729 0 0 0-.007-1.808l-.539-.13a2 2 0 0 1-1.45-2.51l.187-.63a5.278 5.278 0 0 0-1.485-.923l-.493.519a2 2 0 0 1-2.899 0l-.499-.525c-.543.22-1.043.53-1.482.912l.198.686ZM17.5 19c-.8 0-1.45-.672-1.45-1.5 0-.829.65-1.5 1.45-1.5.801 0 1.45.671 1.45 1.5 0 .828-.649 1.5-1.45 1.5Z" fill="#212121"/></svg>
                                 </span>   
                                <span class="pl-5">{{ $t("Shared Settings") }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>   
                <!--li class="">
                    <router-link to="/repo">
                        <span class=" icon p-0"><svg style="width: 26;height: 26;" width="24" height="24"
                                fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10 2a4 4 0 0 1 4 4v2h2.5A1.5 1.5 0 0 1 18 9.5V11c-.319 0-.637.11-.896.329l-.107.1c-.812.845-1.656 1.238-2.597 1.238-.783 0-1.4.643-1.4 1.416v2.501c0 2.374.924 4.22 2.68 5.418L3.5 22A1.5 1.5 0 0 1 2 20.5v-11A1.5 1.5 0 0 1 3.5 8H6V6a4 4 0 0 1 4-4Zm8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.385.385 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.389.389 0 0 1 .566 0ZM10 13.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM10 4a2 2 0 0 0-2 2v2h4V6a2 2 0 0 0-2-2Z"
                                    fill="#212121" />
                            </svg></span>
                        <span class="">{{ $t("Saved repository") }}</span>
                    </router-link>
                </li-->
                <!--li class=" ">
                    <router-link to="/accounts">
                        <span class=" icon p-0"><svg style="width: 26;height: 26;" width="24" height="24"
                                fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 5.23a2.25 2.25 0 0 1 2.25-2.25h1.5A2.25 2.25 0 0 1 15 5.23V21H9V5.23ZM7.5 10H5.25A2.25 2.25 0 0 0 3 12.25v8c0 .415.336.75.75.75H7.5V10ZM16.5 21h3.75a.75.75 0 0 0 .75-.75v-11A2.25 2.25 0 0 0 18.75 7H16.5v14Z" fill="#212121"/>
                            </svg></span>
                        <span class=" ">{{ $t("Saved Credentials") }}</span>
                    </router-link>
                </li-->
                <!--li class=" ">
                    <router-link to="/scheduling">
                        <span class="icon p-0"><svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21 8.5v9.25A3.25 3.25 0 0 1 17.75 21H6.25A3.25 3.25 0 0 1 3 17.75V8.5h18ZM7.25 15a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM12 15a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-4.75-4.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm4.75 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm4.75 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm1-7.5A3.25 3.25 0 0 1 21 6.25V7H3v-.75A3.25 3.25 0 0 1 6.25 3h11.5Z" fill="#212121"/></svg></span>
                        <span class=" ">{{ $t("Backups Scheduling") }}</span>
                    </router-link>
                </li>
                <li class="">
                    <router-link to="/">
                        <span class="mif-cog icon  p-0 rounded"></span>
                        <span class=" ">{{ $t("Saved Configurations") }}</span>
                          <div class="badges">
                                <span class="badge inline small bg-dark fg-white">PRO</span>
                                <span class="badge inline bg-cobalt fg-white">on</span>
                            </div>
                    </router-link>
                </li> -->
                <li class="">
                    <a class="dropdown-toggle" href="#" @click="subMenuOpen= (subMenuOpen!='settings'?'settings':'')" @blur="subMenuOpen =''">
                        <span class="icon p-0 ">
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12.012 2.25c.734.008 1.465.093 2.182.253a.75.75 0 0 1 .582.649l.17 1.527a1.384 1.384 0 0 0 1.927 1.116l1.401-.615a.75.75 0 0 1 .85.174 9.792 9.792 0 0 1 2.204 3.792.75.75 0 0 1-.271.825l-1.242.916a1.381 1.381 0 0 0 0 2.226l1.243.915a.75.75 0 0 1 .272.826 9.797 9.797 0 0 1-2.204 3.792.75.75 0 0 1-.848.175l-1.407-.617a1.38 1.38 0 0 0-1.926 1.114l-.169 1.526a.75.75 0 0 1-.572.647 9.518 9.518 0 0 1-4.406 0 .75.75 0 0 1-.572-.647l-.168-1.524a1.382 1.382 0 0 0-1.926-1.11l-1.406.616a.75.75 0 0 1-.849-.175 9.798 9.798 0 0 1-2.204-3.796.75.75 0 0 1 .272-.826l1.243-.916a1.38 1.38 0 0 0 0-2.226l-1.243-.914a.75.75 0 0 1-.271-.826 9.793 9.793 0 0 1 2.204-3.792.75.75 0 0 1 .85-.174l1.4.615a1.387 1.387 0 0 0 1.93-1.118l.17-1.526a.75.75 0 0 1 .583-.65c.717-.159 1.45-.243 2.201-.252ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" fill="#212121"/></svg>
                        </span>
                        <span class="">{{ $t("Settings") }}</span>
                         <span class="arrow" :class="{'open': subMenuOpen == 'settings'}"></span>
                    </a>
                    <ul class="d-menu" data-role="dropdown">
                        <li>
                            <router-link to="/settings">
                                <span class="icon p-0 pl-5">
                                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2c5.523 0 10 4.477 10 10 0 .263-.01.523-.03.78a6.5 6.5 0 0 0-9.19 9.19 9.96 9.96 0 0 1-5.425-1.112l-4.29 1.117A.85.85 0 0 1 2.03 20.94l1.116-4.289A9.959 9.959 0 0 1 2 12C2 6.477 6.477 2 12 2Zm2.278 11.976a2 2 0 0 1-1.441 2.496l-.584.144a5.729 5.729 0 0 0 .006 1.807l.54.13a2 2 0 0 1 1.45 2.51l-.187.632c.44.386.94.699 1.484.922l.494-.52a2 2 0 0 1 2.899.001l.498.525a5.281 5.281 0 0 0 1.483-.913l-.198-.686a2 2 0 0 1 1.441-2.496l.584-.144a5.716 5.716 0 0 0-.006-1.808l-.54-.13a2 2 0 0 1-1.45-2.51l.187-.63a5.28 5.28 0 0 0-1.484-.922l-.493.518a2 2 0 0 1-2.9 0l-.498-.525a5.28 5.28 0 0 0-1.483.912l.198.687ZM17.5 16c.8 0 1.45.672 1.45 1.5S18.3 19 17.5 19c-.8 0-1.45-.672-1.45-1.5S16.7 16 17.5 16Z" fill="#212121"/></svg>
                                </span>
                                <span class="pl-5">{{ $t("Notification Settings") }}</span>
                            </router-link>
                        </li>

                    </ul>
                </li>

            </ul>

        </aside>

        <div class="docs-content bg-light pt-13 h-100">
            
            <transition name="slide-fade">
                <router-view />
            </transition>

        </div>
        <FileSystemSidebar />
        <addLicenseDialog :license="license" :pricing="pricing" ></addLicenseDialog>


    </div>
</template>

<script>


import Appbar from '../layouts/Appbar';
import FileSystemSidebar from '../components/FileSystemSidebar';
import Dialogs from '../components/Dialogs';
import addLicenseDialog from '../components/dialogs/AddLicenseDialog.vue'
import {  formatDate } from '../../public/assets/js/utilitiesmodule';
export default {
    name: 'nav-layout',
    components: {
        Appbar,
        FileSystemSidebar,
        Dialogs,
        addLicenseDialog
    },
    created: function () {
        //console.log("CREATED Nav Layout!!!")
        this.getAgentIframeUrl();
        
    },
    data() {
        return {
            subMenuOpen: '',
            loader: false,
            licenses: {},
            pricing: null,
            license: {
                num_users: 1,
                num_workstations: 0,
                num_servers: 0,
                num_vms: 0,
                plugin_exchange: 0,
                plugin_database: 0,
                plugin_tape: 0,
                subscription_duration: 1,
                discount_code: null,
                currency: 'EUR'
            },
            parentIframeSrc: '',
            trustedSrc: "",
            urlagentbuild: process.env.VUE_APP_AGENT_BUILD_BASE_URL,
            

        }
    },
    methods: {
            toggleAgent() {
                window.Metro.charms.close('#agentbuilding');
            },

             formatDate(date) {
                return formatDate(date, this.$moment);
            },

                // [TG - 18/06/2024 - generazione link apertura iframe build agent]
            async getAgentIframeUrl() {
                let self = this;

                //const div = document.getElementById('notifications');
                //const isOpened = div.hasAttribute('data-opened');
                //console.log('Notifications div is ' + (isOpened ? 'open' : 'closed'));
                if(self.$session.get("AUTH")==null){
                    return;
                }

                let ima = self.$session.get("AUTH").id_master_account;
                let doa = self.formatDate(self.$session.get("AUTH").dataora_attivazione);
                let dos = self.formatDate(self.$session.get("AUTH").dataora_scadenza);
                let sc = '|';

                let cryLic = await self.$root.api("GETCOCOD", ima);

                if (cryLic != null) {

                    let ext_lic_info = await self.$root.api("LICINFOEXT");

                    if (ext_lic_info != null) {

                        // normalizza il JSON delle caratteristiche aggiuntive della licenza in una unica stringa
                        let nl = ext_lic_info.map(license => {
                            return `nome_licenza: ${license.nome_licenza}, max_control_connections: ${license.max_control_connections}, max_host_list: ${license.max_host_list}, units: ${license.units}`;
                        }).join(' + ');

                        let currentDate = new Date();
                        let formattedDate = this.$moment(currentDate).format('YYYYMMDD');

                        let qry_string = ima + sc + doa + sc + dos + sc + formattedDate + sc + nl + sc + cryLic.result;

                        let param = await self.$root.api("CRYPTSTR", qry_string);

                        var trustedSrc = self.urlagentbuild + '?pl=' + encodeURIComponent(param.result);
                        //console.log(trustedSrc);
                        self.parentIframeSrc = trustedSrc;
                        // il componente padre (Dashboard.vue) ascolterà gli eventi emessi dal componente figlio (Navigation.vue)
                        // va dunque a cambiare la proprietà src dell'iframe (iframe si apre su Dashboard.vue, mentre il link viene creato in Navigation.vue)
                        //this.$emit('update-iframesrc', self.trustedSrc);

                    }

                }

            },


            // [TG - 18/06/2024 - gestione finiestra generazione agent]
            updateParentIframeSrc(newIframeSrc) {
                this.parentIframeSrc = newIframeSrc;
            },

            
            // --------------------------------------------------------------------------

    },
    watch: {
        license: {
            async handler(val) {
                if(val!=null && this.$root.checkAuth() != null){
                    this.pricing = await this.$root.api("GETPRICING", val);
                }
            },
            deep: true,
            immediate:true
        }

    },
}
</script>

