import api from "./api";
import { isValid, waitSeconds, cookieManager, PlatformsTypesEnum } from "../../public/assets/js/utilitiesmodule";
import { createAppObj, getDefaultAzureCredentials, getDefaultScopes, requiredResourceAccess } from "../../public/assets/js/azureConfig";
import { GraphClient } from "./graphClient";

export class AzureClient {

    /**
     * il service account deve contenere : 
        - options.appName : ..., (required)
                oppure
        - options.tenant_id: ..., (required)
        - client_id: ..., (appID) (required)
        - client_secret : ... (optional)
        - refresh_token: ..., (optional)
    }
*/
    constructor(serviceAccount) {
        this.serviceAccount = serviceAccount;
    }

    async getTokens() {
        var ok = await this._getTokens(!isValid(this.serviceAccount.id), isValid(this.serviceAccount.id), isValid(this.serviceAccount.id), isValid(this.serviceAccount.id));

        /***************************ACCESSTOKEN****************************************************/
        //Step 1 : Se presente l'access token, provo una chiamata.
        //Se non andrà a buon fine verrà cancellato il token
        /* if (isValid(this.serviceAccount.token)) {
            ok = await this._getTenantID(this.serviceAccount.token);
        }

        //Step 2 : Se posso riprendere l'access token con il refreshToken, provo ad effettuare la chiamata
        // Se non andrà a buon fine, verranno cancellati entrambe i token
        if (!ok && !isValid(this.serviceAccount.token) && isValid(this.serviceAccount.options.tenant_id) && isValid(this.serviceAccount.client_id) && isValid(this.serviceAccount.refresh_token)) {
            ok = await this._getTokensWithRefreshToken(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id, this.serviceAccount.refresh_token);
        }

        //Step 3 : Se posso riprendere il token con il client secret, provo ad effettuare la chiamata
        if (!ok && !isValid(this.serviceAccount.token) && isValid(this.serviceAccount.options.tenant_id) && isValid(this.serviceAccount.client_id) && isValid(this.serviceAccount.client_secret)) {
            ok = await this._getAccessTokenWithAppIDAndClientSecret(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id, this.serviceAccount.client_secret);
        }

        //Step 4 : Se posso riprendere il token con il appID, provo ad effettuare la chiamata
        if (!ok && !isValid(this.serviceAccount.token) && isValid(this.serviceAccount.options.tenant_id) && isValid(this.serviceAccount.client_id)) {
            ok = await this._getTokensWithTenantIDAndAppID(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id);
        }

        //Step 5 : Se tutti i tentativi sono falliti, riprendo il token da Azure
        if (!ok && !isValid(this.serviceAccount.token)) {
            //Step 1.1 : faccio il login ad azure
            ok = await this._getTokensFromAzure();
            // Step 1.2 : Nel caso in cui la richiesta non va a buon fine, resetto tutti i campi della richiesta e la ritorno
            if (!ok) {
                cookieManager.setCookie("step", messages.azureAccessFailed);
                this.serviceAccount.token = null;
                this.serviceAccount.refresh_token = null
                return false;
            }
            cookieManager.setCookie("step", messages.azureAccessOk);
        } */

        /***************************TENANTID****************************************************/
        //Step 6 : Se non ho il tenantID, lo recupero
        if (ok && !isValid(this.serviceAccount.options.tenant_id)) {
            ok = await this._getTenantID(this.serviceAccount.token);
            // Step 2.1 : Nel caso in cui la richiesta non va a buon fine, resetto tutti i campi della richiesta e la ritorno
            if (!ok) {
                cookieManager.setCookie("step", messages.azureAccessFailed);
                this.serviceAccount.token = null;
                this.serviceAccount.refresh_token = null
                return false;
            }
        }


        //***************************CLIENTID****************************************************/
        //Step 7 : Se non è stato passato un client id, lo ricavo prendendo l'applicazione con il nome passato
        if (ok && !isValid(this.serviceAccount.client_id)) {
            ok = await this._getAppByName(null, this.serviceAccount.options.appName, this.serviceAccount.token);
            //Step 7.1 : Se non esiste l'applicazione con il nome passato, creo l'applicazione
            if (!ok) {
                ok = await this._createNewApp(this.serviceAccount.options.appName, this.serviceAccount.token);

                //Step 3.3 : faccio il login ad azure
                /*ok = await this._getTokensFromAzure();
                // Step 3.4 : Nel caso in cui la richiesta non va a buon fine, resetto tutti i campi della richiesta e la ritorno
                if (!ok) {
                    cookieManager.setCookie("step", messages.azureAccessFailed);
                    this.serviceAccount.token = null;
                    this.serviceAccount.refresh_token = null
                    return false;
                }
                cookieManager.setCookie("step", messages.azureAccessOk);
                if (!ok)
                    return false;*/
            }
        }

        //Step 5 : CLIENT SECRET
        if (ok && !isValid(this.serviceAccount.client_secret)) {
            ok = await this._createClientSecret(this.serviceAccount.options.appName, this.serviceAccount.options.objID, this.serviceAccount.token);
            if (!ok)
                return false;
        }

        if (ok && !isValid(this.application))
            ok = await this._getAppByName(this.serviceAccount.client_id, this.serviceAccount.options.appName, this.serviceAccount.token);
        if (!ok)
            return false;

        //Step 6 : Assegno/aggiorno i permessi, perché nel tempo possono cambiare a seconda delle implementazioni futuri
        ok = await this._setPermissions(this.serviceAccount.options.objID, requiredResourceAccess, this.serviceAccount.token);

        if (!ok)
            return false;

        /*
        //Step 4 : LOGIN applicazione
        cookieManager.setCookie("step", messages.appAccess);
        if (isValid(this.serviceAccount.options.tenant_id) && isValid(this.serviceAccount.client_id)) {
            ok = await this._getTokensWithTenantIDAndAppID(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id);

            if (!ok) {
                cookieManager.setCookie("step", messages.appAccessFailed);
                this.serviceAccount.token = null;
                this.serviceAccount.refresh_token = null;
                return false;
            }
        }
        */

        //per avere un token valido, lo acquisisco con il client secret
        ok = await this._getTokens(false, true, true, false); // _getAccessTokenWithAppIDAndClientSecret(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id, this.serviceAccount.client_secret);

        if (this.serviceAccount.type == PlatformsTypesEnum.Cloud_OneDrive && !isValid(this.serviceAccount.options.userID)) {
            var graph = new GraphClient(this.serviceAccount);
            var user = await graph.getUserByEmail(this.serviceAccount.username);
            if (user == null)
                return false;
            this.serviceAccount.options.userID = user.resID;
            var drive = await graph.getDriveByUserID(user.resID);
            if (drive == null)
                return false;
            this.serviceAccount.options.driveID = drive.id;
        }

        //Step 7 : Chiedo il token con il client secret
        return ok;
    }



    async _getTokens(firstAccess = true, tryAccessToken = true, tryRefreshToken = true, tryClientSecret = true) {
        var ok = false;

        /***************************ACCESSTOKEN****************************************************/
        //Step 1 : Se presente l'access token, provo una chiamata.
        //Se non andrà a buon fine verrà cancellato il token
        if (!firstAccess && tryAccessToken && isValid(this.serviceAccount.token)) {
            ok = await this._getTenantID(this.serviceAccount.token);
        }

        //Step 2 : Se posso riprendere l'access token con il refreshToken, provo ad effettuare la chiamata
        // Se non andrà a buon fine, verranno cancellati entrambe i token
        if (!firstAccess && !ok && tryRefreshToken && !isValid(this.serviceAccount.token) && isValid(this.serviceAccount.options.tenant_id) && isValid(this.serviceAccount.client_id) && isValid(this.serviceAccount.refresh_token)) {
            ok = await this._getTokensWithRefreshToken(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id, this.serviceAccount.refresh_token);
        }

        //Step 3 : Se posso riprendere il token con il client secret, provo ad effettuare la chiamata
        //if (!firstAccess && !ok && tryClientSecret && !isValid(this.serviceAccount.token) && isValid(this.serviceAccount.options.tenant_id) && isValid(this.serviceAccount.client_id) && isValid(this.serviceAccount.client_secret)) {
        //    ok = await this._getAccessTokenWithAppIDAndClientSecret(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id, this.serviceAccount.client_secret);
        //}

        //Step 4 : Se posso riprendere il token con il appID, provo ad effettuare la chiamata
        if (!firstAccess && !ok && !isValid(this.serviceAccount.token) && isValid(this.serviceAccount.options.tenant_id) && isValid(this.serviceAccount.client_id)) {
            ok = await this._getTokensWithTenantIDAndAppID(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id);
        }

        //Step 5 : Se tutti i tentativi sono falliti, riprendo il token da Azure
        if (!ok && !isValid(this.serviceAccount.token)) {
            //Step 1.1 : faccio il login ad azure
            ok = await this._getTokensFromAzure();
            // Step 1.2 : Nel caso in cui la richiesta non va a buon fine, resetto tutti i campi della richiesta e la ritorno
            if (!ok) {
                cookieManager.setCookie("step", messages.azureAccessFailed);
                this.serviceAccount.token = null;
                this.serviceAccount.refresh_token = null
                return false;
            }
            cookieManager.setCookie("step", messages.azureAccessOk);
        }

        return ok;
    }

    async _getTokensWithTenantIDAndAppID(tenantID, appID) {
        //Step 1 : Acquisisco l'authorization code
        var authCode = await this._getAuthCode(tenantID, appID);

        if (authCode == null) return false;

        var ok = await this._getTokensWithAuthorizationCode(tenantID, appID, authCode);

        return ok;
    }

    async _getTokensWithAuthorizationCode(tenantID, appID, authCode) {
        var body = new URLSearchParams()
        body.append("client_id", appID);
        body.append("grant_type", "authorization_code");
        body.append("code", authCode);
        body.append("redirect_uri", "http://localhost:8080/");
        if (tenantID != "common")
        //body.append("scope", "offline_access openid profile");
            body.append("scope", getDefaultScopes().join(" "));

        var response = await api.post(
            "https://login.microsoftonline.com/" + tenantID + "/oauth2/v2.0/token",
            body, {
                headers: {
                    "Access-Control-Allow-Origin": window.location.origin,
                    "Access-Control-Allow-Credentials": true,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).catch(e => {
            console.log(e);
            return e;
        });

        console.log(response);

        //Caso 1 : l'authorization code è già stato utilizzato. Ne richiedo uno nuovo e riprovo la chiamata
        /*     if (response.status == 400 && response.data.error_description.startsWith("AADSTS54005")) {
                return null;
            } */

        if (response.status != 200) {
            return false;
        }

        //Caso 2 : la richiesta è andata a buon fine
        this.serviceAccount.token = response.data.access_token;
        if (tenantID != "common")
            this.serviceAccount.refresh_token = response.data.refresh_token
        return true;


    }

    async _getTokensWithRefreshToken(tenantID, appID, refreshToken) {
        var body = new URLSearchParams();
        body.append("client_id", appID);
        body.append("grant_type", "refresh_token");
        body.append("refresh_token", refreshToken);
        body.append("redirect_uri", "http://localhost:8080/");

        var response = await api.post(
            "https://login.microsoftonline.com/" + tenantID + "/oauth2/v2.0/token",
            body, {
                headers: {
                    "Access-Control-Allow-Origin": window.location.origin,
                    "Access-Control-Allow-Credentials": true,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).catch(async e => {
            console.log(e);
            return {
                status: 400
            }
        });

        //console.log(response);
        if (response.status != 200) {
            this.serviceAccount.token = null;
            this.serviceAccount.refresh_token = null
            return false;
        }

        this.serviceAccount.token = response.data.access_token;
        this.serviceAccount.refresh_token = response.data.refresh_token
            //Caso 2 : la richiesta è andata a buon fine
        return true;
    }

    //https://learn.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-client-creds-grant-flow
    async _getAccessTokenWithAppIDAndClientSecret(tenantID, appID, clientSecret) {
        var body;

        //Riprovo più volte poiché Azure non mi permette di utilizzare subito il client secret appena registrato
        var accessToken = null;
        for (var attempt = 1; attempt <= 10; attempt++) {
            body = new URLSearchParams();
            body.append("client_id", appID);
            body.append("client_secret", clientSecret);
            body.append("grant_type", "client_credentials");
            body.append("scope", "https://graph.microsoft.com/.default");
            accessToken = await api.post(
                    "https://login.microsoftonline.com/" + tenantID + "/oauth2/v2.0/token",
                    body, {
                        headers: {
                            "Access-Control-Allow-Origin": window.location.origin,
                            "Access-Control-Allow-Credentials": true,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                )
                .then(response => {
                    console.log(response);
                    return response.data.access_token;
                })
                .catch(async e => {
                    console.log(e);
                    //Client secret non valido
                    /*if (e.status == 401) {
                        //riprendo il token con il refreshtoken
                        this.serviceAccount.token = null;
                        var ok = await this._getTokens(false, false, true, false);
                        //se la richiesta dei token è andata a buon fine, creo un nuovo client secret
                        if (ok) {
                            ok = await this._createClientSecret(this.serviceAccount.options.appName, this.serviceAccount.options.objID, this.serviceAccount.token);
                            if (ok) {
                                clientSecret = this.serviceAccount.client_secret;
                            }
                        }

                        return null;
                    } */
                    //Azure non è riuscito ancora a vedere il nuovo client secret creato
                    if (e.data.error_description.indexOf("Invalid client secret provided") != -1) {
                        await waitSeconds(10);
                        return null;
                    }

                    //}

                });

            if (accessToken != null) {
                this.serviceAccount.token = accessToken;
                return true;
            }

        }

        this.serviceAccount.client_secret = null;
        this.serviceAccount.token = null;
        this.serviceAccount.refresh_token = null
        return false;
    }

    async _getTokensFromAzure() {
        var request = getDefaultAzureCredentials();
        var ok = await this._getTokensWithTenantIDAndAppID(request.tenantID, request.appID);

        return ok;
    }

    async _getAuthCode(tenantID, appID) {
        cookieManager.deleteCookie("authCode");

        //Step 1 : Richiedo l'authorization code. Questo verrà concatenato all'url del redirect uri.
        var queryParams = [
            "client_id=" + appID,
            "scope=" + (tenantID == "common" ? "openid%20profile%20offline_access%20" : getDefaultScopes().join("%20")),
            "redirect_uri=http%3A%2F%2Flocalhost%3A8080",
            "response_mode=query",
            "response_type=code"
        ];

        var url = "https://login.microsoftonline.com/" + tenantID + "/oauth2/v2.0/authorize?" + queryParams.join("&");
        window.open(url); // client_id=04b07795-8ddb-461a-bbee-02f9e1bf7b46&scope=openid%20profile%20offline_access&redirect_uri=http%3A%2F%2Flocalhost%3A8080&response_mode=query&response_type=code")

        //Step 2 : La pagina specificata nel redirect uri scriverà un cookie contenente l'authCode.
        var authCode = await cookieManager.waitAndGetCookie("authCode");

        return authCode;

    }

    /***************************************************REGISTAZIONE APP***************************************************************************************/

    async _createNewApp(appName, accessToken) {
        cookieManager.setCookie("step", messages.appRegistration);

        //var application = newAppObj(request.appName, ["userreadwrite", "groupuserreadwrite", "directoryuserreadwrite", "filesuserreadwrite", "offline_access", "full_access_as_app", "mailbox", "sitesuserreadwrite", "email", "openid", "profile"])
        var application = createAppObj(appName);

        //console.log(application);
        var ids = await api.post(

                "https://graph.microsoft.com/v1.0/applications",
                JSON.stringify(application), {
                    headers: {
                        "Authorization": "Bearer " + accessToken,
                        "Access-Control-Allow-Origin": window.location.origin,
                        "Access-Control-Allow-Credentials": true,
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then(response => {
                cookieManager.setCookie("step", messages.appRegistrationOk);

                this.application = response.data;

                return {
                    "appID": response.data.appId,
                    "objID": response.data.id
                }
            })
            .catch(e => {
                cookieManager.setCookie("step", messages.appRegistrationFailed);
                console.log(e);
                //Se la richiesta fallisce per un access token scaduto
                //if (e.toString().startsWith("Error: Request failed with status code 401"))
                return null;
            });
        //console.log(response);

        //Aspetto che l'applicazione venga creata
        /* var created = false;
                do {
    
                    await waitSeconds(2);
    
                    created = await _getAppByID(ids.appID, accessToken);
                } while (!created); */

        if (ids == null) {
            this.serviceAccount.client_id = null;
            this.serviceAccount.options.objID = null;
            return false;
        }

        //Devo aspettare un po' di tempo prima di effettuare qualche richiesta
        for (var s = 60; s >= 0; s--) {
            cookieManager.setCookie("step", "Wait " + s + "seconds");
            await waitSeconds(1);
        }

        this.serviceAccount.client_id = ids.appID;
        this.serviceAccount.options.objID = ids.objID;
        return true;
    }

    async _setPermissions(objID, newPermissions, accessToken) {

        var ok = await api.patch(
                "https://graph.microsoft.com/v1.0/applications/" + objID,
                JSON.stringify({ requiredResourceAccess: newPermissions }), {
                    headers: {
                        "Authorization": "Bearer " + accessToken,
                        "Access-Control-Allow-Origin": window.location.origin,
                        "Access-Control-Allow-Credentials": true,
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then(() => {
                cookieManager.setCookie("step", messages.appRegistrationOk);
                return true;
            })
            .catch(e => {
                cookieManager.setCookie("step", messages.appRegistrationFailed);
                console.log(e);
                //Se la richiesta fallisce per un access token scaduto
                //if (e.toString().startsWith("Error: Request failed with status code 401"))
                return false;
            });

        if (!ok)
            return false;

        ok = await this._setAdminConsent(this.serviceAccount.options.tenant_id, this.serviceAccount.client_id);

        if (!ok) {
            this.serviceAccount.token = null;
            this.serviceAccount.refresh_token = null;
            return false;
        }

        return true;
    }

    /* async function _getAppByID(appID, accessToken) {
        
        var response = await api.get(
            "https://graph.microsoft.com/v1.0/applications?$filter=AppId%20eq%20%27" + appID + "%27", {
                headers: {
                    "Authorization": "Bearer " + accessToken
                }
            }
        ).then(response => {
            var results = response.data.value;
            if (results.length == 0) return null;
            else return results[0];
        }).catch(e => {
            console.log(e);
            return null;
        });
        
        console.log(response);
        
        return response;
        
    } */

    async _getAppByName(appID, appName, accessToken) {
        var params = appID == null ?
            "$filter=" + encodeURIComponent("DisplayName eq '" + appName + "'") + "&" :
            "$filter=" + encodeURIComponent("Id eq '" + appID + "' or DisplayName eq '" + appName + "'") + "&";
        params += "$select=id,appId,displayName,requiredResourceAccess";

        var ids = await api.get("https://graph.microsoft.com/v1.0/applications?" + params, {
            headers: {
                "Authorization": "Bearer " + accessToken
            }
        }).then(async response => {
            var results = response.data.value;
            if (results.length == 0) return null;

            this.application = results[0];

            if (results.length > 1) {

                for (var i = 1; i < results.length; i++) {
                    await api.delete("https://graph.microsoft.com/v1.0/applications/" + results[i].id, {
                        headers: {
                            "Authorization": "Bearer " + accessToken
                        }
                    }).then(resp => console.log(resp)).catch(err => console.error(err));
                }

            }

            return {
                appID: results[0].appId,
                objID: results[0].id
            }
        }).catch(e => {
            console.log(e);
            return null;
        });

        if (ids == null) {
            this.serviceAccount.client_id = null;
            return false;
        }

        this.serviceAccount.client_id = ids.appID;
        this.serviceAccount.options.objID = ids.objID;
        return true;

    }

    async _setAdminConsent(tenantID, appID) {
        var param = [
            "client_id=" + appID,
            "redirect_uri=http%3A%2F%2Flocalhost%3A8080",
        ];
        window.open("https://login.microsoftonline.com/" + tenantID + "/adminconsent?" + param.join("&"));
        //console.log(res);

        var adminConsent = await cookieManager.waitAndGetCookie("admin_consent");

        //console.log(adminConsent);
        return adminConsent == "true";

    }

    /****************************************RICHIESTE DI ALTRI DATI***************************************************************/

    async _getTenantID(accessToken) {
        cookieManager.setCookie("step", messages.tenantID);

        var tenantID = await api.get(
                "https://graph.microsoft.com/v1.0/organization", {
                    headers: {
                        "Authorization": "Bearer " + accessToken
                    }
                }
            )
            .then(response => {
                cookieManager.setCookie("step", messages.tenantIDOk);
                return response.data.value[0].id;
            })
            .catch(e => {
                console.log(e);
                cookieManager.setCookie("step", messages.tenantIDFailed);
                this.serviceAccount.token = null;
                return null;
            });

        //console.log(response);
        this.serviceAccount.options.tenant_id = tenantID;
        return tenantID != null;
    }

    async _createClientSecret(appName, objID, accessToken) {
        var now = new Date();
        var startDate = now.toISOString();
        now.setUTCFullYear(now.getUTCFullYear() + 100);
        var endDate = now.toISOString();

        var body = {
            "passwordCredential": {
                "displayName": appName,
                "endDateTime": endDate,
                "startDateTime": startDate
            }
        };

        var clientSecret = await api.post(

                "https://graph.microsoft.com/v1.0/myorganization/applications/" + objID + "/addPassword",
                JSON.stringify(body), {
                    headers: {
                        "Authorization": "Bearer " + accessToken,
                        "Access-Control-Allow-Origin": window.location.origin,
                        "Access-Control-Allow-Credentials": true,
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then(response => {
                console.log(response);
                cookieManager.setCookie("step", messages.clientSecretOk);
                return response.data.secretText;
            })
            .catch(e => {
                console.log(e);
                cookieManager.setCookie("step", messages.clientSecretFailed);
                return null
            });

        await waitSeconds(30);

        this.serviceAccount.client_secret = clientSecret;
        return clientSecret != null;
        //console.log(response);
    }

    /*
    I permessi sono oggetti del tipo:
        "resourceAppId": "00000003-0000-0000-c000-000000000000", //Rappresenta i servizi principali come graph, sharepoint, exchange....
        "resourceAccess": [{ // è la lista dei permessi
                "id": "06da0dbc-49e2-44d2-8312-53f166ab848a",
                "type": "Scope"
            },
            ...
        }],
        
        
    */
    _extractMissingPermissions(oldServices, newServices) {
        var new_service, new_permission, old_service;
        var s_size = newServices.length,
            p_size;
        for (var s = 0; s < s_size; s++) {
            new_service = newServices[s];
            old_service = oldServices.find(old_service => old_service.resourceAppId == new_service.resourceAppId);
            //Se i vecchi servizi non contengono già il nuovo servizio, lo mantengo e vado avanti
            if (!isValid(old_service)) {
                continue;
            }

            //Se i vecchi servizi contengono il nuovo servizio, controllo se contiene tutti i permessi
            p_size = newServices[s].resourceAccess.length;
            for (var p = 0; p < p_size; p++) {
                new_permission = newServices[s].resourceAccess[p];

                //Se i vecchi permessi del servizio non contengono il nuovo permesso, lo mantengo e vado avanti
                if (!old_service.resourceAccess.some(old_permission => old_permission.id == new_permission.id)) {
                    continue;
                }

                //Se i vecchi permessi del servizio contengono già il nuovo permesso, lo rimuovo
                newServices[s].resourceAccess.splice(p, 1);
                p_size--;
                p--;
            }

            //Se i permessi del nuovo servizio sono vuoti, significa che sono già stati assegnati tutti i permessi di quel servizio, quindi lo rimuovo
            if (newServices[s].resourceAccess.length == 0) {
                newServices.splice(s, 1);
                s_size--;
                s--;
                continue;
            }
        }

        return newServices;
    }
}



/*****************************************************ACQUISIZIONE TOKENS***************************************************************************************/


/***************************************************UTILS**********************************************************************/

//"https://outlook.office365.com/EWS.AccessAsUser.All https://outlook.office365.com/Files.ReadWrite.All https://outlook.office365.com/Group.ReadWrite.All https://outlook.office365.com/User.Read https://outlook.office365.com/.default"



const messages = {
    azureAccess: "I'm logging into the Azure platform.",
    azureAccessOk: "I logged into the Azure platform.",
    azureAccessFailed: "An error occurred while accessing the Azure platform.",

    tenantID: "I'm retrieving the tenant ID.",
    tenantIDOk: "I got the tenant ID.",
    tenantIDFailed: "An error occurred while retrieving your tenant ID.",

    appRegistration: "I am registering the new application.",
    appRegistrationOk: "I have registered the new application.",
    appRegistrationFailed: "An error occurred while registering the new application.",

    appAccess: "I'm logging into the new application.",
    appAccessOk: "I logged into the new application.",
    appAccessFailed: "An error occurred while accessing the new application.",

    clientSecret: "I'm registering the new client secret.",
    clientSecretOk: "I have registered the new client secret.",
    clientSecretFailed: "An error occurred while registering the new client secret.",

    credentialsTest: "I validate the credentials.",
    credentialsTestOk: "I validated the credentials.",
    credentialsTestFailed: "An error occurred while validating your credentials."
}