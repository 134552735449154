var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "dropdown-button place-right" }, [
      _c(
        "a",
        {
          staticClass: "button dropdown-toggle",
          class:
            _vm.$session.get("connectionsStatesRemote")[_vm.pc.id_remote] ==
            true
              ? "secondary"
              : "bg-gray fg-black disabled",
          attrs: { title: _vm.$t("Open a Remote connection") },
        },
        [
          _c("div", { staticClass: "text-left" }, [
            _vm._v(
              " " +
                _vm._s(_vm.pc.id_remote.replace(/(\d{3})(?=\d)/g, "$1 ")) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "ul",
        {
          staticClass: "d-menu border place-right text-left",
          attrs: { "data-role": "dropdown" },
        },
        [
          _c("li", { staticClass: "text-bold" }, [
            _c(
              "a",
              {
                class:
                  _vm.$session.get("connectionsStatesRemote")[
                    _vm.pc.id_remote
                  ] == true
                    ? "secondary"
                    : "fg-gray disabled",
                on: {
                  click: function ($event) {
                    return _vm.$root.getOTP(_vm.pc.id)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("Open connection to")) +
                    " " +
                    _vm._s(_vm.pc.id_remote.replace(/(\d{3})(?=\d)/g, "$1 ")) +
                    " "
                ),
              ]
            ),
          ]),
          _c("li", { staticClass: "divider" }),
          _c("li", [
            _c(
              "a",
              {
                class:
                  _vm.$session.get("connectionsStatesRemote")[
                    _vm.pc.id_remote
                  ] == true
                    ? "fg-dark"
                    : "fg-gray disabled",
              },
              [
                _c("div", { staticClass: "text-left" }, [
                  _vm._v(" " + _vm._s(_vm.$t("Download and connect")) + " "),
                ]),
              ]
            ),
          ]),
          _c("li", [
            _c(
              "a",
              {
                class:
                  _vm.$session.get("connectionsStatesRemote")[
                    _vm.pc.id_remote
                  ] == true
                    ? "fg-dark"
                    : "fg-gray disabled",
              },
              [
                _c("div", { staticClass: "text-left" }, [
                  _vm._v(" " + _vm._s(_vm.$t("Restart Application")) + " "),
                ]),
              ]
            ),
          ]),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "#" } }, [
                _vm._v(" " + _vm._s(_vm.$t("Update")) + " "),
              ]),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }