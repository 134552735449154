var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100 overflow" },
    [
      _c("Navigation", { attrs: { pageName: "storage" } }),
      _c("iframe", {
        staticClass: "w-100 h-100",
        attrs: {
          src: "https://pasair85.iperiusstorage.com/login",
          title: "W3Schools Free Online Web Tutorials",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }