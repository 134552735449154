import { PC_MESSAGES, USER_MESSAGES } from '../../public/assets/js/messages';
import { isValid, waitSeconds } from '../../public/assets/js/utilitiesmodule';
import { useWebSocket } from '@vueuse/core';
import { WebSocketInfo } from './WebSocketInfo';
/*
Questo oggetto viene instanziato in main.js chiamando checkSocket().
Dopo una chiamata, a seconda dei casi, 
- si attende la risposta e la ritorna;
- ritorna "true"
*/
export class WebSocketClient {

    // auth è l'oggetto restituito dalla chiamata di login
    //https://vueuse.org/core/useWebSocket/
    constructor(auth, emitFunction, translateFunction) {
        this.responses = {}; // oggetto tipo { [messaggio chiamata socket] : [risposta alla chiamata]}
        this.lastMessage = "";
        this.wait = false;

        var idLicense = auth.id_master_account;
        var token = auth.access_token;

        this.url = process.env.VUE_APP_WEBSOCKET + "?cll=C_" + idLicense; //"ws://192.168.0.91:4003/enterprise/websocket?cll=C_" + idLicense; //"ws://192.168.0.254:4003/enterprise/websocket?cll=C_" + idLicense;  
        this.emitFunction = emitFunction;
        this.translateFunction = translateFunction;
        this.idPC = null;
        this.error = false;
        this.websocket = null;

        let self = this;

        this.config = {
            autoReconnect: {
                retries: 50,
                delay: 5000,
                onFailed: function() {
                    self.websocket = null;
                    console.log('Failed to connect WebSocket ');
                    this.checkConnection();

                }
            },
            heartbeat: {
                message: 'ping',
                interval: 20000,
                pongTimeout: 20000
            },
            autoClose: false,
            onConnected: function(ws) {

                self.responses["CHECKCONNECTION"] = true;
                self.websocket = ws;
                ws.send(JSON.stringify({ "msg": "track", "token": token }));
                self.emitFunction("socketEventConnected");
            },
            onDisconnected: function(ws) {
                console.log("Disconnected");
                console.log(ws)

                self.emitFunction("socketEventDisconnected");
                self.responses["CHECKCONNECTION"] = false;
                self.websocket = null;
                var info;
                //Popolo tutte le richieste in pending con il valore di default
                Object.keys(self.responses).forEach(k => {
                    if (self.responses[k] == null) {
                        info = WebSocketInfo.buildInfo(k, false, false, { text: PC_MESSAGES.NOT_CONNECTED, msgType: "ERROR", msgArgs: null });
                        if (info == null) info = { result: false };

                        self.responses[k] = info;
                    }
                });
            },
            onError: function(ws, e) {
                console.log("Errore");
                console.log(e);

                self.error = true;
                self.websocket = null;
            },
            onMessage: (ws, event) => self.onMessageCallback(event.data)
        }
    }

    //Metodo void
    //La risposta verrà restituita emettendo
    //    - "socketEventConnected" se la risposta è positiva. Verrà emesso se la connessione è già attiva o al momento della connessione
    //    - "socketEventDisconnected" se la risposta è negativa. Verrà emesso se la connessione non va a buon fine
    checkConnection() {
        let self = this;
        var connected = self.websocket != null && self.websocket.readyState == 1;

        if (connected) {
            self.emitFunction("socketEventConnected");
            return;
        }

        try {
            useWebSocket(this.url, this.config);
        } catch (e) {
            console.log(e);
            this.emitFunction("socketEventDisconnected");
        }
    }

    /*  //Caso 1 : la connessione non è stata mai istanziata o è chiusa -> istanzio una nuova connessione
     if (self.websocket == null || self.websocket.readyState == 3) { // 3 = closed
         this.emitFunction("Connection is close|ERROR");
         if (create)
             connected = await this.connect() != null;
         else
             connected = false;
         //Caso 2 : la connessione è in chiusura -> attendo (al massimo 60 secondi) che si chiuda e istanzio una nuova connessione       
     } else if (self.websocket.readyState == 2) { // 2 = closing

         while (self.websocket.readyState != 3) {
             this.emitFunction("Connection is closing|ERROR");
             await waitSeconds(1);
             timer++;
             if (timer == 60) {
                 console.log("TIMEOUT");
                 connected = false;
                 break;
             }
         }
         this.emitFunction("Connection is close|ERROR");
         connected = await this.checkConnection(create, false);
         // Caso 3 : la connessione sta partendo -> attendo (al massimo 60 secondi) che si stabilizzi  
     } else if (self.websocket.readyState == 0) { //connecting

         while (self.websocket.readyState != 1) {
             this.emitFunction("Connecting|INFO mif-loop");

             await waitSeconds(1);
             timer++;
             if (timer == 60) {
                 console.log("TIMEOUT");
                 connected = false;
                 break;
             }
         }
         this.emitFunction("Connection established|SUCCESS wifi-connect");

         connected = true;
         //Caso 3 : la connessione è partita -> ritorno true 
     } else if (self.websocket.readyState == 1) return true;
     // Caso 4 : in tutti gli altri casi, ritorno false
     else return false;

     if (insertInResposes) this.responses["CHECKCONNECTION"] = connected;

     return connected; */

    //}

    async call(message, arg1, arg2, arg3) {
        //console.log("Chiamata WebSocket " + message + " " + (arg1 != undefined ? arg1 : "") + " " + (arg2 != undefined ? arg2 : ""));

        let self = this;
        var upperCaseMessage = message.toUpperCase();
        var seconds = 0;
        var info;

        if (this.websocket == null)
            return WebSocketInfo.buildInfo(upperCaseMessage, false, false, { text: PC_MESSAGES.NOT_CONNECTED, msgType: "ERROR", msgArgs: null });

        self.responses[upperCaseMessage] = null;
        self.wait = false;
        self.lastMessage = upperCaseMessage;

        var request;

        //Step 1 : si popolano request e info
        //Caso 1 : subscribe: invio la richiesta e ritorno true
        if (message == "subscribe_pc") {
            this.idPC = arg1;
            request = {
                "msg_type": "msg",
                "msg": "subscribe_pc",
                "pc_id": arg1
            };

            info = WebSocketInfo.buildInfo(upperCaseMessage, true, false);

        } else if (message == "unsubscribe_pc") { //Caso 2 : unsubscribe
            request = {
                "msg_type": "msg",
                "msg": "unsubscribe_pc",
                "pc_id": arg1
            };

            info = {
                waitResponse: true, //attendo {msg: 'unsubscribe_pc', msg_type: 'request_status', result: 'true'}

            }

        } else if (message == "is_online") {
            //console.log(arg1);
            if (typeof(arg1) == "string")
                arg1 = [arg1];
            request = {
                //"msg_type": "msg",
                "msg": "is_online",
                "pc_ids": arg1
            };

            info = WebSocketInfo.buildInfo(upperCaseMessage, true, false);
        } else { //CASO 3 : altro tipo di chiamata //MA: ho inserito una modifica per passare l'arg1 al recipient ID da "fuori" il computer
            request = {
                "msg_type": "command",
                "command": message,
                "recipient": "agent",
                "recipient_id": this.idPC??arg1
            };

            info = WebSocketInfo.buildInfo(upperCaseMessage, true, false, null, arg1, arg2, arg3);
            request.command = message;

            if (info.data) request.data = info.data;
        }

        //Step 2 : si avvia la chiamata
        this.websocket.send(JSON.stringify(request));

        if (!info.waitResponse) return info;

        //Step 3 : Per un massimo di 60 secondi, attendo la risposta
        for (seconds = 1; seconds <= 60; seconds++) {

            //Caso 1 : se la risposta è arrivata, la ritorno
            if (self.responses[upperCaseMessage] != null) {
                return self.responses[upperCaseMessage];
            }

            //Caso 2 : disconnesso -> modifico info e lo ritorno
            if (self.websocket.readyState != 1) {
                info = WebSocketInfo.buildInfo(upperCaseMessage, false, false);
                if (info == null) info = {};
                info.message = { text: USER_MESSAGES.NOT_CONNECTED, msgType: "ERROR", msgArgs: null };
                return info;
            }

            //Caso 3 : ho ricevuto wait -> si sta processando la richiesta, quindi posso resettare seconds e ricominciare ad aspettare al massimo 60 secondi 
            if (self.wait) {
                seconds = 1;
                self.wait = false;
            }

            self = this;
            await waitSeconds(1);

        }

        info = WebSocketInfo.buildInfo(upperCaseMessage, false, false, { text: PC_MESSAGES.NO_RESPONSE + " " + upperCaseMessage, msgType: "ERROR", msgArgs: null });
        return info;

    }

    /**
     Nell'oggetto di risposta di una chiamata socket, prendo in considerazione il campo msg_type
     {
        msg_type : "data" / message / request_status / command_status / command,
        message / msg/ command
        data / result
        recipient = "multiple"
        id_pc -> solo se msg_type == "data" o message
    }
    */
    async onMessageCallback(data) {
        if (data == "pong") {
            this.lastPong = Date.now();
            return; //TODO:GESTIRE LA CONNESSIONE DA QUI!!!
        }

        data = JSON.parse(data);

        if (!["message", "request_status", "command_status"].includes(data.msg_type) && !["rData", "rtData", "jData", "jobOrder", "is_online", "wuData"].includes(data.message)) {
            console.debug(data);
        }

        switch (data.msg_type) {
            //Risposta dal server
            case "request_status":
                console.debug(data);
                this.onMessageCallback_RequestStatus(data.msg.trim(), data.result);

                break;

                //Risposta dal server
            case "command_status": // non devo far nulla se data.result == true
                this.onMessageCallback_CommandStatus(data.command.trim(), data.result);
                break;

                //Risposta dall'agent
                //{
                //     msg_type = "data"
                //     message 
                //     parent_command 

                //     data 
                //     recipient 
                //     recipient_id 
                //     session_id 
                // }
            case "data":
                if (data.parent_command != "StartSendInfo")
                    console.log(data.parent_command + " -> " + data.message);
                if (data.pc_id == this.idPC) {
                    this.onMessageCallback_Data(data.parent_command, data.message.trim(), data.data);
                }
                break;

                // {
                //     msg_type = "message"
                //     message = {
                //          text
                //          msgArgs
                //          msgType
                //        }
                //     recipient 
                //     recipient_id 
                //     scope 
                //     parent_command 
                // }
            case "message": // messaggi in realtime
                if (data.pc_id == this.idPC) {
                    console.log(data.message);
                    this.onMessageCallback_Message(data.parent_command, data.message);
                }
                break;
            case "cmd":
                this.onMessageCallback_CommandPrompt(data.parent_command, data.message);
                break;
        }
    }

    async onMessageCallback_Data(parent_command, message, data) {

        if (!isValid(parent_command)) {
            alert("parent_command di " + message + " è undefined");
            return;
        }

        parent_command = parent_command.toUpperCase();

        if (parent_command != "STARTSENDINFO") {
            this.responses[parent_command] = WebSocketInfo.buildInfo(parent_command, false, true, null, data);
            return;
        }

        switch (message) {

            case "rtData": //dati in realtime che verranno catturati da Computer.js
                this.emitFunction("addRealTimeToPage", data);
                break;

            case "jData": // jobs in realtime
                //Verrà catturata da Computer.js
                //data è una mappa in cui le chiavi sono gli id dei job e i valori sono i job
                //https://stackoverflow.com/questions/4215737/how-to-convert-an-array-into-an-object
                this.emitFunction("addBackupsToPage", data);
                break;

            case "jobOrder":
                this.emitFunction("addIDsOrder", data);
                break;

            case "rData": // restore in realtime
                // Verrà catturata da Computer.js
                this.emitFunction("addRestoreToPage", data);
                break;

            case "wuData": // restore in realtime
                // Verrà catturata da Computer.js
                this.emitFunction("addWUToPage", data);
                break;

        }
    }


    onMessageCallback_CommandPrompt(parent_command,message) {
        if (parent_command == "closecmd") {
            this.emitFunction("closeCMD");
        }
        if(parent_command=="runcmd"){
            this.emitFunction("runCMD", message.text);
        }else{
            this.responses[parent_command.toUpperCase()] = {result:message}
        }

        /* if (message.msgType == "WAIT") {
            this.wait = true;
        } */
    }

    onMessageCallback_Message(command, message) {

        //CASO 1 : Il messaggio è la risposta ad un comando inviato
        if (isValid(command)) {
            this.onMessageCallback_Message_WithCommand(message, command);
            return;
        }

        this.emitFunction("realtimeMessages", message);

        if (message.msgType == "WAIT") {
            this.wait = true;
        }

    }

    onMessageCallback_Message_WithCommand(message, command) {
        var ok = message.msgType != "ERROR";
        var uppercaseCommand = command.toUpperCase();
        this.responses[uppercaseCommand] = WebSocketInfo.buildInfo(uppercaseCommand, false, ok, message);

    }

    onMessageCallback_CommandStatus(command, result) {

        var uppercaseCommand = command.toUpperCase();
        //Caso 1 : command è un comando che attende solamente una riposta di tipo command_status.
        // result determina lo stato della connessione.
        // Visualizzo un messaggio solo se non si è connessi
        if (["STARTSENDINFO", "STOPSENDINFO"].includes(uppercaseCommand)) {
            this.responses[uppercaseCommand] = {
                result: result == "true"
            };
            return;
        }

        //Caso 2 : Se command_status == "false", non si è connessi, quindi
        if (result == "false") {
            //prendo l'oggetto di default, poiché contiene il result di default
            var info = WebSocketInfo.buildInfo(uppercaseCommand, false, false, { text: PC_MESSAGES.NOT_CONNECTED, msgType: "ERROR", msgArgs: null });
            // ne modifico i campi relativi allo stato della connessione
            this.responses[uppercaseCommand] = info;
        }
    }

    async onMessageCallback_RequestStatus(message, result) {
        if (message == "track") {
            console.log(result);
            return;

        }
        message = message.toUpperCase();
        switch (message) {
            case "IS_ONLINE": //array del tipo [{idpc:true/false}] //TODO Andrea deve sistemare
                this.responses[message] = WebSocketInfo.buildInfo(message, false, true, null, result);
                return;
            case "SUBSCRIBE_PC":
            case "UNSUBSCRIBE_PC":
                this.responses[message] = {
                    result: result == "true"
                };
                return;
        }

        //caso non gestito
        alert("onMessageCallback_RequestStatus di " + message + "non gestito");
        return null;
    }

}