<template>
    <div v-on:keyup.esc="$root.$emit('CLOSERESTOREDIALOG')"
        class="dialog overflow w-100-sm w-100-md w-75-lg  w-50-xl" v-cloak> 

        <DialogTitle :title="$t('MS Exchange Restore')" :defaultTitle="$t('MS Exchange restore')"
            :subTitle="$t('MS Exchange Backups')" @closeDialog="$root.$emit('CLOSERESTOREDIALOG')" />

        <!---------------------SERVICE ACCOUNT DELLA SORGENTE------------------------------------------------->
        <div v-if="step == STEP_SELECTBACKUPCOPY" class="dialog-content">

            <div v-if="isManual" class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t("Choose source type") }}</div>
            </div>

            <div v-if="isManual" class="form-group">
                <ul data-role="listview" data-view="icons" data-select-node="true" data-selectable="false">
                    <!--Le destinazioni del backup diventano le sorgenti del restore-->
                    <li v-for="sourceType in platformsTypes[restore.type].backupDestinationTypes"
                        @click="setSourceType(sourceType)" :data-icon="platformsTypes[sourceType].icon"
                        :data-caption="platformsTypes[sourceType].name" :key="sourceType" />
                </ul>
            </div>

            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <SARepoSelect v-if="![-1, $root.PlatformsTypesEnum.FileFolder].includes(restore.source_type)"
                :type=restore.source_type :idSA='restore.source.id_service_account' :idRepo=null
                @sendSATestResult="sendSATestResult" />

            <!-------------------SCELTA PERCORSO CARTELLA COPIA BACKUP----------------------------------------------->
            <SelectedResourcesLayout v-if="isManual" :list="sourceData.selectedNodes" :singleItem="true"
                :canCancel="false" :buttonText="$t('Select the folder of a backup copy')"
                :isDisabled="restore.source_type != $root.PlatformsTypesEnum.FileFolder && sourceData.client == null"
                :heightPercentage="30" @clickFunction="toggleFileSystemSidebar('CHOOSEBACKUPCOPY')" />

        </div>

        <!--INFORMAZIONI INIZIALI SUL RESTORE-->
        <div v-if="step == STEP_SELECTRESTOREOPTIONS" class="dialog-content">

            <!-----------------------------RESTORETYPE: COSA SI VUOLE RECUPERARE?--------------------------------->
            <div class="form-group">
                <h6 class="text-bold">{{ $t("What is to be recovered?") }}:</h6>
                <select class="select w-100" v-model="restore.restoreType">
                    <option :value="1">{{ $t("Recreate full copy from backup") }}</option>
                    <option :value="2">{{ $t("Recreate a specific copy from backup") }}</option>
                </select>
            </div>

            <!-------------------RESTORE DA UN BACKUP - SCELTA DELLA DATA DALLA QUALE ESEGUIRE IL RESTORE----------------------------->
            <div class="form-group">

                <h6 class="text-bold">{{ $t("Select a copy") }}</h6>
                <div class="overflow">
                    <div v-for="iteration in fileConfig.iterations" :key="iteration.index" class="d-flex mb-4">
                        <input name="iter" type='radio' data-role='radio' class="mr-5"
                            @click="onIterationChanged(iteration)">
                        <div class="pr-4">
                            <div class="d-flex flex-justify-between w-100 pr-3">
                                <div>
                                    <span class='badge inline bg-cobalt fg-white p-1'>
                                        {{ platformsTypes[iteration.sourceType].name }} </span>
                                    <span class="mif-arrow-right mx-1" />
                                    <span class='badge inline bg-cobalt fg-white p-1'>
                                        {{ platformsTypes[iteration.destinationType].name }} </span>
                                </div>

                                <div class="text-bold"><label class="mr-2">{{ $t("Copy number")
                                        }}:</label>{{ (iteration.index + 1) + " / " + (iteration.nCopies + 1) }}</div>
                            </div>
                            <div>
                                <small><b>{{ $t("Backup folder") }} : </b> {{ iteration.iterationFolder }}</small>
                            </div>
                            <small class="text-bold"><label class="mr-2">{{ $t("Start date")
                                    }}:</label>{{ iteration.lastStart | utcAsLocal | moment("MM/DD/YY hh:mm A")
                                }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div v-show="step == STEP_DESTINATION" class="dialog-content">
            <div class="row h6 text-bold m-4">
                <div class="cell-12">{{ $t('Configure destination') }}</div>
            </div>

            <!------------------------------DESTINAZIONE : SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare sendSATestResult-->
            <SARepoSelect :type=restore.type :idSA=restore.destination_idSA :idRepo=null
                @sendSATestResult="sendSATestResult" />
            <!--DESTINAZIONE : SCELTA DEGLI ACCOUNT DA RIPRISTINARE-->
            <SelectedResourcesLayout v-if="destinationData.client != null" :list="destinationData.selectedNodes"
                :singleItem="false" :canCancel="true" :buttonText="$t('Select the mailboxes to restore')"
                :heightPercentage="0" @clickFunction="toggleFileSystemSidebar('SELECTMAILBOXES')" />

            <div v-show="destinationData.client != null" class="form-group row pb-5 w-100">
                <label>{{ $t("Custom folder") }}:</label>
                <input data-role="input" class="metro-input" type="text" v-model="restore.destination_customFolder"
                    :placeholder="$t('Enter the name of the customised folder')" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>
        </div>


        <!-------------------------------TEST ACCOUNT----------------------------------------->
        <div class="dialog-actions flex-justify-end">
            <div v-if="waiting == -1">
                <span class="mif-spinner2 fg-primary ani-spin ml-5" />
            </div>

            <input v-if="step != 1 || restore.source_type != $root.PlatformsTypesEnum.FileFolder"
                :disabled="step == STEP_SELECTBACKUPCOPY" type="button" v-on:click="setStep(step - 1)"
                :value="$t('Prev')" class="button primary ml-2" />

            <input type="button" @click="setStep(step + 1)" :value="$t('Next')" class="button primary ml-2"
                :disabled="!enableNextButton" />

            <input type="button" @click="startRestore" :value="$t('Start')" class="button primary ml-2"
                :disabled="step != STEP_DESTINATION || destinationData.selectedNodes.length == 0 || restore.destination_customFolder == ''" />
        </div>

    </div>
</template>
<script>

import DialogTitle from "../../utils/DialogTitle";
import { getSeparator, isValid, platformsTypes } from "../../../../public/assets/js/utilitiesmodule";
import SARepoSelect from "../../utils/SARepoSelect.vue";
import moment from 'moment'
import RestoreLastResult from "../../utils/RestoreLastResult";
import SelectedResourcesLayout from "../../utils/SelectedResourcesLayout";
import { ClientTreeNode, FileSystemSidebarConfig } from "../../../models/TreeNode";

/*
{
    id: "",
    idBackup: isValid(backup) ? backup.id : '',
    backupName: isValid(backup) ? backup.name : '',
    version: '',
    type: type,
    type_name: platformsTypes[type].name,

    restoreType: -1,
    index: 0,

    source_idSA: isValid(backup) ? backup.destinations[0].id_service_account : -2,
    source_path: "",
    source_type: isValid(backup) ? backup.destinations[0].type : 8,
    source_type_name: isValid(backup) ? backup.destinations[0].type_name : platformsTypes[8].name,

    destination_idSA: isValid(backup) ? backup.sources[0].id_service_account : -2,
    destination_accounts: "",
    destination_customFolder: ""

};

*/
export default {
    name: "ExchangeManualRestore",
    props: {
        restore: {
            type: Object,
            required: true
        }
    },
    components: {
        "DialogTitle": DialogTitle,
        "RestoreLastResult": RestoreLastResult,
        SARepoSelect,
        SelectedResourcesLayout
    },
    filters: {},

    data() {
        return {
            step: 0,
            STEP_SELECTBACKUPCOPY: 0,
            STEP_SELECTRESTOREOPTIONS: 1,
            STEP_DESTINATION: 2,

            RESTORETYPE_SELECTSINGLEITEMS: 0,
            RESTORETYPE_BUILDFULLCOPY: 1,
            RESTORETYPE_BUILDSPECIFICCOPY: 2,
            sourceData: {
                serviceAccount: null,
                client: null,
                filteredServiceAccounts: [],
                selectedNodes: [], //appoggio per la copia di backup scelta
            },

            destinationData: {
                serviceAccount: null,
                client: null,
                filteredServiceAccounts: [],
                filteredRepositories: [],
                selectedNodes: [],
                buttonText: null
            },

            waiting: 0,

            fileConfig: null,

            isManual: true,

            str1: "Choose account from credential locker or use Custom credentials",
            platformsTypes: null,
            microsoftTree: null

        }
    },
    computed: {
        iterationFolder: function () {
            if (this.sourceData.selectedNodes.length == 1)
                return this.sourceData.selectedNodes[0];
            return null;
        },
        enableNextButton: function () {

            switch (this.step) {
                case this.STEP_SELECTBACKUPCOPY:
                    //Nel primo step se il restore è manuale sono obbligato a:
                    // 1 - Selezionare il tipo della sorgente
                    // 2 - Se il tipo della sorgente necessita di un service account, selezionare un serviceaccount e testarlo
                    // 3 - selezionare una cartella di una copia
                    if (
                        this.isManual &&
                        this.restore.source_type != -1 &&
                        (this.restore.source_type == this.$root.PlatformsTypesEnum.FileFolder || this.sourceData.client != null) &&
                        this.iterationFolder != null
                    ) {
                        return true;
                    }

                    //Nel primo step, se il restore è automatico sono obbligato a selezionare un service account e a testarlo se necessario
                    if (
                        !this.isManual &&
                        (this.restore.source_type == this.$root.PlatformsTypesEnum.FileFolder || this.sourceData.client != null)
                    ) {
                        return true;
                    }
                    return false;

                case this.STEP_SELECTRESTOREOPTIONS:

                    return this.restore.restoreType != -1 && this.restore.index != -1;

                case this.STEP_DESTINATION:
                    return false;
            }

            return true;
        },
    },
    created: async function () {
        this.step = 0;
        this.platformsTypes = platformsTypes;
        this.isManual = !isValid(this.restore.idBackup);
        //Se è un restore automatico da una sorgente filefolder, non mi serve nulla del primo step perchè non ho bisogno del serviceaccount
        this.step = !this.isManual && this.restore.source_type == this.$root.PlatformsTypesEnum.FileFolder
            ? this.STEP_SELECTRESTOREOPTIONS
            : this.STEP_SELECTBACKUPCOPY;

        if (!this.isManual) {
            await this.buildFileConfigFromIDBackup();
        }
    },
    methods: {
        /************************************STEP 0 : SCELTA DELLA SORGENTE*******************************************************************************/
        setSourceType(type) {
            this.restore.source_type = type;
            this.restore.source_idSA = -2;
            this.sourceData.client = null;
            this.sourceData.serviceAccount = null;
        },

        async sendSATestResult(testResult) {
            let self = this;

            if (self.step == 0) {
                self.restore.source_idSA = testResult.serviceAccount.id;
                self.sourceData.serviceAccount = testResult.serviceAccount;
                self.sourceData.client = testResult.client;
                //Finito il test, resetto accountSessionId se il restore è di tipo ftp o network
                //if ([2, $root.PlatformsTypesEnum.Network].includes(self.restore.source_type))
                //    self.sourceData.client.serviceAccount.options.accountSessionId = "";
                return;
            }

            if (self.step == 2) {
                self.restore.destination_idSA = testResult.serviceAccount.id;
                self.destinationData.serviceAccount = testResult.serviceAccount;
                self.destinationData.client = testResult.client;
                //Finito il test, resetto accountSessionId se la destinazone del restore è di tipo ftp o network
                //if ([2, $root.PlatformsTypesEnum.Network].includes(self.restore.type))
                //    self.destinationData.client.serviceAccount.options.accountSessionId = "";
                //self.toggleFileSystemSidebar();
                return;
            }
        },

        /************************************STEP 1 : SCELTA DELLA MODALITà DI RESTORE*******************************************************************************/

        /**
         * {
         *  id:,
         * currentIndex
         * iterations : // array di : 
         *     {
         *         index:
         *         name:
         *         result:
         *         lastStart
         *         iterationFolder
         *         nCopies
         *         sourceType
         *         destinationType
         *     }
         * }
         * 
         */
        async buildFileConfigFromPath() {
            try {



                this.$root.$emit("closeFileSystemSidebar");
                this.waiting = -1;
                var id_service_account = "";
                if (isValid(this.sourceData.serviceAccount)) id_service_account = this.sourceData.serviceAccount.id;
                try {
                    this.fileConfig = await this.$root.socket("getconfigfileforrestorefile", id_service_account, this.iterationFolder.path);
                    if (isValid(this.fileConfig)) {
                        this.microsoftTree = await this.$root.socket("getTreeFromConfigFile", this.iterationFolder.path, this.restore.type);

                        this.restore.idBackup = this.fileConfig.id;
                        this.restore.backupName = this.fileConfig.iterations[this.fileConfig.currentIndex].name; //assegno il nome del job al momento dell'ultima iterazione eseguita
                        this.setStep(1);
                        this.waiting = 1;
                        return;
                    }
                } catch (ex) {
                    console.log(ex);
                }
                this.sourceData.selectedNodes.pop();
                this.waiting = 0;

            } catch (e) {

                alert(e);
            }
        },

        async buildFileConfigFromIDBackup() {
            //Step 1 : Prendo i logs del job
            var logs = await this.$root.api("GETBACKUPLOGS", this.restore.idBackup);
            //Step 2 : Ordino i log dal più recente al meno recente
            logs.sort((a, b) => {
                var dateA = this.$moment(a.dt_start_utc.substr(0, 8) + "T" + a.dt_start_utc.substr(8), "YYYYMMDD HH:mm:ss");
                var dateB = this.$moment(b.dt_start_utc.substr(0, 8) + "T" + b.dt_start_utc.substr(8), "YYYYMMDD HH:mm:ss");
                if (dateA > dateB)
                    return -1;
                return 1;
            });
            //Step 3 : Costruisco il file di config prendendo i [nCopies] logs più recenti

            var nCopies = 1; //All'inizio non conosco il numero di copie, quidni lo imposto ad 1 per far partire il for 
            var currentConfiguration;
            var currentIterationFolder;
            var slash;
            for (var c = 0; c < nCopies; c++) {
                currentConfiguration = await this.$root.api("GETBACKUPLOGCONFIGURATION", logs[c].id);
                //Al primo log,
                if (c == 0) {
                    //inizio a costruire il fileConfig
                    this.fileConfig = {
                        id: this.restore.idBackup,
                        currentIndex: logs[0].backup_number,
                        nCopies: parseInt(currentConfiguration.sources[0].options.nCopies.toString()),
                        iterations: []
                    };
                    slash = getSeparator(currentConfiguration.destinations[0].path);
                    //imposto il numero di copie
                    nCopies = ["F", "U"].includes(currentConfiguration.sources[0].options.backupType)
                        ? this.fileConfig.nCopies
                        : this.fileConfig.nCopies + 1;
                    //popolo dei dati del restore che prima non conoscevo
                    //this.restore.source.paths.push(new ClientTreeNode(null, null, currentConfiguration.destinations[0].path + slash + this.restore.backupName, getNodeType(currentConfiguration.destinations[0].type, false, false)));

                    //this.sourceData.selectedNodes.push(this.restore.source.paths[0]);
                }

                if (logs[c].backup_number == 0)
                    currentIterationFolder = currentConfiguration.destinations[0].path + slash + this.restore.backupName + slash + "FULL_" + this.restore.idBackup;
                else
                    currentIterationFolder = currentConfiguration.destinations[0].path + slash + this.restore.backupName + slash + logs[c].backup_number.toString().padStart(3, "0") + "_" + this.restore.idBackup;


                if (c == 0) {
                    this.microsoftTree = await this.$root.socket("getTreeFromConfigFile", currentIterationFolder, this.restore.type);
                }

                this.fileConfig.iterations.push({
                    index: logs[c].backup_number,
                    name: this.restore.backupName,
                    result: logs[c].result == 1,
                    lastStart: this.$moment(logs[c].dt_start_utc.substr(0, 8) + "T" + logs[c].dt_start_utc.substr(8), "YYYYMMDD HH:mm:ss"),
                    iterationFolder: currentIterationFolder,
                    nCopies: parseInt(currentConfiguration.sources[0].options.nCopies.toString()),
                    sourceType: currentConfiguration.sources[0].type,
                    destinationType: currentConfiguration.destinations[0].type
                });
            }
        },

        onIterationChanged(iteration) {
            this.restore.index = iteration.index;
            this.restore.source_iterationFolder = iteration.iterationFolder;
            this.restore.backupLastStart = iteration.lastStart;
        },

        /*****************************STEP 2 : SCELTA DEGLI ACCOUNT DA RIPRISTINARE***********************************************************************/
        async startRestore() {
            this.$root.$emit("closeFileSystemSidebar");
            let self = this;
            //self.restore.source_iterationFolder = this.iterationFolder.path;
            self.restore.destination_accounts = this.destinationData.selectedNodes.map(m => m.resID).join("|");

            var restoreStarted = await self.$root.socket("runexchangerestore", this.restore);

            if (!restoreStarted)
                return;

            this.$root.$emit('CLOSERESTOREDIALOG');

        },

        /*****************************METODI PER LA FILESYSTEMSIDEBAR***********************************************************************/
        async toggleFileSystemSidebar(reason) {

            switch (reason) {
                case "CHOOSEBACKUPCOPY":
                    this.$root.$emit("closeFileSystemSidebar");
                    this.$root.$emit(
                        "toggleFileSystemSidebar",
                        this.sourceData.selectedNodes,
                        await this._getConfig(reason)
                    );
                    break;

                case "SELECTMAILBOXES":
                    this.$root.$emit("closeFileSystemSidebar");
                    this.$root.$emit(
                        "toggleFileSystemSidebar",
                        this.destinationData.selectedNodes,
                        await this._getConfig(reason)
                    );

            }

        },

        async _getConfig(reason) {

            switch (reason) {
                case "CHOOSEBACKUPCOPY":
                    //Si deve scegliere la cartella di backup
                    //if (this.restore.source_paths.length == 0 || [1, 2].includes(this.restore.restoreType)) {
                    if (this.restore.source_type == this.$root.PlatformsTypesEnum.Network && this.sourceData.client.serviceAccount.options.accountSessionId == "") {
                        this.sourceData.client.serviceAccount.options.accountSessionId = await this.$root.socket("connectnetwork", this.sourceData.client.serviceAccount);
                    }
                    return {
                        type: this.restore.source_type,
                        includeFiles: false,
                        multipleChoice: false,
                        client: this.sourceData.client,
                        rootPath: this.restore.source_type == this.$root.PlatformsTypesEnum.Network ? this.sourceData.client.serviceAccount.options.path : ""
                    };

                case "SELECTMAILBOXES":
                    return new FileSystemSidebarConfig(
                        this.restore.type,
                        false,
                        true,//elementi multipli
                        this.destinationData.client,
                        [this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.restore.type) ? this.destinationData.client.serviceAccount.options.path : "",
                        false,
                        this.microsoftTree
                    );
            }

            return null;
        },

        /*********************METODI***********************************************/

        /**
                 * 0 : informazioni iniziali : service account + restoretype
                 * 1 : selezione del percorso / percorsi + scelta data
                 * 2 : filtri
                 * 3 : esclusioni
                 * 4 : compressione zip
                 * 5 : destinazione
                 */
        setStep(nStep) {
            this.$root.$emit("closeFileSystemSidebar");

            switch (nStep) {
                case 1:

                    // RESTORE MANUALE + NO FILE CONFIG
                    if (this.isManual && !isValid(this.fileConfig)) {
                        this.buildFileConfigFromPath();
                        return; // lo step verrà impostato solo al momento in cui fileConfig sarà valido
                    }/**/


                    // RESTORE MANUALE + FILE CONFIG + BUILD ITERATION
                    if (this.isManual && isValid(this.fileConfig) && this.restore.restoreType != 0) {
                        this.step = 1; // lo imposto prima, perchè nel seguente metodo verrà considetato lo step corrente
                        return;
                    }

                    // RESTORE AUTOMATICO + FILE CONFIG + BUILD ITERATION
                    if (!this.isManual && isValid(this.fileConfig) && this.restore.restoreType != 0) {
                        this.step = 1;
                        return;
                    }

                    break;
            }

            this.step = nStep;

        },

        getMetroIcon(type, path) {
            return getMetroIcon(type, path);
        },
        removeNode(node, inSource) {
            var index;
            if (inSource) {
                index = this.sourceData.selectedNodes.findIndex(res => res.path == node.path);
                this.sourceData.selectedNodes.splice(index, 1);
            } else {
                index = this.destinationData.selectedNodes.findIndex(res => res.path == node.path);
                this.this.destinationData.selectedNodes.splice(index, 1);
            }
            this.$root.$emit("uncheckNode", node);
        },


    }
}
</script>