var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass: "d-flex w-100 bg-white bd-gray border-bottom",
      attrs: { "data-role": "appbar", "data-expand-point": "xl" },
    },
    [
      _c(
        "div",
        { staticClass: "app-bar-container w-100 flex-justify-between" },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-align-center" },
            [
              _c("router-link", { attrs: { to: "/dashboard" } }, [
                _c("img", {
                  staticClass: "pl-4 pt-1",
                  attrs: {
                    src: "/assets/img/brand/iperius_one_v3.png",
                    height: "66",
                    alt: "Iperius One Logo",
                  },
                }),
              ]),
            ],
            1
          ),
          _c("ul", { staticClass: "bg-transparent h-menu" }, [
            _vm.user
              ? _c("li", [
                  _c(
                    "a",
                    { staticClass: "app-bar-item", attrs: { href: "#" } },
                    [
                      _c("span", { staticClass: "mif-flag" }),
                      _c(
                        "span",
                        { staticClass: "badge bg-primary fg-white mt-2 mr-1" },
                        [_vm._v(_vm._s(_vm.events.length))]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-menu border bg-white mt-1",
                      attrs: { "data-role": "dropdown" },
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-justify-end" }, [
                        _c("div", { staticClass: "flat-button" }, [
                          _vm._v(_vm._s(_vm.$t("Set all read"))),
                        ]),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "m-0 p-0" },
                        _vm._l(_vm.events, function (event) {
                          return _c(
                            "li",
                            {
                              key: event.id,
                              staticClass: "text-small",
                              class: {
                                "text-bold": event.is_read,
                                "fg-gray": !event.is_read,
                              },
                            },
                            [
                              _c("a", [
                                _vm._v(_vm._s(event.msg)),
                                _c("span", { staticClass: "mr-3 text-small" }, [
                                  _vm._v(_vm._s(event.subtitle)),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.user
              ? _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-toggle app-bar-item",
                      attrs: { href: "#" },
                    },
                    [
                      _c("label", { staticClass: "pl-4 text-bold" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$session.get("AUTH")?.firstname +
                              " " +
                              _vm.$session.get("AUTH")?.lastname +
                              " (" +
                              _vm.$session.get("AUTH")?.username +
                              ")"
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass: "d-menu border w-100 bg-white",
                      attrs: { id: "userdropdown", "data-role": "dropdown" },
                    },
                    [
                      _c("li", { staticClass: "text-right text-bold" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$parent.$parent.logout()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "mif-bin" }),
                            _vm._v(" " + _vm._s(_vm.$t("Logout"))),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("li", [_c("locale-changer")], 1),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }