<template>
    <span>{{truncatedText}}</span>
</template>
<script>
export default{
    name:'TruncatedText',
    props:{
        text:{
            type:String,
            required:true
        },
        nChars:{
            type:Number,
            required:true
        }
    },
    data(){return{truncatedText:''}},
    created(){
        if(this.text.length<=this.nChars) this.truncatedText = this.text;
        else this.truncatedText = this.text.substr(0, this.nChars) + '...';
    }
}
</script>
