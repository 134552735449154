<template>
    <div class="w-100 overflow">
        <Navigation pageName="users" />
        
                <div v-if="filteredusers == null" class="d-flex flex-justify-center p-10">
                    <span class="mif-spinner2 fg-primary ani-spin mif-3x" />
                </div>

                <NoDataAvailable  v-else-if="filteredusers.length == 0" :message="$t('No users available')" />
            
                <div v-else class="p-10" >
            
                <table class="table border striped  bg-white m-0 compact"
                    :data-check=false 
                    data-check-name="chkBox"
                    :data-table-search-title='$t("Search Users")' 	
                    data-cls-search="w-100-sm w-25-xl w-25-lg w-50-md" 
                    data-show-activity="true"
                    data-cell-wrapper="false" 
                    data-role="table" 
                    data-rows="20" 
                    data-show-rows-steps="false" 
                    data-show-pagination="true" 
                    data-horizontal-scroll="true"  >
                    <thead>
                        <tr>

                            <th class="sortable-column">{{$t("Username")}}</th>
                            <th>{{$t("Role")}}</th>
                            <th class="sortable-column">{{$t("Lastname")}}</th>
                            <th class="sortable-column">{{$t("Firstname")}}</th>
                            <th class="text-center" data-cls="text-center" data-cls-column="text-center" >{{$t("Groups")}}</th>  
                            <th class="text-center" data-cls-column="text-center w-10">{{$t("2FA")}}</th>
                            <th data-cls-column="text-center w-10"></th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="(user) in users" class="mx-8 p-4  rounded m-6"  v-bind:key="user.id" >
                            <td >
                                <a :href="'#'+user.id" class="button flat-button text-bold text-left m-0"> {{user.username}} </a>

                            </td>    
                            <td class=""  > 
                                <label class="text-bold text-left m-0">{{user.role}}</label>
                            </td>
                            <td>
                                {{user.lastname}} 
                            </td>    
                            <td>
                                {{user.firstname}} 
                            </td>    
                            <td class="text-center text-bold"><button class="flat-button"   data-role="hint" :data-hint-text="$root.createhintGroups(user.groups)">{{ user.groups.length}}</button></td>
                            <td class=" text-center"> 
                                <span class=" text-center mif-2x " :class="user.mfa_enabled?'mif-security':''"></span>
                            </td>
                            <td>
                                 <ul class="listview ">
                                     <li class="mr-2"><a :href="'#'+user.id"
                                        class="button bg-transparent"><span class="mif-pencil"></span></a>
                                    </li>
                                   
                                    <li> <a @click="deleteUser(user)"
                                            class="button mr-2 bg-transparent"><span class="mif-bin "></span></a>
                                    </li>
                                </ul>
            
                            </td> 
                          
                          
                                
                        </tr>
                    </tbody>
                </table>
           
        </div>
    </div>
</template>
<script>
import Navigation from './utils/Navigation.vue';
import NoDataAvailable from "./utils/NoDataAvailable.vue";
export default {
    name: "Users",
    props: {
        idGroup: String
    },
    components: {
        Navigation,
         NoDataAvailable,
    },
    watch: { 
        '$route': {
            handler: function(to) {
                if(to.hash!=""){
                    var iduser = to.hash.replace("#","");
                    this.openDialogUser(this.filteredusers.find((user)=>user.id == iduser))
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() { return { 
        users: null,
        checkTFA: {
                step1: null,
                step2: null
            },
        treeViewHtml: "",
        filteredusers:[],
     } },
    computed:{
         userEmail() {
           return this.$root.checkAuth().email;
        },
    },
    mounted: async function () {
        if(this.$root.checkAuth()){
            this.users = await this.$root.api("GETUSERS");
            this.filteredusers = this.users;
            //this.$root.$on("filteredString",(data) => this.filteredList(data));
        }
    },

   
    methods:{
         openDialogUser(user, index) {

            this.selectedIndex = index;
            if (user == null) {
                this.selecteduser = {};
                this.selecteduser.id = null;
                //this.selecteduser.directories = [];
                this.selecteduser.email = null;
                this.selecteduser.username = null;
                this.selecteduser.firstname = null;
                this.selecteduser.lastname = null;
                this.selecteduser.password = "";
                this.selecteduser.role = "User";
            } else {
                this.selecteduser = JSON.parse(JSON.stringify(user));
                this.$root.$emit("OPENUSERVIEWDIALOG", this.selecteduser, this.checkTFA);
            }
            //this.createTreeView(this.selecteduser);
        },
      
         filteredList(data) {
            this.filteredusers = this.users;
            this.filteredusers = this.users.filter((user) =>{
                return user.username.toLowerCase().includes(data.toLowerCase())
            }); 
        },

    }
}
</script>
