<template>
    <div>
        <div class="h3 mt-2">
            <span class="caption"> {{ isValid(repository) ? repository.name : serviceAccount.name }} </span>
        </div>

        <!--Rappresento il repository-->
        <div v-if="isValid(repository)" class="text-small">
            <div v-for="prop in repoProps" v-bind:key="prop.id" class="d-flex flex-justify-between">
                <span class="cell-4 text-bold">{{ $t(prop.title) }}</span>
                <span class="cell-7" v-if="['Yes', 'No'].includes(prop.value)">{{ $t(prop.value) }}</span>
                <span class="cell-7" v-else-if="prop.title == 'Path' && prop.value.includes('/')"
                    :title="prop.value">{{ prop.value.split("/").at(-1) }}</span>
                <span class="cell-7" v-else-if="prop.title == 'Path' && prop.value.includes('\\')"
                    :title="prop.value">{{ prop.value.split("\\").at(-1) }}</span>
                <span class="cell-7" v-else>{{ prop.value }}</span>
                <!--<span class="d-flex flex-align-center flex-justify-center">-->
                <button v-if="prop.copyable" class="mif-copy button bg-transparent ml-auto" @click="copy(prop.value)"
                    :title="$t('Click to copy to clipboard')" />
                <!--</span>-->
            </div>
            <!--Se è stato passato il repository ma non il service account, aggiungo una riga-->
            <div v-if="!isValid(serviceAccount)">
                <span class="cell-5">{{ $t("Service account") }}</span>
                <span class="cell-6">{{ $t("Not found") }}</span>
            </div>
        </div>

        <div v-if="isValid(serviceAccount)" class="text-small">
            <div v-for="prop in saProps" v-bind:key="prop.id" class="row">
                <span class="cell-4 text-bold">{{ $t(prop.title) }}</span>
                <span class="cell-7" v-if="['Yes', 'No'].includes(prop.value)">{{ $t(prop.value) }}</span>
                <span class="cell-7" v-else-if="prop.title == 'Path' && prop.value.includes('/')"
                    :title="prop.value">{{ prop.value.split("/").at(-1) }}</span>
                <span class="cell-7" v-else-if="prop.title == 'Path' && prop.value.includes('\\')"
                    :title="prop.value">{{ prop.value.split("\\").at(-1) }}</span>
                <span class="cell-7" v-else>{{ prop.value }}</span>
                <!--<span class="d-flex flex-align-center flex-justify-center">-->
                <button v-if="prop.copyable" class="mif-copy button bg-transparent ml-auto" @click="copy(prop.value)"
                    :title="$t('Click to copy to clipboard')" />
                <!--</span>-->
            </div>
        </div>
    </div>
</template>
<script>
import { isValid } from "../../../../public/assets/js/utilitiesmodule";
import { DefaultObjectBuilder } from "../../../models/DefaulfObjectBuilder";
export default {
    name: "SARepoView",
    props: {
        repository: Object,
        serviceAccount: Object
    },
    data() {
        return {
            repoProps: [],
            saProps: []
        }
    },
    mounted() {
        var objBuilder = new DefaultObjectBuilder();

        if (this.serviceAccount != null) {
            this.saProps = objBuilder.fromObjectToPropertiesList(this.serviceAccount);
            if (isValid(this.serviceAccount.options))
                this.saProps = this.saProps.concat(objBuilder.fromObjectToPropertiesList(this.serviceAccount.options));
        }

        if (isValid(this.repository))
            this.repoProps = objBuilder.fromObjectToPropertiesList(this.repository);
    },
    methods: {
        async copy(value) {
            await navigator.clipboard.writeText(value);
            console.log(navigator.clipboard);
            console.log(await navigator.clipboard.read());
            console.log(await navigator.clipboard.readText());
            this.$root.toast(this.$t("Copied to clipboard"), 2000, "info");
        },
        isValid(value) {
            return isValid(value)
        }
    }
}
</script>
