const dateTimeFormats = {

          en: {

                    short: { year: "numeric", month: "short", day: "numeric" }

          },

          it: {

                    short: { year: "numeric", month: "long", day: "numeric" }

          }

}

export default dateTimeFormats