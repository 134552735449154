<template>
    <div v-if="job != null" @keyup.esc="$root.$emit('CLOSEBACKUPVIEW')"
        class="dialog overflow w-100-sm w-100-md w-75-lg overflow  w-50-xl" id="backupview">

        <div class="dialog-title">
            {{ job.name }}
            <span class="button square closer" @click="$root.$emit('CLOSEBACKUPVIEW')"></span>
            <div class="cell">
                <small class="badge inline">{{ $t(buildSubTitle()) }}</small>
            </div>
        </div>
        <div class="dialog-content w-100 overflow">

            <div class="row">

                <div class="cell-12 d-flex flex-justify-end">
                    <div data-role="buttongroup " class="button rounded">
                        <button @click="runJob" :title="$t('Run')" class="rounded button " v-if="job.isRunning == 'NR'" >
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 5.274c0-1.707 1.826-2.792 3.325-1.977l12.362 6.726c1.566.853 1.566 3.101 0 3.953L8.325 20.702C6.826 21.518 5 20.432 5 18.726V5.274Z" fill="#212121"/></svg></button>
                        <button @click="pauseJob" :title="$t('Pause')" class="rounded button " v-if="job.isRunning == 'R'" >
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.75 3A1.75 1.75 0 0 0 3 4.75v14.5c0 .966.784 1.75 1.75 1.75h14.5A1.75 1.75 0 0 0 21 19.25V4.75A1.75 1.75 0 0 0 19.25 3H4.75Z" fill="#212121"/></svg>
                        </button>
                        <button @click="editJob" :title="$t('Edit')" class="rounded button " :disabled="job.isRunning != 'NR'" >
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 5.25A2.25 2.25 0 0 1 5.25 3h13.5A2.25 2.25 0 0 1 21 5.25v5.762a3.279 3.279 0 0 0-2.607.95L15.355 15H11.25a.75.75 0 1 0 0 1.5h2.605l-1.365 1.365a3.685 3.685 0 0 0-.97 1.712L11.166 21H5.25A2.25 2.25 0 0 1 3 18.75V5.25Zm4.75 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm3.5-1.75a.75.75 0 0 0 0 1.5h5.5a.75.75 0 0 0 0-1.5h-5.5Zm0 3.75a.75.75 0 1 0 0 1.5h5.5a.75.75 0 1 0 0-1.5h-5.5Zm-4.5.75a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm1 4.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm11.35-4.08-5.903 5.902a2.686 2.686 0 0 0-.706 1.247l-.458 1.831a1.087 1.087 0 0 0 1.319 1.318l1.83-.457a2.685 2.685 0 0 0 1.248-.707l5.902-5.902A2.286 2.286 0 0 0 19.1 12.67Z" fill="#212121"/></svg>
                        </button>
                       <!--  <button @click="renameJob" :title="$t('Rename')"
                            class="mif-sign-pen button "
                            :disabled="job.isRunning != 'NR'" /> -->
                        <!--do la possibilità di duplicarlo anche se sta rannando-->
                        <button @click="duplicateJob" :title="$t('Duplicate')" class="rounded button " :disabled="job.isRunning != 'NR'" >
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.5 6.75.003-2.123A2.25 2.25 0 0 0 3 6.75v10.504a4.75 4.75 0 0 0 4.75 4.75h5.064a6.515 6.515 0 0 1-1.08-1.5H7.75a3.25 3.25 0 0 1-3.25-3.25V6.75Z" fill="#212121"/><path d="M19 11.174a6.5 6.5 0 0 0-7.687 8.326H7.75a2.25 2.25 0 0 1-2.25-2.25v-13A2.25 2.25 0 0 1 7.75 2h9A2.25 2.25 0 0 1 19 4.25v6.924Z" fill="#212121"/><path d="M17.5 12a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm.501 8.503V18h2.496a.5.5 0 0 0 0-1H18v-2.5a.5.5 0 1 0-1 0V17h-2.504a.5.5 0 0 0 0 1H17v2.503a.5.5 0 1 0 1 0Z" fill="#212121"/></svg>
                        </button>
                        <button @click="restoreJob" :title="$t('Restore')" class="rounded button" :disabled="job.isRunning != 'NR'" >
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7.207 2.543a1 1 0 0 1 0 1.414L5.414 5.75h7.836a8 8 0 1 1-8 8 1 1 0 1 1 2 0 6 6 0 1 0 6-6H5.414l1.793 1.793a1 1 0 0 1-1.414 1.414l-3.5-3.5a1 1 0 0 1 0-1.414l3.5-3.5a1 1 0 0 1 1.414 0Z" fill="#212121"/></svg>
                        </button>
                        <button @click="setStep(4)"  :title="$t('Show logs')" class=" rounded button" >
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 8V2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V10h-6a2 2 0 0 1-2-2Zm-5 4.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm0 3a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm0 3a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm3-6a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Zm0 3a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Zm0 3a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75ZM13.5 8V2.5l6 6H14a.5.5 0 0 1-.5-.5Z" fill="#212121"/></svg>
                        </button>
                        <button @click="deleteJob" :title="$t('Delete')" class="rounded button bg-red fg-white" :disabled="job.isRunning != 'NR'" >
                            <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21.5 6a1 1 0 0 1-.883.993L20.5 7h-.845l-1.231 12.52A2.75 2.75 0 0 1 15.687 22H8.313a2.75 2.75 0 0 1-2.737-2.48L4.345 7H3.5a1 1 0 0 1 0-2h5a3.5 3.5 0 1 1 7 0h5a1 1 0 0 1 1 1Zm-7.25 3.25a.75.75 0 0 0-.743.648L13.5 10v7l.007.102a.75.75 0 0 0 1.486 0L15 17v-7l-.007-.102a.75.75 0 0 0-.743-.648Zm-4.5 0a.75.75 0 0 0-.743.648L9 10v7l.007.102a.75.75 0 0 0 1.486 0L10.5 17v-7l-.007-.102a.75.75 0 0 0-.743-.648ZM12 3.5A1.5 1.5 0 0 0 10.5 5h3A1.5 1.5 0 0 0 12 3.5Z" fill="#ffffff"/></svg>
                        </button>
                    </div>

                </div>

            </div>

            <!----------------------INFORMAZIONI PRINCIPALI------------------------------->
            <div v-if="step == 0" class="p-10 cell">
                <table>
                    <tbody>

                        <tr>
                            <td>{{ $t("Computer name") }}:</td>
                            <td class="p-3 text-bold">{{ pcName }}</td>
                        </tr>

                        <tr>
                            <td>{{ $t("Created at") }}:</td>
                            <td>{{ (job.inserted_at == null ? "" : convertDate(job.inserted_at)) }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t("Updated at") }}</td>
                            <td>{{ (job.updated_at == null ? "" : convertDate(job.updated_at)) }}</td>
                        </tr>
                        <tr v-if="isValid(job.last_result) && job.last_result != 90 && job.last_result != $root.ResultValue.NeverExecuted">
                            <td>{{ $t("Started at") }}</td>
                            <td>{{ (job.dt_start_utc == null ? "" : convertDate(job.dt_start_utc)) }}</td>
                        </tr>
                        <tr v-if="isValid(job.last_result) && job.last_result != 90 && job.last_result != $root.ResultValue.NeverExecuted">
                            <td>{{ $t("Ended at") }}</td>
                            <td>{{ (job.dt_end_utc == null ? "" : convertDate(job.dt_end_utc)) }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t("Is running") }}</td>
                            <td v-if="job.isRunning == 'NA'"> {{ $t("Information not available") }}</td>
                            <td v-else-if="job.isRunning == 'R'"> {{ $t("Yes") }}</td>
                            <td v-else class="text-bold"> {{ $t("No") }}</td>
                        </tr>
                        <tr v-if="job.isRunning == 'R'">
                            <td>{{ $t("Current operation") }}</td>
                            <td>{{ job.current_operation }}</td>
                        </tr>
                        <tr v-if="job.isRunning == 'R'">
                            <td>{{ $t("Progress") }}</td>
                            <td>{{ job.progress }} %</td>
                        </tr>
                        <tr v-if="job.isRunning == 'R'">
                            <td>{{ $t("Processed data") }}</td>
                            <td>{{ job.processed_data }} </td>
                        </tr>
                        <tr v-if="job.last_result != 99">
                            <td>{{ $t("Copied files") }}</td>
                            <td>{{ job.last_backup_copiedfiles }}</td>
                        </tr>
                        <tr v-if="job.last_result != 99">
                            <td>{{ $t("Backup size") }}</td>
                            <td>{{ prettyBytes(job.last_backup_size) }}</td>
                        </tr>
                        <tr v-if="isValid(job.last_result) && job.last_result != 90 && job.last_result != $root.ResultValue.NeverExecuted">
                            <td>{{ $t("Last result") }}</td>
                            <td><BackupLastResult :lastresult="job.last_result" /></td>
                        </tr>
                        <tr>
                            <td>{{ $t("From") }}</td>
                            <td>{{ job.sources[0].type_name }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t("To") }} </td>
                            <td>{{ job.destinations[0].type_name }}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <!----------------------SORGENTI E DESTINAZIONI-------------------------------------------------->
            <div v-if="step == 1" class="pl-10 cell" id="sourcesdestinations">
                <table class="mb-6" style="height: fit-content;">
                    <tbody>
                        <!-----------SORGENTI--------------------------------------->
                        <tr>
                            <td colspan="3" class="text-upper text-bold">{{ $t("Source") }}</td>
                        </tr>
                        <hr>
                        <tr v-if="source.id_service_account.toString() != '-2'">
                            <td>{{ $t("Service account") }}</td>
                            <td v-if="$session.get('SERVICEACCOUNTS').some(sa => sa.id == source.id_service_account)">
                                {{ $session.get("SERVICEACCOUNTS").find(sa => sa.id == source.id_service_account).name }}
                            </td>
                            <td v-else class="fg-red"><span class="mif-warning pr-2" />
                                <span>{{ $t("Not found") }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t("Elements Selected") }}</td>
                            <td>
                                <SourceView :source="source" />
                            </td>
                        </tr>
                        <br>
                        <!-----------DESTINAZIONE--------------------------------------->
                        <tr>
                            <td class="text-upper text-bold">{{ $t("Destination") }}</td>
                        </tr>
                        <hr>
                        <tr>
                            <td>{{ $t("Name") }}</td>
                            <td>{{ destination.name }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t("Type") }}</td>
                            <td>{{ destination.type_name }}</td>
                        </tr>
                        <tr v-if="destination.id_service_account.toString() != '-2'">
                            <td>{{ $t("Service account") }}</td>
                            <td v-if="$session.get('SERVICEACCOUNTS').some(sa => sa.id == destination.id_service_account)">
                                {{ $session.get('SERVICEACCOUNTS').find(sa => sa.id == destination.id_service_account).name }}</td>
                            <td v-else class="fg-red"><span class="mif-warning pr-2" /> <span>{{ $t("Not found")}}</span></td>
                        </tr>
                        <tr v-if="destination.id_repository.toString() != '-2'">
                            <td>{{ $t("Repository") }}</td>
                            <td v-if="$session.get('REPOSITORIES').some(r => r.id == destination.id_repository)">
                                {{ $session.get('REPOSITORIES').find(r => r.id == destination.id_repository).name }}
                            </td>
                            <td v-else class="fg-red"><span class="mif-warning pr-2" /> <span>{{ $t("Not found")}}</span></td>
                        </tr>
                        <tr v-if="destination.path != null && destination.path != ''">
                            <td>{{ $t("Path") }}</td>
                            <td>{{ destination.path }}</td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <!------------------------OPTIONS------------------------------------------------->
            <div v-if="step == 2" class="pl-10 cell">
                <div v-for="d in details" class="pb-3" v-bind:key="d.id">
                    <div class="text-bold"> {{ d.section }} </div>
                    <div v-for="(k, index) in d.keys" v-bind:key="index">{{ k.toUpperCase() }} : {{ d.values[index] }}
                    </div>
                </div>
            </div>

            <!----------------------SCHEDULING---------------------------------------------------->
            <div v-if="step == 3" class="p-10 cell">
                <NoDataAvailable v-if="job.scheduling.length == 0 || job.scheduling[0] == ''" :message="$t('No scheduling available')" />
                <ul v-else class="items-list">
                    <li v-for="(sched, index) in job.scheduling" v-bind:key="index" class="">
                        <span class=" mif-alarm label"> {{ cronstrue.toString(sched, {locale: 'en', dayOfWeekStartIndexZero: false}) }}</span>
                    </li>
                </ul>
            </div>

            <!----------------------LOGS-------------------------------------------------------------->
            <div v-if="step == 4" class="p-5 cell" style="height: 500px;">
                <div v-if="logs == null" class="h-100 d-flex flex-justify-center flex-align-center">
                    <span class="mif-spinner2 fg-primary ani-spin" />
                </div>
                <NoDataAvailable class="w-100" v-else-if="logs.length == 0" :message="$t('No logs available')" />

                <table v-else class="table  text-center text-small cell-12">
                    <thead>
                        <tr>
                            <td  class="text-right w-25">{{ $t("Start / End") }}</td>
                            <td class="text-right">{{ $t("Duration") }}</td>
                            <td class="text-right">{{ $t("Size") }}</td>
                            <td class="text-right">{{ $t("Num. Files") }}</td>
                            <td class="text-right"></td>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <!-- VERONICA : TODO DA CAPIRE COME RIMETTERE LA SECONDA RIGA DEL LOG -->
                        <!-- eslint-disable-next-line vue/no-v-for-template-key -->
                        <!--  <template v-for="(log, index) in logs"  > -->
                        <tr v-for="(log, index) in logs" :key="index">
                            <td class="text-right">{{ convertDate(log.dt_start_utc) }} <br> {{ convertDate(log.dt_end_utc) }}</td>
                            <td class="text-right">{{ diffDate(log.dt_start_utc, log.dt_end_utc) }}</td>
                            <td class="text-right">{{ prettyBytes(log.backup_size) }}</td>
                            <td class="text-right">{{ log.copied_files }}</td>
                            <td>
                                <span v-if="log.partial_log != null && log.partial_log == 'wait'"
                                    class="mif-spinner2 fg-primary ani-spin" />
                                <a v-else href="#" @click="getPartialLog(index)" >
                                    <span v-if="focusLog" id="logPopover" 
                                    data-role="popover"
                                    :data-popover-text="focusLog"
                                    data-popover-position="left"
                                    data-popover-hide="0" />
                                    <span class="mif-eye"/>
                                    
                                </a>
                            </td>
                            <!--    <td>{{ log.account }}</td>
                            <td>{{ log.version_agent }}</td> -->
                        </tr>
                        <!--  <tr v-show="log.showPartialLog"  :key="index" >
                            <td v-if="log.partial_log == ''" colspan="7"> {{ $t("Log not available") }} </td>
                            <td v-else colspan="7">
                                <table style="width:100%">
                                    <tr>
                                        <td v-html="log.partial_log" class="text-left"></td>
                                    </tr>
                                </table>
                            </td>
                        </tr> -->
                        <!-- </template> -->
                    </tbody>
                </table>

                <!--<div class="cell-12 overflow p-4" v-html="focusLog" />-->
            </div>

        </div>

        <div class="dialog-actions ">

            <button @click="setStep(step - 1)" class="button primary" :disabled="step == 0"><span
                    class="mif-arrow-left pr-2" />{{ $t('Prev') }} </button>
            <button @click="setStep(step + 1)" class="button primary" :disabled="step == 4">{{ $t('Next') }}<span
                    class="mif-arrow-right pl-2" /></button>

        </div>

    </div>
</template>

<script>

import BackupLastResult from "../../utils/BackupLastResult.vue";
import NoDataAvailable from "../../utils/NoDataAvailable.vue";
import { isValid, prettyBytes,diffDate } from "../../../../public/assets/js/utilitiesmodule";
import { OptionsConverter } from '../../../models/Options';
import SourceView from './SourceView';
import { BACKUP_MESSAGES } from '../../../../public/assets/js/messages';



export default {
    name: "BackupView",
    props: {
        idBackup: String
    },
    components: {
        "BackupLastResult": BackupLastResult,
        NoDataAvailable,
        SourceView
    },

    data() {
        return {
            job: null,
            pcName: null,
            details: [],
            logs: null,


            source: null,
            destination: null,

            step: 0,
            focusLog: "",

        }
    },
    async beforeDestroy() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        this.$root.$off("sendRealtimeJob")
        //Se non sono in una room pc, è stata istanziata una connessione socket per il pc del job, quindi devo stoppare
        if (isValid(this.$session.get("ROOMPC"))) {
            return;
        }
        //Altrimenti ho istanziato la connessione socket in questo componente
        console.log("stop = " + await this.$root.socket("STOPSENDINFO"));
        console.log("unsubscribe = " + await this.$root.socket("unsubscribe_pc", this.job.id_computer));
    },
    created: async function () {
        //this.$store.commit('SET_LAYOUT', 'nav-layout');

        this.cronstrue = require('cronstrue/i18n');
        let self = this;

        //Step 1 : Recupero il job tramite API
        var APIjob = await this.$root.api("GETBACKUPWITHID", this.idBackup);
        if (APIjob == null) {
            this.$root.toast(this.$t(BACKUP_MESSAGES.NOT_FOUND), 3000, "warning");
            this.$root.$emit("CLOSEBACKUPVIEW");
            return;
        }

        APIjob.isRunning = "NA"; // Non disponibile
        APIjob.progress = null;
        APIjob.current_operation = "NA";
        APIjob.processed_data = "NA";
        self.job = APIjob;
        self.pcName = this.$session.get("COMPUTERS").find(pc => pc.id == self.job.id_computer).name;
        self.$root.$on("sendRealtimeJob", realtimeJob => {
            self.job.isRunning = realtimeJob.isRunning ? "R" : "NR";
            self.job.progress = realtimeJob.isRunning ? realtimeJob.progress : null;
            self.job.current_operation = realtimeJob.current_operation;
            self.job.processed_data = realtimeJob.processed_data;
            self.job.last_result = realtimeJob.lastresult
        });

        this.source = self.job.sources[0];
        this.destination = self.job.destinations[0];

        //Popolo la parte delle opzioni
        new OptionsConverter().fromOptionsToDetails(this.job.sources[0].options, this.details);



        //Step 5 : In tutti gli altri casi, contrassegno come "NOT AVAILABLE" le informazioni che dovevano ritornarmi in realtime

    },

    methods: {
        /******************************************BOTTONI*********************************/
        async runJob() {
            this.job.isRunning = await this.$root.socket("RunJob", this.job.id) ? 'R' : 'NR';
        },
        async pauseJob() {
            this.job.isRunning = await this.$root.socket("stopjob", this.job.id) ? 'NR' : 'R';
        },
        editJob() {
            this.$router.push('/backup/' + this.job.id);
            this.$root.$emit("CLOSEBACKUPVIEW");
        },
        renameJob() {
            this.$root.$emit("OPENRENAMEJOBDIALOG", this.job.id, this.job.name);
        },
        async duplicateJob() {
            var date = new Date();
            var dupl = {
                "id": null,
                "name": this.job.name + " " + date.getDate() + "" + (date.getMonth() + 1) + "" + date.getFullYear() + "" + date.getHours() + "" + date.getMinutes() + "" + date.getSeconds(),
                "type": this.job.type,
                "id_computer" : this.job.id_computer,
                "sources": this.job.sources.map(s => {s.id = null; s.id_job = null;return s;}),
                "destinations": this.job.destinations.map(d => {d.id = null; d.id_job = null; return d;}),
                "scheduling": this.job.scheduling,
                "options": this.job.options
            };

            //Se il pc è offline, si salva in una memoria locale
            if (!this.$session.get('ONEISCONNECTED')) {
                this.$root.formattedToast(this.$t(BACKUP_MESSAGES.NOT_CREATED), [dupl.name], 5000, "info");
                return;
            }

            //Step 1 : Invio la richiesta all'API di salvare il job sul database
            var api_response = await this.$root.api("CREATEBACKUP", dupl);
            var socket_response;

            // Step 2 : Se il salvataggio sul database non è andato a buon fine, imposto il salvataggio come fallito
            if (!api_response) {
                this.$root.formattedToast(this.$t(BACKUP_MESSAGES.NOT_CREATED), [dupl.name], 5000, "info");
                return;
            }

            //Step 3 : Se il salvataggio sul database è andato a buon fine, eseguo la richiesta socket
            socket_response = await this.$root.socket("CreateJob", api_response);

            //Step 4 : Se il salvataggio non è andato a buon fine imposto il salvataggio come fallito 
            if (!socket_response) {
                this.$root.formattedToast(this.$t(BACKUP_MESSAGES.NOT_CREATED), [dupl.name], 5000, "info");
                return;
            }

        },
        restoreJob() {
            this.$root.$emit("OPENRESTOREDIALOG", parseInt(this.job.type.toString()), this.job/*this.job.id, this.job.name*/);
        },
        async deleteJob() {
            var deleted = await this.$root.api("DELETEBACKUP", { id: this.job.id, name: this.job.name });
            if (!deleted)
                return;
            deleted = await this.$root.socket("DeleteJob", this.job.id);
            if (!deleted)
                return;

            this.$root.$emit("DeletedJob", this.idBackup);
            this.$root.$emit("CLOSEBACKUPVIEW");
        },

        /*******************************LOGS**************************************************************/
        async getLogs() {
            let self = this;
            var logs = await this.$root.api("GETBACKUPLOGS", self.job.id);
            if (logs == null)
                self.logs = [];
            else
                self.logs = logs.map(l => { l.showPartialLog = false; return l; });
        },
        async getPartialLog(index) {
            let self = this;

            //Caso 1 : La richiesta del partial log è in corso
            if (self.logs[index].partial_log != null && self.logs[index].partial_log == 'wait') {
                this.focusLog = self.logs[index].partial_log;
                return;
            }

            //Caso 2 : è stata cliccata una riga dove è già stato richiesto il partial log
            //La riga mostra/nasconde la tabella
            //Applico l'azione solo al log passato e nascondo tutti gli altri partial log
            if (self.logs[index].partial_log != null) {
                /*self.logs.forEach((l, i) => {
                    l.showPartialLog = i == index ? !self.logs[index].showPartialLog : false;
                });*/
                this.focusLog = self.logs[index].partial_log;

                return;
            }

            //Caso 3 : è stata cliccata una riga dove non è ancora stato richiesto il partial log

            if (!this.$session.get("PCSCONNECTIONS")[this.job.id_computer])
                return;

            //Step 3.2 : chiudo tutti gli altri partial log
            self.logs.forEach((l, i) => {
                if (i != index)
                    l.showPartialLog = false;
            });
            //Step 3.3 : metto in attesa l'utente
            self.logs[index].partial_log = "wait";
            //Step 3.4 : richiedo il partial log 
            var partialLog = await this.$root.socket("GetLog", self.logs[index].logfile_name);

            if (partialLog == null)
                self.logs[index].partial_log = '';
            else
                self.logs[index].partial_log = partialLog;
            //Applico l'azione solo al log passato e nascondo tutti gli altri partial log
            self.logs[index].showPartialLog = true;
            this.focusLog = self.logs[index].partial_log;

            if(this.focusLog != ""){
                setTimeout(() =>{

                    Metro.getPlugin('#logPopover', 'popover').show()
                }, 500);
                
            }
        },

        /*************************************UTILS******************************/
        buildSubTitle() {
            switch (this.step) {
                case 0: return "Overview";
                case 1: return "Sources and destinations";
                case 2: return "Options";
                case 3: return "Scheduling";
                case 4: return "Last 10 logs";
            }
            return "";
        },

        convertDate(date) {
            if (date.toString().indexOf("T") == -1)
                date = this.$moment(date.substr(0, 8) + "T" + date.substr(8), "LLL");

            if (date.toString().indexOf("Z") != -1)
                date = date.replace("Z", "");

            return this.$moment.utc(date).format("LLL");
        },

        diffDate(datestart, dateend) {
            return diffDate(datestart, dateend, this.$moment);
        },

        prettyBytes(data) {
            return prettyBytes(data);
        },

        setStep(nStep) {
            this.step = nStep;

            if (this.step == 4 && this.logs == null)
                this.getLogs();
        },

        isValid(value) {
            return isValid(value);
        },

    }
}

</script>
