var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "h3 mt-2" }, [
      _c("span", { staticClass: "caption" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.isValid(_vm.repository)
                ? _vm.repository.name
                : _vm.serviceAccount.name
            ) +
            " "
        ),
      ]),
    ]),
    _vm.isValid(_vm.repository)
      ? _c(
          "div",
          { staticClass: "text-small" },
          [
            _vm._l(_vm.repoProps, function (prop) {
              return _c(
                "div",
                { key: prop.id, staticClass: "d-flex flex-justify-between" },
                [
                  _c("span", { staticClass: "cell-4 text-bold" }, [
                    _vm._v(_vm._s(_vm.$t(prop.title))),
                  ]),
                  ["Yes", "No"].includes(prop.value)
                    ? _c("span", { staticClass: "cell-7" }, [
                        _vm._v(_vm._s(_vm.$t(prop.value))),
                      ])
                    : prop.title == "Path" && prop.value.includes("/")
                    ? _c(
                        "span",
                        { staticClass: "cell-7", attrs: { title: prop.value } },
                        [_vm._v(_vm._s(prop.value.split("/").at(-1)))]
                      )
                    : prop.title == "Path" && prop.value.includes("\\")
                    ? _c(
                        "span",
                        { staticClass: "cell-7", attrs: { title: prop.value } },
                        [_vm._v(_vm._s(prop.value.split("\\").at(-1)))]
                      )
                    : _c("span", { staticClass: "cell-7" }, [
                        _vm._v(_vm._s(prop.value)),
                      ]),
                  prop.copyable
                    ? _c("button", {
                        staticClass: "mif-copy button bg-transparent ml-auto",
                        attrs: { title: _vm.$t("Click to copy to clipboard") },
                        on: {
                          click: function ($event) {
                            return _vm.copy(prop.value)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              )
            }),
            !_vm.isValid(_vm.serviceAccount)
              ? _c("div", [
                  _c("span", { staticClass: "cell-5" }, [
                    _vm._v(_vm._s(_vm.$t("Service account"))),
                  ]),
                  _c("span", { staticClass: "cell-6" }, [
                    _vm._v(_vm._s(_vm.$t("Not found"))),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm.isValid(_vm.serviceAccount)
      ? _c(
          "div",
          { staticClass: "text-small" },
          _vm._l(_vm.saProps, function (prop) {
            return _c("div", { key: prop.id, staticClass: "row" }, [
              _c("span", { staticClass: "cell-4 text-bold" }, [
                _vm._v(_vm._s(_vm.$t(prop.title))),
              ]),
              ["Yes", "No"].includes(prop.value)
                ? _c("span", { staticClass: "cell-7" }, [
                    _vm._v(_vm._s(_vm.$t(prop.value))),
                  ])
                : prop.title == "Path" && prop.value.includes("/")
                ? _c(
                    "span",
                    { staticClass: "cell-7", attrs: { title: prop.value } },
                    [_vm._v(_vm._s(prop.value.split("/").at(-1)))]
                  )
                : prop.title == "Path" && prop.value.includes("\\")
                ? _c(
                    "span",
                    { staticClass: "cell-7", attrs: { title: prop.value } },
                    [_vm._v(_vm._s(prop.value.split("\\").at(-1)))]
                  )
                : _c("span", { staticClass: "cell-7" }, [
                    _vm._v(_vm._s(prop.value)),
                  ]),
              prop.copyable
                ? _c("button", {
                    staticClass: "mif-copy button bg-transparent ml-auto",
                    attrs: { title: _vm.$t("Click to copy to clipboard") },
                    on: {
                      click: function ($event) {
                        return _vm.copy(prop.value)
                      },
                    },
                  })
                : _vm._e(),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }