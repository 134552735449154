var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "body",
    {
      staticClass:
        "h-vh-100 d-flex flex-justify-center flex-align-center bg-cobalt",
    },
    [
      _c("div", { staticClass: "login-box border border-radius-5 w-auto" }, [
        _c(
          "form",
          {
            staticClass: "bg-white p-2",
            attrs: {
              action: "javascript:",
              "data-role": "validator,Validator",
              "data-clear-invalid": "2000",
              "data-on-error-form": "invalidForm",
              "data-role-validator": "true",
              novalidate: "novalidate",
            },
          },
          [
            _c("div", { staticClass: "text-muted mt-4 text-upper" }, [
              _vm._v(_vm._s(_vm.$t("Set authenticator code"))),
            ]),
            _c("div", { staticClass: "form-group pb-4" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.TFAcode,
                    expression: "TFAcode",
                  },
                ],
                staticClass: "form-group input mb-4 mt-4 p-4",
                attrs: {
                  type: "password",
                  placeholder: _vm.$t("Insert your code"),
                },
                domProps: { value: _vm.TFAcode },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.TFAcode = $event.target.value
                  },
                },
              }),
              _c("span", { staticClass: "invalid_feedback" }, [
                _vm._v(_vm._s(_vm.$t("Please enter a code"))),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "form-group d-flex flex-align-center flex-justify-between",
              },
              [
                _c("label", { staticClass: "checkbox transition-on" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.RememberMachine,
                        expression: "RememberMachine",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      "data-role": "checkbox",
                      "data-caption": "Remember Machine for 15 days",
                      "data-role-checkbox": "true",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.RememberMachine)
                        ? _vm._i(_vm.RememberMachine, null) > -1
                        : _vm.RememberMachine,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.RememberMachine,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.RememberMachine = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.RememberMachine = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.RememberMachine = $$c
                        }
                      },
                    },
                  }),
                  _c("span", { staticClass: "check" }),
                  _c("span", { staticClass: "caption" }, [
                    _vm._v(_vm._s(_vm.$t("Remember Machine for 15 days"))),
                  ]),
                ]),
                _c(
                  "button",
                  {
                    staticClass:
                      "button bg-cobalt fg-white rounded text-uppercase",
                    on: {
                      click: function ($event) {
                        return _vm.loginTFA()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Login")))]
                ),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "button flat-button text-center",
                attrs: { id: "recovery_collapse" },
              },
              [
                _vm._v(_vm._s(_vm.$t("Forgot your device?"))),
                _c("b", [_vm._v(" " + _vm._s(_vm.$t("Use recovery code")))]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            attrs: {
              "data-role": "collapse",
              "data-toggle-element": "#recovery_collapse",
              "data-collapsed": "true",
            },
          },
          [
            _c(
              "form",
              {
                staticClass: "bg-white p-2",
                attrs: {
                  action: "javascript:",
                  "data-role": "validator,Validator",
                  "data-clear-invalid": "2000",
                  "data-on-error-form": "invalidForm",
                  "data-role-validator": "true",
                  novalidate: "novalidate",
                },
              },
              [
                _c("div", { staticClass: "text-muted mt-4 text-upper" }, [
                  _vm._v(_vm._s(_vm.$t("Recovery code verification"))),
                ]),
                _c("div", { staticClass: "form-group pb-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.TFAcode_recovery,
                        expression: "TFAcode_recovery",
                      },
                    ],
                    staticClass: "metro-input",
                    attrs: {
                      "data-role": "input",
                      type: "password",
                      placeholder: _vm.$t("Insert your code"),
                      "data-prepend": "<span class='mif-key'></span>",
                    },
                    domProps: { value: _vm.TFAcode_recovery },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.TFAcode_recovery = $event.target.value
                      },
                    },
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("Required"))),
                  ]),
                  _c("span", { staticClass: "text-muted text-small" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "This login will not remembered until you provide an authenticator app code at login or disable 2FA and login again"
                        )
                      )
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group d-flex flex-align-center flex-justify-end",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "button alert rounded text-upper",
                        on: {
                          click: function ($event) {
                            return _vm.loginTFArecovery()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Login with recovery code")))]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }