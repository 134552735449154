var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.lastresult == _vm.$root.ResultValue.OK
      ? _c(
          "b",
          {
            staticClass: "fg-green",
            attrs: { title: _vm.$t("Restore completed successfully") },
          },
          [_c("span", { staticClass: "mif-checkmark" })]
        )
      : _vm.lastresult == _vm.$root.ResultValue.SomeWarnings
      ? _c("b", { staticClass: "fg-orange" }, [
          _vm._v(" " + _vm._s(_vm.$t("Some Warnings"))),
        ])
      : _vm.lastresult == _vm.$root.ResultValue.Running
      ? _c("b", { staticClass: "fg-darkGray" }, [
          _vm._v(" " + _vm._s(_vm.$t("Running")) + " "),
        ])
      : _vm.lastresult == _vm.$root.ResultValue.Aborted
      ? _c("b", { staticClass: "fg-black" }, [
          _vm._v(" " + _vm._s(_vm.$t("Aborted by user"))),
        ])
      : _vm.lastresult == 90 ||
        _vm.lastresult == _vm.$root.ResultValue.NeverExecuted
      ? _c("b", { staticClass: "fg-black" }, [
          _vm._v(" " + _vm._s(_vm.$t("Never executed"))),
        ])
      : _vm._e(),
    _vm.lastresult == _vm.$root.ResultValue.TerminatedAbnormally
      ? _c("b", {
          staticClass: "fg-red text-bold mif-cross-light",
          attrs: { title: _vm.$t("Abnormally terminated") },
        })
      : _c("b", { staticClass: "fg-red" }, [
          _vm._v(" " + _vm._s(_vm.$t("Some Errors"))),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }