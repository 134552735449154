var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100 overflow" },
    [
      _vm.selectedcomputer
        ? _c(
            "div",
            {
              attrs: {
                "data-role": "charms",
                id: "computerselected",
                "data-position": "left",
                "data-cls-charms":
                  "bg-white fg-dark w-vw-33 border bd-gray p-1 overflow",
              },
            },
            [
              _c("div", { staticClass: "row p-1" }, [
                _c("h4", { staticClass: "m-0 p-2 fg-primary" }, [
                  _vm._v(_vm._s(_vm.selectedcomputer.name)),
                ]),
                _c("div", { staticClass: "cell d-flex flex-justify-end" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button bg-transparent",
                      on: {
                        click: function ($event) {
                          return _vm.closeCharm()
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "icon mif mif-close mif-cross",
                      }),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "info-box-content p-3" },
                [
                  _c("label", { staticClass: "m-0" }, [
                    _vm._v(_vm._s(_vm.selectedcomputer.description)),
                  ]),
                  _c("br"),
                  _c("label", [
                    _vm._v(_vm._s(_vm.selectedcomputer.organization_name)),
                  ]),
                  _c("br"),
                  _c("label", { staticClass: "m-0" }, [
                    _vm._v(_vm._s(_vm.selectedcomputer.os_version_full)),
                  ]),
                  _c("br"),
                  _c("br"),
                  _c("label", [
                    _c("span", {
                      class: {
                        "mif-checkmark fg-green":
                          _vm.selectedcomputer.disk_alert == 0,
                      },
                    }),
                    _vm._v(" Disk Status"),
                  ]),
                  _c("br"),
                  _c("label", [
                    _c("span", {
                      class: {
                        "mif-checkmark fg-green":
                          _vm.selectedcomputer.ram_alert == 0,
                      },
                    }),
                    _vm._v(" Ram Status: "),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm._f("prettyBytes")(_vm.selectedcomputer.ram))
                      ),
                    ]),
                  ]),
                  _c("br"),
                  _c("label", [
                    _c("span", {
                      class: {
                        "mif-checkmark fg-green":
                          _vm.selectedcomputer.av_alert == 0,
                      },
                    }),
                    _vm._v(" Protection"),
                  ]),
                  _c("br"),
                  _c("br"),
                  _c("label", [
                    _vm._v(
                      "IP LAN: " + _vm._s(_vm.selectedcomputer.ipaddress_lan)
                    ),
                  ]),
                  _c("br"),
                  _c("label", [
                    _vm._v(
                      "IP Public: " +
                        _vm._s(_vm.selectedcomputer.ipaddress_public)
                    ),
                  ]),
                  _c("br"),
                  _c("br"),
                  _c("label", {}, [_vm._v(_vm._s(_vm.$t("Groups")) + ":")]),
                  _c("br"),
                  _vm._l(_vm.selectedcomputer.groups, function (group) {
                    return _c(
                      "button",
                      {
                        key: group.id,
                        staticClass: "button fg-dark mr-2 mt-2 mini",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            key: group.id,
                            staticClass: "flat-button link",
                            attrs: { to: "/group/" + group.id },
                          },
                          [_vm._v(_vm._s(group.name))]
                        ),
                      ],
                      1
                    )
                  }),
                  _c("br"),
                  _c("br"),
                  _c("table", { staticClass: "table compact" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("td"),
                        _c("td", [_vm._v(_vm._s(_vm.$t("EMAIL")))]),
                        _c("td", [_vm._v(_vm._s(_vm.$t("MOBILE PUSH")) + " ")]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("Backup Notifications"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.selectedcomputer.notifications[1]
                                    .enabled_email == "1",
                              },
                            }),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.selectedcomputer.notifications[1]
                                    .enabled_push == "1",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("System Notifications"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.selectedcomputer.notifications[0]
                                    .enabled_email == "1",
                              },
                            }),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.selectedcomputer.notifications[0]
                                    .enabled_push == "1",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(" " + _vm._s(_vm.$t("Backup Reports")) + " "),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.selectedcomputer.notifications[3]
                                    .enabled_email == "1",
                              },
                            }),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.selectedcomputer.notifications[3]
                                    .enabled_push == "1",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.$t("Disconnect Notifications")) + " "
                          ),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.selectedcomputer.notifications[2]
                                    .enabled_email == "1",
                              },
                            }),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                "data-caption-position": "left",
                                "data-role": "switch",
                                "data-on": "on",
                              },
                              domProps: {
                                checked:
                                  _vm.selectedcomputer.notifications[2]
                                    .enabled_push == "1",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _c("div", { staticClass: "cell d-flex flex-justify-end p-5" }, [
                _c(
                  "button",
                  { staticClass: "button primary", attrs: { type: "submit" } },
                  [_vm._v(_vm._s(_vm.$t("Save")))]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button error ml-2",
                    on: {
                      click: function ($event) {
                        return _vm.removeFromLicense(_vm.selectedcomputer.id)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Remove from license")) + " ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("Navigation", { attrs: { pageName: "dashboard" } }),
      false
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.fullStats,
                  expression: "fullStats",
                },
              ],
              staticClass: "row",
            },
            [
              _c("div", { staticClass: "cell-lg-2 cell-md-3 col-sm-6 mt-2" }, [
                _c("div", { staticClass: "more-info-box card rounded p-2" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("h2", { staticClass: "text-bold mb-0" }, [
                      _vm._v(
                        _vm._s(_vm.fullStats?.nOfWorkstations) +
                          "/" +
                          _vm._s(_vm.fullStats?.nOfServers)
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("Workstations")) +
                          "/" +
                          _vm._s(_vm.$t("Servers"))
                      ),
                    ]),
                  ]),
                  _vm._m(0),
                ]),
              ]),
              _c("div", { staticClass: "cell-lg-2 cell-md-3 col-sm-6 mt-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "more-info-box card rounded p-2",
                    class:
                      _vm.fullStats?.nPcDiskAlert != 0 ? "bg-lightyellow" : "",
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("h2", { staticClass: "text-bold mb-0" }, [
                        _vm._v(_vm._s(_vm.fullStats?.nPcDiskAlert)),
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("Devices with Disk Alert"))),
                      ]),
                    ]),
                    _vm._m(1),
                  ]
                ),
              ]),
              _c("div", { staticClass: "cell-lg-2 cell-md-3 col-sm-6 mt-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "more-info-box card rounded p-2",
                    class:
                      _vm.fullStats?.nPcRamAlert != 0 ? "bg-lightyellow" : "",
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("h2", { staticClass: "text-bold mb-0" }, [
                        _vm._v(_vm._s(_vm.fullStats?.nPcRamAlert)),
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("Devices with Ram Alert"))),
                      ]),
                    ]),
                    _vm._m(2),
                  ]
                ),
              ]),
              _c("div", { staticClass: "cell-lg-2 cell-md-3 col-sm-6 mt-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "more-info-box card rounded p-2",
                    class:
                      _vm.fullStats?.nPcAvAlert != 0 ? "bg-lightyellow" : "",
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("h2", { staticClass: "text-bold mb-0" }, [
                        _vm._v(_vm._s(_vm.fullStats?.nPcAvAlert)),
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("Devices with Antivirus Alert"))),
                      ]),
                    ]),
                    _vm._m(3),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "row pl-5 pr-5 w-100" }, [
        _c("div", { staticClass: "w-100" }, [
          _c(
            "ul",
            {
              staticStyle: { "z-index": "1" },
              attrs: {
                "data-role": "tabs",
                "data-expand": "true",
                "data-cls-tabs": "bg-transparent",
              },
            },
            [
              _c("li", [
                _c("a", { attrs: { href: "#all_computers" } }, [
                  _c("label", { staticClass: "pr-1" }, [
                    _vm.computers == null
                      ? _c("span", {
                          staticClass: "mif-spinner2 fg-primary ani-spin",
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(" " + _vm._s(_vm.$t("Devices")))]),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#all_backups" } }, [
                  _c("label", { staticClass: "pr-1" }, [
                    _vm.jobs == null
                      ? _c("span", {
                          staticClass: "mif-spinner2 fg-primary ani-spin",
                        })
                      : _vm._e(),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("Latest Backups"))),
                    ]),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#all_sessions" } }, [
                  _c("label", { staticClass: "pr-1" }, [
                    _vm.sessions == null
                      ? _c("span", {
                          staticClass: "mif-spinner2 fg-primary ani-spin",
                        })
                      : _vm._e(),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("Latest Remote Sessions"))),
                    ]),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("a", { attrs: { href: "#activities" } }, [
                  _c("label", { staticClass: "pr-1" }, [
                    _c("span", [_vm._v(" " + _vm._s(_vm.$t("Activities")))]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "w-100",
            staticStyle: { height: "calc(75vh)", "overflow-y": "scroll" },
            attrs: { id: "activities" },
          },
          [
            _vm.events
              ? _c(
                  "table",
                  {
                    staticClass: "p-0 table striped bg-white m-0 compact",
                    attrs: {
                      id: "event-list",
                      "data-check": false,
                      "data-check-name": "chkBox",
                      "data-show-search": "false,",
                      "data-show-activity": "true",
                      "data-show-rows-steps": "false",
                      "data-show-pagination": "true",
                      "data-cell-wrapper": "false",
                      "data-role": "table",
                      "data-rows": "20",
                      "data-horizontal-scroll": "false",
                    },
                  },
                  [
                    _c("thead", { staticClass: "mb-0" }, [
                      _c("tr", { staticClass: "mb-3" }, [
                        _c(
                          "th",
                          {
                            attrs: {
                              "data-cls-column": "text-center no-wrap",
                              "data-sortable": "true",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Type")))]
                        ),
                        _c("th", { attrs: { "data-sortable": "true" } }, [
                          _vm._v(_vm._s(_vm.$t("Event"))),
                        ]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("Message")))]),
                        _c("th", { attrs: { "data-sortable": "true" } }, [
                          _vm._v(_vm._s(_vm.$t("Created at "))),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.events, function (event) {
                        return _c(
                          "tr",
                          {
                            class: { "": !event.is_read },
                            attrs: { id: event.id },
                          },
                          [
                            _c("td", { staticClass: "m-2" }, [
                              _c("span", [_vm._v(_vm._s(event.msg_type))]),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge inline bg-light text-bold",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        _vm.types.find(function (el) {
                                          return el.id == event.type ? el : null
                                        }).name
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", [
                              _c("b", [_vm._v(_vm._s(_vm.$t(event.msg)))]),
                              _c("br"),
                              _vm._v(_vm._s(_vm.$t(event.subtitle))),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.formatDate(event.dt_ins)) + " "
                              ),
                              _c("br"),
                              _vm._v(
                                "(" + _vm._s(_vm.fromNow(event.dt_ins)) + ")"
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "w-100 bg-white bordered border border-top-none",
            staticStyle: { height: "calc(75vh)", "overflow-y": "scroll" },
            attrs: { id: "all_backups" },
          },
          [
            _c("div", { staticClass: "d-flex flex-justify-end" }),
            _vm.jobs
              ? _c(
                  "table",
                  {
                    staticClass: "striped compact table w-100",
                    attrs: { id: "backup-list" },
                  },
                  [
                    _c("thead", { staticClass: "mb-0" }, [
                      _c("tr", { staticClass: "mb-3" }, [
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              width: "60px",
                            },
                            attrs: { colspan: "2" },
                          },
                          [_vm._v(_vm._s(_vm.$t("Notifications")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              width: "30px",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Average Result")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              width: "60px",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Last Result")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-left",
                            staticStyle: {
                              "vertical-align": "middle",
                              "min-width": "100px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Name")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              width: "100px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Type")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: { "vertical-align": "middle" },
                          },
                          [_vm._v(_vm._s(_vm.$t("Device")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Started at")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Ended at")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: { "vertical-align": "middle" },
                          },
                          [_vm._v(_vm._s(_vm.$t("Duration")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Files")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: { "vertical-align": "middle" },
                          },
                          [_vm._v(_vm._s(_vm.$t("Size")))]
                        ),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      [
                        !_vm.jobs
                          ? _c("tr", { staticClass: "pt-10" }, [
                              _c("td", {
                                staticClass: "p-6",
                                attrs: {
                                  colspan: "7",
                                  "data-role": "activity",
                                  "data-type": "metro",
                                  "data-style": "dark",
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._l(_vm.jobs, function (backup, index) {
                          return _c("tr", { key: backup.id + "-" + index }, [
                            _c("td", { staticClass: "text-center" }, [
                              _c("span", {
                                staticClass: "mif-mobile mif-2x",
                                class: {
                                  "fg-lightgray": backup.enabled_push == 0,
                                },
                              }),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c("span", {
                                staticClass: "mif-mail mif-2x",
                                class: {
                                  "fg-lightgray": backup.enabled_email == 0,
                                },
                              }),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c("BackupLastResult", {
                                  attrs: { lastresult: backup.average_result },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c("BackupLastResult", {
                                  attrs: { lastresult: backup.last_result },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "text-left c-pointer m-0 p-0",
                                on: {
                                  click: function ($event) {
                                    return _vm.openBackupView(
                                      backup.id,
                                      backup.id_computer
                                    )
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(backup.name) + " ")]
                            ),
                            _c("td", { staticClass: "text-right" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge border inline mt-2 p-1 text-bold",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$root.platformsTypes[
                                        backup.type
                                      ].name.toUpperCase()
                                    ) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-left" },
                              [
                                _c("PCLink", {
                                  attrs: {
                                    pc: _vm.computers[backup.id_computer],
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              backup.dt_start_utc != "" &&
                              backup.dt_start_utc != null
                                ? _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDate(backup.dt_start_utc)
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              backup.dt_end_utc != "" &&
                              backup.dt_end_utc != null
                                ? _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.formatDate(backup.dt_end_utc))
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              backup.dt_end_utc != "" &&
                              backup.dt_end_utc != null
                                ? _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.diffDate(
                                          backup.dt_start_utc,
                                          backup.dt_end_utc
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(backup.last_backup_copiedfiles) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("prettyBytes")(
                                      backup.last_backup_size
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "w-100 bg-white bordered border border-top-none",
            staticStyle: { height: "calc(75vh)", "overflow-y": "auto" },
            attrs: { id: "all_sessions" },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass:
                  "h-100 m-0 op-dark-hi p-0 pos-absolute pos-center w-100 z-2",
              },
              [
                _c("span", {
                  staticClass:
                    "ani-spin fg-white mif-5x mif-spinner2 mt-13-minus pos-center",
                }),
              ]
            ),
            _c("div", { staticClass: "d-flex flex-justify-end p-3" }, [
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("router-link", { attrs: { to: "/sessions" } }, [
                    _c("button", { staticClass: "button secondary" }, [
                      _vm._v(_vm._s(_vm.$t("Show all remote sessions")) + " "),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", [
              _vm.sessions
                ? _c(
                    "table",
                    {
                      staticClass: "striped compact table w-100",
                      attrs: { id: "session-list" },
                    },
                    [
                      _c("thead", { staticClass: "mb-0" }, [
                        _c("tr", { staticClass: "mb-3" }, [
                          _c(
                            "th",
                            {
                              staticClass: "text-left no-wrap",
                              staticStyle: { "vertical-align": "left" },
                            },
                            [_vm._v(_vm._s(_vm.$t("From Device")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-left no-wrap",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("From Device ID")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-left no-wrap",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("To Device")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-left no-wrap",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("To Device ID")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("Organization")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right no-wrap",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("Started at")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-right no-wrap",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("Ended at")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("Duration")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              staticStyle: { "vertical-align": "middle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("Notes")))]
                          ),
                          _c("th", {
                            staticClass: "text-center",
                            staticStyle: { "vertical-align": "middle" },
                          }),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          !_vm.sessions
                            ? _c("tr", { staticClass: "pt-10" }, [
                                _c("td", {
                                  staticClass: "p-6",
                                  attrs: {
                                    colspan: "7",
                                    "data-role": "activity",
                                    "data-type": "metro",
                                    "data-style": "dark",
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._l(_vm.sessions, function (session, index) {
                            return [
                              _c("tr", [
                                _c("td", { staticClass: "no-wrap" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        session.firstname +
                                          " " +
                                          session.lastname +
                                          " (" +
                                          session.name_computer_control +
                                          ")"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(session.id_remote_control)),
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "text-center no-wrap" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(session.name_computer_host) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "text-center no-wrap" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(session.id_remote_host) + " "
                                    ),
                                  ]
                                ),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(session.organization_name) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "text-right no-wrap" },
                                  [
                                    session.dt_start != "" &&
                                    session.dt_start != null
                                      ? _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(session.dt_start)
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "text-right no-wrap" },
                                  [
                                    session.dt_end != "" &&
                                    session.dt_end != null
                                      ? _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(session.dt_end)
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c("td", { staticClass: "text-right" }, [
                                  session.dt_end != "" && session.dt_end != null
                                    ? _c(
                                        "label",
                                        { staticClass: "text-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.diffDate(
                                                session.dt_start,
                                                session.dt_end
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: session.notes[0],
                                        expression: "session.notes[0]",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      "data-role": "input",
                                    },
                                    domProps: { value: session.notes[0] },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          session.notes,
                                          0,
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", [
                                  _c(
                                    "button",
                                    { staticClass: "button small" },
                                    [_vm._v(_vm._s(_vm.$t("Save")))]
                                  ),
                                ]),
                              ]),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "w-100 bg-white bordered border border-top-none",
            staticStyle: { height: "calc(75vh)", overflow: "auto" },
            attrs: { id: "all_computers" },
          },
          [
            _vm.computers
              ? _c(
                  "table",
                  {
                    staticClass: "striped compact table w-100",
                    attrs: { id: "computers-list" },
                  },
                  [
                    _c("thead", { staticClass: "mb-0" }, [
                      _c("tr", { staticClass: "mb-3" }, [
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("Registered"))),
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "text-left",
                            staticStyle: {
                              "vertical-align": "middle",
                              width: "100px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Device")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-left",
                            staticStyle: {
                              "vertical-align": "middle",
                              "min-width": "300px",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Last Backup")) + " ")]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              width: "100px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Groups")) + " ")]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: { "vertical-align": "middle" },
                          },
                          [_vm._v(_vm._s(_vm.$t("Organization")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Private IP")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Public IP")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Remote Ver.")))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "vertical-align": "middle",
                              "white-space": "nowrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("Backup Ver.")))]
                        ),
                        _c("td", {
                          staticClass: "text-center",
                          staticStyle: {
                            "vertical-align": "middle",
                            width: "100px",
                          },
                        }),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      [
                        !_vm.computers
                          ? _c("tr", { staticClass: "pt-10" }, [
                              _c("td", {
                                staticClass: "p-6",
                                attrs: {
                                  colspan: "7",
                                  "data-role": "activity",
                                  "data-type": "metro",
                                  "data-style": "dark",
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._l(_vm.computers, function (computer, id) {
                          return _c("tr", { key: id }, [
                            _c("td", { staticClass: "text-center" }, [
                              computer.backup_license_status == 1
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "button flat-button",
                                      attrs: {
                                        title: _vm.$t("Connected to license"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.removeFromLicense(
                                            computer.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "icon mif-2x mif-link_on",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              computer.backup_license_status == 2
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "button flat-button",
                                      attrs: {
                                        "data-cls-popover": " drop-shadow",
                                        "data-role": "popover",
                                        "data-popover-position": "right",
                                        "data-popover-text":
                                          "<img width='632' height='172' src='/assets/taskmanager.png' />",
                                        title: _vm.$t(
                                          "Waiting to restart agent"
                                        ),
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "icon mif-2x mif-link_off fg-orange",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              computer.backup_license_status == 3
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "button flat-button",
                                      attrs: {
                                        title: _vm.$t(
                                          "Disconnected from license"
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.reconnectToLicense(
                                            computer.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "icon mif-2x mif-link_off",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              computer.backup_license_status == 4
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "button flat-button",
                                      attrs: { title: _vm.$t("Banned") },
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "icon mif-2x mif-link_off fg-grey",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-left" },
                              [_c("PCLink", { attrs: { pc: computer } })],
                              1
                            ),
                            _c("td", { staticClass: "text-left" }, [
                              computer.last_job_result != "" &&
                              computer.last_job_name != ""
                                ? _c("div", [
                                    _c("span", {
                                      staticClass:
                                        "c-context-menu mif-checkmark mr-1",
                                      class: {
                                        "fg-red": computer.last_job_result == 0,
                                        "fg-green":
                                          computer.last_job_result == 1,
                                        "fg-orange":
                                          computer.last_job_result == 2,
                                        "fg-darkgray":
                                          computer.last_job_result == 99,
                                      },
                                    }),
                                    computer.last_job_name
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                computer.last_job_name ?? ""
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("br"),
                                    computer.last_job_dt_start
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatDate(
                                                  computer.last_job_dt_start
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    computer.last_job_dt_start
                                      ? _c("span", [
                                          _vm._v(
                                            " (" +
                                              _vm._s(
                                                _vm.fromNow(
                                                  computer.last_job_dt_start
                                                )
                                              ) +
                                              ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "flat-button",
                                  attrs: {
                                    "data-cls-hint": "drop-shadow",
                                    "data-role": "hint",
                                    "data-hint-text":
                                      _vm.$root.createhintGroups(
                                        computer.groups
                                      ),
                                  },
                                },
                                [_vm._v(_vm._s(computer.groups.length) + " ")]
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(computer.organization_name)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(computer.ipaddress_lan) + " "),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(computer.ipaddress_public) + " "),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(computer.version_remote) + " "),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(computer.version_backup) + " "),
                            ]),
                            _c(
                              "td",
                              [
                                computer.id_remote != ""
                                  ? _c("PCRemoteLink", {
                                      attrs: { pc: computer },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "tertiary",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openComputerView(computer)
                                    },
                                  },
                                },
                                [_c("span", { staticClass: "mif-menu" })]
                              ),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "icon" }, [
      _c("span", { staticClass: "mif-server" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "icon" }, [
      _c("span", { staticClass: "mif-warning" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "icon" }, [
      _c("span", { staticClass: "mif-warning" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "icon" }, [
      _c("span", { staticClass: "mif-warning" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }