<template>
    <div class="dialog-title">
        <span class="caption"> 
        <label  class="badge inline bg-primary fg-white">{{ title == undefined || title == null || title == "" ? defaultTitle : title }} </label>
        <br>
        <label v-if="subTitle!=''" class="p-2">{{ subTitle }}</label></span>
        <span class="button square closer" @click="closeDialog"></span>
    </div>
</template>

<script>
export default {
    name: "DialogTitle",
    props: {
        title: String,
        defaultTitle: String,
        subTitle: String
    },
    methods:{
        closeDialog(){
            this.$emit('closeDialog');
        }
    }
    
}
</script>