<template>
    <div>
        <div class="form-group text-center m-4 ">
            <div class="info-button w-100">
                <a  @click="action" href="#" class="button primary w-100 ">{{title}} 
                    <span v-show="iftrue" class="ani-spin mif-spinner mif-spinner3 p-2"></span></a>                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ButtonLoader",
    props: {
        iftrue:{
            type:Boolean,
            required:true
        },
        action:{
            type:Object,
            required:true
        },
        title: {
            type: Object,
            required: true
        }
    }
}
</script>