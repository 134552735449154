<template>
    <span>
        <span v-if="lastresult == $root.ResultValue.OK" class="fg-green text-bold mif-checkmark" :title="$t('Ok')"></span>
        <span v-if="lastresult == $root.ResultValue.SomeWarnings" class="fg-orange text-bold mif-checkmark" :title="$t('Some Warnings')"></span>
        <span v-if="lastresult == $root.ResultValue.Error" class="fg-red text-bold mif-cross" :title="$t('Error')"></span>
        <span v-if="lastresult == $root.ResultValue.Running" class="fg-black" :title="$t('Running')"> 
            <span v-if="progress!=null" class="text-bold fg-cobalt"> {{progress}}%</span>  
        </span>
        <span v-if="lastresult == $root.ResultValue.Aborted" class="fg-red text-bold mif-checkmark" :title="$t('Aborted by user')"></span>
        <span v-if="lastresult == 90 || lastresult == $root.ResultValue.NeverExecuted"  :title="$t('Never executed')" class="fg-gray text-bold mif-checkmark"> </span>
        <span v-if="lastresult == $root.ResultValue.TerminatedAbnormally" class="fg-red text-bold mif-cross-light" :title="$t('Abnormally terminated')"></span>
</span>
</template>
<script>
export default {
    name: "BackupLastResult",
    props: {
        lastresult: {
            type: Number,
            required: true
        },
        progress: {
            type: Number,
            required: false
        }
    }

}
</script>