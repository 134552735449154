<template :key="componentKey">

    <header v-cloak data-role="appbar" class="d-flex  w-100  bg-white bd-gray border-bottom" data-expand-point="xl">
        
        <div class="app-bar-container w-100 flex-justify-between">
            <div class="d-flex flex-align-center">
                
                <router-link to="/dashboard">
                    <img src="/assets/img/brand/iperius_one_v3.png" height="66" alt="Iperius One Logo" class="pl-4 pt-1">
                </router-link>
            </div>

            <ul class="bg-transparent  h-menu">
        <!--<li v-if="user">
                <a href="#" :title="$t('last jobs executed')" class="app-bar-item ">
                    <span class="icon">
                        <span class="mif-server"></span>
                        <span class="badge bg-primary fg-white mt-2 mr-1">4</span>
                    </span>
                </a>
            </li>
            <li v-if="user">
                <a href="#" class="app-bar-item">
                    <span class="mif-envelop"></span>
                    <span class="badge bg-primary fg-white mt-2 mr-1">4</span>
                </a>
            </li> -->
                <li v-if="user">
                    <a href="#" class="app-bar-item ">
                        <span class="mif-flag "></span>
                        <span class="badge bg-primary fg-white mt-2 mr-1">{{ events.length }}</span>
                    </a>
                    <div class="d-menu border  bg-white mt-1" data-role="dropdown">
                        <div class="d-flex flex-justify-end">
                            <div class="flat-button">{{ $t('Set all read') }}</div>
                        </div>
                        <ul class="m-0 p-0">
                            <li class="text-small" v-for="(event) in events" :key="event.id"
                                :class="{ 'text-bold': event.is_read, 'fg-gray': !event.is_read }">
                                <a>{{ event.msg }}<span class="mr-3 text-small">{{ event.subtitle }}</span> </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li v-if="user">
                    <a href="#" class="dropdown-toggle app-bar-item"  >
                       <label class=" pl-4 text-bold">{{ $session.get("AUTH")?.firstname +' '+ $session.get("AUTH")?.lastname + ' ('+ $session.get("AUTH")?.username + ')' }}</label> 
                    </a>
                    <ul class="d-menu border  w-100 bg-white"  id="userdropdown" data-role="dropdown" >

                        <li class="text-right text-bold"><a @click="$parent.$parent.logout()"><span class="mif-bin"></span>
                                {{ $t("Logout") }}</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <locale-changer />
                </li>
                
            </ul>
        </div>
    </header>

</template>

<script>
import LocaleChangerVue from '../components/LocaleChanger.vue';

export default {
    name: 'Appbar',
    components: {
        'locale-changer': LocaleChangerVue,
    },
    props: {
        user: {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            componentKey: 0,
            events: []
        };
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        }
    },
    mounted() {
        var self = this;
        if (self.user) {


        }

    },
}
</script>