<template>
    <div class="w-100 overflow">
        <Navigation pageName="groups" />
        <div v-if="filteredgroups == null" class="d-flex flex-justify-center  p-10">
            <span class="mif-spinner2 fg-primary ani-spin mif-3x " />
        </div>

        <NoDataAvailable v-else-if="filteredgroups.length == 0" :message="$t('No groups available')" />

        <div v-else class="p-10">
            <table class="table table-border compact striped bg-white m-0  " :data-check=false data-check-name="chkBox"
                :data-table-search-title='$t("Search Groups")' data-cls-search="w-100-sm w-25-xl w-25-lg w-50-md"
                data-show-activity="true" data-cell-wrapper="false" data-role="table" data-rows="20"
                data-show-rows-steps="false" data-show-pagination="true" data-horizontal-scroll="true">
                <thead>
                    <tr>
                        <th class="sortable-column">{{ $t("Name") }}</th>
                        <th class="sortable-column">{{ $t("Description") }}</th>
                        <th class="sortable-column" data-cls-column="text-center  no-wrap">{{ $t("Email") }}</th>
                        <th data-cls-column="text-center no-wrap" class="text-center no-wrap">{{ $t("Phone Number") }}
                        </th>
                        <th>{{ $t("Address") }}</th>
                        <th data-cls-column="text-center  no-wrap" class="text-center no-wrap">{{ $t("Devices") }}</th>
                        <th data-cls-column="text-center  no-wrap" class="text-center no-wrap">{{ $t("Users") }}</th>
                        <th></th>

                    </tr>
                </thead>
                <tbody>

                        <tr v-for="group in groups" class="mx-8 p-4  rounded m-6"  v-bind:key="group.id_group" >
                            <td> 
                                <a class="text-bold text-left m-0 button flat-button" :href="'#'+group.id_group">
                                     {{group.name_group}}
                                </a>
                               <!--  <router-link :to="'/group/'+group.id_group"  class="text-bold text-left m-0 button flat-button">
                                    {{group.name_group}}
                                </router-link> -->
                            </td>
                            <td>
                                {{group.notes_group}} 
                            </td>    
                            <td>
                                {{group.email_group}} 
                            </td>    
                            <td>
                                {{group.phone_number}} 
                            </td>    
                            <td>
                                {{group.address_group}} 
                            </td>    
                            
                            <td>
                               {{group.num_computers}} 
                            </td> 
                            <td>
                               {{group.num_users}} 
                            </td> 
                           
                             <td>
                                 <ul class="listview ">
                                     <li class="mr-2">
                                        <a :href="'#'+group.id_group"
                                        class="button bg-transparent"><span class="mif-pencil"></span>
                                    </a>
                                    </li>
                                   
                                    <li> <a @click="deleteGroup(group)"
                                            class="button mr-2 bg-transparent"><span class="mif-bin "></span></a>
                                    </li>
                                </ul>
            
                            </td> 
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>

</template>
<script>

import Navigation from './utils/Navigation.vue';
import NoDataAvailable from "./utils/NoDataAvailable.vue";
export default {
    name: "Groups",
    components: {
        Navigation,
        NoDataAvailable
    },
    watch: {
        '$route': {
            handler: function (to, from) {
                if (to.hash != "" && to.hash.endsWith("/delete")) {
                    var groupID = to.hash.split("/")[0].replace("#", "");
                    var groupName = this.groups.find(g => g.id_group == groupID).name_group;
                    this.deleteGroup(groupID, groupName);
                    return;
                }
                if (to.hash != "") {
                    var id_group = to.hash.replace("#", "");
                    this.openDialogGroup(id_group)
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() { return { groups: null, filteredgroups: null } },
    mounted: function () {
        this.groups = this.$session.get("GROUPS");
        this.filteredgroups = this.groups;
        this.$root.$on("filteredString", (data) => this.filteredList(data));
        let self = this;
        this.$root.$on("refreshGroups", () => {
            self.groups = self.$session.get("GROUPS");
            self.filteredgroups = this.groups;
        });
    },
    methods: {
        logNavigation(event) {
            console.log('Navigating to:', { name: 'Group', params: { idGroup: this.group.id_group } });
        },

        async openDialogGroup(id_group) {
            var group = await this.$root.api("GETGROUP", id_group);
            this.$root.$emit("OPENGROUPVIEWDIALOG", group, this.$session.get('COMPUTERS'), this.$session.get('USERS'));
        },

        filteredList(data) {
            this.filteredgroups = this.groups;
            this.filteredgroups = this.groups.filter((group) => {
                return group.name_group.toLowerCase().includes(data.toLowerCase())
            });
        },

        async deleteGroup(groupID, groupName) {
            //Personalizzo la dialog da mostrare
            var dialogTitle = "Delete '" + groupName + "'?";
            var dialogContent = "Are you sure you want to delete the '" + groupName + "' group?";
            var okText = "Yes";
            var okClass = "alert";
            var okResult = true;

            var cancelText = "No";
            var cancelClass = "";
            var cancelResult = false;

            let self = this;
            this.$root.$on(dialogContent, async ok => {
                //Se l'utente non conferma la cancellazione, riporto l'URL a /groups
                if (!ok) {
                    self.$router.replace({ path: '/groups' });
                    return;
                }
                var ok = await self.$root.api("DELETEGROUP", groupID);

                //Se la cancellazione del gruppo non va a buon fine, riporto l'URL a /groups
                if (!ok) {
                    self.$router.replace({ path: '/groups' });
                    return;
                }

                //Se la cancellazione va a buon fine, rimuovo il gruppo dalle liste in locale e riporto l'URL a /groups
                self.groups.splice(self.groups.findIndex(g => g.id_group == groupID), 1);
                self.$session.set("GROUPS", self.groups);
                //self.filteredgroups.splice(self.filteredgroups.findIndex(g => g.id_group == groupID), 1);
                self.$router.replace({ path: '/groups' });
                window.location.reload();
                self.$root.$off(dialogContent);
            });

            //Avvio la richiesta che mi risponderà con un $emit
            this.$root.openDialogActions(dialogTitle, dialogContent, okText, okClass, okResult, cancelText, cancelClass, cancelResult)
        }
    }
}
</script>
