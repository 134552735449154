var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.group != null
    ? _c(
        "div",
        { staticClass: "w-100 overflow" },
        [
          _vm.group != null
            ? _c("Navigation", {
                attrs: { pageName: "group", data: { group: _vm.group } },
              })
            : _vm._e(),
          _vm.group == null
            ? _c("div", { staticClass: "d-flex flex-justify-center p-10" }, [
                _c("span", {
                  staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
                }),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.fullStats,
                  expression: "fullStats",
                },
              ],
              staticClass: "row p-5",
            },
            [
              _c("div", { staticClass: "cell-lg-3 cell-md-3 col-sm-6 mt-2" }, [
                _c("div", { staticClass: "more-info-box card rounded p-2" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("h2", { staticClass: "text-bold mb-0" }, [
                      _vm._v(
                        _vm._s(_vm.fullStats?.nOfWorkstations) +
                          "/" +
                          _vm._s(_vm.fullStats?.nOfServers)
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("Workstations")) +
                          "/" +
                          _vm._s(_vm.$t("Servers"))
                      ),
                    ]),
                  ]),
                  _vm._m(0),
                ]),
              ]),
              _c("div", { staticClass: "cell-lg-3 cell-md-3 col-sm-6 mt-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "more-info-box card rounded p-2",
                    class:
                      _vm.fullStats?.nPcDiskAlert != 0 ||
                      _vm.fullStats?.nPcRamAlert != 0
                        ? "bg-red"
                        : "",
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("h2", { staticClass: "text-bold mb-0" }, [
                        _vm._v(
                          _vm._s(_vm.fullStats?.nPcDiskAlert) +
                            "/" +
                            _vm._s(_vm.fullStats?.nPcRamAlert)
                        ),
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("Devices with Disk/Ram Alert"))),
                      ]),
                    ]),
                    _vm._m(1),
                  ]
                ),
              ]),
              _c("div", { staticClass: "cell-lg-3 cell-md-3 col-sm-6 mt-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "more-info-box card rounded p-2",
                    class: _vm.fullStats?.nPcAvAlert != 0 ? "bg-red" : "",
                  },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("h2", { staticClass: "text-bold mb-0" }, [
                        _vm._v(_vm._s(_vm.fullStats?.nPcAvAlert)),
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("Devices with Antivirus Alert"))),
                      ]),
                    ]),
                    _vm._m(2),
                  ]
                ),
              ]),
            ]
          ),
          _vm.group.computers == null || _vm.group.computers.length == 0
            ? _c("NoDataAvailable", {
                attrs: { message: _vm.$t("No computers available") },
              })
            : _vm._e(),
          _vm.group.computers.length != 0
            ? _c("div", { staticClass: "p-10" }, [
                _c("h3", { staticClass: "p-2" }, [
                  _vm._v(_vm._s(_vm.$t("Devices"))),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table border compact striped d-table-lg bg-white m-0",
                      attrs: { "data-role": "" },
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { staticClass: "text-left" }),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Description")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Organization")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("OS Version")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Private IP")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Public IP")))]),
                          _c("th", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(_vm.$t("Remote Connection"))),
                          ]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.group.computers, function (pc) {
                          return _c("tr", { key: pc.id, staticClass: "p-4" }, [
                            _c(
                              "td",
                              { staticClass: "text-left" },
                              [_c("PCLink", { attrs: { pc: pc } })],
                              1
                            ),
                            _c("td", [
                              pc.description
                                ? _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-small text-center p-0 m-0",
                                    },
                                    [_vm._v(" " + _vm._s(pc.description))]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [
                              pc.description
                                ? _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-small text-center p-0 m-0",
                                    },
                                    [_vm._v(" " + _vm._s(pc.organization))]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [
                              pc.description
                                ? _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-small text-center p-0 m-0",
                                    },
                                    [_vm._v(" " + _vm._s(pc.os_version_full))]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c(
                                "label",
                                {
                                  staticClass: "text-small text-center p-0 m-0",
                                },
                                [_vm._v(" " + _vm._s(pc.ipaddress_lan))]
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "label",
                                {
                                  staticClass: "text-small text-center p-0 m-0",
                                },
                                [_vm._v(" " + _vm._s(pc.ipaddress_public))]
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "label",
                                {
                                  staticClass: "text-small text-center p-0 m-0",
                                },
                                [_c("PCRemoteLink", { attrs: { pc: pc } })],
                                1
                              ),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.group.users.length == 0
            ? _c("NoDataAvailable", {
                attrs: { message: _vm.$t("No users available") },
              })
            : _vm._e(),
          _vm.group != null && _vm.group.users.length != 0
            ? _c("div", { staticClass: "p-10" }, [
                _c("h3", { staticClass: "p-2" }, [
                  _vm._v(_vm._s(_vm.$t("Users"))),
                ]),
                _c(
                  "table",
                  { staticClass: "table border striped bg-white m-0 compact" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.$t("Username")))]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("Lastname")))]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("Firstname")))]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("Role")))]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("2FA"))),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.group.users, function (user) {
                        return _c(
                          "tr",
                          { key: user.id, staticClass: "mx-8 p-4 rounded m-6" },
                          [
                            _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "text-bold text-left m-0 flat-button",
                                },
                                [_vm._v(" " + _vm._s(user.username) + " ")]
                              ),
                            ]),
                            _c("td", [
                              _vm._v(" " + _vm._s(user.lastname) + " "),
                            ]),
                            _c("td", [
                              _vm._v(" " + _vm._s(user.firstname) + " "),
                            ]),
                            _c("td", {}, [
                              _c(
                                "label",
                                { staticClass: "text-bold text-left m-0" },
                                [_vm._v(_vm._s(user.role))]
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c("span", {
                                staticClass: "text-center mif-2x",
                                class: user.mfa_enabled ? "mif-security" : "",
                              }),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("span", { staticClass: "mif-server" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("span", { staticClass: "mif-cogs" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("span", { staticClass: "mif-security" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }