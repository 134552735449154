<template>
    <span class="badge inline text-small px-2 py-1 m-1" :class="'bg-'+ bgColor +' fg-'+ txtColor">{{text}}</span>
</template>
<script>
export default{
    name:'mBadge',
    props:{
        text:{
            type:String,
            required:true
        },
        bgColor:{
            type:String,
            required:true
        },
        txtColor:{
            type:String,
            required:true
        }
    }
}
</script>