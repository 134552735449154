<template>
    <div class="dialog overflow center text-small  overflow w-100-sm w-100-md w-75-lg  w-50-xl" v-cloak>

        <DialogTitle :title="serviceAccount.id == null ? $t('Add Service Account') : serviceAccount.name"
            :defaultTitle="serviceAccount.id == null ? $t('Add Service Account') : $t('Edit/Test Service Account')"
            :subTitle="serviceAccount.type_name" @closeDialog="$root.$emit('CLOSESERVICEACCOUNTDIALOG')" />


        <div class="dialog-content">

            <!--------------------------FORM--------------------------------------------->
            <div class="form-group">
                <label>{{ $t("Name") }}</label>
                <input data-role="input" type="text" v-model="serviceAccount.name" maxlength="50"
                    :placeholder="$t('Enter friendly name')" data-prepend="<span class='mif-pencil'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!--------------MICROSOFT - MODERN AUTH ---------------------------->
            <!--15/01/24 : Tolta la basic per exchange 365-->
            <div v-show="!$root.isExchangeOnPremises(serviceAccount.type)">
                <div class="form-group">
                    <label v-if="serviceAccount.type != 13">{{ $t("Administrator email") }}</label>
                    <label v-else>{{ $t("User email") }}</label>
                    <input data-role="input" v-model="serviceAccount.username" type="text" required />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                <div class="form-group">
                    <label>{{ $t("Application name") }}</label>
                    <input data-role="input" v-model="serviceAccount.options.appName" type="text" required />
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
                <div class="form-group">
                    <label>{{ $t("Region") }}</label>
                    <select class="select w-auto" v-model="serviceAccount.region">
                        <option v-for="region in regions" :value="region.Url" :key="region.Url">{{
            region.Name
        }}</option>
                    </select>
                    <small class="text-muted">{{ $t("Required") }}</small>
                </div>
            </div>

            <!------EXCHANGE ON PREMISES = username, password, server, domain, include child domains------------->
            <div v-show="$root.isExchangeOnPremises(serviceAccount.type)" class="form-group">
                <label>{{ $t("Server") }}</label>
                <input data-role="input" type="text" class="metro-input" v-model="serviceAccount.options.server"
                    :placeholder="$t('Enter Server')" data-prepend="<span class='mif-cloud'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <div v-show="$root.isExchangeOnPremises(serviceAccount.type)" class="form-group">
                <div class="row">
                    <div class="cell-8">
                        <label>{{ $t("Domain") }}</label>
                        <input data-role="input" class="metro-input" type="text" v-model="serviceAccount.options.domain"
                            :placeholder="$t('Enter Domain')" data-prepend="<span class='mif-cloud'></span>" />
                        <small class="text-muted">{{ $t("Required") }}</small>
                    </div>
                    <div class="cell-4 pt-6">
                        <input data-role="checkbox" type="checkbox" :data-caption="$t('Include child domains')"
                            data-validate="required" v-model="serviceAccount.options.IncludeChildDomains" required
                            data-prepend="<span class='mif-server'></span>" />
                    </div>
                </div>
            </div>

            <div v-show="$root.isExchangeOnPremises(serviceAccount.type)" class="form-group">
                <label>{{ $t("Username") }}</label>
                <input data-role="input" class="metro-input" v-model="serviceAccount.username" type="text" required
                    data-prepend="<span class='mif-user'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <div v-show="$root.isExchangeOnPremises(serviceAccount.type)" class="form-group">
                <label>{{ $t("Password") }}</label>
                <input data-role="input" class="metro-input" type="password" v-model="serviceAccount.password"
                    :placeholder="$t('Enter Password')" data-prepend="<span class='mif-lock'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!-------------------------------SCELTA PC----------------------------------------->
            <div v-if="$root.isExchangeOnPremises(serviceAccount.type) && $session.get('ROOMPC') == null"
                class="form-group">
                <label>
                    <span>{{ $t("Select PC to Test Service Account") }}</span>
                </label>
                <select class="select cell-9" v-model="testPC" :disabled="$session.get('COMPUTERS').length == 0">
                    <option :data-append="pc.note" v-for="pc in $session.get('COMPUTERS')" :value="pc"
                        :disabled="!$session.get('PCSCONNECTIONS')[pc.id]" v-bind:key="pc.id"> {{ pc.name }}
                    </option>
                </select>
            </div>


        </div>

        <!-------------------------------TEST ACCOUNT----------------------------------------->
        <div class="dialog-actions d-flex " :class="step == null ? 'flex-justify-end' : 'flex-justify-between'">
            <!--Durante l'autenticazione a Exchange 365, verrà mostrato un messaggio che indica il corrente step -->
            <div v-if="step != null" class="text-light fg-cobalt">{{ $t(step) }}</div>
            <div class="d-flex flex-justify-end flex-align-center">
                <button class="button alert mr-2" @click="$root.$emit('CLOSESERVICEACCOUNTDIALOG')">{{ $t("Cancel") }}</button>
                <div v-if="tested == TEST_WAIT"><span class="mif-spinner2 ani-spin mr-2" /></div>
                <button class="button primary mr-2" @click="test" :disabled="!enableTest">
                    <span class="mif-checkmark pr-2" /> {{ $t("Test account") }}
                </button>
                <button class="button primary ml-2" :disabled="!enableSave"
                    @click="$root.saveServiceAccount(serviceAccount)">
                    <span class="mif-checkmark pr-2" /> {{ $t("Save") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import DialogTitle from "../../utils/DialogTitle.vue";
import { cookieManager } from "../../../../public/assets/js/utilitiesmodule";
export default {
    name: "MicrosoftServiceAccount",
    components: {
        "DialogTitle": DialogTitle,
    },
    props: {
        serviceAccount: Object,
    },
    data() {
        return {
            testPC: null,

            // -1 : avviato
            // 0 : non avviato o fallito
            // 1 : ok
            tested: 0,
            TEST_NOTOK: 0,
            TEST_WAIT: -1,
            TEST_OK: 1,

            regions: [],

            step: null,
            readStepCookie: null,

            forbiddenChars : "!@#$%^&*()=+{}[]\\|;:\"'<>,.?/",
            forbiddenNames : [
                "Microsoft", "Azure", "Office", "Windows", "Dynamics", "Outlook", "Teams",
                "OneDrive", "SharePoint", "PowerBI", "VisualStudio", "MSFT", "MS",
                "WindowsServer", "Intune", "Exchange", "Google", "Gmail", "Android",
                "YouTube", "GoogleCloud", "Amazon", "AWS", "Alexa", "Prime",
                "AmazonWebServices", "Apple", "iCloud", "iPhone", "iPad", "iOS",
                "Facebook", "Instagram", "WhatsApp", "Oculus", "IBM", "Watson",
                "Oracle", "Java", "Salesforce", "Heroku", "LinkedIn", "Twitter",
                "Netflix", "Spotify", "Slack", "Zoom", "Adobe", "Dropbox", "GitHub",
                "Pinterest", "Snapchat", "TikTok", "Hulu", "PayPal", "Stripe",
                "Admin", "Support", "Service", "Helpdesk", "Root", "System",
                "Default", "User", "Guest"
            ]
        }
    },
    computed: {
        allInputCompleted() {
            var specificInputsCompleted;
            switch (this.serviceAccount.type) {
                case this.$root.PlatformsTypesEnum.Microsoft_Exchange365:
                case this.$root.PlatformsTypesEnum.Microsoft_SharePoint:
                case this.$root.PlatformsTypesEnum.Microsoft_Teams:
                case this.$root.PlatformsTypesEnum.Microsoft_OneDriveForBusiness:
                case this.$root.PlatformsTypesEnum.Cloud_OneDrive:
                    specificInputsCompleted =
                        this.serviceAccount.username != "" &&
                        this.serviceAccount.region != "" &&
                        this.serviceAccount.options.appName != "";
                    break;
                case this.$root.PlatformsTypesEnum.Microsoft_ExchangeOnPremises:
                case this.$root.PlatformsTypesEnum.Microsoft_ExchangeOnPremisesEWS:
                    specificInputsCompleted = this.serviceAccount.server != "" &&
                        this.serviceAccount.domain != "" &&
                        this.serviceAccount.username != "" &&
                        this.serviceAccount.password != "";
                    break;
            }
            return this.serviceAccount.name != "" &&
                //il pc è richiesto solo nel caso di exchange on premises
                (!this.$root.isExchangeOnPremises(this.serviceAccount.type) || this.$session.get('ROOMPC') != null || this.testPC != null)
                && specificInputsCompleted;
        },
        enableTest() {
            return this.allInputCompleted && this.tested == this.TEST_NOTOK;
        },
        enableSave() {
            return this.allInputCompleted && (this.tested == this.TEST_OK || (this.tested == this.TEST_NOTOK && this.serviceAccount.id != null));
        }

    },
    async created() {
        if (this.serviceAccount.options.lastTestPC != null)
            this.testPC = this.$session.get("COMPUTERS").find(pc => pc.id == this.serviceAccount.options.lastTestPC);
        this.regions = await this.$root.api("GETAMZREGIONS", this.$root.PlatformsTypesEnum.Microsoft_Exchange365);
    },


    methods: {

        async test() {
            let self = this;
            self.tested = -1; // test in corso

            //Exchange On-Premises
            if (this.$root.isExchangeOnPremises(self.serviceAccount.type)) {
                self.tested = (await this.$root.testServiceAccount(self.serviceAccount, self.testPC)).okNumber;
                return;
            }

            //Exchange 365 + ModernAuth

            var checkedName = this.validateAndReplace(self.serviceAccount.name);
            if(checkedName == null){


            }
            cookieManager.setCookie("step", "");
            //Se il service account è di tipo Exchange 265, avvio un timer che legge il cookie che conterrà un messaggio che descrive il corrente step
            self.readStepCookie = setInterval(
                () => { self.step = cookieManager.getCookie("step"); },
                2000
            );
            var testResult = await this.$root.testServiceAccount(self.serviceAccount);
            self.tested = testResult.okNumber;
            if (testResult.ok) {
                self.serviceAccount.client_id = testResult.serviceAccount.client_id;
                self.serviceAccount.client_secret = testResult.serviceAccount.client_secret;
                self.serviceAccount.options = testResult.serviceAccount.options;
                self.serviceAccount.token = testResult.serviceAccount.token;
                self.serviceAccount.refresh_token = testResult.serviceAccount.refresh_token;
            }

            clearInterval(self.readStepCookie);
            self.readStepCookie = null;
            self.step = cookieManager.getCookie("step");
        },

        validateAndReplace(inputString) {
            // Controllo se la stringa contiene un nome proibito
            const lowerCaseInput = inputString.toLowerCase();
            for (let name of this.forbiddenNames) {
                if (lowerCaseInput.includes(name.toLowerCase())) {
                    return false;
                }
            }

            // Sostituzione dei caratteri proibiti con lo spazio
            /*let processedString = inputString;
            for (let char of forbiddenChars) {
                processedString = processedString.split(char).join(' ');
            }*/
            for (let char of this.forbiddenChars) {
                inputString = inputString.split(char).join(' ');
            }
            inputString = inputString.trimStart().trimEnd();
            while(inputString.indexOf("  ") != -1){
                inputString = inputString.replace("  ", " ");
            }

            return inputString;
        }
    }
}

</script>