var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dialog overflow center text-small overflow w-100-sm w-100-md w-75-lg w-50-xl",
    },
    [
      _c("DialogTitle", {
        attrs: {
          title:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.serviceAccount.name,
          defaultTitle:
            _vm.serviceAccount.id == null
              ? _vm.$t("Add Service Account")
              : _vm.$t("Edit/Test Service Account"),
          subTitle: _vm.serviceAccount.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
          },
        },
      }),
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("Name")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.serviceAccount.name,
                expression: "serviceAccount.name",
              },
            ],
            attrs: {
              "data-role": "input",
              type: "text",
              maxlength: "50",
              placeholder: _vm.$t("Enter friendly name"),
              "data-prepend": "<span class='mif-pencil'></span>",
            },
            domProps: { value: _vm.serviceAccount.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.serviceAccount, "name", $event.target.value)
              },
            },
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("Required"))),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.$root.isExchangeOnPremises(_vm.serviceAccount.type),
                expression: "!$root.isExchangeOnPremises(serviceAccount.type)",
              },
            ],
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _vm.serviceAccount.type != 13
                ? _c("label", [_vm._v(_vm._s(_vm.$t("Administrator email")))])
                : _c("label", [_vm._v(_vm._s(_vm.$t("User email")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.serviceAccount.username,
                    expression: "serviceAccount.username",
                  },
                ],
                attrs: { "data-role": "input", type: "text", required: "" },
                domProps: { value: _vm.serviceAccount.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount,
                      "username",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Application name")))]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.serviceAccount.options.appName,
                    expression: "serviceAccount.options.appName",
                  },
                ],
                attrs: { "data-role": "input", type: "text", required: "" },
                domProps: { value: _vm.serviceAccount.options.appName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.serviceAccount.options,
                      "appName",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Region")))]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.region,
                      expression: "serviceAccount.region",
                    },
                  ],
                  staticClass: "select w-auto",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.serviceAccount,
                        "region",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.regions, function (region) {
                  return _c(
                    "option",
                    { key: region.Url, domProps: { value: region.Url } },
                    [_vm._v(_vm._s(region.Name))]
                  )
                }),
                0
              ),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("Required"))),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$root.isExchangeOnPremises(_vm.serviceAccount.type),
                expression: "$root.isExchangeOnPremises(serviceAccount.type)",
              },
            ],
            staticClass: "form-group",
          },
          [
            _c("label", [_vm._v(_vm._s(_vm.$t("Server")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.serviceAccount.options.server,
                  expression: "serviceAccount.options.server",
                },
              ],
              staticClass: "metro-input",
              attrs: {
                "data-role": "input",
                type: "text",
                placeholder: _vm.$t("Enter Server"),
                "data-prepend": "<span class='mif-cloud'></span>",
              },
              domProps: { value: _vm.serviceAccount.options.server },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.serviceAccount.options,
                    "server",
                    $event.target.value
                  )
                },
              },
            }),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("Required"))),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$root.isExchangeOnPremises(_vm.serviceAccount.type),
                expression: "$root.isExchangeOnPremises(serviceAccount.type)",
              },
            ],
            staticClass: "form-group",
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "cell-8" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("Domain")))]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.options.domain,
                      expression: "serviceAccount.options.domain",
                    },
                  ],
                  staticClass: "metro-input",
                  attrs: {
                    "data-role": "input",
                    type: "text",
                    placeholder: _vm.$t("Enter Domain"),
                    "data-prepend": "<span class='mif-cloud'></span>",
                  },
                  domProps: { value: _vm.serviceAccount.options.domain },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.serviceAccount.options,
                        "domain",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("Required"))),
                ]),
              ]),
              _c("div", { staticClass: "cell-4 pt-6" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serviceAccount.options.IncludeChildDomains,
                      expression: "serviceAccount.options.IncludeChildDomains",
                    },
                  ],
                  attrs: {
                    "data-role": "checkbox",
                    type: "checkbox",
                    "data-caption": _vm.$t("Include child domains"),
                    "data-validate": "required",
                    required: "",
                    "data-prepend": "<span class='mif-server'></span>",
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.serviceAccount.options.IncludeChildDomains
                    )
                      ? _vm._i(
                          _vm.serviceAccount.options.IncludeChildDomains,
                          null
                        ) > -1
                      : _vm.serviceAccount.options.IncludeChildDomains,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.serviceAccount.options.IncludeChildDomains,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "IncludeChildDomains",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.serviceAccount.options,
                              "IncludeChildDomains",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.serviceAccount.options,
                          "IncludeChildDomains",
                          $$c
                        )
                      }
                    },
                  },
                }),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$root.isExchangeOnPremises(_vm.serviceAccount.type),
                expression: "$root.isExchangeOnPremises(serviceAccount.type)",
              },
            ],
            staticClass: "form-group",
          },
          [
            _c("label", [_vm._v(_vm._s(_vm.$t("Username")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.serviceAccount.username,
                  expression: "serviceAccount.username",
                },
              ],
              staticClass: "metro-input",
              attrs: {
                "data-role": "input",
                type: "text",
                required: "",
                "data-prepend": "<span class='mif-user'></span>",
              },
              domProps: { value: _vm.serviceAccount.username },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.serviceAccount, "username", $event.target.value)
                },
              },
            }),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("Required"))),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$root.isExchangeOnPremises(_vm.serviceAccount.type),
                expression: "$root.isExchangeOnPremises(serviceAccount.type)",
              },
            ],
            staticClass: "form-group",
          },
          [
            _c("label", [_vm._v(_vm._s(_vm.$t("Password")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.serviceAccount.password,
                  expression: "serviceAccount.password",
                },
              ],
              staticClass: "metro-input",
              attrs: {
                "data-role": "input",
                type: "password",
                placeholder: _vm.$t("Enter Password"),
                "data-prepend": "<span class='mif-lock'></span>",
              },
              domProps: { value: _vm.serviceAccount.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.serviceAccount, "password", $event.target.value)
                },
              },
            }),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("Required"))),
            ]),
          ]
        ),
        _vm.$root.isExchangeOnPremises(_vm.serviceAccount.type) &&
        _vm.$session.get("ROOMPC") == null
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("Select PC to Test Service Account"))),
                ]),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.testPC,
                      expression: "testPC",
                    },
                  ],
                  staticClass: "select cell-9",
                  attrs: {
                    disabled: _vm.$session.get("COMPUTERS").length == 0,
                  },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.testPC = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.$session.get("COMPUTERS"), function (pc) {
                  return _c(
                    "option",
                    {
                      key: pc.id,
                      attrs: {
                        "data-append": pc.note,
                        disabled: !_vm.$session.get("PCSCONNECTIONS")[pc.id],
                      },
                      domProps: { value: pc },
                    },
                    [_vm._v(" " + _vm._s(pc.name) + " ")]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-actions d-flex",
          class: _vm.step == null ? "flex-justify-end" : "flex-justify-between",
        },
        [
          _vm.step != null
            ? _c("div", { staticClass: "text-light fg-cobalt" }, [
                _vm._v(_vm._s(_vm.$t(_vm.step))),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex flex-justify-end flex-align-center" },
            [
              _c(
                "button",
                {
                  staticClass: "button alert mr-2",
                  on: {
                    click: function ($event) {
                      return _vm.$root.$emit("CLOSESERVICEACCOUNTDIALOG")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              ),
              _vm.tested == _vm.TEST_WAIT
                ? _c("div", [
                    _c("span", { staticClass: "mif-spinner2 ani-spin mr-2" }),
                  ])
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "button primary mr-2",
                  attrs: { disabled: !_vm.enableTest },
                  on: { click: _vm.test },
                },
                [
                  _c("span", { staticClass: "mif-checkmark pr-2" }),
                  _vm._v(" " + _vm._s(_vm.$t("Test account")) + " "),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "button primary ml-2",
                  attrs: { disabled: !_vm.enableSave },
                  on: {
                    click: function ($event) {
                      return _vm.$root.saveServiceAccount(_vm.serviceAccount)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "mif-checkmark pr-2" }),
                  _vm._v(" " + _vm._s(_vm.$t("Save")) + " "),
                ]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }