<template>
    <div class="row p-1 pl-6 pr-6">
        <div class="cell d-flex flex-align-center p-0 ">
            <h3 class="dashboard-section-title text-left-md m-0">
                <ul class="breadcrumbs">
                    <button class="ml-3 mr-3  button square" id="menu-toggle">   
                        <span class="mif-menu"></span>
                    </button>
                    <button  @click="$root.goBack()" v-if="pageName != 'dashboard'" class="page-link button">
                        <i class=" mif-arrow-left"></i>
                    </button>
                    <li class="page-item">
                        <router-link to="/dashboard" class="page-link pl-0">
                            <table>
                                <tr>
                                    <td class="px-1 py-0" :class="{'h2':pageName == 'dashboard','p-4':pageName!='dashboard'}">{{ $t("Dashboard") }}</td>
                                </tr>
                                <tr v-if="pageName == 'dashboard'">
                                    <td class="px-1 py-0">{{ $t("License") }} : {{ $t($session.get('licenza').assignee) }}</td> 
                                </tr>
                            </table>
                        </router-link>
                    </li>

                  

                    <li v-if="accounts" class="page-item">
                        <router-link to="/accounts" class="page-link">
                            <span v-if="pageName == 'accounts'" class="mr-1"><svg  style="width: 26;height: 26;"  width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.249 13.995h5.876c-.349.423-.574.952-.62 1.53l-.009.22v4.5c0 .665.236 1.275.63 1.75l-.13.001c-3.42 0-5.943-1.072-7.486-3.236A2.75 2.75 0 0 1 2 17.164v-.92a2.249 2.249 0 0 1 2.249-2.249Zm10.497-2.5h3c.648 0 1.18.492 1.244 1.123l.007.127-.001 1.25h1.25c.967 0 1.75.784 1.75 1.75v4.5a1.75 1.75 0 0 1-1.75 1.75h-8a1.75 1.75 0 0 1-1.75-1.75v-4.5c0-.966.784-1.75 1.75-1.75h1.25v-1.25c0-.647.492-1.18 1.123-1.243l.127-.007h3-3Zm2.75 1.5h-2.5v1h2.5v-1ZM9.997 2a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z" fill="#212121"/></svg></span>
                            <span>{{ $t("Credentials Locker") }}</span>
                        </router-link>
                    </li>

                    <li v-if="repositories" class="page-item">
                        <router-link to="/repo" class="page-link">
                            <span v-if="pageName == 'repositories'"  class="mr-1"><svg style="width: 26;height: 26;" width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a4 4 0 0 1 4 4v2h2.5A1.5 1.5 0 0 1 18 9.5V11c-.319 0-.637.11-.896.329l-.107.1c-.812.845-1.656 1.238-2.597 1.238-.783 0-1.4.643-1.4 1.416v2.501c0 2.374.924 4.22 2.68 5.418L3.5 22A1.5 1.5 0 0 1 2 20.5v-11A1.5 1.5 0 0 1 3.5 8H6V6a4 4 0 0 1 4-4Zm8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.385.385 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.389.389 0 0 1 .566 0ZM10 13.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM10 4a2 2 0 0 0-2 2v2h4V6a2 2 0 0 0-2-2Z" fill="#212121"/></svg></span>
                            <span>{{ $t("Backup Repositories") }}</span>
                        </router-link>
                    </li>

                    <li v-if="computer && $session.get('ROOMPC') != null && pc" class="page-item">
                        <router-link :to="'/computer/' + $session.get('ROOMPC').id" class="page-link  no-wrap">
                           <!-- <span class="mr-1" :class="$root.connectionsStates[pc.id] ? 'fg-green' : 'fg-red'"> ● </span>  -->{{ pc.name }}<br>
                        </router-link>
                              
                    </li>

                    <li v-if="backup" class="page-item">
                        <router-link to="/backup/" class="page-link"><span v-if="pageName == 'backup'"
                                class="mr-1 mif-plus" />{{ data.editing ? $t('Edit') + ' ' + data.backupName :'Create Backup' }}</router-link>
                    </li>

                    <li v-if="restore" class="page-item">
                        <router-link to="/restore" class="page-link"><span v-if="pageName == 'restore'"
                                class="mr-1 mif-arrow-up" />{{ 'Restore Backup' }}</router-link>
                    </li>

                    <li v-if="backupView" class="page-item">
                        <router-link :to="'/backupview/' + data.job.id" class="page-link  no-wrap "><span
                                v-if="pageName == 'backupview'" class="mr-1 mif-news" />{{data.job.name }}</router-link>
                    </li>

                    <li v-if="groups" class="page-item">
                        <router-link to="/groups" class="page-link"><span v-if="pageName == 'groups'"
                                class="mr-1" />{{ $t("Groups") }}</router-link>
                                
                    </li>

                    <li v-if="group" class="page-item">
                        <router-link :to="'/group/' + data.group.id" class="page-link  no-wrap "><span v-if="pageName == 'group'"
                                class="mr-1 mif-folder-open" />{{data.group.name }}</router-link>
                    </li>

                    <li v-if="users" class="page-item">
                        <router-link to="/users" class="page-link"><span v-if="pageName == 'users'" />{{ $t("Users") }}</router-link>
                    </li>
                    <li v-if="pageName == 'settings'" class="page-item">
                        <router-link to="/settings" class="page-link"><span 
                                class="mr-1 " />{{ $t("Settings") }}</router-link>
                    </li>
                    <li v-if="pageName == 'schedulation'" class="page-item">
                        <router-link to="/scheduling" class="page-link"><span 
                                class="mr-1 " />{{ $t("Backup Scheduling") }}</router-link>
                    </li>
                    <li v-if="pageName == 'remotestats'" class="page-item">
                        <router-link to="/remotestats" class="page-link"><span 
                                class="mr-1 " />{{ $t("Remote statistics") }}</router-link>
                    </li>
                    <li v-if="pageName == 'backupstats'" class="page-item">
                        <router-link to="/backupstats" class="page-link"><span 
                                class="mr-1 " />{{ $t("Backup statistics") }}</router-link>
                    </li>
                    <li v-if="pageName == 'sessions'" class="page-item">
                        <router-link to="/sessions" class="page-link"><span 
                                class="mr-1 " />{{ $t("Sessions") }}</router-link>
                    </li>
                    <li v-if="pageName == 'activesessions'" class="page-item">
                        <router-link to="/sessions" class="page-link"><span 
                                class="mr-1 " />{{ $t("Active Sessions") }}</router-link>
                    </li>
                </ul>
            </h3>
        </div>


        <div v-if="pageName == 'computers'" class="cell d-flex flex-align-center flex-justify-end">
            <ul class="listview">
                <li>
                    <router-link class="button primary fg-white mr-2" to="/repo">
                        <span class="mr-1 icon p-0 m-0 pr-1 "><svg style="width: 26;height: 26;" width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a4 4 0 0 1 4 4v2h2.5A1.5 1.5 0 0 1 18 9.5V11c-.319 0-.637.11-.896.329l-.107.1c-.812.845-1.656 1.238-2.597 1.238-.783 0-1.4.643-1.4 1.416v2.501c0 2.374.924 4.22 2.68 5.418L3.5 22A1.5 1.5 0 0 1 2 20.5v-11A1.5 1.5 0 0 1 3.5 8H6V6a4 4 0 0 1 4-4Zm8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.385.385 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.389.389 0 0 1 .566 0ZM10 13.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM10 4a2 2 0 0 0-2 2v2h4V6a2 2 0 0 0-2-2Z" fill="#212121"/></svg></span>
                        <span class="text-small  fg-white">{{ $t("Backup Repositories") }}</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/accounts" class="button primary fg-white ">
                        <span class="mr-1 icon p-0 m-0 pr-1"></span>
                        <span class="text-small fg-white">{{ $t("Credentials Locker") }}</span>
                    </router-link>
                </li>
                <li class="ml-2">
                     <input type="text" @change="emitFilter" @input="emitFilter" data-role="input" data-search-button="true" placeholder="Search..." />
                </li>

            </ul>
        </div>
        <div v-if="pageName == 'groups'" class="cell d-flex flex-align-center flex-justify-end pr-1">
            <ul class="listview">
                <li>
                       <a class="button secondary mr-2" @click="$root.$emit('OPENGROUPVIEWDIALOG')">
                        <span class="icon mif-2x mif-add "></span>
                        <span class="">{{$t("ADD GROUP")}}</span>
                    </a>
                </li>
                <li>
                    <a :title="$t('Refresh all Iperius Backup')" class="button" >
                        <span class="icon mif-2x mif-refresh "></span>  
                        <span>{{ $t("Refresh all Iperius Backup") }}</span>
                    </a>
                </li>
            </ul>
        </div>
       
        <div v-if="pageName == 'dashboard'" class="cell d-flex flex-align-center flex-justify-end ">
            <ul class="listview">
                <li>
                     
                    <a  class="button secondary ml-4" onclick="window.Metro.charms.toggle('#plugin');" >
                        <span class="mif-add-shopping-cart mif-2x pr-2"></span>{{ $t("Add License") }}
                    </a>
               
                </li>
                <li class="ml-2">
                     <a class="button secondary" onclick="window.Metro.charms.toggle('#agentbuilding');" >
                        <span class="icon mif-download "></span>
                        <span class="">{{$t("Agent download")}}</span>
                    </a>
                </li>
               
                
                                
            </ul>
        </div>
        <div v-if="pageName == 'users'" class="cell d-flex flex-align-center flex-justify-end ">
            <ul class="listview">
                 <li>
                    <a class="button secondary mr-2" @click="$root.$emit('OPENUSERVIEWDIALOG')">
                        <span class="icon mif-2x mif-add "></span>
                        <span class="">{{$t("ADD USER")}}</span>
                    </a>
                </li>
               
                
                                
            </ul>
        </div>
        <div v-if="pageName == 'computer'" class="cell d-flex flex-align-center flex-justify-end pr-4">
             <div class="">
                    <ul class="listview">
                        
                        <router-link :to="this.$route.path+'/backup'" class="mr-2 text-bold" :disabled="!$session.get('PCSCONNECTIONS')[pc.id]">
                                <button class="button secondary">{{$t("Add Backup")}}</button>
                        </router-link>

                       
                        <div class="dropdown-button place-right mr-2">
                            <a  class="button dropdown-toggle secondary  pl-5" >
                                <div class="text-left "> {{$t('Restore Backup')}}   </div>
                            </a>
                            <ul class="d-menu border  place-right" data-role="dropdown">
                                <template v-if="platformsTypes!=null" v-for="t in restoreTypes"  :disabled="!$session.get('PCSCONNECTIONS')[pc.id]" >
                                    <li  v-if="t == 5" v-for="microsoft_t in $root.getMicrosoftTypes()" :key="microsoft_t"  class="">
                                        <a @click="$root.$emit('OPENRESTOREDIALOG', microsoft_t, null)">Microsoft <b>{{ platformsTypes[microsoft_t].name }}</b></a>
                                    </li>
                                    <li  v-if="t != 5"  >
                                        <a @click="$root.$emit('OPENRESTOREDIALOG', t, null)"><b>{{ platformsTypes[t].name }}</b></a>
                                    </li>
                                </template>
                            
                            </ul> 
                        </div>

                        <PCRemoteLink class="mr-2" :pc="pc" :disabled="!$session.get('PCSCONNECTIONS')[pc.id]"/>
    
                        <button class="button secondary" onclick="window.Metro.charms.toggle('#notifications-pc');">
                            <span class="mif-cogs"></span> 
                        </button>
                
                        
                    </ul>
                </div>
        </div>
      
           
      
    </div>
</template>
<script>

import moment from 'moment';
import {
    platformsTypes,
   
} from "../../../public/assets/js/utilitiesmodule";
import PCRemoteLink from '../../components/utils/PCRemoteLink.vue';
export default {
    name: "Navigation",
     components: {
        "PCRemoteLink": PCRemoteLink,
    },
    props: {
        pageName: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: false
        },
        childIframeSrc: {
            type: String,
            required: false
        },
        childisNotificationsVisible: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            input:"",
            back: false,
            computers: false,
            computer: false,
            backup: false,
            restore: false,
            backupView: false,
            accounts: false,
            repositories: false,
            groups: false,
            group: false,
            users: false,
            pc: null,
            platformsTypes:null,
                // questo è l'array che contiene le voci di sottomenu del bottone restore backup
                restoreTypes: [this.$root.PlatformsTypesEnum.Microsoft, 
                this.$root.PlatformsTypesEnum.HyperV, 
                this.$root.PlatformsTypesEnum.ESXi, 
                this.$root.PlatformsTypesEnum.FileFolder, 
                this.$root.PlatformsTypesEnum.Network, 
                this.$root.PlatformsTypesEnum.Database_SQLServer ] ,
            
        }
    },
    methods:{

  
    toggleNotifications() {                     
        window.Metro.charms.toggle("#notifications");
    },

    async getOTP()  {
            var Key_OTP =await  this.$root.api("GETOTP", { pcId: this.pc.id });
            console.log(Key_OTP);

            var win = window.open('iperiusremote://' + Key_OTP + '/', '_blank');
            win.getValue = function () {
                return Key_OTP;
            };
        
        },

    emitFilter: function(event){
        this.$root.$emit("filteredString", event.target.value);
    },   

    formatDate(dateString) {
      // Usa date-fns per formattare la data
      //return format(new Date(dateString), 'yyyyMMddHHmmss');
      return moment(dateString).utc().format('yyyyMMDDHHmmss');
    },
 
    },
    created() {
        this.platformsTypes = platformsTypes;
        switch (this.pageName.toLowerCase()) {
            case "computers":
                this.computers = true;
                this.back = true;
                break;

            case "accounts":
                this.back = true;
                this.computers = true;
                this.accounts = true;
                break;

            case "repositories":
                this.back = true;
                this.computers = true;
                this.repositories = true;
                break;

            case "computer":
                this.back = true;
                this.computers = true;
                this.computer = true;
                this.pc = this.data.pc;
                break;

            case "backup": // data deve contenere il booleano editing
                this.back = true;
                this.computers = true;
                this.computer = true;
                this.backup = true;
                this.pc = this.data.pc;
                break;

            case "restore":
                this.back = true;
                this.computers = true;
                this.computer = true;
                this.restore = true
                break;

            case "backupview":// data deve contenere job.id, job.name
                this.back = true;
                this.computers = true;
                this.computer = true;
                this.backupView = true
                break;

            case "groups":
                this.back = true;
                this.groups = true;
                break;

            case "group": // data deve contenere group.id, group.name
                this.back = true;
                this.groups = true;
                this.group = true;
                break;

            case "users":
                this.back = true;
                this.users = true;
                break;
           
            case "settings":
                this.back = true;
                break;

            case "remotestats":
                this.back = true;
                break;                

        }
    }
}
</script>