var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-group text-center m-4" }, [
      _c("div", { staticClass: "info-button w-100" }, [
        _c(
          "a",
          {
            staticClass: "button primary w-100",
            attrs: { href: "#" },
            on: { click: _vm.action },
          },
          [
            _vm._v(_vm._s(_vm.title) + " "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.iftrue,
                  expression: "iftrue",
                },
              ],
              staticClass: "ani-spin mif-spinner mif-spinner3 p-2",
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }