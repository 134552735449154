var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "p-2 mb-2 tree-item",
      class: { hovered: _vm.hover, "selected-item": _vm.item.selected },
      on: {
        mouseover: function ($event) {
          _vm.hover = true
        },
        click: _vm.selectItem,
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "tree-item-header",
          attrs: { title: _vm.item.name + " [" + _vm.item.os_version + "]" },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.selected,
                expression: "item.selected",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.item.selected)
                ? _vm._i(_vm.item.selected, null) > -1
                : _vm.item.selected,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.item.selected,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.item, "selected", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.item,
                          "selected",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.item, "selected", $$c)
                  }
                },
                function ($event) {
                  return _vm.updateSelection(_vm.item)
                },
              ],
            },
          }),
          _c("span", { staticClass: "mif-computer mif-lg ml-1" }),
          _c("span", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.item.name))]),
        ]
      ),
      _vm.item.virtualHardDisks
        ? _c(
            "ul",
            _vm._l(_vm.item.virtualHardDisks, function (disk) {
              return _c("TreeDisk", { key: disk.id, attrs: { disk: disk } })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }