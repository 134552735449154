var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.source.hvSource != null
    ? _c(
        "div",
        {
          staticClass: "dialog overflow w-100-sm w-100-md w-75-lg w-50-xl",
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.$root.$emit("CLOSESOURCEDIALOG")
            },
          },
        },
        [
          _c("DialogTitle", {
            attrs: {
              title: _vm.$t("Configure Source"),
              defaultTitle: _vm.$t("Configure Source"),
              subTitle: _vm.source.type_name,
            },
            on: {
              closeDialog: function ($event) {
                return _vm.$root.$emit("CLOSESOURCEDIALOG")
              },
            },
          }),
          _vm.source.hvSource == ""
            ? _c("div", { staticClass: "dialog-content" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "h-100 d-flex flex-align-center flex-column flex-justify-center py-4",
                  },
                  [
                    _c("span", { staticClass: "mif-info mif-6x fg-gray" }),
                    _c("h5", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("No virtual machine available"))),
                    ]),
                  ]
                ),
              ])
            : _c(
                "div",
                {
                  staticClass: "dialog-content",
                  staticStyle: { overflow: "hidden" },
                },
                [
                  _c("div", { staticClass: "info-box-content h-100" }, [
                    _c(
                      "div",
                      { staticClass: "bd-default no-border-top h-100" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.steps[0],
                                expression: "steps[0]",
                              },
                            ],
                            staticClass: "p-1 h-100",
                          },
                          [
                            _c("h6", { staticClass: "pl-5 mb-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Select Virtual Machines you want to back up"
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "w-100 d-flex flex-align-left" },
                              [
                                _c("div", { staticClass: "p-3 w-75" }, [
                                  _vm.source.hvSource.length
                                    ? _c(
                                        "ul",
                                        { attrs: { "data-role": "treeview" } },
                                        _vm._l(
                                          _vm.source.hvSource,
                                          function (hvsource) {
                                            return _c("TreeItem", {
                                              key: hvsource.guid,
                                              attrs: {
                                                item: hvsource,
                                                hvselected: _vm.hvselected,
                                                selectedCount:
                                                  _vm.selectedCount,
                                              },
                                              on: {
                                                "update:hvselected": function (
                                                  $event
                                                ) {
                                                  _vm.hvselected = $event
                                                },
                                                "update:selectedCount":
                                                  function ($event) {
                                                    _vm.selectedCount = $event
                                                  },
                                                "update:selected-count":
                                                  function ($event) {
                                                    _vm.selectedCount = $event
                                                  },
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                                _vm.hvselected
                                  ? _c("div", { staticClass: "p-3" }, [
                                      _c("h5", { staticClass: "w-100" }, [
                                        _c("span", {
                                          staticClass: "mif-computer",
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.hvselected.name)
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          attrs: { "data-role": "buttongroup" },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button rounded m-1 small",
                                              class: {
                                                disabled:
                                                  _vm.hvselected.status == 2,
                                              },
                                              attrs: {
                                                title: _vm.machine_status[2],
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.changeVmStatusHyperV(
                                                    _vm.hvselected,
                                                    2
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "mif-play",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button rounded m-1 small",
                                              class: {
                                                disabled:
                                                  _vm.hvselected.status == 3,
                                              },
                                              attrs: {
                                                title: _vm.machine_status[3],
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.changeVmStatusHyperV(
                                                    _vm.hvselected,
                                                    3
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "mif-stop",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button rounded m-1 small",
                                              class: {
                                                disabled:
                                                  _vm.hvselected.status == 9,
                                              },
                                              attrs: {
                                                title: _vm.machine_status[9],
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.changeVmStatusHyperV(
                                                    _vm.hvselected,
                                                    9
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "mif-pause",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button rounded m-1 small",
                                              class: {
                                                disabled:
                                                  _vm.hvselected.status == 6,
                                              },
                                              attrs: {
                                                title: _vm.machine_status[6],
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.changeVmStatusHyperV(
                                                    _vm.hvselected,
                                                    6
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "mif-bookmarks",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button rounded m-1 small",
                                              class: {
                                                disabled:
                                                  _vm.hvselected.status == 4,
                                              },
                                              attrs: {
                                                title: _vm.machine_status[4],
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.changeVmStatusHyperV(
                                                    _vm.hvselected,
                                                    4
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "mif-switch",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("hr"),
                                      _c(
                                        "table",
                                        { staticClass: "w-100 text-secondary" },
                                        [
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Status")) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.machine_status[
                                                        _vm.hvselected.status
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Version")) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.hvselected.version
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("td", [_vm._v(" RAM ")]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.hvselected.status == 2
                                                        ? _vm.hvselected.ram
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Integrated Services"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.hvselected.vss
                                                        ? "Enabled"
                                                        : "Disabled"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Number of Snapshots"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.hvselected
                                                        .nofSnapshots
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("hr"),
                                      _c("div", [
                                        _c("h6", { staticClass: "mb-3" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("Backup Options"))
                                          ),
                                        ]),
                                        _c(
                                          "label",
                                          { staticClass: "text-secondary" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.hvselected.backupOptions
                                                      .changeVMstatus,
                                                  expression:
                                                    "hvselected.backupOptions.changeVMstatus",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                "data-role": "checkbox",
                                                "data-caption": _vm.$t(
                                                  "Change VM status before the backup"
                                                ),
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.hvselected.backupOptions
                                                    .changeVMstatus
                                                )
                                                  ? _vm._i(
                                                      _vm.hvselected
                                                        .backupOptions
                                                        .changeVMstatus,
                                                      null
                                                    ) > -1
                                                  : _vm.hvselected.backupOptions
                                                      .changeVMstatus,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.hvselected
                                                        .backupOptions
                                                        .changeVMstatus,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.hvselected
                                                            .backupOptions,
                                                          "changeVMstatus",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.hvselected
                                                            .backupOptions,
                                                          "changeVMstatus",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.hvselected
                                                        .backupOptions,
                                                      "changeVMstatus",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.hvselected.backupOptions
                                                    .changeVMstatus,
                                                expression:
                                                  "hvselected.backupOptions.changeVMstatus",
                                              },
                                            ],
                                            staticClass: "select w-100",
                                            attrs: {
                                              disabled:
                                                _vm.hvselected.backupOptions
                                                  .changeVMstatus == false ||
                                                _vm.hvselected.backupOptions
                                                  .changeVMstatus == null,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.hvselected.backupOptions,
                                                  "changeVMstatus",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "3" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Turn off the VM")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "6" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Put in Saved State")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "9" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Quiesce the VM")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            staticClass: "p-2 h-100",
                          },
                          [
                            _c("h6", { staticClass: "pl-5" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Select the type of Hyper-V backup you want to configure"
                                  )
                                ) + ":"
                              ),
                            ]),
                            _c("div", { staticClass: "form-group p-4" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.source.options.rct,
                                    expression: "source.options.rct",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  "data-role": "radio",
                                  "data-caption": _vm.$t(
                                    "Backup RCT (Resilient Change Tracking)"
                                  ),
                                },
                                domProps: {
                                  value: 1,
                                  checked: _vm._q(_vm.source.options.rct, 1),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.source.options,
                                      "rct",
                                      1
                                    )
                                  },
                                },
                              }),
                              _c("br"),
                              _c("small", {}, [
                                _vm._v(_vm._s(_vm.$t(_vm.messages[0])) + "."),
                              ]),
                              _c("br"),
                              _c("small", {}, [
                                _vm._v(_vm._s(_vm.$t(_vm.messages[1]))),
                              ]),
                            ]),
                            _c("div", { staticClass: "form-group p-4" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.source.options.rct,
                                    expression: "source.options.rct",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  "data-role": "radio",
                                  "data-caption": _vm.$t(
                                    "Direct Copy of single disks and config. files"
                                  ),
                                },
                                domProps: {
                                  value: 2,
                                  checked: _vm._q(_vm.source.options.rct, 2),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.source.options,
                                      "rct",
                                      2
                                    )
                                  },
                                },
                              }),
                              _c("br"),
                              _c("small", {}, [
                                _vm._v(_vm._s(_vm.$t(_vm.messages[2]))),
                              ]),
                              _c("br"),
                              _c("small", {}, [
                                _vm._v(_vm._s(_vm.$t(_vm.messages[3]))),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.steps[1],
                                expression: "steps[1]",
                              },
                            ],
                            staticClass: "p-4 h-100",
                          },
                          [
                            _c("h6", [
                              _vm._v(
                                _vm._s(_vm.$t("Configure backup options")) + ":"
                              ),
                            ]),
                            _c("div", { staticClass: "form-group row" }, [
                              _c("div", { staticClass: "cell-8" }, [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$t("Backup Type"))),
                                ]),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.source.options.backupType,
                                        expression: "source.options.backupType",
                                      },
                                    ],
                                    staticClass: "select w-100",
                                    attrs: {
                                      disabled: _vm.source.options.rct == 2,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.source.options,
                                          "backupType",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "F" } }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("Full backup Only"))
                                      ),
                                    ]),
                                    _c("option", { attrs: { value: "I" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "One full Backup + Incremental Backups"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("option", { attrs: { value: "D" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "One full Backup + Differential Backups"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "cell-4" }, [
                                _c("label", [_vm._v(_vm._s(_vm.$t("Copies")))]),
                                _c("input", {
                                  attrs: {
                                    type: "text",
                                    "data-role": "spinner",
                                    id: "nCopiesSpinner",
                                    "data-default-value": "1",
                                    "data-min-value": "1",
                                    "data-max-value": "99",
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.source.options.autoAddNewItems,
                                    expression:
                                      "source.options.autoAddNewItems",
                                  },
                                ],
                                attrs: {
                                  "data-role": "checkbox",
                                  type: "checkbox",
                                  "data-caption": _vm.$t(
                                    "Add automatically every new virtual machine to the backup"
                                  ),
                                  "data-validate": "required",
                                  required: "",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.source.options.autoAddNewItems
                                  )
                                    ? _vm._i(
                                        _vm.source.options.autoAddNewItems,
                                        null
                                      ) > -1
                                    : _vm.source.options.autoAddNewItems,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.source.options.autoAddNewItems,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.source.options,
                                            "autoAddNewItems",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.source.options,
                                            "autoAddNewItems",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.source.options,
                                        "autoAddNewItems",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.source.enableCompression,
                                      expression: "source.enableCompression",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    "data-role": "checkbox",
                                    "data-caption":
                                      _vm.$t("Enable Compression"),
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.source.enableCompression
                                    )
                                      ? _vm._i(
                                          _vm.source.enableCompression,
                                          null
                                        ) > -1
                                      : _vm.source.enableCompression,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.source.enableCompression,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.source,
                                              "enableCompression",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.source,
                                              "enableCompression",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.source,
                                          "enableCompression",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false",
                                  },
                                ],
                                staticClass: "form-group w-50",
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("Archive File Custom Name")) +
                                      " "
                                  ),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.lazy",
                                      value:
                                        _vm.source.options
                                          .archiveFileCustomName,
                                      expression:
                                        "source.options.archiveFileCustomName",
                                      modifiers: { lazy: true },
                                    },
                                  ],
                                  attrs: {
                                    disabled: !_vm.source.enableCompression,
                                    type: "text",
                                    "data-role": "input",
                                  },
                                  domProps: {
                                    value:
                                      _vm.source.options.archiveFileCustomName,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.source.options,
                                        "archiveFileCustomName",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  { staticClass: "text-small text-muted" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "If blank, default name will be used"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.source.options.useArchivePassword,
                                      expression:
                                        "source.options.useArchivePassword",
                                    },
                                  ],
                                  attrs: {
                                    disabled: !_vm.source.enableCompression,
                                    type: "checkbox",
                                    "data-role": "checkbox",
                                    "data-caption": _vm.$t(
                                      "Protect zip with Password"
                                    ),
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.source.options.useArchivePassword
                                    )
                                      ? _vm._i(
                                          _vm.source.options.useArchivePassword,
                                          null
                                        ) > -1
                                      : _vm.source.options.useArchivePassword,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.source.options.useArchivePassword,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.source.options,
                                              "useArchivePassword",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.source.options,
                                              "useArchivePassword",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.source.options,
                                          "useArchivePassword",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm.source.enableCompression &&
                            _vm.source.options.useArchivePassword
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group w-50" },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(_vm.$t("Archive Password"))
                                          ),
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.source.options
                                                  .archivePassword,
                                              expression:
                                                "source.options.archivePassword",
                                            },
                                          ],
                                          staticClass: "metro-input",
                                          attrs: {
                                            "data-role": "input",
                                            type: "password",
                                            placeholder:
                                              _vm.$t("Enter Password"),
                                            "data-prepend":
                                              "<span class='mif-lock'></span>",
                                          },
                                          domProps: {
                                            value:
                                              _vm.source.options
                                                .archivePassword,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.source.options,
                                                "archivePassword",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-muted" },
                                          [_vm._v(_vm._s(_vm.$t("Required")))]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
          _c("div", { staticClass: "dialog-actions" }, [
            _c("div", { staticClass: "ml-auto" }, [
              _c(
                "button",
                {
                  staticClass: "button alert",
                  on: {
                    click: function ($event) {
                      return _vm.$root.$emit("CLOSESOURCEDIALOG")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              ),
              _c(
                "button",
                {
                  staticClass: "button ml-2",
                  class: {
                    disabled: _vm.source.hvSource == "" || _vm.steps[0],
                  },
                  on: { click: _vm.back },
                },
                [
                  _c("span", { staticClass: "mif-arrow-left" }),
                  _vm._v(_vm._s(_vm.$t("Prev"))),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "button ml-2",
                  class: {
                    disabled:
                      _vm.source.hvSource == "" ||
                      _vm.steps[1] ||
                      _vm.selectedCount <= 0,
                  },
                  on: { click: _vm.next },
                },
                [
                  _vm._v(_vm._s(_vm.$t("Next"))),
                  _c("span", { staticClass: "mif-arrow-right" }),
                ]
              ),
              _vm.steps[1]
                ? _c(
                    "button",
                    {
                      staticClass: "button primary ml-2",
                      on: { click: _vm.saveSource },
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  )
                : _vm._e(),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }