<template>
    <div class="w-100 overflow">
        <Navigation pageName="activesessions" />
            <div id="activesessions" class="p-5 w-100 h-100">
                    <table v-if="controls" id="controls-list" class="panel bg-white  compact table w-100 mt-4">
                        <thead class="mb-0">
                            <tr class="mb-3">

                                <td class="text-center" style="vertical-align: middle;">{{ $t("Operator") }}</td>
                                <td class="text-center" style="vertical-align: middle;">{{ $t("Source") }}</td>
                               
                                <td class="text-center" style="vertical-align: middle;"></td>

                                <td class="text-center" style="vertical-align: middle;">{{ $t("Destination") }}</td>
                                <td class="text-center" style="vertical-align: middle;">{{ $t("Start date") }}</td>
                             
                                <td class="text-left" style="vertical-align: middle;"></td>
                                
                            </tr>
                        </thead>

                                <tbody>
                                    <tr class="pt-10" v-if="!controls">
                                        <td class="p-6" colspan="7" data-role="activity" data-type="metro"
                                            data-style="dark"></td>
                                    </tr>
                                    <tr v-for="(control,index) in controls" :key="control.id+'-'+index">
                                        <td class="text-bold text-center">
                                            {{control.firstname + " "+ control.lastname}}
                                        </td>
                                        <td class="d-flex flex-align-center no-wrap text-center flex-justify-center">
                                            <PCLink :pc="{id:control.id,name:control.computer_name,device:control.device,os_version_full:control.os_version_full}"></PCLink>
                                            <PCRemoteLink :pc="{id_remote:control.id_remote,id:control.id}" ></PCRemoteLink> 
                                        </td>

                                        <td style="vertical-align: middle;"><span class="mif mif-2x mif-arrow-right text-center"></span></td>

                                        <td class="d-flex flex-align-center no-wrap text-center flex-justify-center">
                                            <PCLink :pc="{id:control.target_host_guid,name:control.name_computer_host,device:'',os_version_full:''}"></PCLink>
                                             <PCRemoteLink :pc="{id_remote:control.target_host_id,id:control.target_host_guid}" ></PCRemoteLink> 
                                        </td>
                                        
                                        <td class="text-center"><label v-if="control.dt_start != '' && control.dt_start != null">{{ formatDate(control.dt_start) }}</label></td>
                                    
                                        <td class="text-left">
                                            <button class="bg-transparent button fg-red fg-white" :title="$t('Terminate')" ><span class="mif mif-cross"></span></button>
                                        </td> 
                                    </tr>
                        
                                </tbody>
                    </table>
            </div>
    </div>
    
</template>
<script>
import PCLink from './utils/PCLink'
import PCRemoteLink from './utils/PCRemoteLink'
import Navigation from './utils/Navigation.vue';
import NoDataAvailable from "./utils/NoDataAvailable.vue";
 import {  formatDate, diffDate, fromNow } from '../../public/assets/js/utilitiesmodule';
export default {
    name: "ActiveSessions",
    components:{
        Navigation,
        NoDataAvailable,
        PCLink,
        PCRemoteLink
    },
    watch: { 
       
    },
    data() { return { 
        groups: null,
        filteredgroups:null,   
        controls: [],  
        filters: {
            
                    firstline: true,
                    lastline: false,
                    sessionrow: 0,
                    first_dt_start: 0,
                    total_session_inarow: "",
                    headergroupname: "",
                    numrows: 25,
                    offset: 0,
                    lastresult: '',
                    dt_inf:  this.$moment().subtract(5, 'days').format('YYYY-MM-DD'),
                     dt_sup:  this.$moment().format('YYYY-MM-DD'),
                    id_group: '',
                    id_host: '',
                    id_user: '',
                },
                sessions: null,
                users:null,
                groups:null,
                loading:false,
             } },
    async created() {
        //this.$store.commit('SET_LAYOUT', 'nav-layout')
        this.groups = this.$session.get("GROUPS");
        this.users = this.$session.get("USERS");
        
       /*  this.$root.$on("finish", message => {
            var mss = message.split("|");
            self.$root.toast(this.$t(mss[0]), 3000, mss[1]);
        }); */
    }, 
    mounted: function () {
       
        this.$root.api("GETCONTROLS").then(res => {
                    this.controls = res.controls;
                   
            });
    },
    methods:{

    
        formatDate(date) {
            if(date==null || date =="")return ""
            return formatDate(date, this.$moment);
        },
        fromNow(date) {
                if(date==null || date =="")return ""
                return fromNow(date, this.$moment);
            },
        diffDate(datestart, dateend) {
            return diffDate(datestart, dateend, this.$moment);
        },
      
               

     
        
    
        
    
        
    }
}



</script>
