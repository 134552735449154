<template>
    <div>
        <div class="split-button m-1">
            <button class="button m-0 p-0 bg-transparent"  >
                <router-link  class="flat-button p-0 m-0" :class="!$root.connectionsStates[pc.id] ? 'disabled':''" :to="!$root.connectionsStates[pc.id]?'#':'/computer/' + pc.id"  :disabled="!$root.connectionsStates[pc.id]">
                      
                   
                
                <div v-if="pc.description==''">
                     <span class="" :title="$t('Device Health')+' : '+$t( pc.av_alert + pc.ram_alert + pc.disk_alert == 0?'Good':pc.av_alert + pc.ram_alert + pc.disk_alert == 1?'Some Warning':'Errors')"  :class="{ 'fg-green': pc.av_alert + pc.ram_alert + pc.disk_alert == 0, 'fg-orange': pc.av_alert + pc.ram_alert + pc.disk_alert == 1, 'fg-red': pc.av_alert + pc.ram_alert + pc.disk_alert > 1 }">●</span>
                     {{ pc.name }}  
                 
                </div>
                <div v-else class="text-left">
                    <div class="row m-0 d-flex flex-nowrap m-0 mt-2 row">
                        <div class="col m-0">
                             <span class="" :title="$t('Device Health')+' : '+$t( pc.av_alert + pc.ram_alert + pc.disk_alert == 0?'Good':pc.av_alert + pc.ram_alert + pc.disk_alert > 2?'Some Warning':'Errors')"  :class="{ 'fg-green': pc.av_alert + pc.ram_alert + pc.disk_alert == 0, 'fg-orange': pc.av_alert + pc.ram_alert + pc.disk_alert == 1, 'fg-red': pc.av_alert + pc.ram_alert + pc.disk_alert >1 }">●</span>
                        </div>
                        <div class="col m-0">
                            <span class="ml-1">{{ pc.name }}</span>
                            <span class="badge inline bg-transparent ml-0 text-bold" style="display:block">{{ pc.description }}</span>
                        </div>

                    </div>
                </div>
         
             </router-link>
            </button>
            <!--   <button class="split dropdown-toggle"></button>
                <ul class="d-menu" data-role="dropdown">
                    <li>
                        <a :title="$t('Open a Remote connection to', { pcremote : pc.id_remote })" @click="$root.getOTP(pc.id)"  :class="$session.get('connectionsStatesRemote')[pc.id_remote]==true ? 'fg-dark' : 'fg-gray disabled'" >
                            <div class="text-left">
                            {{ $t('Remote Desktop') }} ({{ pc.id_remote }})     
                            </div> 
                        </a>
                    </li>
                    <li class="divider"></li>
                    <li><a href="#">Delete</a></li>
                </ul> -->
        </div>
      
            
      
    </div>
</template>
<script>
export default {
    name: "PCLink",
    props: {
        pc: {
            type: Object,
            required: true
        }
    }/*,
    data: function () {
        return {
            connected: false
        }
    },
    mounted() {
        this.connected = this.$session.get('PCSCONNECTIONS')[this.pc.id];
    },
    watch : {
        connected : function(val){
            this.connected = val;
        }
    } */
}
</script>