<template>
    <span>
        <b v-if="lastresult == $root.ResultValue.OK" class="fg-green" :title="$t('Restore completed successfully')"><span class="mif-checkmark"></span></b>
        <b v-else-if="lastresult == $root.ResultValue.SomeWarnings" class="fg-orange"> {{ $t("Some Warnings") }}</b>
        <b v-else-if="lastresult == $root.ResultValue.Running" class="fg-darkGray"> {{ $t("Running") }} </b>
        <b v-else-if="lastresult == $root.ResultValue.Aborted" class="fg-black"> {{ $t("Aborted by user") }}</b>
        <b v-else-if="lastresult == 90 || lastresult == $root.ResultValue.NeverExecuted" class="fg-black"> {{ $t("Never executed") }}</b>
        <b v-if="lastresult == $root.ResultValue.TerminatedAbnormally" class="fg-red text-bold mif-cross-light" :title="$t('Abnormally terminated')"></b>
        <b v-else class="fg-red"> {{ $t("Some Errors") }}</b>
</span>
</template>
<script>
export default {
    name: "RestoreLastResult",
    props: {
        lastresult: {
            type: Number,
            required: true
        }
    }
}
</script>