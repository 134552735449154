<template>
    <div>
        <div class="loader overflow" v-show="nOpenDialogs != 0" />

        <!-- GENERIC DIALOGS-->
        <GroupDialog v-if="group" :computers="computers" :group="group" :users="users" />
        <UserDialog v-if="user" :selecteduser="user" :checkTFA=checkTFA />
        <!-- <ComputerAgentDialog  v-if="createagent"  /> -->

        <!--------------------------------BACKUP - SORGENTI--------------------------------------------------------------->
        <FolderSource v-if="source != null && source.type == $root.PlatformsTypesEnum.FileFolder" :source="source"
            class="sourceDialog" />

        <NetworkSource v-if="source != null && source.type == $root.PlatformsTypesEnum.Network" :source="source"
            class="sourceDialog" />

        <!--Vengono nascoste se la finestra del service account viene aperta-->
        <DriveImageSource v-if="source != null && source.type == $root.PlatformsTypesEnum.DriveImage"
            :source="source" />

        <HyperVSource v-if="source != null && source.type == $root.PlatformsTypesEnum.HyperV"
            v-show="serviceAccount == null" :source="source" />

        <ESXiSourceDestination v-if="source != null && source.type == $root.PlatformsTypesEnum.ESXi"
            v-show="serviceAccount == null" :source="source" class="sourceDialog" />

        <ExchangeSource v-if="source != null && $root.isExchange(source.type)" v-show="serviceAccount == null"
            :source="source" class="sourceDialog" />

        <DatabaseSource v-if="source != null && $root.isDatabase(source.type)" v-show="serviceAccount == null"
            :source="source" class="sourceDialog" />

        <MicrosoftSource v-if="source != null && $root.isMicrosoft(source.type) && !$root.isExchange(source.type)"
            v-show="serviceAccount == null" :source="source" class="sourceDialog" />

        <!--------------------------------BACKUP - DESTINAZIONI--------------------------------------------------------------->
        <Destination v-if="destination != null" :destination="destination" class="destinationDialog" />

        <!--------------------------RESTORE------------------------------------------------------------------------------->
        <RestoreFile v-if="restore != null && $root.isSimpleFileNFolderType(restore.type)"
            v-show="serviceAccount == null && repository == null" :restore="restore" class="restoreDialog" />

        <!------------------------RESTORE - MANUAL------------------------------------------------------------->
        <div v-if="restore != null && !isValid(restore.idBackup)" v-show="serviceAccount == null">
            <HyperVManualRestore v-if="restore.type == $root.PlatformsTypesEnum.HyperV" :source="restore"
                class="restoreDialog" />

            <ESXiManualRestore v-if="restore.type == $root.PlatformsTypesEnum.ESXi" :source="restore"
                class="restoreDialog" />


            <DatabaseManualRestore v-if="restore.type == $root.PlatformsTypesEnum.Database_SQLServer" :source="restore"
                class="restoreDialog" />
        </div>

        <ExchangeManualRestore v-if="restore != null && $root.isExchange(restore.type)" :restore="restore"
            class="restoreDialog" />

        <!------------------------RESTORE - AUTOMATIC------------------------------------------------------------->
        <div v-if="restore != null && isValid(restore.idBackup)" v-show="serviceAccount == null">
            <ESXiDestinationRestore v-if="restore.type == $root.PlatformsTypesEnum.ESXi" :idJob="selectedJobId"
                :idPC="$session.get('ROOMPC').id" class="restoreDialog" />

            <!--ExchangeDestinationRestore v-if="$root.isExchange(restore.type)" :idJob="selectedJobId"
                :idPC="$session.get('ROOMPC').id" class="restoreDialog" /-->

            <HyperVDestinationRestore v-if="restore.type == $root.PlatformsTypesEnum.HyperV" :idJob="restore.idBackup"
                :idPC="$session.get('ROOMPC').id" class="restoreDialog" />

            <DatabaseDestinationRestore v-if="restore.type == $root.PlatformsTypesEnum.Database_SQLServer"
                :idJob="restore.idBackup" :idPC="$session.get('ROOMPC').id" class="restoreDialog" />
        </div>

        <!--------------------------VISTE----------------------------------------------------------------->
        <BackupView v-if="idBackupForView != null" :idBackup="idBackupForView" />
        <SARepoView v-if="sarepo != null" :repository="sarepo.repository" :serviceAccount="sarepo.serviceAccount" />

        <!------------------------REPOSITORY--------------------------------------------->
        <Repository v-if="isValid(repository)" :repository="repository" class="repositoryDialog" />

        <!--------------------------SERVICE ACCOUNTS-------------------------------------->
        <div v-if="isValid(serviceAccount)">
            <CloudServiceAccount
                v-if="$root.isCloud(serviceAccount.type) && serviceAccount.type != $root.PlatformsTypesEnum.Cloud_OneDrive"
                :serviceAccount="serviceAccount" class="saDialog" />

            <FTPServiceAccount v-if="serviceAccount.type == $root.PlatformsTypesEnum.FTP"
                :serviceAccount="serviceAccount" class="saDialog" />

            <NetworkServiceAccount v-if="serviceAccount.type == $root.PlatformsTypesEnum.Network"
                :serviceAccount="serviceAccount" class="saDialog" />

            <ESXIServiceAccount v-if="serviceAccount.type == $root.PlatformsTypesEnum.ESXi"
                :serviceAccount="serviceAccount" class="saDialog" />
            <!--Exchange + onedrive + sharepoint + teams-->
            <MicrosoftServiceAccount
                v-if="$root.isMicrosoft(serviceAccount.type) || serviceAccount.type == $root.PlatformsTypesEnum.Cloud_OneDrive"
                :serviceAccount="serviceAccount" class="saDialog" />

            <DatabaseServiceAccount v-if="$root.isDatabase(serviceAccount.type)" :serviceAccount="serviceAccount"
                class="saDialog" />

            <EmailServiceAccount v-if="$root.isEmail(serviceAccount.type)" :serviceAccount="serviceAccount"
                class="saDialog" />
        </div>

        <!-------------------------MINI DIALOGS---------------------------------------------------------------------------------------------->
        <!--------------------------FINESTRA DI CREAZIONE DI UNA NUOVA CARTELLA-------------------------------->
        <div v-if="createFolderDialog != null" class="dialog h-auto">
            <div class="dialog-title">{{ $t("Create new folder") }}</div>
            <div class="p-5">
                <div class=" d-flex mb-2 va-baseline">
                    <span class=""><svg width="24" height="24" fill="none" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.821 6.5h5.929a2.25 2.25 0 0 1 2.229 1.938l.016.158.005.154v9a2.25 2.25 0 0 1-2.096 2.245L19.75 20H4.25a2.25 2.25 0 0 1-2.245-2.096L2 17.75v-7.251l6.207.001.196-.009a2.25 2.25 0 0 0 1.088-.393l.156-.12L13.821 6.5ZM8.207 4c.46 0 .908.141 1.284.402l.156.12 2.103 1.751-3.063 2.553-.085.061a.75.75 0 0 1-.29.106L8.206 9 2 8.999V6.25a2.25 2.25 0 0 1 2.096-2.245L4.25 4h3.957Z"
                                fill="#212121" />
                        </svg></span>
                    <span>{{ createFolderDialog.parentPath }}</span>
                </div>
                <input type="text" v-model="createFolderDialog.name" :placeholder="createFolderDialog.name"
                    class="input bg-light" />
            </div>
            <div class="dialog-actions d-flex flex-justify-end flex-align-center">
                <button class="button js-dialog-close primary my-3" @click="createFolder"
                    :disabled="createFolderDialog.name == ''">{{ $t("Save") }}</button>
                <button class="button js-dialog-close alert" @click="closeCreateFolderDialog">{{ $t("Cancel")
                    }}</button>
            </div>
        </div>

        <!--------------------------FINESTRA PER RINOMINARE UNA CARTELLA O UN FILE-------------------------------->
        <div v-if="renameItemDialog != null" class="dialog h-auto">
            <div class="dialog-title">{{ $t("Rename") }}</div>
            <div class="">
                <div class="row text-small flex-align-center mb-2">
                    <span :class="getMetroIcon(renameItemDialog.resource)" class="mr-2" />
                    <span>{{ renameItemDialog.parentPath }}</span>
                </div>
                <input type="text" v-model="renameItemDialog.name" :placeholder="renameItemDialog.name" />
            </div>
            <div class="dialog-actions d-flex flex-justify-end flex-align-center">
                <button class="button js-dialog-close primary my-3" @click="renameItem"
                    :disabled="renameItemDialog.name == ''">{{ $t("Save") }}</button>
                <button class="button js-dialog-close alert" @click="closeRenameItemDialog">{{ $t("Cancel") }}</button>
            </div>
        </div>

        <!--------------------------FINESTRA PER CANCELLARE UNA CARTELLA O UN FILE-------------------------------->
        <div v-if="deleteItemDialog != null" class="dialog h-auto" style="min-height: fit-content;">
            <div class="dialog-title">{{ $t("Do you want delete selected item?") }}</div>
            <div class=" text-small p-4">
                <span :class="getMetroIcon(deleteItemDialog.resource)" class="mr-2" />
                <span>{{ deleteItemDialog.resource.path }}</span>
            </div>
            <div class="dialog-actions d-flex flex-justify-end flex-align-center">
                <button class="button js-dialog-close primary my-3" @click="deleteItem">{{ $t("Yes") }}</button>
                <button class="button js-dialog-close alert" @click="closeDeleteItemDialog">{{ $t("No") }}</button>
            </div>
        </div>

        <!--------------------------FINESTRA PER RINOMINARE UN JOB-------------------------------->
        <div v-if="renameJobDialog != null" class="dialog h-auto"
            style="min-height: fit-content; padding-top: 8px !important;padding-bottom:8px !important;">
            <div class="dialog-title">{{ $t("Rename Job") }}</div>
            <div class="dialog-content d-flex flex-column dialog-content flex-justify-center flex-align-center">
                <s class="mb-4">{{ renameJobDialog.oldName }}</s>
                <input type="text" v-model="renameJobDialog.newName" class="w-75 mb-4"
                    :placeholder="$t('Enter the new name of the backup')" />
            </div>
            <div class="dialog-actions d-flex flex-justify-end flex-align-center ">
                <button class="button js-dialog-close primary my-3" @click="renameJob"
                    :disabled="renameJobDialog.newName == ''">{{ $t("Save") }}</button>
                <button class="button js-dialog-close alert" @click="closeRenameJobDialog">{{ $t("Cancel") }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import GroupDialog from "../components/dialogs/view/GroupDialog"
import UserDialog from "../components/dialogs/view/UserDialog"
//SERVICEACCOUNTS
import CloudServiceAccount from "./dialogs/serviceaccount/CloudServiceAccount";
import DatabaseServiceAccount from "./dialogs/serviceaccount/DatabaseServiceAccount";
import EmailServiceAccount from "./dialogs/serviceaccount/EmailServiceAccount";
import ESXIServiceAccount from "./dialogs/serviceaccount/ESXIServiceAccount";
import MicrosoftServiceAccount from "./dialogs/serviceaccount/MicrosoftServiceAccount";
import FTPServiceAccount from "./dialogs/serviceaccount/FTPServiceAccount";
import NetworkServiceAccount from "./dialogs/serviceaccount/NetworkServiceAccount";
//REPOSITORY
import Repository from "./dialogs/repository/Repository";
//BACKUP - SOURCE
import FolderSource from "./dialogs/backup/FolderSource";
import NetworkSource from "./dialogs/backup/NetworkSource";
import DriveImageSource from './dialogs/backup/DriveImageSource';
import DatabaseSource from './dialogs/backup/DatabaseSource';
import HyperVSource from './dialogs/backup/HyperVSource';
import ExchangeSource from './dialogs/backup/ExchangeSource';
import ESXiSourceDestination from './dialogs/backup/ESXiSourceDestination';
import MicrosoftSource from './dialogs/backup/MicrosoftSource';
//BACKUP - DESTINATION
import Destination from "./dialogs/backup/Destination";
//RESTORE
import RestoreFile from "./dialogs/restore/RestoreFile";
import DatabaseDestinationRestore from "./dialogs/restore/DatabaseDestinationRestore";
import DatabaseManualRestore from "./dialogs/restore/DatabaseManualRestore";
import ESXiDestinationRestore from "./dialogs/restore/ESXiDestinationRestore";
import ESXiManualRestore from "./dialogs/restore/ESXiManualRestore";
import ExchangeDestinationRestore from "./dialogs/restore/ExchangeDestinationRestore";
import ExchangeManualRestore from "./dialogs/restore/ExchangeManualRestore";
import HyperVDestinationRestore from "./dialogs/restore/HyperVDestinationRestore";
import HyperVManualRestore from "./dialogs/restore/HyperVManualRestore";
//VISTE
import BackupView from './dialogs/view/BackupView';
import SARepoView from './dialogs/view/SARepoView';
//UTILS
import { DefaultObjectBuilder } from '../models/DefaulfObjectBuilder';
import { isValid, getMetroIcon } from '../../public/assets/js/utilitiesmodule';

import $ from 'jquery';

export default {
    name: "Dialogs",
    components: {
        //SERVICEACCOUNTS
        CloudServiceAccount,
        DatabaseServiceAccount,
        EmailServiceAccount,
        ESXIServiceAccount,
        MicrosoftServiceAccount,
        FTPServiceAccount,
        NetworkServiceAccount,
        //REPOSITORY
        Repository,
        //BACKUP -SOURCE
        FolderSource,
        NetworkSource,
        DriveImageSource,
        DatabaseSource,
        HyperVSource,
        ExchangeSource,
        ESXiSourceDestination,
        MicrosoftSource,
        //BACKUP - DESTINATION
        Destination,
        //RESTORE
        RestoreFile,
        DatabaseDestinationRestore,
        DatabaseManualRestore,
        ESXiDestinationRestore,
        ESXiManualRestore,
        ExchangeDestinationRestore,
        ExchangeManualRestore,
        HyperVDestinationRestore,
        HyperVManualRestore,
        //VISTE
        BackupView,
        SARepoView,
        GroupDialog,
        UserDialog,
    },
    created: function () {

        /*if(this.$route.hash != ""){
            this.$router.push(this.$route.path);
        }*/

        this.$root.$on("OPENSERVICEACCOUNTDIALOG", type_or_serviceAccount => this.openServiceAccountDialog(type_or_serviceAccount));
        this.$root.$on("CLOSESERVICEACCOUNTDIALOG", () => this.closeServiceAccountDialog());

        this.$root.$on("OPENREPOSITORYDIALOG", type_or_repository => this.openRepositoryDialog(type_or_repository));
        this.$root.$on("CLOSEREPOSITORYDIALOG", () => this.closeRepositoryDialog());

        this.$root.$on("OPENSOURCEDIALOG", type_or_source => this.openSourceDialog(type_or_source));
        this.$root.$on("CLOSESOURCEDIALOG", () => this.closeSourceDialog());

        this.$root.$on("OPENDESTINATIONDIALOG", type_or_destination => this.openDestinationDialog(type_or_destination));
        this.$root.$on("CLOSEDESTINATIONDIALOG", () => this.closeDestinationDialog());

        this.$root.$on("OPENRESTOREDIALOG", (type, job/*backupID, backupName*/) => this.openRestoreDialog(type, job /*backupID, backupName*/));
        this.$root.$on("CLOSERESTOREDIALOG", () => this.closeRestoreDialog());

        this.$root.$on("OPENBACKUPVIEW", idBackup => this.openBackupView(idBackup));
        this.$root.$on("CLOSEBACKUPVIEW", () => this.closeBackupView());

        this.$root.$on("OPENUSERVIEWDIALOG", (user, checkTFA) => this.openUserViewDialog(user, checkTFA));
        this.$root.$on("CLOSEUSERVIEWDIALOG", () => this.closeUserViewDialog());

        this.$root.$on("OPENGROUPVIEWDIALOG", (group, computers, users) => this.openGroupViewDialog(group, computers, users));
        this.$root.$on("CLOSEGROUPVIEWDIALOG", () => this.closeGroupViewDialog());

        this.$root.$on("OPENCOMPUTERVIEWDIALOG", () => this.openComputerViewDialog());//TODO
        this.$root.$on("CLOSECOMPUTERVIEWDIALOG", () => this.closeComputerViewDialog());//TODO

        this.$root.$on("OPENCREATEFOLDERDIALOG", (parentPath) => this.openCreateFolderDialog(parentPath));
        this.$root.$on("CLOSECREATEFOLDERDIALOG", () => this.closeCreateFolderDialog());

        this.$root.$on("OPENRENAMEITEMDIALOG", (resource, parentPath) => this.openRenameItemDialog(resource, parentPath));
        this.$root.$on("CLOSERENAMEITEMDIALOG", () => this.closeRenameItemDialog());

        this.$root.$on("OPENDELETEITEMDIALOG", (resource) => this.openDeleteItemDialog(resource));
        this.$root.$on("CLOSEDELETEITEMDIALOG", () => this.closeDeleteItemDialog());

        this.$root.$on("OPENRENAMEJOBDIALOG", (backupID, backupName) => this.openRenameJobDialog(backupID, backupName));
        this.$root.$on("CLOSERENAMEJOBDIALOG", () => this.closeRenameJobDialog());

        this.$root.$on("OPENSERVICEACCOUNTVIEW", serviceAccount => this.openSARepoView(serviceAccount, null));
        this.$root.$on("CLOSESERVICEACCOUNTVIEW", () => this.closeSARepoView());
        this.$root.$on("OPENREPOSITORYVIEW", repository => this.openSARepoView(null, repository));
        this.$root.$on("CLOSEREPOSITORYVIEW", () => this.closeSARepoView());
        this.$root.$on("OPENSAREPOVIEW", (serviceAccount, repository) => this.openSARepoView(serviceAccount, repository));
        this.$root.$on("CLOSESAREPOVIEW", () => this.closeSARepoView());
    },
    data() {
        return {
            users: [],
            computers: [],
            group: null,
            user: null,
            checkTFA: {
                step1: null,
                step2: null
            },

            serviceAccount: null,

            repository: null,

            source: null,
            destination: null,


            restore: null,

            idBackupForView: null,

            createFolderDialog: null,

            renameItemDialog: null,

            renameJobDialog: null,

            deleteItemDialog: null,

            sarepo: null,

            nOpenDialogs: 0,


        }
    },
    methods: {

        async openServiceAccountDialog(type_or_serviceAccount) {
            this.nOpenDialogs += 1;

            if (typeof type_or_serviceAccount != "number")
                this.serviceAccount = type_or_serviceAccount;
            else if (!this.$root.isEmail(type_or_serviceAccount))
                this.serviceAccount = new DefaultObjectBuilder().getServiceAccount(type_or_serviceAccount);
            else {
                //Se il service account è di tipo email, per costruire l'oggetto ho bisogno di alcuni dati relativi al provider selezionato
                var emailProviders = await this.$root.api("GETEMAILPROVIDERS");
                var selectedEmailProvider = emailProviders.find(ep => ep.Id.toString() == type_or_serviceAccount.toString());
                this.serviceAccount = new DefaultObjectBuilder().getServiceAccount(type_or_serviceAccount, selectedEmailProvider);
            }

            $(".sourceDialog").addClass("background");
            $(".destinationDialog").addClass("background");
            $(".repositoryDialog").addClass("background");
            $(".restoreDialog").addClass("background");
        },

        closeServiceAccountDialog() {
            this.nOpenDialogs -= 1; if (this.nOpenDialogs < 0) this.nOpenDialogs = 0;
            this.$root.$emit("closeFileSystemSidebar");

            this.serviceAccount = null;
            $(".sourceDialog").removeClass("background");
            $(".destinationDialog").removeClass("background");
            $(".repositoryDialog").removeClass("background");
            $(".restoreDialog").removeClass("background");
            /* this.$router.push(this.$route.path); */

        },

        openRepositoryDialog(type_or_repository) {
            this.nOpenDialogs += 1;

            if (typeof type_or_repository == "number") {
                this.repository = new DefaultObjectBuilder().getRepository(type_or_repository);
            } else {
                this.repository = type_or_repository;
            }

            $(".destinationDialog").addClass("background");
        },

        closeRepositoryDialog() {
            this.nOpenDialogs -= 1; if (this.nOpenDialogs < 0) this.nOpenDialogs = 0;
            this.$root.$emit("closeFileSystemSidebar");

            this.repository = null;
            $(".destinationDialog").removeClass("background");
            this.$root.$emit("closeFileSystemSidebar");
            /* this.$router.push(this.$route.path); */

        },

        openSourceDialog(type_or_source) {
            this.nOpenDialogs += 1;

            if (typeof type_or_source == "number") {
                this.source = new DefaultObjectBuilder().getSource(type_or_source);
            } else {
                this.source = type_or_source;
            }
        },
        closeSourceDialog() {
            this.nOpenDialogs -= 1; if (this.nOpenDialogs < 0) this.nOpenDialogs = 0;
            this.$root.$emit("closeFileSystemSidebar");
            this.source = null;

        },

        openDestinationDialog(type_or_destination) {
            this.nOpenDialogs += 1;
            if (typeof type_or_destination == "number") {
                this.destination = new DefaultObjectBuilder().getDestination(type_or_destination);
            } else {
                this.destination = type_or_destination;
            }
        },
        closeDestinationDialog() {
            this.nOpenDialogs -= 1; if (this.nOpenDialogs < 0) this.nOpenDialogs = 0;
            this.$root.$emit("closeFileSystemSidebar");

            this.destination = null;
        },

        openRestoreDialog(type, backup) {
            this.nOpenDialogs += 1;
            //Caso 1 : restore di file
            if (this.$root.isRestoreFile(type)) {
                this.closeBackupView();
                this.restore = new DefaultObjectBuilder().getRestoreFile(type, backup);
                return;
            }

            //Caso 2 : restore automatico no file
            if (isValid(backup)) {
                this.selectedJobId = backup.id;
                if (type == this.$root.PlatformsTypesEnum.Microsoft_Exchange365) {
                    this.restore = new DefaultObjectBuilder().getRestoreSource(type, backup);
                } else {
                    this.restore = {
                        type: type,
                        idBackup: backup.id,
                    }
                }

                this.closeBackupView();
                return;
            }

            //Caso 3 : restore manuale no file
            this.restore = new DefaultObjectBuilder().getRestoreSource(type);

        },

        closeRestoreDialog() {
            this.nOpenDialogs -= 1;
            if (this.nOpenDialogs < 0) 
            {
                this.nOpenDialogs = 0;
            }
            this.$root.$emit("closeFileSystemSidebar");

            this.restore = null;
        },

        openBackupView(idBackup) {
            this.nOpenDialogs += 1;
            this.idBackupForView = idBackup;
        },

        closeBackupView() {

            if (this.idBackupForView == null)
                return;

            this.nOpenDialogs -= 1; if (this.nOpenDialogs < 0) this.nOpenDialogs = 0;
            this.$root.$emit("closeFileSystemSidebar");

            this.idBackupForView = null;
        },

        openUserViewDialog(user, checkTFA) {
            this.nOpenDialogs += 1;

            
            this.computers = this.$session.get("COMPUTERS");
            this.groups = this.$session.get("GROUPS");
            if (isValid(user)) {
                this.user = user;
            } else {
                this.user = new DefaultObjectBuilder().getUser()
                this.user.id_master_account = this.$session.get('AUTH').id_master_account;
            }

            if (isValid(checkTFA))   this.checkTFA = checkTFA;
            
        },

        closeUserViewDialog() {
            this.nOpenDialogs -= 1; 
            if (this.nOpenDialogs < 0) this.nOpenDialogs = 0;
            this.$root.$emit("closeFileSystemSidebar");

            this.user = null;
            this.checkTFA =  {
                step1: null,
                step2: null
            };
            /* this.$router.push(this.$route.path); */
        },

        openGroupViewDialog(group, computers, users) {
            this.nOpenDialogs += 1;

            if (isValid(group)) {
                this.group = group;
            } else {
                this.group = new DefaultObjectBuilder().getGroup();
            }

            if (isValid(computers)) {
                this.computers = computers;

            } else {
                this.computers = this.$session.get("COMPUTERS");
            }

            if (isValid(users)) {
                this.users = users;
            } else {
                this.users = this.$session.get("USERS")
            }

        },
        closeGroupViewDialog() {
            this.nOpenDialogs -= 1; 
            if (this.nOpenDialogs < 0) this.nOpenDialogs = 0;
            this.$root.$emit("closeFileSystemSidebar");

            this.group = null;
            this.computers = null;
            this.users = null;

           /*  this.$router.push(this.$route.path); */
        },

        openComputerViewDialog() {
            this.nOpenDialogs += 1;
            alert("DA IMPLEMENTARE");
        },
        closeComputerViewDialog() {
            this.nOpenDialogs -= 1; 
            if (this.nOpenDialogs < 0) 
            {
                this.nOpenDialogs = 0;
            }
            this.$root.$emit("closeFileSystemSidebar");
            alert("DA IMPLEMENTARE");

            /* this.$router.push(this.$route.path); */
        },

        openCreateFolderDialog(parentPath) {
            this.nOpenDialogs += 1;
            this.createFolderDialog = {
                parentPath: parentPath,
                name: ""
            };
        },

        async createFolder() {
            var newPath = this.createFolderDialog.parentPath + "\\" + this.createFolderDialog.name;
            var created = await this.$root.socket("CreateFolder", newPath);

            this.$root.$emit("createitemfeedback", created);
            if (created) this.closeCreateFolderDialog();
        },
        closeCreateFolderDialog() {
            this.nOpenDialogs -= 1;
            if (this.nOpenDialogs < 0) {
                this.nOpenDialogs = 0;
            }
            this.createFolderDialog = null;

        },

        openRenameItemDialog(resource, parentPath) {
            this.nOpenDialogs += 1;
            this.renameItemDialog = {
                resource: resource,
                parentPath: parentPath,
                name: ""
            };
        },

        async renameItem() {
            var oldPath = this.renameItemDialog.resource.path;
            var newPath = this.renameItemDialog.parentPath + "\\" + this.renameItemDialog.name;
            var renamed = await this.$root.socket("RenameItem", oldPath, newPath);

            this.$root.$emit("renameitemfeedback", renamed);
            if (renamed)
                this.closeRenameItemDialog();
        },
        closeRenameItemDialog() {
            this.nOpenDialogs -= 1; 
            if (this.nOpenDialogs < 0) this.nOpenDialogs = 0;
            this.renameItemDialog = null;
        },

        openDeleteItemDialog(resource) {
            this.nOpenDialogs += 1;
            this.deleteItemDialog = {
                resource: resource
            };
        },
        async deleteItem() {
            var deleted = await this.$root.socket("DeleteItem", this.deleteItemDialog.resource.path);
            this.$root.$emit("deleteitemfeedback", deleted);
            if (deleted)
                this.closeDeleteItemDialog();
        },
        closeDeleteItemDialog() {
            this.nOpenDialogs -= 1;
            if (this.nOpenDialogs < 0) {
                this.nOpenDialogs = 0;
            }
            this.deleteItemDialog = null;
        },

        openRenameJobDialog(backupID, backupName) {
            this.nOpenDialogs += 1;
            this.renameJobDialog = {
                id: backupID,
                oldName: backupName,
                newName: ""
            };

        },

        async renameJob() {
            var renamed = await this.$root.api("RENAMEBACKUP", this.renameJobDialog);
            if (!renamed) return;

            renamed = await this.$root.socket("ChangeJobName", this.renameJobDialog.id, this.renameJobDialog.newName);

            if (renamed) this.closeRenameJobDialog();

        },
        closeRenameJobDialog() {
            this.nOpenDialogs -= 1;
            if (this.nOpenDialogs < 0) {
                this.nOpenDialogs = 0;
            }
            this.renameJobDialog = null;
        },

        openSARepoView(serviceAccount, repository) {
            this.nOpenDialogs += 1;
            this.sarepo = {
                repository: repository,
                serviceAccount: serviceAccount
            }
        },

        closeSARepoView() {
            this.nOpenDialogs -= 1;
            if (this.nOpenDialogs < 0) {

                this.nOpenDialogs = 0;
            }
            this.sarepo = null;
        },


        /**********************************UTILS**********************************************************/
        isValid(value) {
            return isValid(value);
        },

        getMetroIcon(node) {
            return getMetroIcon(node.type, node.path);
        }

    }

}
</script>