<template>
  <div class="bg-white charms fg-dark right-side w-100 h-100 m-0 no-overflow"
    data-role="charms" id="plugin" data-position="right" v-cloak>
    <a href="#"><span onclick="window.Metro.charms.toggle('#plugin');" class="mif-cross-light mif-2x"></span></a>
    <div class="m-7 card-content">
      <h3>
        <span class="badge inline mif-add bg-cobalt fg-white"></span> {{ $t('Add License') }}
      </h3>
      <form novalidate="" class="row">
        <div class="col-8 col-md-8 col-lg-8 p-8">
          <h3 class="mb-4">
            <span> {{ $t("Select the workloads you want to protect") }} </span>
          </h3>
          <div class="block-wrapper mb-4">
            <div class="row d-flex small-gutters mb-4">
              <div class="form-group col-12 col-md-4 col-lg-4 pr-lg-0 flex-self-end">
                <label class="text-bold">
                  <p class="mb-0">
                    VM, VMware, Hyper-V
                    <!---->
                  </p>
                </label><input type="number" data-role="spinner" data-min-value="0" data-max-value="300"
                  class="form-control" v-model.lazy.number="license.num_vms" id="vmware" />
              </div>


              <div class="form-group col-12 col-md-4 col-lg-4 pr-lg-0 flex-self-end">
                <label class="text-bold">
                  <p class="mb-0">
                    {{ $t("Server") }}
                    <span placement="top" class="cic-icon cic-icon-info"></span>
                  </p>
                </label><input type="number" data-role="spinner" data-min-value="0" data-max-value="200"
                  class="form-control" v-model.lazy.number="license.num_servers" id="physicalServer" />
              </div>

              <div class="form-group col-12 col-md-4 col-lg-4 pr-lg-0 flex-self-end">
                <label class="text-bold">
                  <p class="mb-0">
                    {{ $t("Workstation") }} <span class="mif-info" data-role="hint" data-hint-position="top"
                      data-hint-text="This is a hinted button"></span>
                    <span placement="top" class="cic-icon cic-icon-info"></span>
                  </p>
                </label>
                <input v-model.lazy.number="license.num_workstations" type="number" data-role="spinner"
                  data-min-value="0" data-max-value="200" class="form-control" id="workstation" />
              </div>

              <div class="form-group col-12 col-md-4 col-lg-4 pr-lg-0 flex-self-end">
                <label class="text-bold">
                  <p class="mb-0"> Storage (FTp/S3) (TB)
                    <span placement="top" class="cic-icon cic-icon-info"></span>
                  </p>
                </label>
                <input type="number" data-role="spinner" data-min-value="0" data-max-value="200" class="form-control"
                  id="nasPack" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <input type="checkbox" data-role="switch" true-value="1" false-value="0"
                v-model.lazy.number="license.plugin_database"
                :data-caption="$t('Backup Exchange On-Premises / Exchange 365')"><br>
              <input type="checkbox" data-role="switch" true-value="1" false-value="0"
                v-model.lazy.number="license.plugin_exchange" :data-caption="$t('Backup Database (SQL Server)')">
            </div>
          </div>
          <div class="block-wrapper mb-5 mt-5">
            <h3>{{ $t("Subscription period") }}</h3>
            <div class="row">
              <div class="col-12 col-lg-10 mb-1">
                <input data-role="slider" class="ultra-thin cycle-marker" data-hint-mask="$1 anni"
                  data-hint-always="true" v-model.lazy.number="license.subscription_duration" data-show-min-max="true"
                  data-min="1" data-max="5" data-hint-position="top" data-cls-complete="bg-cobalt"
                  data-cls-marker="bg-cobalt rounded" data-cls-hint="bg-cobalt fg-white enlarge" data-accuracy="1"
                  id="slider" />
              </div>
              <div class="col-12 text-muted">
                {{ $t("Multi-year contracts may include additional discounts.") }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 col-md-4 p-8 bg-cobalt fg-white border-radius-6 shadow-1 " v-if="pricing">
          <div>
            <h4 class="mb-3">{{ pricing.description }}</h4>

            <div class="mb-4">

              <p class="mb-0 text-muted">
                - <span class="fg-white text-bold">{{ pricing.subscription_duration }}
                </span><span>{{ pricing.subscription_duration == 1 ? $t('year') : $t('years') }} {{ $t("subscription")
                  }}</span>
              </p>
              <p v-show="pricing.num_vms != 0" class="mb-0 text-muted">- <span class="fg-white text-bold">{{
          pricing.num_vms }} </span><span>{{ $t("Iperius VM License") }} </span>
              </p>
              <p v-show="pricing.num_servers != 0" class="mb-0 text-muted">- <span class="fg-white text-bold">{{
          pricing.num_servers }} </span><span>{{ $t("Iperius Server License") }}
                </span>
              </p>
              <p v-show="pricing.num_workstations != 0" class="mb-0 text-muted">- <span class="fg-white text-bold">{{
          pricing.num_workstations }} </span><span>{{ $t("Iperius Workstation License")}} </span>
              </p>
              <p v-show="pricing.plugin_exchange != 0" class="mb-0 text-muted fadeIn">- <span
                  class="fg-white text-bold">{{ pricing.plugin_exchange }} </span><span>{{ $t("Iperius Backup for Exchange (On - Premises / 365)")}} </span>
              </p>
              <p v-show="pricing.plugin_database != 0" class="mb-0 text-muted">- <span class="fg-white text-bold">{{
          pricing.plugin_database }} </span><span>{{ $t("Iperius Backup for Database (Sql Server)")}} </span>
              </p>

              <div class="loader-wrapper mt-4 border-top pt-3">
                <div class="row">
                  <div class="col-12">
                    <div class="text-muted m-0">
                      {{ $t("Total price") }}
                    </div>
                    <h2 v-if="pricing" class="m-0 price mb-0">
                      {{ pricing.price.price.toFixed(1) }} {{ pricing.price.currency }}
                    </h2>
                  </div>
                  <!---->
                </div>
                <!---->
              </div>
              <!---->
            </div>
            <div class="inline-form d-flex flex-align-baseline">
              <input type="text" class="form-group input   mb-4 text-center w-100 text-upper"
                :placeholder="$t('Add discount code here')" v-model="license.discount_code">
              <button type="button" class="button primary  " @click="getPricing()">
                {{ $t("Apply") }}
              </button>

            </div>
            <div class="">
              <button type="button" class="button primary large mr-4 popover">
                {{ $t("Buy now") }}
              </button>
            </div>


          </div>
        </div>
      </form>
      <footer class="footer p-6">
        <div class="footer__row">
          <div class="">
            <div class="row mb-3">
              <div class="col-12">
                <h3 class="mb-4"><span> {{ $t("More information") }} </span></h3>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-5">
                    <p>
                      <a href="https://www.iperiusbackup.com/smb-backup-solutions.html?ad=in-text-link"><span
                          class="cic-icon cic-icon-link"></span>
                        {{ $t("Small business solutions") }}
                      </a>
                    </p>
                    <p>
                      <span class="cic-icon cic-icon-link"></span><a
                        href="https://www.iperiusbackup.com/backup-software-comparison-for-small-business.html?ad=in-text-link">
                        {{ $t("Comparison of competitive features") }}
                      </a>
                    </p>
                  </div>
                  <div class="col-12 col-sm-6 col-md-5">
                    <p>
                      <span class="cic-icon cic-icon-link"></span><a
                        href="https://www.iperiusbackup.com/smb-vmware-hyper-v-essentials.html?ad=in-text-link">
                        {{ $t("iperiusbackup Backup Essentials") }}
                      </a>
                    </p>
                    <p>
                      <span class="cic-icon cic-icon-link"></span><a
                        href="https://www.iperiusbackup.com/smb-vmware-hyper-v-essentials-download.html">
                        {{ $t("Download the trial") }}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer__row">
          <div class="">
            <div class="row mb-3">
              <div class="col-12">
                {{ $t("The price displayed is based on the current Manufacturer's Suggested Resale Price (MSRP).") }}
              </div>
              <div class="col-12">
                {{ $t("Price is not a quote and is provided for informational purposes only. Does not include any local taxes(VAT, GST or sales tax).Iperius Backup sells through channel partners, so contact your partner for detailed pricing and quotes.If you don't have a referral partner you can")}}
                <a href="https://www.iperiusbackup.com/it/buy-iperiusbackup-products-pricing.html" target="_blank">{{
            $t("find a reseller") }}</a>
                {{ $t("or contact")}}
                <a href="https://www.iperiusbackup.com/it/salesinc.html" target="_blank">{{$t("the sales department iperiusbackup")}}</a>
                {{$t("for assistance.")}}
              </div>
            </div>
          </div>
        </div>
        <div class="footer__row bg-light mt-5">
          <div class="">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 mb-3 mb-md-0 text-muted">
                <span>© 2021 iperiusbackup<sup>Ⓡ</sup> Software</span><span class="mx-2">|</span><a target="_blank"
                  class="text-muted" href="https://www.iperiusbackup.com/eula.html">EULA</a><span
                  class="mx-2">|</span><a target="_blank" class="text-muted"
                  href="https://www.iperiusbackup.com/privacy-policy.html">Privacy Policy &amp; Cookie</a>
              </div>

            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  name: "addLicenseDialog",
  props: {
    license: Object,
    pricing: Object
  },//['license','pricing'],
  data() {
    return {
    }
  },
  created() {

    let self = this;
    setTimeout(() => {
      //vmware
      var spinner = window.Metro.get$el("#vmware").data("spinner");
      if (spinner == undefined)
        return;
      spinner.val(self.license.num_vms);
      spinner = spinner.options;
      spinner.onButtonClick = (x, y, val) => {
        self.license.num_vms = y;
      };
      spinner.onArrowClick = (x, y, val) => {
        self.license.num_vms = y;
      };

      //physicalServer
      spinner = window.Metro.get$el("#physicalServer").data("spinner");
      spinner.val(self.license.num_servers);
      spinner = spinner.options;
      spinner.onButtonClick = (x, y, val) => {
        self.license.num_servers = y;
      };
      spinner.onArrowClick = (x, y, val) => {
        self.license.num_servers = y;
      };

      //workstation
      spinner = window.Metro.get$el("#workstation").data("spinner");
      spinner.val(self.license.num_workstations);
      spinner = spinner.options;
      spinner.onButtonClick = (x, y, val) => {
        self.license.num_workstations = y;
      };
      spinner.onArrowClick = (x, y, val) => {
        self.license.num_workstations = y;
      };
      //nasPack
      //10/07/2024 : TODO : manca il model per questo campo!!!
      spinner = window.Metro.get$el("#nasPack").data("spinner");
      //spinner.val(self.source.options.num_vms);
      spinner = spinner.options;
      spinner.onButtonClick = (x, y, val) => {
        //self.license.num_vms = y;
      };
      spinner.onArrowClick = (x, y, val) => {
        //self.license.num_vms = y;
      };
    }, 500);
  }
}
</script>