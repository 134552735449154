const axios = require('axios').default;
import https from 'https';
import getBrowserLocale from "../utils/getBrowserLocale";
//import router from './'

//axios.defaults.withCredentials = true;
//axios.defaults.httpsAgent = new https.Agent({ keepAlive: true, rejectUnauthorized: false });

const api = axios.create({
    withCredentials: true,
    credentials: 'include',
    /*jar: new tough.CookieJar(),  */
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 6000,
    httpsAgent: new https.Agent({ keepAlive: true, rejectUnauthorized: false }),
    /* onUploadProgress: function(progressEvent) {
        //console.log(progressEvent)
    },
    onDownloadProgress: function(progressEvent) {
        /*   var total = progressEvent.srcElement.getResponseHeader('content-length');
          let percentCompleted = Math.round((progressEvent.loaded * 100) / total);
          console.log(percentCompleted); 
    }, */
    headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Language': localStorage.getItem('lang') ?? getBrowserLocale({ countryCodeOnly: true }),
        'Content-Type': "application/json",
        'Cache-Control': "no-cache",
        //"Access-Control-Allow-Origin": window.location.origin,
       /*  "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true */
    },

});

/* api.interceptors.request.use(config => {
    // perform a task before the request is sent
    console.log('Request was sent');

    return config;
    }, error => {
        // handle the error
        return Promise.reject(error);
    }); */



api.interceptors.response.use(


    response => {
        return response;
    },
    error => {

        if (error.response)
            return Promise.reject(error.response); // Va nel catch

        if (error.toJSON)
            return Promise.reject(error.toJSON()); // Va nel catch
        return Promise.reject(error); // Va nel catch
    });




export default api;