<template>
    <div>

       <div class="dropdown-button place-right">
            <a :title="$t('Open a Remote connection')"  class="button dropdown-toggle" :class="$session.get('connectionsStatesRemote')[pc.id_remote]==true ? 'secondary' : 'bg-gray fg-black disabled'" >
                <div class="text-left">
                {{ pc.id_remote.replace(/(\d{3})(?=\d)/g, '$1 ') }} 
                </div>
                
            </a>
            <ul class="d-menu border place-right text-left" data-role="dropdown">
                <li  class="text-bold">
                    <a @click="$root.getOTP(pc.id)" :class="$session.get('connectionsStatesRemote')[pc.id_remote]==true ? 'secondary' : 'fg-gray disabled'" >
                        {{ $t("Open connection to") }}  {{ pc.id_remote.replace(/(\d{3})(?=\d)/g, '$1 ') }} 
                      
                    </a>
                </li>
                <li class="divider"></li>
                <li>
                    <a  :class="$session.get('connectionsStatesRemote')[pc.id_remote]==true ? 'fg-dark' : 'fg-gray disabled'" >
                        <div class="text-left">
                        {{ $t("Download and connect") }}
                        </div> 
                    </a>
                </li>
                <li>
                    <a   :class="$session.get('connectionsStatesRemote')[pc.id_remote]==true ? 'fg-dark' : 'fg-gray disabled'" >
                        <div class="text-left">
                        {{ $t("Restart Application") }}
                        </div> 
                    </a>
                </li>
                <li>
                    <router-link to="#" >
                        {{$t("Update")}}
                    </router-link>
                </li>
            </ul> 
        </div>


    </div>
</template>
<script>
export default {
    name: "PCRemoteLink",
    props: {
        pc: {
            type: Object,
            required: true
        }
    }/*,
    data: function () {
        return {
            connected: false
        }
    },
    mounted() {
        this.connected = this.$session.get('PCSCONNECTIONS')[this.pc.id];
    },
    watch : {
        connected : function(val){
            this.connected = val;
        }
    } */
}
</script>