import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/components/Dashboard'
import Login from '@/components/Login'
import Login2FA from '@/components/Login2FA'
import Computers from '@/components/Computers'
import Computer from '@/components/Computer'
import Accounts from '@/components/Accounts'
import Schedulation from '@/components/Schedulation'
import Repositories from '@/components/Repositories'
import Backup from '../components/Backup'
import PageNotFound from '../components/PageNotFound'
import Settings from '../components/Settings'
import Groups from '../components/Groups'
import Sessions from '../components/Sessions'
import ActiveSessions from '../components/ActiveSessions'
import Group from '../components/Group'
import Users from '../components/Users'
import Storage from '../components/Storage'
import StatsRemote from '../components/stats/StatsRemote.vue'
import StatsBackup from '../components/stats/StatsBackup.vue'

Vue.use(VueRouter)

const router = new VueRouter({
    //history: createWebHistory(),

    linkExactActiveClass: "",
    routes: [{
            path: '/',
            name: 'login',
            component:() => import('../components/Login.vue'),
            meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/login2FA',
            name: 'login2FA',
            component: Login2FA,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/dashboard',
            name: 'cashboard',
            component: Dashboard,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/storage',
            name: 'Storage Accounts',
            component: Storage,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/computers',
            name: 'computers',
            component: Computers,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/computer/:idPC',
            name: 'computer',
            component: Computer,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
            /*beforeEnter(to, from, next) {
                if (this.$store.state.accessToken) {
                    this.$store.dispatch('setInternalComponents', true);
                    next()
                } else
                    next('/')
            }*/
        },
        {
            path: '/computer/:idPC/backup',
            name: 'backupView',
            component: Backup,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
            /*beforeEnter(to, from, next) {
                if (this.$store.state.accessToken) {
                    this.$store.dispatch('setInternalComponents', true);
                    next()
                } else
                    next('/')
            }*/
        },
        {
            path: '/backup',
            name: 'backup',
            component: Backup,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/backup/:idBackup',
            name: 'backupEdit',
            component: Backup,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/accounts',
            name: 'accounts',
            component: Accounts,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/repo',
            name: 'repositories',
            component: Repositories,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/groups',
            name: 'Groups',
            component: Groups,
            props: false,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },

        {
            path: '/group/:idGroup',
            name: 'group',
            component: Group,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/scheduling',
            name: 'scheduling',
            component: Schedulation,
            props: false,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }

        },
        {
            path: '/scheduling/:idbackup',
            name: 'schedulingDetail',
            component: Schedulation,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/users',
            name: 'users',
            component: Users,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/sessions',
            name: 'sessions',
            component: Sessions,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/activesessions',
            name: 'activesessions',
            component: ActiveSessions,
            props: true,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/remotestats',
            name: 'remotestats',
            component: StatsRemote,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/backupstats',
            name: 'backupstats',
            component: StatsBackup,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: '/404',
            name: 'PageNotExist',
            component: PageNotFound,
              meta: {
                title: 'Login Page',
                keepAlive: true
            }
        },
        {
            path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
            redirect: '/404',
        },

    ],
    mode: "history",
    base: process.env.BASE_URL,
    //mode:'history' //https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})


router.beforeEach((to, from, next) => {
    const IsItABackButton = window.popStateDetected
    window.popStateDetected = false
    if (IsItABackButton) {
        console.debug("back!!!");
        
    }
    next()
})

/* router.beforeResolve(to => {
    console.log(to)
    return to.fullPath;
}) */

export default router;