import NavLayout from '../layouts/navLayout'
import SimpleLayout from '../layouts/simpleLayout'
import { isValid, cookieManager } from "./../../public/assets/js/utilitiesmodule";



/* EventBus.$on('connectionOnRoot', connection => {
    console.log(connection);
    //self.data.rootConnection = connection;
}); */

export default {
    name: 'App',
    metaInfo: {
        titleTemplate: '%s | Iperius ONE',
        htmlAttrs: {
            //lang: 'en-US'
        },
        meta: [
            { charset: 'utf-8' },
            { name: 'description', content: 'Iperius ONE - the complete RMM tool for Iperius Suite.' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' }
        ]
    },
    computed: {
        layout() {
            return this.$store.getters.layout
        },
        userLogged() {
            var auth = this.$root.checkAuth();
            return auth != null ? (auth.firstname + " " + auth.lastname + " (" + auth.username) + ")" : null;
        },
        roles() {
            return this.$store.getters.roles
        },
        ViewComponent() {
            return this.$route.name
        }

    },
    components: {
        'nav-layout': NavLayout,
        'simple-layout': SimpleLayout,
    },
    data() {
        return {
            lang: null,
            theme: null,
            rootConnection: null,
        };
    },
    filters: {
        prettyBytes: function(bytes, precision) {
            if (bytes == "0" || isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '0 bytes';
            if (typeof precision === 'undefined') precision = 1;
            var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
                number = Math.floor(Math.log(bytes) / Math.log(1024));
            return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + units[number];
        }
    },
    created: function() {
        var locale = localStorage.getItem('lang') ? this.$i18n.locale : "gb";
        document.documentElement.lang = locale;
        window.METRO_LOCALE = locale;
        this.$i18n.locale = locale;
        this.$moment.locale(locale);
        this.$store.commit('SET_LAYOUT', 'nav-layout')
        console.log("CREATED APP")
    },

    /*  beforeMount: function() {
         if (this.$route.name != null && this.$route.name !== 'login' && this.$route.name !== 'login2FA' && !this.$root.checkAuth()) {
             this.$router.replace("/");
         }
     }, */

    watch: {
        $route(to, from, next) {

            if (Metro.getPlugin('#userdropdown', 'dropdown')) Metro.getPlugin('#userdropdown', 'dropdown').close();
            if (Metro.getPlugin('#langdropdown', 'dropdown')) Metro.getPlugin('#langdropdown', 'dropdown').close();

            this.checkIfIsRedirectUri();

            if (to.name !== 'login' && to.name !== 'login2FA' && this.$root.checkAuth() == null) {
                this.$store.commit('SET_LAYOUT', 'simple-layout')
                this.$router.replace("/");
            }
            if ((to.name == 'login' || to.name == 'login2FA') && this.$root.checkAuth() != null) {
                this.$store.commit('SET_LAYOUT', 'nav-layout')
                this.$router.replace("/dashboard");
            }

        }
    },
    mounted: function() {

        if (this.$route.path !== '/login' && this.$route.path !== '/login2FA' && this.$root.checkAuth() == null) {
            this.$store.commit('SET_LAYOUT', 'simple-layout')
            this.$router.replace("/");
        }
        if ((this.$route.path == '/login' || this.$route.path == '/login2FA') && this.$root.checkAuth() != null) {
            this.$store.commit('SET_LAYOUT', 'nav-layout')
            this.$router.replace("/dashboard");
        }
        this.$store.commit('SET_CLOUDS', this.cloud_types)
    },
    methods: {
        toggleTheme() {
            this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
            document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
            localStorage.setItem('theme', this.theme); // stores theme value on local storage
        },

        async logout() {
            //await this.$root.closeSocket();
            this.$root.closeConnectionTimerLight();
            this.$session.remove("AUTH");
            this.$session.destroy();
            localStorage.removeItem("AUTH")
            sessionStorage.clear();
            this.$router.replace("/");

        },

        setLayout(layout) {
            this.$store.commit('SET_LAYOUT', layout)
        },

        checkIfIsRedirectUri() {

            if (isValid(this.$route.query.code)) {
                cookieManager.setCookie("authCode", this.$route.query.code);
                close();
            }

            //Caso 2 : Durante la procedura di creazione di un service account di tipo 13 (Onedrive),
            //è stata effettuata la richiesta per convalidare i permessi dell'applicazione appena registrata sulla piattaforma di Azure
            if (isValid(this.$route.query.admin_consent) && this.$route.query.admin_consent == "True") {
                cookieManager.setCookie("admin_consent", this.$route.query.admin_consent == "True");
                close();
            }

            //this.$root.newPage("login", "mounted");

            //Se nella richiesta dell'auth code:
            //response_mode == fragment -> this.$route.hash è una stringa che contiene 'code='
            //response_mode == query -> this.$route.query.code è l'authCode


            if (!isValid(this.$route.query)) return;

            //Caso 1 : Durante la procedura di creazione di un service account di tipo 10 (Google Drive) o di tipo 13 (Onedrive),
            //è stata effettuata la richiesta per l'authorization code.
            if (isValid(this.$route.query.code)) {
                cookieManager.setCookie("authCode", this.$route.query.code);
                close();
            }

            //Caso 2 : Durante la procedura di creazione di un service account di tipo 13 (Onedrive),
            //è stata effettuata la richiesta per convalidare i permessi dell'applicazione appena registrata sulla piattaforma di Azure
            if (isValid(this.$route.query.admin_consent) && this.$route.query.admin_consent == "True") {
                cookieManager.setCookie("admin_consent", this.$route.query.admin_consent == "True");
                close();
            }
        }


        /*  selectEventOffsite(event) {
             console.log(event.target.value);
             if (event.target.value == 0) {
                 window.Metro.get$el("#backup_master").data("master").toPage(6);
             }
         } */
    }



}