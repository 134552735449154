<template>
  <div class="locale-changer place-right">
    <a href="#" class="dropdown-toggle app-bar-item"  :title="$t('Lang')">
        <span class="icon">
            <country-flag :country="locale=='en'?'gb':locale" size="small"/> 
        </span>   
    </a>
    <ul class="d-menu border  place-right bg-white" id="langdropdown"  data-role="dropdown">
        <li class="text-small" @click="$i18n.locale=lang.language;"  v-for="(lang, i) in languages" :key="`Lang${i}`" :class="{'active':lang.language==$i18n.locale}" :value="lang.language"><a href="#">  <country-flag :country=lang.flag size="small"/>  {{lang.title}}</a></li>  
    </ul>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  watch: {
      '$i18n.locale': {
          handler: function (locale) {
            this.locale = locale;
            document.documentElement.lang = this.locale;
            this.$moment.locale(this.locale);
            window.METRO_LOCALE = this.locale;
            $.meta('metro4:locale').attr("content",this.locale);
            localStorage.setItem('lang', this.locale);
          },
          deep: true,
          immediate: true
      }
  },
  created: function() {
      this.locale = localStorage.getItem('lang') ?? $i18n.locale ?? "en";
      document.documentElement.lang =this.locale;
      this.$moment.locale(this.locale);
      window.METRO_LOCALE = this.locale;
      $.meta('metro4:locale').attr("content",this.locale);
      localStorage.setItem('lang', this.locale);
  },
  data () {
    return { 
      locale:'en',
      languages: [
            { flag: 'gb', language: 'en', title: 'English' },
            { flag: 'it', language: 'it', title: 'Italiano' },
            { flag: 'fr', language: 'fr', title: 'Français' },
            { flag: 'pt', language: 'pt', title: 'Português'},
            { flag: 'es', language: 'es', title: 'Español'  },
            { flag: 'de', language: 'de', title: 'Deutsch'  }, 
        ], }
  }
}
</script>