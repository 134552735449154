var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog-title" }, [
    _c("span", { staticClass: "caption" }, [
      _c("label", { staticClass: "badge inline bg-primary fg-white" }, [
        _vm._v(
          _vm._s(
            _vm.title == undefined || _vm.title == null || _vm.title == ""
              ? _vm.defaultTitle
              : _vm.title
          ) + " "
        ),
      ]),
      _c("br"),
      _vm.subTitle != ""
        ? _c("label", { staticClass: "p-2" }, [_vm._v(_vm._s(_vm.subTitle))])
        : _vm._e(),
    ]),
    _c("span", {
      staticClass: "button square closer",
      on: { click: _vm.closeDialog },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }