var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "split-button m-1" }, [
      _c(
        "button",
        { staticClass: "button m-0 p-0 bg-transparent" },
        [
          _c(
            "router-link",
            {
              staticClass: "flat-button p-0 m-0",
              class: !_vm.$root.connectionsStates[_vm.pc.id] ? "disabled" : "",
              attrs: {
                to: !_vm.$root.connectionsStates[_vm.pc.id]
                  ? "#"
                  : "/computer/" + _vm.pc.id,
                disabled: !_vm.$root.connectionsStates[_vm.pc.id],
              },
            },
            [
              _vm.pc.description == ""
                ? _c("div", [
                    _c(
                      "span",
                      {
                        class: {
                          "fg-green":
                            _vm.pc.av_alert +
                              _vm.pc.ram_alert +
                              _vm.pc.disk_alert ==
                            0,
                          "fg-orange":
                            _vm.pc.av_alert +
                              _vm.pc.ram_alert +
                              _vm.pc.disk_alert ==
                            1,
                          "fg-red":
                            _vm.pc.av_alert +
                              _vm.pc.ram_alert +
                              _vm.pc.disk_alert >
                            1,
                        },
                        attrs: {
                          title:
                            _vm.$t("Device Health") +
                            " : " +
                            _vm.$t(
                              _vm.pc.av_alert +
                                _vm.pc.ram_alert +
                                _vm.pc.disk_alert ==
                                0
                                ? "Good"
                                : _vm.pc.av_alert +
                                    _vm.pc.ram_alert +
                                    _vm.pc.disk_alert ==
                                  1
                                ? "Some Warning"
                                : "Errors"
                            ),
                        },
                      },
                      [_vm._v("●")]
                    ),
                    _vm._v(" " + _vm._s(_vm.pc.name) + " "),
                  ])
                : _c("div", { staticClass: "text-left" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row m-0 d-flex flex-nowrap m-0 mt-2 row",
                      },
                      [
                        _c("div", { staticClass: "col m-0" }, [
                          _c(
                            "span",
                            {
                              class: {
                                "fg-green":
                                  _vm.pc.av_alert +
                                    _vm.pc.ram_alert +
                                    _vm.pc.disk_alert ==
                                  0,
                                "fg-orange":
                                  _vm.pc.av_alert +
                                    _vm.pc.ram_alert +
                                    _vm.pc.disk_alert ==
                                  1,
                                "fg-red":
                                  _vm.pc.av_alert +
                                    _vm.pc.ram_alert +
                                    _vm.pc.disk_alert >
                                  1,
                              },
                              attrs: {
                                title:
                                  _vm.$t("Device Health") +
                                  " : " +
                                  _vm.$t(
                                    _vm.pc.av_alert +
                                      _vm.pc.ram_alert +
                                      _vm.pc.disk_alert ==
                                      0
                                      ? "Good"
                                      : _vm.pc.av_alert +
                                          _vm.pc.ram_alert +
                                          _vm.pc.disk_alert >
                                        2
                                      ? "Some Warning"
                                      : "Errors"
                                  ),
                              },
                            },
                            [_vm._v("●")]
                          ),
                        ]),
                        _c("div", { staticClass: "col m-0" }, [
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v(_vm._s(_vm.pc.name)),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "badge inline bg-transparent ml-0 text-bold",
                              staticStyle: { display: "block" },
                            },
                            [_vm._v(_vm._s(_vm.pc.description))]
                          ),
                        ]),
                      ]
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }