<template>

    <div id="destinationBackup" v-on:keyup.esc="close()"
        class="dialog w-100-sm w-50 w-50-lg w-50-xl pos-center overflow">

        <DialogTitle :title="destination.name ?? 'Create Backup Destination'"
            :defaultTitle="$t('Add or Create Backup Destination')" :subTitle="destination.type_name"
            @closeDialog="close()" />

        <div class="dialog-content p-4">
            <div class="form-group">
                <label class="text-bold">{{ $t("Backup Destination name") }}</label>
                <input class="input" data-role="input" v-model="destination.name" />
            </div>

            <!--SCELTA DELLE CREDENZIALI-->
            <div v-if="destination.type != $root.PlatformsTypesEnum.FileFolder" class="form-group d-flex flex-column">
                <label class="text-bold">{{ $t("Choose credentials") }}</label>
                <input type="radio" :data-caption="$t('Create or select a repository')" @click="credentialMode = 'repo'"
                    data-role="radio" name="r1">
                <input type="radio" :data-caption="$t('Create or select a service account')"
                    @click="credentialMode = 'sa'" data-role="radio" name="r1">
            </div>

            <!------------------------------SCELTA DEL REPOSITORY--------------------------------------------------------------->
            <!--Questa pagina deve implementare this.$root.$on("setRepository")-->
            <SARepoSelect v-if="destination.type != $root.PlatformsTypesEnum.FileFolder && credentialMode == 'repo'"
                :type=destination.type :idSA="null" :idRepo=destination.id_repository @setRepository="setRepository" />

            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->
            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <SARepoSelect v-if="destination.type != $root.PlatformsTypesEnum.FileFolder && credentialMode == 'sa'"
                :type=destination.type :idSA=destination.id_service_account :idRepo="null"
                @sendSATestResult="sendSATestResult" />

            <!--SCELTA DEL PERCORSO-->
            <SelectedResourcesLayout :buttonText="$t('Choose folder destination')" :singleItem="true"
                :isDisabled="destination.type != $root.PlatformsTypesEnum.FileFolder && !testResult.ok"
                :list="selectedNodes" :heightPercentage="0" :canCancel="true"
                @clickFunction="toggleFileSystemSidebar" />


        </div>
        <!--BOTTONI-->
        <div class="dialog-actions">
            <div class="ml-auto">
                <button class="button ml-2" @click="close()"><span class="mif-close"></span> {{ $t("Cancel") }}</button>
                <button class="button primary ml-2" :disabled="!enableSave" @click="save"><span class="mif-checkmark mr-2" /> {{ $t("Save") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import DialogTitle from "../../utils/DialogTitle";
import SelectedResourcesLayout from "../../utils/SelectedResourcesLayout";
import SARepoSelect from "../../utils/SARepoSelect.vue";
import { getNodeType, isValid } from "../../../../public/assets/js/utilitiesmodule";
import {FileSystemSidebarConfig} from "../../../models/TreeNode";

export default {
    name: "Destination",
    props: {
        destination: Object
    },
    components: {
        "DialogTitle": DialogTitle,
        SARepoSelect,
        SelectedResourcesLayout
    },

    data() {
        return {
            paths: [],
            credentialMode: '',
            selectedNodes: [],
            testResult: {
                ok: false,
                okNumber: 0,
                serviceAccount: { id: -2, type: this.destination.type },
                client: null,// il client della piattaforma che effettuerà le richieste dei list
                list: null,
            }
        }
    },
    computed: {
        enableSave() {
            var partialValue = (this.destination.type == this.$root.PlatformsTypesEnum.FileFolder && this.selectedNodes.length == 1) ||
                (this.destination.type != this.$root.PlatformsTypesEnum.FileFolder && this.credentialMode=="repo" && this.destination.path != "") ||
                (this.destination.type != this.$root.PlatformsTypesEnum.FileFolder && this.credentialMode=="sa" && this.selectedNodes.length == 1)
            return this.destination.name != "" && partialValue;
        }
    },

    created() {
        let self = this;

        //Se la destinazione è stata aperta in modifica ed è impostato un service account,
        // popolo il service account dando la possibilità all'utente di testarlo
        if (this.destination.id != "" && this.destination.id_service_account != -2) {
            self.destination.id_repository = -2;
            this.credentialMode = 'sa';
        }

        //Se la destinazione è stata aperta in modifica ed è impostato un repository,
        // popolo selectedNodes
        if (this.destination.id != "" && this.destination.id_repository != -2) {
            this.credentialMode = 'repo';
            this.selectedNodes.push({
                path: this.$session.get('REPOSITORIES').find(r => r.id == this.destination.id_repository).path,
                type: getNodeType(this.destination.type, false)
            });
        }

        if (this.destination.path != null || this.destination.path != "") {
            this.selectedNodes.push({
                path: this.destination.path,
                type: getNodeType(this.destination.type, false)
            });
        }

    },

    async beforeDestroy() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        //In caso di ftp o network, eseguo la disconnessione
        if (this.testResult.client != null && [this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.destination.type)) {
            var method = this.destination.type == 2 ? "disconnectftp" : "disconnectnetwork";
            this.$root.socket(method, this.testResult.client.serviceAccount.options.accountSessionId);
        }
    },
    methods: {
        async sendSATestResult(testResult) {
            this.testResult = testResult;
            this.destination.id_service_account = testResult.serviceAccount.id;
        },

        async setRepository(repository) {
            this.credentialMode = 'repo';
            this.destination.id_repository = repository.id;
            this.destination.path = repository.path;
            this.destination.id_service_account = repository.id_service_account;
            this.testResult.serviceAccount = this.$session.get("SERVICEACCOUNTS").find(sa => sa.id == this.destination.id_service_account);
            this.testResult.ok = isValid(this.serviceAccount);
        },
        async toggleFileSystemSidebar() {

            var config = new FileSystemSidebarConfig(
                this.destination.type,
                false, //si vuole non includere file
                false, // si vuole la scelta singola
                this.testResult.client,
                [this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.destination.type) ? this.testResult.serviceAccount.options.path : undefined,
                false, //un solo livello
                null//tree
            );

            this.$root.$emit("toggleFileSystemSidebar", this.selectedNodes, config);
        },

        async save() {
            this.$root.$emit("closeFileSystemSidebar");
            if (this.selectedNodes.length == 1) {
                this.destination.path = this.selectedNodes[0].path;
            }
            //salva la destinazione nel job e chiude la dialog

            this.$root.$emit("saveBackupDestination", this.destination);
        },

        cancel() {
            this.$root.$emit("uncheckNode", this.selectedNodes.pop());
        },

        async close() {
            //In caso di ftp o network, eseguo la disconnessione
            if (this.testResult.client != null && [this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.destination.type)) {
                var method = this.destination.type == this.$root.PlatformsTypesEnum.FTP ? "disconnectftp" : "disconnectnetwork";
                this.$root.socket(method, this.testResult.client.serviceAccount.options.accountSessionId);
            }
            this.$root.$emit('CLOSEDESTINATIONDIALOG');
            this.$root.$emit("closeFileSystemSidebar");
        },
    }
}
</script>