import { FileFolderEntityType } from "../../public/assets/js/utilitiesmodule";
import { ClientTreeNode } from "../models/TreeNode";
import api from "./api";

export class DropboxAuthentication {

    constructor(appKey, appSecret) {
        this.appKey = appKey;
        this.appSecret = appSecret;
    }

    getAuthenticationCode() {
        var url = "https://www.dropbox.com/oauth2/authorize?client_id=" + this.appKey + "&client_secret=" + this.appSecret + "&response_type=code&token_access_type=offline&grant_type=authorization_code";
        window.open(url, "_blank");
    }


    async getTokensWithAuthCode(authCode) {

        var url = "https://api.dropbox.com/oauth2/token?code=" + authCode + "&grant_type=authorization_code&client_id=" + this.appKey + "&client_secret=" + this.appSecret;

        var response = await api.post(
            url,
            null, {
                headers: {
                    /* "Access-Control-Allow-Origin": window.location.origin, */
                    "Access-Control-Allow-Credentials": true,
                    //'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).catch(e => {
            console.log(e);
            return e;
        });
        console.log(response);

        if (response.status >= 400) return null;

        setTimeout(async() => {

            var newTokens = await this.getTokensWithRefreshToken(response.data.refresh_token);
            console.log(newTokens);
        }, 20000);


        return {
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token
        };
    }

    async getAccessTokenWithRefreshToken(refreshToken) {
        var url = "https://api.dropbox.com/oauth2/token?refresh_token=" + refreshToken + "&grant_type=refresh_token&client_id=" + this.appKey + "&client_secret=" + this.appSecret;

        var response = await api.post(
            url,
            null, {
                headers: {
                   /*  "Access-Control-Allow-Origin": window.location.origin, */
                    "Access-Control-Allow-Credentials": true,
                    //'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).catch(e => {
            console.log(e);
            return e;
        });
        console.log(response);

        if (response.status >= 400) return null;

        return response.data.access_token;
    }
}

export class DropboxClient {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    async getRootNodes() {
        return await this.getNodesFrom(new ClientTreeNode(null, null, "", FileFolderEntityType.Cloud_Root));
    }

    async getNodesFrom(resource) {
        var path = resource.path;

        if (path != "") path = "/" + path;

        var url = "https://api.dropboxapi.com/2/files/list_folder";
        var body = { "path": path, "recursive": false, "include_deleted": false, "include_mounted_folders": true };
        var config = {
            headers: {
               /*  "Access-Control-Allow-Origin": window.location.origin, */
                "Access-Control-Allow-Credentials": true,
                "Authorization": "Bearer " + this.accessToken
            }
        };

        var nodes = await api.post(url, body, config)
            .then(res => {
                var resourcesList = res.data.entries.map(r => {

                    var newPath;
                    if (path == "") newPath = r.name;
                    else newPath = resource.path + "/" + r.name;

                    /**
                     * Array di oggetti di tipo
                     * .tag : "folder"
                     * id : id:tPcDEmrP3EAAAAAAAADkmg"
                     * name : "Full28"
                     *  path_display : "/Full28"
                     *   path_lower : "/full28"
                     */
                    return new ClientTreeNode(
                        null,
                        r.id,
                        newPath,
                        r[".tag"] != "folder" ?
                        FileFolderEntityType.Cloud_File :
                        FileFolderEntityType.Cloud_Folder
                    );
                });

                return resourcesList;

            }).catch(e => {
                console.log(e);
                return null;
            });

        console.log(nodes);

        return nodes;

    }
}