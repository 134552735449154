import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
    state: {
        layout: 'simple-layout',
        auth: {},
        license: "",
        computer: "",
        clouds: [],
        roles: [],
        types: [],
        realtime: {
            backups: [],
            messages: "",
            events: "",
            pcInfo: null
        },

        websocket: null
    },
    mutations: {
        SET_LAYOUT(state, layout) {
            state.layout = layout
        },
        SET_AUTH(state, auth) {
            state.auth = auth
        },
        SET_LICENSE(state, license) {
            state.license = license
        },
        SET_COMPUTER(state, computer) {
            state.computer = computer
        },
        SET_CLOUDS(state, clouds) {
            state.clouds = clouds
        },
        SET_ROLES(state, roles) {
            state.roles = roles
        },
        SET_TYPES(state, types) {
            state.types = types
        },
        SET_REALTIME_BACKUPS(state, backups) {
            state.realtime.backups = backups
        },
        SET_REALTIME_MESSAGES(state, messages) {
            state.realtime.messages = messages
        },
        SET_REALTIME_EVENTS(state, events) {
            state.realtime.events = events
        },
        SET_REALTIME_PCINFO(state, pcinfo) {
            state.realtime.pcInfo = pcinfo
        },

        SET_WEBSOCKET(state, websocket) {
            state.websocket = websocket;
        }

    },
    getters: {
        layout(state) {
            return state.layout
        },
        auth(state) {
            return state.auth
        },
        license(state) {
            return state.license
        },
        computer(state) {
            return state.computer
        },
        clouds(state) {
            return state.clouds
        },
        roles(state) {
            return state.roles
        },
        types(state) {
            return state.types
        },
        backups(state) {
            return state.realtime.backups
        },
        messages(state) {
            return state.realtime.messages
        },
        events(state) {
            return state.realtime.events
        },
        pcInfo(state) {
            return state.realtime.pcInfo
        },
        websocket(state) {
            return state.websocket
        }
    },
    setters: {

    }
})
