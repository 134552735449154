var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "dropdown-button place-right" }, [
      _c(
        "a",
        { staticClass: "button dropdown-toggle bg-backup fg-black pl-5" },
        [
          _c("div", { staticClass: "text-left" }, [
            _vm._v(" " + _vm._s(_vm.$t("Backup")) + " "),
          ]),
        ]
      ),
      _c(
        "ul",
        {
          staticClass: "d-menu border place-right",
          attrs: { "data-role": "dropdown" },
        },
        [
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "text-bold",
                  attrs: {
                    to: "/backup",
                    disabled: !_vm.$session.get("PCSCONNECTIONS")[_vm.pc.id],
                  },
                },
                [
                  _c("span", { staticClass: "mif-add icon text-bold" }),
                  _vm._v(_vm._s(_vm.$t("New Backup").toUpperCase()) + " "),
                ]
              ),
            ],
            1
          ),
          _vm._l(_vm.restoreTypes, function (t) {
            return _vm.platformsTypes != null
              ? _vm._l(_vm.$root.getMicrosoftTypes(), function (microsoft_t) {
                  return t == _vm.$root.PlatformsTypesEnum.Microsoft
                    ? _c("li", { key: microsoft_t }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$root.$emit(
                                  "OPENRESTOREDIALOG",
                                  microsoft_t,
                                  null
                                )
                              },
                            },
                          },
                          [
                            _vm._v("RESTORE "),
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.platformsTypes[microsoft_t].name)
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _c("li", [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$root.$emit(
                                  "OPENRESTOREDIALOG",
                                  t,
                                  null
                                )
                              },
                            },
                          },
                          [
                            _vm._v("RESTORE "),
                            _c("b", [
                              _vm._v(_vm._s(_vm.platformsTypes[t].name)),
                            ]),
                          ]
                        ),
                      ])
                })
              : _vm._e()
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }