import { isValid, platformsTypes, PlatformsTypesEnum } from "../../public/assets/js/utilitiesmodule";
import { selectValues } from "../../public/assets/js/selectvalues";

export class DefaultObjectBuilder {

    constructor() {}

    getRepository(type) {
        var repository = {
            id: null,
            name: '',
            type: type,
            type_name: platformsTypes[type].name,
            id_service_account: -2, // -1 = custom
            path: '',
            options: {}
        };

        if ([PlatformsTypesEnum.Cloud_AmazonS3Storage, PlatformsTypesEnum.Cloud_AzureBlob, PlatformsTypesEnum.Cloud_AzureFile, PlatformsTypesEnum.Cloud_IperiusS3Storage, PlatformsTypesEnum.Cloud_S3CompatibleStorage].findIndex(x => x == type) != -1) {
            repository.bucket_name = "";
        }

        return repository;
    }

    getServiceAccount(type, extraData) {
        var serviceAccount = {
            id: null,
            name: '',
            type: type,
            type_name: platformsTypes[type].name,
            options: {}
        };

        //Cloud
        if (platformsTypes[type].mainType == PlatformsTypesEnum.Cloud) {
            serviceAccount.client_id = "";
            serviceAccount.client_secret = "";
            serviceAccount.region = "";
            serviceAccount.auth_code = "";
            serviceAccount.url = "";
            serviceAccount.use_ssl = true;
            serviceAccount.options.signatureversion = 2;
            serviceAccount.options.appName = "";
            serviceAccount.options.tenant_id = "";
            serviceAccount.options.useModernAuth = true;
            return serviceAccount;
        }

        if (platformsTypes[type].mainType == PlatformsTypesEnum.Microsoft) {
            serviceAccount.client_id = "";
            serviceAccount.client_secret = "";
            serviceAccount.region = "";
            serviceAccount.options.appName = "";
            serviceAccount.options.tenant_id = "";
            serviceAccount.options.useModernAuth = true;
            serviceAccount.options.server = "";
            serviceAccount.options.domain = "";
            //serviceAccount.options.IncludeChildDomains = true; //a cosa serve?
            serviceAccount.username = "";
            serviceAccount.password = "";
            return serviceAccount;
        }

        //Email
        if (platformsTypes[type].mainType == PlatformsTypesEnum.Email) { // extradata è l'email provider selezionato
            serviceAccount.type = extraData.Id;
            serviceAccount.type_name = extraData.Name;
            serviceAccount.url = extraData.Url;
            serviceAccount.username = "";
            serviceAccount.password = "";
            serviceAccount.use_ssl = extraData.UseSSL;
            serviceAccount.port = extraData.Port;
            serviceAccount.options = {
                senderAddress: "",
                usePop3: false,
                popUsername: "",
                popPassword: "",
                popServer: "",
                popPort: 0
            };
            return serviceAccount;
        }

        //Database
        if (platformsTypes[type].mainType == PlatformsTypesEnum.Database) {
            serviceAccount.url = "";
            serviceAccount.port = type == PlatformsTypesEnum.Database_SQLServer ? 1433 : 21;
            serviceAccount.username = "";
            serviceAccount.password = "";
            serviceAccount.options = {
                useTcpIpNetLib: false,
                usePersistSecInfo: false,
                useIntSecSSPI: false,
                useTrustConn: false,
                useEncConn: false,
                useWinAuth: true,
            }
            return serviceAccount;
        }

        switch (type) {
            case PlatformsTypesEnum.FTP:
                serviceAccount.url = "";
                serviceAccount.port = 21;
                serviceAccount.username = "";
                serviceAccount.password = "";
                serviceAccount.options.connectionType = "ftp";
                serviceAccount.options.passiveMode = false;
                return serviceAccount;

            case PlatformsTypesEnum.ESXi:
                serviceAccount.url = "";
                serviceAccount.username = "";
                serviceAccount.password = "";
                return serviceAccount;

            case PlatformsTypesEnum.Network:
                serviceAccount.username = "";
                serviceAccount.password = "";
                return serviceAccount;
        }

        return serviceAccount;
    }

    getRestoreSource(type, backup) {
        var source = {
            id: null,
            id_service_account: -2,
            type: type,
            type_name: platformsTypes[type].name,
            enableCompression: false,
            options: {
                archiveType: 0,
                archiveFileCustomName: "",
                useArchivePassword: false,
                archivePassword: ""
            },
        };

        switch (type) {
            case PlatformsTypesEnum.DriveImage: // DriveImage
                source.driveImgSource = null;
                source.options.disableDasdIo = true;
                break;

            case PlatformsTypesEnum.HyperV: //HyperV
                source.folderSource = {
                    paths: [], //Lista di oggetti di tipo {path : '', type : int -> FileFolderEntityType}
                };

                source.hvSource = null;
                source.options.rct = 1; // [Andrea-Alberto 06/08/2024 - Backup di HyperV è sempre RCT]
                source.options.backupType = "full";
                source.options.nCopies = 1;
                source.options.autoAddNewItems = false;
                source.options.disableDasdIo = null;

                break;

            case PlatformsTypesEnum.ESXi: //ESXi
                source.folderSource = {
                    paths: [], //Lista di oggetti di tipo {path : '', type : int -> FileFolderEntityType}
                };
                source.esxiSource = null;

                source.options = {

                    //GENERALE
                    copySecurityPermissions: false,
                    recreateCompletePath: true,

                    //FILTRI
                    specifiedExtensions: "",
                    extensionsFilterAction: 0,

                    useFileSizeFilter: false,
                    fileSizeFilterAction: 0,
                    fileSizeFilterCompare: 0,
                    fileSize: 0, // in MB

                    //ESCLUSIONI
                    excludedPaths: [],
                    excludeSubFolders: false,

                    //COMPRESSIONE
                    useArchiveCompression: false,

                    useArchiveFileCustomName: false,
                    archiveFileCustomName: "",

                    useArchivePassword: false,
                    archivePassword: "",

                    protectionType: 0,
                    pathsInArchive: 1, // percorso completo nello zip di default

                    //BACKUP
                    backupType: "F",
                    nCopies: 1,
                    syncronize: false, // non utilizzato
                    backupMode: "STD",
                    cbtPort: 0,
                    nOfCopies: 1,
                    autoAddNewItems: false,

                };

                break;

            case 5:
            case PlatformsTypesEnum.Microsoft_ExchangeOnPremises:
            case PlatformsTypesEnum.Microsoft_ExchangeOnPremisesEWS:
            case PlatformsTypesEnum.Microsoft_Exchange365:
                //return { "id": "19593ff0-5e38-446e-808c-6aa127f31940", "jobID": "", "version": "", "type": 52, "type_name": "Exchange OnLINE", "isExchangeOnline": true, "isMultipleRestore": false, "overWrite": false, "isPST": true, "source_idSA": -2, "source_path": "G:\\Exchange_Test_Veronica\\PST Destination\\v.bachetti@iperius.onmicrosoft.com.pst", "source_type": 8, "source_type_name": "FileFolder", "destination_idSA": "ab900a23-1ee5-45e2-93d7-2d4fd6a281c7", "destination_path": "kkkkkkkkkkkkkkkkkkkkkkkkkkkkk" };
                //!!!TOLTO DESTINATIONS E MAILBOXES!!!!
                return {
                    id: "",
                    idBackup: isValid(backup) ? backup.id : '',
                    backupName: isValid(backup) ? backup.name : '',
                    version: '',
                    type: type,
                    type_name: platformsTypes[type].name,

                    restoreType: -1,
                    index: -1,

                    source_idSA: isValid(backup) ? backup.destinations[0].id_service_account : -2,
                    source_path: "",
                    source_type: isValid(backup) ? backup.destinations[0].type : 8,
                    source_type_name: isValid(backup) ? backup.destinations[0].type_name : platformsTypes[8].name,

                    destination_idSA: isValid(backup) ? backup.sources[0].id_service_account : -2,
                    destination_accounts: "",
                    destination_customFolder: ""

                }; /**/
                /*source.folderSource = {
                    paths: [], //Lista di oggetti di tipo {path : '', type : int -> FileFolderEntityType}
                };

                source.exchangeSource = null;
                source.options.outputFormat = "PST";
                //source.options.includeArchiveMailbox = false;
                source.options.useParallelMode = false;
                source.options.multipleDownloadThreads = false;
                //source.options.includePublicFolders = false;
                source.options.truncateExchangeLogs = false;
                source.options.badItemsLimit = 50;
                break;*/

            case 6: // Database
            case PlatformsTypesEnum.Database_SQLServer:
                //case 61:
                source.dbSource = null;
                source.options.verifyBackup = false;
                source.options.useNativeComp = false;
                source.options.useCopyOnly = false;
                source.options.enableAppend = false;
                source.options.appendTextBkp = "";
                source.options.singleComp = false;
                source.options.backupLog = false;
                source.options.shrinkLog = false;
                source.options.alterDbRecModel = false;
                break;
        }

        return source;
    }

    getSource(type) {
        var source = {
            id: null,
            id_service_account: -2,
            type: type,
            type_name: platformsTypes[type].name,
            enableCompression: false,
            options: {
                archiveType: 0,
                archiveFileCustomName: "",
                useArchivePassword: false,
                archivePassword: ""
            },
        };

        switch (type) {
            case PlatformsTypesEnum.DriveImage: // DriveImage
                source.driveImgSource = null;
                source.options.disableDasdIo = true;
                source.options.createPCNameFolder = true;
                source.options.backupType = "F";
                source.options.nCopies = 1;
                source.options.isCBT = true;
                source.options.resetCBT = true;
                break;

            case PlatformsTypesEnum.HyperV: //HyperV
                source.hvSource = null;
                source.options.rct = 1;
                source.options.backupType = "full";
                source.options.nCopies = 1;
                source.options.autoAddNewItems = false;
                source.options.disableDasdIo = null;

                break;

            case PlatformsTypesEnum.ESXi: //ESXi
                source.esxiSource = null;
                source.options.backupMode = "STD";
                source.options.backupType = "F";
                source.options.cbtPort = 0;
                source.options.nOfCopies = 1;
                source.options.autoAddNewItems = false;
                break;

            case 5:
            case PlatformsTypesEnum.Microsoft_ExchangeOnPremises:
            case PlatformsTypesEnum.Microsoft_ExchangeOnPremisesEWS:
            case PlatformsTypesEnum.Microsoft_Exchange365:
                source.exchangeSource = null;
                source.options.outputFormat = "PST";
                source.options.useParallelMode = true;
                source.options.backupType = "F"
                source.options.nCopies = 1
                source.options.nThreads = 6;
                source.options.nDownloadThreads = 6;
                source.options.includeArchiveMailbox = false;
                source.options.includePublicFolders = false;
                source.options.truncateExchangeLogs = false;
                source.options.badItemsLimit = 50;
                break;


            case 6: // Database
            case PlatformsTypesEnum.Database_MySQL:
            case PlatformsTypesEnum.Database_SQLServer:
                source.dbSource = null;
                source.options.verifyBackup = false;
                source.options.useNativeComp = false;
                source.options.useCopyOnly = false;
                source.options.enableAppend = false;
                source.options.appendTextBkp = "";
                source.options.singleComp = false;
                source.options.backupLog = false;
                source.options.shrinkLog = false;
                source.options.alterDbRecModel = false;
                break;

                //Questi tipi di sorgenti sono relativamente semplici,
                // quindi per praticità utilizzo lo stesso modello
            case PlatformsTypesEnum.FileFolder: // File/Folder
            case PlatformsTypesEnum.Microsoft_SharePoint: //sharepoint
            case PlatformsTypesEnum.Microsoft_Teams: // teams
            case PlatformsTypesEnum.Microsoft_OneDriveForBusiness: //onedrive
                source.folderSource = {
                    paths: [], //Lista di oggetti di tipo {path : '', type : int -> FileFolderEntityType}
                };
                source.options = {

                    //GENERALE
                    copySecurityPermissions: false,
                    recreateCompletePath: true,
                    nThreads: 10,

                    //FILTRI
                    specifiedExtensions: "",
                    extensionsFilterAction: 0,

                    useFileSizeFilter: false,
                    fileSizeFilterAction: 0,
                    fileSizeFilterCompare: 0,
                    fileSize: 0, // in MB

                    //ESCLUSIONI
                    excludedPaths: type == 8 ? [] : "",
                    excludeSubFolders: false,

                    //COMPRESSIONE
                    useArchiveCompression: false,

                    useArchiveFileCustomName: false,
                    archiveFileCustomName: "",

                    useArchivePassword: false,
                    archivePassword: "",

                    protectionType: 0,
                    pathsInArchive: 1, // percorso completo nello zip di default

                    //BACKUP
                    backupType: "F",
                    nCopies: 1,
                    syncronize: false, // non utilizzato
                    //RESTORE
                    restoreCustomFolder: ""
                };
                break;

            case PlatformsTypesEnum.Network: // Network
                source.networkSource = {
                    paths: [], //Lista di oggetti di tipo {path : '', type : int -> FileFolderEntityType}
                };
                source.options = {
                    //GENERALE
                    copySecurityPermissions: false,
                    recreateCompletePath: true,
                    nThreads: 10,

                    //FILTRI
                    specifiedExtensions: "",
                    extensionsFilterAction: 0,

                    useFileSizeFilter: false,
                    fileSizeFilterAction: 0,
                    fileSizeFilterCompare: 0,
                    fileSize: 0, // in MB

                    //ESCLUSIONI
                    excludedPaths: type == 8 ? [] : "",
                    excludeSubFolders: false,

                    //COMPRESSIONE
                    useArchiveCompression: false,

                    useArchiveFileCustomName: false,
                    archiveFileCustomName: "",

                    useArchivePassword: false,
                    archivePassword: "",

                    protectionType: 0,
                    pathsInArchive: 1, // percorso completo nello zip di default

                    //BACKUP
                    backupType: "F",
                    nCopies: 1,
                    syncronize: false, // non utilizzato
                    //RESTORE
                    restoreCustomFolder: ""

                };
                break;
        }

        return source;
    }

    getDestination(type, backupSource) {
        var destination = {
            id: null,
            name: '',
            type: type,
            type_name: platformsTypes[type].name,
            id_service_account: isValid(backupSource) ? backupSource.id_service_account : -2,
            id_repository: -2,
            path: "",
            options: {},
        };

        return destination;
    }

    //Corrisponde a ...\iperius-enterprise-agent\IperiusEWP\BackupSources\FileFolder\Model\RestoreFileModel.cs
    getRestoreFile(type, backup) {
        var restoreFile = {
            id: "", // id del job di restore
            idBackup: isValid(backup) ? backup.id : "", // id del job di backup
            backupName: isValid(backup) ? backup.name : "",
            type: type,
            type_name: platformsTypes[type].name,
            /**
             * 0 = Scarica dei singoli elementi
             * 1 = ricostruisci copia full
             * 2 = ricostruisci una copia specifica
             */
            restoreType: -1,
            createFolderWithBackupName: false,
            index: -1,
            source: this.getRestoreFileSource(isValid(backup) ? backup.destinations[0] : null),
            destination: isValid(backup) ?
                this.getDestination(backup.sources[0].type, backup.sources[0]) : this.getDestination(type, null)
        };

        return restoreFile;
    }

    getRestoreFileSource(backupDestination) {
        var restoreFileSource = {
            id_service_account: isValid(backupDestination) ? backupDestination.id_service_account : -2,
            type: isValid(backupDestination) ? backupDestination.type : -1,
            type_name: isValid(backupDestination) ? backupDestination.type_name : "",

            paths: [],
            options: {
                //GENERALE

                //FILTRI
                specifiedExtensions: "",
                extensionsFilterAction: 0,

                useFileSizeFilter: false,
                fileSizeFilterAction: 0,
                fileSizeFilterCompare: 0,
                fileSize: 0, // in MB

                //ESCLUSIONI
                excludedPaths: [],
                excludeSubFolders: false,

                //COMPRESSIONE
                useArchiveCompression: false,

                useArchiveFileCustomName: false,
                archiveFileCustomName: "",

                useArchivePassword: false,
                archivePassword: "",

                protectionType: 0,
                pathsInArchive: 1, // percorso completo nello zip di default

                //RESTORE
                restoreInOriginalPosition: true
            }
        };
        return restoreFileSource;

    }

    getCompleteBackup(apiBackup, socketBackup) {
        var backup = {
            //ID
            "id": apiBackup.id,
            "id_computer": apiBackup.id_computer,
            "id_group": apiBackup.id_group,
            "id_master_account": apiBackup.id_master_account,
            //NAME
            "name": apiBackup.name,
            "name_computer": apiBackup.name_computer,
            "name_group": apiBackup.name_group,
            //DATE
            "dt_start_utc": isValid(apiBackup.dt_start_utc) ? apiBackup.dt_start_utc : isValid(socketBackup.dt_start_utc) ? socketBackup.dt_start_utc : "",
            "dt_end_utc": isValid(apiBackup.dt_end_utc) ? apiBackup.dt_end_utc : isValid(socketBackup.dt_end_utc) ? socketBackup.dt_end_utc : "",
            "inserted_at": isValid(apiBackup.inserted_at) ? apiBackup.inserted_at : "",
            "updated_at": isValid(apiBackup.updated_at) ? apiBackup.updated_at : "",

            //ESECUZIONE JOB
            "isRunning": isValid(socketBackup.isRunning) ? socketBackup.isRunning : "",
            "current_operation": isValid(socketBackup.current_operation) ? socketBackup.current_operation : "",
            "progress": isValid(socketBackup.progress) ? socketBackup.progress : "",
            "processed_data": isValid(socketBackup.processed_data) ? socketBackup.processed_data : "",

            "last_backup_copiedfiles": isValid(apiBackup.last_backup_copiedfiles) ? apiBackup.last_backup_copiedfiles : "",
            "last_backup_size": isValid(apiBackup.last_backup_size) ? apiBackup.last_backup_size : "",
            "last_result": isValid(apiBackup.last_result) ? apiBackup.last_result : isValid(socketBackup.lastresult) ? socketBackup.lastresult : "",

            //MAIN INFOS
            "type": isValid(apiBackup.type) ? apiBackup.type : isValid(socketBackup.type) ? socketBackup.type : "",
            "type_name": isValid(apiBackup.type) ? platformsTypes[apiBackup.type].name : isValid(socketBackup.type) ? platformsTypes[socketBackup.type].name : "",
            "scheduling": isValid(apiBackup.scheduling) ? apiBackup.scheduling : isValid(socketBackup.scheduling) ? socketBackup.scheduling : "",

            //SOURCES/DESTINATIONS
            "sources": apiBackup.sources,
            "destinations": apiBackup.destinations,

            //LOGS : Verranno richiesti solo se necessario
            "logs": null,
        };

        return backup;
    }

    getGroup() {
        return {
            "address": "",
            /*
            1 = gruppo privato creato successivamente
            2 = gruppo privato di default
            3 = gruppo shared di default
            4 = gruppo shared creato successivamente
            */
            "computers": [],
            "email": "",
            "id": null,
            "id_master_account": "",
            "name": "",
            "notes": "",
            "phone_number": "",
            "users": [],
        };
    }

    getUser() {
        var emptyuser = {
            "id": null,
            "username": "",
            "firstname": "",
            "lastname": "",
            "name": "",
            "address": "",
            "level": "2",
            "computers": [],
            "groups": [],
            "status": "1",
            "mfa_enabled": "false",
            "role": "User",
            "id_master_account": "",
        };

        return emptyuser;
    }

    /**
     * apiJobs è un array di oggetti del tipo:
     *     {
        
    },
     */
    fromAPIJobsToSocketJobs(apiJobs) {
        return apiJobs.map(job => {
            return {
                current_operation: "",
                dt_end_utc: job.dt_end_utc, // 2023-08-04T13:45:24Z -> 20230804172436
                dt_start_utc: job.dt_start_utc, // 2023-08-04T13:45:24Z
                id: job.id,
                isRunning: false,
                lastresult: job.last_result,
                name: job.name,
                processed_data: 0,
                progress: 0,
                scheduling: job.scheduling,
                type: parseInt(job.type),
                type_name: platformsTypes[job.type]
            };
        });
    }

    fromObjectToPropertiesList(obj) {
        return Object.keys(obj)
            .map(x => this.getObjectFromProp(obj, x))
            .filter(x => x != null)
            .sort((a, b) => {
                if (!isValid(a.level))
                    a.level = 30;

                if (!isValid(b.level))
                    b.level = 30;

                return a.level - b.level;
            });
    }

    getObjectFromProp(obj, prop) {

        if (propsToExclude.includes(prop) || !isValid(obj[prop]))
            return null;

        switch (prop) {
            case "id":
                return {
                    level: 0,
                    section: '',
                    title: "ID",
                    value: obj[prop],
                    copyable: true
                }
            case "name":
                return {
                    level: 0,
                    section: '',
                    title: "Name",
                    value: obj[prop],
                    copyable: true
                }
            case "type_name":
                return {
                    level: 0,
                    section: '',
                    title: "Type",
                    value: obj[prop],
                    copyable: false
                }
            case "path":
                return {
                    level: 2,
                    section: '',
                    title: "Path",
                    value: obj[prop],
                    copyable: true
                }
            case "client_id":
                return {
                    level: 1,
                    section: 'Credentials',
                    title: "Client ID",
                    value: obj[prop],
                    copyable: true
                }
            case "client_secret":
                return {
                    level: 1,
                    section: 'Credentials',
                    title: "Cient secret",
                    value: obj[prop],
                    copyable: true
                }
            case "region":
                return {
                    level: 2,
                    section: 'Credentials',
                    title: "Region",
                    value: obj[prop],
                    copyable: false
                }
            case "url":
                return {
                    level: 2,
                    section: '',
                    title: "URL",
                    value: obj[prop],
                    copyable: true
                }
            case "appName":
                return {
                    level: 1,
                    section: 'Credentials',
                    title: "Application name",
                    value: obj[prop],
                    copyable: true
                }
            case "tenant_id":
                return {
                    level: 1,
                    section: 'Credentials',
                    title: "Tenant ID",
                    value: obj[prop],
                    copyable: true
                }
            case "useModernAuth":
                return {
                    level: 2,
                    section: '',
                    title: "Use modern authentication",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
            case "username":
                return {
                    level: 1,
                    section: 'Credentials',
                    title: "Username",
                    value: obj[prop],
                    copyable: true
                }
            case "password":
                return {
                    level: 1,
                    section: 'Credentials',
                    title: "Password",
                    value: obj[prop],
                    copyable: true
                }
            case "useWinAuth":
                return {
                    section: '',
                    title: "Use Windows authentication",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
            case "port":

                if (!obj.type.toString().startsWith('5') && !obj.type.toString().startsWith('6') && !obj.type.toString().startsWith('7') && ![PlatformsTypesEnum.FTP, PlatformsTypesEnum.Network, PlatformsTypesEnum.ESXi].includes(parseInt(obj.type.toString())))
                    return null;
                return {
                    level: 2,
                    section: 'Credentials',
                    title: "Port",
                    value: obj[prop],
                    copyable: true
                }
            case "use_ssl":
                if (!platformsTypes[parseInt(obj.type.toString())].mainType == PlatformsTypesEnum.Email)
                    return null;
                return {
                    section: '',
                    title: "Use SSL",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "useTcpIpNetLib":
                return {
                    section: '',
                    title: "Use 'TCP/IP Net Library'",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "usePersistSecInfo":
                return {
                    section: '',
                    title: "Use 'Persist Security Info'",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
            case "useIntSecSSPI":
                return {
                    section: '',
                    title: "Use 'Integrated Security SSPI'",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
            case "useTrustConn":
                return {
                    section: '',
                    title: "Use Trusted Connection",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
            case "useEncConn":
                return {
                    section: '',
                    title: "Use Encrypted Connection",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
            case "senderAddress":
                return {
                    section: '',
                    title: "Sender address",
                    value: obj[prop],
                    copyable: true
                }
            case "usePop3":
                return {
                    section: 'POP3',
                    title: "Use POP3",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
            case "popUsername":
                return {
                    section: 'POP3',
                    title: "POP3 Username",
                    value: obj[prop],
                    copyable: true
                }
                /*case "popPassword":
                    return {
                        section: 'POP3',
                        title: "POP3 Password",
                        value: obj[prop],
                        copyable: true
                    }*/
            case "popServer":
                return {
                    section: 'POP3',
                    title: "POP3 Server",
                    value: obj[prop],
                    copyable: true
                }
            case "popPort":
                return {
                    section: 'POP3',
                    title: "POP3 Port",
                    value: obj[prop],
                    copyable: true
                }
            case "connectionType":
                return {
                    section: '',
                    title: "Connection type",
                    value: obj[prop],
                    copyable: false
                }
            case "enableCompression":
                return {
                    section: 'Compression',
                    title: "Enable compression",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
            case "archiveType":
                return {
                    section: 'Compression',
                    title: "Archive type",
                    value: selectValues.archiveTypes[prop],
                    copyable: false
                }
            case "archiveFileCustomName":
                return {
                    section: 'Compression',
                    title: "Archive file custom name",
                    value: obj[prop],
                    copyable: false
                }
            case "useArchivePassword":
                return {
                    section: 'Compression',
                    title: "Use archive password",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
                /*case "archivePassword":
                    return {
                        section: 'Compression',
                        title: "Archive password",
                        value: obj[prop],
                        copyable: false
                    }*/
            case "disableDasdIo":
                return {
                    section: '',
                    title: "Enable I/O Boundary Checks by the file system",
                    value: obj[prop],
                    copyable: false
                }
            case "rct":
                return {
                    section: 'Backup',
                    title: "Backup RCT (Resilient Change Tracking)",
                    value: obj[prop],
                    copyable: false
                }
            case "passiveMode":
                return {
                    section: '',
                    title: "Passive mode",
                    value: obj[prop],
                    copyable: false
                }
            case "nCopies":
                return {
                    section: 'Backup',
                    title: "N. Copies",
                    value: obj[prop],
                    copyable: false
                }
            case "autoAddNewItems":
                return {
                    section: '',
                    title: "Add automatically every new virtual machine to the backup",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "badItemsLimit":
                return {
                    section: '',
                    title: "BAD ITEMS LIMIT",
                    value: obj[prop],
                    copyable: false
                }
            case "outputFormat":
                return {
                    section: '',
                    title: "OUTPUT FORMAT",
                    value: obj[prop],
                    copyable: false
                }
            case "rtc":
                return {
                    section: '',
                    title: "RTC",
                    value: obj[prop],
                    copyable: false
                }
            case "server":
                return {
                    section: '',
                    title: "SERVER",
                    value: obj[prop],
                    copyable: true
                }
            case "useParallelMode":
                return {
                    section: '',
                    title: "USE PARALLEL MODE",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
            case "useNativeComp":
                return {
                    section: 'Compression',
                    title: "USE NATIVE COMPRESSION",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
            case "replicaName":
                return {
                    section: '',
                    title: "REPLICA NAME",
                    value: obj[prop],
                    copyable: true
                }

            case "cbtPort":
                return {
                    section: '',
                    title: "CBT PORT",
                    value: obj[prop],
                    copyable: true
                }

            case "includeArchiveMailbox":
                return {
                    section: '',
                    title: "Include Archive Mailbox",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "includePublicFolders":
                return {
                    section: '',
                    title: "Include public folders",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "truncateExchangeLogs":
                return {
                    section: '',
                    title: "Truncate Exchange logs",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "useCopyOnly":
                return {
                    section: '',
                    title: "Use copy only",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "enableAppend":
                return {
                    section: '',
                    title: "Append text and/or dynamic variables to the backup file name",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "appendTextBkp":
                return {
                    section: '',
                    title: "Append text backup",
                    value: obj[prop],
                    copyable: false
                }

            case "singleComp":
                return {
                    section: 'Compression',
                    title: "Create .zip file for each single database",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "backupLog":
                return {
                    section: '',
                    title: "Backup the transaction log",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "shrinkLog":
                return {
                    section: '',
                    title: "Shrink the transaction log after the backup",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "alterDbRecModel":
                return {
                    section: '',
                    title: "Alter the database recovery mode to SIMPLE if necessary",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }
            case "excludedPaths":
                return {
                    section: 'Filters',
                    title: "Excluded paths",
                    value: obj[prop].map(r => r.path).join(", "),
                    copyable: false
                }

            case "excludeSubFolders":
                return {
                    section: 'Filters',
                    title: "EXCLUDE SUB FOLDERS",
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "specifiedExtensions":
                return {
                    section: 'Filters',
                    title: selectValues.extensionsFilterAction[obj.extensionsFilterAction],
                    value: obj[prop],
                    copyable: false
                }

            case "useFileSizeFilter":

                if (obj[prop])
                    return {
                        section: 'Filters',
                        title: selectValues.fileSizeFilterAction[obj.fileSizeFilterAction],
                        value: selectValues.fileSizeFilterCompare[obj.fileSizeFilterCompare] + " " + obj.fileSize + " MB",
                        copyable: false
                    }
                return {
                    section: 'Filters',
                    title: "Use size filter",
                    value: "No",
                    copyable: false
                }


            case "backupMode":
                return {
                    section: 'Backup',
                    title: 'MODE',
                    value: obj[prop],
                    copyable: false
                }


            case "backupType":
                return {
                    section: 'Backup',
                    title: 'TYPE',
                    value: obj[prop].toLowerCase().startsWith('i') ? 'Incremental' : obj.backupType.toLowerCase().startsWith('d') ? 'Differential' : obj.backupType.toLowerCase().startsWith('f') ? 'Full' : 'Update full',
                    copyable: false
                }
            case "nOfCopies":
                return {
                    section: 'Filters',
                    title: 'N. Copies',
                    value: obj[prop],
                    copyable: false
                }

            case "verifyBackup":
                return {
                    section: 'Backup',
                    title: 'Verify backup',
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "useArchiveCompression":
                return {
                    section: 'Compression',
                    title: 'Use archive compression',
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "useArchiveFileCustomName":
                return {
                    section: 'Compression',
                    title: 'USE ARCHIVE FILE CUSTOM NAME',
                    value: obj[prop].toString().toLowerCase() == "true" || obj[prop].toString() == "1" ? "Yes" : "No",
                    copyable: false
                }

            case "protectionType":
                return {
                    section: 'Compression',
                    title: "PROTECTION TYPE",
                    value: obj[prop],
                    copyable: false
                }
            case "pathsInArchive":
                return {
                    section: 'Compression',
                    title: "PATHS IN ARCHIVE",
                    value: obj[prop],
                    copyable: false
                }
            case "bucket_name":
                return {
                    level: 2,
                    section: '',
                    title: "Bucket",
                    value: obj[prop],
                    copyable: true
                }
            case "signatureversion":
                return {
                    section: '',
                    title: "Signature version",
                    value: obj[prop],
                    copyable: false
                }

        }

        alert("proprietà " + prop + " non mappata in default object builder")
        return null;
    }

}

const propsToExclude = [
    "id_master_account",
    "access_token",
    "refresh_token",
    "status",
    "token",
    "type",
    "options",
    "accountSessionId",
    "id_service_account",
    "password",
    "archivePassword",
    "popPassword",
    "objID",
    "driveID",
    "userID",
    "lastTestPC",
    "client_secret"
];