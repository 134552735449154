    import BackupLastResult from "../components/utils/BackupLastResult";
    import Navigation from "../components/utils/Navigation";
    import { formatDate, fromNow, diffDate, prettyBytes } from '../../public/assets/js/utilitiesmodule';
    import PCLink from "../components/utils/PCLink"
    import { NO_UPPERCASE_CHARACTERS } from '../../public/assets/js/messages';
    import BackupView from '../components/dialogs/view/BackupView';
    import PCRemoteLink from '../components/utils/PCRemoteLink.vue';
    import PCBackupLink from '../components/utils/PCBackupLink.vue';
    import StatsCollector from '../components/stats/StatsCollector.vue'
    import ApexCharts from 'apexcharts'
    import $ from 'jquery';
    import { PC_MESSAGES } from '../../public/assets/js/messages';

    export default {
        name: "Dashboard",
        components: {
            "BackupLastResult": BackupLastResult,
            "PCLink": PCLink,
            "PCRemoteLink": PCRemoteLink,
            "PCBackupLink": PCBackupLink,
            StatsCollector,
            Navigation,
            BackupView
        },
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Dashboard',
            // all titles will be injected into this template
            titleTemplate: '%s | Iperius One ',
        },
        data() {
            return {
                licenseName: "",
                disabledDates: {
                    from: new Date(), // Disable all dates after specific date
                },
                //caricati tramite api
                jobs: null,
                loading: false,
                firstline: true,
                lastline: false,
                sessionrow: 0,
                first_dt_start: 0,
                total_session_inarow: "",
                headergroupname: "",
                filters: {
                    numrows: 30,
                    offset: 0,
                    lastresult: '',
                    dt_inf: '',
                    dt_sup: '',
                    id_group: '',
                    id_host: '',
                    id_user: '',
                    limit: '-1'
                },
                sessions: null,
                computers: null,
                events: null,
                transformedArray: {},
                users: null,
                roles: null,
                types: null,
                filterDate: null,
                groups: null,
                controls: null,
                checkTFA: {
                    step1: null,
                    step2: null
                },
                treeViewHtml: null,
                gridView: false,
                subDirectories: [],
                parentDir: null,
                datacollection: null,
                fullStats: null,
                selectedcomputer: null,
                selecteduser: null,
                selectedgroup: null,
                selectedbackup: null,
                connected: false,

                platformsTypes: null,

              


            }
        },
        beforeDestroy: function() {
            this.$root.$off("socketEventConnected");
            this.$root.$off("socketEventDisconnected");
            this.$root.$off("addBackupsToPage");
            this.$root.$off("is_online");
            this.computers = null;  // Pulire i dati in memoria
            this.sessions = null;
            this.events = null;
            //this.$root.newPage("dashboard", "destroy");
        },
        filters: {
            prettyBytes: function(bytes, precision) {
                return prettyBytes(bytes, precision);
            },

        },
        mounted: async function() {


            //this.$root.newPage("dashboard", "mounted");
            this.licenseName = this.$root.checkAuth().nome_licenza;
            //popolo i valori dei filtri
            this.headergroupname = "";
            this.sessionrow = 0;
            this.filters.typeList = this.$root.platformsTypes;
            this.filters.resultList = this.$root.ResultValue;
            this.filters.dt_inf = this.$moment().subtract(5, 'days').format('YYYY-MM-DD')
            this.filters.dt_sup = this.$moment().format('YYYY-MM-DD')

            this.$session.set("ROOMPC", null);

            this.startRetrieveDataFromAPI();

            
        },

        computed: {
            userEmail() {
                return this.$root.checkAuth().email;
            },
        },
        methods: {

        

            async removeFromLicense(idPC) {
                let self = this;
                var isconfirmed = await this.$root.openDialogActions("Unregister device from license");
                if (isconfirmed) {
                    this.$root.api("REMFROMLICENSE", idPC).then((value)=>{
                        if(value){
                            self.$root.toast(this.$t(PC_MESSAGES.LICENSE_REMOVED), 5000, "success");
                            self.computers[idPC].backup_license_status = 3;
                        }
                    }
                    );
                }
            },

            async reconnectToLicense(idPC) {
                let self = this;
                var isconfirmed = await this.$root.openDialogActions("Register this device to license", "Are you sure?");
                if (isconfirmed) {
                    self.$root.api("ADDTOLICENSE", idPC).then((value)=>{
                        if(value){
                            self.$root.toast(this.$t(PC_MESSAGES.LICENSE_ADDED), 5000, "success")
                            self.computers[idPC].backup_license_status = 2;
                        }
                    }
                    );
                }
            },


            /********************************STATS*****************************************************/

            //Si effettuano le chiamate API per richiedere i dati più utilizzati e si inseriscono in sessione. 
            //In questo modo verranno presi in maniera più veloce
            //Per praticità, tutte le chiavi di session sono in uppercase
            startRetrieveDataFromAPI() {

                let self = this;

                this.$root.$on("DeletedJob", idBackup => {
                    var index = self.jobs.findIndex(j => j.id == idBackup);
                    self.jobs.splice(index, 1);
                });


                //Solo dopo aver recuperato i computers, avvio la connessione socket e recupero i jobs
                this.$root.api("GETPCLIST").then(pcs => {

                    self.$session.set("COMPUTERS", pcs);

                    if (pcs == []) {
                        //Se la chiamata fosse andata a buon fine, computers sarebbe stato una mappa idPC : pc 
                        self.computers = {};
                        return;
                    }
                    //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
                    // "checkSocket" avvia la connessione socket se ferma
                    // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
                    // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
                    this.$root.$on("socketEventConnected", () => {
                        self.$root.socketConnected = true;
                        self.$root.checkConnectionTimerLight();
                    });

                    //passo la lista dei computer e vedo quali sono connessi attraverso IPERIUS REMOTE, cioè collegati come host
                    self.$root.api("GETPCREMOTECONNECTEDLIST", pcs).then(res => {
                        self.$root.connectionsStatesRemote = res;
                        self.$session.set("connectionsStatesRemote", res);
                        pcs.forEach(computer => {
                            computer.connected_remote = res == [] ? false : res[computer.id_remote];
                        });
                        //Trasformo la lista in una mappa {idPC : pc}
                        self.computers = pcs.reduce((obj, pc) => ({...obj, [pc.id]: pc }), {});
                        self.getJobs();
                    });
                    this.$root.checkSocket();



                });

                this.$root.api("GETUSERS").then(res => {
                    self.users = res;
                    self.$session.set("USERS", res);
                });

                this.$root.api("GETGROUPS").then(res => {
                    self.groups = res;
                    self.$session.set("GROUPS", res);
                });

                //ho fatto la pagina apposta
                /* this.$root.api("GETCONTROLS").then(res => {
                    self.controls = res.controls;
                    self.$session.set("CONTROLS", res.controls);
                }); */

                this.$root.api("GETSESSIONSLIGHT").then(res => {
                    self.sessions = res;
                });

                this.$root.api("GETROLES").then(res => {
                    self.roles = res;
                    self.$store.commit('SET_ROLES', res);
                    self.$session.set("ROLES", res);
                });

                this.$root.api("GETTYPES").then(res => {
                    self.types = res;
                    self.$store.commit('SET_TYPES', res);
                    self.$session.set("TYPES", res);
                });

                this.$root.api("GETSERVICEACCOUNTS").then(serviceAccounts => {
                    self.$session.set("SERVICEACCOUNTS", serviceAccounts);
                });

                this.$root.api("GETREPOSITORIES").then(repositories => {
                    self.$session.set("REPOSITORIES", repositories);
                });


                this.$root.api("GETSTATSFROMLICENSELIGHT").then(stats => {
                    self.fullStats = stats;
                    //self.statsOS();
                });

                this.$root.api("GETEVENTS").then(events => {
                    self.events = events;
                });


            },


            /*****************************BACKUPS*************************************************/

            async getJobs() {
                let self = this;
                if (self.jobs != null && self.jobs.length > parseInt(this.filters.numrows)) {
                    return;
                }
                self.jobs = await this.$root.api("GETBACKUPS", null, self.filters.lastresult == '' ? 99 : self.filters.lastresult, self.filters.numrows, self.filters.offset);
            },

            /*  applyFiltersToJobs() {
                let self = this;
                var list = self.jobs;
                if (this.filters.numrows.toString() == "25" && list.length > 25)
                    list = list.slice(0, 25);

                if (this.filters.lastresult == 'idle') {
                    list = list.filter(j => {
                        if (isValid(j.scheduling) && j.scheduling[0] != '') {
                            var interval = self.cronParser.parseExpression(j.scheduling[0], { currentDate: new Date(), iterator: true });
                            console.log(interval.next().value.toString());
                            console.log();
                            var prevIteration = new Date(interval.prev().value.toString());
                            return new Date(j.dt_start_utc) < prevIteration;
                        }
                        return false;
                    });
                }

                if (this.filters.lastresult != 'idle' && this.filters.lastresult != '')
                    list = list.filter(job => job.last_result.toString() == this.filters.lastresult);

                return list;
            },
 */
            //metodo per controllare se devo aggiungere o meno la riga iniziale...
            shouldBeAddedBefore(session) {
                if (this.headergroupname == "" || session.name_computer_control != this.headergroupname) {
                    this.headergroupname = session.name_computer_control;
                    this.sessionrow = 1;
                    this.total_session_inarow = session.total_time;
                    return true;
                }
                if (session.name_computer_control == this.headergroupname) {
                    this.total_session_inarow += session.total_time;
                    this.sessionrow += 1;
                    return false;
                }

            },
            shouldBeAddedAfter(session) {

                if (this.headergroupname != "" && session.name_computer_control != this.headergroupname) {

                    return true;
                }
                return false;

            },



            openComputerView(computer) {
                this.closeCharm();
                this.$root.api("GETPC", computer.id).then(computer => {
                    this.selectedcomputer = computer;
                    window.Metro.charms.open("#computerselected");
                });
            },
            closeCharm() {
                window.Metro.charms.close('#computerselected');
            },

            async openBackupView(idBackup, idPC) {

                //Si vuole aprire la view del job con l'id passato.
                //Il job mostrato sarà un insieme di informazioni prese sia dalla chiamata API, sia dal job arrivato in realtime tramite socket.
                //Per questo secondo tipo di job è necessario che il computer collegato al job sia connesso.
                //Se il computer è connesso, si chiede all'agent l'invio dei backups
                let self = this;
                if (this.$session.get("PCSCONNECTIONS")[idPC]) {
                    //le chiamate stop e unsubscribe verranno effettuate all'interno di backupview
                    self.$root.socket("subscribe_pc", idPC)
                        .then(() => self.$root.socket("STARTSENDINFO"));


                    //TODO: VERONICA: non sarebbe meglio metterlo direttamente sulla maschera che si apre in modo da semplificare?
                    //backups_received è una mappa in cui le chiavi sono gli id dei job e i valori sono i job
                    this.$root.$on("addBackupsToPage", backups_received => {
                        self.$root.$emit("sendRealtimeJob", backups_received[idBackup]);
                    });

                }
                //this.selectedbackup = this.jobs[index];
                this.$root.$emit("OPENBACKUPVIEW", idBackup);
                //window.Metro.charms.toggle("#backupselected");
            },
            /*****************************USERS*************************************************/
            async deleteUser(user) {

                if (!this.$root.openDialogActions()) return;

                let self = this;
                var userLite = {
                    id: user.id,
                    username: user.username
                }
                var deleted = await this.$root.api("DELETEUSER", userLite);

                if (deleted) {
                    self.users.splice(self.selectedIndex, 1);
                    self.closeDialog();
                }
            },
            

            async saveUser() {
                let self = this;
                this.selecteduser.directories = [];
                $('#treeview input[type=checkbox]:checked').each(function() {
                    this.selecteduser.directories.push($(this).val().toString());
                });


                if (!this.selecteduser.password.split("").some(c => 65 <= c.charCodeAt(0) && c.charCodeAt(0) <= 90)) {
                    this.$root.toast(this.$t(NO_UPPERCASE_CHARACTERS), 5000, "alert");
                    return;
                }

                var isNew = this.selecteduser.id == null;
                var method = isNew ? "CREATEUSER" : "EDITUSER";
                var user = await this.$root.api(method, this.selecteduser);
                var ok = user != null;

                if (ok) {
                    self.closeDialog();
                    if (isNew) {
                        self.users.push(user);
                    } else {
                        self.users[self.selectedIndex] = user;
                    }
                }
            },

            statsOS() {
                let self = this;

                var options = {
                    series: self.fullStats.os_versions.map((item => (item.count))),
                    labels: self.fullStats.os_versions.map((item => (item.os))),
                    plotOptions: {
                        pie: {

                            size: 100,
                            expandOnClick: false
                        }
                    },
                    /* series: [{
                        data: self.fullStats.os_versions.map((item => ({
                            x: item.os.split("-")[0],
                            y: item.count
                        }))),
                        
                    }], */

                    chart: {
                        type: 'donut'
                    },

                    legend: {
                        show: false,
                        position: 'bottom'
                    },
                    xaxis: {
                        show: false,
                        showAlways: false,
                        labels: {
                            show: false,
                            align: 'left',

                        },
                    },
                    yaxis: {
                        show: true,
                        showAlways: false,
                        labels: {
                            show: true,
                            align: 'left',
                        }
                    },

                };

                $("#os-per-pc").html('');
                var chart = new ApexCharts(document.querySelector("#os-per-pc"), options);
                chart.render();
            },


            openDialogUser(user, index) {

                this.selectedIndex = index;
                if (user == null) {
                    this.selecteduser = {};
                    this.selecteduser.id = null;
                    //this.selecteduser.directories = [];
                    this.selecteduser.email = null;
                    this.selecteduser.username = null;
                    this.selecteduser.firstname = null;
                    this.selecteduser.lastname = null;
                    this.selecteduser.password = "";
                    this.selecteduser.role = "User";
                } else {
                    this.selecteduser = JSON.parse(JSON.stringify(user));
                    this.$root.$emit("OPENUSERVIEWDIALOG", this.selecteduser, self.checkTFA);
                }
                //this.createTreeView(this.selecteduser);
            },




            /*****************************UTILS*************************************************/

            closeDialog() {
                this.selecteduser = null;
            },
            createTree(array_nodes, directoryChecked) {
                var nodi = "";
                //v-model="selecteduser.directories" 
                if (array_nodes == "") return nodi;
                array_nodes.forEach(node => {
                    nodi += '<li > <input type="checkbox" data-role="checkbox" value="' + node.id + '" ' + (directoryChecked.includes(node.id) ? 'checked' : '') + ' data-caption="' + node.name + '" ><ul>' + this.createTree(node.subDirectories, directoryChecked) + '</ul></li>'
                });
                return nodi;
            },

            createTreeView(userSelected) {
                let self = this;

                self.treeViewHtml = "";
                self.treeViewHtml += '<ul id="treeview" data-role="treeview"  >' + self.createTree([self.treeView], userSelected.groups) + '</ul>';
            },



            /*
            ?????????????????????????????????????????????????????????
            getDirectory(dir, direction = "down") {
                let self = this;

                api
                    .get('/directories/' + self.idLicense + "?d=" + (direction == "down" ? dir.id : dir.parent), {
                        headers: {
                            Authorization: 'Bearer ' + self.$root.checkAuth().access_token
                        }
                    })
                    .then(response => {
                        self.computers = response.data.computers;
                        self.subDirectories = response.data.subDirectories;
                        self.parentDir = response.data.parent;
                        self.dir = response.data
                    })
            },*/

            buildHintText(computer) {
                var text = "";
                if (computer.disk_alert == 1)
                    text += "Some disk alert\n";
                if (computer.ram_alert == 1)
                    text += "Some RAM alert\n";
                if (computer.av_alert == 1)
                    text += "Some antivirus alert\n";
                return text;
            },

            formatDate(date) {
                return formatDate(date, this.$moment);
            },
            fromNow(date) {
                return fromNow(date, this.$moment);
            },
            diffDate(datestart, dateend) {
                return diffDate(datestart, dateend, this.$moment);
            },


        }
    };