import { platformsTypes } from '../../public/assets/js/utilitiesmodule';
import Navigation from "../components/utils/Navigation";
import NoDataAvailable from '../components/utils/NoDataAvailable';
import SARepoView from '../components/dialogs/view/SARepoView';

export default {
    name: "Accounts",
    data() {
        return {
            serviceAccounts: null,
            selectedType: -1,
            selectedIndex: -1,
            accselected: null,
            accountselected: null,
            computers: [],

            platformsTypes: [],
            microsoftTypes: [],
            emailTypes: [],
        };
    },
    props: {
        msg: String,
    },
    components: {

        NoDataAvailable,
        Navigation,
        SARepoView
    },
    watch: {
        '$route': {
            handler: function(to) {
                if (to.hash != "") {
                    var idServiceAccount = to.hash.replace("#", "");
                    this.$root.$emit('OPENSERVICEACCOUNTDIALOG', this.$session.get("SERVICEACCOUNTS").find((sa) => sa.id == idServiceAccount))
                }
            },
            deep: true,
            immediate: true
        }
    },
    beforeDestroy: async function() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        this.$root.$off("REFRESHSERVICEACCOUNTS");
        this.serviceAccounts = null;  // Pulire i dati memorizzati
        this.computers = null;
        //alert("destroy accounts.JS");
        //this.$root.checkConnectionTimerAction("stop");
        //this.$root.newPage("accounts", "destroy");
    },
    created: function() {
        //alert("CREATED COMPUTERS.JS");
        //this.$root.newPage("accounts", "created");
        this.serviceAccounts = this.$session.get("SERVICEACCOUNTS");
        let self = this;
        this.$root.$on("REFRESHSERVICEACCOUNTS", () => self.serviceAccounts = self.$session.get("SERVICEACCOUNTS"));

        this.platformsTypes = platformsTypes;
        this.microsoftTypes = this.$root.getMicrosoftTypes();
        this.emailTypes = this.$root.getEmailTypes();
        this.$root.$on("socketEventConnected", () => {
            self.$root.socketConnected = true;
            self.$root.checkConnectionTimerLight();
        });
        //"socketEventDisconnected" viene emesso se la connessione socket non è attiva
        this.$root.$on("socketEventDisconnected", () => {
            self.$root.socketConnected = false;
        });
        this.$root.checkSocket();


    },
    mounted: async function() {
        //this.$root.newPage("accounts", "mounted");
        this.$session.set("ROOMPC", null);
        this.computers = this.$session.get("COMPUTERS");
        this.platformsTypes = platformsTypes;

    },

    methods: {
        /***************************************PC********************************/
        checkClick() {
            console.log("checkbox clicked....")
        },

        async deleteServiceAccount(sa, index) {
            var self = this;

            if (await !self.$root.openDialogActions()) return;

            var saLite = {
                id: sa.id,
                name: sa.name
            }

            var deleted = await this.$root.api("DELETESERVICEACCOUNT", saLite);

            if (!deleted)
                return;
            this.serviceAccounts.splice(index, 1);
            this.$session.set("SERVICEACCOUNTS", this.serviceAccounts);
            //this.$forceUpdate();

        },

        see(sa) {
            this.accountselected = sa;
            window.Metro.charms.toggle("#accountselected");
        },

        closeCharm() {
            window.Metro.charms.toggle("#reposelected");
        }
    },
}