var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "text-center bg-white h-100" }, [
    _c("img", { attrs: { src: "assets/img/theme/404.png" } }),
    _c(
      "div",
      { staticClass: "text-leader2 text-center page-error-box mx-auto" },
      [
        _vm._v(" We could not find the page you were looking for. "),
        _c("br"),
        _vm._v("Meanwhile, you may "),
        _c("router-link", { attrs: { to: "/dashboard" } }, [
          _vm._v("return to dashboard"),
        ]),
        _vm._v(" or try using the search form. "),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }