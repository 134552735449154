<template>
    <div class="form-group p-2">
        <!-------------------------------------SERVICEACCOUNT MENU---------------------------------------------------------------------->
        <div v-if="!selectRepo" class="d-flex flex-row flex-align-baseline">
            <span class="mif-key mr-1" />
            <span>{{ $t("Select a service account") }}:</span>
            
        </div>

        <!-------------------------------------REPOSITORY MENU---------------------------------------------------------------------->
        <div v-else class="d-flex flex-row  flex-align-baseline p-2">
            <span class="mif-folder-open mr-1" />
            <span> {{ $t("Select a repository") }}:</span>
            <a class="button ml-2">
                <!--info-->
                <span v-if="index_Repo != -1" class="mif-info pl-4 mr-4 " data-role="popover"
                    data-cls-popover="text-small" data-popover-position="right" data-popover-hide="0"
                    data-popover-trigger="click" data-hide-on-leave="false" :data-popover-text="buildPopoverTemplate()" />
                <!--create-->
                <span class="mif-plus mr-4" @click="$root.$emit('OPENREPOSITORYDIALOG', type)"
                    :title="$t('Create new repository')" />
                <!--edit-->
                <span v-if="index_Repo != -1" class="mif-pencil mr-4"
                    @click="$root.$emit('OPENREPOSITORYDIALOG', repositories[index_Repo])"
                    :title="$t('Edit service account')" />
                <span class="mif-loop2" @click="reloadData" />
            </a>
        </div>

        <!--SERVICE ACCOUNTS-->
        <div v-if="!selectRepo && serviceAccounts != null && serviceAccounts.length > 0" class="row m-0">
            <select data-role="select" data-filter="false" :model="idSA" @change="onChange" class="w-50">
                <option :value="-1"></option>
                <option v-for="(sa, index) in serviceAccounts" :class="showSA(index) ? '' : 'd-none'"
                    class="p-0" :key="sa.id" :value="index" :selected="index_SA == index">
                    {{ sa.name }}
                </option>
            </select>

            <div class="p-2" v-if="wait">
                <span class="mif-spinner2 fg-primary ani-spin ml-2" />
            </div>

            <a v-else  @click="test" class="button primary ml-2" :disabled="index_SA == -1 || testState == SA_TESTED" :title="$t('Connect')" ><svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21.707 3.707a1 1 0 0 0-1.414-1.414L18.496 4.09a4.252 4.252 0 0 0-5.251.604l-1.068 1.069a1.75 1.75 0 0 0 0 2.474l3.585 3.586a1.75 1.75 0 0 0 2.475 0l1.068-1.068a4.252 4.252 0 0 0 .605-5.25l1.797-1.798ZM10.707 11.707a1 1 0 0 0-1.414-1.414l-1.47 1.47-.293-.293a.75.75 0 0 0-1.06 0l-1.775 1.775a4.252 4.252 0 0 0-.605 5.25l-1.797 1.798a1 1 0 1 0 1.414 1.414l1.798-1.797a4.252 4.252 0 0 0 5.25-.605l1.775-1.775a.75.75 0 0 0 0-1.06l-.293-.293 1.47-1.47a1 1 0 0 0-1.414-1.414l-1.47 1.47-1.586-1.586 1.47-1.47Z" fill="#fff"/></svg></a>
            <a class="button ml-2">
                <span v-if="index_SA != -1" class="mif-info mif-2x " data-role="popover" data-popover-trigger="click"
                data-cls-popover="text-small" data-popover-position="right" data-popover-hide="0"
                data-hide-on-leave="false" :data-popover-text="buildPopoverTemplate()" />
                <span class="mif-plus" @click="$root.$emit('OPENSERVICEACCOUNTDIALOG', types[0])"
                :title="$t('Create new service account')" />
                <span v-if="index_SA != -1" class="mif-pencil"
                    @click="$root.$emit('OPENSERVICEACCOUNTDIALOG', serviceAccounts[index_SA])"
                    :title="$t('Edit service account')" />
                <span class="mif-loop2" @click="reloadData" />
            </a>
           
        </div>

        <div v-else-if="selectRepo && repositories != null && repositories.length > 0" class="row m-0">

            <select data-role="select" data-filter="false" :model="index_Repo" @change="onChange" class="w-50">
                <option v-for="(repo, index) in repositories" :key="repo.id" :class="type == repo.type ? '' : 'd-none'"
                    class="p-0 d-flex flex-align-center va-middle" :value="index" :selected="index_Repo == index">
                    {{ repo.name }}
                    <span class="p-0 mif-info float-right" />
                </option>
            </select>
            <input type="button" @click="test" :value="$t('Test repository')" class="button primary ml-4"
                :disabled="idSA == null" :title="$t('Test the service account associated with the repository')" />

        </div>
    </div>
</template>

<script>
import { DefaultObjectBuilder } from '../../models/DefaulfObjectBuilder';
import { isValid } from '../../../public/assets/js/utilitiesmodule';
import { REPOSITORY_MESSAGES, SERVICEACCOUNT_MESSAGES } from '../../../public/assets/js/messages';
//import SARepoView from '../dialogs/view/SARepoView.vue';

export default {
    name: "SARepoSelect",
    props: {
        type: [Number, Array],
        idSA: [Number, String],
        idRepo: [Number, String]
    },
    components: {
        //SARepoView
    },
    data() {
        return {
            types: [],
            serviceAccounts: null,
            repositories: null,
            selectRepo: false,
            index_SA: -1,
            index_Repo: -1,
            wait: false,
            testReady : true,
            SA_NOTSELECTED : -1,
            SA_SELECTED_NOTTESTED : 0,
            SA_TESTED : 1,

            testState : -1
        }
    },
    mounted() {

        if (typeof this.type == 'number')
            this.types = [this.type];
        else
            this.types = this.type;

        this.serviceAccounts = this.$session.get('SERVICEACCOUNTS');//.filter(s => this.types.includes(s.type));
        this.repositories = this.$session.get('REPOSITORIES');//.filter(r => this.types.includes(r.type));
        this.selectRepo = isValid(this.idRepo);

        if (this.selectRepo)
            this.selectRepoBy(null, this.idRepo);
        else
            this.selectSABy(null, this.idSA);
    },

    methods: {
        showSA(index){
            //Mostro tutti i serviceaccount 365 se il type passato è 365
            return (this.$root.isMicrosoft365(this.types[0]) && this.$root.isMicrosoft365(this.serviceAccounts[index].type)) ||
            this.types.includes(this.serviceAccounts[index].type);
        },
        selectSABy(index, id) {
            try {

                if(!isValid(index) && id == -2){
                    this.index_SA = -1;
                    this.index_Repo = -1;
                    this.testState = this.SA_NOTSELECTED;
                    return;
                }

                if (isValid(index)) {
                    this.index_SA = index;
                    this.testState = this.SA_SELECTED_NOTTESTED;
                    return;
                }

                if (isValid(id) && id != -2) {
                    this.index_SA = this.serviceAccounts.findIndex(sa => sa.id == id);
                    if (this.index_SA != -1){
                        this.testState = this.SA_SELECTED_NOTTESTED;
                        return;
                    }
                }
                
                this.index_SA = -1;
                this.index_Repo = -1;
                this.testState = this.SA_NOTSELECTED;
                this.$root.toast(this.$t(SERVICEACCOUNT_MESSAGES.NOT_RETRIEVED), 5000, "warning");

            } catch (e) {
                console.log(e);
            }
        },

        selectRepoBy(index, id) {
            if (isValid(index)) {
                this.index_Repo = index;
                this.selectSABy(null, this.repositories[index].id_service_account);
                return;
            }

            if (isValid(id) && id != -2) {
                this.index_Repo = this.repositories.findIndex(repo => repo.id == id);
                if (this.index_Repo != -1) {
                    this.selectSABy(null, this.repositories[this.index_Repo].id_service_account);
                    return;
                }
            }
            this.index_SA = -1;
            this.index_Repo = -1;
            this.$root.toast(this.$t(REPOSITORY_MESSAGES.NOT_RETRIEVED), 5000, "warning");
            return;
        },

        onChange(event) {
            if (!isValid(event.detail) || !isValid(event.detail.selected))
                return;

            if (this.selectRepo)
                this.selectRepoBy(parseInt(event.detail.selected[0].toString()), null);
            else
                this.selectSABy(parseInt(event.detail.selected[0].toString()), null);

        },

        buildPopoverTemplate() {
            var objBuilder = new DefaultObjectBuilder();
            var sa_html = "",
                repo_html = "";

            // Funzione per filtrare e rimuovere l'ID dalle proprietà
            function filterProps(props) {
                    return props.filter(prop => prop.title !== 'ID');
                }                

            // Step 1: Se passato creo la tabella che rappresenta il service account
            if (this.index_SA != -1) {
                var props = filterProps(objBuilder.fromObjectToPropertiesList(this.serviceAccounts[this.index_SA]));

                props.forEach(x => {
                    sa_html += "<div class='d-flex flex-justify-between mb-2'>";
                    sa_html += "<div class='pr-4'>" + this.$t(x.title) + ":</div>";
                    sa_html += "<div class='text-bold' style='white-space: nowrap;'>" + this.$t(x.value) + "</div>";
                    sa_html += "</div>";
                });

                if (isValid(this.serviceAccounts[this.index_SA].options)) {
                    props = filterProps(objBuilder.fromObjectToPropertiesList(this.serviceAccounts[this.index_SA].options));
                    props.forEach(x => {
                        sa_html += "<div class='d-flex flex-justify-between mb-2'>";
                        sa_html += "<div class='pr-4'>" + this.$t(x.title) + ":</div>";
                        sa_html += "<div class='text-bold' style='white-space: nowrap;'>" + this.$t(x.value) + "</div>";
                        sa_html += "</div>";
                    });
                }
            }

            // Step 2: Se passato, creo la tabella che rappresenta il repository
            if (this.index_Repo != -1) {
                props = filterProps(objBuilder.fromObjectToPropertiesList(this.repositories[this.index_Repo]));

                props.forEach(x => {
                    repo_html += "<div class='d-flex flex-justify-between mb-2'>";
                    repo_html += "<div class='pr-4'>" + this.$t(x.title) + ":</div>";
                    repo_html += "<div class='text-bold'>" + this.$t(x.value) + "</div>";
                    repo_html += "</div>";
                });

                // Step 1.1: Se è stato passato anche il repository, creo nella tabella una riga di divisione tra il repository e il service account
                if (this.index_SA == -1) {
                    repo_html += "<div class='d-flex flex-justify-between mb-2'>";
                    repo_html += "<div>"+this.$t('Service Account') + ":</div>";
                    repo_html += "<div class='text-bold'>" + this.$t("Not found") + "</div>";
                    repo_html += "</div>";
                } else {
                    repo_html += "<div class='d-flex flex-justify-between mb-2'>";
                    repo_html += "<div class='text-bold'>" + this.$t('Service Account') + ":</div>";
                    repo_html += "</div>";
                }
            }

            return "<div class='p-4'>" +
                "<h5 class='text-bold mb-4'>" + this.$t('Service Account Details') + "</h4>" +
                repo_html + sa_html +
                "</div>";
        },





        /*buildPopoverTemplate() {

            var objBuilder = new DefaultObjectBuilder();
            //Ogni opzione del select contiene
            //A sinistra il nome dell'oggetto

            var sa_html = "",
                repo_html = "";
            //A destra l'icona dell'occhio, la quale

            //apre una piccola finestra descrittiva dell'elemento stesso

            //Step 1 : Se passato creo la tabella che rappresenta il service account
            if (this.index_SA != -1) {
                var props = objBuilder.fromObjectToPropertiesList(this.serviceAccounts[this.index_SA]);


                props.forEach(x => {
                    sa_html += "<div class='row'>";
                    sa_html += "<div class='cell-auto no-wrap' >" + this.$t(x.title) + "</div>";

                    if (['Yes', 'No'].includes(x.value))
                        sa_html += "<div class='cell-auto no-wrap text-bold text-small'>" + this.$t(x.value) + "</div>";
                    else if (x.title == 'Path' && x.value.includes('/'))
                        sa_html += "<div class='cell-auto  no-wrap text-bold text-small' title='" + x.value + "'>" + x.value.split("/").at(-1) + "</div>";
                    else if (x.title == 'Path' && x.value.includes('\\'))
                        sa_html += "<div class='cell-auto no-wrap text-bold text-small' title='" + x.value + "'>" + x.value.split("\\").at(-1) + "</div>";
                    else
                        sa_html += "<div class='cell-auto no-wrap text-bold text-small' title='" + x.value + "'>" + x.value + "</div>"

                    sa_html += "</div>"
                });

                if (isValid(this.serviceAccounts[this.index_SA].options)) {
                    props = objBuilder.fromObjectToPropertiesList(this.serviceAccounts[this.index_SA].options);
                    props.forEach(x => {
                        sa_html += "<div class='row'>";
                        sa_html += "<div class='cell-auto no-wrap'>" + this.$t(x.title) + "</div>";

                        if (['Yes', 'No'].includes(x.value))
                            sa_html += "<div class='cell-auto no-wrap text-bold text-small' >" + this.$t(x.value) + "</div>";
                        else if (x.title == 'Path' && x.value.includes('/'))
                            sa_html += "<div class='cell-auto no-wrap text-bold text-small' title='" + x.value + "'>" + x.value.split("/").at(-1) + "</div>";
                        else if (x.title == 'Path' && x.value.includes('\\'))
                            sa_html += "<div class='cell-auto no-wrap text-bold text-small' title='" + x.value + "'>" + x.value.split("\\").at(-1) + "</div>";
                        else
                            sa_html += "<div class='cell-auto no-wrap text-bold text-small' title='" + x.value + "'>" + x.value + "</div>"

                        sa_html += "</div>"
                    });
                }

                sa_html += "</div>"
            }


            //Step 2 : Se passato, creo la tabella che rappresenta il repository
            if (this.index_Repo != -1) {

                props = objBuilder.fromObjectToPropertiesList(this.repositories[this.index_Repo]);

                props.forEach(x => {
                    repo_html += "<div class='row'>";
                    repo_html += "<div class='cell-auto no-wrap '>" + this.$t(x.title) + "</div>";

                    if (['Yes', 'No'].includes(x.value))
                        repo_html += "<div class='cell-auto no-wrap text-bold text-small' >" + this.$t(x.value) + "</div>";
                    else if (x.title == 'Path' && x.value.includes('/'))
                        repo_html += "<div class='cell-auto no-wrap text-bold text-small' title='" + x.value + "'>" + x.value.split("/").at(-1) + "</div>";
                    else if (x.title == 'Path' && x.value.includes('\\'))
                        repo_html += "<div class='cell-auto no-wrap text-bold text-small' title='" + x.value + "'>" + x.value.split("\\").at(-1) + "</div>";
                    else
                        repo_html += "<div class='cell-auto no-wrap text-bold text-small' title='" + x.value + "'>" + x.value + "</div>"

                    repo_html += "</div>"
                });

                //Step 1.1 Se è stato passato anche il repository, creo nella tabella una riga di divisione tra il repository e il service account
                if (this.index_SA == -1)
                    repo_html += "<div><div class='cell-auto  no-wrap'>Service account</div><div class='cell-8'>" + this.$t("Not found") + "</div>";
                else
                    repo_html += "<div><div class='cell-auto no-wrap '>Service account</div></div>";
            }
            return "<div class=''>" + repo_html + sa_html + "</div>";



        },*/

        reloadData() {
            //var fakeServiceAccount = { id: -1, name: this.$t("Create custom service account"), type: this.types[0] };
            this.serviceAccounts.splice(1, this.serviceAccounts.length - 1);
            this.$session.get('SERVICEACCOUNTS').filter(s => this.types.includes(s.type)).forEach(sa => {
                //if (this.serviceAccounts.findIndex(s => sa.id == s.id) == -1)
                this.serviceAccounts.push(sa);
            })
            //this.serviceAccounts = [fakeServiceAccount].concat(this.$session.get('SERVICEACCOUNTS').filter(s => this.types.includes(s.type)));

            //this.repositories = [fakeRepository].concat(this.$session.get('REPOSITORIES').filter(r => this.types.includes(r.type)));
            this.$forceUpdate();
        },
        
        async test() {
            /**
             * {
             * ok : bool,
             * okNumber : 1 se ok, 0 se !ok
             * serviceAccount:
             * client:
             * list:
             * }
             */
            this.wait = true;
            var testResult = await this.$root.testServiceAccount(this.serviceAccounts[this.index_SA]);
            this.wait = false;
            if (testResult.ok){
                this.testState = this.SA_TESTED;
                this.$root.formattedToast(this.$t(SERVICEACCOUNT_MESSAGES.TEST_OK), [this.serviceAccounts[this.index_SA].name], 3000, "success");
                this.$emit("sendSATestResult", testResult);
                return;
            }
            this.testState = this.SA_SELECTED_NOTTESTED;
            this.$root.formattedToast(this.$t(SERVICEACCOUNT_MESSAGES.TEST_FAILED), [this.serviceAccounts[this.index_SA].name], 5000, "alert");
        },

        isValid(value) {
            return isValid(value);
        }
    }
}

</script>