//Veronica : Ho raccolto alcune funzioni che vengono utilizzate in più componenti,
//in modo da non ripetere il codice e renderlo più pulito.

import { DATABASE_MESSAGES, DATACENTER_MESSAGES, DISK_MESSAGES, ERROR, MAILBOXES_MESSAGES, VIRTUAL_MACHINES_MESSAGES } from "./messages";

import { GoogleDriveAuthentication, GoogleDriveClient } from "../../../src/router/googledriveClient";
import { S3Client } from "../../../src/router/s3Client";
import { DropboxAuthentication, DropboxClient } from "../../../src/router/dropboxClient";
import { AzureClient } from "../../../src/router/azureClient";
import { AzureBlobClient, AzureFileClient } from "../../../src/router/azureblobfileClient";
import { GraphClient } from "../../../src/router/graphClient";

export const PlatformsTypesEnum = {
    DriveImage: 0,
    Cloud: 1,
    Cloud_GoogleDrive: 10,
    Cloud_S3CompatibleStorage: 110,
    Cloud_IperiusS3Storage: 111,
    Cloud_AmazonS3Storage: 112,
    Cloud_Dropbox: 12,
    Cloud_OneDrive: 13,
    Cloud_AzureBlob: 140,
    Cloud_AzureFile: 141,
    FTP: 2,
    HyperV: 3,
    ESXi: 4,
    Microsoft: 5,
    Microsoft_ExchangeOnPremises: 50,
    Microsoft_ExchangeOnPremisesEWS: 51,
    Microsoft_Exchange365: 52,
    Microsoft_SharePoint: 53,
    Microsoft_Teams: 54,
    Microsoft_OneDriveForBusiness: 55,
    Database: 6,
    Database_SQLServer: 60,
    Database_MySQL: 61,
    FileFolder: 8,
    Network: 81,
    Email: 7,
    Email_GMail: 70,
    Email_Outlook: 71,
    Email_Yahoo: 72,
    Email_ICloud: 73,
    Email_AoL: 74
}

export const platformsTypes = {
    0: {
        mainType: -1,
        name: "Drive Image",
        icon: "/assets/img/icons/driveimage.png",
        backupDestinationTypes: [PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: false,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        enabled: true
    },
    1: {
        mainType: -1,
        name: "Cloud",
        icon: "/assets/img/icons/cloud.png",
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    10: {
        mainType: 1,
        name: "Google Drive",
        icon: "/assets/img/icons/googledrive.png",
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    110: {
        mainType: 1,
        name: "S3 Storage",
        icon: "/assets/img/icons/s3compatiblestorage.png",
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    111: {
        mainType: 1,
        name: "Iperius S3 Storage",
        icon: "/assets/img/icons/iperiuss3storage.png",
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    112: {
        mainType: 1,
        name: "Amz S3 Storage",
        icon: "/assets/img/icons/amazons3storage.png",
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    12: {
        mainType: 1,
        name: "Dropbox",
        icon: "/assets/img/icons/dropbox.png",
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    13: {
        mainType: 1,
        name: "OneDrive",
        icon: "/assets/img/icons/onedrive.png",
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    140: {
        mainType: 1,
        name: "Azure Blob",
        icon: "/assets/img/icons/azureblob.png",
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    141: {
        mainType: 1,
        name: "Azure File",
        icon: "/assets/img/icons/azurefile.png",
        needSocketConnection: false, //gli elementi vengono recuperati tramite chiamate API
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    2: {
        mainType: -1,
        name: "FTP",
        icon: "/assets/img/icons/ftp-icon.png",
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: false,
        forBackupDestination: true,
        forRestoreFileDestination: false,
        enabled: true
    },
    3: {
        mainType: -1,
        name: "Hyper-V",
        icon: "/assets/img/icons/hyper-v.png",
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: false,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true

    },
    4: {
        mainType: -1,
        name: "ESXi",
        icon: "/assets/img/icons/esxi-logo.png",
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false //Nel primo rilascio di Enterprise, viene ignorato
    },
    5: {
        mainType: -1,
        name: "Microsoft",
        icon: "/assets/img/icons/microsoft.svg",
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    50: {
        mainType: 5,
        name: "Exch On Premises",
        icon: "/assets/img/icons/exchange.png",
        backupDestinationTypes: [PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false

    },
    51: {
        mainType: 5,
        name: "Exch.On Prem.(EWS)",
        icon: "/assets/img/icons/exchange.png",
        backupDestinationTypes: [PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false
    },
    52: {
        mainType: 5,
        name: "Exchange Online",
        icon: "/assets/img/icons/Microsoft Exchange.svg",
        backupDestinationTypes: [PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    53: {
        mainType: 5,
        name: "SharePoint",
        icon: "/assets/img/icons/ms-sharepoint.svg",
        backupDestinationTypes: [PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    54: {
        mainType: 5,
        name: "Microsoft Teams",
        icon: "/assets/img/icons/teams.svg",
        backupDestinationTypes: [PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    55: {
        mainType: 5,
        name: "OneDrive for business",
        icon: "/assets/img/icons/onedrive.svg",
        backupDestinationTypes: [PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    6: {
        mainType: -1,
        name: "Database",
        icon: "/assets/img/icons/database.png",
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        enabled: true
    },
    60: {
        mainType: 6,
        name: "SQL Server Database",
        icon: "/assets/img/icons/sqlserverdatabase.png",
        backupDestinationTypes: [PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    61: {
        mainType: 6,
        name: "MySQL Database",
        icon: "/assets/img/icons/mysqldatabase.png",
        backupDestinationTypes: [PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: false
    },
    7: {
        mainType: -1,
        name: "Email",
        icon: "/assets/img/icons/email-icon.png",
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    70: {
        mainType: 7,
        name: "Gmail",
        icon: "/assets/img/icons/gmail.png",
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    71: {
        mainType: 7,
        name: "Outlook",
        icon: "/assets/img/icons/outlook.png",
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true

    },
    72: {
        mainType: 7,
        name: "Yahoo",
        icon: "/assets/img/icons/yahoo.png",
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    73: {
        mainType: 7,
        name: "iCloud",
        icon: "/assets/img/icons/icloud.png",
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    74: {
        mainType: 7,
        name: "AOL",
        icon: "/assets/img/icons/aol.png",
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: false,
        forBackupSource: false,
        forBackupDestination: false,
        forRestoreFileDestination: false,
        enabled: true
    },
    8: {
        mainType: -1,
        name: "FileFolder",
        icon: "/assets/img/icons/file-folder.svg",
        backupDestinationTypes: [PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network, PlatformsTypesEnum.FTP, PlatformsTypesEnum.Cloud_AmazonS3Storage, PlatformsTypesEnum.Cloud_AzureBlob, PlatformsTypesEnum.Cloud_AzureFile, PlatformsTypesEnum.Cloud_Dropbox, PlatformsTypesEnum.Cloud_GoogleDrive, PlatformsTypesEnum.Cloud_IperiusS3Storage, PlatformsTypesEnum.Cloud_OneDrive, PlatformsTypesEnum.Cloud_S3CompatibleStorage],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: false,
        forRepository: false,
        forBackupSource: true,
        forBackupDestination: true,
        forRestoreFileDestination: true,
        enabled: true
    },
    81: {
        mainType: -1,
        name: "Network",
        icon: "/assets/img/icons/network.png",
        backupDestinationTypes: [PlatformsTypesEnum.FileFolder, PlatformsTypesEnum.Network, PlatformsTypesEnum.FTP, PlatformsTypesEnum.Cloud_AmazonS3Storage, PlatformsTypesEnum.Cloud_AzureBlob, PlatformsTypesEnum.Cloud_AzureFile, PlatformsTypesEnum.Cloud_Dropbox, PlatformsTypesEnum.Cloud_GoogleDrive, PlatformsTypesEnum.Cloud_IperiusS3Storage, PlatformsTypesEnum.Cloud_OneDrive, PlatformsTypesEnum.Cloud_S3CompatibleStorage],
        needSocketConnection: true, //gli elementi vengono recuperati dall'agent
        forServiceAccount: true,
        forRepository: true,
        forBackupSource: true,
        forBackupDestination: true,
        forRestoreFileDestination: true,
        enabled: true
    }
};

export const ResultValue = {
    Error: 0,
    OK: 1,
    SomeWarnings: 2,
    Running: 3,
    Aborted: 4,
    JobNotNeeded: 5,
    NeverExecuted: 99,
    TerminatedAbnormally: 6
}

export const ExchangeAccountType = {
    User: 0,
    Impersonation: 1,
    Group: 2,
    Shared: 3,
    Unknown: -1
}

export function prettyBytes(bytes, prec) { //prec = precision
    if (bytes == "0" || isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '0';
    if (typeof prec === 'undefined') prec = 1;
    var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
        number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(prec) + units[number];
}

//Questa è un'altra versione trovata di prettybytes
export function prettyBytes2(num) {
    // jacked from: https://github.com/sindresorhus/pretty-bytes
    if (typeof num !== 'number' || isNaN(num)) {
        throw new TypeError('Expected a number');
    }

    var exponent;
    var unit;
    var neg = num < 0;
    var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (neg) {
        num = -num;
    }

    if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
    }

    exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
    num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
    unit = units[exponent];

    return (neg ? '-' : '') + num + ' ' + unit;
}

export function mConvertToMB(bytes, prec) {
    if (bytes == "0" || isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '0 bytes';
    if (typeof prec === 'undefined') prec = 1;

    return parseFloat((bytes / 1024 / 1024).toFixed(prec).replace(",", "."));
}

export function formatDate(date, momentModule) {
    if (date == null || date == "") return ""
    date = normalizeDateForMomentLibrary(date);
    date = momentModule.utc(date).local().format("lll");
    return date;
}

export function fromNow(date, momentModule) {
    if (date == null || date == "") return ""
    date = normalizeDateForMomentLibrary(date);
    date = momentModule.utc(date).local().fromNow();
    return date;
}



export function diffDate(start, end, momentModule) {
    start = momentModule(normalizeDateForMomentLibrary(start));
    end = momentModule(normalizeDateForMomentLibrary(end));
    var diff = end.diff(start);
    return momentModule.utc(diff).format("HH:mm:ss");
}

function normalizeDateForMomentLibrary(date) {
    if (date.indexOf("Z") != -1 && date.indexOf("T") != -1)
        return date.replace("Z", ""); //2023-09-01T11:00:00Z
    if (date.indexOf("Z") == -1 && date.indexOf("T") == -1)
        return date.substr(0, 8) + "T" + date.substr(8); //20230901110000
    return date; //2023(-)09(-)01T11(:)00(:)00
}

//Nota : in Javascript la stringa vuota è uguale a false
export function isValid(value) {
    //return value != undefined && value != null && (typeof(value) != 'string' || (value != "" && value != 'undefined' && value != "null") || (typeof(value).toLowerCase() == 'object' && Object.keys(value).length == 0));
    return value != undefined && value != null && (typeof(value) != 'string' || value != "");
}

export function cutText(text, x) {

    var l = text.length;

    if (l <= x) return text;

    //if(x<=l && l<=1.5*x) return text.substr(0, x/2) + "..." + text.substr(l-x/2, l);
    return text.substr(0, x / 2) + "..." + text.substr(l - x / 2, l);
}

export async function waitSeconds(seconds) {
    await new Promise(resolve => { setTimeout(resolve, seconds * 1000) });
}

export function createErrorObject(error) {
    var obj = {};
    //STATUS
    obj.status = error.status;

    //MESSAGE
    if (isValid(error.message))
        obj.message = error.message;
    else if (isValid(error.data)) {
        if (typeof(error.data) == 'string')
            obj.message = error.data;
        else if (isValid(error.data.message)) {
            obj.message = error.data.message;
        } else if (isValid(error.data.error)) {
            if (isValid(error.data.error.message)) {
                obj.message = error.data.error.message;
            }
        }
    } else obj.message = ERROR;

    //DATA
    if (isValid(error.data) && typeof(error.data) == 'object') {
        if (isValid(error.data.error) && isValid(error.data.error.errors))
            obj.data = error.data.error.errors[0];
        else obj.data = error.data;
    } else obj.data = null;

    return obj;
}

export function syncronizeArray(type, oldArray, newArray) {
    var utils = getUtils(type);

    return _syncarray(oldArray, newArray, 0, null, utils);
}

function _syncarray(oldArray, newArray, level, parentObj, utils) {
    var comparators = utils.comparators;
    var nextArrayNames = utils.nextArrayNames

    var propForCompare = comparators[level];
    var childArray = nextArrayNames.length == 0 ? null : nextArrayNames[level];
    var result = {
        finalArray: [],
        messages: [] // array di oggetti {message : ..., args : []}
    };

    //Step 0 : Creo un unico array concatenando i due passati e li ordino in base al comparatore in posizione level. 
    //In caso di uguaglianza, dò precedenza agli oggetti old
    var array = newArray.map(d => { d.old = false; return d }).concat(oldArray.map(d => { d.old = true; return d }));
    array.sort((a, b) => {
        if (a[propForCompare] < b[propForCompare])
            return -1;
        if (a[propForCompare] > b[propForCompare])
            return 1;
        if (a.old)
            return -1;
        return 1;
    });

    var a, b;
    //Step 1 : Inizio a scorrere array, analizzando gli oggetti 2 alla volta
    for (var i = 0; i < array.length - 1; i++) {
        a = array[i];
        b = array[i + 1];
        console.log(a[propForCompare] + " " + b[propForCompare]);
        var childResult;
        //Caso 1 : Trovo lo stesso oggetto: analizzo lo stato del primo (quello old)
        if (a[propForCompare] == b[propForCompare]) {
            //Caso 1.1 : se a è stato selezionato, devo selezionare  
            if (a.selected) {
                // il corrispettivo b
                b.selected = true;
                // e tutti i figli di b, se esistono
                if (childArray) {
                    childResult = _syncarray(a[childArray], b[childArray], level + 1, a, utils);
                    b[childArray] = childResult.finalArray;
                    result.messages = result.messages.concat(childResult.messages);
                }
                //aggiungo il nuovo b all'array risultato
                result.finalArray.push(b);
                //procedo con l'analisi della prossima coppia
                i++; // ho trovato una coppia -> analizzo la prossima coppia
                continue;
            }
            //Caso 1.2 : se a è stato parzialmente selezionato
            if (a.indeterminate) {
                //Seleziono parzialmente b
                b.indeterminate = true;
                //Analizzo i figli di a e di b, se esistono
                if (childArray && a[childArray].length != 0) {
                    childResult = _syncarray(a[childArray], b[childArray], level + 1, a, utils);
                    b[childArray] = childResult.finalArray;
                    result.messages = result.messages.concat(childResult.messages);
                }
                //aggiungo il nuovo b all'array risultato
                result.finalArray.push(b);
                //procedo con l'analisi della prossima coppia
                i++; // ho trovato una coppia -> analizzo la prossima coppia
                continue;
            }

            //Caso 1.3 : se a non è stato selezionato
            //aggiungo il nuovo b all'array risultato, non selezionato per default
            result.finalArray.push(b);
            //procedo con l'analisi della prossima coppia
            i++; // ho trovato una coppia -> analizzo la prossima coppia
            continue;
        }

        //Caso 2 : Trovo 2 elementi differenti e a è old, a è stato cancellato (perché non si trova in b)
        if (a.old) {
            result.messages.push(createMessage(true, level, utils, a, parentObj));
            continue;
        }

        //Caso 3: Trovo due elementi differenti e a non è old, a è un nuovo elemento, quindi lo aggiungo nell'array risultato

        result.messages.push(createMessage(false, level, utils, a, parentObj));
        result.finalArray.push(a);
    }

    return result;
}

function createMessage(isCancelled, level, utils, currentObj, parentObj) {

    var type = utils.type;

    //DRIVE IMAGE LEVEL 0--------------------------------------------
    if (type == "0" && level == "0" && isCancelled)
        return {
            message: DISK_MESSAGES.NOT_FOUND,
            args: [currentObj.caption]
        };

    if (type == "0" && level == "0" && !isCancelled)
        return {
            message: DISK_MESSAGES.FOUND_NEW,
            args: [currentObj.caption]
        };

    //DRIVE IMAGE LEVEL 1-------------------------------------------
    if (type == "0" && level == "1" && isCancelled)
        return {
            message: DISK_MESSAGES.NOT_FOUND_PARTITION_,
            args: [currentObj.volumeDriveLetter, parentObj.caption]
        };

    if (type == "0" && level == "1" && !isCancelled)
        return {
            message: DISK_MESSAGES.FOUND_NEW_PARTITION,
            args: [currentObj.volumeDriveLetter, parentObj.caption]
        };

    //HYPER-V LEVEL 0--------------------------------------------
    if (type == "3" && level == "0" && isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.NOT_FOUND,
            args: [currentObj.name]
        };

    if (type == "3" && level == "0" && !isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.FOUND_NEW,
            args: [currentObj.name]
        };

    //HYPER-V LEVEL 1--------------------------------------------
    if (type == "3" && level == "1" && isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.NOT_FOUND_VIRTUAL_DISK,
            args: [currentObj.volumeDriveLetter, parentObj.caption]
        };

    if (type == "3" && level == "1" && !isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.FOUND_NEW_VIRTUAL_DISK,
            args: [currentObj.name, parentObj.name]
        };

    //ESXI LEVEL 0--------------------------------------------
    if (type == "4" && level == "0" && isCancelled)
        return {
            message: DATACENTER_MESSAGES.NOT_FOUND,
            args: [currentObj.name]
        };

    if (type == "4" && level == "0" && !isCancelled)
        return {
            message: DATACENTER_MESSAGES.FOUND_NEW,
            args: [currentObj.name]
        };

    //ESXI LEVEL 1--------------------------------------------
    if (type == "4" && level == "1" && isCancelled)
        return {
            message: DATACENTER_MESSAGES.NOT_FOUND_COMPUTER,
            args: [currentObj.name, parentObj.name]
        };

    if (type == "4" && level == "1" && !isCancelled)
        return {
            message: DATACENTER_MESSAGES.FOUND_NEW_COMPUTER,
            args: [currentObj.name, parentObj.name]
        };

    //ESXI LEVEL 2--------------------------------------------
    if (type == "4" && level == "2" && isCancelled)
        return {
            message: DATACENTER_MESSAGES.NOT_FOUND_DATASTORE,
            args: [currentObj.name, parentObj.name]
        };

    if (type == "4" && level == "2" && !isCancelled)
        return {
            message: DATACENTER_MESSAGES.FOUND_NEW_DATASTORE,
            args: [currentObj.name, parentObj.name]
        };

    //ESXI LEVEL 3--------------------------------------------
    if (type == "4" && level == "3" && isCancelled)
        return {
            message: DATACENTER_MESSAGES.NOT_FOUND_VIRTUAL_MACHINE,
            args: [currentObj.name, parentObj.name]
        };

    if (type == "4" && level == "3" && !isCancelled)
        return {
            message: DATACENTER_MESSAGES.FOUND_NEW_VIRTUAL_MACHINE,
            args: [currentObj.name, parentObj.name]
        };

    //ESXI LEVEL 4--------------------------------------------
    if (type == "4" && level == "4" && isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.NOT_FOUND_VIRTUAL_DISK,
            args: [currentObj.diskFilename, parentObj.name]
        };

    if (type == "4" && level == "4" && !isCancelled)
        return {
            message: VIRTUAL_MACHINES_MESSAGES.FOUND_NEW_VIRTUAL_DISK,
            args: [currentObj.diskFilename, parentObj.name]
        };
    //EXCHANGE LEVEL 0--------------------------------------------
    if (type.startsWith("5") && level == "0" && isCancelled)
        return {
            message: MAILBOXES_MESSAGES.NOT_FOUND,
            args: [currentObj.name]
        };

    if (type.startsWith("5") && level == "0" && !isCancelled)
        return {
            message: MAILBOXES_MESSAGES.FOUND_NEW,
            args: [currentObj.name]
        };

    //DATABASE LEVEL 0--------------------------------------------
    if (type.startsWith("6") && level == "0" && isCancelled)
        return {
            message: DATABASE_MESSAGES.NOT_FOUND,
            args: [currentObj.name]
        };

    if (type.startsWith("6") && level == "0" && !isCancelled)
        return {
            message: DATABASE_MESSAGES.FOUND_NEW,
            args: [currentObj.name]
        };
    return null;
}

function getUtils(type) {
    var comparators;
    var nextArrayNames;
    type = type.toString();

    switch (type) {
        case "0": // Drive Image -> source.driveImgSource è un array
            comparators = ["caption", "volumeDriveLetter"];
            nextArrayNames = ["partitions"]
            break;
        case "3": // Hyper-V -> source.hvSource è un array
            comparators = ["name", "name"];
            nextArrayNames = ["virtualHardDisks"];
            break;
        case "4": //ESXi -> source.esxiSource.datacenters è un array
            comparators = ["name", "name", "name", "name", "diskFilename"];
            nextArrayNames = ["computerResourceList", "datastores", "virtualMachines", "virtualDisks"];
            break;
        case "5":
        case "50":
        case "51":
        case "52": //Exchange Online (365) -> source.exchangeSource.mailboxes è un array
            comparators = ["name"];
            nextArrayNames = [];
            break;
        case "6":
        case "60":
        case "61": // Database -> source.dbSource.databases è un array
            comparators = ["name"];
            nextArrayNames = [];
            break;
    }

    return {
        "comparators": comparators,
        "nextArrayNames": nextArrayNames,
        "type": type
    };
}



export const cookieManager = {
    setCookie: function(key, value) {
        document.cookie = key + "=" + value + ";path=/";
    },

    getCookie: function(key) {

        var cookie = document.cookie.split(";").find(cookie => cookie.indexOf(key + "=") != -1);

        if (isValid(cookie))
            return cookie.split("=")[1];

        return null;
    },

    deleteCookie: function(key) {
        this.setCookie(key, "");
    },

    // Attendo per un massimo di 60 secondi che il cookie con il nome passato venga popolato.
    // Appena il cookie viene valorizzato ne ritorna il valore.
    // Se dopo 60 secondi il cookie non è stato ancora popolato ritorno null
    waitAndGetCookie: async function(cookieName) {

        var cookieValue;
        for (var i = 1; i <= 30; i++) {
            cookieValue = this.getCookie(cookieName);
            if (isValid(cookieValue)) {
                this.deleteCookie(cookieName);
                return cookieValue;
            }

            await waitSeconds(2);
        }

        return null;
    }

}

//args = // oggetto di dati extra passati
//ritorna un oggetto del tipo:
//{
//  ok:
//  okNumber:
//  serviceAccount
//  client:
//  list:
//}
export async function testServiceAccount(serviceAccount, args) {

    var feedback = {
        ok: false,
        okNumber: 0,
        serviceAccount: serviceAccount,
        client: null,
        list: null
    };

    switch (serviceAccount.type) {
        case 10:
            feedback = await _testGoogleDrive(serviceAccount, true);
            break;

        case 110:
            feedback = await _testS3Storage(serviceAccount);
            break;

        case 111:
            feedback = await _testIperiusStorage(serviceAccount);
            break;

        case 112:
            feedback = await _testAmazonS3(serviceAccount);
            break;

        case 12:
            feedback = await _testDropbox(serviceAccount, isValid(args) ? args.authCode : null, true);
            break;

        case 13:
            feedback = await _testOnedrive(serviceAccount, true);
            break;

        case 140:
            feedback = await _testAzureBlob(serviceAccount);
            break;

        case 141:
            feedback = await _testAzureFile(serviceAccount);
            break;

        case PlatformsTypesEnum.Microsoft_Exchange365:
        case PlatformsTypesEnum.Microsoft_SharePoint:
        case PlatformsTypesEnum.Microsoft_Teams:
        case PlatformsTypesEnum.Microsoft_OneDriveForBusiness:
            feedback = await _testMicrosoft365(serviceAccount);
            break;
    }

    return feedback;

}

async function _testGoogleDrive(serviceAccount, tryAgain) {

    //Caso 1 : Se è presente l'accessToken, provo ad utilizzarlo
    if (isValid(serviceAccount.token)) {
        var googledrive = new GoogleDriveClient(serviceAccount.token);
        //Le richieste possono restituire un oggetto di tip {status:400, url:...} se non è stato abilitato Google Drive API sulla console di Google
        //Le richieste possono restituire null in caso di errore, ad esempio di token scaduto
        var response = await googledrive.getRootNodes();

        //Se il token non è valido, lo svuoto è riprovo
        if (response == null && tryAgain) {
            serviceAccount.token = "";
            return _testGoogleDrive(serviceAccount, false);
        }

        if (response == null && !tryAgain) {
            serviceAccount.token = null;
            serviceAccount.refresh_token = null;
            return _testGoogleDrive(serviceAccount, false);

        }

        //Non è stato abilitato Google Drive API sulla console di Google
        if (isValid(response.url)) {
            return {
                ok: false,
                okNumber: 0,
                serviceAccount: null,
                client: null,
                url: response.url
            };
        }

        var folders = response;

        if (folders != null) {
            return {
                ok: true,
                okNumber: 1,
                serviceAccount: serviceAccount,
                client: googledrive
            }
        }

        if (!tryAgain)
            return {
                ok: false,
                okNumber: 0,
                serviceAccount: null,
                client: null
            };

    }

    var auth = new GoogleDriveAuthentication(serviceAccount.client_id, serviceAccount.client_secret);

    //Caso 2 : Se è presente il refreshToken, provo ad utilizzarlo per richiedere il nuovo accessToken
    if (isValid(serviceAccount.refresh_token)) {
        serviceAccount.token = await auth.getAccessTokenWithRefreshToken(serviceAccount.refresh_token);

        if (serviceAccount.token != null)
            return _testGoogleDrive(serviceAccount, false);

    }

    //Caso 3 : I token non sono validi, richiedo l'authorization code
    var authCode = await auth.getAuthCode();

    // Se la richiesta fallisce, fallisce il test
    if (authCode == null) {
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: null,
            client: null
        };
    }

    // Richiedo i nuovi token con l'authorization code
    var tokens = await auth.getTokensWithAuthorizationCode(authCode);

    console.log(tokens);

    // Se la richiesta fallisce, fallisce il test
    if (tokens == null) {
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: null,
            client: null
        }
    }

    serviceAccount.token = tokens.accessToken;
    serviceAccount.refresh_token = tokens.refreshToken;

    return _testGoogleDrive(serviceAccount, false);

}

async function _testAmazonS3(serviceAccount) {
    var s3 = new S3Client(serviceAccount);

    var rootResources = await s3.getRootNodes();
    if (rootResources == null)
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: serviceAccount,
            client: null
        };

    return {
        ok: true,
        okNumber: 1,
        serviceAccount: serviceAccount,
        client: s3
    };
}

async function _testDropbox(serviceAccount, authCode, tryAgain) {

    //Caso 1 : Se è presente l'accessToken, provo ad utilizzarlo
    if (isValid(serviceAccount.token)) {
        var db = new DropboxClient(serviceAccount.token);
        var rootResources = await db.getRootNodes();

        if (rootResources != null)
            return {
                ok: true,
                okNumber: 1,
                serviceAccount: serviceAccount,
                client: db
            };

        if (!tryAgain)
            return {
                ok: false,
                okNumber: 0,
                serviceAccount: null,
                client: null
            };
    }

    var auth = new DropboxAuthentication(serviceAccount.client_id, serviceAccount.client_secret);

    //Caso 2 : Se è presente il refreshToken, lo utilizza per richiedere il nuovo token
    if (isValid(serviceAccount.refresh_token)) {
        serviceAccount.token = await auth.getAccessTokenWithRefreshToken(serviceAccount.refresh_token);
        if (isValid(serviceAccount.token))
            return _testDropbox(serviceAccount, null, false);
    }

    //Caso 3 : Se è stato passato l'authorizationCode, lo utilizzo per richiedere i nuovi token
    if (isValid(authCode)) {

        var tokens = await auth.getTokensWithAuthCode(authCode);
        //Se la richiesta fallisce, il test fallisce
        if (tokens == null)
            return {
                ok: false,
                okNumber: 0,
                serviceAccount: null,
                client: null
            };

        serviceAccount.token = tokens.accessToken;
        serviceAccount.refresh_token = tokens.refreshToken;

        return _testDropbox(serviceAccount, null, false);
    }

    //Caso 4 : Se i token non sono validi e non è stato passato l'authorization code, devo richiedere quest'ultimo,
    // che verrà inserito dall'utente nello step successivo
    auth.getAuthenticationCode();

    return {
        ok: false,
        okNumber: 0,
        serviceAccount: null,
        client: null
    };
}

async function _testOnedrive(serviceAccount, tryAgain) {
    var user;
    var drive;
    var response = {
        ok: false,
        okNumber: 0,
        serviceAccount: serviceAccount,
        client: null,
        list: null
    };

    //Caso 1 : Se è presente l'accessToken, provo ad effettuare una richiesta 
    if (isValid(serviceAccount.token)) {
        response.client = new GraphClient(serviceAccount);
        user = await response.client.getUserByEmail(serviceAccount.username);
        response.ok = isValid(user);
        response.okNumber = response.ok ? 1 : 0;
    }

    //Caso 2 : provo a recuperare il drive dell'utente
    if (isValid(serviceAccount.token) && response.ok) {
        drive = await response.client.getDriveByUserID(user.resID);
        response.ok = drive != null;
    }

    //Caso 3 : provo ad effettuare una richiesta al drive dell'utente
    if (isValid(serviceAccount.token) && response.ok) {
        response.serviceAccount.options.userID = user.resID;
        response.serviceAccount.options.driveID = drive.id;
        response.ok = (await response.client.getOneDriveRootNodes(user.resID, drive.id)) != null;
    }

    //Caso 1.1 : Se la richiesta va a buon fine, il test si è concluso con successo
    if (isValid(response.serviceAccount.token) && response.ok)
        return response;

    //Caso 1.2 : Se la richiesta non va a buon fine e non si deve effettuare un altro tentativo,
    // il test è fallito
    if (isValid(response.serviceAccount.token) && !response.ok && !tryAgain)
        return response;

    //Caso 1.2 : Se la richiesta non va a buon fine e si può effettuare un altro tentativo,
    // resetto l'accessToken e provo altri metodi di autenticazione
    if (isValid(response.serviceAccount.token) && !response.ok && tryAgain) {
        response.serviceAccount.token = null;
        return _testOnedrive(response.serviceAccount, false);
    }



    //Caso 2 : Se non è presente l'access token, richiedo i token e riprovo
    //All'interno di azureClient.getTokens(), verranno gestiti tutti i casi di autenticazione
    //e verranno modificati i campi del service account passato
    var azureClient = new AzureClient(serviceAccount);
    var okAuth = await azureClient.getTokens();

    //Caso 2.1 : Se la richiesta va a buon fine, memorizzo le informazioni nel service account e riprovo
    if (okAuth) {
        return _testOnedrive(azureClient.serviceAccount, false);
    }

    //Caso 2.2 : Se la richiesta non va a buon fine, il test è fallito
    return response;
}

async function _testAzureBlob(serviceAccount) {
    var azureblob = new AzureBlobClient(serviceAccount.client_id, serviceAccount.client_secret);
    var rootResources = await azureblob.getRootNodes();
    if (rootResources == null)
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: false,
            client: null
        };

    return {
        ok: true,
        okNumber: 1,
        serviceAccount: serviceAccount,
        client: azureblob
    }
}

async function _testAzureFile(serviceAccount) {
    var azurefile = new AzureFileClient(serviceAccount.client_id, serviceAccount.client_secret);
    var rootResources = await azurefile.getRootNodes();
    if (rootResources == null)
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: false,
            client: null
        };

    return {
        ok: true,
        okNumber: 1,
        serviceAccount: serviceAccount,
        client: azurefile
    }
}

async function _testIperiusStorage(serviceAccount) {
    var s3 = new S3Client(serviceAccount);
    var rootResources = await s3.getRootNodes();
    if (rootResources == null)
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: serviceAccount,
            client: null
        };

    return {
        ok: true,
        okNumber: 1,
        serviceAccount: serviceAccount,
        client: s3
    };
}

async function _testS3Storage(serviceAccount) {
    var s3 = new S3Client(serviceAccount);
    var rootResources = await s3.getRootNodes();
    if (rootResources == null)
        return {
            ok: false,
            okNumber: 0,
            serviceAccount: serviceAccount,
            client: null
        };

    return {
        ok: true,
        okNumber: 1,
        serviceAccount: serviceAccount,
        client: s3
    };
}
//Nota : Exchange 365 non utilizza il refresh token come Onedrive
//Verrà utilizzata l'autenticazione con client ID e client secret
async function _testMicrosoft365(serviceAccount) {

    var ok = await new AzureClient(serviceAccount).getTokens();
    //Caso 1.1 : Se la richiesta va a buon fine, il test si è concluso con successo
    if (ok)
        return {
            ok: true,
            okNumber: 1,
            serviceAccount: serviceAccount,
            client: new GraphClient(serviceAccount),
            list: null
        };

    //Caso 1.2 : Se la richiesta non va a buon fine e non si deve effettuare un altro tentativo,
    // il test è fallito
    return {
        ok: false,
        okNumber: 0,
        serviceAccount: null,
        client: null,
        list: null
    }
}


//ATTENZIONE : Sono mappati solamente i cloud, ftp, network e exchange 365
//args : oggetto di dati extra passati
export function getClientByTestedServiceAccount(serviceAccount) {
    switch (serviceAccount.type) {
        case 10:
            return new GoogleDriveClient(serviceAccount.token);
        case 110:
            return new S3Client(serviceAccount);
        case 111:
            return new S3Client(serviceAccount);
        case 112:
            return new S3Client(serviceAccount);
        case 12:
            return new DropboxClient(serviceAccount.token);
        case 13:
        case 55:
            return new GraphClient(serviceAccount);
        case 140:
            return new AzureBlobClient(serviceAccount.client_id, serviceAccount.client_secret);
        case 141:
            return new AzureFileClient(serviceAccount.client_id, serviceAccount.client_secret);
        case 2:
        case PlatformsTypesEnum.Network:
            serviceAccount.options.accountSessionId = "";
            return {
                serviceAccount: serviceAccount
            }

        default:
            return null;
    }

}

export function getParentOf(path) {
    var slash = getSeparator(path);
    path = trimEnd(path, slash);

    return path.slice(0, path.lastIndexOf(slash) + 1);
}

export function getSeparator(path) {
    if (path.includes("/")) return "/";
    if (path.includes("\\")) return "\\";
    return "/";
}

export function trimEnd(str, endChars) {
    if (str.endsWith(endChars))
        return str.slice(0, -(endChars.length));
    return str;
}

export function GetFoldersNames(path) {
    var slash = getSeparator(path);

    if (!path.includes(slash))
        return [path];

    path = trimEnd(path, slash);

    return path.split(slash);
}

export function GetAllPaths(path) {
    var folders = GetFoldersNames(path);
    if (folders.length == 1)
        return folders;

    var paths = [];
    var slash = getSeparator(path);

    folders.forEach(f => {
        if (paths.length == 0)
            paths.push(f);
        else
            paths.push(paths.at(-1) + slash + f);
    })

    return paths;
}

export function applyBucketsRules(path) {
    var mainFolder = path.indexOf("/") == -1 ? path : path.split("/")[0];
    var subPath = path.indexOf("/") == -1 ? "" : path.substr(path.indexOf("/") + 1);

    mainFolder = mainFolder.toLowerCase();
    var ascii;
    for (var i = 0; i < mainFolder.length; i++) {
        ascii = mainFolder.charCodeAt(i);
        if (47 < ascii && ascii < 58) continue; // (0-9)
        if (96 < ascii && ascii < 123) continue; // (a-z)
        if (ascii == 46 || ascii == 45) continue; // 46  -> punto , 45 -> trattino
        mainFolder = mainFolder.replace(mainFolder.at(i), "-");
    }

    if (subPath == "") return mainFolder;
    return mainFolder + "/" + subPath;
}

//https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript
export function distinctBy(array, propertyName) {
    return [...new Map(array.map(item => [item[propertyName], item])).values()]
}

export function getNodeType(platformType, isFile, isRoot) {
    switch (platformType) {
        case 8:
        case PlatformsTypesEnum.Network:
            if (isFile)
                return FileFolderEntityType.FN_File;
            return FileFolderEntityType.FN_Folder;
        case 2:
            if (isFile)
                return FileFolderEntityType.FTP_File;
            return FileFolderEntityType.FTP_Folder;
        case 10:
        case 110:
        case 111:
        case 112:
        case 12:
        case 13:
        case 140:
        case 141:
            if (isRoot)
                return FileFolderEntityType.Cloud_Root;
            if (isFile)
                return FileFolderEntityType.Cloud_File;
            return FileFolderEntityType.Cloud_Folder;
        case 53:
            if (isRoot)
                return FileFolderEntityType.SP_Root;
            if (isFile)
                return FileFolderEntityType.SP_File;
            return FileFolderEntityType.SP_Folder;
        default:
            if (isFile)
                return FileFolderEntityType.Cloud_File;
            return FileFolderEntityType.Cloud_Folder;
    }
}

export function getRootType(platformType) {
    if ([8, PlatformsTypesEnum.Network].includes(platformType))
        return FileFolderEntityType.FN_Folder;
    if (platformType == 2)
        return FileFolderEntityType.FTP_Folder;
    if (platformType == 53)
        return FileFolderEntityType.SP_Root;
    if (platformType == 54)
        return FileFolderEntityType.MT_Root;
    return FileFolderEntityType.Cloud_Root;
}

export function getMetroIcon(type, path) {
    if (isValid(path)) {
        var slash = getSeparator(path);

        //Caso 1 : questo nodo corrisponde ad un drive di filefolder (C:\, L:\) ecc...
        if (slash == "\\" && path.length == 3)
            return "mif-drive2";
    }
    switch (type) {
        case FileFolderEntityType.FN_File:
            return "mif-file-empty";
        case FileFolderEntityType.Cloud_File: //CLOUD_FILE
            return "mif-file-empty";
        case FileFolderEntityType.SP_File: //SP_FILE
            return "mif-file-empty";
        case FileFolderEntityType.FTP_File: //FTP_FILE
            return "mif-file-empty";
        case FileFolderEntityType.FN_Folder: //FN_FOLDER
            return 'mif-folder';
        case FileFolderEntityType.Cloud_Folder: //CLOUD_FOLDER
            return 'mif-folder';
        case FileFolderEntityType.SP_Folder: //SP_FOLDER
            return 'mif-folder';
        case FileFolderEntityType.FTP_Folder: //FTP_FOLDER
            return 'mif-folder';
        case FileFolderEntityType.Cloud_Root: //cloud root
            return 'mif-cloud';
        case FileFolderEntityType.SP_Root: // sp_root
            return 'mif-cloud';
        case FileFolderEntityType.Cloud_Bucket: //cloud bucket
            return 'mif-drive2';
        case FileFolderEntityType.SP_Site: //sp_site
            return 'mif-earth';
        case FileFolderEntityType.SP_Library: //sp_library
            return 'mif-books';
        case FileFolderEntityType.MT_Team:
            return 'mif-users';
        case FileFolderEntityType.MT_Channel:
            return 'mif-chat-bubble-outline';
        case FileFolderEntityType.Account:
            if (!path.includes("?"))
                return 'mif-user';
            var exchangeAccountType = parseInt(path.split("?")[1]);
            if (exchangeAccountType == ExchangeAccountType.User)
                return 'mif-user';
            if (exchangeAccountType == ExchangeAccountType.Shared)
                return 'mif-share-circle';
            return 'mif-users';
        case FileFolderEntityType.MT_Root:
            return 'mif-earth';
        default:
            return 'mif-question';
    }

}

export function generateGuid() {
    // Concatena i numeri casuali per formare il GUID
    return (
        randomHex() + randomHex() + '-' + randomHex() + '-' + randomHex() + '-' +
        randomHex() + '-' + randomHex() + randomHex() + randomHex()
    );
}

// Genera un numero casuale a 32 bit in esadecimale
function randomHex() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export function assignProperties(from, to) {
    for (let key in from) {
        if (from.hasOwnProperty(key)) {
            if (Array.isArray(from[key])) {
                if (!Array.isArray(to[key])) {
                    to[key] = [];
                }
                to[key].length = 0; // Clear the existing array
                from[key].forEach((item, index) => {
                    to[key][index] = item;
                });
            } else if (typeof from[key] === 'object' && from[key] !== null) {
                if (typeof to[key] !== 'object' || to[key] === null) {
                    to[key] = {};
                }
                assignProperties(from[key], to[key]);
            } else {
                to[key] = from[key];
            }
        }
    }
    return to;
}

//...\iperius-enterprise-agent\IperiusEWP\BackupSources\FileFolder\Core\Utils\Enums.cs
export const FileFolderEntityType = {
    FN_File: 0,
    FN_Folder: 1,

    Cloud_Root: 2,
    Cloud_Bucket: 3,
    Cloud_File: 4,
    Cloud_Folder: 5,

    SP_Root: 6,
    SP_File: 7,
    SP_Folder: 8,
    SP_Site: 9,
    SP_Library: 10,

    MT_Team: 11,
    MT_Channel: 12,
    MT_PostSection: 13,
    MT_UserChat: 14,

    EX_Note: 15,
    EX_Appointment: 16,
    EX_Contact: 17,
    EX_Task: 18,
    EX_OtherItem: 19,

    FTP_File: 20,
    FTP_Folder: 21,
    Account: 22,

    MT_Root: 23
}