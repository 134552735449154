import { render, staticRenderFns } from "./Computer.vue?vue&type=template&id=9232cc6a"
import script from "../services/Computer.js?vue&type=script&lang=js&external"
export * from "../services/Computer.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\nodeworkspace\\IPERIUS\\Iperius\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9232cc6a')) {
      api.createRecord('9232cc6a', component.options)
    } else {
      api.reload('9232cc6a', component.options)
    }
    module.hot.accept("./Computer.vue?vue&type=template&id=9232cc6a", function () {
      api.rerender('9232cc6a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Computer.vue"
export default component.exports