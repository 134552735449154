var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 overflow" },
    [
      _c("Navigation", { attrs: { pageName: "users" } }),
      _vm.filteredusers == null
        ? _c("div", { staticClass: "d-flex flex-justify-center p-10" }, [
            _c("span", {
              staticClass: "mif-spinner2 fg-primary ani-spin mif-3x",
            }),
          ])
        : _vm.filteredusers.length == 0
        ? _c("NoDataAvailable", {
            attrs: { message: _vm.$t("No users available") },
          })
        : _c("div", { staticClass: "p-10" }, [
            _c(
              "table",
              {
                staticClass: "table border striped bg-white m-0 compact",
                attrs: {
                  "data-check": false,
                  "data-check-name": "chkBox",
                  "data-table-search-title": _vm.$t("Search Users"),
                  "data-cls-search": "w-100-sm w-25-xl w-25-lg w-50-md",
                  "data-show-activity": "true",
                  "data-cell-wrapper": "false",
                  "data-role": "table",
                  "data-rows": "20",
                  "data-show-rows-steps": "false",
                  "data-show-pagination": "true",
                  "data-horizontal-scroll": "true",
                },
              },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "sortable-column" }, [
                      _vm._v(_vm._s(_vm.$t("Username"))),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("Role")))]),
                    _c("th", { staticClass: "sortable-column" }, [
                      _vm._v(_vm._s(_vm.$t("Lastname"))),
                    ]),
                    _c("th", { staticClass: "sortable-column" }, [
                      _vm._v(_vm._s(_vm.$t("Firstname"))),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        attrs: {
                          "data-cls": "text-center",
                          "data-cls-column": "text-center",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Groups")))]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        attrs: { "data-cls-column": "text-center w-10" },
                      },
                      [_vm._v(_vm._s(_vm.$t("2FA")))]
                    ),
                    _c("th", {
                      attrs: { "data-cls-column": "text-center w-10" },
                    }),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.users, function (user) {
                    return _c(
                      "tr",
                      { key: user.id, staticClass: "mx-8 p-4 rounded m-6" },
                      [
                        _c("td", [
                          _c(
                            "a",
                            {
                              staticClass:
                                "button flat-button text-bold text-left m-0",
                              attrs: { href: "#" + user.id },
                            },
                            [_vm._v(" " + _vm._s(user.username) + " ")]
                          ),
                        ]),
                        _c("td", {}, [
                          _c(
                            "label",
                            { staticClass: "text-bold text-left m-0" },
                            [_vm._v(_vm._s(user.role))]
                          ),
                        ]),
                        _c("td", [_vm._v(" " + _vm._s(user.lastname) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(user.firstname) + " ")]),
                        _c("td", { staticClass: "text-center text-bold" }, [
                          _c(
                            "button",
                            {
                              staticClass: "flat-button",
                              attrs: {
                                "data-role": "hint",
                                "data-hint-text": _vm.$root.createhintGroups(
                                  user.groups
                                ),
                              },
                            },
                            [_vm._v(_vm._s(user.groups.length))]
                          ),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("span", {
                            staticClass: "text-center mif-2x",
                            class: user.mfa_enabled ? "mif-security" : "",
                          }),
                        ]),
                        _c("td", [
                          _c("ul", { staticClass: "listview" }, [
                            _c("li", { staticClass: "mr-2" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "button bg-transparent",
                                  attrs: { href: "#" + user.id },
                                },
                                [_c("span", { staticClass: "mif-pencil" })]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "button mr-2 bg-transparent",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteUser(user)
                                    },
                                  },
                                },
                                [_c("span", { staticClass: "mif-bin" })]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }