<template>
    <div v-on:keyup.esc="$root.$emit('CLOSERESTOREDIALOG')" class="dialog w-100-sm w-100-md w-75-lg  w-50-xl" v-cloak>

        <DialogTitle :title="$t('Configure Hyper V Restore')" :defaultTitle="$t('Config Hyper V restore')" :subTitle="$t('Hyper V Backups')"
            @closeDialog="$root.$emit('CLOSERESTOREDIALOG')" />

        <div class="dialog-content p-4">
            <!-- SELEZIONE DEI BACKUP E DEL SINGOLO RESTORE RELATO AL JOB-->
            <div class="row" v-show="steps[0]">

                <div v-if="wait" data-role="activity" data-type="metro" data-style="dark"></div>
                <div id="hypervlogslist" class="pb-4 w-100" style="overflow: scroll;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small " v-if="!wait"
                        data-show-search="true" data-show-rows-steps="false" data-search-min-length="3"
                        data-search-threshold="300">

                        <thead>
                            <tr>
                                <td style="text-align: center;">Sel</td>
                                <td data-name="dt_start_utc" data-sortable="true" data-format="int">{{ $t('Start Date') }}</td>
                                <td data-name="dt_end_utc" data-sortable="true">{{ $t('End Date') }}</td>
                                <td data-name="backup_size" data-sortable="true">{{ $t('Backup Size') }}</td>
                                <td data-name="copied_files" data-sortable="true" data-sort-dir="desc">{{ $t('Copied files') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(forlog, index) in logs" :key="forlog.id" track-by="forlog.id">
                                <td>
                                    <input class="ml-5" name="r1" type='radio' data-role='radio'
                                        @click="getJobConfiguration(forlog, index)">
                                </td>
                                <td>{{ convertDate(forlog.dt_start_utc) }}</td>
                                <td>{{ convertDate(forlog.dt_end_utc) }}</td>
                                <td>{{ forlog.backup_size | prettyBytes }}</td>
                                <td>{{ forlog.copied_files }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
            <!-- SELEZIONE DELLA VM PER RESTORE-->
            <div class="row" v-show="steps[1]">
                <div v-if="waitvm" data-role="activity" data-type="metro" data-style="dark"></div>
                <div id="esxivmslist" class="pb-4 w-100" style="overflow: scroll;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small" v-if="!waitvm"
                        data-show-search="true" data-show-rows-steps="false" data-search-min-length="3"
                        data-search-threshold="300">

                        <thead>
                            <tr>
                                <!--th style="text-align: center;"><input class="ml-5" name="r3" type='checkbox'
                                                    data-role='checkbox'></th-->
                                <td></td>
                                <td data-name="name" data-sortable="true" data-format="int">{{ $t('VM Name') }}</td>
                                <td data-name="os" data-sortable="true">{{ $t('VM Version') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-show="vm.Selected" v-for="(vm) in virtualMachines" :key="vm.guid"  track-by="vm.name">
                                <td>
                                    <input class="ml-5" name="r2" type='radio' data-role='radio'
                                        @click="onRadioChange(vm)">
                                </td>
                                <td>{{ vm.Name }}</td>
                                <td>{{ vm.Version }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- SELEZIONE DELL'ACCOUNT PER RESTORE-->
            <div v-show="steps[2]" class="container-fluid">

                <div v-if="!virtualMachines" data-role="activity" data-type="metro" data-style="dark"></div>

                <div v-if="virtualMachines" class="row mt-4"><b>{{ $t('Select the destination of restore') }}:</b></div>

                <div class="row mt-4" v-if="virtualMachines">
                    <label>{{ $t('Virtual machine name (the name must not already exist)') }}:</label>
                </div>
                <div class="row" v-if="virtualMachines">
                    <input type="text" class="w-75" data-role="input" v-model="suggestedRestoredVmName" />
                </div>
                <div class="row mt-4" v-if="virtualMachines">
                    <input type="radio" data-role="radio" id="default" value="Default" v-model="picked"
                        :data-caption="$t('Copy the disk file to the defaullt folder')" />
                </div>
                <div class="row mt-0" v-if="virtualMachines">
                    <input type="radio" data-role="radio" id="custom" value="Custom" v-model="picked"
                        :data-caption="$t('Copy the disk file in the following folder')" />
                </div>
                <div class="row mt-1" v-if="virtualMachines">
                    <div class="cell-8">
                        <input type="text" v-if="customCopyFilePath.length == 0" data-role="input"
                            :class="{ 'disabled': picked != 'Custom' }">
                        <input type="text" v-else data-role="input" v-model="customCopyFilePath[0].path"
                            :class="{ 'disabled': picked != 'Custom' }">
                    </div>
                    <div class="cell-4">
                        <button class="button primary ml-2" :class="{ 'disabled': picked != 'Custom' }"
                            v-on:click="toggleFileSystemSidebar_CustomCopyPath()">{{ $t('Select path') }}</button>
                    </div>
                </div>
                <div class="row mt-4" v-if="virtualMachines">
                    <input type="checkbox" data-role="checkbox" id="newid" name="r1" data-style="1" v-model="newId"
                        :data-caption="$t('Generate new Id if the virtual machine already exists')">
                </div>
            </div>
            <div v-show="steps[3]" class="container-fluid">

                <div v-if="HyperVTestVmReport" class="row mt-4"><b>{{ $t('Virtual machine compatibility report') }}:</b>
                </div>

                <div v-if="!scanTest" data-role="activity" data-type="metro" data-style="dark"></div>

                <div class="row mt-3" v-if="!scanTest">
                    <label>{{ $t('Virtual Machine information') }}</label>
                </div>


                <div v-if="scanTest" id="scanTest" class="pb-4 w-100" style="overflow: scroll;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small " data-show-search="true"
                        data-show-rows-steps="false" data-search-min-length="3" data-search-threshold="300">

                        <thead>
                            <tr>
                                <td data-name="key" data-sortable="true" data-format="int">{{ $t('Key') }}</td>
                                <td data-name="value" data-sortable="true">{{ $t('Value') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, key) in scanTest" v-bind:key="key">
                                <td>{{ key }}</td>
                                <td>{{ value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row mt-3" v-if="!incompatIssue">
                    <label>{{ $t('Compatibility issue') }}</label>
                </div>


                <div v-if="incompatIssue" id="incompatIssue" class="pb-4 w-100" style="overflow: scroll;">

                    <table data-role="sorter" data-sort-dir="desc" class="table striped text-small " data-show-search="true"
                        data-show-rows-steps="false" data-search-min-length="3" data-search-threshold="300">

                        <thead>
                            <tr>
                                <td data-name="dt_start_utc" data-sortable="true" data-format="int">{{ $t('Issue id') }}
                                </td>
                                <td data-name="dt_end_utc" data-sortable="true">{{ $t('Issue description') }}</td>
                                <td data-name="backup_size" data-sortable="true">{{ $t('Solvability') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(is) in incompatIssue" :key="is.errorId" track-by="log.id">
                                <td>{{ is.errorId }}</td>
                                <td>{{ is.errorMessage }}</td>
                                <td>{{ is.isSolvable }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>


            <!--SUMMARY END BEFORE START RESTORE -->
            <div v-show="steps[4]" class="container-fluid">

                <div v-if="true" class="row mt-4 mb-2"><b>{{ $t('Summary') }}:</b></div>

                <div class="row mb-2">
                    <div v-if="true" class="cell-3">{{ $t('Server') }}</div>
                    <div v-if="HyperVTestVMCompatibilityModel" class="cell">{{ HyperVTestVMCompatibilityModel.HyperVHost
                    }}-{{ getPCOSFullVersion() }}</div>
                </div>

                <div class="row mb-2">
                    <div class="cell-3">{{ $t('Backup path') }}</div>
                    <div v-if="HyperVTestVMCompatibilityModel" class="cell">{{ HyperVTestVMCompatibilityModel.VmBackupFolder
                    }}</div>
                </div>

                <div class="row mb-2">
                    <div class="cell-3">{{ $t('Selected backup') }}</div>
                    <div class="cell">{{ convertDate(selBackupInfo.date) }} - {{ (selBackupInfo.number == 0 ? 'FULL' :
                        selBackupInfo.number.padStart(3, '0')) }} - {{ selBackupInfo.dimensione | prettyBytes }} - {{
        selBackupInfo.tipo }}</div>
                </div>
                <div class="row mb-2">
                    <div class="cell-3">{{ $t('Name of the target VM') }}</div>
                    <div class="cell">{{ suggestedRestoredVmName }}</div>
                </div>
                <div class="row mb-2">
                    <div class="cell-3">{{ $t('Compatibility report') }}</div>
                    <div class="cell" v-if="!canRestore">{{ $t('Not solvable issue') }}</div>
                    <div class="cell" v-else-if="incompatIssue.length > 0">{{ $t('Solvable issue') }}</div>
                    <div class="cell" v-else>{{ $t('Ok') }}</div>
                </div>


            </div>

            <div v-if="steps[5] && restore" class="container-fluid">

                <div class="row mt-4 ml-2">
                    <div class="cell-8">
                        <label>{{ restore.restorename }}</label>
                    </div>
                    <div class="cell-8">
                        <label v-if="restore.dt_start_utc != '' && restore.dt_start_utc != null">{{
                            restore.dt_start_utc.substr(0, 8) + "T" + restore.dt_start_utc.substr(8) |
                            utcAsLocal | moment("MM/DD/YY hh:mm A") }}</label>
                    </div>
                    <div class="cell-8">
                        <RestoreLastResult :lastresult="restore.lastresult" />
                    </div>
                    <div class="cell-6">
                        <div v-show="restore.progress != null && restore.progress != 0">
                            <div class="clear">
                                <small v-if="restore.current_operation.length <= 50" class="place-left" data-role="hint"
                                    data-hint-position="top" :data-hint-text="restore.current_operation">{{
                                        restore.current_operation
                                    }}</small>
                                <small v-else-if="restore.current_operation <= 100" class="place-left" data-role="hint"
                                    data-hint-position="top" :data-hint-text="restore.current_operation">{{
                                        restore.current_operation.substr(0, 50) + "..."
                                    }}</small>
                                <small v-else class="place-left" data-role="hint" data-hint-position="top"
                                    :data-hint-text="restore.current_operation">{{
                                        restore.current_operation.substr(0,
                                            50) + "..." + restore.current_operation.substr(-50, 50)
                                    }}</small>
                            </div>
                            <div data-role="progress" data-type="line" :data-value="restore.progress" data-small="true"
                                data-cls-bar="bg-cobalt" data-role-progress="true" class="progress small">
                                <div class="bar ribbed-cobalt" :style="{ width: restore.progress + '%' }">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="cell-2">
                        <small v-if="restore.isRunning" class="place-right text-bold enlarge-1">{{
                            restore.progress
                        }}%</small>
                    </div>
                </div>

            </div>


        </div>
        <div class="dialog-actions">
            <div class="ml-auto">
                <button class="button error float-right ml-2" @click="$root.$emit('CLOSERESTOREDIALOG')">{{ $t('Cancel') }}</button>
                <button class="button ml-2" :class="{ 'disabled': steps[0] }" @click="back"><span class="mif-arrow-left"></span>{{ $t('Prev') }}</button>
                <button class="button ml-2" :class="{ 'disabled': (steps[0] && this.jobselected == '') || (steps[1] && vmtorestore == '') || (steps[2] && suggestedRestoredVmName == '') || (steps[3] && !canRestore) || (steps[4] || steps[5]) }" @click="next">{{ $t('Next') }} <span class="mif-arrow-right"> </span> </button>
                <button class="button primary ml-2 float-right" v-show="steps[4]" @click="beginRestore"><span class="mif-checkmark pr-2"></span>{{ $t('Restore') }}</button>
            </div>
        </div>

    </div>
</template>
<script>
import DialogTitle from "../../utils/DialogTitle";
import { prettyBytes,formatDate,isValid } from '../../../../public/assets/js/utilitiesmodule';
import RestoreLastResult from "../../utils/RestoreLastResult";


export default {
    name: "HyperVDestinationRestore",
    props: {
        idPC: String,
        idJob: {
            type: String,
            required: true
        }
    },
    components: {
        "DialogTitle": DialogTitle,
        "RestoreLastResult": RestoreLastResult,
    },
    filters: {
        prettyBytes: function (bytes, precision) {
            return prettyBytes(bytes, precision);
        }
    },

    data() {
        return {
            steps: [1, 0, 0],

            virtualMachines: [],
            wait: true,
            waitvm: true,
            jobselected: '',
            checkedNames: [],
            id_service_account: -2,

            go: false,
            justOneGuid: '',

            restores: null, // socket [GT - 06/06/2023]
            restore: null,

            job: {},
            selectedlog: {},

            logs: [],
            vmtorestore: '',
            source: {},
            customCopyFilePath: [],
            picked: 'Default',
            newId: true,
            canRestore: false,

            HyperVRestoreModel: {},

            waitHVVMTest: true,
            // struttura dati da inviare per il test di compatibilità della Vm
            HyperVTestVMCompatibilityModel: {
                HyperVHost: '',
                Username: '',
                Password: '',
                VmBackupFolder: '',
                BackupType: '',
                CopyNumber: '',
                VmGuid: '',
                NetAccount: '',
                NetPwd: '',
                ckbNewVmId: '',
                LogFilePath: '',
                ReturnedError: '',
                result: 0,
                status: '',
            },
            HyperVTestVmReport: {},
            scanTest: null,
            incompatIssue: [],

            ESXiVmPowerState: [
                "OFF", "ON", "SUSPENDED"
            ],

            dataStoreList: null,
            dataStore: null,
            esxiselected: null,

            folder: null,

            connection: null,

            pc_isconnected: '',
            portchech: null,
            port: null,
            virtualMachineName: '',
            suggestedRestoredVmName: '',

            selBackupInfo: {
                date: '',
                number: '',
                dimensione: 0,
                tipo: ''
            }

        }
    },

    created: function () {
        this.getLogsList();
    },

    methods: {

        // primo metodo ad essere eseguito
        // mostra tutte le esecuzioni con relativa configurazione
        async getLogsList() {
            let self = this;

            //self.logs = await this.$root.api("GETBACKUPLOGS");
            await this.$root.api("GETBACKUPLOGS", self.idJob).then(response => {
                self.logs = response;
                self.wait = false;
            });

        },

        // mostra la configurazione completa del singolo backup esxi
        async getJobConfiguration(log) {

            let self = this;
            self.jobselected = ''; // svuoto la variabile che attiva pulsante next

            self.selectedlog = log;

            await this.$root.api("GETBACKUPLOGCONFIGURATION", log.id).then(response => {

                console.log('[HyperVDestinationRestore.getJobConfiguration] - api get backupconfigurations');
                console.log('[HyperVDestinationRestore.getJobConfiguration] - ' + response);

                self.job = {}

                if (!self.job) {
                    self.job = response;
                }
                else {
                    self.job = Object.assign(self.job, response)
                }

                if (self.job.options) {
                    self.options = self.job.options
                }

                //valorizza l'array con le virtual machine incluse nel singolo backup
                self.populateVMArray();

                self.jobselected = log.id; // attiva il pulsante next           

            });


        },
      
        setVmSelected(vm) {
            let self = this;
            self.virtualMachineName = vm.Name.replace(' ', '_') + '_restore_' + Date.now.format('hhmmsss');
            console.log('Name selected: ' + self.virtualMachineName);
        },

        onRadioChange(vm) {
            let self = this;

            self.selectedVm = vm;
            self.vmtorestore = vm.Name;
            vm.selectedRestore = true;

            console.log('[onRadioChange] --> vm name to restore: ' + vm.Name)
            const uniquestringdata = new Date().getDate() + '' + new Date().getHours() + '' + new Date().getMinutes() + '' + new Date().getSeconds();
            self.suggestedRestoredVmName = vm.Name + '_restored_' + uniquestringdata;

        },

        toggleFileSystemSidebar_CustomCopyPath() {

            var config = {
                type: this.$root.PlatformsTypesEnum.FileFolder, // sempre numero 8 per selezionare file/folder
                includeFiles: false,
                multipleChoice: false,
                client: null
            };

            this.$root.$emit(
                "toggleFileSystemSidebar",
                this.customCopyFilePath,
                config
            );
        },

        //valorizza l'array con le virtual machine incluse nel singolo backup
        populateVMArray() {
            let self = this;
            self.waitvm = true;
           // var hvS, cl, ds, vm; // , idsToClose = [];

            // svuoto array
            self.virtualMachines.splice(0);

            if (self.job.sources) {
                if (self.job.sources.length > 0) {
                    for (var i = 0; i < self.job.sources.length; i++) {
                        self.virtualMachines = self.virtualMachines.concat(self.job.sources[i].hvSource);
                    }
                }
            }

            setTimeout(function () {
                //var treeview = window.Metro.get$el("#esxiTreeview").data("treeview");
                //console.log(treeview)
                /*idsToClose.forEach(id => {
                    console.log(treeview);
                    console.log(treeview.toggleNode);
                    console.log(id);
                    console.log($("#" + id));

                    treeview.toggleNode($("#" + id)); // espande tutti i nodi 

                });*/
                self.waitvm = false;

            }, 1000);

        },

        
        convertDate(date) {
            date = this.$moment(date.substr(0, 8) + "T" + date.substr(8), "YYYYMMDD HH:mm:ss");
            return this.$moment.utc(date).format("ddd DD/MM/YYYY HH:mm:ss");
        },

        /*********************METODI***********************************************/
        //refreshFolder(serviceId, datastore) {
            //if (this.source.options.backupMode != 'REP') {
            //    this.dataStore.datastore.folders = null;
            //    this.connection.invoke("GetDatastoreFoldersESXi", serviceId, { "Datacenter": datastore.datacentername, "Datastore": datastore.datastore.name });

            //}
       // },

        /*********************************UTILS**************************************/
        setIdServiceAccount() {
            this.$root.$emit('setIdServiceAccount', this.id_service_account);
            if ((typeof this.id_service_account) == 'string')
                this.connection.invoke("GetVirtualMachinesESXi", this.id_service_account);
        },

        getPC() {
            if (this.$session.exists("PC")) {
                var lastPC = this.$session.get("PC");
                if (isValid(lastPC)) return lastPC;
            }
            return null;
        },

        getPCOSFullVersion() {

            var pc = this.getPC();
            if (pc) {
                return pc.os_version_full;
            }

            return '';

        },

        next() {
            var next = true;
            let self = this;

            // se è stata selezionata la macchina da ripristinare ne prendo il nome per mostrarlo nel campo di testo    
            if (self.virtualMachines != null && self.steps[1]) {
                for (var idc = 0; idc < self.virtualMachines.length; idc++) {
                    if (self.virtualMachines[idc].selected) {
                        const uniquestringdata = new Date().getHours() + '' + new Date().getMinutes() + '' + new Date().getSeconds() + '' + new Date().getMilliseconds()
                        self.virtualMachineName = self.virtualMachines[idc].name.replace(' ', '_') + '_restore_' + uniquestringdata;
                    }
                }
            }

            //steps[2] = quando seleziona la macchia virtuale da ripristinare
            if (self.steps[2] && self.job && self.suggestedRestoredVmName != '') {
                self.HyperVTestVMCompatibilityModel.HyperVHost = 'localhost';
                self.HyperVTestVMCompatibilityModel.Username = '';
                self.HyperVTestVMCompatibilityModel.Password = '';
                self.HyperVTestVMCompatibilityModel.VmBackupFolder = self.job.destinations[0].path + '\\' + self.vmtorestore + '\\';
                self.HyperVTestVMCompatibilityModel.BackupType = ((self.job.options.rct == 1) ? 'RCT-' : '') + self.job.options.backupType;     //.HyperVBackupDetails.ibvhconfig.backupType;
                self.HyperVTestVMCompatibilityModel.CopyNumber = (self.selectedlog.backup_number == 0 ? 'FULL' : self.selectedlog.backup_number.toString().padStart(3, '0'));
                self.HyperVTestVMCompatibilityModel.VmGuid = self.job.sources[0].hvSource[0].guid;//self.HyperVBackupDetails.ibvhconfig.vmId;
                self.HyperVTestVMCompatibilityModel.NetAccount = '';
                self.HyperVTestVMCompatibilityModel.NetPwd = '';
                self.HyperVTestVMCompatibilityModel.ckbNewVmId = self.newId;
                self.HyperVTestVMCompatibilityModel.LogFilePath = '';
                self.HyperVTestVMCompatibilityModel.ReturnedError = '';
                self.HyperVTestVMCompatibilityModel.result = 0;
                self.HyperVTestVMCompatibilityModel.status = '';

                // variabile per far vedere il progess mentre verifica la compatibilità
                self.aitHVVMTest = true;
                if (!self.HyperVTestVmReport.status) {
                    self.RunCompatibilityTest(self.HyperVTestVMCompatibilityModel);
                }
                self.waitHVVMTest = false;

            }

            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index + 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
        back() {
            var next = true;
            this.steps.forEach((step, index) => {
                if (step == 1) {
                    if (next) {
                        this.$set(this.steps, index - 1, true)
                        this.$set(this.steps, index, false)
                        next = false;

                    }
                }
            });

        },
      

        async RunCompatibilityTest(HVTestModel) {
            let self = this;

            var testres = await this.$root.socket("HYPERV_COMPATIBILITYTEST", HVTestModel);
            if (testres != null) {
                self.HyperVTestVmReport = testres;
                if (self.HyperVTestVmReport.scan && self.HyperVTestVmReport.scan.length > 0) {
                    self.scanTest = self.HyperVTestVmReport.scan[0];
                }

                self.canRestore = true;
                if (testres.incompatibilityModels) {

                    self.incompatIssue = testres.incompatibilityModels;

                    for (let index = 0; index < self.incompatIssue.length; index++) {
                        //console.log('Solvable:' + self.incompatIssue[index].isSolvable);
                        if (!self.incompatIssue[index].isSolvable) { // se c'è almeno un errore non solvable blocco il restore all'utente
                            self.canRestore = false;
                            break;
                        }

                    }

                }

                //self.listbackup = self.HyperVBackupDetails.ibvhconfig.listBackup;
            }

            //if (self.listbackup.length < 1)
            //    alert(self.$t('Nessun backup valido trovato'),self.$t('Controllo validità dei file di backup'),'warning');
        },

        /* SUBSCRIBE METODO SOCKET PER RESTORE EFFETTIVO*/
        async beginRestore() {
            var next = true;
            let self = this;

            self.go = confirm(this.$t("Restore the selected backup?"));


            if (self.go) {

                // faccio vedere la sezione del progress
                this.steps.forEach((step, index) => {
                    if (step == 1) {
                        if (next) {
                            this.$set(this.steps, index + 1, true)
                            this.$set(this.steps, index, false)
                            next = false;

                        }
                    }
                });

                self.justOneGuid = self.uuidv4();

                self.HyperVRestoreModel.Id = self.justOneGuid;
                self.HyperVRestoreModel.ID_LOG = '';
                self.HyperVRestoreModel.ID_JOB = '';
                self.HyperVRestoreModel.VmName = self.suggestedRestoredVmName;
                self.HyperVRestoreModel.VmDestinationFolder = self.job.destinations[0].path + '\\' + self.vmtorestore + '\\';// + (self.bkpDirSelected == 0 ? 'FULL_' + self.HyperVBackupDetails.ibvhconfig.vmId : self.bkpDirSelected.padStart(3, '0') + '_' + self.HyperVBackupDetails.ibvhconfig.vmId);
                self.HyperVRestoreModel.BackupType = ((self.job.options.rct == 1) ? 'RCT-' : '') + self.job.options.backupType;
                self.HyperVRestoreModel.VmGuids = self.job.sources[0].hvSource[0].guid;
                self.HyperVRestoreModel.CopyNumber = (self.selectedlog.backup_number == 0 ? 'FULL' : self.selectedlog.backup_number.toString().padStart(3, '0'));
                self.HyperVRestoreModel.GenerateNewId = self.newId;
                self.HyperVRestoreModel.HyperVHost = 'localhost';
                self.HyperVRestoreModel.Username = '';
                self.HyperVRestoreModel.Password = '';
                if (self.customCopyFilePath.length > 0) {
                    self.HyperVRestoreModel.VhdCustomFolder = self.customCopyFilePath[0].path;
                } else {
                    self.HyperVRestoreModel.VhdCustomFolder = '';
                }
                self.HyperVRestoreModel.type = self.source.type; //self.HyperVBackupDetails.ibvhconfig.backupType;
                self.HyperVRestoreModel.type_name = self.source.type;//self.backupTypeToName(self.HyperVBackupDetails.ibvhconfig.backupType.split('-')[1],self.selBackupInfo.number);

                console.log("[HyperVDestinationRestore.beginRestore]");

                this.$root.socket("HYPERV_RUNRESTORE", self.HyperVRestoreModel);

                // invoca agent per dare start ad invio delle informazioni sul restore
                self.idPC = await this.$root.getPC().id;
                //###DA INSERIRE IN OGNI COMPONENTE CHE NECESSITA DI CHIAMATE SOCKET###
                // "checkSocket" avvia la connessione socket se ferma
                // "socketEventConnected" viene emesso quando la connessione socket è attiva, quindi nella sua callback si possono avviare tutte le istruzioni che riguardano le chiamate socket
                // "socketEventDisconnected" viene emesso quando la connessione socket non è attiva
                this.$root.$on("socketEventConnected", () => {
                    self.$root.socketConnected = true;
                    self.pc_isconnected = true;
                    self.$root.socket("subscribe_pc", self.idPC).then(() => 
                        self.$root.socket("STARTSENDINFO")
                    );
                });
                this.$root.$on("socketEventDisconnected", () =>{
                    self.$root.socketConnected = false;
                    self.pc_isconnected = false;
                });
                this.$root.checkSocket();
                // -------------------------------------------------------------------- 


                // subscrive per ricevere il progress del restore ---------------------
                this.$root.$on("addRestoreToPage", restores => {
                    // TODO
                    console.log('Restore in popup');
                    console.log(restores);
                    //self.restores = restores;

                    self.restore = restores.find(obj => {
                        return obj.id == self.justOneGuid
                    })

                });
                // --------------------------------------------------------------------    

            }
        },

    },

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
    },


    beforeDestroy: function () {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        if (this.connection != null) this.connection.stop();
    },
}
</script>