<template>
    <div v-on:keyup.esc="close()" class="dialog center text-small overflow w-100-sm w-100-md w-75-lg  w-50-xl" v-cloak>

        <DialogTitle :title="repository.id == null ? $t('Add Repository') : repository.name"
            :defaultTitle="repository.id == null ? $t('Add Repository') : $t('Edit Repository')"
            :subTitle="repository.type_name" @closeDialog="close()" />

        <div class="dialog-content">

            <div class="form-group p-3">
                <label>{{ $t("Name") }}</label>
                <input data-role="input" type="text" v-model="repository.name" maxlength="50"
                    :placeholder="$t('Enter friendly name')" data-prepend="<span class='mif-pencil'></span>" />
                <small class="text-muted">{{ $t("Required") }}</small>
            </div>

            <!------------------------------SCELTA DEL SERVICE ACCOUNT--------------------------------------------------------------->

            <!--Questa pagina deve implementare il metodo "sendSATestResult"-->
            <SARepoSelect class="form-group p-3" :type=repository.type :idSA=repository.id_service_account :idRepo=null
                @sendSATestResult="sendSATestResult" ref="saRepoSelect" />

            <!----------------------------------SCELTA DEL PERCORSO------------------------------------------------------------------>

            <div class="form-group p-3">
                <span class="mif-folder-open mr-1" />
                <span> {{ $t("Select a repository") }}:</span>
                <!--Nota : Se l'utente deve scegliere il sottopercorso, mostro solo il bottone.-->
                <div class="d-flex flex-row  flex-align-baseline">

                    <input v-if="selectedNodes.length == 0" type="text" data-role="input"
                        class="w-75 text-small" :placeholder="$t('Click here for select a path')" />
                    <input v-else type="text" data-role="input" v-model="selectedNodes[0].path"
                        class="w-75 text-small " :title="selectedNodes[0].path"
                        :placeholder="$t('Click here for select a path')" />
                    <a class="button ml-2" @click="toggleFileSystemSidebar"><span class="mif-folder" /></a>
                </div>
            </div>
        </div>

        <!-------------------------------------SALVATAGGIO-------------------------------------------------------------->
        <div class="dialog-actions flex-justify-end">
            <button class="button ml-2" @click="close()"><span class="mif-close"></span>
                {{ $t("Cancel") }}</button>

            <button class="button primary ml-2"
                :disabled="!testResult.ok || selectedNodes.length == 0 || repository.name == ''" @click="save"><span
                    class="mif-checkmark mr-1" />
                {{ $t("Save") }}</button>
        </div>
    </div>
</template>

<script>
import DialogTitle from "../../utils/DialogTitle";
import SARepoSelect from "../../utils/SARepoSelect";
import { isValid, getNodeType } from "../../../../public/assets/js/utilitiesmodule";

export default {
    name: "Repository",
    props: {
        repository: Object,
    },
    components: {
        "DialogTitle": DialogTitle,
        SARepoSelect
    },
    data: function () {
        return {
            selectedNodes: [],
            testResult: {
                ok: false,
                okNumber: 0,
                serviceAccount: { id: -2, type: this.repository.type },
                client: null,// il client della piattaforma che effettuerà le richieste dei list
                list: null,
            }
        }
    },
    async created() {
        this.selectedNodes.push({
            path: this.repository.path,
            type: getNodeType(this.repository.type, false)
        });

        if (!isValid(this.repository.id))
            return;

        //Solamente al termine del created, è visibile this.$refs.saRepoSelect
        setTimeout(() => {
            this.$refs.saRepoSelect.test();    
        }, 500);

    },

    beforeDestroy() {
        this.$root.$off("socketEventConnected");
        this.$root.$off("socketEventDisconnected");
        //In caso di ftp o network, eseguo la disconnessione
        if (this.testResult.ok && [this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.repository.type)) {
            var method = this.repository.type == 2 ? "disconnectftp" : "disconnectnetwork";
            this.$root.socket(method, this.testResult.client.serviceAccount.accountSessionId);
            if (this.$session.get('ROOMPC') == null)
                this.$root.socket("SETPC", null);
        }
    },
    methods: {
        sendSATestResult(testResult) {
            this.testResult = testResult;
            this.repository.id_service_account = testResult.serviceAccount.id;
        },
        async toggleFileSystemSidebar() {

            if (isValid(this.testResult.serviceAccount.options.lastTestPC) && this.$session.get("PCSCONNECTIONS")[this.testResult.serviceAccount.options.lastTestPC]) {
                var pc = this.$session.get("COMPUTERS").find(c => c.id == this.testResult.serviceAccount.options.lastTestPC);
                await this.$root.socket("SETPC", pc);
            }



            //if ([2, $root.PlatformsTypesEnum.Network].includes(this.repository.type) && this.client.serviceAccount.options.accountSessionId == "") {
            //var method = this.repository.type == 2 ? "connectftp" : "connectnetwork";
            //this.client.serviceAccount.options.accountSessionId = await this.$root.socket(method, null, this.client.serviceAccount);
            //}
            var config = {
                type: this.repository.type,
                includeFiles: false,
                multipleChoice: false,
                client: this.testResult.client,
                rootPath: [this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.repository.type) ? this.testResult.client.serviceAccount.options.path : ""
            };

            this.$root.$emit("toggleFileSystemSidebar", this.selectedNodes, config);
        },

        async save() {
            let self = this;
            this.$root.$emit("closeFileSystemSidebar");
            self.repository.path = this.selectedNodes[0].path;
            await this.$root.saveRepository(self.repository);
        },

        async close() {
            //In caso di ftp o network, eseguo la disconnessione
            if (this.client != null && [this.$root.PlatformsTypesEnum.FTP, this.$root.PlatformsTypesEnum.Network].includes(this.repository.type)) {
                var method = this.repository.type == 2 ? "disconnectftp" : "disconnectnetwork";
                this.$root.socket(method, this.testResult.client.serviceAccount.accountSessionId);
            }
            this.$root.$emit('CLOSEREPOSITORYDIALOG');
            this.$root.$emit("closeFileSystemSidebar");
        }
    }
}
</script>