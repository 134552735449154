<template>
<div class="h-vh-100" >
    <Appbar :user=false />
    <transition name="slide-fade" >
        <router-view/>
    </transition>   

</div>
</template>
<script>
import Appbar from '../layouts/Appbar';
export default {
  name: 'simple-layout',
  components: {
    Appbar
  },
  data () {
    return {  }
  }
}
</script>
