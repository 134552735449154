var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dialog center text-small overflow w-100-sm w-100-md w-75-lg w-50-xl",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.close()
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title:
            _vm.repository.id == null
              ? _vm.$t("Add Repository")
              : _vm.repository.name,
          defaultTitle:
            _vm.repository.id == null
              ? _vm.$t("Add Repository")
              : _vm.$t("Edit Repository"),
          subTitle: _vm.repository.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.close()
          },
        },
      }),
      _c(
        "div",
        { staticClass: "dialog-content" },
        [
          _c("div", { staticClass: "form-group p-3" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("Name")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.repository.name,
                  expression: "repository.name",
                },
              ],
              attrs: {
                "data-role": "input",
                type: "text",
                maxlength: "50",
                placeholder: _vm.$t("Enter friendly name"),
                "data-prepend": "<span class='mif-pencil'></span>",
              },
              domProps: { value: _vm.repository.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.repository, "name", $event.target.value)
                },
              },
            }),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("Required"))),
            ]),
          ]),
          _c("SARepoSelect", {
            ref: "saRepoSelect",
            staticClass: "form-group p-3",
            attrs: {
              type: _vm.repository.type,
              idSA: _vm.repository.id_service_account,
              idRepo: null,
            },
            on: { sendSATestResult: _vm.sendSATestResult },
          }),
          _c("div", { staticClass: "form-group p-3" }, [
            _c("span", { staticClass: "mif-folder-open mr-1" }),
            _c("span", [
              _vm._v(" " + _vm._s(_vm.$t("Select a repository")) + ":"),
            ]),
            _c("div", { staticClass: "d-flex flex-row flex-align-baseline" }, [
              _vm.selectedNodes.length == 0
                ? _c("input", {
                    staticClass: "w-75 text-small",
                    attrs: {
                      type: "text",
                      "data-role": "input",
                      placeholder: _vm.$t("Click here for select a path"),
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNodes[0].path,
                        expression: "selectedNodes[0].path",
                      },
                    ],
                    staticClass: "w-75 text-small",
                    attrs: {
                      type: "text",
                      "data-role": "input",
                      title: _vm.selectedNodes[0].path,
                      placeholder: _vm.$t("Click here for select a path"),
                    },
                    domProps: { value: _vm.selectedNodes[0].path },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.selectedNodes[0],
                          "path",
                          $event.target.value
                        )
                      },
                    },
                  }),
              _c(
                "a",
                {
                  staticClass: "button ml-2",
                  on: { click: _vm.toggleFileSystemSidebar },
                },
                [_c("span", { staticClass: "mif-folder" })]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "dialog-actions flex-justify-end" }, [
        _c(
          "button",
          {
            staticClass: "button ml-2",
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [
            _c("span", { staticClass: "mif-close" }),
            _vm._v(" " + _vm._s(_vm.$t("Cancel"))),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "button primary ml-2",
            attrs: {
              disabled:
                !_vm.testResult.ok ||
                _vm.selectedNodes.length == 0 ||
                _vm.repository.name == "",
            },
            on: { click: _vm.save },
          },
          [
            _c("span", { staticClass: "mif-checkmark mr-1" }),
            _vm._v(" " + _vm._s(_vm.$t("Save"))),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }