var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog overflow w-100-sm w-100-md w-75-lg w-50-xl",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$root.$emit("CLOSESOURCEDIALOG")
        },
      },
    },
    [
      _c("DialogTitle", {
        attrs: {
          title: _vm.$t("Configure Source"),
          defaultTitle: _vm.$t("Configure Source"),
          subTitle: _vm.source.type_name,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.$root.$emit("CLOSESOURCEDIALOG")
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == 0,
              expression: "step == 0",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c("SARepoSelect", {
            attrs: {
              type: _vm.source.type,
              idSA: _vm.source.id_service_account,
              idRepo: null,
            },
            on: { sendSATestResult: _vm.sendSATestResult },
          }),
          _vm.wait || _vm.saTest == _vm.SA_TEST_FAILED
            ? _c("div", { staticClass: "form-group mt-2" }, [
                _vm.wait
                  ? _c("h6", [
                      _c("span", { staticClass: "mif-spinner2 ani-spin ml-2" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("Listing resources from Exchange 365"))
                        ),
                      ]),
                    ])
                  : _c("h6", [_vm._v(_vm._s(_vm.$t("No mailbox found")))]),
              ])
            : _vm._e(),
          _vm.saTest == _vm.SA_TEST_OK
            ? _c("SelectedResourcesLayout", {
                attrs: {
                  list: _vm.selectedNodes,
                  singleItem: false,
                  canCancel: true,
                  buttonText: _vm.$t("Select Exchange Mailboxes to backup"),
                  isDisabled: false,
                  heightPercentage: 40,
                },
                on: {
                  clickFunction: function ($event) {
                    return _vm.toggleFileSystemSidebar()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.step == 1
        ? _c("div", { staticClass: "dialog-content" }, [
            _c("h6", { staticClass: "form-group text-bold" }, [
              _vm._v(_vm._s(_vm.$t("Backup mode"))),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Type")) + ":")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.source.options.backupType,
                      expression: "source.options.backupType",
                    },
                  ],
                  attrs: { "data-role": "select", "data-filter": "false" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.source.options,
                        "backupType",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  Object.keys(_vm.$root.selectValues.backupTypes),
                  function (key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: key } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(_vm.$root.selectValues.backupTypes[key])
                            )
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("Number of copies")) + ":")]),
              _c("input", {
                staticClass: "w-25 ml-2",
                attrs: {
                  type: "text",
                  "data-role": "spinner",
                  id: "nCopiesSpinner",
                  "data-default-value": "1",
                  "data-min-value": "1",
                  "data-max-value": "365",
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.outputFormat,
                    expression: "source.options.outputFormat",
                  },
                ],
                staticClass: "form-check-input mb-1",
                attrs: {
                  "data-role": "radio",
                  type: "radio",
                  value: "PST",
                  "data-caption": _vm.$t(
                    "Export mailboxes to individual .pst files"
                  ),
                },
                domProps: {
                  checked: _vm._q(_vm.source.options.outputFormat, "PST"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.source.options, "outputFormat", "PST")
                  },
                },
              }),
              _c("br"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.outputFormat,
                    expression: "source.options.outputFormat",
                  },
                ],
                staticClass: "form-check-input mb-1",
                attrs: {
                  "data-role": "radio",
                  disabled:
                    _vm.source.type ==
                    _vm.$root.PlatformsTypesEnum.Microsoft_ExchangeOnPremises,
                  type: "radio",
                  value: "EML",
                  "data-caption": _vm.$t(
                    "Export mailboxes to individual .eml/.ics/.vcf files"
                  ),
                },
                domProps: {
                  checked: _vm._q(_vm.source.options.outputFormat, "EML"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.source.options, "outputFormat", "EML")
                  },
                },
              }),
              _c("br"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.outputFormat,
                    expression: "source.options.outputFormat",
                  },
                ],
                staticClass: "form-check-input mb-1",
                attrs: {
                  "data-role": "radio",
                  disabled:
                    _vm.source.type !=
                    _vm.$root.PlatformsTypesEnum.Microsoft_ExchangeOnPremises,
                  type: "radio",
                  value: "EDB",
                  "data-caption": _vm.$t("Export Database Files (.edb)"),
                },
                domProps: {
                  checked: _vm._q(_vm.source.options.outputFormat, "EDB"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.source.options, "outputFormat", "EDB")
                  },
                },
              }),
              _c("br"),
            ]),
            _c("h6", { staticClass: "form-group text-bold" }, [
              _vm._v(_vm._s(_vm.$t("Backup Options"))),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includeArchiveMailbox,
                    expression: "source.options.includeArchiveMailbox",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  type: "checkbox",
                  "data-caption": _vm.$t("Include Archive Mailboxes"),
                  "data-validate": "required",
                  required: "",
                  "data-prepend": "<span class='mif-server'></span>",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.source.options.includeArchiveMailbox
                  )
                    ? _vm._i(_vm.source.options.includeArchiveMailbox, null) >
                      -1
                    : _vm.source.options.includeArchiveMailbox,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includeArchiveMailbox,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeArchiveMailbox",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includeArchiveMailbox",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includeArchiveMailbox", $$c)
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.useParallelMode,
                    expression: "source.options.useParallelMode",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  type: "checkbox",
                  "data-caption": _vm.$t("Use parallel mode"),
                  "data-validate": "required",
                  required: "",
                  "data-prepend": "<span class='mif-server'></span>",
                },
                domProps: {
                  checked: Array.isArray(_vm.source.options.useParallelMode)
                    ? _vm._i(_vm.source.options.useParallelMode, null) > -1
                    : _vm.source.options.useParallelMode,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.useParallelMode,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "useParallelMode",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "useParallelMode",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "useParallelMode", $$c)
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.multipleDownloadThreads,
                    expression: "source.options.multipleDownloadThreads",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  type: "checkbox",
                  "data-caption": _vm.$t("Use multiple download processes"),
                  "data-validate": "required",
                  required: "",
                  "data-prepend": "<span class='mif-server'></span>",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.source.options.multipleDownloadThreads
                  )
                    ? _vm._i(_vm.source.options.multipleDownloadThreads, null) >
                      -1
                    : _vm.source.options.multipleDownloadThreads,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.multipleDownloadThreads,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "multipleDownloadThreads",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "multipleDownloadThreads",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.source.options,
                        "multipleDownloadThreads",
                        $$c
                      )
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.includePublicFolders,
                    expression: "source.options.includePublicFolders",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  type: "checkbox",
                  "data-caption": _vm.$t("Include Public folder"),
                  "data-validate": "required",
                  required: "",
                  "data-prepend": "<span class='mif-server'></span>",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.source.options.includePublicFolders
                  )
                    ? _vm._i(_vm.source.options.includePublicFolders, null) > -1
                    : _vm.source.options.includePublicFolders,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.includePublicFolders,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "includePublicFolders",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "includePublicFolders",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "includePublicFolders", $$c)
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.source.options.truncateExchangeLogs,
                    expression: "source.options.truncateExchangeLogs",
                  },
                ],
                attrs: {
                  "data-role": "checkbox",
                  disabled:
                    _vm.source.type !=
                    _vm.$root.PlatformsTypesEnum.Microsoft_ExchangeOnPremises,
                  type: "checkbox",
                  "data-caption": _vm.$t("Truncate Exchange log"),
                  "data-validate": "required",
                  required: "",
                  "data-prepend": "<span class='mif-server'></span>",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.source.options.truncateExchangeLogs
                  )
                    ? _vm._i(_vm.source.options.truncateExchangeLogs, null) > -1
                    : _vm.source.options.truncateExchangeLogs,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.source.options.truncateExchangeLogs,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.source.options,
                            "truncateExchangeLogs",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.source.options,
                            "truncateExchangeLogs",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.source.options, "truncateExchangeLogs", $$c)
                    }
                  },
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "form-group row d-flex flex-justify-between" },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("Number of main threads")) + ":"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.source.options.nThreads,
                      expression: "source.options.nThreads",
                    },
                  ],
                  staticClass: "w-25",
                  attrs: {
                    id: "nThreadsSpinner",
                    type: "text",
                    "data-role": "spinner",
                    "data-min-value": "1",
                    "data-max-value": "6",
                  },
                  domProps: { value: _vm.source.options.nThreads },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.source.options,
                        "nThreads",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "form-group row d-flex flex-justify-between" },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("Number of download threads")) + ":"),
                ]),
                _c("input", {
                  staticClass: "w-25",
                  attrs: {
                    id: "nDownloadThreadsSpinner",
                    type: "text",
                    "data-role": "spinner",
                    "data-min-value": "1",
                    "data-max-value": "20",
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "form-group pt-4" }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "Number of bad items for considering failed a mailbox export"
                    )
                  ) + ":"
                ),
              ]),
              _c("input", {
                staticClass: "w-25",
                attrs: {
                  id: "badItemsLimitSpinner",
                  type: "text",
                  "data-role": "spinner",
                  "data-min-value": "1",
                  "data-max-value": "500",
                },
              }),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "dialog-actions d-flex flex-justify-end" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { disabled: _vm.step == 0 },
            on: {
              click: function ($event) {
                _vm.step -= 1
              },
            },
          },
          [
            _c("span", { staticClass: "mif-arrow-left mr-1" }),
            _c("span", [_vm._v(_vm._s(_vm.$t("Prev")))]),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "button ml-2",
            attrs: {
              disabled:
                _vm.source.id_service_account == -2 ||
                _vm.step == 1 ||
                _vm.selectedNodes.length == 0,
            },
            on: {
              click: function ($event) {
                _vm.step += 1
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("Next")))]),
            _c("span", { staticClass: "mif-arrow-right ml-1" }),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "button error float-right ml-2",
            on: {
              click: function ($event) {
                return _vm.$root.$emit("CLOSESOURCEDIALOG")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Cancel")))]
        ),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step == 1,
                expression: "step == 1",
              },
            ],
            staticClass: "button primary ml-2 float-right",
            on: { click: _vm.saveSource },
          },
          [
            _c("span", { staticClass: "mif-checkmark pr-2 mr-1" }),
            _c("span", [_vm._v(_vm._s(_vm.$t("Save")))]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }