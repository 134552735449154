var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "badge inline text-small px-2 py-1 m-1",
      class: "bg-" + _vm.bgColor + " fg-" + _vm.txtColor,
    },
    [_vm._v(_vm._s(_vm.text))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }