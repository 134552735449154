import api from "./api";
import { cookieManager, createErrorObject, isValid, platformsTypes } from "../../public/assets/js/utilitiesmodule";
import "../../public/assets/js/messages";
import { BACKUP_MESSAGES, LOGIN_FAILED, PC_MESSAGES, USER_MESSAGES, GROUP_MESSAGES } from "../../public/assets/js/messages";
import qs from 'querystring';
import moment from "moment";
// Importa il modulo crypto
/******************************************SESSIONE****************************************************************************/

export const loginAPI = {
    /*
    {
        "access_token": "xzrVbMM9LswKd5-ixRtzfQDXGjAjELAKuA0N5WW_AfU",
        "dataora_scadenza": "2033-04-07T00:00:00Z",
        "email": "a.foresi@entersrl.it",
        "firstname": "Andrea",
        "id_master_account": "1d64e95e-2179-48f9-bcd1-f47dba8722df",
        "id_user": "3c5b0568-73e7-47cd-9cd5-109349608e0b",
        "lastname": "Foresi",
        "level": "2",
        "ma_username": "m_test",
        "nome_licenza": "Small",
        "roles": "Admin",
        "username": "op_test"
    }
    */
    login: async function(username, password) {

        var config = {
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };

        var response = await api.post('/oauth/token', qs.stringify({ "username": username, "password": password }), config)
            .then(res => {
                if (res.status == 400) {
                    console.log(res.data.message);
                    return null;
                }
                return res.data;
            }).catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    logout: async function(config) {
        var response = await api.post('/auth/logout', config)
            .then(() => {
                return true
            })
            .catch(e => {
                console.log(e);
                return false;
            });
        return response;
    },
    loginTFA: async function(TFACode) {
        var config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        var body = {
            "totp_code": TFACode

        };

        var response = await api.post('/oauth/mfa/login', body, config)
            .then(res => {
                if (res.status == 400) {
                    console.log(res.data.message);
                    return null;
                }
                return res.data;
            }).catch(e => {
                console.log(LOGIN_FAILED);
                console.log(e);
                return null;
            });

        return response;
    },
    loginTFARecovery: async function(TFACodeRecovery) {
        var config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };

        var body = qs.stringify({ "RecoveryCode": TFACodeRecovery });

        var response = await api.post('/auth/recovery', body, config)
            .then(res => {
                if (res.status == 400) {
                    console.log(res.data.message);
                    return null;
                }
                return res.data;
            }).catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    getEvents: async function(accessToken) {
        var response = await api.get('/enterprise/events', buildConfig(accessToken))
            .then(res => {
                if (res.status == 400) {
                    console.log(res.data.message);
                    return null;
                }

                return res.data;
            }).catch(e => {
                console.log(e);
                return null;
            });

        return response;



    },
    // sessioni e statistiche di Iperius Remote
    getSessions: async function(stats, accessToken) {

        var body = {
            'id_group': stats.id_group,
            'id_user': stats.id_user, //stats["id_user"],
            'dt_inf': moment(stats.dt_inf).format("YYYYMMDDhhmmss"),
            'dt_sup': moment(stats.dt_sup).format("YYYYMMDDhhmmss"),
            'id_host': "",
            "include_stats": stats.include_stats ? stats.include_stats : "1"
        };

        var response = await api.put('/sessions', body, buildConfig(accessToken))
            .then(res => {
                if (res.status == 400) {
                    console.log(res.data.message);
                    return null;
                }

                return res.data;
            }).catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    getSessionsLight: async function(accessToken) {


        var response = await api.get('/sessions?limit=30', buildConfig(accessToken))
            .then(res => {
                if (res.status == 400) {
                    console.log(res.data.message);
                    return null;
                }

                return res.data;
            }).catch(e => {
                console.log(e);
                return null;
            });

        return response;



    },
    getBackupstats: async function(stats, accessToken) {
        var url = 'enterprise/stats/backup';
        url += '?dt_start=' + moment(stats.dt_inf).format("YYYYMMDDhhmmss");
        url += '&dt_end=' + moment(stats.dt_sup).format("YYYYMMDDhhmmss");
        url += '&idg=' + stats.id_group;
        var response = await api.get(url, buildConfig(accessToken))
            .then(res => {
                if (res.status == 400) {
                    console.log(res.data.message);
                    return null;
                }

                return res.data;
            }).catch(e => {
                console.log(e);
                return null;
            });

        return response;

    },


    toggleTFA: async function(accessToken) {
        var response = await api.get('/accounts/auth/mfa', buildConfigTFA(accessToken))
            .then(res => {
                console.log(res.headers['Cookie']);
                return res.data.auth_uri;

            })
            .catch(e => {
                console.log(e);
                return false;
            });

        return response;
    },

    enableTFA: async function(accessToken, code) {
        var data = {
            'validation_totp': code,
        };


        var response = await api.post('/accounts/auth/mfa/enable', data, buildConfigTFA(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return false;
            });

        return response;
    },

    disableTFA: async function(accessToken) {
        var response = await api.get('/accounts/auth/mfa/disable', buildConfigTFA(accessToken))
            .then(res => {
                console.log(res);
                return true;
            })
            .catch(e => {
                console.log(e);
                return false;
            });

        return response;
    },

    confirmTFA: async function(TFACode, accessToken) {
        var config = buildConfig(accessToken);
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        var response = await api.post(
                '/accounts/auth/options/mfa',
                qs.stringify({ Code: TFACode }),
                config
            ).then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    getLocalPublicIP: async function() {
        var response = await api.get("https://api.ipify.org?format=json")
            .then(res => res.data.ip)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
        /*$.getJSON("https://api.ipify.org?format=json",
            function(data) {

                    // Displayin IP address on screen
                alert(data.ip);
        });*/
    }
}



/*************************************************TFA*********************************************************************/





/******************************************SERVICE ACCOUNTS******************************************************************/

export const serviceAccountAPI = {
    getServiceAccounts: async function(accessToken) {
        var response = await api.get('/enterprise/svcaccounts', buildConfig(accessToken))
            .then(res => res.data.map(x => {
                if (!isValid(x.type_name) || x.type_name.toLowerCase() == "unknown")
                    x.type_name = platformsTypes[x.type].name;
                return x;
            }))
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },
    createServiceAccount: async function(serviceAccount, accessToken) {
        if (!serviceAccount.port)
            serviceAccount.port = 0;
        if (!serviceAccount.status)
            serviceAccount.status = 1;
        var response = await api.post('/enterprise/svcaccounts/', serviceAccount, buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },
    editServiceAccount: async function(serviceAccount, accessToken) {
        if (!serviceAccount.port)
            serviceAccount.port = 0;
        if (!serviceAccount.status)
            serviceAccount.status = 1;
        var response = await api.put('/enterprise/svcaccounts/', serviceAccount, buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },
    deleteServiceAccount: async function(idServiceAccount, accessToken) {
        var response = await api.delete('/enterprise/svcaccounts/' + idServiceAccount, buildConfig(accessToken))
            .then(() => true)
            .catch(e => {
                console.log(e);
                return false;
            });

        return response;
    }
}

/**********************************************REPOSITORIES*****************************************************************/

export const repositoryAPI = {
    getRepositories: async function(accessToken) {
        var response = await api.get('/enterprise/repositories', buildConfig(accessToken))
            .then(res => res.data.map(x => {
                if (!isValid(x.type_name) || x.type_name.toLowerCase() == "unknown")
                    x.type_name = platformsTypes[x.type].name;
                return x;
            }))
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    createRepository: async function(repository, accessToken) {

        var response = await api.post('/enterprise/repositories', repository, buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    editRepository: async function(repository, accessToken) {
        var response = await api.put('/enterprise/repositories', repository, buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });
        return response;
    },

    deleteRepository: async function(repositoryID, accessToken) {
        var response = await api.delete('/enterprise/repositories/' + repositoryID, buildConfig(accessToken))
            .then(() => true)
            .catch(e => {
                console.log(e);
                return false;
            });

        return response;
    },

    getAmzRegions: async function(accessToken, type) {
        var response = await api.get('/enterprise/awsregions', buildConfig(accessToken))
            .then(response => {
                var regions = response.data;

                if (type)
                    regions = regions.filter(region => isValid(region.ForType) && region.ForType.includes(type.toString()));

                return regions;
            })
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;

    }
}


/****************************************PC*********************************************************/
export const pcAPI = {

    // Funzione per convertire una stringa in UTF-8
    toUtf8(str) {
        return unescape(encodeURIComponent(str));
    },

    getPCList: async function(accessToken) {
        var response = await api.get("/computers?gLite=true", buildConfig(accessToken))
            .then(res => res.data.computers.map(pc => { pc.connected = false; return pc }))
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                return null;
            });

        return response;
    },
    getPCRemoteConnected: async function(idPClist, accessToken) {
        var response = await api.put("/computers/isonline?id_list=" + idPClist.map((obj) => obj.id_remote).join("|"), [], buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                return null;
            });

        return response;
    },

    removeFromLicense: async function(idPC, accessToken, ) {
        var response = await api.put("/enterprise/license/disconnect",{"id":idPC}, buildConfig(accessToken, process.env.VUE_APP_BASE_URL_ENT))
            .then(res => res.data)
            .catch(e => {
                console.log(PC_MESSAGES.ERROR);
                console.log(e);
                return null;
            });

        return response;
    },

    addToLicense: async function(idPC, accessToken) {
        var response = await api.put("/enterprise/license/restore?id", { "id": idPC },buildConfig(accessToken))
            .then(res => res.statusText)
            .catch(e => {
                console.log(PC_MESSAGES.ERROR);
                console.log(e);
                return null;
            });

        return response;
    },

    getPcListByGroupID: async function(idGroup, accessToken) {
        var response = await api.get("/enterprise/computers/list/lite?idg=" + idGroup, buildConfig(accessToken))
            .then(res => res.data.computers)
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                return null;
            });
        return response;
    },

    getPCListLite: async function(accessToken) { // TODO DA CORREGGERE
        var response = await api.get("/enterprise/computers/list/lite/", buildConfig(accessToken))
            .then(res => res.data.computers)
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                return null;
            });

        return response;
    },

    getOTP: async function(pcId, accessToken) { // TODO DA CORREGGERE
        var response = await api.get("/enterprise/remote/connection/otp?pcId=" + pcId.pcId, buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    getPCListWithSubdirectories: async function(idLicense, accessToken) { //TODO SERVE?
        var response = await api
            .get('/directories/' + idLicense, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            })
            .then(response => {

                return {
                    pcList: response.data.computers,
                    subDirectories: response.data.subDirectories
                };
            })
            .catch(e => {
                console.log(PC_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                return null;
            });

        return response;
    },

    getPC: async function(idPC, accessToken) {
        var response = await api.get('/enterprise/computers/' + idPC + "?wg=true", buildConfig(accessToken))
            .then(res => res.data.computer)
            .catch(e => {
                console.log(PC_MESSAGES.NOT_RETRIEVED);
                console.log(e);
                return null;
            });

        return response;
    },



    savePwCustom: async function(pc, accessToken) {


        const crypto = require('crypto');
        // Convertire la password in UTF-8
        const strUtf8 = this.toUtf8(pc.custom_password);

        var data = {
            "id": pc.id_setting,
            "custom_password": crypto.createHash('sha256').update(strUtf8).digest('hex').toUpperCase()
        };



        var response = await api.put('/settings/setpwd', data, buildConfig(accessToken))
            .then(resp => resp)
            .catch(e => {
                console.log(PC_MESSAGES.PASSWORD_ERROR);
                console.log(e);
                return null;
            });

        return response;
    },


    removePwCustom: async function(pc, accessToken) {



        var data = {
            "id": pc.id_setting,

        };



        var response = await api.put('/settings/removepwd', data, buildConfig(accessToken))
            .then(resp => resp)
            .catch(e => {
                console.log(PC_MESSAGES.PASSWORD_ERROR);
                console.log(e);
                return null;
            });

        return response;
    },

    editPC: async function(pc, accessToken) {


        var data = {
            "computer_id": pc.id,
            "id_user": pc.id_user,
            "setting_attribute": "2",
            "pc_name": pc.pc_name,
            "description": pc.description,
            "organization": pc.organization_name,
            "group_attribute": pc.attribute,
            "is_protected": pc.is_protected,
            "pc_status": pc.pc_status,
            "id": pc.id_setting
        };



        if (pc.attribute == "3") {

            var response = await api.post(
                '/settings',
                data,
                buildConfig(accessToken)
            ).then(res => {
                if (res.status == 400) return null;
                return true;
            }).catch(e => {
                console.log(PC_MESSAGES.NOT_MODIFIED);
                console.log(e);
                Object.keys(e.data.errors).forEach(key => {
                    console.log(key + ":" + e.data.errors[key]);
                });
                return e.data.errors;
            })

        } else {

            var response = await api.put(
                '/settings',
                data,
                buildConfig(accessToken)
            ).then(res => {
                if (res.status == 400) return null;
                return true;
            }).catch(e => {
                console.log(PC_MESSAGES.NOT_MODIFIED);
                console.log(e);
                Object.keys(e.data.errors).forEach(key => {
                    console.log(key + ":" + e.data.errors[key]);
                });
                return e.data.errors;
            });
        }


        return response;
    },

    getStatsFromPC: async function(idPC, accessToken) {
        var endDate = moment(new Date()).format("YYYYMMDDhhmmss");

        var response = await api.get('/enterprise/stats/computer?id=' + idPC + '&dt_start=20200101000000&dt_end=' + endDate + '&stepped=true',
                buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    getStatsFromLicense: async function(accessToken, startDate, endDate) {
        var startDate = moment(startDate).format("YYYYMMDDhhmmss");
        var endDate = moment(endDate).format("YYYYMMDDhhmmss");
        var response = await api.get(
                '/enterprise/stats/license?dt_start=' + startDate + '&dt_end=' + endDate + '&stepped=true',
                buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    getStatsFromLicenseLight: async function(idgroup, accessToken) {

        var response = await api.get(
                '/enterprise/stats/main' + (idgroup != null ? '?idg=' + idgroup : ''),
                buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },
    //Restituisce un array di oggetti di tipo:
    //{
    //  id_group:...
    //  name_group:...
    //  num_computers:...
    //}
    getGroups: async function(accessToken) {
        var response = await api.get('/groupslite', buildConfig(accessToken))
            .then(res => res.data.groups.map(g => {
                //g.pcs = [];
                return g;
            }))
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },
    getControls: async function(accessToken) {
        var response = await api.get('/controls', buildConfig(accessToken))
            .then(res => {
                return res.data;
            })
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },
    getGroup: async function(idgroup, accessToken) {
        var response = await api.get('/groupswu/' + idgroup, buildConfig(accessToken))
            .catch(e => {
                console.log(e);
                return null;
            });
        response.data.computers = response.data.computers.map(pc => { pc.connected = false; return pc })
        response.data.ids_computers = response.data.computers.map(pc => { return pc.id })
        response.data.ids_users = response.data.users.map(user => { return user.id })
        return response.data;
    },

    createGroup: async function(group, accessToken) {

        var config = buildConfig(accessToken);

        var response = await api.post(
            '/shgroups?full=true',
            JSON.stringify(group),
            config
        ).then(res => {
            if (res.status == 400) return null;
            return res.data;
        }).catch(e => {
            console.log(e);
            Object.keys(e.data.user.errors).forEach(key => {
                e.data.user.errors[key].forEach(message => console.log(key + " : " + message))
            });
            return null;
        });

        return response;
    },

    editGroup: async function(group, accessToken) {
        var config = buildConfig(accessToken);
        var groupString = JSON.stringify(group);
        //config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        var response = await api.put(
            '/groups?full=true',
            groupString,
            config
        ).then(res => {
            if (res.status == 400) return null;
            return res.data;
        }).catch(e => {
            console.log(GROUP_MESSAGES.NOT_MODIFIED);
            console.log(e);
            Object.keys(e.data.errors).forEach(key => {
                console.log(key + ":" + e.data.errors[key]);
            });
            return null;
        });

        return response;
    },

    deleteGroup: async function(groupid, accessToken) {
        var response = await api.delete('/groups/' + groupid, buildConfig(accessToken))
            .then(res => res.status != 400)
            .catch(e => {
                console.log(GROUP_MESSAGES.NOT_DELETED);
                console.log(e);
                return false;
            });

        return response;
    }
}


/****************************************BACKUP**************************************************************************************/
export const backupAPI = {
    //TODO DA MODIFICARE
    //STEP 0 : async function creare i vari id (job, sources, destinations)
    //vedere con andrea
    createBackup: async function(backupObj, accessToken) {
        var config = buildConfig(accessToken);
        var body = JSON.stringify(backupObj);


        var response = await api.post('/enterprise/jobs', body, config)
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                e = createErrorObject(e);
                console.log(e);
                return null;
            });

        return response;
    },

    editBackup: async function(backupObj, accessToken) {
        var config = buildConfig(accessToken);
        var body = JSON.stringify(backupObj);

        var response = await api.put('/enterprise/jobs', body, config)
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                e = createErrorObject(e);
                console.log(e);
                return null;
            });

        return response;
    },

    deleteBackup: async function(idBackup, accessToken) {
        var config = buildConfig(accessToken);

        var response = await api.delete('/enterprise/jobs/' + idBackup, config)
            .then(res => 200 <= res.status && res.status <= 299)
            .catch(e => {
                console.log(e);
                return false;
            });

        return response;
    },

    renameBackup: async function(idBackup, newName, accessToken) {
        var config = buildConfig(accessToken);
        var body = JSON.stringify({ name: newName });

        var response = await api.put('/enterprise/jobs/rename/' + idBackup, body, config)
            .then(res => 200 <= res.status && res.status <= 299)
            .catch(e => {
                console.log(e);
                e = createErrorObject(e);
                console.log(e);
                return false;
            });

        return response;
    },
    /*
    Ritorna un oggetto del tipo
    {
        id: ...,
        name : ... , 
        sources : [],
        destinations : [],
        scheduling : []
    }*/
    getBackupWithID: async function(idBackup, accessToken) {
        var response = await api.get('/enterprise/jobs/' + idBackup + "?fview=true", buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    getBackupLogs: async function(idBackup, accessToken) {
        var response = await api.get('/enterprise/logs/job/' + idBackup, buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });
        return response;
    },

    getBackups: async function(idPC, level, numRows, offset, accessToken) { //offset -> punto di inizio, index -> quanti elementi prendere
        //numRows = 300; //TODO RICORDATI CHE VA UTILIZZATO PER LA PAGINAZIONE...
        var params = "index=" + numRows + "&offset=" + offset;
        if (idPC != null) {
            params += "&pc=" + idPC
        }
        //se level è 99, restituisce tutti i backup e non serve inserire il filtro nei parametri
        if (level != 99) params += "&level=" + level;
        var response = await api.get('/enterprise/jobs?' + params, buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });
        return response;
    },


    getIdleBackups: async function(accessToken) {
        var response = await api.get('/enterprise/jobs/zombies/1', buildConfig(accessToken))
            .then(res => res.data.map(x => {
                if (!isValid(x.type_name) || x.type_name.toLowerCase() == "unknown")
                    x.type_name = platformsTypes[x.type].name;
                return x;
            }))
            .catch(e => {
                console.log(BACKUP_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                return null;
            });

        return response;
    },
    //[TG - legge la configurazione del backup dal singolo log]
    getBackupLogConfiguration: async function(logId, accessToken) {
        var response = await api.get('/enterprise/backupconfigurations?lid=' + logId, buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(BACKUP_MESSAGES.LOG_DETAIL_NOT_RETRIEVED);
                console.log(e);
                return null;
            });
        return response;
    },

}


/*****************************************USERS***************************************************************************************/
export const userAPI = {
    getUsers: async function(accessToken) {
        var response = await api.get('/accounts/users', buildConfig(accessToken))
            .then(res => res.data.users)
            .catch(e => {
                console.log(USER_MESSAGES.LIST_NOT_RETRIEVED);
                console.log(e);
                return null;
            });

        return response;
    },

    /*

    {
        "data": {
            "user": {
                "errors": {
                    "email": [
                        "must be a valid email address"
                    ],
                    "password": [
                        "at least one upper case character"
                    ],
                    "username": [
                        "can't be blank"
                    ]
                }
            }
        },
        "status": 422,
        "statusText": "Unprocessable Entity",
        
    }
    */
    createUser: async function(user, accessToken) {

        var config = buildConfig(accessToken);
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        var response = response = await api.post(
            '/accounts/users/',
            JSON.stringify(user),
            config
        ).then(res => {
            if (res.status == 400) return null;
            return res.data;
        }).catch(e => {
            console.log(e);
            Object.keys(e.data.user.errors).forEach(key => {
                e.data.user.errors[key].forEach(message => console.log(key + " : " + message))
            });
            return null;
        });


        return response;
    },

    editUser: async function(user, accessToken) {
        var response = await api.put(
            '/accounts/users/',
            JSON.stringify(user),
            buildConfig(accessToken)
        ).then(res => {
            if (res.status == 400) return null;
            return user;
        }).catch(e => {
            console.log(USER_MESSAGES.NOT_MODIFIED);
            console.log(e);
            Object.keys(e.data.user.errors).forEach(key => {
                console.log(e.data.user.errors[key]);
            });
            return null;
        });

        return response;
    },

    deleteUser: async function(idUser, accessToken) {
        var response = await api.delete('/accounts/users/' + idUser, buildConfig(accessToken))
            .then(res => res.status != 400)
            .catch(e => {
                console.log(USER_MESSAGES.NOT_DELETED);
                console.log(e);
                return false;
            });

        return response;
    }
}


/********************************************ALTRO**********************************************************************/



export async function getLicenses(accessToken) {
    var response = await api.get("/licenses/", buildConfig(accessToken))
        .then(res => res.data)
        .catch(e => {
            console.log(e);
            return null;
        });

    return response;
}

export async function getRoles(accessToken) {
    var response = await api.get("/roles/", buildConfig(accessToken))
        .then(res => res.data)
        .catch(e => {
            console.log(e);
            return null;
        });

    return response;
}

export async function getTypes(accessToken) {
    var response = await api.get("enterprise/events/types", buildConfig(accessToken))
        .then(res => res.data)
        .catch(e => {
            console.log(e);
            return null;
        });

    return response;
}

export async function getPricing(license, accessToken) {

    var response = await api.post('enterprise/license/configure/', license, buildConfig(accessToken))
        .then(res => res.data)
        .catch(e => {
            console.log(e);
            return null;
        });

    return response;
}

export async function getEmailProviders(accessToken) {
    var config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + accessToken
        }
    };
    var response = await api.get('/enterprise/smtp', config)
        .then(res => res.data)
        .catch(e => {
            console.log(e);
            return null;
        });

    return response;
}
export const agentAPI = {
    //[TG - 18/06/2024 - hash stringa codice controllo]
    getCoCod: async function(valore, accessToken) {
        var data = {
            'str': valore,
        };

        var response = await api.put('/enterprise/utils/hash', data, buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    //[TG - 18/06/2024 - crypt stringa per parametro]
    getCrio: async function(valore, accessToken) {
        var data = {
            'str': valore,
        };

        var response = await api.put('/enterprise/utils/crypt', data, buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    },

    //[TG - 18/06/2024 - informazioni aggiuntive sulla licenza corrente]
    getLicenseExtendedInfo: async function(valore, accessToken) {

        var response = await api.get('/enterprise/license', buildConfig(accessToken))
            .then(res => res.data)
            .catch(e => {
                console.log(e);
                return null;
            });

        return response;
    }
}


export async function testGET(url) {

    var config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1laWQiOiI1NTVjM2Q3ZS1hNTgxLTQ4MDMtYWZiYy05NTg3ODBlMjRlZWYiLCJ1bmlxdWVfbmFtZSI6Implc3NpY2F2aW51ZXphXzMxN2ZhIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS9hY2Nlc3Njb250cm9sc2VydmljZS8yMDEwLzA3L2NsYWltcy9pZGVudGl0eXByb3ZpZGVyIjoiQVNQLk5FVCBJZGVudGl0eSIsIkFzcE5ldC5JZGVudGl0eS5TZWN1cml0eVN0YW1wIjoiOWUxMDgxNjctZTQyZS00ZDAyLWE4NjktYTA2OWRkZjZmOTM1Iiwicm9sZSI6IkFkbWluIiwiTGljZW5zZUlkIjoiMzE3RkFBNzAwMzU4MUM3NDg4NjNDMjExOTJDMTg2RTUiLCJNYXhOdW1Vc2VycyI6IjEwMCIsIk1heE51bUNvbXB1dGVycyI6IjM1MCIsImlzcyI6Imh0dHBzOi8vaXBlcml1c2MxLmNvbSIsImF1ZCI6IjQxNGUxOTI3YTM4ODRmNjhhYmM3OWY3MjgzODM3ZmQxIiwiZXhwIjoxNjgzMjgxMTA5LCJuYmYiOjE2ODMyNzc1MDl9.JAcv246gOXSA_I12UWrlQCOLWVdmXsq9xGQO7Dnbkqc"
        }
    };
    var response = await api.get(url, config)
        .then(res => res.data)
        .catch(e => {
            console.log(e);
            return null;
        });

    console.log(response)
    return response;

}

function buildConfig(accessToken, baseurl = process.env.VUE_APP_BASE_URL) {
    return {
        baseURL: baseurl ?? process.env.VUE_APP_BASE_URL,
        headers: {
            //"Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + accessToken
        }
    };
}

function buildConfigTFA(accessToken) {
    return {
        headers: {
            //"Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + accessToken,
            "redirect": 1,
            "Content-Type": "application/json",
        }
    };
}